///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			containers are pages / views used in the app and are made up of components and can interact with services and models

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Box, Card, Typography } from '@mui/material/'
import { useContext, useEffect, useReducer, useState } from 'react'
import { AuthenticatedContainer } from 'rfbp_aux/containers/authenticated_container'
import { ApplicationPages } from 'rfbp_aux/data/application_structure'
import { DatabaseRef_Regions_Collection } from 'rfbp_aux/services/database_endpoints/directory/regions'
import { DatabaseRef_SchedulingTeams_Collection } from 'rfbp_aux/services/database_endpoints/operations/schedules'
import { Icon } from 'rfbp_core/components/icons'
import {
  TableCellManage,
  TableDatabase,
  TsInterface_TableAdditionalData,
  TsInterface_TableColumns,
  TsInterface_TableDatabaseEndpointQueryObject,
  TsInterface_TableDatabaseSettings,
  TsInterface_TableDataRow,
  TsInterface_TableHooks,
} from 'rfbp_core/components/table'
import { rLIB } from 'rfbp_core/localization/library'
import { Context_RootData_ClientKey } from 'rfbp_core/services/context'
import {
  DatabaseGetLiveCollection,
  generateDatabaseQuery,
  TsInterface_QueryCursorsObject,
  TsInterface_QueryOperatorsArray,
} from 'rfbp_core/services/database_management'
import { getProp } from 'rfbp_core/services/helper_functions'
import { directAppNavigation } from 'rfbp_core/services/navigation/navigation_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject, TsType_VoidFunction } from 'rfbp_core/typescript/global_types'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

// Authenticated Nav Data
const pageKey: string = ApplicationPages['AdminDatabaseTeamViewPage']['key']

///////////////////////////////
// Functions
///////////////////////////////
const formatTeamType = (teamType: string): string => {
  if (!teamType) return 'No Team Type'

  // Replace underscores with spaces and capitalize the first letter of each word
  return teamType
    .split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')
}

///////////////////////////////
// Container
///////////////////////////////

export const Container: React.FC = (): JSX.Element => {
  const [us_regions, us_setRegions] = useState<TsInterface_UnspecifiedObject>({})
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setRegions(newData)
      ur_forceRerender()
    }
    if (uc_RootData_ClientKey != null) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_Regions_Collection(res_GCK.clientKey), updateLiveData)
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    } else {
      us_setRegions({})
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, ur_forceRerender, uc_setRootData_ClientKey])

  // Functions

  // JSX Generation

  const tableSettings_AllTeams: TsInterface_TableDatabaseSettings = {
    rows_per_page: 100,
    show_header: true,
    size: 'small',
    sort_direction: 'asc',
    sort_property: 'name',
    use_live_data: false,
    sticky_header: true,
    sticky_first_column: true,
    sticky_table_height: '900px',
    conditional_row_styles: [
      {
        className: 'tw-opacity-30 tw-line-through',
        conditional_display: {
          active: true,
          logic_type: 'comparison',
          source: 'rowData',
          prop: 'status',
          comparator: '==',
          value: 'deleted',
          conditions: [],
        },
      },
    ],
  }

  const tableDatabaseEndpoint_Teams = (
    queryGenerationData: TsInterface_TableDatabaseEndpointQueryObject,
    tableAdditionalData: TsInterface_TableAdditionalData,
  ) => {
    let queryOperatorsArray: TsInterface_QueryOperatorsArray = [
      { prop: 'status', comparator: '==', value: 'active' }, // Only show active teams
    ]
    let queryCursorsObject: TsInterface_QueryCursorsObject = {}
    if (queryGenerationData['startAfter'] != null) {
      queryCursorsObject['startAfter'] = queryGenerationData.startAfter
    }
    if (queryGenerationData['startAt'] != null) {
      queryCursorsObject['startAt'] = queryGenerationData.startAt
    }
    if (queryGenerationData['endAt'] != null) {
      queryCursorsObject['endAt'] = queryGenerationData.endAt
    }
    if (queryGenerationData['endBefore'] != null) {
      queryCursorsObject['endBefore'] = queryGenerationData.endBefore
    }

    // Apply limit for pagination
    let limit = getProp(queryGenerationData, 'limit', 5)

    return generateDatabaseQuery(DatabaseRef_SchedulingTeams_Collection(uc_RootData_ClientKey as string), queryOperatorsArray, [], queryCursorsObject, limit)
  }

  const viewTeam = (rowData: TsInterface_TableDataRow, tableHooks: TsInterface_TableHooks): void => {
    if (rowData.key != null) {
      directAppNavigation(tableHooks.un_routerNavigation, ApplicationPages.AdminDatabaseTeamViewPage.url(rowData.key as string))
    }
  }

  const tableColumns_TeamData: TsInterface_TableColumns = {
    manage: TableCellManage({
      view: {
        icon: (
          <Icon
            icon="eye"
            type="solid"
          />
        ),
        label: <>{rLIB('View')}</>,
        onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
          viewTeam(rowData, tableHooks)
        },
      },
    }),

    name: {
      header: {
        header_jsx: () => <Box>{rLIB('Team Name')}</Box>,
        header_sort_by: 'name',
      },
      cell: {
        cell_jsx: (rowData: TsInterface_UnspecifiedObject) => <Typography>{rowData.name || 'No Name'}</Typography>,
      },
    },
    associated_member_names: {
      header: {
        header_jsx: () => <Box>{rLIB('Team Members')}</Box>,
        header_sort_by: 'associated_member_names',
      },
      cell: {
        cell_jsx: (rowData: TsInterface_UnspecifiedObject) => {
          const memberNames = rowData.associated_member_names ? Object.values(rowData.associated_member_names).join(', ') : 'No Members'

          return <>{memberNames}</>
        },
      },
    },

    team_type: {
      header: {
        header_jsx: () => <Box>{rLIB('Team Type')}</Box>,
        header_sort_by: 'team_type',
      },
      cell: {
        cell_jsx: (rowData: TsInterface_UnspecifiedObject) => <Typography>{formatTeamType(rowData.team_type)}</Typography>,
      },
    },

    associated_region_key: {
      header: {
        header_jsx: () => <Box>{rLIB('Region')}</Box>,
        header_sort_by: 'associated_region_key',
      },
      cell: {
        cell_jsx: (rowData: TsInterface_UnspecifiedObject) => {
          const regionKey = rowData.associated_region_key
          const regionName = us_regions && regionKey ? us_regions[regionKey]?.name : 'No Region'

          return <Typography>{regionName}</Typography>
        },
      },
    },
  }

  const rJSX_Table = (): JSX.Element => {
    let tableJSX = <></>

    if (!uc_RootData_ClientKey) {
      return tableJSX
    }

    tableJSX = (
      <Card className="tw-mt-4">
        <TableDatabase
          tableAdditionalData={{}}
          tableColumns={tableColumns_TeamData}
          tableDatabaseEndpoint={tableDatabaseEndpoint_Teams}
          tableSettings={tableSettings_AllTeams}
        />
      </Card>
    )

    return tableJSX
  }

  const rJSX_Page = (): JSX.Element => {
    let pageJSX = (
      <AuthenticatedContainer
        pageHeader={rLIB('Teams')}
        pageKey={pageKey}
        content={<Box>{rJSX_Table()}</Box>}
      />
    )
    return pageJSX
  }

  // Render
  return <>{rJSX_Page()}</>
}
