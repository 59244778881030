import { collection, CollectionReference, doc, DocumentData, DocumentReference, getFirestore, Query } from 'firebase/firestore'
import { generateDatabaseQuery, TsInterface_OrderByArray, TsInterface_QueryOperatorsArray } from 'rfbp_core/services/database_management'

export const DatabaseRef_Proposals_Collection = (clientKey: string): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'sales', 'proposals', 'main')
}

export const DatabaseRef_Proposal_Document = (clientKey: string, proposalKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'sales', 'proposals', 'main', proposalKey)
}

export const DatabaseRef_ProposalsForOpportunity_Query = (clientKey: string, opportunityKey: string): Query<unknown> => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [{ prop: 'associated_opportunity_key', comparator: '==', value: opportunityKey }]
  let orderByArray: TsInterface_OrderByArray = []
  return generateDatabaseQuery(collection(getFirestore(), 'clients', clientKey, 'sales', 'proposals', 'main'), queryOperatorsArray, orderByArray, {}, null)
}

export const DatabaseRef_ProposalFiles_Collection = (clientKey: string, proposalKey: string): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'sales', 'proposals', 'main', proposalKey, 'files')
}

export const DatabaseRef_ProposalFile_Document = (clientKey: string, proposalKey: string, fileKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'sales', 'proposals', 'main', proposalKey, 'files', fileKey)
}
