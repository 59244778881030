///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			containers are pages / views used in the app and are made up of components and can interact with services and models

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { AppBar, Box, Button, Card, Chip, Dialog, DialogContent, IconButton, Toolbar, Tooltip, Typography } from '@mui/material/'
import { rJSX_UserRoleChip } from 'app/models/users/user_table'
import { useContext, useEffect, useReducer, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { AuthenticatedContainer } from 'rfbp_aux/containers/authenticated_container'
import { ApplicationPages } from 'rfbp_aux/data/application_structure'
import { DatabaseRef_TimecardHourlyUsers_Query } from 'rfbp_aux/services/database_endpoints/directory/users'
import {
  DatabaseRef_CombineLogsForSpecificDateKey_Query,
  DatabaseRef_CombineLogs_Document,
  DatabaseRef_PreviousUserCombineLogs_Query,
  DatabaseRef_UserCombineLogs_Query,
} from 'rfbp_aux/services/database_endpoints/finances/combine_logs'
import { DatePicker } from 'rfbp_core/components/date_picker'
import {
  TsInterface_FormAdditionalData,
  TsInterface_FormData,
  TsInterface_FormHooksObject,
  TsInterface_FormInputs,
  TsInterface_FormSettings,
  TsInterface_FormSubmittedData,
} from 'rfbp_core/components/form'
import { Icon } from 'rfbp_core/components/icons'
import {
  TableBasic,
  TableCellBasic,
  TableCellManage,
  TsInterface_TableAdditionalData,
  TsInterface_TableColumns,
  TsInterface_TableDataRow,
  TsInterface_TableHooks,
  TsInterface_TableSettings,
} from 'rfbp_core/components/table'
import { rLIB } from 'rfbp_core/localization/library'
import { Context_RootData_ClientKey, UserInterface_Default_CustomDialogDisplayState } from 'rfbp_core/services/context'
import { DatabaseGetCollection, DatabaseGetLiveCollection, DatabaseSetMergeDocument } from 'rfbp_core/services/database_management'
import { dynamicSort, getProp, objectToArray, returnFormattedDateKey } from 'rfbp_core/services/helper_functions'
import { onClickAppNavigation } from 'rfbp_core/services/navigation/navigation_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject, TsType_UnknownPromise, TsType_VoidFunction } from 'rfbp_core/typescript/global_types'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

// Authenticated Nav Data
const pageKey: string = ApplicationPages['CombineManagementPage']['key']

// Employee Table
const tableSettings_Employees: TsInterface_TableSettings = {
  paginated: true,
  pagination_rows_per_page_default: 100,
  pagination_rows_per_page_options: [10, 25, 50, 100],
  show_header: true,
  size: 'small',
  sort_direction: 'asc',
  sort_property_default: 'name',
  sortable: true,
  collapsible_columns: true,
}

// Table
const tableColumns_EmployeeCombine: TsInterface_TableColumns = {
  manage: TableCellManage({
    view: {
      icon: <Icon icon="magnifying-glass" />,
      label: <>{rLIB('View history')}</>,
      onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        viewCombineHistory(rowData, tableHooks)
      },
    },
  }),
  name: TableCellBasic('name', rLIB('Employee'), 'name'),
  user_role: {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rLIB('User Type')
      },
      header_sort_by: 'user_role',
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = <Box>{rJSX_UserRoleChip(rowData)}</Box>
        return cellJSX
      },
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        let cellCSS = ''
        return cellCSS
      },
    },
  },
  // hourly_or_salaried: TableCellBasic( "hourly_or_salaried", s_HOURLY_OR_SALARIED, "hourly_or_salaried" ),
  TEMP_previous_combine_status: {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rLIB('Previous Combine Status')
      },
      header_sort_by: null,
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = <></>
        if (
          rowData != null &&
          rowData.key != null &&
          tableAdditionalData != null &&
          tableAdditionalData.us_previousUserCombineLogs != null &&
          tableAdditionalData.us_previousUserCombineLogs[rowData.key as string] != null
        ) {
          let previousCombineLog = tableAdditionalData.us_previousUserCombineLogs[rowData.key as string]
          if (previousCombineLog.in_combine === true) {
            cellJSX = (
              <Box>
                <Tooltip
                  title={previousCombineLog.notes}
                  placement="left"
                >
                  <Chip
                    sx={{ maxWidth: '230px' }}
                    className="tw-text-ellipsis tw-overflow-hidden tw-cursor-pointer"
                    color="error"
                    label={
                      <>
                        <Icon
                          icon="triangle-exclamation"
                          className="tw-mr-1"
                        />
                        {rLIB('In Combine')} - {formatDate(getProp(previousCombineLog, 'timestamp_key', ''))}
                      </>
                    }
                    variant="filled"
                  />
                </Tooltip>
              </Box>
            )
          } else if (previousCombineLog.in_combine === false) {
            cellJSX = (
              <Box>
                <Tooltip
                  title={previousCombineLog.notes}
                  placement="left"
                >
                  <Chip
                    sx={{ maxWidth: '230px' }}
                    className="tw-text-ellipsis tw-overflow-hidden tw-cursor-pointer"
                    color="success"
                    label={
                      <>
                        <Icon
                          icon="badge-check"
                          className="tw-mr-1"
                        />
                        {rLIB('Not in Combine')} - {formatDate(getProp(previousCombineLog, 'timestamp_key', ''))}
                      </>
                    }
                    variant="outlined"
                  />
                </Tooltip>
              </Box>
            )
          }
        } else {
          cellJSX = (
            <Box>
              <Chip
                sx={{ maxWidth: '230px' }}
                className="tw-text-ellipsis tw-overflow-hidden tw-cursor-pointer"
                color="default"
                label={
                  <>
                    <Icon
                      icon="badge-check"
                      className="tw-mr-1"
                    />
                    {rLIB('Never in Combine')}
                  </>
                }
                variant="outlined"
              />
            </Box>
          )
        }
        return cellJSX
      },
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        let cellCSS = ''
        return cellCSS
      },
    },
  },
  TEMP_current_combine_status: {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        let headerJSX = (
          <Box>
            <Box>{rLIB('Combine status')}</Box>
            <Box>
              {getProp(tableAdditionalData, 'us_mondayDateKey', '')} - {getProp(tableAdditionalData, 'us_weekEndDateKey', '')}
            </Box>
          </Box>
        )
        return headerJSX
      },
      header_sort_by: null,
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let formData: TsInterface_UnspecifiedObject = {}
        if (
          rowData != null &&
          rowData.key != null &&
          tableAdditionalData != null &&
          tableAdditionalData.us_mondayDateKeyNumber != null &&
          tableAdditionalData.us_selectedWeekCombineLogs != null &&
          tableAdditionalData.us_selectedWeekCombineLogs[(rowData.key as string) + '_' + tableAdditionalData.us_mondayDateKeyNumber.toString()] != null
        ) {
          formData = tableAdditionalData.us_selectedWeekCombineLogs[(rowData.key as string) + '_' + tableAdditionalData.us_mondayDateKeyNumber.toString()]
        }
        let editIconJSX = (
          <Icon
            icon="pen-to-square"
            tooltip={rLIB('Edit')}
            tooltipPlacement="left"
            className="tw-ml-2 tw-opacity-30 hover:tw-opacity-100 tw-cursor-pointer hover:tw-text-success_main"
            onClick={() => {
              tableHooks.uc_setUserInterface_FormDialogDisplay({
                display: true,
                form: {
                  form: {
                    formAdditionalData: {},
                    formData: formData,
                    formInputs: {
                      in_combine: {
                        key: 'in_combine',
                        label: rLIB('Combine status'),
                        input_type: 'multiple_choice_radio',
                        required: false,
                        data_type: 'boolean',
                        options: [
                          { key: true, value: rLIB('In Combine') },
                          { key: false, value: rLIB('Not in Combine') },
                        ],
                      },
                      notes: {
                        key: 'notes',
                        label: rLIB('Notes'),
                        input_type: 'text_multiline',
                        required: true,
                        data_type: 'string',
                      },
                    },
                    formOnChange: (
                      formAdditionalData: TsInterface_FormAdditionalData,
                      formData: TsInterface_FormData,
                      formInputs: TsInterface_FormInputs,
                      formSettings: TsInterface_FormSettings,
                    ) => {},
                    formSettings: {},
                    formSubmission: (
                      formSubmittedData: TsInterface_FormSubmittedData,
                      formAdditionalData: TsInterface_FormAdditionalData,
                      formHooks: TsInterface_FormHooksObject,
                    ) => {
                      return new Promise((resolve, reject) => {
                        getClientKey(formHooks.uc_RootData_ClientKey, formHooks.uc_setRootData_ClientKey)
                          .then((res_GCK) => {
                            let updateObject = {
                              in_combine: getProp(formSubmittedData, 'in_combine', false),
                              notes: getProp(formSubmittedData, 'notes', null),
                              associated_user_key: rowData.key as string,
                              timestamp_key: tableAdditionalData.us_mondayDateKeyNumber,
                            }
                            let combineLogKey = (rowData.key as string) + '_' + tableAdditionalData.us_mondayDateKeyNumber.toString()
                            DatabaseSetMergeDocument(DatabaseRef_CombineLogs_Document(res_GCK.clientKey, combineLogKey), updateObject)
                              .then((res_DSMD) => {
                                resolve(res_DSMD)
                              })
                              .catch((rej_DSMD) => {
                                reject(rej_DSMD)
                              })
                          })
                          .catch((rej_GCK) => {
                            formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                            reject(rej_GCK)
                          })
                      })
                    },
                  },
                  dialog: {
                    formDialogHeaderColor: 'info',
                    formDialogHeaderText: (
                      <>
                        {rLIB('Combine status')} - {getProp(rowData, 'name', '')} - {rLIB('Week of')} {getProp(tableAdditionalData, 'us_mondayDateKey', '')}
                      </>
                    ),
                    formDialogIcon: (
                      <Icon
                        type="solid"
                        icon="pen-to-square"
                      />
                    ),
                  },
                },
              })
            }}
          />
        )
        let cellJSX = <></>
        if (
          rowData != null &&
          rowData.key != null &&
          tableAdditionalData != null &&
          tableAdditionalData.us_mondayDateKeyNumber != null &&
          tableAdditionalData.us_selectedWeekCombineLogs != null &&
          tableAdditionalData.us_selectedWeekCombineLogs[(rowData.key as string) + '_' + tableAdditionalData.us_mondayDateKeyNumber.toString()] != null
        ) {
          let combineLog = tableAdditionalData.us_selectedWeekCombineLogs[(rowData.key as string) + '_' + tableAdditionalData.us_mondayDateKeyNumber.toString()]
          if (combineLog.in_combine === true) {
            cellJSX = (
              <Box>
                <Tooltip
                  title={combineLog.notes}
                  placement="left"
                >
                  <Chip
                    sx={{ maxWidth: '230px' }}
                    className="tw-text-ellipsis tw-overflow-hidden tw-cursor-pointer"
                    color="error"
                    label={
                      <>
                        <Icon
                          icon="triangle-exclamation"
                          className="tw-mr-1"
                        />
                        {rLIB('In Combine')}
                      </>
                    }
                    variant="filled"
                  />
                </Tooltip>
                {editIconJSX}
              </Box>
            )
          } else if (combineLog.in_combine === false) {
            cellJSX = (
              <Box>
                <Tooltip
                  title={combineLog.notes}
                  placement="left"
                >
                  <Chip
                    sx={{ maxWidth: '230px' }}
                    className="tw-text-ellipsis tw-overflow-hidden tw-cursor-pointer"
                    color="success"
                    label={
                      <>
                        <Icon
                          icon="badge-check"
                          className="tw-mr-1"
                        />
                        {rLIB('Not in Combine')}
                      </>
                    }
                    variant="outlined"
                  />
                </Tooltip>
                {editIconJSX}
              </Box>
            )
          } else {
            cellJSX = <Box>{editIconJSX}</Box>
          }
        } else {
          cellJSX = (
            <Box>
              <Box className="tw-opacity-30 tw-inline-block">{rLIB('No change this week')}</Box>
              {editIconJSX}
            </Box>
          )
        }
        return cellJSX
      },
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        let cellCSS = ''
        return cellCSS
      },
    },
  },
  // TEMP_referrer: {
  //   header: {
  //     header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
  //       return rLIB('Referrer (For Lifetime Bonus)')
  //     },
  //     header_sort_by: null,
  //   },
  //   cell: {
  //     cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
  //       let cellJSX = <>{rowData.key}</>
  //       let editIcon = (
  //         <Icon
  //           icon="pen-to-square"
  //           tooltip={rLIB('Edit')}
  //           tooltipPlacement="left"
  //           className="tw-opacity-30 tw-ml-2 tw-cursor-pointer hover:tw-opacity-100 hover:tw-text-success_main"
  //           onClick={() => {
  //             // Generate Form Inputs
  //             let referrerOptions: TsInterface_UnspecifiedObject[] = []
  //             for (let loopUserKey in getProp(tableAdditionalData, 'us_activeHourlyUsers', {})) {
  //               if (getProp(tableAdditionalData.us_activeHourlyUsers[loopUserKey], 'name', null) != null) {
  //                 if (loopUserKey === rowData.key) {
  //                   referrerOptions.push({
  //                     key: loopUserKey,
  //                     value: getProp(tableAdditionalData.us_activeHourlyUsers[loopUserKey], 'name', ''),
  //                     disabled: true,
  //                   })
  //                 } else {
  //                   referrerOptions.push({
  //                     key: loopUserKey,
  //                     value: getProp(tableAdditionalData.us_activeHourlyUsers[loopUserKey], 'name', ''),
  //                   })
  //                 }
  //               }
  //             }
  //             referrerOptions = referrerOptions.sort(dynamicSort('value', 'asc'))
  //             // Open Form Dialog
  //             tableHooks.uc_setUserInterface_FormDialogDisplay({
  //               display: true,
  //               form: {
  //                 form: {
  //                   formAdditionalData: {},
  //                   formData: {},
  //                   formInputs: {
  //                     associated_referrer_key: {
  //                       data_type: 'string',
  //                       input_type: 'multiple_choice_select',
  //                       key: 'associated_referrer_key',
  //                       label: rLIB('Referrer'),
  //                       required: true,
  //                       options: referrerOptions,
  //                     },
  //                   },
  //                   formOnChange: (
  //                     formAdditionalData: TsInterface_FormAdditionalData,
  //                     formData: TsInterface_FormData,
  //                     formInputs: TsInterface_FormInputs,
  //                     formSettings: TsInterface_FormSettings,
  //                   ) => {},
  //                   formSettings: {},
  //                   formSubmission: (
  //                     formSubmittedData: TsInterface_FormSubmittedData,
  //                     formAdditionalData: TsInterface_FormAdditionalData,
  //                     formHooks: TsInterface_FormHooksObject,
  //                   ) => {
  //                     return new Promise((resolve, reject) => {
  //                       console.log(formSubmittedData)
  //                       getClientKey(formHooks.uc_RootData_ClientKey, formHooks.uc_setRootData_ClientKey)
  //                         .then((res_GCK) => {
  //                           let updateObject = {
  //                             [rowData.key as string]: {
  //                               associated_referrer_key: formSubmittedData.associated_referrer_key,
  //                               associated_referrer_name: getProp(
  //                                 referrerOptions.find((option) => option.key === formSubmittedData.associated_referrer_key),
  //                                 'value',
  //                                 '',
  //                               ),
  //                             },
  //                           }
  //                           console.log(updateObject)
  //                           DatabaseSetMergeDocument(DatabaseRef_LifetimeReferrals_Document(res_GCK.clientKey), updateObject)
  //                             .then((res_DAD) => {
  //                               resolve(res_DAD)
  //                             })
  //                             .catch((rej_DAD) => {
  //                               reject(rej_DAD)
  //                             })
  //                         })
  //                         .catch((rej_GCK) => {
  //                           formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
  //                           reject(rej_GCK)
  //                         })
  //                     })
  //                   },
  //                 },
  //                 dialog: {
  //                   formDialogHeaderColor: 'success',
  //                   formDialogHeaderText: (
  //                     <>
  //                       {rLIB('Edit Referrer for')} {rowData.name}
  //                     </>
  //                   ),
  //                   formDialogIcon: (
  //                     <Icon
  //                       type="solid"
  //                       icon="pen-to-square"
  //                     />
  //                   ),
  //                 },
  //               },
  //             })
  //           }}
  //         />
  //       )
  //       if (
  //         rowData.key != null &&
  //         tableAdditionalData != null &&
  //         tableAdditionalData.us_lifetimeReferrals != null &&
  //         tableAdditionalData.us_lifetimeReferrals[rowData.key as string] != null &&
  //         tableAdditionalData.us_lifetimeReferrals[rowData.key as string]['associated_referrer_key'] != null &&
  //         tableAdditionalData.us_lifetimeReferrals[rowData.key as string]['associated_referrer_name'] != null
  //       ) {
  //         cellJSX = (
  //           <Box>
  //             <Box className="tw-inline-block ">{tableAdditionalData.us_lifetimeReferrals[rowData.key as string]['associated_referrer_name']}</Box>
  //             {editIcon}
  //           </Box>
  //         )
  //       } else {
  //         cellJSX = (
  //           <Box>
  //             <Box className="tw-inline-block tw-opacity-30 tw-italic">{rLIB('No referrer')}</Box>
  //             {editIcon}
  //           </Box>
  //         )
  //       }
  //       return cellJSX
  //     },
  //     cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
  //       let cellCSS = ''
  //       return cellCSS
  //     },
  //   },
  // },
}

///////////////////////////////
// Functions
///////////////////////////////

const viewCombineHistory = (user: TsInterface_UnspecifiedObject, tableHooks: TsInterface_TableHooks) => {
  if (user != null && user.key != null && user.name != null) {
    getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
      .then((res_GCK) => {
        DatabaseGetCollection(DatabaseRef_UserCombineLogs_Query(res_GCK.clientKey, user.key))
          .then((res_DGLC) => {
            let combineLogs = objectToArray(res_DGLC.data).sort(dynamicSort('timestamp_key', 'asc'))

            console.log(res_DGLC.data)

            let logsJSX = <></>
            if (combineLogs.length > 0) {
              logsJSX = (
                <Box>
                  <TableBasic
                    tableAdditionalData={{}}
                    tableColumns={{
                      timestamp_key: {
                        header: {
                          header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
                            let headerJSX = <></>
                            return headerJSX
                          },
                          header_sort_by: null,
                        },
                        cell: {
                          cell_jsx: (
                            rowData: TsInterface_TableDataRow,
                            tableAdditionalData: TsInterface_TableAdditionalData,
                            tableHooks: TsInterface_TableHooks,
                          ) => {
                            let cellJSX = <></>

                            cellJSX = <Box>{formatDate(getProp(rowData, 'timestamp_key', ''))}</Box>

                            return cellJSX
                          },
                          cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
                            let cellCSS = ''
                            return cellCSS
                          },
                        },
                      },
                      associated_user_key: {
                        header: {
                          header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
                            let headerJSX = <></>
                            return headerJSX
                          },
                          header_sort_by: null,
                        },
                        cell: {
                          cell_jsx: (
                            rowData: TsInterface_TableDataRow,
                            tableAdditionalData: TsInterface_TableAdditionalData,
                            tableHooks: TsInterface_TableHooks,
                          ) => {
                            let cellJSX = <></>

                            cellJSX = (
                              <Chip
                                sx={{ maxWidth: '230px' }}
                                className="tw-text-ellipsis tw-overflow-hidden tw-cursor-pointer"
                                color={rowData.in_combine === true ? 'error' : 'success'}
                                label={
                                  <>
                                    {rowData.in_combine === true ? (
                                      <Icon
                                        icon="triangle-exclamation"
                                        className="tw-mr-1"
                                      />
                                    ) : (
                                      <Icon
                                        icon="badge-check"
                                        className="tw-mr-1"
                                      />
                                    )}
                                    {rowData.in_combine === true ? rLIB('In Combine') : rLIB('Not in Combine')}
                                  </>
                                }
                                variant="filled"
                              />
                            )
                            return cellJSX
                          },
                          cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
                            let cellCSS = ''
                            return cellCSS
                          },
                        },
                      },
                      notes: TableCellBasic('notes', rLIB('Notes'), 'notes'),
                    }}
                    tableData={combineLogs}
                    tableSettings={{
                      paginated: false,
                      show_header: false,
                      size: 'small',
                      sort_direction: 'asc',
                      sort_property_default: 'timestamp_key',
                      sortable: false,
                      collapsible_columns: false,
                    }}
                  />
                </Box>
              )
            } else {
              logsJSX = <Box className="tw-text-center tw-p-4 tw-opacity-30">{rLIB('No combine status logs')}</Box>
            }

            let dialogJSX = (
              <Box>
                <Dialog
                  className="bp_dialog_xl_width"
                  keepMounted
                  onClose={() => {
                    tableHooks.uc_setUserInterface_CustomDialogDisplay(UserInterface_Default_CustomDialogDisplayState)
                  }}
                  open={true}
                >
                  <AppBar
                    position="static"
                    color="inherit"
                  >
                    <Toolbar>
                      <IconButton
                        color="inherit"
                        disabled
                        edge="start"
                        size="large"
                        sx={{ mr: 2, color: '#fff !important' }}
                      >
                        <Icon icon="list-timeline" />
                      </IconButton>
                      <Typography
                        component={'span'}
                        variant={'h6'}
                        sx={{ flexGrow: 1 }}
                      >
                        <Box className="tw-inline-block">{user.name}</Box>
                      </Typography>
                    </Toolbar>
                  </AppBar>
                  <DialogContent sx={{ padding: '0px' }}>
                    <Box>{logsJSX}</Box>
                  </DialogContent>
                </Dialog>
              </Box>
            )
            tableHooks.uc_setUserInterface_CustomDialogDisplay({
              display: true,
              dialog: {
                dialog_jsx: dialogJSX,
                settings: {
                  max_width: 'lg',
                },
              },
            })
          })
          .catch((rej_DGLC) => {
            console.error(rej_DGLC)
          })
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
  }
}

function getStartAndEndOfWeek(date: Date) {
  const startOfWeek = new Date(date)
  startOfWeek.setHours(0, 0, 0, 0)
  startOfWeek.setDate(date.getDate() - ((date.getDay() + 6) % 7) + 0)
  const endOfWeek = new Date(date)
  endOfWeek.setHours(23, 59, 59, 999)
  endOfWeek.setDate(date.getDate() - ((date.getDay() + 6) % 7) + 6)
  return {
    startOfWeek,
    endOfWeek,
  }
}

function formatDate(inputDate: number): string {
  // Check if the input is a valid 8-digit number
  if (/^\d{8}$/.test(inputDate.toString())) {
    // Extract year, month, and day from the input
    const year = inputDate.toString().substring(0, 4)
    const month = inputDate.toString().substring(4, 6)
    const day = inputDate.toString().substring(6, 8)
    // Format the date as YYYY-MM-DD
    const formattedDate = `${year}-${month}-${day}`
    return formattedDate
  } else {
    // Handle invalid input
    return ''
  }
}

///////////////////////////////
// Container
///////////////////////////////

export const Container: React.FC = (): JSX.Element => {
  // Props
  // const params = useParams()
  // const itemKey: string = params.id as string

  // Hooks - useContext, useState, useReducer, other
  // { sort-start } - hooks
  const [us_showOnlyCombineUsers, us_setShowOnlyCombineUsers] = useState<boolean>(false)
  const [us_activeHourlyUsersInCombine, us_setActiveHourlyUsersInCombine] = useState<TsInterface_UnspecifiedObject>({})
  const [us_activeHourlyUsers, us_setActiveHourlyUsers] = useState<TsInterface_UnspecifiedObject>({})
  const [us_mondayDateKey, us_setMondayDateKey] = useState<string | null>(null)
  const [us_mondayDateKeyNumber, us_setMondayDateKeyNumber] = useState<number | null>(null)
  const [us_previousUserCombineLogs, us_setPreviousUserCombineLogs] = useState<TsInterface_UnspecifiedObject>({})
  const [us_selectedDate, us_setSelectedDate] = useState<Date>(new Date())
  const [us_selectedWeekCombineLogs, us_setSelectedWeekCombineLogs] = useState<TsInterface_UnspecifiedObject>({})
  const [us_userKeysArray, us_setUserKeysArray] = useState<string[]>([])
  const [us_weekEndDateKey, us_setWeekEndDateKey] = useState<string | null>(null)
  const un_routerNavigation = useNavigate()
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)
  // { sort-end } - hooks

  // Hooks - useEffect
  useEffect(() => {
    document.title = rLIB('Combine Management', false) as string
  }, [])

  useEffect(() => {
    let weekBoundingDates = getStartAndEndOfWeek(us_selectedDate)
    let formattedDateKey = returnFormattedDateKey(weekBoundingDates.startOfWeek)
    let mondayDateKeyNumber = parseInt(formattedDateKey.replace(/-/g, ''))
    us_setMondayDateKey(formattedDateKey)
    us_setMondayDateKeyNumber(mondayDateKeyNumber)
    us_setWeekEndDateKey(returnFormattedDateKey(weekBoundingDates.endOfWeek))
    return () => {}
  }, [us_selectedDate])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setSelectedWeekCombineLogs(newData)
      ur_forceRerender()
    }
    if (us_mondayDateKeyNumber != null) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          unsubscribeLiveData = DatabaseGetLiveCollection(
            DatabaseRef_CombineLogsForSpecificDateKey_Query(res_GCK.clientKey, us_mondayDateKeyNumber),
            updateLiveData,
          )
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender, us_mondayDateKeyNumber])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setActiveHourlyUsers(newData)
      let userKeys = Object.keys(newData).sort()
      us_setUserKeysArray(userKeys)
      ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_TimecardHourlyUsers_Query(res_GCK.clientKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

  useEffect(() => {
    if (us_mondayDateKeyNumber != null) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          let previousCombineLogs: TsInterface_UnspecifiedObject = {}
          let promiseArray: TsType_UnknownPromise[] = []
          for (let loopUserIndex in us_userKeysArray) {
            let loopUserKey = us_userKeysArray[loopUserIndex]
            promiseArray.push(
              DatabaseGetCollection(DatabaseRef_PreviousUserCombineLogs_Query(res_GCK.clientKey, loopUserKey, us_mondayDateKeyNumber))
                .then((res_DGLC) => {
                  previousCombineLogs[loopUserKey] = objectToArray(res_DGLC.data)[0]
                })
                .catch((rej_DGLC) => {
                  console.error(rej_DGLC)
                }),
            )
          }
          Promise.all(promiseArray).then(() => {
            us_setPreviousUserCombineLogs(previousCombineLogs)
          })
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    }
    return () => {}
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, us_mondayDateKeyNumber, us_userKeysArray])

  useEffect(() => {
    let usersInCombine: TsInterface_UnspecifiedObject = {}
    for (let loopUserKey in us_activeHourlyUsers) {
      let loopUser = us_activeHourlyUsers[loopUserKey]
      if (
        us_mondayDateKeyNumber != null &&
        us_selectedWeekCombineLogs != null &&
        us_selectedWeekCombineLogs[loopUserKey + '_' + us_mondayDateKeyNumber.toString()] != null &&
        us_selectedWeekCombineLogs[loopUserKey + '_' + us_mondayDateKeyNumber.toString()].in_combine === true
      ) {
        usersInCombine[loopUserKey] = loopUser
      } else if (
        us_mondayDateKeyNumber != null &&
        us_selectedWeekCombineLogs != null &&
        us_selectedWeekCombineLogs[loopUserKey + '_' + us_mondayDateKeyNumber.toString()] == null &&
        us_previousUserCombineLogs != null &&
        us_previousUserCombineLogs[loopUserKey] != null &&
        us_previousUserCombineLogs[loopUserKey].in_combine === true
      ) {
        usersInCombine[loopUserKey] = loopUser
      }
    }
    us_setActiveHourlyUsersInCombine(usersInCombine)
  }, [us_mondayDateKeyNumber, us_activeHourlyUsers, us_previousUserCombineLogs, us_selectedWeekCombineLogs])

  // Functions
  const changeCalendarDate = (newDate: Date): TsType_UnknownPromise => {
    return new Promise((resolve, reject) => {
      us_setSelectedDate(new Date(newDate))
      ur_forceRerender()
      resolve({ success: true })
    })
  }

  // JSX Generation
  const rJSX_BackButton = (): JSX.Element => {
    let buttonJSX = (
      <Button
        color="inherit"
        variant="outlined"
        onClick={(event) => {
          onClickAppNavigation(event, un_routerNavigation, ApplicationPages.AdminPayrollIndexPage.url())
        }}
        disableElevation
        startIcon={<Icon icon="chevron-left" />}
        className="tw-mr-2"
      >
        {rLIB('Back to Payroll')}
      </Button>
    )
    return buttonJSX
  }

  const rJSX_EmployeeTable = (): JSX.Element => {
    let tableAdditionalData_AllUsers: TsInterface_TableAdditionalData = {
      us_mondayDateKey: us_mondayDateKey,
      us_mondayDateKeyNumber: us_mondayDateKeyNumber,
      us_previousUserCombineLogs: us_previousUserCombineLogs,
      us_selectedWeekCombineLogs: us_selectedWeekCombineLogs,
      us_weekEndDateKey: us_weekEndDateKey,
    }
    let tableData = []
    if (us_showOnlyCombineUsers == false) {
      tableData = objectToArray(us_activeHourlyUsers)
    } else {
      tableData = objectToArray(us_activeHourlyUsersInCombine)
    }
    let tableJSX = (
      <Card className="tw-mt-2">
        <TableBasic
          tableAdditionalData={tableAdditionalData_AllUsers}
          tableColumns={tableColumns_EmployeeCombine}
          tableData={tableData}
          tableSettings={tableSettings_Employees}
        />
      </Card>
    )
    return tableJSX
  }

  const rJSX_DatePicker = (disabled: boolean): JSX.Element => {
    let datePickerJSX = (
      <Box className="tw-inline-block tw-mb-2 tw-align-top tw-mr-2">
        <DatePicker
          key={'calendar_date'}
          datePickerText={rLIB('Date')}
          datePickerDate={us_selectedDate}
          datePickerDateOnChange={changeCalendarDate}
          datePickerSettings={{ thin_input: true }}
          datePickerDisabled={disabled}
        />
      </Box>
    )
    return datePickerJSX
  }

  const rJSX_FilterButton = (): JSX.Element => {
    let buttonJSX = <></>
    if (us_showOnlyCombineUsers == false) {
      buttonJSX = (
        <Button
          color="secondary"
          variant="contained"
          onClick={() => {
            us_setShowOnlyCombineUsers(true)
          }}
          disableElevation
          startIcon={<Icon icon="filter" />}
        >
          {rLIB('All Employees')}
        </Button>
      )
    } else {
      buttonJSX = (
        <Button
          color="secondary"
          variant="contained"
          onClick={() => {
            us_setShowOnlyCombineUsers(false)
          }}
          disableElevation
          startIcon={<Icon icon="filter" />}
        >
          {rLIB('Employees In Combine')}
        </Button>
      )
    }
    return buttonJSX
  }

  const rJSX_Page = (): JSX.Element => {
    let pageJSX = (
      <AuthenticatedContainer
        pageHeader={rLIB('Combine Management')}
        pageKey={pageKey}
        content={
          <Box>
            <Box>
              {rJSX_BackButton()}
              {rJSX_DatePicker(false)}
              {rJSX_FilterButton()}
            </Box>
            <Box>{rJSX_EmployeeTable()}</Box>
          </Box>
        }
      />
    )
    return pageJSX
  }

  // Render
  return <>{rJSX_Page()}</>
}
