///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			Services contain business logic that is completely abstracted from interfaces

		TODO:
			Cleanup / rethink?

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { CollectionReference, DocumentData } from 'firebase/firestore'
import { rLIB } from 'rfbp_core/localization/library'
import { DatabaseAddDocument } from 'rfbp_core/services/database_management'

///////////////////////////////
// Types
///////////////////////////////

interface TsInterface_LogObject {
  timestamp: number
  log_type: string
  associated_user_name: string | null
  associated_user_key: string | null
}

///////////////////////////////
// Variables
///////////////////////////////

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Exports
///////////////////////////////

// export const GenerateEventTimelineDataFromLogs = ( logObject: TsInterface_TimelineEvents ): TsInterface_TimelineEvents => {
// let newLogObject: TsInterface_TimelineEvents = {}
// for ( let logKey in logObject ){
// 	let log = getProp( logObject, logKey, {} )
// 	if ( log.log_type == null && log.type != null ) { log.log_type = log.type }
// 	if ( log.timestamp != null ){
// 		newLogObject[ logKey ] = {
// 			text: "",
// 			event_type: getProp( log, "log_type", null ),
// 			timestamp: log.timestamp,
// 		}
// 		switch(log.log_type){
// 			case "create":
// 				if (log.associated_user_name != null){
// 					newLogObject[ logKey ]["text"] = <span>{s_CREATED_BY} {log.associated_user_name}</span>
// 				} else {
// 					newLogObject[ logKey ]["text"] = <span>{s_CREATED}</span>
// 				}
// 				break
// 			case "update":
// 				if (log.associated_user_name != null){
// 					newLogObject[ logKey ]["text"] = <span>{s_UPDATED_BY} {log.associated_user_name}</span>
// 				} else {
// 					newLogObject[ logKey ]["text"] = <span>{s_UPDATED}</span>
// 				}
// 				break
// 			case "delete":
// 				if (log.associated_user_name != null){
// 					newLogObject[ logKey ]["text"] = <span>{s_DELETED_BY} {log.associated_user_name}</span>
// 				} else {
// 					newLogObject[ logKey ]["text"] = <span>{s_DELETED}</span>
// 				}
// 				break
// 			case "undelete":
// 				if (log.associated_user_name != null){
// 					newLogObject[ logKey ]["text"] = <span>{s_UNDELETED_BY} {log.associated_user_name}</span>
// 				} else {
// 					newLogObject[ logKey ]["text"] = <span>{s_UNDELETED}</span>
// 				}
// 				break
// 			case "disable":
// 				if (log.associated_user_name != null){
// 					newLogObject[ logKey ]["text"] = <span>{s_DISABLED_BY} {log.associated_user_name}</span>
// 				} else {
// 					newLogObject[ logKey ]["text"] = <span>{s_DISABLED}</span>
// 				}
// 				break
// 			case "enable":
// 				if (log.associated_user_name != null){
// 					newLogObject[ logKey ]["text"] = <span>{s_ENABLED_BY} {log.associated_user_name}</span>
// 				} else {
// 					newLogObject[ logKey ]["text"] = <span>{s_ENABLED}</span>
// 				}
// 				break
// 			case "user_account_create":
// 				if (log.associated_user_name != null){
// 					newLogObject[ logKey ]["text"] = <span>{s_USER_ACCOUNT_CREATED_BY} {log.associated_user_name}</span>
// 				} else {
// 					newLogObject[ logKey ]["text"] = <span>{s_USER_ACCOUNT_CREATED}</span>
// 				}
// 				break
// 			case "user_account_update":
// 				if (log.associated_user_name != null){
// 					newLogObject[ logKey ]["text"] = <span>{s_USER_ACCOUNT_UPDATED_BY} {log.associated_user_name}</span>
// 				} else {
// 					newLogObject[ logKey ]["text"] = <span>{s_USER_ACCOUNT_UPDATED}</span>
// 				}
// 				break
// 			case "user_account_disable":
// 				if (log.associated_user_name != null){
// 					newLogObject[ logKey ]["text"] = <span>{s_USER_ACCOUNT_DEACTIVATED_BY} {log.associated_user_name}</span>
// 				} else {
// 					newLogObject[ logKey ]["text"] = <span>{s_USER_ACCOUNT_DEACTIVATED}</span>
// 				}
// 				break
// 			case "user_account_reenable":
// 				if (log.associated_user_name != null){
// 					newLogObject[ logKey ]["text"] = <span>{s_USER_ACCOUNT_REACTIVATED_BY} {log.associated_user_name}</span>
// 				} else {
// 					newLogObject[ logKey ]["text"] = <span>{s_USER_ACCOUNT_REACTIVATED}</span>
// 				}
// 				break
// 			case "lock":
// 				if (log.associated_user_name != null){
// 					newLogObject[ logKey ]["text"] = <span>{s_LOCKED_BY} {log.associated_user_name}</span>
// 				} else {
// 					newLogObject[ logKey ]["text"] = <span>{s_LOCKED}</span>
// 				}
// 				break
// 			case "unlock":
// 				if (log.associated_user_name != null){
// 					newLogObject[ logKey ]["text"] = <span>{s_UNLOCKED_BY} {log.associated_user_name}</span>
// 				} else {
// 					newLogObject[ logKey ]["text"] = <span>{s_UNLOCKED}</span>
// 				}
// 				break
// 			default:
// 				if (log.associated_user_name != null){
// 					newLogObject[ logKey ]["text"] = <span>{log.log_type} ({log.associated_user_name})</span>
// 				} else {
// 					newLogObject[ logKey ]["text"] = <span>{log.log_type}</span>
// 				}
// 				break
// 		}
// 	}
// }
// return newLogObject
// }

export const LogChange = (
  logCollectionRef: CollectionReference<DocumentData>,
  logType: string,
  userName: string | null,
  userKey: string | null,
): Promise<unknown> => {
  return new Promise((resolve, reject) => {
    if (userName == null) {
      userName = null
    }
    if (userKey == null) {
      userKey = null
    }
    if (logCollectionRef != null && logType != null) {
      let timestamp = new Date().getTime()
      let databaseUpdateOject: TsInterface_LogObject = {
        timestamp: timestamp,
        log_type: logType,
        associated_user_name: userName,
        associated_user_key: userKey,
      }
      DatabaseAddDocument(logCollectionRef, databaseUpdateOject, true)
        .then((res_DAD: unknown) => {
          resolve(res_DAD)
        })
        .catch((rej_DAD: unknown) => {
          reject(rej_DAD)
        })
    } else {
      reject({
        success: false,
        error: {
          message: rLIB('Failed to log event'),
          details: rLIB('Missing required parameters'),
          code: 'ER-D-SLG-LC-01',
        },
      })
    }
  })
}
