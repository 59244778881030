//////////////////////////////////////////
//		  ooOOOO BOILERPLATE FILE		//
//		 oo		 _____					//
//		_I__n_n__||_|| ________			//
//	  >(_________|_7_|-|______|			//
//	   /o ()() ()() o   oo  oo			//
//////////////////////////////////////////

///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			Components are reused segments of code used to create contend used to create containers (pages)

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Box, FormControl } from '@mui/material/'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/material.css'
import { themeVariables } from 'rfbp_aux/config/app_theme' // Outside Boilerplate
import {
  rJSX_InputAdornment,
  TsInterface_DynamicAttributes,
  TsInterface_FormAdditionalData,
  TsInterface_FormData,
  TsInterface_FormHooksObject,
  TsInterface_FormInput,
  TsInterface_FormSettings,
  TsType_InputChangeCallback,
} from 'rfbp_core/components/form'
import { rLIB } from 'rfbp_core/localization/library'
import { getProp } from 'rfbp_core/services/helper_functions'

///////////////////////////////
// Typescript
///////////////////////////////

interface TsInterface_ComponentProps {
  formAdditionalData: TsInterface_FormAdditionalData
  formData: TsInterface_FormData
  formHooks: TsInterface_FormHooksObject
  formInput: TsInterface_FormInput
  formSettings: TsInterface_FormSettings
  inputChangeCallback: TsType_InputChangeCallback
}

interface TsInterface_PhoneSettings {
  countryCode: string // "us"
  dialCode: string // "1"
  format: string // "+. (...)" ...-....
  name: string // "United States"
}

///////////////////////////////
// Variables
///////////////////////////////

// themeVariables.background_paper

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Component
///////////////////////////////

export const PhoneNumberUSA = (props: TsInterface_ComponentProps): JSX.Element => {
  // Props
  let pr_formData: TsInterface_FormData = getProp(props, 'formData', {})
  let pr_formInput: TsInterface_FormInput = getProp(props, 'formInput', {})
  let pr_formSettings: TsInterface_FormSettings = getProp(props, 'formSettings', {})
  let pr_inputChangeCallback: TsType_InputChangeCallback = props.inputChangeCallback

  // Hooks - useContext, useState, useReducer, other
  // { sort-start } - hooks

  // { sort-end } - hooks

  // Hooks - useEffect

  // Other Variables
  const phoneRegex = /^[0-9]{11}/

  let phoneInputCss =
    `
			.react-tel-input .special-label { background: ` +
    getProp(pr_formInput, 'background_color', themeVariables.background_paper) +
    ` !important; }
			.react-tel-input .invalid-number-message { background: ` +
    getProp(pr_formInput, 'background_color', themeVariables.background_paper) +
    ` !important; }
			.react-tel-input .form-control { background: ` +
    getProp(pr_formInput, 'background_color', themeVariables.background_paper) +
    ` !important; }
			.react-tel-input .form-control { color: ` +
    getProp(pr_formInput, 'font_color', themeVariables.contrast_text) +
    ` !important; }
		`

  // Functions

  // Generate JSX

  // JSX Generation
  const rJSX_Component = (): JSX.Element => {
    // Dynamic Class Name
    let dynamicClassName = ''
    // Dynamic Attributes
    let dynamicAttributes: TsInterface_DynamicAttributes = {}
    if (pr_formInput['required'] === true) {
      dynamicAttributes['required'] = true
    }
    if (
      pr_formInput['required'] === true &&
      pr_formSettings.highlight_missing === true &&
      (pr_formData == null || pr_formData[pr_formInput['key']] == null || pr_formData[pr_formInput['key']] === '')
    ) {
      dynamicAttributes['error'] = true
    }
    if (pr_formInput['disabled'] === true) {
      dynamicAttributes['disabled'] = true
    }
    // Form Input JSX
    let componentJSX = (
      <Box className="tw-mt-3 tw-mb-2">
        <FormControl
          className={dynamicClassName}
          fullWidth
        >
          <PhoneInput
            country={'us'}
            countryCodeEditable={true}
            disableDropdown={true}
            disableSearchIcon={true}
            // @ts-expect-error - TODO: reason for error
            isValid={(value: string) => {
              if (value.match(phoneRegex)) {
                return true
              } else {
                return rLIB('Enter a valid phone number')
              }
            }}
            color="primary"
            value={(pr_formData[pr_formInput.key] as string) || ''}
            id={pr_formInput['key']}
            label={pr_formInput['label']}
            margin="normal"
            onChange={(value: string, settings: TsInterface_PhoneSettings) => {
              if (value != null) {
                pr_inputChangeCallback(pr_formInput.key, value, true)
              }
            }}
            variant="outlined"
            InputProps={{
              startAdornment: rJSX_InputAdornment(pr_formInput, 'start'),
              endAdornment: rJSX_InputAdornment(pr_formInput, 'end'),
            }}
            {...dynamicAttributes}
          />
        </FormControl>
        <style>{phoneInputCss}</style>
      </Box>
    )
    return componentJSX
  }

  // Render
  return <>{rJSX_Component()}</>
}
