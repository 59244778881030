///////////////////////////////
// Description
///////////////////////////////

import { returnFormattedDateKey } from 'rfbp_core/services/helper_functions'

///////////////////////////////
// Imports
///////////////////////////////

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

///////////////////////////////
// Functions
///////////////////////////////

const removeOverlappingTimeOffDays = (rawPanelsInstalled: any, us_organizedTimeOff: any) => {
  Object.keys(us_organizedTimeOff).forEach((weekStart: string) => {
    const teams = us_organizedTimeOff[weekStart]
    Object.keys(teams).forEach((teamKey: string) => {
      const teamTimeOff = teams[teamKey]

      // Filter out the days that overlap with working days (scheduled dates)
      teamTimeOff.days_off = teamTimeOff.days_off.filter((dayOff: string) => {
        const teamDetails = rawPanelsInstalled[weekStart]?.data?.[teamTimeOff.associated_team_name]
        if (!teamDetails) return true // Keep the day off if no team details are found

        // Check if any task is scheduled on this day
        const hasTaskOnDayOff = teamDetails.tasks.some((task: any) => task.latest_task_completion_date === dayOff)

        return !hasTaskOnDayOff // Remove the day off if there is a task on that day
      })

      // If no days off remain, remove the team entry from us_organizedTimeOff
      if (teamTimeOff.days_off.length === 0) {
        delete us_organizedTimeOff[weekStart][teamKey]
      }
    })
  })
}

///////////////////////////////
// Exports
///////////////////////////////

export const processPanelsInstalledData = (
  rawPanelsInstalled: any,
  mondaysInDateRange: string[],
  us_organizedTimeOff: any,
): { summary_rows: any[]; data_rows: any[] } | null => {
  if (!rawPanelsInstalled || !mondaysInDateRange || !us_organizedTimeOff) return null

  // Preprocess the time off data to remove overlapping days
  removeOverlappingTimeOffDays(rawPanelsInstalled, us_organizedTimeOff)

  const today = new Date()
  today.setHours(0, 0, 0, 0) // Normalize today's date

  // Calculate the Monday of the current week
  const currentWeekMonday = new Date(today)
  const dayOfWeek = currentWeekMonday.getDay()
  currentWeekMonday.setDate(today.getDate() - dayOfWeek + 1) // Get Monday of the current week
  currentWeekMonday.setHours(0, 0, 0, 0) // Normalize to midnight

  const formattedData: { summary_rows: any[]; data_rows: any[] } = {
    summary_rows: [],
    data_rows: [],
  }

  const dateHeaders: any[] = [{ cellValue: 'Panels Installed', conditionalFormatting: { fontWeight: 'bold', width: '200px' } }]
  const weeklyTotals: { [key: string]: { installed: number; expected: number } } = {}

  mondaysInDateRange.forEach((monday: string) => {
    const date: Date = new Date(monday)
    date.setDate(date.getDate() + 1) // Ensure it's showing Monday
    date.setHours(0, 0, 0, 0) // Normalize date to midnight

    const formattedDateKey: string = returnFormattedDateKey(date)
    const formattedDate: string = date.toLocaleDateString('en-US', { month: 'short', day: '2-digit' })

    // Only apply opacity for Mondays strictly in the future
    const isFutureMonday = date > currentWeekMonday

    weeklyTotals[formattedDateKey] = { installed: 0, expected: 0 }

    dateHeaders.push({
      cellValue: formattedDate,
      conditionalFormatting: { fontWeight: 'bold', width: '120px', opacity: isFutureMonday ? 0.5 : 1 },
    })
  })

  formattedData.summary_rows.push(dateHeaders)

  const teamDataMap: any = {}

  // Step 1: Initialize team data and calculate expected panels
  mondaysInDateRange.forEach((monday: string, index: number) => {
    const date: Date = new Date(monday)
    date.setDate(date.getDate() + 1) // Ensure it's Monday
    const formattedDateKey: string = returnFormattedDateKey(date)

    Object.entries(rawPanelsInstalled).forEach(([_, teamsData]: [string, any]) => {
      if (teamsData && teamsData.data) {
        Object.entries(teamsData.data).forEach(([teamName, teamDetails]: [string, any]) => {
          const teamKey = teamDetails.associated_team_key

          if (!teamDataMap[teamKey]) {
            teamDataMap[teamKey] = mondaysInDateRange.map((monday: string) => {
              const date: Date = new Date(monday)
              date.setDate(date.getDate() + 1) // Ensure it's Monday
              return { dateKey: returnFormattedDateKey(date), panels: '-', expectedPanels: 100 }
            })
            teamDataMap[teamKey].unshift({
              cellValue: teamName,
              conditionalFormatting: { fontWeight: 'bold', width: '200px' },
            })
          }

          let expectedPanels = 100
          const teamTimeOff = us_organizedTimeOff[monday]?.[teamKey]

          if (teamTimeOff) {
            teamTimeOff.days_off.forEach((dayOff: string) => {
              if (!teamDetails.tasks.some((task: any) => task.latest_task_completion_date === dayOff)) {
                expectedPanels -= 20 // Subtract 20 panels for each day off without a task
              }
            })
          }

          teamDataMap[teamKey][index + 1].expectedPanels = Math.max(expectedPanels, 0) // Prevent negative expected panels
        })
      }
    })
  })

  // Step 2: Set installed panels and populate cell values
  Object.entries(rawPanelsInstalled).forEach(([dateKey, teamsData]: [string, any]) => {
    if (teamsData && teamsData.data) {
      Object.entries(teamsData.data).forEach(([teamName, teamDetails]: [string, any]) => {
        const teamKey = teamDetails.associated_team_key

        mondaysInDateRange.forEach((monday: string, index: number) => {
          const date: Date = new Date(monday)
          date.setDate(date.getDate() + 1) // Ensure it's Monday
          date.setHours(0, 0, 0, 0) // Normalize to midnight
          const formattedDateKey: string = returnFormattedDateKey(date)

          // Adjust the logic to ensure today is not considered a future Monday
          const isCurrentWeek = date.getTime() === currentWeekMonday.getTime() // Comparing exact time
          const isFutureMonday = date > currentWeekMonday

          if (formattedDateKey === dateKey) {
            const installedPanels = teamDetails.panels || 0 // Set installedPanels here
            const expectedPanels = teamDataMap[teamKey][index + 1].expectedPanels

            weeklyTotals[formattedDateKey].installed += installedPanels
            weeklyTotals[formattedDateKey].expected += expectedPanels

            // Ensure opacity is only set based on future or current week
            const finalOpacity = isFutureMonday ? 0.5 : 1 // Correct opacity handling

            // Apply opacity only for future weeks (not the current week)
            teamDataMap[teamKey][index + 1] = {
              ...teamDataMap[teamKey][index + 1],
              dateKey: formattedDateKey,
              panels: installedPanels,
              cellValue: `${installedPanels} / ${expectedPanels}`,
              conditionalFormatting: {
                fontWeight: 'normal',
                width: '120px',
                backgroundColor: installedPanels >= expectedPanels ? '#28a56c' : '#c82424',
                cursor: installedPanels > 0 ? 'pointer' : 'default',
                opacity: finalOpacity, // Ensure final opacity is set correctly
              },
            }
          }
        })
      })
    }
  })

  // Step 3: Handle future weeks with no data, including "Full Week Off" and "0/100"
  mondaysInDateRange.forEach((monday: string, index: number) => {
    const date: Date = new Date(monday)
    date.setDate(date.getDate() + 1) // Ensure it's Monday
    date.setHours(0, 0, 0, 0) // Normalize to midnight

    Object.keys(teamDataMap).forEach((teamKey: string) => {
      const teamCell = teamDataMap[teamKey][index + 1]
      const expectedPanels = teamCell.expectedPanels || 100

      // Reuse the exact logic to identify current week
      const isCurrentWeek = date.getTime() === currentWeekMonday.getTime() // Exact comparison for current week

      // If the team has no panels and has the full week off
      const teamTimeOff = us_organizedTimeOff[monday]?.[teamKey]
      const fullWeekOff = teamTimeOff && teamTimeOff.days_off.length >= 5

      if (fullWeekOff) {
        teamDataMap[teamKey][index + 1] = {
          dateKey: teamCell.dateKey,
          cellValue: 'Full Week Off',
          conditionalFormatting: {
            fontWeight: 'normal',
            width: '120px',
            backgroundColor: 'inherit',
            cursor: 'default',
            opacity: date > today ? 0.5 : 1, // Only apply opacity to future weeks
          },
        }

        weeklyTotals[teamCell.dateKey].expected += 0 // Override expected panels to 0
      } else if (isCurrentWeek) {
        // Skip handling for the current week if it was already set in step 2
      } else if (date > today && (!teamCell.panels || teamCell.panels === '-')) {
        // Only add 0/expectedPanels for future weeks
        teamDataMap[teamKey][index + 1] = {
          dateKey: teamCell.dateKey,
          cellValue: `0 / ${expectedPanels}`,
          conditionalFormatting: {
            fontWeight: 'normal',
            width: '120px',
            backgroundColor: '#c82424',
            cursor: 'default',
            opacity: 0.5, // Apply opacity only for future weeks
          },
        }

        weeklyTotals[teamCell.dateKey].expected += expectedPanels
      }
    })
  })

  // Sort the teams alphabetically by name
  const sortedTeams = Object.keys(teamDataMap).sort((a, b) => {
    const teamNameA = teamDataMap[a][0].cellValue.toLowerCase()
    const teamNameB = teamDataMap[b][0].cellValue.toLowerCase()
    return teamNameA.localeCompare(teamNameB)
  })

  formattedData.data_rows = sortedTeams.map((teamKey: any) =>
    teamDataMap[teamKey].map((cell: any, index: number) => {
      if (index === 0 || cell.panels !== '-') {
        return cell
      }
      return {
        ...cell,
        cellValue: '-',
        conditionalFormatting: {
          ...cell.conditionalFormatting,
          backgroundColor: 'inherit',
          cursor: 'default',
        },
      }
    }),
  )

  // Adding the summary totals row
  // Adding the summary totals row
  const totalsRow: any[] = [{ cellValue: 'Total', conditionalFormatting: { fontWeight: 'bold', width: '200px' } }]
  mondaysInDateRange.forEach((monday: string, index: number) => {
    const date: Date = new Date(monday)
    date.setDate(date.getDate() + 1)
    date.setHours(0, 0, 0, 0) // Normalize the date to midnight

    // Only apply opacity for Mondays strictly in the future
    const isFutureMonday = date > currentWeekMonday

    let totalInstalled = 0
    let totalExpected = 0

    sortedTeams.forEach((teamKey: string) => {
      const cellValue = teamDataMap[teamKey][index + 1]?.cellValue || ''
      const [installed, expected] = cellValue.split(' / ').map(Number)

      if (!isNaN(installed)) totalInstalled += installed
      if (!isNaN(expected)) totalExpected += expected
    })

    totalsRow.push({
      cellValue: totalInstalled || totalExpected ? `${totalInstalled} / ${totalExpected}` : '-',
      conditionalFormatting: {
        fontWeight: 'normal',
        width: '120px',
        backgroundColor: totalInstalled === 0 && totalExpected === 0 ? 'inherit' : totalInstalled >= totalExpected ? '#28a56c' : '#c82424',
        opacity: isFutureMonday ? 0.5 : 1, // Apply opacity for future Mondays
      },
    })
  })

  formattedData.summary_rows.push(totalsRow)

  return formattedData
}
