import {
  AppBar,
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material'
import Grid2 from '@mui/material/Unstable_Grid2'
import React, { ReactNode, useContext, useEffect, useState } from 'react'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import { DatabaseRef_ActiveBatteries_Query } from 'rfbp_aux/services/database_endpoints/directory/batteries'
import { DatabaseRef_ActiveFinancePartners_Query } from 'rfbp_aux/services/database_endpoints/directory/finance_partners'
import { DatabaseRef_ActiveInverters_Query } from 'rfbp_aux/services/database_endpoints/directory/inverters'
import { DatabaseRef_ActiveModules_Query } from 'rfbp_aux/services/database_endpoints/directory/modules'
import { DatabaseRef_ActiveRacking_Query } from 'rfbp_aux/services/database_endpoints/directory/racking'
import { DatabaseRef_ActiveSalesPartners_Query } from 'rfbp_aux/services/database_endpoints/directory/sales_partners'
import { DatabaseRef_ActiveAdders_Query } from 'rfbp_aux/services/database_endpoints/sales/adders'
import { DatabaseRef_ActiveDiscounts_Query } from 'rfbp_aux/services/database_endpoints/sales/discounts'
import { DatabaseRef_ActiveIncentives_Query } from 'rfbp_aux/services/database_endpoints/sales/incentives'
import {
  DatabaseRef_ProposalFiles_Collection,
  DatabaseRef_ProposalFile_Document,
  DatabaseRef_Proposal_Document,
} from 'rfbp_aux/services/database_endpoints/sales/proposals'
import { DatabaseRef_ActiveSOW_Query } from 'rfbp_aux/services/database_endpoints/sales/sow'
import { StorageRef_ProposalFile } from 'rfbp_aux/services/storage_endpoints/proposals'
import { FileSystemBasic, TsInterface_FileSystemSettings } from 'rfbp_core/components/file_system/file_system_basic'
import {
  TsInterface_FormAdditionalData,
  TsInterface_FormData,
  TsInterface_FormHooksObject,
  TsInterface_FormInputs,
  TsInterface_FormSettings,
  TsInterface_FormSubmittedData,
} from 'rfbp_core/components/form'
import { Icon } from 'rfbp_core/components/icons'
import { TabsBasic } from 'rfbp_core/components/tabs'
import { rLIB } from 'rfbp_core/localization/library'
import {
  Context_RootData_ClientKey,
  Context_RootData_ClientUser,
  Context_UserInterface_CustomDialog,
  Context_UserInterface_FormDialog,
  UserInterface_Default_CustomDialogDisplayState,
} from 'rfbp_core/services/context'
import { DatabaseGetCollection, DatabaseGetLiveDocument, DatabaseSetMergeDocument } from 'rfbp_core/services/database_management'
import { getProp } from 'rfbp_core/services/helper_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject, TsType_VoidFunction } from 'rfbp_core/typescript/global_types'

interface OpportunityProposalDialogProps {
  proposalKey: string
  opportunity: TsInterface_UnspecifiedObject
}

interface AdderItem {
  name: string
  amount: number
}

interface SOWItem {
  name: string
  amount: number
}

interface IncentiveItem {
  name: string
  amount: number
}

interface DiscountItem {
  name: string
  amount: number
}

const formInputs_ProposalDetails: TsInterface_FormInputs = {
  associated_product_name: {
    key: 'associated_product_name',
    label: rLIB('Product Name'),
    input_type: 'multiple_choice_select',
    options: [
      { key: 'Solar', value: 'Solar' },
      { key: 'Solar + Storage', value: 'Solar + Storage' },
      { key: 'EV Charger', value: 'EV Charger' },
      { key: 'Standalone Storage', value: 'Standalone Storage' },
    ],
    required: true,
    data_type: 'string',
  },
  associated_proposal_origin_name: {
    key: 'associated_proposal_origin_name',
    label: rLIB('Proposal Origin'),
    input_type: 'multiple_choice_select',
    options: [
      { key: 'Aurora', value: 'Aurora' },
      { key: 'OpenSolar', value: 'OpenSolar' },
      { key: 'Solo', value: 'Solo' },
      { key: 'Merdeka', value: 'Merdeka' },
      { key: 'Energy Toolbase', value: 'Energy Toolbase' },
      { key: 'Google Solar', value: 'Google Solar' },
    ],
    required: true,
    data_type: 'string',
  },
  associated_production_model_name: {
    key: 'associated_production_model_name',
    label: rLIB('Production Model'),
    input_type: 'multiple_choice_select',
    options: [
      { key: 'PVWatts v6', value: 'PVWatts v6' },
      { key: 'PVWatts v7', value: 'PVWatts v7' },
      { key: 'PVWatts v8', value: 'PVWatts v8' },
      { key: 'Google Solar', value: 'Google Solar' },
    ],
    required: true,
    data_type: 'string',
  },
  system_size_dc: {
    key: 'system_size_dc',
    label: rLIB('System Size KWdc'),
    input_type: 'text_number',
    required: false,
    data_type: 'number',
    min: 3,
    max: 100,
    min_error_message: rLIB('System size must be greater than or equal to 3 kWdc'),
    max_error_message: rLIB('System size must be less than or equal to 100 kWdc'),
  },
  system_size_ac: {
    key: 'system_size_ac',
    label: rLIB('System Size KWac'),
    input_type: 'text_number',
    required: false,
    data_type: 'number',
    min: 2,
    max: 100,
    min_error_message: rLIB('System size must be greater than or equal to 2 kWac'),
    max_error_message: rLIB('System size must be less than or equal to 100 kWac'),
  },
  system_estimated_annual_production: {
    key: 'system_estimated_annual_production',
    label: rLIB('Estimated Annual Production'),
    input_type: 'text_number',
    required: true,
    data_type: 'number',
    min: 3000,
    max: 150000,
    min_error_message: rLIB('Estimated annual production must be greater than or equal to 3000 kWh'),
    max_error_message: rLIB('Estimated annual production must be less than or equal to 150000 kWh'),
  },
  system_usage_offset: {
    key: 'system_usage_offset',
    label: rLIB('Usage Offset (%)'),
    input_type: 'text_number',
    required: false,
    data_type: 'number',
    min: 10,
    max: 200,
    min_error_message: rLIB('Usage offset must be greater than or equal to 10%'),
    max_error_message: rLIB('Usage offset must be less than or equal to 200%'),
  },
  system_estimated_year_one_savings: {
    key: 'system_estimated_year_one_savings',
    label: rLIB('Est. Y1 Savings ($)'),
    input_type: 'text_number',
    required: false,
    data_type: 'number',
    min: -2000,
    max: 5000,
    min_error_message: rLIB('Estimated year one savings must be greater than or equal to $-2,000'),
    max_error_message: rLIB('Estimated year one savings must be less than or equal to $5,000'),
  },
  system_mount_type: {
    key: 'system_mount_type',
    label: rLIB('Mount Type'),
    input_type: 'multiple_choice_select',
    options: [
      { key: 'Roof Mount', value: 'Roof Mount' },
      { key: 'Ground Mount', value: 'Ground Mount' },
    ],
    required: true,
    data_type: 'string',
  },
  associated_financing_type: {
    key: 'associated_financing_type',
    label: rLIB('Financing Type'),
    input_type: 'multiple_choice_select',
    options: [
      { key: 'Cash', value: 'Cash' },
      { key: 'Loan', value: 'Loan' },
      { key: 'Leasing', value: 'Leasing' },
      { key: 'PPA', value: 'PPA' },
    ],
    required: false,
    data_type: 'string',
  },
  associated_sales_rep_name: {
    key: 'associated_sales_rep_name',
    label: rLIB('Sales Rep Name'),
    input_type: 'text_basic',
    required: false,
    data_type: 'string',
  },
  financials_cash_deposit: {
    key: 'financials_cash_deposit',
    label: rLIB('Cash Deposit ($)'),
    input_type: 'text_number',
    required: false,
    data_type: 'number',
  },
  financials_cash_base_price: {
    key: 'financials_cash_base_price',
    label: rLIB('Base Price ($)'),
    input_type: 'text_number',
    required: false,
    data_type: 'number',
  },
  financials_lease_downpayment: {
    key: 'financials_lease_downpayment',
    label: rLIB('Downpayment ($)'),
    input_type: 'text_number',
    required: false,
    data_type: 'number',
  },
  financials_lease_monthly_payment: {
    key: 'financials_lease_monthly_payment',
    label: rLIB('Monthly Payment ($)'),
    input_type: 'text_number',
    required: false,
    data_type: 'number',
  },
  financials_lease_solar_rate: {
    key: 'financials_lease_solar_rate',
    label: rLIB('Solar Rate ($/kWh)'),
    input_type: 'text_number',
    required: false,
    data_type: 'number',
  },
  financials_lease_escalator_rate: {
    key: 'financials_lease_escalator_rate',
    label: rLIB('Escalator (%)'),
    input_type: 'text_number',
    required: false,
    data_type: 'number',
    min: 0,
    max: 4,
    min_error_message: rLIB('Escalator rate must be greater than or equal to 0%'),
    max_error_message: rLIB('Escalator rate must be less than or equal to 4%'),
  },
  financials_lease_epc_amount: {
    key: 'financials_lease_epc_amount',
    label: rLIB('EPC Amount ($)'),
    input_type: 'text_number',
    required: false,
    data_type: 'number',
  },
  financials_loan_apr: {
    key: 'financials_loan_apr',
    label: rLIB('APR (%)'),
    input_type: 'text_number',
    required: false,
    data_type: 'number',
    min: 0,
    max: 100,
  },
  financials_loan_dealer_fee_percent: {
    key: 'financials_loan_dealer_fee_percent',
    label: rLIB('Dealer Fee (%)'),
    input_type: 'text_number',
    required: false,
    data_type: 'number',
  },
  credit_check_status: {
    key: 'credit_check_status',
    label: rLIB('Credit Check Status'),
    input_type: 'multiple_choice_select',
    options: [
      { key: 'Not Started', value: 'Not Started' },
      { key: 'Not Required', value: 'Not Required' },
      { key: 'Approved', value: 'Approved' },
      { key: 'Failed', value: 'Failed' },
    ],
    required: false,
    data_type: 'string',
  },
}

const formInputs_ProposalAssociations: TsInterface_FormInputs = {
  system_panel_model: {
    key: 'system_panel_model',
    label: rLIB('Panel Model'),
    input_type: 'multiple_choice_select',
    options: [],
    required: false,
    data_type: 'string',
  },
  system_storage_model: {
    key: 'system_storage_model',
    label: rLIB('Storage Model'),
    input_type: 'multiple_choice_select',
    options: [],
    required: false,
    data_type: 'string',
  },
  system_inverter_model: {
    key: 'system_inverter_model',
    label: rLIB('Inverter Model'),
    input_type: 'multiple_choice_select',
    options: [],
    required: false,
    data_type: 'string',
  },
  system_racking_model: {
    key: 'system_racking_model',
    label: rLIB('Racking Model'),
    input_type: 'multiple_choice_select',
    options: [],
    required: false,
    data_type: 'string',
  },
  associated_finance_partner_key: {
    key: 'associated_finance_partner_key',
    label: rLIB('Finance Partner'),
    input_type: 'multiple_choice_select',
    options: [],
    required: false,
    data_type: 'string',
  },
  associated_sales_partner_key: {
    key: 'associated_sales_partner_key',
    label: rLIB('Sales Partner'),
    input_type: 'multiple_choice_select',
    options: [],
    required: false,
    data_type: 'string',
  },
}

const ArrayCard: React.FC<{ array: any; onEdit: () => void }> = ({ array, onEdit }) => {
  return (
    <Box className="tw-mb-2">
      <Typography
        variant="subtitle1"
        sx={{ color: themeVariables.primary_main }}
      >
        {array.name || 'Unnamed Array'}
        <IconButton
          onClick={onEdit}
          size="small"
          sx={{ color: themeVariables.gray_600 }}
        >
          <Icon icon="edit" />
        </IconButton>
      </Typography>
      <Box className="tw-pl-4">
        <Typography sx={{ color: themeVariables.contrast_text }}>{`Panels: ${array.panel_quantity || 'Not set'}`}</Typography>
        <Typography sx={{ color: themeVariables.contrast_text }}>{`Azimuth: ${array.azimuth !== null ? `${array.azimuth}°` : 'Not set'}`}</Typography>
        <Typography sx={{ color: themeVariables.contrast_text }}>{`Pitch: ${array.pitch !== null ? `${array.pitch}°` : 'Not set'}`}</Typography>
        <Typography sx={{ color: themeVariables.contrast_text }}>{`Orientation: ${array.orientation || 'Not set'}`}</Typography>
        <Typography sx={{ color: themeVariables.contrast_text }}>{`Story: ${array.story || 'Not set'}`}</Typography>
      </Box>
    </Box>
  )
}

const ArrayDialog: React.FC<{
  open: boolean
  onClose: () => void
  onSave: (array: any) => void
  array: any
  isNew: boolean
}> = ({ open, onClose, onSave, array, isNew }) => {
  const [editedArray, setEditedArray] = useState(array)

  useEffect(() => {
    setEditedArray(array)
  }, [array])

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | { name?: string; value: unknown }>) => {
    const { name, value } = e.target
    let newValue = value

    if (name === 'azimuth') {
      newValue = Math.min(Math.max(Number(value), 0), 360)
    } else if (name === 'pitch') {
      newValue = Math.min(Math.max(Number(value), 0), 90)
    } else if (name === 'story') {
      newValue = Math.min(Math.max(Number(value), 0), 2)
    }

    setEditedArray({ ...editedArray, [name as string]: newValue })
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
    >
      <DialogTitle>{isNew ? 'Add New Array' : 'Edit Array'}</DialogTitle>
      <DialogContent>
        <TextField
          fullWidth
          margin="normal"
          name="name"
          label="Array Name"
          value={editedArray.name || ''}
          onChange={handleChange}
        />
        <TextField
          fullWidth
          margin="normal"
          name="panel_quantity"
          label="Panel Quantity"
          type="number"
          value={editedArray.panel_quantity || ''}
          onChange={handleChange}
          inputProps={{ min: 0 }}
        />
        <TextField
          fullWidth
          margin="normal"
          name="azimuth"
          label="Azimuth (0-360°)"
          type="number"
          value={editedArray.azimuth || ''}
          onChange={handleChange}
          inputProps={{ min: 0, max: 360 }}
        />
        <TextField
          fullWidth
          margin="normal"
          name="pitch"
          label="Pitch (0-90°)"
          type="number"
          value={editedArray.pitch || ''}
          onChange={handleChange}
          inputProps={{ min: 0, max: 90 }}
        />
        <Select
          fullWidth
          margin="dense"
          name="orientation"
          label="Orientation"
          value={editedArray.orientation || ''}
          onChange={handleChange as (event: SelectChangeEvent<any>, child: ReactNode) => void}
        >
          <MenuItem value="landscape">Landscape</MenuItem>
          <MenuItem value="portrait">Portrait</MenuItem>
        </Select>
        <TextField
          fullWidth
          margin="normal"
          name="story"
          label="Story"
          type="number"
          value={editedArray.story || ''}
          onChange={handleChange}
          inputProps={{ min: 0 }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={() => onSave(editedArray)}>Save</Button>
      </DialogActions>
    </Dialog>
  )
}

const AdderDialog: React.FC<{
  open: boolean
  onClose: () => void
  onSave: (adder: AdderItem) => void
  adder: AdderItem
  adderOptions: { key: string; value: string }[]
}> = ({ open, onClose, onSave, adder, adderOptions }) => {
  const [editedAdder, setEditedAdder] = useState(adder)

  useEffect(() => {
    setEditedAdder(adder)
  }, [adder])

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | { name?: string; value: unknown }>) => {
    const { name, value } = e.target
    setEditedAdder({ ...editedAdder, [name as string]: value })
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
    >
      <DialogTitle>{rLIB('Add Adder')}</DialogTitle>
      <DialogContent>
        <Select
          fullWidth
          margin="dense"
          name="name"
          label={rLIB('Adder')}
          value={editedAdder.name}
          onChange={handleChange as (event: SelectChangeEvent<any>, child: ReactNode) => void}
        >
          {adderOptions.map((option) => (
            <MenuItem
              key={option.key}
              value={option.value}
            >
              {option.value}
            </MenuItem>
          ))}
        </Select>
        <TextField
          fullWidth
          margin="normal"
          name="amount"
          label={rLIB('Amount ($)')}
          type="number"
          value={editedAdder.amount}
          onChange={handleChange}
          inputProps={{ min: 0 }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{rLIB('Cancel')}</Button>
        <Button onClick={() => onSave(editedAdder)}>{rLIB('Save')}</Button>
      </DialogActions>
    </Dialog>
  )
}

const SOWDialog: React.FC<{
  open: boolean
  onClose: () => void
  onSave: (sow: SOWItem) => void
  sow: SOWItem
  sowOptions: { key: string; value: string }[]
}> = ({ open, onClose, onSave, sow, sowOptions }) => {
  const [editedSOW, setEditedSOW] = useState(sow)

  useEffect(() => {
    setEditedSOW(sow)
  }, [sow])

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | { name?: string; value: unknown }>) => {
    const { name, value } = e.target
    setEditedSOW({ ...editedSOW, [name as string]: value })
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
    >
      <DialogTitle>{rLIB('Add SOW')}</DialogTitle>
      <DialogContent>
        <Select
          fullWidth
          margin="dense"
          name="name"
          label={rLIB('SOW')}
          value={editedSOW.name}
          onChange={handleChange as (event: SelectChangeEvent<any>, child: ReactNode) => void}
        >
          {sowOptions.map((option) => (
            <MenuItem
              key={option.key}
              value={option.value}
            >
              {option.value}
            </MenuItem>
          ))}
        </Select>
        <TextField
          fullWidth
          margin="normal"
          name="amount"
          label={rLIB('Amount ($)')}
          type="number"
          value={editedSOW.amount}
          onChange={handleChange}
          inputProps={{ min: 0 }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{rLIB('Cancel')}</Button>
        <Button onClick={() => onSave(editedSOW)}>{rLIB('Save')}</Button>
      </DialogActions>
    </Dialog>
  )
}

const IncentiveDialog: React.FC<{
  open: boolean
  onClose: () => void
  onSave: (incentive: IncentiveItem) => void
  incentive: IncentiveItem
  incentiveOptions: { key: string; value: string }[]
}> = ({ open, onClose, onSave, incentive, incentiveOptions }) => {
  const [editedIncentive, setEditedIncentive] = useState(incentive)

  useEffect(() => {
    setEditedIncentive(incentive)
  }, [incentive])

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | { name?: string; value: unknown }>) => {
    const { name, value } = e.target
    setEditedIncentive({ ...editedIncentive, [name as string]: value })
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
    >
      <DialogTitle>{rLIB('Add Incentive')}</DialogTitle>
      <DialogContent>
        <Select
          fullWidth
          margin="dense"
          name="name"
          label={rLIB('Incentive')}
          value={editedIncentive.name}
          onChange={handleChange as (event: SelectChangeEvent<any>, child: ReactNode) => void}
        >
          {incentiveOptions.map((option) => (
            <MenuItem
              key={option.key}
              value={option.value}
            >
              {option.value}
            </MenuItem>
          ))}
        </Select>
        <TextField
          fullWidth
          margin="normal"
          name="amount"
          label={rLIB('Amount ($)')}
          type="number"
          value={editedIncentive.amount}
          onChange={handleChange}
          inputProps={{ min: 0 }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{rLIB('Cancel')}</Button>
        <Button onClick={() => onSave(editedIncentive)}>{rLIB('Save')}</Button>
      </DialogActions>
    </Dialog>
  )
}

const DiscountDialog: React.FC<{
  open: boolean
  onClose: () => void
  onSave: (discount: DiscountItem) => void
  discount: DiscountItem
  discountOptions: { key: string; value: string }[]
}> = ({ open, onClose, onSave, discount, discountOptions }) => {
  const [editedDiscount, setEditedDiscount] = useState(discount)

  useEffect(() => {
    setEditedDiscount(discount)
  }, [discount])

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | { name?: string; value: unknown }>) => {
    const { name, value } = e.target
    setEditedDiscount({ ...editedDiscount, [name as string]: value })
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
    >
      <DialogTitle>{rLIB('Add Discount')}</DialogTitle>
      <DialogContent>
        <Select
          fullWidth
          margin="dense"
          name="name"
          label={rLIB('Discount')}
          value={editedDiscount.name}
          onChange={handleChange as (event: SelectChangeEvent<any>, child: ReactNode) => void}
        >
          {discountOptions.map((option) => (
            <MenuItem
              key={option.key}
              value={option.value}
            >
              {option.value}
            </MenuItem>
          ))}
        </Select>
        <TextField
          fullWidth
          margin="normal"
          name="amount"
          label={rLIB('Amount ($)')}
          type="number"
          value={editedDiscount.amount}
          onChange={handleChange}
          inputProps={{ min: 0 }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{rLIB('Cancel')}</Button>
        <Button onClick={() => onSave(editedDiscount)}>{rLIB('Save')}</Button>
      </DialogActions>
    </Dialog>
  )
}

export const OpportunityProposalDialog: React.FC<OpportunityProposalDialogProps> = ({ proposalKey, opportunity }) => {
  // Hooks
  const { uc_setUserInterface_CustomDialogDisplay } = useContext(Context_UserInterface_CustomDialog)
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)
  const [us_proposalData, us_setProposalData] = useState<TsInterface_UnspecifiedObject>({})
  const { uc_RootData_ClientUser } = useContext(Context_RootData_ClientUser)
  const { uc_setUserInterface_FormDialogDisplay } = useContext(Context_UserInterface_FormDialog)
  const [us_arrayDialogOpen, us_setArrayDialogOpen] = useState(false)
  const [us_currentArray, us_setCurrentArray] = useState<TsInterface_UnspecifiedObject>({})
  const [us_isNewArray, us_setIsNewArray] = useState(true)
  const [us_adderDialogOpen, us_setAdderDialogOpen] = useState(false)
  const [us_currentAdder, us_setCurrentAdder] = useState<AdderItem>({ name: '', amount: 0 })
  const [us_adderOptions, us_setAdderOptions] = useState<{ key: string; value: string }[]>([])
  const [us_sowDialogOpen, us_setSOWDialogOpen] = useState(false)
  const [us_currentSOW, us_setCurrentSOW] = useState<SOWItem>({ name: '', amount: 0 })
  const [us_sowOptions, us_setSOWOptions] = useState<{ key: string; value: string }[]>([])
  const [us_incentiveDialogOpen, us_setIncentiveDialogOpen] = useState(false)
  const [us_currentIncentive, us_setCurrentIncentive] = useState<IncentiveItem>({ name: '', amount: 0 })
  const [us_incentiveOptions, us_setIncentiveOptions] = useState<{ key: string; value: string }[]>([])
  const [us_discountDialogOpen, us_setDiscountDialogOpen] = useState(false)
  const [us_currentDiscount, us_setCurrentDiscount] = useState<DiscountItem>({ name: '', amount: 0 })
  const [us_discountOptions, us_setDiscountOptions] = useState<{ key: string; value: string }[]>([])

  //useEffect
  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setProposalData(newData)
    }

    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveDocument(DatabaseRef_Proposal_Document(res_GCK.clientKey, proposalKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.log(rej_GCK)
      })

    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, proposalKey, uc_setRootData_ClientKey])

  //functions

  const calculateProductionRatio = (): string | null => {
    const y1Production = parseFloat(us_proposalData.system_estimated_annual_production)
    const systemSizeDC = parseFloat(us_proposalData.system_size_dc)

    if (!isNaN(y1Production) && !isNaN(systemSizeDC) && systemSizeDC > 0) {
      const ratio = y1Production / systemSizeDC // Convert kW to W
      return ratio.toFixed(2)
    }
    return null
  }

  const handleAddArray = () => {
    us_setCurrentArray({
      name: null,
      panel_quantity: null,
      azimuth: null,
      pitch: null,
      orientation: null,
      story: null,
    })
    us_setIsNewArray(true)
    us_setArrayDialogOpen(true)
  }

  const handleEditArray = (array: TsInterface_UnspecifiedObject) => {
    us_setCurrentArray({ ...array })
    us_setIsNewArray(false)
    us_setArrayDialogOpen(true)
  }

  const handleSaveArray = (editedArray: any) => {
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        let updatedArrays: any[]
        if (us_isNewArray) {
          updatedArrays = [...(us_proposalData.system_panel_arrays || []), editedArray]
        } else {
          updatedArrays = us_proposalData.system_panel_arrays.map((a: any) => (a.name === editedArray.name ? editedArray : a))
        }
        DatabaseSetMergeDocument(DatabaseRef_Proposal_Document(res_GCK.clientKey, proposalKey), { system_panel_arrays: updatedArrays })
          .then(() => {
            us_setProposalData({ ...us_proposalData, system_panel_arrays: updatedArrays })
            us_setArrayDialogOpen(false)
          })
          .catch((error) => {
            console.error('Error updating arrays:', error)
          })
      })
      .catch((error) => {
        console.error('Error getting client key:', error)
      })
  }

  const handleAddAdder = () => {
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey).then((res_GCK) => {
      DatabaseGetCollection(DatabaseRef_ActiveAdders_Query(res_GCK.clientKey, 'name', true, null, {}))
        .then((res_DGC) => {
          const options = Object.entries(res_DGC.data).map(([key, value]) => ({
            key,
            value: value.name,
          }))
          us_setAdderOptions(options)
          us_setCurrentAdder({ name: '', amount: 0 })
          us_setAdderDialogOpen(true)
        })
        .catch((error) => {
          console.error('Error fetching adders:', error)
        })
    })
  }

  const handleSaveAdder = (editedAdder: AdderItem) => {
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        const updatedAdders = [...(us_proposalData.adders || []), editedAdder]
        DatabaseSetMergeDocument(DatabaseRef_Proposal_Document(res_GCK.clientKey, proposalKey), { adders: updatedAdders })
          .then(() => {
            us_setProposalData({ ...us_proposalData, adders: updatedAdders })
            us_setAdderDialogOpen(false)
          })
          .catch((error) => {
            console.error('Error updating adders:', error)
          })
      })
      .catch((error) => {
        console.error('Error getting client key:', error)
      })
  }

  const handleAddSOW = () => {
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey).then((res_GCK) => {
      DatabaseGetCollection(DatabaseRef_ActiveSOW_Query(res_GCK.clientKey, 'name', true, null, {}))
        .then((res_DGC) => {
          const options = Object.entries(res_DGC.data).map(([key, value]) => ({
            key,
            value: value.name,
          }))
          us_setSOWOptions(options)
          us_setCurrentSOW({ name: '', amount: 0 })
          us_setSOWDialogOpen(true)
        })
        .catch((error) => {
          console.error('Error fetching sow:', error)
        })
    })
  }

  const handleSaveSOW = (editedSOW: SOWItem) => {
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        const updatedSOW = [...(us_proposalData.sow || []), editedSOW]
        DatabaseSetMergeDocument(DatabaseRef_Proposal_Document(res_GCK.clientKey, proposalKey), { sow: updatedSOW })
          .then(() => {
            us_setProposalData({ ...us_proposalData, sow: updatedSOW })
            us_setSOWDialogOpen(false)
          })
          .catch((error) => {
            console.error('Error updating sow:', error)
          })
      })
      .catch((error) => {
        console.error('Error getting client key:', error)
      })
  }

  const handleAddIncentive = () => {
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey).then((res_GCK) => {
      DatabaseGetCollection(DatabaseRef_ActiveIncentives_Query(res_GCK.clientKey, 'name', true, null, {}))
        .then((res_DGC) => {
          const options = Object.entries(res_DGC.data).map(([key, value]) => ({
            key,
            value: value.name,
          }))
          us_setIncentiveOptions(options)
          us_setCurrentIncentive({ name: '', amount: 0 })
          us_setIncentiveDialogOpen(true)
        })
        .catch((error) => {
          console.error('Error fetching sow:', error)
        })
    })
  }

  const handleSaveIncentive = (editedIncentive: IncentiveItem) => {
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        const updatedIncentives = [...(us_proposalData.incentives || []), editedIncentive]
        DatabaseSetMergeDocument(DatabaseRef_Proposal_Document(res_GCK.clientKey, proposalKey), { incentives: updatedIncentives })
          .then(() => {
            us_setProposalData({ ...us_proposalData, incentives: updatedIncentives })
            us_setIncentiveDialogOpen(false)
          })
          .catch((error) => {
            console.error('Error updating sow:', error)
          })
      })
      .catch((error) => {
        console.error('Error getting client key:', error)
      })
  }

  const handleAddDiscount = () => {
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey).then((res_GCK) => {
      DatabaseGetCollection(DatabaseRef_ActiveDiscounts_Query(res_GCK.clientKey, 'name', true, null, {}))
        .then((res_DGC) => {
          const options = Object.entries(res_DGC.data).map(([key, value]) => ({
            key,
            value: value.name,
          }))
          us_setDiscountOptions(options)
          us_setCurrentDiscount({ name: '', amount: 0 })
          us_setDiscountDialogOpen(true)
        })
        .catch((error) => {
          console.error('Error fetching sow:', error)
        })
    })
  }

  const handleSaveDiscount = (editedDiscount: DiscountItem) => {
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        const updatedDiscounts = [...(us_proposalData.discounts || []), editedDiscount]
        DatabaseSetMergeDocument(DatabaseRef_Proposal_Document(res_GCK.clientKey, proposalKey), { discounts: updatedDiscounts })
          .then(() => {
            us_setProposalData({ ...us_proposalData, discounts: updatedDiscounts })
            us_setDiscountDialogOpen(false)
          })
          .catch((error) => {
            console.error('Error updating sow:', error)
          })
      })
      .catch((error) => {
        console.error('Error getting client key:', error)
      })
  }

  //JSX
  const rJSX_DetailLineItem = (
    icon: string,
    label: JSX.Element | string,
    displayKey: string,
    formKey: string,
    editIcon: (propKey: string) => JSX.Element,
    displayValue?: string,
  ): JSX.Element => {
    const value = displayValue || getProp(us_proposalData, displayKey, null)
    const editIconJSX = editIcon(formKey)
    return (
      <Box>
        <Icon
          className="tw-mr-2"
          icon={icon}
          sx={{ color: themeVariables.primary_main }}
        />
        <Typography
          className="tw-inline-block tw-mr-2"
          sx={{ color: themeVariables.primary_main }}
        >
          {label}:
        </Typography>
        {value !== null ? (
          <Typography className="tw-inline-block">{value}</Typography>
        ) : (
          <Typography
            className="tw-inline-block"
            sx={{ opacity: 0.5 }}
          >
            {rLIB('Missing')}
          </Typography>
        )}
        {editIconJSX}
      </Box>
    )
  }

  const rJSX_NotEditableLineItemIcon = (propKey: string): JSX.Element => {
    return <></>
  }

  const rJSX_SimpleEditLineItemIcon = (propKey: string): JSX.Element => {
    return (
      <Tooltip
        placement="right"
        title={rLIB('Edit')}
      >
        <Box className="tw-inline-block">
          <Icon
            className="tw-ml-2 tw-cursor-pointer tw-opacity-30 hover:tw-opacity-100"
            icon={'pen-to-square'}
            onClick={() => {
              let formInputs: TsInterface_FormInputs = {}

              if (formInputs_ProposalDetails[propKey] != null) {
                formInputs[propKey] = formInputs_ProposalDetails[propKey]
              }

              uc_setUserInterface_FormDialogDisplay({
                display: true,
                form: {
                  form: {
                    formAdditionalData: {},
                    formData: { ...us_proposalData },
                    formInputs: formInputs,
                    formOnChange: (
                      formAdditionalData: TsInterface_FormAdditionalData,
                      formData: TsInterface_FormData,
                      formInputs: TsInterface_FormInputs,
                      formSettings: TsInterface_FormSettings,
                    ) => {},
                    formSettings: {},
                    formSubmission: (
                      formSubmittedData: TsInterface_FormSubmittedData,
                      formAdditionalData: TsInterface_FormAdditionalData,
                      formHooks: TsInterface_FormHooksObject,
                    ) => {
                      return new Promise((resolve, reject) => {
                        getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey).then((res_GCK) => {
                          let updateObject = formSubmittedData
                          DatabaseSetMergeDocument(DatabaseRef_Proposal_Document(res_GCK.clientKey, proposalKey), updateObject)
                            .then((res_DAD) => {
                              resolve(res_DAD)
                            })
                            .catch((rej_DAD) => {
                              reject(rej_DAD)
                            })
                        })
                      })
                    },
                  },
                  dialog: {
                    formDialogHeaderColor: 'success',
                    formDialogHeaderText: rLIB('Edit Opportunity'),
                    formDialogIcon: (
                      <Icon
                        type="solid"
                        icon="pen-to-square"
                      />
                    ),
                  },
                },
              })
            }}
          ></Icon>
        </Box>
      </Tooltip>
    )
  }

  const rJSX_AssociationLineItemEditIcon = (propKey: string): JSX.Element => {
    return (
      <Tooltip
        placement="right"
        title={rLIB('Edit')}
      >
        <Box className="tw-inline-block">
          <Icon
            className="tw-ml-2 tw-cursor-pointer tw-opacity-30 hover:tw-opacity-100"
            icon={'pen-to-square'}
            onClick={() => {
              getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey).then((res_GCK) => {
                //get association list
                let associationOptions: TsInterface_UnspecifiedObject = {}
                let promiseArray: Promise<unknown>[] = []

                switch (propKey) {
                  case 'system_panel_model':
                    promiseArray.push(
                      DatabaseGetCollection(DatabaseRef_ActiveModules_Query(res_GCK.clientKey, 'name', true, null, {})).then((res_DGC) => {
                        associationOptions = res_DGC.data
                      }),
                    )
                    break
                  case 'system_storage_model':
                    promiseArray.push(
                      DatabaseGetCollection(DatabaseRef_ActiveBatteries_Query(res_GCK.clientKey, 'name', true, null, {})).then((res_DGC) => {
                        associationOptions = res_DGC.data
                      }),
                    )
                    break
                  case 'system_inverter_model':
                    promiseArray.push(
                      DatabaseGetCollection(DatabaseRef_ActiveInverters_Query(res_GCK.clientKey, 'name', true, null, {})).then((res_DGC) => {
                        associationOptions = res_DGC.data
                      }),
                    )
                    break
                  case 'system_racking_model':
                    promiseArray.push(
                      DatabaseGetCollection(DatabaseRef_ActiveRacking_Query(res_GCK.clientKey, 'name', true, null, {})).then((res_DGC) => {
                        associationOptions = res_DGC.data
                      }),
                    )
                    break
                  case 'associated_finance_partner_key':
                    promiseArray.push(
                      DatabaseGetCollection(DatabaseRef_ActiveFinancePartners_Query(res_GCK.clientKey, 'name', true, null, {})).then((res_DGC) => {
                        associationOptions = res_DGC.data
                      }),
                    )
                    break
                  case 'associated_sales_partner_key':
                    promiseArray.push(
                      DatabaseGetCollection(DatabaseRef_ActiveSalesPartners_Query(res_GCK.clientKey, 'name', true, null, {})).then((res_DGC) => {
                        associationOptions = res_DGC.data
                      }),
                    )
                    break
                }
                Promise.all(promiseArray).finally(() => {
                  //format options
                  let formattedOptions: { key: string; value: string }[] = []
                  for (let loopOptionKey in associationOptions) {
                    formattedOptions.push({ value: associationOptions[loopOptionKey]['name'], key: loopOptionKey })
                  }
                  //generate form inputs
                  let formInputs: TsInterface_FormInputs = {}

                  if (formInputs_ProposalAssociations[propKey] != null) {
                    formInputs[propKey] = formInputs_ProposalAssociations[propKey]
                    formInputs[propKey].options = formattedOptions
                  }

                  const currentValue = us_proposalData[propKey]
                  const currentKey = formattedOptions.find((option) => option.value === currentValue)?.key || ''

                  const formData = { ...us_proposalData, [propKey]: currentKey }
                  //open form dialog
                  uc_setUserInterface_FormDialogDisplay({
                    display: true,
                    form: {
                      form: {
                        formAdditionalData: {},
                        formData: formData,
                        formInputs: formInputs,
                        formOnChange: (
                          formAdditionalData: TsInterface_FormAdditionalData,
                          formData: TsInterface_FormData,
                          formInputs: TsInterface_FormInputs,
                          formSettings: TsInterface_FormSettings,
                        ) => {},
                        formSettings: {},
                        formSubmission: (
                          formSubmittedData: TsInterface_FormSubmittedData,
                          formAdditionalData: TsInterface_FormAdditionalData,
                          formHooks: TsInterface_FormHooksObject,
                        ) => {
                          return new Promise((resolve, reject) => {
                            getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                              .then((res_GCK) => {
                                let updateObject = formSubmittedData
                                let namePropKey = propKey.replace('_key', '_name')
                                let matchingOption = getProp(associationOptions, formSubmittedData[propKey], {})
                                let nameValue = getProp(matchingOption, 'name', null)

                                updateObject[namePropKey] = nameValue

                                DatabaseSetMergeDocument(DatabaseRef_Proposal_Document(res_GCK.clientKey, proposalKey), updateObject)
                                  .then((res_DAD) => {
                                    resolve(res_DAD)
                                  })
                                  .catch((rej_DAD) => {
                                    reject(rej_DAD)
                                  })
                              })
                              .catch((rej_GCK) => {
                                formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                                reject(rej_GCK)
                              })
                          })
                        },
                      },
                      dialog: {
                        formDialogHeaderColor: 'success',
                        formDialogHeaderText: rLIB('Edit Opportunity'),
                        formDialogIcon: (
                          <Icon
                            type="solid"
                            icon="pen-to-square"
                          />
                        ),
                      },
                    },
                  })
                })
              })
            }}
          ></Icon>
        </Box>
      </Tooltip>
    )
  }

  const rJSX_ModelAndQuantityEditIcon = (propKey: string, quantityKey: string): JSX.Element => {
    return (
      <Tooltip
        placement="right"
        title={rLIB('Edit')}
      >
        <Box className="tw-inline-block">
          <Icon
            className="tw-ml-2 tw-cursor-pointer tw-opacity-30 hover:tw-opacity-100"
            icon={'pen-to-square'}
            onClick={() => {
              getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey).then((res_GCK) => {
                //get association list
                let associationOptions: TsInterface_UnspecifiedObject = {}
                let promiseArray: Promise<unknown>[] = []

                switch (propKey) {
                  case 'system_panel_model':
                    promiseArray.push(
                      DatabaseGetCollection(DatabaseRef_ActiveModules_Query(res_GCK.clientKey, 'name', true, null, {})).then((res_DGC) => {
                        associationOptions = res_DGC.data
                      }),
                    )
                    break
                  case 'system_storage_model':
                    promiseArray.push(
                      DatabaseGetCollection(DatabaseRef_ActiveBatteries_Query(res_GCK.clientKey, 'name', true, null, {})).then((res_DGC) => {
                        associationOptions = res_DGC.data
                      }),
                    )
                    break
                  case 'system_inverter_model':
                    promiseArray.push(
                      DatabaseGetCollection(DatabaseRef_ActiveInverters_Query(res_GCK.clientKey, 'name', true, null, {})).then((res_DGC) => {
                        associationOptions = res_DGC.data
                      }),
                    )
                    break
                  case 'system_racking_model':
                    promiseArray.push(
                      DatabaseGetCollection(DatabaseRef_ActiveRacking_Query(res_GCK.clientKey, 'name', true, null, {})).then((res_DGC) => {
                        associationOptions = res_DGC.data
                      }),
                    )
                    break
                }
                Promise.all(promiseArray).finally(() => {
                  //format options
                  let formattedOptions: { key: string; value: string }[] = []
                  for (let loopOptionKey in associationOptions) {
                    formattedOptions.push({ value: associationOptions[loopOptionKey]['name'], key: loopOptionKey })
                  }
                  //generate form inputs
                  let formInputs: TsInterface_FormInputs = {}

                  if (formInputs_ProposalAssociations[propKey] != null) {
                    formInputs[propKey] = formInputs_ProposalAssociations[propKey]
                    formInputs[propKey].options = formattedOptions
                  }

                  formInputs[quantityKey] = {
                    key: quantityKey,
                    label: rLIB('Quantity'),
                    input_type: 'text_number',
                    required: true,
                    data_type: 'number',
                    min: 0,
                  }

                  const currentValue = us_proposalData[propKey]
                  const currentKey = formattedOptions.find((option) => option.value === currentValue)?.key || ''

                  const formData = { ...us_proposalData, [propKey]: currentKey }
                  //open form dialog
                  uc_setUserInterface_FormDialogDisplay({
                    display: true,
                    form: {
                      form: {
                        formAdditionalData: {},
                        formData: formData,
                        formInputs: formInputs,
                        formOnChange: (
                          formAdditionalData: TsInterface_FormAdditionalData,
                          formData: TsInterface_FormData,
                          formInputs: TsInterface_FormInputs,
                          formSettings: TsInterface_FormSettings,
                        ) => {},
                        formSettings: {},
                        formSubmission: (
                          formSubmittedData: TsInterface_FormSubmittedData,
                          formAdditionalData: TsInterface_FormAdditionalData,
                          formHooks: TsInterface_FormHooksObject,
                        ) => {
                          return new Promise((resolve, reject) => {
                            getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                              .then((res_GCK) => {
                                let updateObject = formSubmittedData
                                let namePropKey = propKey.replace('_key', '_name')
                                let matchingOption = getProp(associationOptions, formSubmittedData[propKey], {})
                                let nameValue = getProp(matchingOption, 'name', null)

                                updateObject[namePropKey] = nameValue

                                DatabaseSetMergeDocument(DatabaseRef_Proposal_Document(res_GCK.clientKey, proposalKey), updateObject)
                                  .then((res_DAD) => {
                                    resolve(res_DAD)
                                  })
                                  .catch((rej_DAD) => {
                                    reject(rej_DAD)
                                  })
                              })
                              .catch((rej_GCK) => {
                                formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                                reject(rej_GCK)
                              })
                          })
                        },
                      },
                      dialog: {
                        formDialogHeaderColor: 'success',
                        formDialogHeaderText: rLIB('Edit Opportunity'),
                        formDialogIcon: (
                          <Icon
                            type="solid"
                            icon="pen-to-square"
                          />
                        ),
                      },
                    },
                  })
                })
              })
            }}
          ></Icon>
        </Box>
      </Tooltip>
    )
  }

  const rJSX_ProposalSystemTabContent = (): JSX.Element => {
    const calculatedProductionRatio = calculateProductionRatio()
    if (calculatedProductionRatio !== null) {
      us_proposalData.calculated_production_ratio = `${calculatedProductionRatio} kWh/kWdc`
    } else {
      us_proposalData.calculated_production_ratio = null
    }

    return (
      <Box sx={{ padding: '16px' }}>
        <Grid2
          container
          spacing={2}
        >
          <Grid2
            xs={12}
            sm={6}
            md={4}
          >
            <Typography
              variant="h6"
              className="tw-opacity-50 tw-mb-0"
            >
              {rLIB('Details')}
            </Typography>
            <Card className="tw-p-2">
              {rJSX_DetailLineItem(
                'file-signature',
                rLIB('Proposal Origin'),
                'associated_proposal_origin_name',
                'associated_proposal_origin_name',
                rJSX_SimpleEditLineItemIcon,
              )}
              {rJSX_DetailLineItem('solar-panel', rLIB('Product'), 'associated_product_name', 'associated_product_name', rJSX_SimpleEditLineItemIcon)}
              {rJSX_DetailLineItem(
                'head-side-brain',
                rLIB('Production Model'),
                'associated_production_model_name',
                'associated_production_model_name',
                rJSX_SimpleEditLineItemIcon,
              )}
              {rJSX_DetailLineItem('solar-panel', rLIB('System Size (kWdc)'), 'system_size_dc', 'system_size_dc', rJSX_SimpleEditLineItemIcon)}
              {rJSX_DetailLineItem('solar-panel', rLIB('System Size (kWac)'), 'system_size_ac', 'system_size_ac', rJSX_SimpleEditLineItemIcon)}
              {rJSX_DetailLineItem(
                'clock-one',
                rLIB('Y1 Production (kWh)'),
                'system_estimated_annual_production',
                'system_estimated_annual_production',
                rJSX_SimpleEditLineItemIcon,
              )}
              {rJSX_DetailLineItem('divide', rLIB('Production Ratio'), 'calculated_production_ratio', '', rJSX_NotEditableLineItemIcon)}
              {rJSX_DetailLineItem('badge-percent', rLIB('Usage Offset (%)'), 'system_usage_offset', 'system_usage_offset', rJSX_SimpleEditLineItemIcon)}
              {rJSX_DetailLineItem(
                'badge-dollar',
                rLIB('Est. Y1 Savings ($)'),
                'system_estimated_year_one_savings',
                'system_estimated_year_one_savings',
                rJSX_SimpleEditLineItemIcon,
              )}
              {rJSX_DetailLineItem('house-signal', rLIB('Mount Type'), 'system_mount_type', 'system_mount_type', rJSX_SimpleEditLineItemIcon)}
            </Card>
          </Grid2>
          <Grid2
            xs={12}
            sm={6}
            md={4}
          >
            <Typography
              variant="h6"
              className="tw-opacity-50 tw-mb-0"
            >
              {rLIB('Equipment')}
            </Typography>
            <Card className="tw-p-2">
              {rJSX_DetailLineItem(
                'solar-panel',
                rLIB('Panel'),
                'system_panel_model',
                'system_panel_model',
                () => rJSX_ModelAndQuantityEditIcon('system_panel_model', 'system_panel_quantity'),
                us_proposalData.system_panel_model && us_proposalData.system_panel_quantity
                  ? `${us_proposalData.system_panel_quantity} x ${us_proposalData.system_panel_model}`
                  : undefined,
              )}
              {rJSX_DetailLineItem(
                'battery',
                rLIB('Storage'),
                'system_storage_model',
                'system_storage_model',
                () => rJSX_ModelAndQuantityEditIcon('system_storage_model', 'system_storage_quantity'),
                us_proposalData.system_storage_model && us_proposalData.system_storage_quantity
                  ? `${us_proposalData.system_storage_quantity} x ${us_proposalData.system_storage_model}`
                  : undefined,
              )}
              {rJSX_DetailLineItem(
                'solar-panel',
                rLIB('Inverter'),
                'system_inverter_model',
                'system_inverter_model',
                () => rJSX_ModelAndQuantityEditIcon('system_inverter_model', 'system_inverter_quantity'),
                us_proposalData.system_inverter_model && us_proposalData.system_inverter_quantity
                  ? `${us_proposalData.system_inverter_quantity} x ${us_proposalData.system_inverter_model}`
                  : undefined,
              )}
              {rJSX_DetailLineItem(
                'solar-panel',
                rLIB('Racking'),
                'system_racking_model',
                'system_racking_model',
                () => rJSX_ModelAndQuantityEditIcon('system_racking_model', 'system_racking_quantity'),
                us_proposalData.system_racking_model && us_proposalData.system_racking_quantity
                  ? `${us_proposalData.system_racking_quantity} x ${us_proposalData.system_racking_model}`
                  : undefined,
              )}
            </Card>
          </Grid2>
          <Grid2
            xs={12}
            sm={6}
            md={4}
          >
            <Typography
              variant="h6"
              className="tw-opacity-50 tw-mb-0"
            >
              {rLIB('Arrays')}
            </Typography>
            <Card className="tw-p-2">
              {us_proposalData.system_panel_arrays && us_proposalData.system_panel_arrays.length > 0 ? (
                us_proposalData.system_panel_arrays.map((array: any, index: number) => (
                  <ArrayCard
                    key={index}
                    array={array}
                    onEdit={() => handleEditArray(array)}
                  />
                ))
              ) : (
                <Typography>{rLIB('No arrays defined')}</Typography>
              )}
              <Button
                variant="outlined"
                size="small"
                startIcon={<Icon icon="plus" />}
                onClick={handleAddArray}
                className="tw-mt-2"
              >
                {rLIB('Add Array')}
              </Button>
            </Card>
          </Grid2>
        </Grid2>
        <ArrayDialog
          open={us_arrayDialogOpen}
          onClose={() => us_setArrayDialogOpen(false)}
          onSave={handleSaveArray}
          array={us_currentArray}
          isNew={us_isNewArray}
        />
      </Box>
    )
  }

  const rJSX_PricingTabContent = (): JSX.Element => {
    const financingType = us_proposalData.associated_financing_type

    return (
      <Box sx={{ padding: '16px' }}>
        <Grid2
          container
          spacing={2}
        >
          <Grid2
            xs={12}
            sm={6}
            md={4}
          >
            <Typography
              variant="h6"
              className="tw-opacity-50 tw-mb-0"
            >
              {rLIB('Details')}
            </Typography>
            <Card className="tw-p-2">
              {rJSX_DetailLineItem(
                'money-bill-transfer',
                rLIB('Financing Type'),
                'associated_financing_type',
                'associated_financing_type',
                rJSX_SimpleEditLineItemIcon,
              )}
              {rJSX_DetailLineItem(
                'building',
                rLIB('Finance Partner'),
                'associated_finance_partner_name',
                'associated_finance_partner_key',
                rJSX_AssociationLineItemEditIcon,
              )}
              {rJSX_DetailLineItem(
                'building',
                rLIB('Sales Partner'),
                'associated_sales_partner_name',
                'associated_sales_partner_name',
                rJSX_AssociationLineItemEditIcon,
              )}
              {rJSX_DetailLineItem('user', rLIB('Sales Rep'), 'associated_sales_rep_name', 'associated_sales_rep_name', rJSX_SimpleEditLineItemIcon)}
              {rJSX_DetailLineItem(
                'business-time',
                rLIB('Redline'),
                'associated_sales_partner_redline',
                'associated_sales_partner_redline',
                rJSX_NotEditableLineItemIcon,
              )}
              {rJSX_DetailLineItem(
                'circle-dollar',
                rLIB('Sales Rep Commission'),
                'associated_sales_rep_commission',
                'associated_sales_rep_commission',
                rJSX_NotEditableLineItemIcon,
              )}
              {rJSX_DetailLineItem('check', rLIB('Credit Check Status'), 'credit_check_status', 'credit_check_status', rJSX_SimpleEditLineItemIcon)}
            </Card>
          </Grid2>
          {financingType === 'Cash' && (
            <Grid2
              xs={12}
              sm={6}
              md={4}
            >
              <Typography
                variant="h6"
                className="tw-opacity-50 tw-mb-0"
              >
                {rLIB('Cash')}
              </Typography>
              <Card className="tw-p-2">
                {rJSX_DetailLineItem(
                  'money-bill-transfer',
                  rLIB('Cash Deposit ($)'),
                  'financials_cash_deposit',
                  'financials_cash_deposit',
                  rJSX_SimpleEditLineItemIcon,
                )}
                {rJSX_DetailLineItem(
                  'square-dollar',
                  rLIB('Base Price ($)'),
                  'financials_cash_base_price',
                  'financials_cash_base_price',
                  rJSX_SimpleEditLineItemIcon,
                )}
                {rJSX_DetailLineItem(
                  'money-bill-wave',
                  rLIB('Price Before Incentives ($)'),
                  'financials_cash_price_before_incentives',
                  'financials_cash_price_before_incentives',
                  rJSX_NotEditableLineItemIcon,
                )}
                {rJSX_DetailLineItem(
                  'money-bill-wave',
                  rLIB('Price After Incentives ($)'),
                  'financials_cash_price_after_incentives',
                  'financials_cash_price_after_incentives',
                  rJSX_NotEditableLineItemIcon,
                )}
                {rJSX_DetailLineItem(
                  'money-bill-wave',
                  rLIB('Price Per Watt ($/W)'),
                  'financials_cash_price_per_watt',
                  'financials_cash_price_per_watt',
                  rJSX_NotEditableLineItemIcon,
                )}
              </Card>
            </Grid2>
          )}
          {financingType === 'Loan' && (
            <Grid2
              xs={12}
              sm={6}
              md={4}
            >
              <Typography
                variant="h6"
                className="tw-opacity-50 tw-mb-0"
              >
                {rLIB('Loan')}
              </Typography>
              <Card className="tw-p-2">
                {rJSX_DetailLineItem('money-bill-transfer', rLIB('APR (%)'), 'financials_loan_apr', 'financials_loan_apr', rJSX_SimpleEditLineItemIcon)}
                {rJSX_DetailLineItem(
                  'money-bill-transfer',
                  rLIB('Dealer Fee (%)'),
                  'financials_loan_dealer_fee_percent',
                  'financials_loan_dealer_fee_percent',
                  rJSX_SimpleEditLineItemIcon,
                )}
                {rJSX_DetailLineItem(
                  'money-bill-transfer',
                  rLIB('Dealer Fee ($)'),
                  'financials_loan_dealer_fee',
                  'financials_loan_dealer_fee',
                  rJSX_NotEditableLineItemIcon,
                )}
                {rJSX_DetailLineItem(
                  'money-bill-transfer',
                  rLIB('Total Loan Amount ($)'),
                  'financials_loan_total_loan_amount',
                  'financials_loan_total_loan_amount',
                  rJSX_NotEditableLineItemIcon,
                )}
              </Card>
            </Grid2>
          )}
          {(financingType === 'Leasing' || financingType === 'PPA') && (
            <Grid2
              xs={12}
              sm={6}
              md={4}
            >
              <Typography
                variant="h6"
                className="tw-opacity-50 tw-mb-0"
              >
                {financingType === 'Leasing' ? rLIB('Lease') : rLIB('PPA')}
              </Typography>
              <Card className="tw-p-2">
                {rJSX_DetailLineItem(
                  'money-bill-transfer',
                  rLIB('Downpayment ($)'),
                  'financials_lease_down_payment',
                  'financials_lease_down_payment',
                  rJSX_SimpleEditLineItemIcon,
                )}
                {rJSX_DetailLineItem(
                  'money-bill-transfer',
                  rLIB('Monthly Payment ($)'),
                  'financials_lease_monthly_payment',
                  'financials_lease_monthly_payment',
                  rJSX_SimpleEditLineItemIcon,
                )}
                {rJSX_DetailLineItem(
                  'money-bill-transfer',
                  rLIB('Solar Rate ($/kWh)'),
                  'financials_lease_solar_rate',
                  'financials_lease_solar_rate',
                  rJSX_SimpleEditLineItemIcon,
                )}
                {rJSX_DetailLineItem(
                  'money-bill-transfer',
                  rLIB('Escalator (%)'),
                  'financials_lease_escalator_rate',
                  'financials_lease_escalator_rate',
                  rJSX_SimpleEditLineItemIcon,
                )}
                {rJSX_DetailLineItem(
                  'money-bill-transfer',
                  rLIB('EPC Amount ($)'),
                  'financials_lease_epc_amount',
                  'financials_lease_epc_amount',
                  rJSX_SimpleEditLineItemIcon,
                )}
              </Card>
            </Grid2>
          )}
          <Grid2
            xs={12}
            sm={6}
            md={4}
          >
            <Typography
              variant="h6"
              className="tw-opacity-50 tw-mb-0"
            >
              {rLIB('Adders')}
            </Typography>
            <Card className="tw-p-2">
              {us_proposalData.adders && us_proposalData.adders.length > 0 ? (
                us_proposalData.adders.map((adder: AdderItem, index: number) => (
                  <Box
                    key={index}
                    className="tw-mb-2"
                  >
                    <Typography sx={{ color: themeVariables.primary_main }}>
                      {adder.name}: ${adder.amount}
                    </Typography>
                  </Box>
                ))
              ) : (
                <Typography>{rLIB('No adders defined')}</Typography>
              )}
              <Button
                variant="outlined"
                size="small"
                startIcon={<Icon icon="plus" />}
                onClick={handleAddAdder}
                className="tw-mt-2"
              >
                {rLIB('Add Adder')}
              </Button>
            </Card>
          </Grid2>
          <Grid2
            xs={12}
            sm={6}
            md={4}
          >
            <Typography
              variant="h6"
              className="tw-opacity-50 tw-mb-0"
            >
              {rLIB('SOW')}
            </Typography>
            <Card className="tw-p-2">
              {us_proposalData.sow && us_proposalData.sow.length > 0 ? (
                us_proposalData.sow.map((sow: SOWItem, index: number) => (
                  <Box
                    key={index}
                    className="tw-mb-2"
                  >
                    <Typography sx={{ color: themeVariables.primary_main }}>
                      {sow.name}: ${sow.amount}
                    </Typography>
                  </Box>
                ))
              ) : (
                <Typography>{rLIB('No SOW defined')}</Typography>
              )}
              <Button
                variant="outlined"
                size="small"
                startIcon={<Icon icon="plus" />}
                onClick={handleAddSOW}
                className="tw-mt-2"
              >
                {rLIB('Add SOW')}
              </Button>
            </Card>
          </Grid2>
          <Grid2
            xs={12}
            sm={6}
            md={4}
          >
            <Typography
              variant="h6"
              className="tw-opacity-50 tw-mb-0"
            >
              {rLIB('Incentives')}
            </Typography>
            <Card className="tw-p-2">
              {us_proposalData.incentives && us_proposalData.incentives.length > 0 ? (
                us_proposalData.incentives.map((incentive: IncentiveItem, index: number) => (
                  <Box
                    key={index}
                    className="tw-mb-2"
                  >
                    <Typography sx={{ color: themeVariables.primary_main }}>
                      {incentive.name}: ${incentive.amount}
                    </Typography>
                  </Box>
                ))
              ) : (
                <Typography>{rLIB('No Incentive defined')}</Typography>
              )}
              <Button
                variant="outlined"
                size="small"
                startIcon={<Icon icon="plus" />}
                onClick={handleAddIncentive}
                className="tw-mt-2"
              >
                {rLIB('Add Incentive')}
              </Button>
            </Card>
          </Grid2>
          <Grid2
            xs={12}
            sm={6}
            md={4}
          >
            <Typography
              variant="h6"
              className="tw-opacity-50 tw-mb-0"
            >
              {rLIB('Discounts')}
            </Typography>
            <Card className="tw-p-2">
              {us_proposalData.discounts && us_proposalData.discounts.length > 0 ? (
                us_proposalData.discounts.map((discount: DiscountItem, index: number) => (
                  <Box
                    key={index}
                    className="tw-mb-2"
                  >
                    <Typography sx={{ color: themeVariables.primary_main }}>
                      {discount.name}: ${discount.amount}
                    </Typography>
                  </Box>
                ))
              ) : (
                <Typography>{rLIB('No Discount defined')}</Typography>
              )}
              <Button
                variant="outlined"
                size="small"
                startIcon={<Icon icon="plus" />}
                onClick={handleAddDiscount}
                className="tw-mt-2"
              >
                {rLIB('Add Discount')}
              </Button>
            </Card>
          </Grid2>
        </Grid2>
        <AdderDialog
          open={us_adderDialogOpen}
          onClose={() => us_setAdderDialogOpen(false)}
          onSave={handleSaveAdder}
          adder={us_currentAdder}
          adderOptions={us_adderOptions}
        />
        <SOWDialog
          open={us_sowDialogOpen}
          onClose={() => us_setSOWDialogOpen(false)}
          onSave={handleSaveSOW}
          sow={us_currentSOW}
          sowOptions={us_sowOptions}
        />
        <IncentiveDialog
          open={us_incentiveDialogOpen}
          onClose={() => us_setIncentiveDialogOpen(false)}
          onSave={handleSaveIncentive}
          incentive={us_currentIncentive}
          incentiveOptions={us_incentiveOptions}
        />
        <DiscountDialog
          open={us_discountDialogOpen}
          onClose={() => us_setDiscountDialogOpen(false)}
          onSave={handleSaveDiscount}
          discount={us_currentDiscount}
          discountOptions={us_discountOptions}
        />
      </Box>
    )
  }

  const rJSX_DocumentsTabContent = (): JSX.Element => {
    let fileSystemSettings: TsInterface_FileSystemSettings = {
      allow_file_archiving: false,
      allow_file_movement: false,
      allow_file_unarchiving: false,
      allow_file_uploads: false,
      allow_folder_creation: false,
      allow_folder_deletion: false,
      allow_folder_movement: false,
      allow_folder_rename: false,
      allow_link_creation: false,
      archive_filter_visible: false,
    }
    if (uc_RootData_ClientUser != null && uc_RootData_ClientUser.user_role === 'admin') {
      fileSystemSettings = {
        allow_file_archiving: true,
        allow_file_movement: true,
        allow_file_unarchiving: true,
        allow_file_uploads: true,
        allow_folder_creation: true,
        allow_folder_deletion: true,
        allow_folder_movement: true,
        allow_folder_rename: true,
        allow_link_creation: true,
        archive_filter_visible: true,
      }
    }
    return (
      <Box sx={{ padding: '16px' }}>
        <FileSystemBasic
          fileSystemCollectionDatabaseEndpoint={(clientKey) => {
            return DatabaseRef_ProposalFiles_Collection(clientKey, proposalKey)
          }}
          fileSystemDocumentDatabaseEndpoint={(clientKey, fileKey) => {
            return DatabaseRef_ProposalFile_Document(clientKey, proposalKey, fileKey)
          }}
          fileSystemStorageEndpoint={(clientKey, fileName) => {
            return StorageRef_ProposalFile(clientKey, proposalKey, fileName)
          }}
          fileSystemHardCodedFolders={{}}
          fileSystemSettings={fileSystemSettings}
        />
      </Box>
    )
  }

  const rJSX_Dialog = (): JSX.Element => {
    return (
      <Box>
        <Dialog
          className="bp_dialog_full_width"
          keepMounted
          onClose={() => {
            uc_setUserInterface_CustomDialogDisplay(UserInterface_Default_CustomDialogDisplayState)
          }}
          open={true}
        >
          <AppBar position="static">
            <Toolbar>
              <IconButton
                disabled
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                sx={{ mr: 2, color: '#fff !important' }}
              >
                <Icon icon="file-signature" />
              </IconButton>
              <Typography
                component={'span'}
                variant={'h6'}
                sx={{ flexGrow: 1 }}
              >
                {rLIB('Proposal')}: {getProp(us_proposalData, 'name', '')}
              </Typography>
            </Toolbar>
          </AppBar>
          <DialogContent sx={{ padding: '0px' }}>
            <Box>
              <TabsBasic
                tabs={[
                  { tabHeader: rLIB('System'), tabContent: <Box>{rJSX_ProposalSystemTabContent()}</Box> },
                  { tabHeader: rLIB('Pricing'), tabContent: <Box>{rJSX_PricingTabContent()}</Box> },
                  { tabHeader: rLIB('Documents'), tabContent: <Box>{rJSX_DocumentsTabContent()}</Box> },
                  { tabHeader: rLIB('Logs'), tabContent: <Box></Box> },
                ]}
                tabsSettings={{}}
              />
            </Box>
          </DialogContent>
        </Dialog>
      </Box>
    )
  }

  return rJSX_Dialog()
}
