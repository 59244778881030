//////////////////////////////////////////
//		  ooOOOO BOILERPLATE FILE		//
//		 oo		 _____					//
//		_I__n_n__||_|| ________			//
//	  >(_________|_7_|-|______|			//
//	   /o ()() ()() o   oo  oo			//
//////////////////////////////////////////

///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			Example documentation for calendars

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Box } from '@mui/material/'
import {
  CalendarMonth,
  TsInterface_CalendarAdditionalData,
  TsInterface_CalendarData,
  TsInterface_CalendarSettings,
  TsType_CalendarDateChangeCallback,
} from 'rfbp_core/components/calendar'
import { ComponentDocumentation, TsInterface_ComponentDocumentation } from 'rfbp_core/components/_example_components'

///////////////////////////////
// Typescript
///////////////////////////////

interface TsInterface_ComponentProps {}

///////////////////////////////
// Variables
///////////////////////////////

// Html strings
const CalendarMonthHtmlExample = `    <CalendarMonth
	calendarData={ calendarData_Example }
	calendarSettings={ calendarSettings_Example }
	calendarAdditionalData={ calendarAdditionalData_Example }
	calendarDateChangeCallback={ calendarDateChangeCallback_Example }
	/>`

///////////////////////////////
// Functions
///////////////////////////////

const calendarDateChangeCallback_Example: TsType_CalendarDateChangeCallback = (startDate: Date, endDate: Date) => {
  return new Promise((resolve, reject) => {
    resolve({ success: true })
  })
}

///////////////////////////////
// Component
///////////////////////////////

export const ExampleCalendars = (props: TsInterface_ComponentProps): JSX.Element => {
  // Props

  // Hooks - useContext, useState, useReducer, other
  // { sort-start } - hooks

  // { sort-end } - hooks

  // Hooks - useEffect

  // Other Variables
  const calendarData_Example: TsInterface_CalendarData = {
    ev1: {
      event_timestamp: new Date(2022, 5, 30, 12).getTime(),
      name: 'Test 1',
    },
    ev2: {
      event_timestamp: new Date(2022, 5, 30, 10).getTime(),
      name: 'Test 2',
    },
    ev3: {
      event_timestamp: new Date(2022, 4, 19, 10).getTime(),
      name: 'Test 3',
    },
  }
  const calendarSettings_Example: TsInterface_CalendarSettings = {
    show_week_number: false,
    initial_selected_date: new Date(2022, 5, 1),
  }
  const calendarAdditionalData_Example: TsInterface_CalendarAdditionalData = {}
  const documentationObject: TsInterface_ComponentDocumentation = {
    group_name: 'Calendars',
    group_description: 'Calendar components render a calendar and events passed in that fall within its date range bounds',
    components: {
      CalendarMonth: {
        component_name: (
          <Box component="span">
            <span className="tw-font-bold tw-inline-block tw-align-top tw-normal-case">CalendarMonth</span>
          </Box>
        ),
        component_description: 'The CalendarMonth component renders an entire month at a time as a traditional calendar',
        component_example_jsx_string: CalendarMonthHtmlExample,
        component_jsx: (
          <Box>
            <CalendarMonth
              calendarData={calendarData_Example}
              calendarSettings={calendarSettings_Example}
              calendarAdditionalData={calendarAdditionalData_Example}
              calendarDateChangeCallback={calendarDateChangeCallback_Example}
            />
          </Box>
        ),
        component_props: {
          calendarData: {
            propKey: 'calendarData',
            propTypeOrInterface: 'TsInterface_CalendarData',
            propDescription: 'Event data to be rendered on the calendar',
            propExampleValue: calendarData_Example,
          },
          calendarSettings: {
            propKey: 'calendarSettings',
            propTypeOrInterface: 'TsInterface_CalendarSettings',
            propDescription: 'Settings that dictate how a calendar renders',
            propExampleValue: calendarSettings_Example,
          },
          calendarAdditionalData: {
            propKey: 'calendarAdditionalData',
            propTypeOrInterface: 'TsInterface_CalendarAdditionalData',
            propDescription: 'Additional Data that can be passed in and used (need to check if this is even useful)',
            propExampleValue: calendarAdditionalData_Example,
          },
          calendarDateChangeCallback: {
            propKey: 'calendarDateChangeCallback',
            propTypeOrInterface: 'TsType_CalendarDateChangeCallback',
            propDescription:
              'Callback function that is invoked when the calendar date is chaged - use to load new event data from the database and set to calendarData in parent component / container',
            propExampleValue: 'calendarDateChangeCallback_Example fn(startDate: Date, endDate: Date)',
          },
        },
        component_todo: {
          0: 'Event Renderer',
          1: 'Click on Event Event',
          2: 'Click on Date Event',
          3: 'Might need to see how additional data can even be accessed',
        },
      },
      CalendarDay: {
        component_name: (
          <Box component="span">
            <span className="tw-font-bold tw-inline-block tw-align-top tw-normal-case">CalendarDay</span>
          </Box>
        ),
        component_description: 'In progress',
        component_example_jsx_string: '',
        component_jsx: <Box></Box>,
        component_props: {},
        component_todo: {
          0: 'BUILD',
        },
      },
      CalendarSchedule: {
        component_name: (
          <Box component="span">
            <span className="tw-font-bold tw-inline-block tw-align-top tw-normal-case">CalendarSchedule</span>
          </Box>
        ),
        component_description: 'In progress',
        component_example_jsx_string: '',
        component_jsx: <Box></Box>,
        component_props: {},
        component_todo: {
          0: 'BUILD',
        },
      },
      CalendarWeek: {
        component_name: (
          <Box component="span">
            <span className="tw-font-bold tw-inline-block tw-align-top tw-normal-case">CalendarWeek</span>
          </Box>
        ),
        component_description: 'In progress',
        component_example_jsx_string: '',
        component_jsx: <Box></Box>,
        component_props: {},
        component_todo: {
          0: 'BUILD',
        },
      },
      CalendarTwoWeeks: {
        component_name: (
          <Box component="span">
            <span className="tw-font-bold tw-inline-block tw-align-top tw-normal-case">CalendarTwoWeeks</span>
          </Box>
        ),
        component_description: 'In progress',
        component_example_jsx_string: '',
        component_jsx: <Box></Box>,
        component_props: {},
        component_todo: {
          0: 'BUILD',
        },
      },
      CalendarYear: {
        component_name: (
          <Box component="span">
            <span className="tw-font-bold tw-inline-block tw-align-top tw-normal-case">CalendarYear</span>
          </Box>
        ),
        component_description: 'In progress',
        component_example_jsx_string: '',
        component_jsx: <Box></Box>,
        component_props: {},
        component_todo: {
          0: 'BUILD',
        },
      },
    },
  }

  // Functions

  // JSX Generation
  const rJSX_Component = (): JSX.Element => {
    let componentJSX = (
      <Box>
        <ComponentDocumentation documentation={documentationObject} />
      </Box>
    )
    return componentJSX
  }

  // Render
  return <>{rJSX_Component()}</>
}
