/* eslint-disable react/prop-types */
///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Box, Card } from '@mui/material'
import { tableColumns_UserSpendingLimitsSettings, tableSettings_UserSpendingLimitsSettings } from 'app/models/users/user_table'
import { useContext, useEffect, useReducer, useState } from 'react'
import { DatabaseRef_UserSettings_Collection } from 'rfbp_aux/services/database_endpoints/directory/users'
import { DatabaseRef_SpendingLimits_Collection } from 'rfbp_aux/services/database_endpoints/finances/spending'
import { TableDatabase, TsInterface_TableAdditionalData } from 'rfbp_core/components/table'
import { Context_RootData_ClientKey, Context_RootData_ClientPermissions } from 'rfbp_core/services/context'
import {
  DatabaseGetLiveCollection,
  generateDatabaseQuery,
  TsInterface_OrderByArray,
  TsInterface_QueryCursorsObject,
  TsInterface_QueryOperatorsArray,
} from 'rfbp_core/services/database_management'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject, TsType_VoidFunction } from 'rfbp_core/typescript/global_types'

///////////////////////////////
// Typescript
///////////////////////////////

interface TabProps {
  selectedDate: Date
  setSelectedDate: any
}

///////////////////////////////
// Variables
///////////////////////////////

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// JSX Exports
///////////////////////////////

export const Tab: React.FC<TabProps> = ({ selectedDate, setSelectedDate }): JSX.Element => {
  // Props

  // Hooks - useContext, useState, useReducer, other
  // { sort-start } - hooks
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  const [us_spendingLimits, us_setSpendingLimits] = useState<TsInterface_UnspecifiedObject>({})
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)
  const { uc_RootData_ClientPermissions } = useContext(Context_RootData_ClientPermissions)
  // { sort-end } - hooks

  // Hooks - useEffect

  useEffect(() => {
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
    return () => {}
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setSpendingLimits(newData)
    }
    unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_SpendingLimits_Collection(uc_RootData_ClientKey as string), updateLiveData)

    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey])

  // Functions

  const tableDatabaseEndpoint_UserSettings = () => {
    let queryOperatorsArray: TsInterface_QueryOperatorsArray = []
    let orderByArray: TsInterface_OrderByArray = [{ prop: 'stripe_cardholder_id', desc: false }]
    let queryCursorsObject: TsInterface_QueryCursorsObject = {}
    let limit = 100
    return generateDatabaseQuery(
      DatabaseRef_UserSettings_Collection(uc_RootData_ClientKey as string),
      queryOperatorsArray,
      orderByArray,
      queryCursorsObject,
      limit,
    )
  }

  // Call Functions

  // JSX Generation

  const rJSX_Table = (): JSX.Element => {
    let tableJSX = <></>

    let tableAdditionalData_AllUsers: TsInterface_TableAdditionalData = {
      client_type: uc_RootData_ClientPermissions.client_type,
      spendingLimits: us_spendingLimits,
    }

    tableJSX = (
      <Card className="">
        <TableDatabase
          tableAdditionalData={tableAdditionalData_AllUsers}
          tableColumns={tableColumns_UserSpendingLimitsSettings}
          tableDatabaseEndpoint={tableDatabaseEndpoint_UserSettings}
          tableSettings={tableSettings_UserSpendingLimitsSettings}
        />
      </Card>
    )
    return tableJSX
  }

  const rJSX_Tab = (): JSX.Element => {
    let tabJSX = <></>
    tabJSX = (
      <Box className="tw-pt-1">
        <Box>{rJSX_Table()}</Box>
      </Box>
    )
    return tabJSX
  }

  // Render
  return <>{rJSX_Tab()}</>
}
