///////////////////////////////
// Imports
///////////////////////////////

import { collection, CollectionReference, doc, DocumentData, getFirestore, Query } from 'firebase/firestore'
import { generateDatabaseQuery, TsInterface_QueryOperatorsArray } from 'rfbp_core/services/database_management'

///////////////////////////////
// Exports
///////////////////////////////

// export const DatabaseRef_GlobalDesktopWiki_SectionActiveItems_Query = (pageKey: string, sectionKey: string): Query<unknown> => {
//   let queryOperatorsArray: TsInterface_QueryOperatorsArray = [{ prop: 'status', comparator: '==', value: 'active' }]
//   let queryCursorsObject = {}
//   return generateDatabaseQuery(
//     collection(getFirestore(), 'global', 'wiki', 'desktop', pageKey, 'sections', sectionKey, 'items'),
//     queryOperatorsArray,
//     [],
//     queryCursorsObject,
//     null,
//   )
// }

// export const DatabaseRef_GlobalDesktopWiki_SectionItems_Collection = (pageKey: string, sectionKey: string): CollectionReference<DocumentData> => {
//   return collection(getFirestore(), 'global', 'wiki', 'desktop', pageKey, 'sections', sectionKey, 'items')
// }

// export const DatabaseRef_GlobalDesktopWiki_SectionItem_Document = (pageKey: string, sectionKey: string, itemKey: string) => {
//   return doc(getFirestore(), 'global', 'wiki', 'desktop', pageKey, 'sections', sectionKey, 'items', itemKey)
// }

//new Dev Requests

export const DatabaseRef_GlobalDesktopWiki_DevRequest_Document = (itemKey: string) => {
  return doc(getFirestore(), 'global', 'wiki', 'desktop', 'dev_requests', 'main', itemKey)
}

export const DatabaseRef_GlobalDesktopWiki_DevRequests_Collection = (): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'global', 'wiki', 'desktop', 'dev_requests', 'main')
}

export const DatabaseRef_GlobalDesktopWiki_DevRequestsActiveItems_Query = (associated_page_key: string, type: string): Query<unknown> => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [
    { prop: 'status', comparator: 'in', value: ['unassigned', 'assigned'] }, // Allow for multiple statuses
    { prop: 'associated_page_key', comparator: '==', value: associated_page_key },
    { prop: 'type', comparator: '==', value: type },
  ]
  let queryCursorsObject = {}

  return generateDatabaseQuery(
    collection(getFirestore(), 'global', 'wiki', 'desktop', 'dev_requests', 'main'),
    queryOperatorsArray,
    [],
    queryCursorsObject,
    null,
  )
}

export const DatabaseRef_GlobalDesktopWiki_DevRequestsByStatus_Query = (status: string): Query<unknown> => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [
    { prop: 'status', comparator: '==', value: status }, // Allow for multiple statuses
  ]
  let queryCursorsObject = {}

  return generateDatabaseQuery(
    collection(getFirestore(), 'global', 'wiki', 'desktop', 'dev_requests', 'main'),
    queryOperatorsArray,
    [],
    queryCursorsObject,
    null,
  )
}

export const DatabaseRef_GlobalDesktopWiki_DevRequestsCompletedItems_Query = (associated_page_key: string): Query<unknown> => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [
    { prop: 'status', comparator: '==', value: 'completed' }, // Only fetch completed tasks
    { prop: 'associated_page_key', comparator: '==', value: associated_page_key },
    { prop: 'type', comparator: 'in', value: ['known_issues', 'future_improvement'] }, // Fetch both types
  ]
  let queryCursorsObject = {}

  return generateDatabaseQuery(
    collection(getFirestore(), 'global', 'wiki', 'desktop', 'dev_requests', 'main'),
    queryOperatorsArray,
    [],
    queryCursorsObject,
    null,
  )
}
