///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			containers are pages / views used in the app and are made up of components and can interact with services and models

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Box, Button, Card } from '@mui/material/'
import React, { useContext, useEffect, useReducer, useState } from 'react'
import { Trans } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { AuthenticatedContainer } from 'rfbp_aux/containers/authenticated_container'
import { ApplicationPages } from 'rfbp_aux/data/application_structure'
import { DatabaseRef_Region_Document } from 'rfbp_aux/services/database_endpoints/directory/regions'
import {
  DatabaseRef_SchedulingTeams_Document,
  DatabaseRef_TeamFiles_Collection,
  DatabaseRef_TeamFile_Document,
  DatabaseRef_TeamForumMessages_Collection,
  DatabaseRef_TeamForumMessage_Document,
  DatabaseRef_TeamForumThreads_Collection,
  DatabaseRef_TeamForumThread_Document,
  DatabaseRef_TeamLogs_Collection,
  StorageRef_TeamFile,
  StorageRef_Team_MessageThread_File,
} from 'rfbp_aux/services/database_endpoints/operations/schedules'
import { DataViewer, TsInterface_DataViewerAdditionalData } from 'rfbp_core/components/data_viewer'
import { FileSystemBasic } from 'rfbp_core/components/file_system/file_system_basic'
import { ForumBasic } from 'rfbp_core/components/forum/forum_basic'
import { Icon } from 'rfbp_core/components/icons'
import { LogTable } from 'rfbp_core/components/logs/log_table'
import { TabsUrl } from 'rfbp_core/components/tabs'
import { rLIB } from 'rfbp_core/localization/library'
import { Context_RootData_ClientKey, Context_RootData_ClientPermissions, Context_RootData_ClientUser } from 'rfbp_core/services/context'
import { DatabaseGetLiveDocument } from 'rfbp_core/services/database_management'
import { onClickAppNavigation } from 'rfbp_core/services/navigation/navigation_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject, TsType_VoidFunction } from 'rfbp_core/typescript/global_types'
import { viewerLines_Team, viewerSettings_Team } from './teams_display'

///////////////////////////////
// Variables
///////////////////////////////

// Authenticated Nav Data
const pageKey: string = ApplicationPages['AdminDatabaseTeamViewPage']['key']

// Displayed Translatable Strings

const se_TEAM = 'Team'

///////////////////////////////
// Component
///////////////////////////////

export const Container: React.FC = (): JSX.Element => {
  // Props
  const params = useParams()
  const pr_itemKey: string = params.id as string

  // Hooks - useContext, useState, useReducer, other
  const [us_viewerData_Team, us_setViewerData_Team] = useState<TsInterface_UnspecifiedObject>({})
  const [regionName, setRegionName] = useState<string>('No Region') // New state for the region name
  const un_routerNavigation = useNavigate()
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)
  const { uc_RootData_ClientPermissions } = useContext(Context_RootData_ClientPermissions)
  const { uc_RootData_ClientUser } = useContext(Context_RootData_ClientUser)

  // Hooks - useEffect
  useEffect(() => {
    if (us_viewerData_Team && us_viewerData_Team['name'] != null) {
      document.title = se_TEAM + ': ' + us_viewerData_Team['name']
    }
  }, [us_viewerData_Team])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setViewerData_Team(newData)
      ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveDocument(DatabaseRef_SchedulingTeams_Document(res_GCK.clientKey, pr_itemKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [pr_itemKey, uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction

    if (us_viewerData_Team?.associated_region_key) {
      const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
        setRegionName(newData.name || 'No Region')
        ur_forceRerender()
      }

      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          unsubscribeLiveData = DatabaseGetLiveDocument(
            DatabaseRef_Region_Document(res_GCK.clientKey, us_viewerData_Team.associated_region_key),
            updateLiveData,
          )
        })
        .catch((rej_GCK) => {
          console.error('Error fetching region data:', rej_GCK)
        })
    }

    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [pr_itemKey, uc_RootData_ClientKey, uc_setRootData_ClientKey, us_viewerData_Team.associated_region_key, ur_forceRerender])

  // Other Variables
  const viewerAdditionalData_Team: TsInterface_DataViewerAdditionalData = {
    client_type: uc_RootData_ClientPermissions.client_type,
    root_client_permissions: uc_RootData_ClientPermissions,
    teamKey: pr_itemKey,
    regionName, // Pass the fetched region name to DataViewer
  }

  // Functions

  const rJSX_FilesTab = (): JSX.Element => {
    let tabJSX = (
      <FileSystemBasic
        fileSystemCollectionDatabaseEndpoint={(clientKey) => {
          return DatabaseRef_TeamFiles_Collection(clientKey, pr_itemKey) // Use team file collection
        }}
        fileSystemDocumentDatabaseEndpoint={(clientKey, fileKey) => {
          return DatabaseRef_TeamFile_Document(clientKey, pr_itemKey, fileKey) // Use team file document
        }}
        fileSystemStorageEndpoint={(clientKey, fileName) => {
          return StorageRef_TeamFile(clientKey, pr_itemKey, fileName) // Use team file storage reference
        }}
        fileSystemHardCodedFolders={{}}
        fileSystemSettings={{
          allow_file_archiving: true,
          allow_file_movement: true,
          allow_file_unarchiving: true,
          allow_file_uploads: true,
          allow_folder_creation: true,
          allow_folder_deletion: true,
          allow_folder_movement: true,
          allow_folder_rename: true,
          archive_filter_visible: true,
        }}
      />
    )
    return tabJSX
  }

  const rJSX_LogTab = (): JSX.Element => {
    let logTab = <></>
    if (uc_RootData_ClientKey != null) {
      logTab = (
        <Card>
          <LogTable
            logType="team"
            logEndpoint={DatabaseRef_TeamLogs_Collection(uc_RootData_ClientKey as string, pr_itemKey)}
          />
        </Card>
      )
    }
    return logTab
  }

  // Notes Tab
  const rJSX_NotesTab = (): JSX.Element => {
    let tabJSX = <></>
    tabJSX = (
      <Box>
        <ForumBasic
          forumThreadCollectionDatabaseEndpoint={(clientKey) => {
            return DatabaseRef_TeamForumThreads_Collection(clientKey, pr_itemKey) // Use team thread collection
          }}
          forumThreadDocumentDatabaseEndpoint={(clientKey, threadKey) => {
            return DatabaseRef_TeamForumThread_Document(clientKey, pr_itemKey, threadKey) // Use team thread document
          }}
          forumMessageCollectionDatabaseEndpoint={(clientKey, threadKey) => {
            return DatabaseRef_TeamForumMessages_Collection(clientKey, pr_itemKey, threadKey) // Use team messages collection
          }}
          forumMessageDocumentDatabaseEndpoint={(clientKey, threadKey, messageKey) => {
            return DatabaseRef_TeamForumMessage_Document(clientKey, pr_itemKey, threadKey, messageKey) // Use team message document
          }}
          forumMessageStorageEndpoint={(clientKey, threadKey, fileName) => {
            return StorageRef_Team_MessageThread_File(clientKey, pr_itemKey, threadKey, fileName) // Use team message storage
          }}
          forumSettings={{
            allow_thread_creation: true,
            scrollbar_track_color: '#272b33',
            scrollbar_thumb_color: '#888',
            input_text_color: '#fff',
            base_message_container_offset_px: 336,
          }}
        />
      </Box>
    )
    return tabJSX
  }

  // Page JSX
  const rJSX_Page = (): JSX.Element => {
    let pageJSX = (
      <AuthenticatedContainer
        pageHeader={rLIB('View Team')}
        pageKey={pageKey}
        content={
          <Box>
            <Button
              color="inherit"
              variant="outlined"
              onClick={(event) => {
                onClickAppNavigation(event, un_routerNavigation, ApplicationPages.AdminDatabaseTeamsListPage.url())
              }}
              disableElevation
              startIcon={<Icon icon="chevron-left" />}
            >
              {rLIB('Back to all Teams')}
            </Button>

            <Box className="tw-my-0">
              <TabsUrl
                tabsSettings={{
                  baseUrl: ApplicationPages.AdminDatabaseTeamViewPage.url(pr_itemKey),
                  tabQueryParam: 'tab',
                  overridePageTitle: true,
                  basePageTitle: se_TEAM,
                }}
                tabs={[
                  {
                    tabUrlKey: 'Team',
                    tabHeader: rLIB('Team'),
                    tabContent: (
                      <Box>
                        <Card className="tw-p-4">
                          <DataViewer
                            viewerAdditionalData={viewerAdditionalData_Team}
                            viewerData={us_viewerData_Team}
                            viewerLines={viewerLines_Team}
                            viewerSettings={viewerSettings_Team}
                          />
                        </Card>
                      </Box>
                    ),
                  },

                  ...(uc_RootData_ClientKey != null
                    ? [
                        {
                          tabUrlKey: 'Notes',
                          tabHeader: <Trans>Notes</Trans>,
                          tabContent: rJSX_NotesTab(),
                        },
                      ]
                    : []),

                  ...(uc_RootData_ClientUser?.task_roles?.hr_admin
                    ? [
                        {
                          tabUrlKey: 'Files',
                          tabHeader: rLIB('Files'),
                          tabContent: rJSX_FilesTab(),
                        },
                      ]
                    : []),

                  ...(uc_RootData_ClientKey != null
                    ? [
                        {
                          tabUrlKey: 'Logs',
                          tabHeader: <Trans>Logs</Trans>,
                          tabContent: rJSX_LogTab(),
                        },
                      ]
                    : []),
                ]}
              />
            </Box>
          </Box>
        }
      />
    )

    return pageJSX
  }

  // Render
  return <>{rJSX_Page()}</>
}
