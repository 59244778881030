/* eslint-disable react/prop-types */
///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Box, Card } from '@mui/material'
import { useContext, useEffect, useReducer } from 'react'
import { Trans } from 'react-i18next'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import { ApplicationPages } from 'rfbp_aux/data/application_structure'
import { DatabaseRef_SpendingProfiles_Collection } from 'rfbp_aux/services/database_endpoints/finances/spending'
import {
  TableDatabase,
  TsInterface_TableAdditionalData,
  TsInterface_TableColumns,
  TsInterface_TableDatabaseSettings,
  TsInterface_TableDataRow,
  TsInterface_TableHooks,
} from 'rfbp_core/components/table'
import { Context_RootData_ClientKey } from 'rfbp_core/services/context'
import {
  generateDatabaseQuery,
  TsInterface_OrderByArray,
  TsInterface_QueryCursorsObject,
  TsInterface_QueryOperatorsArray,
} from 'rfbp_core/services/database_management'
import { getClientKey } from 'rfbp_core/services/user_authentication'

///////////////////////////////
// Typescript
///////////////////////////////

interface TabProps {
  selectedDate: Date
  setSelectedDate: any
}

///////////////////////////////
// Variables
///////////////////////////////

// Displayed Translatable Strings
// { sort-start } - displayed text - scoped sort plugin
const s_APPROVE: JSX.Element = <Trans>Approve</Trans>
const s_REJECT: JSX.Element = <Trans>Reject</Trans>
const s_DELETE: JSX.Element = <Trans>Delete</Trans>
const se_SpendingProfiles = 'Spending Profiles'
// { sort-end } - displayed text

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// JSX Exports
///////////////////////////////

export const Tab: React.FC<TabProps> = ({ selectedDate, setSelectedDate }): JSX.Element => {
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)

  // Hooks - useEffect
  useEffect(() => {
    document.title = se_SpendingProfiles
  }, [])

  useEffect(() => {
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
    return () => {}
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

  // Functions

  const tableDatabaseEndpoint_SpendingProfiles = () => {
    let queryOperatorsArray: TsInterface_QueryOperatorsArray = [{ prop: 'status', comparator: '!=', value: 'deleted' }]
    let orderByArray: TsInterface_OrderByArray = []
    let queryCursorsObject: TsInterface_QueryCursorsObject = {}
    let limit = 100
    return generateDatabaseQuery(
      DatabaseRef_SpendingProfiles_Collection(uc_RootData_ClientKey as string),
      queryOperatorsArray,
      orderByArray,
      queryCursorsObject,
      limit,
    )
  }

  const nameProfileColumn = {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return 'Name'
      },
      header_sort_by: 'name',
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        const cellJSX = (
          <>
            <Box
              className="tw-cursor-pointer tw-rounded-md tw-p-1 tw-text-center tw-inline-block"
              sx={{ 'background': themeVariables.background_default, '&:hover': { background: themeVariables.background_json } }}
              onClick={() => {
                if (rowData.key != null) {
                  // TODO - handle other user types probably
                  tableHooks.un_routerNavigation(ApplicationPages.AdminFinanceSpendingProfileViewPage.url(rowData.key as string))
                }
              }}
            >
              {rowData.name}
            </Box>
          </>
        )
        return cellJSX
      },
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        const cellCSS = ''
        return cellCSS
      },
    },
  }

  const tableColumns_SpendingProfiles: TsInterface_TableColumns = {
    name: nameProfileColumn,
  }

  const tableSettings_SpendingProfiles: TsInterface_TableDatabaseSettings = {
    rows_per_page: 100,
    show_header: true,
    size: 'small',
    sort_direction: 'desc',
    sort_property: 'timestamp_created',
    use_live_data: true,
    collapsible_columns: true,
    conditional_row_styles: [
      {
        className: 'tw-opacity-30 tw-line-through',
        conditional_display: {
          active: true,
          logic_type: 'comparison',
          source: 'rowData',
          prop: 'status',
          comparator: '==',
          value: 'deleted',
          conditions: [],
        },
      },
    ],
  }

  // Call Functions

  // JSX Generation

  const rJSX_Table = (): JSX.Element => {
    let tableJSX = <></>

    let tableAdditionalData_SpendingProfiles: TsInterface_TableAdditionalData = {}

    tableJSX = (
      <Card className="">
        <TableDatabase
          tableAdditionalData={tableAdditionalData_SpendingProfiles}
          tableColumns={tableColumns_SpendingProfiles}
          tableDatabaseEndpoint={tableDatabaseEndpoint_SpendingProfiles}
          tableSettings={tableSettings_SpendingProfiles}
        />
      </Card>
    )

    return tableJSX
  }

  const rJSX_Tab = (): JSX.Element => {
    let tabJSX = <></>
    tabJSX = (
      <Box className="tw-pt-1">
        <Box>{rJSX_Table()}</Box>
      </Box>
    )
    return tabJSX
  }

  // Render
  return <>{rJSX_Tab()}</>
}
