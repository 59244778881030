import { collection, CollectionReference, doc, DocumentData, DocumentReference, getFirestore, Query } from 'firebase/firestore'
import {
  generateDatabaseQuery,
  TsInterface_OrderByArray,
  TsInterface_QueryCursorsObject,
  TsInterface_QueryOperatorsArray,
} from 'rfbp_core/services/database_management'

export const DatabaseRef_Incentives_Collection = (clientKey: string): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'sales', 'incentives', 'main')
}

export const DatabaseRef_Incentive_Document = (clientKey: string, incentiveKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'sales', 'incentives', 'main', incentiveKey)
}

export const DatabaseRef_ActiveIncentives_Query = (
  clientKey: string,
  orderBy: string,
  orderDesc: boolean,
  limit: number | null,
  queryCursorsObject: TsInterface_QueryCursorsObject,
): Query<unknown> => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [{ prop: 'status', comparator: '==', value: 'active' }]
  let orderByArray: TsInterface_OrderByArray = [{ prop: orderBy, desc: orderDesc }]
  return generateDatabaseQuery(
    collection(getFirestore(), 'clients', clientKey, 'sales', 'incentives', 'main'),
    queryOperatorsArray,
    orderByArray,
    queryCursorsObject,
    limit,
  )
}
