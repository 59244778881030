///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			containers are pages / views used in the app and are made up of components and can interact with services and models

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Box, Button, Card, FormControl, MenuItem, Select, Typography } from '@mui/material/'
import { rJSX_TaskTypeSelector } from 'app/models/tasks/global_tasks'
import { useContext, useEffect, useReducer, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { AuthenticatedContainer } from 'rfbp_aux/containers/authenticated_container'
import { ApplicationPages } from 'rfbp_aux/data/application_structure'
import { DatabaseRef_ActiveRegions_Query } from 'rfbp_aux/services/database_endpoints/directory/regions'
import { DatabaseRef_ActiveSalesPartners_Query } from 'rfbp_aux/services/database_endpoints/directory/sales_partners'
import { DatabaseRef_TaskBlueprints_Collection } from 'rfbp_aux/services/database_endpoints/directory/task_blueprints'
import { DatabaseRef_TaskWorkflowProd_Document, DatabaseRef_TaskWorkflowsActive_Query } from 'rfbp_aux/services/database_endpoints/directory/task_workflows'
import { DatabaseRef_SalesPartner_InvoiceRates_Collection } from 'rfbp_aux/services/database_endpoints/finances/invoice_rates'
import { Icon } from 'rfbp_core/components/icons'
import { TableBasic, TableDatabase, TsInterface_TableAdditionalData, TsInterface_TableDatabaseEndpointQueryObject } from 'rfbp_core/components/table'
import { TabsUrl } from 'rfbp_core/components/tabs'
import { rLIB } from 'rfbp_core/localization/library'
import { Context_RootData_ClientKey } from 'rfbp_core/services/context'
import {
  DatabaseGetLiveCollection,
  DatabaseGetLiveDocument,
  generateDatabaseQuery,
  TsInterface_OrderByArray,
  TsInterface_QueryCursorsObject,
  TsInterface_QueryOperatorsArray,
} from 'rfbp_core/services/database_management'
import { dynamicSort, getProp, objectToArray } from 'rfbp_core/services/helper_functions'
import { onClickAppNavigation } from 'rfbp_core/services/navigation/navigation_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject, TsType_VoidFunction } from 'rfbp_core/typescript/global_types'
import {
  tableColumns_V1TasksInvoiceMapping,
  tableColumns_V2TasksInvoiceMapping,
  tableSettings_V1TasksInvoiceMapping,
  tableSettings_V2TasksInvoiceMapping,
} from './tables/task_invoice_mapping'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

// Authenticated Nav Data
const pageKey: string = ApplicationPages['AdminInvoiceSettingsPage']['key']

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Container
///////////////////////////////

export const Container: React.FC = (): JSX.Element => {
  // Props
  // const params = useParams()
  // const itemKey: string = params.id as string

  // Hooks - useContext, useState, useReducer, other
  const [us_invoiceRates, us_setInvoiceRates] = useState<TsInterface_UnspecifiedObject>({})
  const [us_regionKey, us_setRegionKey] = useState<string>('')
  const [us_regionsList, us_setRegionsList] = useState<TsInterface_UnspecifiedObject>({})
  const [us_salesPartners, us_setSalesPartners] = useState<TsInterface_UnspecifiedObject>({})
  // const [us_selectedSalesPartnerKey, us_setSelectedSalesPartnerKey] = useState<string>('')
  const [us_selectedSalesPartnerKey, us_setSelectedSalesPartnerKey] = useState<string>('')
  const [us_selectedTaskVersion, us_setSelectedTaskVersion] = useState<string>('v2')
  const [us_selectedV1Workflow, us_setSelectedV1Workflow] = useState<string>('')
  const [us_selectedWorkflowTasks, us_setSelectedWorkflowTasks] = useState<TsInterface_UnspecifiedObject>({})
  const [us_taskType, us_setTaskType] = useState<string>('')
  const [us_v1TaskWorkflows, us_setV1TaskWorkflows] = useState<TsInterface_UnspecifiedObject>({})
  const un_routerNavigation = useNavigate()
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)

  // Hooks - useEffect
  useEffect(() => {
    document.title = rLIB('Invoice Settings', false) as string
  }, [])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setRegionsList(newData)
      ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_ActiveRegions_Query(res_GCK.clientKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, ur_forceRerender, uc_setRootData_ClientKey])

  useEffect(() => {
    // us_setInvoiceRates
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setInvoiceRates(newData)
      ur_forceRerender()
    }
    if (us_selectedSalesPartnerKey != null && us_selectedSalesPartnerKey !== '') {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          unsubscribeLiveData = DatabaseGetLiveCollection(
            DatabaseRef_SalesPartner_InvoiceRates_Collection(res_GCK.clientKey, us_selectedSalesPartnerKey),
            updateLiveData,
          )
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    } else {
      us_setInvoiceRates({})
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, ur_forceRerender, uc_setRootData_ClientKey, us_selectedSalesPartnerKey])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      let v1Workflows: TsInterface_UnspecifiedObject = {}
      for (let loopTaskWorkflowKey in newData) {
        let loopTaskWorkflow = newData[loopTaskWorkflowKey]
        if (loopTaskWorkflow['workflow_version_number'] === 1) {
          v1Workflows[loopTaskWorkflowKey] = loopTaskWorkflow
        }
      }
      us_setV1TaskWorkflows(v1Workflows)
      ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_TaskWorkflowsActive_Query(res_GCK.clientKey, 'name', false, null, {}), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      let tasks = getProp(newData, 'tasks', {})
      let sowItems = getProp(newData, 'sow_items', {})
      us_setSelectedWorkflowTasks(Object.assign({}, tasks, sowItems))
      ur_forceRerender()
    }
    if (us_selectedV1Workflow != null && us_selectedV1Workflow !== '') {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          unsubscribeLiveData = DatabaseGetLiveDocument(DatabaseRef_TaskWorkflowProd_Document(res_GCK.clientKey, us_selectedV1Workflow), updateLiveData)
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender, us_selectedV1Workflow])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setSalesPartners(newData)
      ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_ActiveSalesPartners_Query(res_GCK.clientKey, 'name', false, null, {}), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

  // Functions
  const tableDatabaseEndpoint_Tasks = (
    queryGenerationData: TsInterface_TableDatabaseEndpointQueryObject,
    tableAdditionalData: TsInterface_TableAdditionalData,
  ) => {
    let queryOperatorsArray: TsInterface_QueryOperatorsArray = [
      { prop: 'status', comparator: '==', value: 'active' },
      { prop: 'task_type', comparator: '==', value: us_taskType },
    ]
    let orderByArray: TsInterface_OrderByArray = [{ prop: 'name', desc: false }]
    let queryCursorsObject: TsInterface_QueryCursorsObject = {}
    if (queryGenerationData['startAfter'] != null) {
      queryCursorsObject['startAfter'] = queryGenerationData.startAfter
    }
    if (queryGenerationData['startAt'] != null) {
      queryCursorsObject['startAt'] = queryGenerationData.startAt
    }
    if (queryGenerationData['endAt'] != null) {
      queryCursorsObject['endAt'] = queryGenerationData.endAt
    }
    if (queryGenerationData['endBefore'] != null) {
      queryCursorsObject['endBefore'] = queryGenerationData.endBefore
    }
    let limit = getProp(queryGenerationData, 'limit', 5)
    return generateDatabaseQuery(
      DatabaseRef_TaskBlueprints_Collection(uc_RootData_ClientKey as string),
      queryOperatorsArray,
      orderByArray,
      queryCursorsObject,
      limit,
    )
  }

  // JSX Generation
  const rJSX_BackButton = (): JSX.Element => {
    let buttonJSX = (
      <Button
        color="inherit"
        variant="outlined"
        onClick={(event) => {
          onClickAppNavigation(event, un_routerNavigation, ApplicationPages.AdminInvoiceIndexPage.url())
        }}
        disableElevation
        startIcon={<Icon icon="chevron-left" />}
        className="tw-mr-2"
      >
        {rLIB('Back to All Invoices')}
      </Button>
    )
    return buttonJSX
  }

  const rJSX_TaskVersionDropdown = (): JSX.Element => {
    // JSX
    let dropdownJSX = (
      <Box className="tw-inline-block tw-align-top tw-mr-2">
        <FormControl className="bp_thin_select_input">
          <Select
            color="primary"
            value={us_selectedTaskVersion}
            onChange={(event: any) => {
              if (event != null && event.target != null && event.target.value != null) {
                us_setSelectedTaskVersion(event.target.value)
              }
            }}
            variant="outlined"
          >
            <MenuItem
              value={'v1'}
              disabled={true}
            >
              {rLIB('Task Workflow Version')} 1
            </MenuItem>
            <MenuItem value={'v2'}>{rLIB('Task Workflow Version')} 2</MenuItem>
          </Select>
        </FormControl>
      </Box>
    )
    return dropdownJSX
  }

  const rJSX_SalesPartnerDropdown = (): JSX.Element => {
    let dropdownJSX = (
      <Box className="tw-inline-block tw-align-top tw-mr-2">
        <FormControl className="bp_thin_select_input">
          <Select
            color="primary"
            value={us_selectedSalesPartnerKey}
            onChange={(event: any) => {
              if (event != null && event.target != null && event.target.value != null) {
                us_setSelectedSalesPartnerKey(event.target.value)
              }
            }}
            variant="outlined"
          >
            {objectToArray(us_salesPartners)
              .sort(dynamicSort('name', null))
              .map((option: TsInterface_UnspecifiedObject, index: number) => (
                <MenuItem
                  key={index}
                  value={option['key']}
                >
                  {option['name']}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </Box>
    )
    return dropdownJSX
  }

  const rJSX_TaskTypeDropdown = (): JSX.Element => {
    let taskSelectorDropdownJSX = <></>
    if (us_selectedTaskVersion === 'v2') {
      taskSelectorDropdownJSX = rJSX_TaskTypeSelector(us_taskType, us_setTaskType, 'all', 'project')
    } else if (us_selectedTaskVersion === 'v1') {
      taskSelectorDropdownJSX = rJSX_WorkflowSelection()
    }
    return taskSelectorDropdownJSX
  }

  const rJSX_WorkflowSelection = (): JSX.Element => {
    let selectJSX = (
      <Box className="tw-inline-block tw-align-top">
        <FormControl className="bp_thin_select_input">
          <Select
            color="primary"
            value={us_selectedV1Workflow}
            onChange={(event: any) => {
              if (event != null && event.target != null && event.target.value != null) {
                us_setSelectedV1Workflow(event.target.value)
              }
            }}
            variant="outlined"
          >
            {objectToArray(us_v1TaskWorkflows)
              .sort(dynamicSort('name', null))
              .map((option: TsInterface_UnspecifiedObject, index: number) => (
                <MenuItem
                  key={index}
                  value={option['key']}
                >
                  {option['name']}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </Box>
    )
    return selectJSX
  }

  const rJSX_TaskMappingTab = (): JSX.Element => {
    let taskTableJSX = <></>
    if (us_selectedTaskVersion === 'v2') {
      taskTableJSX = rJSX_TaskTableV2()
    } else if (us_selectedTaskVersion === 'v1') {
      taskTableJSX = rJSX_TaskTableV1()
    }
    let tabJSX = <Box>{taskTableJSX}</Box>
    return tabJSX
  }

  const rJSX_TaskTableV2 = (): JSX.Element => {
    let tableJSX = <></>
    if (uc_RootData_ClientKey != null) {
      if (us_selectedSalesPartnerKey !== '') {
        tableJSX = (
          <Card className="">
            <TableDatabase
              tableAdditionalData={{
                editable: true,
                us_selectedSalesPartnerKey: us_selectedSalesPartnerKey,
                us_salesPartners: us_salesPartners,
                us_regionKey: us_regionKey,
                us_setRegionKey: us_setRegionKey,
                us_regionsList: us_regionsList,
                us_invoiceRates: us_invoiceRates,
              }}
              tableColumns={tableColumns_V2TasksInvoiceMapping}
              tableDatabaseEndpoint={tableDatabaseEndpoint_Tasks}
              tableSettings={tableSettings_V2TasksInvoiceMapping}
            />
          </Card>
        )
      } else {
        tableJSX = (
          <Card className="">
            <Box className="tw-p-4 tw-text-center">
              <Typography variant="h6">{rLIB('Select a Sales Partner to view tasks')}</Typography>
            </Box>
          </Card>
        )
      }
    }
    return tableJSX
  }

  const rJSX_TaskTableV1 = (): JSX.Element => {
    let tableJSX = <></>
    if (uc_RootData_ClientKey != null) {
      tableJSX = (
        <Card className="">
          <TableBasic
            tableAdditionalData={{
              editable: true,
              us_selectedSalesPartnerKey: us_selectedSalesPartnerKey,
              us_salesPartners: us_salesPartners,
              us_regionKey: us_regionKey,
              us_setRegionKey: us_setRegionKey,
              us_regionsList: us_regionsList,
              us_invoiceRates: us_invoiceRates,
            }}
            tableColumns={tableColumns_V1TasksInvoiceMapping}
            tableData={objectToArray(us_selectedWorkflowTasks)}
            tableSettings={tableSettings_V1TasksInvoiceMapping}
          />
        </Card>
      )
    }
    return tableJSX
  }

  const rJSX_Page = (): JSX.Element => {
    let pageJSX = (
      <AuthenticatedContainer
        pageHeader={rLIB('Invoice Settings')}
        pageKey={pageKey}
        content={
          <Box>
            <TabsUrl
              tabs={[
                {
                  tabUrlKey: 'Task_Mapping',
                  tabHeader: rLIB('Task Mapping'),
                  tabContent: rJSX_TaskMappingTab(),
                  tabButtons: [
                    { fullJSX: rJSX_BackButton(), minJSX: rJSX_BackButton(), sizeCutoff: 0 },
                    { fullJSX: rJSX_TaskVersionDropdown(), minJSX: rJSX_TaskVersionDropdown(), sizeCutoff: 0 },
                    { fullJSX: rJSX_SalesPartnerDropdown(), minJSX: rJSX_SalesPartnerDropdown(), sizeCutoff: 0 },
                    { fullJSX: rJSX_TaskTypeDropdown(), minJSX: rJSX_TaskTypeDropdown(), sizeCutoff: 0 },
                  ],
                },
              ]}
              tabsSettings={{
                baseUrl: ApplicationPages.AdminInvoiceSettingsPage.url(),
                tabQueryParam: 'tab',
                overridePageTitle: true,
                basePageTitle: rLIB('Invoice Settings', false) as string,
              }}
            />
          </Box>
        }
      />
    )
    return pageJSX
  }

  // Render
  return <>{rJSX_Page()}</>
}
