///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			containers are pages / views used in the app and are made up of components and can interact with services and models

		TODO:
			associated_sales_access - updated either here or whenever createOrUpdateProjectFromLeadUpdate is called on server
	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Box, Button, FormControl, MenuItem, Select, Typography } from '@mui/material/'
import { useContext, useEffect, useReducer, useState } from 'react'
import { Trans } from 'react-i18next'
import { useCSVReader } from 'react-papaparse'
import { useNavigate } from 'react-router-dom'
import { AuthenticatedContainer } from 'rfbp_aux/containers/authenticated_container'
import { ApplicationPages } from 'rfbp_aux/data/application_structure'
import {
  DatabaseRef_SalesHierarchyUser_Collection,
  DatabaseRef_SalesHierarchyUser_Document,
} from 'rfbp_aux/services/database_endpoints/directory/sales_hierarchies'
import { DatabaseRef_SalesPartner_Collection } from 'rfbp_aux/services/database_endpoints/directory/sales_partners'
import { DatabaseRef_ActiveProjects_Query, DatabaseRef_Project_Document } from 'rfbp_aux/services/database_endpoints/operations/projects'
import { Json } from 'rfbp_core/components/code_display'
import { Icon } from 'rfbp_core/components/icons'
import { rLIB } from 'rfbp_core/localization/library'
import { Context_RootData_ClientKey, Context_UserInterface_AlertDialog, Context_UserInterface_ErrorDialog } from 'rfbp_core/services/context'
import {
  DatabaseBatchUpdate,
  DatabaseGetCollection,
  DatabaseGetLiveCollection,
  TsInterface_DatabaseBatchUpdatesArray,
} from 'rfbp_core/services/database_management'
import { downloadCSV, generateRandomString, objectToArray } from 'rfbp_core/services/helper_functions'
import { onClickAppNavigation } from 'rfbp_core/services/navigation/navigation_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject, TsType_UnknownPromise, TsType_VoidFunction } from 'rfbp_core/typescript/global_types'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

// Authenticated Nav Data
const pageKey: string = ApplicationPages['SalesHierarchyImportPage']['key']

// Displayed Translatable Strings
// { sort-start } - displayed text - scoped sort plugin
const s_DOWNLOAD_EXAMPLE: JSX.Element = <Trans>Download Example</Trans>
const s_FAILURES: JSX.Element = <Trans>Failures</Trans>
const s_IMPORT_FILE: JSX.Element = <Trans>Import File</Trans>
const s_SALES_HIERARCHY_IMPORT: JSX.Element = <Trans>Sales Hierarchy Import</Trans>
const s_SALES_HIERARCHY_UPLOAD_COMPLETED: JSX.Element = <Trans>Sales Hierarchy Upload Completed</Trans>
const s_SELECT_FILE: JSX.Element = <Trans>Select File</Trans>
const s_SUCCESSES: JSX.Element = <Trans>Successes</Trans>
const s_UPDATE_PROJECT_ACCESS: JSX.Element = <Trans>Update Project Access</Trans>
const se_SALES_HIERARCHY_IMPORT = 'Sales Hierarchy Import'
// { sort-end } - displayed text

// Example Data
const exampleRawData = [
  { user: 'James', userEmail: 'james@email.com', manager: 'Michael', managerEmail: 'michael@email.com' },
  { user: 'James', userEmail: 'james@email.com', manager: 'David', managerEmail: 'david@email.com' },
  { user: 'James', userEmail: 'james@email.com', manager: 'William', managerEmail: 'william@email.com' },
  { user: 'Robert', userEmail: 'robert@email.com', manager: 'David', managerEmail: 'david@email.com' },
  { user: 'Robert', userEmail: 'robert@email.com', manager: 'William', managerEmail: 'william@email.com' },
  { user: 'John', userEmail: 'john@email.com', manager: 'Michael', managerEmail: 'michael@email.com' },
  { user: 'John', userEmail: 'john@email.com', manager: 'William', managerEmail: 'william@email.com' },
  { user: 'Michael', userEmail: 'michael@email.com', manager: 'Richard', managerEmail: 'richard@email.com' },
  { user: 'Michael', userEmail: 'michael@email.com', manager: 'Joseph', managerEmail: 'joseph@email.com' },
  { user: 'David', userEmail: 'david@email.com', manager: 'Richard', managerEmail: 'richard@email.com' },
  { user: 'William', userEmail: 'william@email.com', manager: 'Thomas', managerEmail: 'thomas@email.com' },
  { user: 'Richard', userEmail: 'richard@email.com', manager: 'Thomas', managerEmail: 'thomas@email.com' },
  { user: 'Joseph', userEmail: 'joseph@email.com', manager: 'Charles', managerEmail: 'charles@email.com' },
  { user: 'Thomas', userEmail: 'thomas@email.com', manager: 'Charles', managerEmail: 'charles@email.com' },
]

///////////////////////////////
// Functions
///////////////////////////////

// Helper Functions
const generateUserHierarchy = (data: TsInterface_UnspecifiedObject[], importSessionKey: string) => {
  const hierarchy: TsInterface_UnspecifiedObject = {}
  const visited = new Set()
  const getAllManagers = (userEmail: unknown, maxDepth = 100) => {
    if (maxDepth <= 0 || visited.has(userEmail)) {
      return [userEmail]
    }
    visited.add(userEmail)
    const userManagers = data.filter((item) => item.userEmail === userEmail)
    let managerEmails = userManagers.map((item) => item.managerEmail)
    managerEmails.forEach((managerEmail) => {
      managerEmails = [...managerEmails, ...getAllManagers(managerEmail, maxDepth - 1)]
    })
    // @ts-expect-error - TODO: reason for error
    return [...new Set([userEmail, ...managerEmails])]
  }
  data.forEach(({ user, userEmail, manager, managerEmail }) => {
    if (!hierarchy[userEmail]) {
      hierarchy[userEmail] = {
        email: userEmail.toLowerCase(),
        name: user,
        direct_manager_emails: [userEmail],
        all_upstream_manager_emails: [],
        associated_import_session_key: importSessionKey,
        timestamp_updated: new Date(),
      }
    }
    hierarchy[userEmail].direct_manager_emails.push(managerEmail)
    visited.clear()
    hierarchy[userEmail].all_upstream_manager_emails = getAllManagers(userEmail)
    if (!hierarchy[managerEmail]) {
      hierarchy[managerEmail] = {
        email: managerEmail.toLowerCase(),
        name: manager,
        direct_manager_emails: [managerEmail],
        all_upstream_manager_emails: [],
        associated_import_session_key: importSessionKey,
        timestamp_updated: new Date(),
      }
    }
  })
  Object.values(hierarchy).forEach((userObj) => {
    // @ts-expect-error - TODO: reason for error
    userObj.direct_manager_emails = [...new Set(userObj.direct_manager_emails)]
  })
  return hierarchy
}

///////////////////////////////
// Container
///////////////////////////////

export const Container: React.FC = (): JSX.Element => {
  // Props

  // Hooks - useContext, useState, useReducer, other
  const [us_fileUploadData, us_setFileUploadData] = useState<TsInterface_UnspecifiedObject>({})
  const [us_fileUploaded, us_setFileUploaded] = useState<boolean>(false)
  const [us_fileUploading, us_setFileUploading] = useState<boolean>(false)
  const [us_parsingData, us_setParsingData] = useState<boolean>(false)
  const [us_salesPartnersList, us_setSalesPartnersList] = useState<TsInterface_UnspecifiedObject>({})
  const [us_selectedSalesPartner, us_setSelectedSalesPartner] = useState<string>('')
  const [us_updatingProjectAccess, us_setUpdatingProjectAccess] = useState<boolean>(false)
  const un_routerNavigation = useNavigate()
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  const { CSVReader } = useCSVReader()
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)
  const { uc_setUserInterface_AlertDialogDisplay } = useContext(Context_UserInterface_AlertDialog)
  const { uc_setUserInterface_ErrorDialogDisplay } = useContext(Context_UserInterface_ErrorDialog)

  // Hooks - useEffect

  useEffect(() => {
    document.title = se_SALES_HIERARCHY_IMPORT
  }, [])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setSalesPartnersList(newData)
      ur_forceRerender()
    }
    if (uc_RootData_ClientKey != null) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_SalesPartner_Collection(res_GCK.clientKey), updateLiveData)
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    } else {
      us_setSalesPartnersList({})
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, ur_forceRerender, uc_setRootData_ClientKey])

  // Functions
  const downloadExampleExportFile = () => {
    let exampleDownloadData = [['user', 'userEmail', 'manager', 'managerEmail']]
    for (let rowIndex in exampleRawData) {
      let row = [
        exampleRawData[rowIndex]['user'],
        exampleRawData[rowIndex]['userEmail'],
        exampleRawData[rowIndex]['manager'],
        exampleRawData[rowIndex]['managerEmail'],
      ]
      exampleDownloadData.push(row)
    }
    downloadCSV('Example Import', exampleDownloadData)
  }

  const handleFileSelection = (data: any): void => {
    // Instantiate variables
    let importSessionKey = new Date().getTime() + '_' + generateRandomString(6, null)
    let unformattedData: TsInterface_UnspecifiedObject[] = []
    let uploadedFileColumns: string[] = []
    us_setParsingData(true)
    // let dataMapping: string[] = []
    // Loop through and format data
    for (let cellIndex = 0; cellIndex < data[0].length; cellIndex++) {
      uploadedFileColumns[cellIndex] = data[0][cellIndex]
    }
    for (let rowIndex = 1; rowIndex < data.length; rowIndex++) {
      if (data != null && data[rowIndex] != null) {
        let loopRow = data[rowIndex]
        let unformattedDataRow: TsInterface_UnspecifiedObject = {}
        for (let cellIndex = 0; cellIndex < loopRow.length; cellIndex++) {
          if (loopRow[cellIndex] != null && uploadedFileColumns[cellIndex] != null && uploadedFileColumns[cellIndex] !== '') {
            unformattedDataRow[uploadedFileColumns[cellIndex]] = loopRow[cellIndex]
          }
        }
        if (unformattedDataRow.user != null && unformattedDataRow.user !== '') {
          unformattedData.push(unformattedDataRow)
        }
      }
    }
    // let formattedData = buildHierarchy( unformattedData, importSessionKey )
    let formattedData = generateUserHierarchy(unformattedData, importSessionKey)
    // Set to state
    us_setFileUploadData(formattedData)
    us_setParsingData(false)
  }

  const importFormattedData = (): void => {
    us_setFileUploading(true)
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        let successCount = 0
        let failureCount = 0
        let updateArray: TsInterface_DatabaseBatchUpdatesArray = []
        for (let loopUserKey in us_fileUploadData) {
          let loopUser = us_fileUploadData[loopUserKey]
          successCount++
          updateArray.push({
            type: 'setOverwrite',
            ref: DatabaseRef_SalesHierarchyUser_Document(res_GCK.clientKey, us_selectedSalesPartner, loopUser.email.toLowerCase()),
            data: loopUser,
          })
        }
        DatabaseBatchUpdate(updateArray)
          .then((res_DBU) => {
            us_setFileUploaded(true)
            us_setFileUploading(false)
            uc_setUserInterface_AlertDialogDisplay({
              display: true,
              alert: {
                color: 'info',
                header: s_SALES_HIERARCHY_UPLOAD_COMPLETED,
                icon: <Icon icon="upload" />,
                text: (
                  <Box>
                    <Box>
                      <Typography variant="body1">
                        {s_SUCCESSES}: {successCount}
                      </Typography>
                      <Typography variant="body1">
                        {s_FAILURES}: {failureCount}
                      </Typography>
                    </Box>
                    <Box></Box>
                  </Box>
                ),
              },
            })
          })
          .catch((rej_DBU) => {
            console.error(rej_DBU)
            us_setFileUploading(false)
            uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DBU.error })
          })
      })
      .catch((rej_GCK) => {
        us_setFileUploading(false)
        uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
      })
  }

  const updateProjectAccess = (): void => {
    us_setUpdatingProjectAccess(true)
    ur_forceRerender()
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        let promiseArray: TsType_UnknownPromise[] = []
        let salesUsers: TsInterface_UnspecifiedObject = {}
        let activeProjects: TsInterface_UnspecifiedObject = {}
        // Get Sales Heirarchy
        promiseArray.push(
          DatabaseGetCollection(DatabaseRef_SalesHierarchyUser_Collection(res_GCK.clientKey, us_selectedSalesPartner))
            .then((res_DGC) => {
              salesUsers = res_DGC.data
            })
            .catch((rej_DGC) => {
              console.error(rej_DGC)
            }),
        )
        // Get Active Projects
        promiseArray.push(
          DatabaseGetCollection(DatabaseRef_ActiveProjects_Query(res_GCK.clientKey))
            .then((res_DGC) => {
              activeProjects = res_DGC.data
            })
            .catch((rej_DGC) => {
              console.error(rej_DGC)
            }),
        )
        // After Data Loaded
        Promise.all(promiseArray).finally(() => {
          let updateArray: TsInterface_DatabaseBatchUpdatesArray = []
          // Loop through projects
          for (let loopProjectKey in activeProjects) {
            let loopProject = activeProjects[loopProjectKey]
            // If a project has an associated_sales_rep_email and that email exists in the hierarchy, update that project with manager email array
            if (loopProject != null && loopProject.associated_sales_rep_email != null) {
              let allManagersArray: string[] = []
              let directManagersArray: string[] = []
              if (
                salesUsers != null &&
                salesUsers[loopProject.associated_sales_rep_email] != null &&
                salesUsers[loopProject.associated_sales_rep_email]['all_upstream_manager_emails'] != null
              ) {
                allManagersArray = salesUsers[loopProject.associated_sales_rep_email]['all_upstream_manager_emails']
              }
              if (
                salesUsers != null &&
                salesUsers[loopProject.associated_sales_rep_email] != null &&
                salesUsers[loopProject.associated_sales_rep_email]['direct_manager_emails'] != null
              ) {
                directManagersArray = salesUsers[loopProject.associated_sales_rep_email]['direct_manager_emails']
              }
              updateArray.push({
                type: 'setMerge',
                ref: DatabaseRef_Project_Document(res_GCK.clientKey, loopProject.key),
                data: {
                  associated_sales_access_all_managers: allManagersArray,
                  associated_sales_access_direct_managers: directManagersArray,
                },
              })
            }
          }
          DatabaseBatchUpdate(updateArray)
            .then((res_DBU) => {
              us_setUpdatingProjectAccess(false)
              ur_forceRerender()
            })
            .catch((rej_DBU) => {
              us_setUpdatingProjectAccess(false)
              ur_forceRerender()
              uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DBU.error })
            })
        })
      })
      .catch((rej_GCK) => {
        us_setUpdatingProjectAccess(false)
        ur_forceRerender()
        uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
      })
  }

  // JSX Generation
  const rJSX_Page = (): JSX.Element => {
    let pageJSX = (
      <AuthenticatedContainer
        pageHeader={s_SALES_HIERARCHY_IMPORT}
        pageKey={pageKey}
        content={
          <Box>
            <Box>
              <Button
                color="inherit"
                variant="outlined"
                onClick={(event) => {
                  onClickAppNavigation(event, un_routerNavigation, ApplicationPages.AdminDatabaseSalesPartnersListPage.url())
                }}
                disableElevation
                startIcon={<Icon icon="chevron-left" />}
                className="tw-mr-2 tw-mb-2"
              >
                {rLIB('Back to Sales Partner List')}
              </Button>
              <Button
                color="info"
                variant="contained"
                onClick={() => {
                  downloadExampleExportFile()
                }}
                disableElevation
                startIcon={<Icon icon="cloud-arrow-down" />}
                className="tw-mr-2 tw-mb-2"
                disabled={us_parsingData === true}
              >
                {s_DOWNLOAD_EXAMPLE}
              </Button>
              <CSVReader
                // https://react-papaparse.js.org/docs#config
                // https://github.com/Bunlong/react-papaparse/blob/v4.0.0/examples/CSVReaderBasicUpload.tsx
                onUploadAccepted={(results: any) => {
                  handleFileSelection(results.data)
                }}
                noDrag
              >
                {({ getRootProps, acceptedFile, ProgressBar, getRemoveFileProps }: any) => (
                  <Button
                    color="success"
                    variant="contained"
                    disableElevation
                    disabled={us_parsingData === true || objectToArray(us_fileUploadData).length > 0}
                    startIcon={<Icon icon="upload" />}
                    className="tw-mr-2 tw-mb-2"
                    {...getRootProps()}
                  >
                    {s_SELECT_FILE}
                  </Button>
                )}
              </CSVReader>
              <FormControl className="bp_thin_select_input tw-mr-2">
                <Select
                  color="primary"
                  value={us_selectedSalesPartner}
                  disabled={false}
                  onChange={(event: any) => {
                    if (event != null && event.target != null && event.target.value != null) {
                      us_setSelectedSalesPartner(event.target.value)
                    }
                  }}
                  variant="outlined"
                >
                  {objectToArray(us_salesPartnersList).map((option: TsInterface_UnspecifiedObject, index: number) => (
                    <MenuItem
                      key={index}
                      value={option['key']}
                      disabled={option['disabled'] === true}
                    >
                      {option['name']}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Button
                color="success"
                variant="contained"
                onClick={() => {
                  importFormattedData()
                }}
                disableElevation
                startIcon={<Icon icon="cloud-arrow-up" />}
                className="tw-mr-2 tw-mb-2"
                disabled={
                  us_parsingData === true ||
                  objectToArray(us_fileUploadData).length < 1 ||
                  us_fileUploaded === true ||
                  us_fileUploading === true ||
                  us_selectedSalesPartner === ''
                }
              >
                {s_IMPORT_FILE}
              </Button>
              <Button
                color="warning"
                variant="contained"
                onClick={() => {
                  updateProjectAccess()
                }}
                disableElevation
                startIcon={
                  us_updatingProjectAccess ? (
                    <Icon
                      icon="arrows-rotate"
                      className="bp_spin"
                    />
                  ) : (
                    <Icon icon="wand-magic-sparkles" />
                  )
                }
                className="tw-mr-2 tw-mb-2"
                disabled={us_selectedSalesPartner === '' || us_updatingProjectAccess === true}
              >
                {s_UPDATE_PROJECT_ACCESS}
              </Button>
              <Box
                className="tw-py-1.5 tw-opacity-30 tw-px-2 tw-inline-block tw-align-top"
                sx={{
                  border: '1px solid rgba(255,255,255,0.6)',
                  borderRadius: '5px',
                }}
              >
                {uc_RootData_ClientKey}
              </Box>
            </Box>
            <Box>
              <Json data={us_fileUploadData} />
            </Box>
          </Box>
        }
      />
    )
    return pageJSX
  }

  // Render
  return <>{rJSX_Page()}</>
}
