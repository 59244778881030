/* eslint-disable no-case-declarations */
///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			containers are pages / views used in the app and are made up of components and can interact with services and models

		TODO:
			Not being used except for json data exports

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Accordion, AccordionDetails, AccordionSummary, Avatar, Box, Button, Card, Chip, Divider, Stack, Tooltip, Typography } from '@mui/material/'
import Grid2 from '@mui/material/Unstable_Grid2'
import { availableTeamRoles } from 'app/pages/scheduler/task_dispatch_index'
import React, { useContext, useEffect, useReducer, useState } from 'react'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import { AuthenticatedContainer } from 'rfbp_aux/containers/authenticated_container'
import { ApplicationPages } from 'rfbp_aux/data/application_structure'
import { DatabaseRef_TaskBlueprintConfig_Document } from 'rfbp_aux/services/database_endpoints/directory/task_blueprints'
import { DatabaseRef_InternalUsers_Query } from 'rfbp_aux/services/database_endpoints/directory/users'
import {
  DatabaseRef_FinalizedPayroll_EmployeeFullPayrollData_Document,
  DatabaseRef_FinalizedPayroll_UnitPayTasks_Collection,
} from 'rfbp_aux/services/database_endpoints/finances/finalized_payroll'
import { DatabaseRef_LeaderboardDataForSpecificDateKey_Query } from 'rfbp_aux/services/database_endpoints/finances/leaderboards'
import { DatabaseRef_LifetimePanels_Collection } from 'rfbp_aux/services/database_endpoints/finances/lifetime_panels'
import { DatabaseRef_LifetimeUnits_Collection } from 'rfbp_aux/services/database_endpoints/finances/lifetime_units'
import { DatabaseRef_FeedbackMissingAssociatedUser_Query, DatabaseRef_Feedback_Query } from 'rfbp_aux/services/database_endpoints/operations/feedback'
import { DatabaseRef_TaskByProjectAndBlueprintKey_Query, DatabaseRef_TasksByTypeAndRange_Query } from 'rfbp_aux/services/database_endpoints/operations/tasks'
import { stringToColor } from 'rfbp_core/components/chat'
import { DatePicker } from 'rfbp_core/components/date_picker/date_picker'
import { FeedbackNpsGraph, TsInterface_FeedbackData, TsInterface_FeedbackGraphSettings } from 'rfbp_core/components/feedback'
import { Icon } from 'rfbp_core/components/icons'
import { TableBasic, TableCellBasic, TsInterface_TableColumns, TsInterface_TableDatabaseSettings } from 'rfbp_core/components/table'
import { TabsBasic, TabsUrl } from 'rfbp_core/components/tabs'
import { Context_RootData_ClientKey } from 'rfbp_core/services/context'
import { DatabaseGetCollection, DatabaseGetDocument, DatabaseGetLiveCollection } from 'rfbp_core/services/database_management'
import { dynamicSort, formatCurrency, getProp, objectToArray } from 'rfbp_core/services/helper_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject, TsType_UnknownPromise, TsType_VoidFunction } from 'rfbp_core/typescript/global_types'
import { rLIB } from '../../../rfbp_core/localization/library'
import { getLastChronologicalDate } from '../payroll/data/payroll_calculations'
import { FeedbackCycleTimeGraph } from './cycle_time_graph'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

// Authenticated Nav Data
const pageKey: string = ApplicationPages['AdminDatabaseLeaderboardIndexPage']['key']

///////////////////////////////
// Functions
///////////////////////////////

export const getMondaysInDateRange = (startDate: Date, endDate: Date) => {
  const mondays = []
  let currentDate = new Date(startDate)
  currentDate.setDate(currentDate.getDate() + ((1 - currentDate.getDay() + 7) % 7))
  while (currentDate <= endDate) {
    mondays.push(currentDate.toISOString().slice(0, 10))
    currentDate.setDate(currentDate.getDate() + 7)
  }
  return mondays
}

export const generateLeaderboardTableData = (
  us_rawLeaderboardData: TsInterface_UnspecifiedObject,
  us_setLeaderboardTableData: any,
  us_setMaxLeaderboardValues: any,
) => {
  let leaderboardTableData: TsInterface_UnspecifiedObject = {}
  for (let loopDateUserKey in us_rawLeaderboardData) {
    let loopUser = us_rawLeaderboardData[loopDateUserKey]
    if (loopUser != null && loopUser.associated_user_key != null) {
      if (leaderboardTableData[loopUser.associated_user_key] == null) {
        leaderboardTableData[loopUser.associated_user_key] = {
          key: loopUser.associated_user_key,
          name: loopUser.associated_user_name,
          combined_quality_assurance_failures: 0,
          // panel_efficiency: 0,
          // panel_efficiency_v2: 0,
          panel_hours: 0,
          // panel_hours_v2: 0,
          panel_pay: 0,
          total_efficiency_panels: 0,
          total_efficiency_units: 0,
          // total_lifetime_panels: 0,
          // total_lifetime_units: 0,
          total_payroll_panels: 0,
          total_payroll_units: 0,
          // unit_efficiency: 0,
          // unit_efficiency_v2: 0,
          unit_hours: 0,
          // unit_hours_v2: 0,
          unit_pay: 0,
          // user_role: 0,
          CALCULATED_unit_efficiency: 0,
          CALCULATED_panel_efficiency: 0,
        }
      }
      leaderboardTableData[loopUser.associated_user_key]['combined_quality_assurance_failures'] += getProp(loopUser, 'combined_quality_assurance_failures', 0)
      leaderboardTableData[loopUser.associated_user_key]['panel_hours'] += getProp(loopUser, 'panel_hours', 0)
      leaderboardTableData[loopUser.associated_user_key]['panel_pay'] += getProp(loopUser, 'panel_pay', 0)
      leaderboardTableData[loopUser.associated_user_key]['total_efficiency_panels'] += getProp(loopUser, 'total_efficiency_panels', 0)
      leaderboardTableData[loopUser.associated_user_key]['total_efficiency_units'] += getProp(loopUser, 'total_efficiency_units', 0)
      leaderboardTableData[loopUser.associated_user_key]['total_payroll_panels'] += getProp(loopUser, 'total_payroll_panels', 0)
      leaderboardTableData[loopUser.associated_user_key]['total_payroll_units'] += getProp(loopUser, 'total_payroll_units', 0)
      leaderboardTableData[loopUser.associated_user_key]['unit_hours'] += getProp(loopUser, 'unit_hours', 0)
      leaderboardTableData[loopUser.associated_user_key]['unit_pay'] += getProp(loopUser, 'unit_pay', 0)
    }
  }
  // Calculate Efficiency
  for (let loopUserKey in leaderboardTableData) {
    if (leaderboardTableData[loopUserKey]['panel_hours'] > 0) {
      leaderboardTableData[loopUserKey]['CALCULATED_panel_efficiency'] =
        leaderboardTableData[loopUserKey]['total_efficiency_panels'] / leaderboardTableData[loopUserKey]['panel_hours']
    }
    if (leaderboardTableData[loopUserKey]['unit_hours'] > 0) {
      leaderboardTableData[loopUserKey]['CALCULATED_unit_efficiency'] =
        leaderboardTableData[loopUserKey]['total_efficiency_units'] / leaderboardTableData[loopUserKey]['unit_hours']
    }
  }
  us_setLeaderboardTableData(leaderboardTableData)
  // Determine Maximum Values
  let maximumValues: TsInterface_UnspecifiedObject = {
    combined_quality_assurance_failures: 0,
    panel_pay: 0,
    unit_pay: 0,
    CALCULATED_unit_efficiency: 0,
    CALCULATED_panel_efficiency: 0,
  }
  for (let loopUserKey in leaderboardTableData) {
    let loopUser = leaderboardTableData[loopUserKey]
    if (loopUser['combined_quality_assurance_failures'] > maximumValues['combined_quality_assurance_failures']) {
      maximumValues['combined_quality_assurance_failures'] = loopUser['combined_quality_assurance_failures']
    }
    if (loopUser['panel_pay'] > maximumValues['panel_pay']) {
      maximumValues['panel_pay'] = loopUser['panel_pay']
    }
    if (loopUser['unit_pay'] > maximumValues['unit_pay']) {
      maximumValues['unit_pay'] = loopUser['unit_pay']
    }
    if (loopUser['CALCULATED_unit_efficiency'] > maximumValues['CALCULATED_unit_efficiency']) {
      maximumValues['CALCULATED_unit_efficiency'] = loopUser['CALCULATED_unit_efficiency']
    }
    if (loopUser['CALCULATED_panel_efficiency'] > maximumValues['CALCULATED_panel_efficiency']) {
      maximumValues['CALCULATED_panel_efficiency'] = loopUser['CALCULATED_panel_efficiency']
    }
  }
  //Set max for panel and unit efficiency
  if (maximumValues['CALCULATED_panel_efficiency'] > 20) {
    maximumValues['CALCULATED_panel_efficiency'] = 20
    us_setMaxLeaderboardValues(maximumValues)
  } else {
    us_setMaxLeaderboardValues(maximumValues)
  }
  if (maximumValues['CALCULATED_unit_efficiency'] > 10) {
    maximumValues['CALCULATED_unit_efficiency'] = 10
    us_setMaxLeaderboardValues(maximumValues)
  } else {
    us_setMaxLeaderboardValues(maximumValues)
  }
}

const returnQualityColor = (qaFailureCount: number) => {
  if (qaFailureCount > 0) {
    return themeVariables.error_main
  } else {
    return themeVariables.success_main
  }
}

function getInitials(name: string) {
  return name
    .split(' ')
    .map((part) => (part[0] ? part[0].toUpperCase() : ''))
    .join('')
}

//Calculate Units, NPS Score, and Cycle Time Score
function calculateValues(nps: number, cycle_time: number) {
  let units = 0
  let nps_score = 0
  let cycle_time_score = 0
  if (nps >= 9) {
    nps_score = 1
    if (cycle_time <= 45) {
      units = 10
      cycle_time_score = 1
    } else if (cycle_time <= 120) {
      units = 8
      cycle_time_score = 2
    } else {
      units = 6
      cycle_time_score = 3
    }
  } else if (nps >= 7 && nps < 9) {
    nps_score = 2
    if (cycle_time <= 45) {
      units = 8
      cycle_time_score = 1
    } else if (cycle_time <= 120) {
      units = 6
      cycle_time_score = 2
    } else {
      units = 3
      cycle_time_score = 3
    }
  } else {
    nps_score = 3
    if (cycle_time <= 45) {
      units = 6
      cycle_time_score = 1
    } else if (cycle_time <= 120) {
      units = 3
      cycle_time_score = 2
    } else {
      units = 2
      cycle_time_score = 3
    }
  }
  return {
    units: units,
    nps_score: nps_score,
    cycle_time_score: cycle_time_score,
  }
}

///////////////////////////////
// Container
///////////////////////////////

export const Container: React.FC = (): JSX.Element => {
  const currentDate = new Date()
  const firstDayOfCurrentMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1)
  // Props

  // Hooks - useContext, useState, useReducer, other
  // { sort-start } - hooks
  const [us_displaySplitScreen, us_setDisplaySplitScreen] = useState<boolean>(false)
  const [us_errorCount, us_setErrorCount] = useState<number>(0)
  const [us_fullUnitPayTaskData, us_setFullUnitPayTaskData] = useState<TsInterface_UnspecifiedObject>({})
  const [us_internalUsers, us_setInternalUsers] = useState<TsInterface_UnspecifiedObject>({})
  const [us_leaderboardTableData, us_setLeaderboardTableData] = useState<TsInterface_UnspecifiedObject>({})
  const [us_lifetimePanels, us_setLifetimePanels] = useState<TsInterface_UnspecifiedObject>({})
  const [us_lifetimePanelsTableData, us_setLifetimePanelsTableData] = useState<TsInterface_UnspecifiedObject>({})
  const [us_lifetimeUnits, us_setLifetimeUnits] = useState<TsInterface_UnspecifiedObject>({})
  const [us_lifetimeUnitsTableData, us_setLifetimeUnitsTableData] = useState<TsInterface_UnspecifiedObject>({})
  const [us_maxLeaderboardValues, us_setMaxLeaderboardValues] = useState<TsInterface_UnspecifiedObject>({})
  const [us_maxLifetimePanels, us_setMaxLifetimePanels] = useState<number>(0)
  const [us_maxLifetimeUnits, us_setMaxLifetimeUnits] = useState<number>(0)
  const [us_mondaysInDateRange, us_setMondaysInDateRange] = useState<string[]>([])
  const [us_npsMissingUser, us_setNpsMissingUser] = useState<TsInterface_UnspecifiedObject>({})
  const [us_processedPtoCapaNpsData, us_setProcessedPtoCapaNpsData] = useState<TsInterface_UnspecifiedObject>({})
  const [us_rawCAPaData, us_setRawCAPaData] = useState<TsInterface_UnspecifiedObject>({})
  const [us_rawLeaderboardData, us_setRawLeaderboardData] = useState<TsInterface_UnspecifiedObject>({})
  const [us_rawNPSData, us_setRawNPSData] = useState<TsInterface_UnspecifiedObject>({})
  const [us_rawPTOData, us_setRawPTOData] = useState<TsInterface_UnspecifiedObject>({})
  const [us_selectedEndDate, us_setSelectedEndDate] = useState<Date>(currentDate)
  const [us_selectedRepData, us_setSelectedRepData] = useState<TsInterface_UnspecifiedObject>({})
  const [us_selectedRepKey, us_setSelectedRepKey] = useState<string>('')
  const [us_selectedStartDate, us_setSelectedStartDate] = useState<Date>(firstDayOfCurrentMonth)
  const [us_selectedUserKey, us_setSelectedUserKey] = useState<string>('')
  const [us_taskBlueprintConfig, us_setTaskBlueprintConfig] = useState<TsInterface_UnspecifiedObject>({})
  const [us_tasksData, us_setTasksData] = useState<TsInterface_UnspecifiedObject>({})
  const [us_warningCount, us_setWarningCount] = useState<number>(0)
  const [us_warningErrorData, us_setWarningErrorData] = useState<TsInterface_UnspecifiedObject>({})
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)
  // { sort-end } - hooks
  // Hooks - useEffect
  useEffect(() => {
    document.title = rLIB('Leaderboard', false) as string
  }, [])

  //DatabaseRef_FinalizedPayroll_UnitPayTasks_Collection
  useEffect(() => {
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        let mondaysToLoad = getMondaysInDateRange(us_selectedStartDate, us_selectedEndDate)
        let promiseArray: TsType_UnknownPromise[] = []
        let tasksList: TsInterface_UnspecifiedObject = {}
        for (let loopIndex in mondaysToLoad) {
          let mondayKey = mondaysToLoad[loopIndex]
          promiseArray.push(
            DatabaseGetCollection(DatabaseRef_FinalizedPayroll_UnitPayTasks_Collection(res_GCK.clientKey, mondayKey))
              .then((res_DGD) => {
                if (res_DGD != null && res_DGD.data != null) {
                  for (let loopTaskKey in res_DGD.data) {
                    tasksList[loopTaskKey] = res_DGD.data[loopTaskKey]
                  }
                }
              })
              .catch((rej_DGC) => {}),
          )
        }
        Promise.all(promiseArray).finally(() => {
          us_setFullUnitPayTaskData(tasksList)
        })
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
  }, [us_selectedUserKey, uc_RootData_ClientKey, uc_setRootData_ClientKey, us_selectedEndDate, us_selectedStartDate])

  //
  //
  //
  //
  //
  useEffect(() => {
    if (us_selectedUserKey !== '' && us_selectedUserKey != null) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          let mondaysToLoad = getMondaysInDateRange(us_selectedStartDate, us_selectedEndDate)
          let promiseArray: TsType_UnknownPromise[] = []
          let tasksList: TsInterface_UnspecifiedObject = {}

          for (let loopIndex in mondaysToLoad) {
            let mondayKey = mondaysToLoad[loopIndex]
            promiseArray.push(
              DatabaseGetDocument(DatabaseRef_FinalizedPayroll_EmployeeFullPayrollData_Document(res_GCK.clientKey, mondayKey, us_selectedUserKey))
                .then((res_DGD) => {
                  if (res_DGD != null && res_DGD.data != null) {
                    const { unit_pay_task_breakdown, raw_codes } = res_DGD.data

                    if (unit_pay_task_breakdown != null) {
                      for (let loopTaskKey in unit_pay_task_breakdown) {
                        const task = unit_pay_task_breakdown[loopTaskKey]
                        tasksList[loopTaskKey] = { ...task } // Keep the task object intact
                        tasksList[loopTaskKey].key = loopTaskKey // Preserve the original key (do not change this!)

                        // Get the inner `key` from the task and call it `project_key`
                        const project_key = task.key // This is the key inside the task object

                        // Save the `project_key` for comparison
                        tasksList[loopTaskKey].project_key = project_key

                        // Compare `project_key` with the keys from `raw_codes`
                        if (raw_codes != null) {
                          for (let rawCodeKey in raw_codes) {
                            if (rawCodeKey === project_key) {
                              tasksList[loopTaskKey].hours = raw_codes[rawCodeKey] // Assign the matched raw_codes value as 'hours'
                            }
                          }
                        }
                      }
                    }
                  }
                })
                .catch((rej_DGC) => {
                  console.error(rej_DGC)
                }),
            )
          }

          // After all promises are resolved, update the tasks data
          Promise.all(promiseArray).finally(() => {
            us_setTasksData(tasksList)
          })
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    }
  }, [us_selectedUserKey, uc_RootData_ClientKey, uc_setRootData_ClientKey, us_selectedEndDate, us_selectedStartDate])

  //
  //
  //
  //
  //
  useEffect(() => {
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        let mondaysToLoad = getMondaysInDateRange(us_selectedStartDate, us_selectedEndDate)
        let promiseArray: TsType_UnknownPromise[] = []
        let aggregatedLeaderboardData: TsInterface_UnspecifiedObject = {}
        for (let loopIndex in mondaysToLoad) {
          let mondayKey = mondaysToLoad[loopIndex]
          promiseArray.push(
            DatabaseGetCollection(DatabaseRef_LeaderboardDataForSpecificDateKey_Query(res_GCK.clientKey, mondayKey))
              .then((res_DGC) => {
                for (let loopUserDateKey in res_DGC.data) {
                  aggregatedLeaderboardData[loopUserDateKey] = res_DGC.data[loopUserDateKey]
                }
              })
              .catch((rej_DGC) => {
                console.error(rej_DGC)
              }),
          )
        }
        Promise.all(promiseArray).finally(() => {
          us_setMondaysInDateRange(mondaysToLoad)
          us_setRawLeaderboardData(aggregatedLeaderboardData)
        })
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, us_selectedEndDate, us_selectedStartDate])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setInternalUsers(newData)
      ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_InternalUsers_Query(res_GCK.clientKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setLifetimePanels(newData)
      ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_LifetimePanels_Collection(res_GCK.clientKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setLifetimeUnits(newData)
      ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_LifetimeUnits_Collection(res_GCK.clientKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

  useEffect(() => {
    generateLeaderboardTableData(us_rawLeaderboardData, us_setLeaderboardTableData, us_setMaxLeaderboardValues)
  }, [us_rawLeaderboardData])

  useEffect(() => {
    let lifetimePanelsTableData: TsInterface_UnspecifiedObject = {}
    let maxLifetimePanels: number = 0
    for (let loopUserKey in us_lifetimePanels) {
      if (us_internalUsers != null && us_internalUsers[loopUserKey] != null && us_internalUsers[loopUserKey]['status'] === 'active') {
        let loopUser = us_lifetimePanels[loopUserKey]
        lifetimePanelsTableData[loopUserKey] = {
          key: loopUserKey,
          name: getProp(loopUser, 'name', ''),
          lifetime_panels: 0,
        }
        for (let loopPropKey in loopUser) {
          let loopValue = loopUser[loopPropKey]
          if (!isNaN(loopValue) && loopPropKey !== 'name' && loopPropKey !== 'key') {
            lifetimePanelsTableData[loopUserKey].lifetime_panels += loopValue
          }
        }
      }
    }
    for (let loopUserKey in lifetimePanelsTableData) {
      let loopUser = lifetimePanelsTableData[loopUserKey]
      if (loopUser.lifetime_panels > maxLifetimePanels) {
        maxLifetimePanels = loopUser.lifetime_panels
      }
    }
    us_setLifetimePanelsTableData(lifetimePanelsTableData)
    us_setMaxLifetimePanels(maxLifetimePanels)
  }, [us_internalUsers, us_lifetimePanels])

  useEffect(() => {
    let lifetimeUnitsTableData: TsInterface_UnspecifiedObject = {}
    let maxLifetimeUnits = 0
    for (let loopUserKey in us_lifetimeUnits) {
      if (us_internalUsers != null && us_internalUsers[loopUserKey] != null && us_internalUsers[loopUserKey]['status'] === 'active') {
        let loopUser = us_lifetimeUnits[loopUserKey]
        lifetimeUnitsTableData[loopUserKey] = {
          key: loopUserKey,
          name: getProp(loopUser, 'name', ''),
          lifetime_units: 0,
        }
        for (let loopPropKey in loopUser) {
          let loopValue = loopUser[loopPropKey]
          if (!isNaN(loopValue) && loopPropKey !== 'name' && loopPropKey !== 'key') {
            lifetimeUnitsTableData[loopUserKey].lifetime_units += loopValue
          }
        }
      }
    }
    for (let loopUserKey in lifetimeUnitsTableData) {
      let loopUser = lifetimeUnitsTableData[loopUserKey]
      if (loopUser.lifetime_units > maxLifetimeUnits) {
        maxLifetimeUnits = loopUser.lifetime_units
      }
    }
    us_setLifetimeUnitsTableData(lifetimeUnitsTableData)
    us_setMaxLifetimeUnits(maxLifetimeUnits)
  }, [us_internalUsers, us_lifetimeUnits])

  //CSS Leaderboard
  //Get task blueprint config data
  useEffect(() => {
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        DatabaseGetDocument(DatabaseRef_TaskBlueprintConfig_Document(res_GCK.clientKey))
          .then((res_Config) => {
            if (res_Config != null && res_Config.data != null) {
              us_setTaskBlueprintConfig(res_Config.data)
            }
          })
          .catch((error) => {
            console.error('Error fetching task blueprint config:', error)
          })
      })
      .catch((error) => {
        console.error('Error getting client key:', error)
      })
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: any) => {
      us_setRawPTOData(objectToArray(newData).flat())
      ur_forceRerender()
    }
    if (us_taskBlueprintConfig != null && us_taskBlueprintConfig.associated_v2_task_mapping_pto != null) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          unsubscribeLiveData = DatabaseGetLiveCollection(
            DatabaseRef_TasksByTypeAndRange_Query(
              res_GCK.clientKey,
              new Date(us_selectedStartDate),
              new Date(us_selectedEndDate),
              us_taskBlueprintConfig.associated_v2_task_mapping_pto,
            ),
            updateLiveData,
          )
        })
        .catch((rej_GCK) => {
          console.error('Error fetching client key:', rej_GCK)
        })
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, us_selectedStartDate, us_selectedEndDate, us_taskBlueprintConfig, uc_setRootData_ClientKey, ur_forceRerender])

  //Get CAPa Data for PTO tasks
  useEffect(() => {
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey).then((res_GCK) => {
      const clientKey = res_GCK.clientKey
      let promiseArray = []
      let capaTaskData: TsInterface_UnspecifiedObject = {}
      if (us_taskBlueprintConfig !== null && us_taskBlueprintConfig.associated_v2_task_mapping_capa !== null) {
        for (let loopPtoTaskKey in us_rawPTOData) {
          let loopPtoTask = us_rawPTOData[loopPtoTaskKey]
          if (loopPtoTask.associated_project_key != null) {
            promiseArray.push(
              DatabaseGetCollection(
                DatabaseRef_TaskByProjectAndBlueprintKey_Query(
                  clientKey,
                  loopPtoTask.associated_project_key,
                  us_taskBlueprintConfig.associated_v2_task_mapping_capa,
                ),
              )
                .then((res_DGC) => {
                  capaTaskData[loopPtoTaskKey] = res_DGC.data
                })
                .catch((rej_DGC) => {
                  console.error(rej_DGC)
                }),
            )
          }
        }
      }
      Promise.all(promiseArray).finally(() => {
        us_setRawCAPaData(capaTaskData)
      })
    })
  }, [us_rawPTOData, uc_RootData_ClientKey, uc_setRootData_ClientKey, us_taskBlueprintConfig])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: any) => {
      const allNPSData = objectToArray(newData)
      const installData = allNPSData.filter((nps) => nps.feedback_topic === 'Install')
      us_setRawNPSData(installData)
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        const startDate = new Date(us_selectedStartDate)
        const endDate = new Date(us_selectedEndDate)
        endDate.setDate(endDate.getDate() + 42) // Extend the date range by 6 weeks
        unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_Feedback_Query(res_GCK.clientKey, startDate, endDate), updateLiveData)
      })
      .catch((error) => {
        console.error('Error fetching client key:', error)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, us_selectedStartDate, us_selectedEndDate, uc_setRootData_ClientKey])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: any) => {
      us_setNpsMissingUser(newData)
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey).then((res_GCK) => {
      unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_FeedbackMissingAssociatedUser_Query(res_GCK.clientKey), updateLiveData)
    })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, us_selectedStartDate, us_selectedEndDate, uc_setRootData_ClientKey])

  // Process PTO, CAPa, and NPS Data
  useEffect(() => {
    const tempPTOData: TsInterface_UnspecifiedObject = {}
    const tempCAPaData: TsInterface_UnspecifiedObject = {}
    let combinedData: TsInterface_UnspecifiedObject = {}
    let errors: TsInterface_UnspecifiedObject = {}
    let warnings: TsInterface_UnspecifiedObject = {}

    // Process PTO data
    Object.values(us_rawPTOData).forEach((pto) => {
      const key = pto.associated_project_key
      if (pto.timestamp_completed) {
        // Ensure there's a completed timestamp
        if (!tempPTOData[key]) {
          tempPTOData[key] = [pto]
        } else {
          tempPTOData[key].push(pto)
        }
      }
    })

    // Process CAPa data
    Object.values(us_rawCAPaData).forEach((capaContainer) => {
      Object.values(capaContainer).forEach((capa: any) => {
        const key = capa.associated_project_key
        if (capa.timestamp_completed) {
          // Ensure there's a completed timestamp
          if (!tempCAPaData[key]) {
            tempCAPaData[key] = [capa]
          } else {
            tempCAPaData[key].push(capa)
          }
        }
      })
    })

    Object.keys(tempPTOData).forEach((key) => {
      if (tempCAPaData[key]) {
        // Check if both PTO and CAPa data exist for the key
        combinedData[key] = {
          tasks: [...tempPTOData[key], ...tempCAPaData[key]],
        }
      } else {
        // Log error if there's a PTO without a corresponding CAPa
        errors[key] = {
          key: key,
          text: 'Missing corresponding CAPa task',
          associated_project_id_number: tempPTOData[key][0].associated_project_id_number,
          associated_owner_key: tempPTOData[key][0].associated_owner_key,
          associated_owner_name: tempPTOData[key][0].associated_owner_name,
        }
      }
    })

    // Process NPS data
    Object.values(us_rawNPSData).forEach((nps) => {
      const key = nps.associated_project_key
      if (combinedData[key]) {
        combinedData[key].nps = nps
      } else {
        warnings[key] = {
          ...warnings[key],
          missingNPS: 'PTO task exists but does not have a corresponding NPS task.',
        }
      }
    })

    // Further error checking for missing CAPa task for each PTO
    Object.keys(combinedData).forEach((key) => {
      const project = combinedData[key]
      const hasCAPa = project.tasks.some((task: any) => task.associated_task_blueprint_key === us_taskBlueprintConfig.associated_v2_task_mapping_capa)
      if (!hasCAPa) {
        errors[key] = {
          key: key,
          text: 'Missing corresponding CAPa task',
          associated_project_id_number: project.tasks[0]?.associated_project_id_number,
          associated_owner_key: project.tasks[0]?.associated_owner_key,
          associated_owner_name: project.tasks[0]?.associated_owner_name,
        }
        delete combinedData[key] // Remove from combined data due to critical error
      }
    })

    Object.keys(combinedData).forEach((key) => {
      const project = combinedData[key]
      if (project.tasks.length > 0 && !project.nps) {
        const firstTask = project.tasks[0] // Ensure there's at least one task to refer to
        project.nps = {
          key: key,
          feedback_topic: 'Install',
          associated_project_key: firstTask.associated_project_key,
          associated_project_id_number: firstTask.associated_project_id_number,
          associated_user_key: firstTask.associated_owner_key,
          feedback_rating: 0,
        }
        warnings[key] = {
          key: key,
          text: 'NPS score is 0 due to missing response',
          associated_project_id_number: firstTask.associated_project_id_number,
          associated_owner_key: firstTask.associated_owner_key,
          associated_owner_name: firstTask.associated_owner_name,
          associated_project_key: firstTask.associated_project_key,
        }
      }
    })

    Object.keys(combinedData).forEach((key) => {
      const project = combinedData[key]
      if (project.tasks.length > 0 && project.nps) {
        const completedTasks = project.tasks.filter((task: any) => task.timestamp_completed)
        if (completedTasks.length > 1) {
          const timestamps = completedTasks.map(
            (task: any) => new Date(task.timestamp_completed.seconds * 1000 + task.timestamp_completed.nanoseconds / 1000000),
          )
          const minTime = Math.min(...timestamps.map((date: any) => date.getTime()))
          const maxTime = Math.max(...timestamps.map((date: any) => date.getTime()))
          const cycle_time = (maxTime - minTime) / 1000 / 3600 / 24 // Convert milliseconds to days
          const daysInBetween = Math.floor(cycle_time)

          // Store daysInBetween directly at the project level
          project.daysInBetween = daysInBetween

          // Calculate metrics using NPS and cycle time
          const nps_rating = parseInt(project.nps.feedback_rating)
          const { units, nps_score, cycle_time_score } = calculateValues(nps_rating, cycle_time)

          // Store calculated values directly at the project level
          project.units = units
          project.nps_score = nps_score
          project.cycle_time_score = cycle_time_score
        } else {
          // Handle cases with insufficient data to compute cycle_time
          project.daysInBetween = 'Insufficient data for calculation'
          project.units = 0
          project.nps_score = 0
          project.cycle_time_score = 0
        }
      }
    })

    us_setWarningErrorData({ errors, warnings })
    us_setWarningCount(Object.keys(warnings).length)
    us_setErrorCount(Object.keys(errors).length)
    us_setProcessedPtoCapaNpsData(combinedData) // Update state with the filtered combined data
  }, [us_rawPTOData, us_rawCAPaData, us_rawNPSData, us_taskBlueprintConfig])

  useEffect(() => {
    if (us_selectedRepKey) {
      // Create a new object to store filtered projects
      const filteredProjects: TsInterface_UnspecifiedObject = {}
      // Iterate through us_processedPtoCapaNpsData to filter projects
      Object.entries(us_processedPtoCapaNpsData).forEach(([key, project]) => {
        // Check if any tasks within this project are owned by the selected user
        const isOwner = project.tasks.some((task: { associated_owner_key: string }) => task.associated_owner_key === us_selectedRepKey)
        // If the owner is found in any task, include this project
        if (isOwner) {
          filteredProjects[key] = project
        }
      })
      // Set the filtered data to state
      us_setSelectedRepData(filteredProjects)
    }
  }, [us_selectedRepKey, us_processedPtoCapaNpsData])

  const changeCalendarStartDate = (newDate: Date): TsType_UnknownPromise => {
    return new Promise((resolve, reject) => {
      us_setSelectedStartDate(new Date(newDate))
      ur_forceRerender()
      resolve({ success: true })
    })
  }

  const changeCalendarEndDate = (newDate: Date): TsType_UnknownPromise => {
    return new Promise((resolve, reject) => {
      us_setSelectedEndDate(new Date(newDate))
      ur_forceRerender()
      resolve({ success: true })
    })
  }

  function formatNumberWithCommas(num: number) {
    return num.toLocaleString()
  }

  const returnPanelEfficiencyColor = (efficiencyNumber: number): string => {
    let color = themeVariables.success_main
    if (efficiencyNumber < 1.8) {
      color = themeVariables.error_main
    } else if (efficiencyNumber < 2) {
      color = themeVariables.warning_dark
    }
    return color
  }

  const returnUnitEfficiencyColor = (effiencyNumber: number): string => {
    let color = themeVariables.success_main
    if (effiencyNumber < 0.9) {
      color = themeVariables.error_main
    } else if (effiencyNumber < 1) {
      color = themeVariables.warning_dark
    }
    return color
  }

  // JSX Generation
  const rJSX_StartDatePicker = (disabled: boolean): JSX.Element => {
    let datePickerJSX = (
      <Box className="tw-inline-block tw-align-top tw-mr-2">
        <DatePicker
          key={'calendar_date'}
          datePickerText={rLIB('Start Date')}
          datePickerDate={us_selectedStartDate}
          datePickerDateOnChange={changeCalendarStartDate}
          datePickerSettings={{ thin_input: true }}
          datePickerDisabled={disabled}
        />
      </Box>
    )
    return datePickerJSX
  }

  const rJSX_EndDatePicker = (disabled: boolean): JSX.Element => {
    let datePickerJSX = (
      <Box className="tw-inline-block tw-align-top tw-mr-2">
        <DatePicker
          key={'calendar_date'}
          datePickerText={rLIB('End Date')}
          datePickerDate={us_selectedEndDate}
          datePickerDateOnChange={changeCalendarEndDate}
          datePickerSettings={{ thin_input: true }}
          datePickerDisabled={disabled}
        />
      </Box>
    )
    return datePickerJSX
  }

  const rJSX_PayrollDatesInDateQuery = (): JSX.Element => {
    let payrollDatesJSX = (
      <Box className="tw-my-2">
        {us_mondaysInDateRange.sort().map((date: string, index: number) => (
          <Chip
            key={index}
            className="tw-mr-1"
            label={<Box>{date}</Box>}
          />
        ))}
      </Box>
    )
    return payrollDatesJSX
  }

  const rJSX_LeaderboardSegment = (
    positionNumber: number,
    userName: string,
    userKey: string,
    value: JSX.Element,
    progressPercent: number,
    barColor: string,
  ): JSX.Element => {
    let segmentJSX = <></>
    segmentJSX = (
      <Box
        className="tw-p-2"
        sx={{
          borderBottom: '1px solid ' + themeVariables.background_default,
          background:
            'linear-gradient(to right, ' +
            barColor +
            ' 0%, ' +
            barColor +
            ' ' +
            progressPercent +
            '%, ' +
            themeVariables.background_paper +
            ' ' +
            progressPercent +
            '%, ' +
            themeVariables.background_paper +
            ' 100% )',
        }}
      >
        <Stack
          direction="row"
          spacing={1}
          className="tw-justify-between"
        >
          <Stack
            direction="row"
            spacing={1}
          >
            <Box sx={{ width: '100px' }}>{positionNumber}</Box>
            <Box
              className="tw-cursor-pointer tw-py-0.5 tw-px-2 tw-rounded-lg"
              onClick={() => {
                us_setDisplaySplitScreen(true)
                us_setSelectedUserKey(userKey)
              }}
              sx={{
                '&:hover': {
                  background: 'rgba(255,255,255,0.4)',
                },
              }}
            >
              {userName}
            </Box>
          </Stack>
          <Box>{value}</Box>
        </Stack>
      </Box>
    )
    return segmentJSX
  }

  const rJSX_PanelPayTableValue = (user: TsInterface_UnspecifiedObject): JSX.Element => {
    let valueJSX = (
      <Tooltip
        title={
          <Box>
            <Box
              component="span"
              sx={{ marginRight: '4px' }}
            >
              {user.total_payroll_panels.toFixed(1)}
            </Box>
            <Box component="span">{rLIB('Panels')}</Box>
          </Box>
        }
        placement="left"
      >
        <Box>
          <>{formatCurrency(user.panel_pay)}</>
        </Box>
      </Tooltip>
    )
    return valueJSX
  }

  const rJSX_PanelPayTable = (): JSX.Element => {
    let tableJSX = (
      <Box>
        {rJSX_PayrollDatesInDateQuery()}
        <Card>
          {objectToArray(us_leaderboardTableData)
            .sort(dynamicSort('panel_pay', 'desc'))
            .filter((user) => user.panel_pay > 0)
            .map((user, index) => (
              <Box key={index}>
                {rJSX_LeaderboardSegment(
                  index + 1,
                  user.name,
                  user.key,
                  rJSX_PanelPayTableValue(user),
                  us_maxLeaderboardValues['panel_pay'] > 0 ? (user.panel_pay / us_maxLeaderboardValues['panel_pay']) * 100 : 0,
                  themeVariables.info_main,
                )}
              </Box>
            ))}
        </Card>
      </Box>
    )
    return tableJSX
  }

  const rJSX_UnitPayTableValue = (user: TsInterface_UnspecifiedObject): JSX.Element => {
    let valueJSX = (
      <Tooltip
        title={
          <Box>
            <Box
              component="span"
              sx={{ marginRight: '4px' }}
            >
              {user.total_payroll_units.toFixed(1)}
            </Box>
            <Box component="span">{rLIB('Units')}</Box>
          </Box>
        }
        placement="left"
      >
        <Box>{formatCurrency(user.unit_pay)}</Box>
      </Tooltip>
    )
    return valueJSX
  }

  const rJSX_UnitPayTable = (): JSX.Element => {
    let tableJSX = (
      <Box>
        {rJSX_PayrollDatesInDateQuery()}
        <Card>
          {objectToArray(us_leaderboardTableData)
            .sort(dynamicSort('unit_pay', 'desc'))
            .filter((user) => user.unit_pay > 0)
            .map((user, index) => (
              <Box key={index}>
                {rJSX_LeaderboardSegment(
                  index + 1,
                  user.name,
                  user.key,
                  rJSX_UnitPayTableValue(user),
                  us_maxLeaderboardValues['unit_pay'] > 0 ? (user.unit_pay / us_maxLeaderboardValues['unit_pay']) * 100 : 0,
                  themeVariables.info_main,
                )}
              </Box>
            ))}
        </Card>
      </Box>
    )
    return tableJSX
  }

  const rJSX_PanelEfficiencyTableValue = (user: TsInterface_UnspecifiedObject): JSX.Element => {
    let valueJSX = (
      <Tooltip
        title={
          <Box>
            <Box
              component="span"
              sx={{ marginRight: '4px' }}
            >
              {user.total_efficiency_panels.toFixed(1)}
            </Box>
            <Box component="span">{rLIB('Panels')}</Box>
            <Divider />
            <Box
              component="span"
              sx={{ marginRight: '4px' }}
            >
              {user.panel_hours.toFixed(1)}
            </Box>
            <Box component="span">{rLIB('hr')}</Box>
          </Box>
        }
        placement="left"
      >
        <Box>
          <Box
            component="span"
            className="tw-mr-2"
          >
            {user.CALCULATED_panel_efficiency.toFixed(1)}
          </Box>
          <Box
            component="span"
            className="tw-opacity-40"
          >
            {rLIB('Panels')}/{rLIB('hr')}
          </Box>
        </Box>
      </Tooltip>
    )
    return valueJSX
  }

  const rJSX_PanelEfficiencyColor = (user: TsInterface_UnspecifiedObject): string => {
    let color = themeVariables.success_main
    if (user.CALCULATED_panel_efficiency < 1.8) {
      color = themeVariables.error_main
    } else if (user.CALCULATED_panel_efficiency < 2) {
      color = themeVariables.warning_dark
    }
    return color
  }

  const rJSX_PanelEfficiencyTable = (): JSX.Element => {
    let tableJSX = (
      <Box>
        {rJSX_PayrollDatesInDateQuery()}
        <Card>
          {objectToArray(us_leaderboardTableData)
            .sort(dynamicSort('CALCULATED_panel_efficiency', 'desc'))
            .filter((user) => user.CALCULATED_panel_efficiency > 0)
            .map((user, index) => (
              <Box key={index}>
                {rJSX_LeaderboardSegment(
                  index + 1,
                  user.name,
                  user.key,
                  rJSX_PanelEfficiencyTableValue(user),
                  us_maxLeaderboardValues['CALCULATED_panel_efficiency'] > 0
                    ? (user.CALCULATED_panel_efficiency / us_maxLeaderboardValues['CALCULATED_panel_efficiency']) * 100
                    : 0,
                  rJSX_PanelEfficiencyColor(user),
                )}
              </Box>
            ))}
        </Card>
      </Box>
    )
    return tableJSX
  }

  const rJSX_UnitEfficiencyTableValue = (user: TsInterface_UnspecifiedObject): JSX.Element => {
    let valueJSX = (
      <Tooltip
        title={
          <Box>
            <Box
              component="span"
              sx={{ marginRight: '4px' }}
            >
              {user.total_efficiency_units.toFixed(1)}
            </Box>
            <Box component="span">{rLIB('Units')}</Box>
            <Divider />
            <Box
              component="span"
              sx={{ marginRight: '4px' }}
            >
              {user.unit_hours.toFixed(1)}
            </Box>
            <Box component="span">{rLIB('hr')}</Box>
          </Box>
        }
        placement="left"
      >
        <Box>
          <Box
            component="span"
            className="tw-mr-2"
          >
            {user.CALCULATED_unit_efficiency.toFixed(1)}
          </Box>
          <Box
            component="span"
            className="tw-opacity-40"
          >
            {rLIB('Units')}/{rLIB('hr')}
          </Box>
        </Box>
      </Tooltip>
    )
    return valueJSX
  }

  const rJSX_UnitEfficiencyColor = (user: TsInterface_UnspecifiedObject): string => {
    let color = themeVariables.success_main
    if (user.CALCULATED_unit_efficiency < 0.9) {
      color = themeVariables.error_main
    } else if (user.CALCULATED_unit_efficiency < 1) {
      color = themeVariables.warning_dark
    }
    return color
  }

  const rJSX_UnitEfficiencyTable = (): JSX.Element => {
    let tableJSX = (
      <Box>
        {rJSX_PayrollDatesInDateQuery()}
        <Card>
          {objectToArray(us_leaderboardTableData)
            .sort(dynamicSort('CALCULATED_unit_efficiency', 'desc'))
            .filter((user) => user.CALCULATED_unit_efficiency > 0)
            .map((user, index) => (
              <Box key={index}>
                {rJSX_LeaderboardSegment(
                  index + 1,
                  user.name,
                  user.key,
                  rJSX_UnitEfficiencyTableValue(user),
                  us_maxLeaderboardValues['CALCULATED_unit_efficiency'] > 0
                    ? (user.CALCULATED_unit_efficiency / us_maxLeaderboardValues['CALCULATED_unit_efficiency']) * 100
                    : 0,
                  rJSX_UnitEfficiencyColor(user),
                )}
              </Box>
            ))}
        </Card>
      </Box>
    )
    return tableJSX
  }

  const rJSX_QualityTableValue = (user: TsInterface_UnspecifiedObject): JSX.Element => {
    let valueJSX = (
      <Box className="">
        <Box
          component="span"
          className="tw-mr-2"
        >
          {user.combined_quality_assurance_failures}
        </Box>
        <Box
          component="span"
          className="tw-opacity-40"
        >
          {rLIB('Failed Inspections')}
        </Box>
      </Box>
    )
    return valueJSX
  }

  const rJSX_QualityTable = (): JSX.Element => {
    let tableJSX = (
      <Box>
        {rJSX_PayrollDatesInDateQuery()}
        <Card>
          {objectToArray(us_leaderboardTableData)
            .sort(dynamicSort('combined_quality_assurance_failures', 'desc'))
            .map((user, index) => (
              <Box key={index}>
                {rJSX_LeaderboardSegment(
                  index + 1,
                  user.name,
                  user.key,
                  rJSX_QualityTableValue(user),
                  us_maxLeaderboardValues['combined_quality_assurance_failures'] > 0
                    ? (user.combined_quality_assurance_failures / us_maxLeaderboardValues['combined_quality_assurance_failures']) * 100
                    : 0,
                  themeVariables.error_main,
                )}
              </Box>
            ))}
        </Card>
      </Box>
    )
    return tableJSX
  }

  const rJSX_LifetimePanelsTableValue = (user: TsInterface_UnspecifiedObject): JSX.Element => {
    let valueJSX = (
      <Box className="">
        <Box
          component="span"
          className="tw-mr-2"
        >
          {user.lifetime_panels}
        </Box>
        <Box
          component="span"
          className="tw-opacity-40"
        >
          {rLIB('Panels')}
        </Box>
      </Box>
    )
    return valueJSX
  }

  const rJSX_LeaderboardProgressBar = (width: string, backgroundColor: string): JSX.Element => {
    let progressBarJSX = (
      <Box
        className="tw-mb-6"
        sx={{
          background: themeVariables.background_default,
          borderRadius: '4px',
        }}
      >
        <Box
          sx={{
            width: width,
            backgroundColor: backgroundColor,
            height: '8px',
            borderRadius: '4px',
          }}
        />
      </Box>
    )
    return progressBarJSX
  }

  const rJSX_LifetimePanelsTable = (): JSX.Element => {
    let tableJSX = <></>
    tableJSX = (
      <Box>
        <Box className="tw-my-2">
          <Chip
            className="tw-mr-1"
            label={<Box>{rLIB('All Time')}</Box>}
          />
        </Box>
        <Card>
          {objectToArray(us_lifetimePanelsTableData)
            .sort(dynamicSort('lifetime_panels', 'desc'))
            .filter((user) => user.lifetime_panels > 0)
            .map((user, index) => (
              <Box key={index}>
                {rJSX_LeaderboardSegment(
                  index + 1,
                  user.name,
                  user.key,
                  rJSX_LifetimePanelsTableValue(user),
                  us_maxLifetimePanels > 0 ? (user.lifetime_panels / us_maxLifetimePanels) * 100 : 0,
                  themeVariables.primary_main,
                )}
              </Box>
            ))}
        </Card>
      </Box>
    )
    return tableJSX
  }

  const rJSX_LifetimeUnitsTableValue = (user: TsInterface_UnspecifiedObject): JSX.Element => {
    let valueJSX = (
      <Box className="">
        <Box
          component="span"
          className="tw-mr-2"
        >
          {user.lifetime_units}
        </Box>
        <Box
          component="span"
          className="tw-opacity-40"
        >
          {rLIB('Units')}
        </Box>
      </Box>
    )
    return valueJSX
  }

  const rJSX_LifetimeUnitsTable = (): JSX.Element => {
    let tableJSX = <></>
    tableJSX = (
      <Box>
        <Box className="tw-my-2">
          <Chip
            className="tw-mr-1"
            label={<Box>{rLIB('All Time')}</Box>}
          />
        </Box>
        <Card>
          {objectToArray(us_lifetimeUnitsTableData)
            .sort(dynamicSort('lifetime_units', 'desc'))
            .filter((user) => user.lifetime_units > 0)
            .map((user, index) => (
              <Box key={index}>
                {rJSX_LeaderboardSegment(
                  index + 1,
                  user.name,
                  user.key,
                  rJSX_LifetimeUnitsTableValue(user),
                  us_maxLifetimeUnits > 0 ? (user.lifetime_units / us_maxLifetimeUnits) * 100 : 0,
                  themeVariables.primary_main,
                )}
              </Box>
            ))}
        </Card>
      </Box>
    )
    return tableJSX
  }

  const rJSX_JobsCompletedTab = (): JSX.Element => {
    let tabJSX = (
      <Box className="">
        {objectToArray(us_tasksData)
          .sort(dynamicSort('key', 'asc'))
          .map((task, index) => (
            <Box key={index}>{rJSX_TaskDetails(task)}</Box>
          ))}
      </Box>
    )
    return tabJSX
  }

  const rJSX_SummaryTab = (): JSX.Element => {
    let tabJSX = (
      <Box>
        <Card>
          <Box className="tw-p-4">
            {/* PANEL PAY Section */}
            <Typography
              className="tw-inline-block"
              variant="h6"
            >
              {rLIB('Panel Pay')}{' '}
            </Typography>
            <Box className="tw-opacity-50 tw-inline-block tw-ml-5">
              {formatCurrency(getProp(getProp(us_leaderboardTableData, us_selectedUserKey, {}), 'panel_pay', 0))}{' '}
            </Box>
            <Box className="tw-opacity-50 tw-float-right"> {formatCurrency(getProp(us_maxLeaderboardValues, 'panel_pay', 0))}</Box>
            {rJSX_LeaderboardProgressBar(
              `${(100 * getProp(getProp(us_leaderboardTableData, us_selectedUserKey, {}), 'panel_pay', 0)) / getProp(us_maxLeaderboardValues, 'panel_pay', 1)}%`,
              themeVariables.info_main,
            )}
          </Box>
          <Divider></Divider>
          <Box className="tw-p-4">
            <Typography
              className="tw-inline-block"
              variant="h6"
            >
              {rLIB('Unit Pay')}
            </Typography>
            <Box className="tw-opacity-50 tw-inline-block tw-ml-5">
              {formatCurrency(getProp(getProp(us_leaderboardTableData, us_selectedUserKey, {}), 'unit_pay', 0))}{' '}
            </Box>
            <Box className="tw-opacity-50 tw-float-right"> {formatCurrency(getProp(us_maxLeaderboardValues, 'unit_pay', 0))}</Box>
            {rJSX_LeaderboardProgressBar(
              `${(100 * getProp(getProp(us_leaderboardTableData, us_selectedUserKey, {}), 'unit_pay', 0)) / getProp(us_maxLeaderboardValues, 'unit_pay', 1)}%`,
              themeVariables.info_main,
            )}
          </Box>
          <Divider></Divider>
          <Box className="tw-p-4">
            {/* QUALITY Section */}
            <Typography
              className="tw-inline-block"
              variant="h6"
            >
              {rLIB('Quality')}
            </Typography>
            <Box className="tw-opacity-50 tw-inline-block tw-ml-5">
              {getProp(getProp(us_leaderboardTableData, us_selectedUserKey, {}), 'combined_quality_assurance_failures', <></>)}{' '}
            </Box>
            <Box className="tw-opacity-50 tw-float-right"> {getProp(us_maxLeaderboardValues, 'combined_quality_assurance_failures', <></>)}</Box>
            {rJSX_LeaderboardProgressBar(
              `${(100 * getProp(getProp(us_leaderboardTableData, us_selectedUserKey, {}), 'combined_quality_assurance_failures', 0)) / getProp(us_maxLeaderboardValues, 'combined_quality_assurance_failures', 1)}%`,
              returnQualityColor(getProp(getProp(us_leaderboardTableData, us_selectedUserKey, {}), 'combined_quality_assurance_failures', 0)),
            )}
          </Box>
          <Divider></Divider>
          <Box className="tw-p-4">
            {/* PANEL EFFICIENCY Section */}
            <Typography
              className="tw-inline-block"
              variant="h6"
            >
              {rLIB('Panel Efficiency')}
            </Typography>
            <Box className="tw-opacity-50 tw-inline-block tw-ml-5">
              {formatNumberWithCommas(
                parseFloat(getProp(getProp(us_leaderboardTableData, us_selectedUserKey, {}), 'CALCULATED_panel_efficiency', 0).toFixed(1)),
              )}{' '}
            </Box>
            <Box className="tw-opacity-50 tw-float-right">
              {' '}
              {formatNumberWithCommas(parseFloat(getProp(us_maxLeaderboardValues, 'CALCULATED_panel_efficiency', 0).toFixed(1)))}
            </Box>
            {rJSX_LeaderboardProgressBar(
              `${(100 * getProp(getProp(us_leaderboardTableData, us_selectedUserKey, {}), 'CALCULATED_panel_efficiency', 0)) / getProp(us_maxLeaderboardValues, 'CALCULATED_panel_efficiency', 1)}%`,
              returnPanelEfficiencyColor(getProp(getProp(us_leaderboardTableData, us_selectedUserKey, {}), 'CALCULATED_panel_efficiency', 0).toFixed(1)),
            )}
          </Box>
          <Divider></Divider>
          <Box className="tw-p-4">
            {/* LIFETIME PANELS Section */}
            <Typography
              className="tw-inline-block"
              variant="h6"
            >
              {rLIB('Lifetime Panels')}
            </Typography>
            <Box className="tw-opacity-50 tw-inline-block tw-ml-5">
              {formatNumberWithCommas(getProp(getProp(us_lifetimePanelsTableData, us_selectedUserKey, {}), 'lifetime_panels', <></>))}{' '}
            </Box>
            <Box className="tw-opacity-50 tw-float-right"> {formatNumberWithCommas(us_maxLifetimePanels)}</Box>
            {rJSX_LeaderboardProgressBar(
              `${(100 * getProp(getProp(us_lifetimePanelsTableData, us_selectedUserKey, {}), 'lifetime_panels', 0)) / us_maxLifetimePanels}%`,
              themeVariables.primary_main,
            )}
          </Box>
          <Divider></Divider>
          <Box className="tw-p-4">
            {/* UNIT EFFICIENCY Section */}
            <Typography
              className="tw-inline-block"
              variant="h6"
            >
              {rLIB('Unit Efficiency')}
            </Typography>
            <Box className="tw-opacity-50 tw-inline-block tw-ml-5">
              {formatNumberWithCommas(
                parseFloat(getProp(getProp(us_leaderboardTableData, us_selectedUserKey, {}), 'CALCULATED_unit_efficiency', 0).toFixed(1)),
              )}{' '}
            </Box>
            <Box className="tw-opacity-50 tw-float-right">
              {' '}
              {formatNumberWithCommas(parseFloat(getProp(us_maxLeaderboardValues, 'CALCULATED_unit_efficiency', 0).toFixed(1)))}
            </Box>
            {rJSX_LeaderboardProgressBar(
              `${(100 * getProp(getProp(us_leaderboardTableData, us_selectedUserKey, {}), 'CALCULATED_unit_efficiency', 0)) / getProp(us_maxLeaderboardValues, 'CALCULATED_unit_efficiency', 1)}%`,
              returnUnitEfficiencyColor(getProp(getProp(us_leaderboardTableData, us_selectedUserKey, {}), 'CALCULATED_unit_efficiency', 0).toFixed(1)),
            )}
          </Box>
          <Divider></Divider>
          <Box className="tw-p-4">
            {/* LIFETIME UNITS Section */}
            <Typography
              className="tw-inline-block"
              variant="h6"
            >
              {rLIB('Lifetime Units')}
            </Typography>
            <Box className="tw-opacity-50 tw-inline-block tw-ml-5">
              {getProp(getProp(us_lifetimeUnitsTableData, us_selectedUserKey, {}), 'lifetime_units', <></>)}{' '}
            </Box>
            <Box className="tw-opacity-50 tw-float-right"> {formatNumberWithCommas(us_maxLifetimeUnits)}</Box>
            {rJSX_LeaderboardProgressBar(
              `${(100 * getProp(getProp(us_lifetimeUnitsTableData, us_selectedUserKey, {}), 'lifetime_units', 0)) / us_maxLifetimeUnits}%`,
              themeVariables.primary_main,
            )}
          </Box>
        </Card>
      </Box>
    )
    return tabJSX
  }

  const rJSX_TaskAvatar = (fullTaskDetails: TsInterface_UnspecifiedObject, lastDateKey: any) => {
    let combinedUserData: TsInterface_UnspecifiedObject = {}
    if (
      fullTaskDetails != null &&
      fullTaskDetails['task_completion_scheduled_team_names'] != null &&
      fullTaskDetails['task_completion_scheduled_team_names'][lastDateKey] != null
    ) {
      for (let loopUserKey in fullTaskDetails['task_completion_scheduled_team_names'][lastDateKey]) {
        combinedUserData[loopUserKey] = {
          key: loopUserKey,
          name: fullTaskDetails['task_completion_scheduled_team_names'][lastDateKey][loopUserKey],
        }
        // Get Team Role
        if (
          fullTaskDetails != null &&
          fullTaskDetails['task_completion_scheduled_team_roles'] != null &&
          fullTaskDetails['task_completion_scheduled_team_roles'][lastDateKey] != null &&
          fullTaskDetails['task_completion_scheduled_team_roles'][lastDateKey][loopUserKey] != null
        ) {
          combinedUserData[loopUserKey]['team_role'] = fullTaskDetails['task_completion_scheduled_team_roles'][lastDateKey][loopUserKey]
        }
      }
    }
    let avatarJSX = (
      <Box className="tw-my-2">
        {objectToArray(combinedUserData)
          .sort(dynamicSort('name', 'asc'))
          .map((user: TsInterface_UnspecifiedObject, index: number) => (
            <Box
              key={index}
              className="tw-inline-block tw-py-1 tw-px-2"
            >
              <Stack
                direction="row"
                spacing={1}
              >
                <Avatar
                  sx={{
                    width: 40,
                    height: 40,
                    margin: 'auto',
                    bgcolor: stringToColor(user.name),
                    color: 'white',
                    display: 'inline-block',
                    textAlign: 'center',
                  }}
                >
                  <Box className="tw-mt-2">{getInitials(user.name)}</Box>
                </Avatar>
                <Box>
                  <Box>{user.name}</Box>
                  <Box className="tw-opacity-50">{getProp(getProp(availableTeamRoles, user.team_role, {}), 'value', user.team_role)}</Box>
                </Box>
              </Stack>
            </Box>
          ))}
      </Box>
    )
    return avatarJSX
  }

  const rJSX_AdderSum = (fullTaskDetails: TsInterface_UnspecifiedObject) => {
    let addersJSX = <></>
    let taskUnitPayData: TsInterface_UnspecifiedObject = {}
    if (
      fullTaskDetails != null &&
      fullTaskDetails['unit_pay_totals'] != null &&
      us_selectedUserKey != null &&
      fullTaskDetails['unit_pay_totals'][us_selectedUserKey] != null
    ) {
      taskUnitPayData = fullTaskDetails['unit_pay_totals'][us_selectedUserKey]
    }
    addersJSX = (
      <Box>
        <Box className="tw-opacity-50">
          <Box>
            {rLIB('Task Units')}: {getProp(taskUnitPayData, 'task_units', 0)}
          </Box>
          <Box>
            {rLIB('Distance Adder')}: {getProp(taskUnitPayData, 'distance_units', 0)}
          </Box>
          <Box>
            {rLIB('Pitch Adder')}: {getProp(taskUnitPayData, 'pitch_units', 0)}
          </Box>
          <Box>
            {rLIB('Battery Adder')}: {getProp(taskUnitPayData, 'battery_units', 0)}
          </Box>
          <Divider />
          <Box>
            {rLIB('Total Units')}: {getProp(taskUnitPayData, 'total_units', 0)}
          </Box>
          <Box>
            {rLIB('Rate')}: @ {formatCurrency(getProp(taskUnitPayData, 'rate', 0))}
          </Box>
          <Divider />
          <Box>
            {rLIB('Total Unit Pay')}: {formatCurrency(getProp(taskUnitPayData, 'total_unit_pay', 0))}
          </Box>
        </Box>
      </Box>
    )
    return addersJSX
  }

  //TODO: Get task data from  /clients/etw_energy/finances/finalized_payroll/main/2024-04-01/unit_pay_tasks/1083708558_sow_electrical_service_conductor_refeed_1695759342188
  const rJSX_TaskDetails = (task: TsInterface_UnspecifiedObject): JSX.Element => {
    let fullTaskDetails = getProp(us_fullUnitPayTaskData, task.key, {})
    let lastDateKey = getLastChronologicalDate(fullTaskDetails['task_completion_scheduled_dates'])
    let taskDetailsJSX = (
      <Box>
        <Accordion>
          <AccordionSummary expandIcon={<Icon icon="angle-down" />}>
            {fullTaskDetails.associated_project_id_number + ' - ' + fullTaskDetails.name}
          </AccordionSummary>
          <AccordionDetails>
            <Grid2
              container
              spacing={2}
            >
              <Grid2
                xs={12}
                sm={12}
                md={12}
                lg={12}
              >
                <Icon icon="users" /> {rLIB('Crew')}
                {rJSX_TaskAvatar(fullTaskDetails, lastDateKey)}
              </Grid2>
              <Grid2
                xs={12}
                sm={6}
                md={4}
                lg={3}
              >
                <Icon icon="calendar-days" /> {rLIB('Date')}
                <Box className="tw-opacity-50">
                  {lastDateKey}
                  {}
                </Box>
              </Grid2>
              <Grid2
                xs={12}
                sm={6}
                md={4}
                lg={3}
              >
                <Icon icon="solar-panel" /> {rLIB('Panel Pay')}
                <Box className="tw-opacity-50">{formatCurrency(getProp(getProp(task, 'breakdown', {}), 'total_unit_pay', {}))}</Box>
              </Grid2>
              <Grid2
                xs={12}
                sm={6}
                md={4}
                lg={3}
              >
                <Icon icon="battery-full" /> {rLIB('Battery Job')}
                <Box className="tw-opacity-50">
                  {getProp(getProp(task, 'breakdown', {}), 'battery_units', {}) > 0 ? rLIB('Yes') : rLIB('No')}
                  {}
                </Box>
              </Grid2>
              <Grid2
                xs={12}
                sm={6}
                md={4}
                lg={3}
              >
                <Icon icon="location-dot" /> {rLIB('Distance')}
                <Box className="tw-opacity-50">
                  {getProp(getProp(getProp(task, 'breakdown', {}), 'adder_related_fields', {}), 'distance_from_warehouse', 0)} {rLIB('Miles')}
                </Box>
              </Grid2>
              <Grid2
                xs={12}
                sm={6}
                md={4}
                lg={3}
              >
                {/* //
                //
                //
                //
                //
                // */}
                <Icon icon="clock" /> {rLIB('Hours')}
                <Box className="tw-opacity-50">{getProp(task, 'hours', 0).toFixed(2)}</Box>
              </Grid2>
              <Grid2
                xs={12}
                sm={6}
                md={4}
                lg={3}
              >
                {/* //
                //
                //                //
                //
                // */}
                <Icon icon="angle" /> {rLIB('Pitch')}
                <Box className="tw-opacity-50">
                  {getProp(getProp(getProp(task, 'breakdown', {}), 'adder_related_fields', {}), 'system_max_roof_pitch', 0)} {rLIB('°')}
                </Box>
              </Grid2>
              <Grid2
                xs={12}
                sm={6}
                md={4}
                lg={3}
              >
                <Icon icon="credit-card" /> {rLIB('Payout')}
                {rJSX_AdderSum(fullTaskDetails)}
              </Grid2>
            </Grid2>
          </AccordionDetails>
        </Accordion>
      </Box>
    )
    return taskDetailsJSX
  }

  const rJSX_SummaryTabs = (): JSX.Element => {
    let summaryJSX = (
      <Box>
        <Box className="tw-text-right">
          <Button
            color="inherit"
            variant="outlined"
            onClick={() => {
              us_setDisplaySplitScreen(false)
            }}
            disableElevation
            className=""
          >
            <Icon
              icon="x"
              className="tw-p-1"
            />
          </Button>
        </Box>
        <TabsBasic
          tabs={[
            {
              tabHeader: rLIB('Summary'),
              tabContent: rJSX_SummaryTab(),
            },
            {
              tabHeader: rLIB('Jobs Completed'),
              tabContent: rJSX_JobsCompletedTab(),
            },
          ]}
          tabsSettings={{}}
        />
      </Box>
    )
    return summaryJSX
  }

  const rJSX_UnlinkedNpsData = () => {
    let bannerJSX = <></>
    if (objectToArray(us_npsMissingUser).length > 0) {
      bannerJSX = (
        <Box className="tw-m-2 tw-rounded tw-bg-warning_main tw-p-1">
          <Icon
            icon="exclamation-triangle"
            className="tw-mr-2"
          />
          {objectToArray(us_npsMissingUser).length} {rLIB('NPS responses that are not linked properly. Please contact the development team')}
        </Box>
      )
    }
    return bannerJSX
  }

  const rJSX_CSS_Leaderboard = () => {
    const handleRepClick = (repKey: string) => {
      us_setSelectedRepKey(repKey)
      us_setDisplaySplitScreen(true)
    }

    const renderCssRepNames = () => {
      const uniqueReps: { [key: string]: string } = {}

      // First, process PTO CAPA data to get unique reps
      objectToArray(us_processedPtoCapaNpsData).forEach((project) => {
        const firstTask = project.tasks[0]
        if (firstTask) {
          const ownerKey = firstTask.associated_owner_key
          const ownerName = firstTask.associated_owner_name
          if (ownerKey && ownerName) {
            // Ensure both ownerKey and ownerName are valid
            if (!uniqueReps[ownerKey]) {
              uniqueReps[ownerKey] = ownerName
            }
          }
        }
      })

      // Then, add reps from warning/error data
      if (us_warningErrorData) {
        const { errors, warnings } = us_warningErrorData

        const processWarningErrorData = (data: any) => {
          Object.entries(data).forEach(([key, error]: [string, any]) => {
            const ownerKey = error.associated_owner_key
            const ownerName = error.associated_owner_name
            if (ownerKey && ownerName) {
              // Ensure both ownerKey and ownerName are valid
              if (!uniqueReps[ownerKey]) {
                uniqueReps[ownerKey] = ownerName
              }
            }
          })
        }

        if (errors) processWarningErrorData(errors)
        if (warnings) processWarningErrorData(warnings)
      }

      return (
        <Box className="tw-flex tw-flex-wrap tw-gap-4 tw-p-4">
          {Object.entries(uniqueReps).map(([key, name]) => (
            <Box
              key={key}
              onClick={() => handleRepClick(key)}
              className={`tw-cursor-pointer tw-bg-gray-200 tw-border tw-p-20 tw-text-xl ${
                us_selectedRepKey === key ? 'tw-border-white' : 'tw-border-gray-400'
              }`}
            >
              {name}
            </Box>
          ))}
        </Box>
      )
    }

    return (
      <Box>
        <Card>
          <Box>{renderCssRepNames()}</Box>
        </Card>
        {rJSX_UnlinkedNpsData()}
      </Box>
    )
  }

  const rJSX_NpsScore = (repData: TsInterface_UnspecifiedObject): JSX.Element => {
    let totalCount = 0
    let npsScore = 0
    let promoterCount = 0
    let detractorCount = 0
    objectToArray(repData).forEach((project) => {
      if (project.nps && typeof project.nps.feedback_rating === 'number') {
        const rating = parseInt(project.nps.feedback_rating, 10)
        totalCount++

        if (rating >= 9) {
          promoterCount++
        } else if (rating <= 6) {
          detractorCount++
        }
      }
    })
    npsScore = (promoterCount / totalCount - detractorCount / totalCount) * 100 || 0
    return (
      <Box className="tw-flex tw-flex-col tw-items-center">
        {/* <h1 className="tw-text-xl tw-font-bold tw-pb-10">{rLIB("Average NPS")}</h1> */}
        <Typography className="tw-text-5xl">{npsScore.toFixed(1)}</Typography>
      </Box>
    )
  }

  const calculateTotalUnits = (repData: TsInterface_UnspecifiedObject): number => {
    let totalUnits = 0
    let data = objectToArray(repData)
    objectToArray(data).forEach((project) => {
      if (project.units) {
        totalUnits += project.units
      }
    })
    return totalUnits
  }

  const rJSX_ErrorBanner = (): JSX.Element => {
    let bannerJSX = <></>
    if (us_errorCount > 0 || us_warningCount > 0) {
      bannerJSX = (
        <Box
          className="tw-p-2 tw-rounded"
          sx={{ background: themeVariables.error_main }}
        >
          It looks like a problem occurred while calculating units. Refer to the errors tab for details.
        </Box>
      )
    }
    return bannerJSX
  }

  const rJSX_CssSummary = (repData: TsInterface_UnspecifiedObject): JSX.Element => {
    let totalUnits = calculateTotalUnits(repData)
    const topTitles: { [index: number]: string } = {
      0: '< 45',
      1: '45 - 120',
      2: '120 +',
    }
    const leftTitles: { [index: number]: string } = {
      0: '9-10',
      3: '7-8',
      6: '0-6',
    }

    const getBoxContent = (index: any) => {
      // Mapping scores to their corresponding indexes
      const scoreMapping = [
        { nps: 1, cycle: 1 },
        { nps: 1, cycle: 2 },
        { nps: 1, cycle: 3 },
        { nps: 2, cycle: 1 },
        { nps: 2, cycle: 2 },
        { nps: 2, cycle: 3 },
        { nps: 3, cycle: 1 },
        { nps: 3, cycle: 2 },
        { nps: 3, cycle: 3 },
      ]

      // Helper function to get matching projects based on score mappings
      const getMatchingProjects = (score: any, timeScore: any) => {
        return objectToArray(repData).filter((project) => project.nps_score === score && project.cycle_time_score === timeScore)
      }

      // Helper function to render box content
      const renderBoxContent = (matchingProjects: any) => {
        const count = matchingProjects.length
        const totalMatchingUnits = matchingProjects.reduce((sum: any, project: any) => sum + project.units, 0)
        const projectDetails = matchingProjects
          .map((project: any) => `${project.nps.associated_project_id_number} - Rating: ${project.nps.feedback_rating}, Days: ${project.daysInBetween}`)
          .join('\n')

        const textColor = count > 0 ? 'white' : '#A9A9A9' // Using white if there's data, grey otherwise
        const textFontWeight = count > 0 ? 'bold' : 'normal' // Bold if there's data

        return (
          <>
            <Typography style={{ color: textColor, fontWeight: textFontWeight }}>{`Units: ${totalMatchingUnits}`}</Typography>
            <Tooltip
              title={<span style={{ whiteSpace: 'pre-line' }}>{projectDetails}</span>}
              placement="top"
            >
              <Typography style={{ color: textColor, fontWeight: textFontWeight }}>{`Projects: ${count}`}</Typography>
            </Tooltip>
          </>
        )
      }

      // Fetch appropriate data based on the index using scoreMapping
      if (index >= 0 && index < scoreMapping.length) {
        const { nps, cycle } = scoreMapping[index]
        const matchingProjects = getMatchingProjects(nps, cycle)
        return renderBoxContent(matchingProjects)
      } else {
        return <Typography style={{ color: '#A9A9A9', fontWeight: 'bold' }}>Other Box</Typography>
      }
    }

    let gridItems = Array.from({ length: 9 }).map((_, index) => (
      <Box
        key={index}
        className="tw-w-32 tw-h-32 tw-border tw-flex tw-flex-col tw-items-center tw-justify-center tw-relative"
      >
        {topTitles[index] && <Typography className="tw-text-xs tw-font-bold tw-absolute tw-top-[-25px]">{topTitles[index]}</Typography>}
        {leftTitles[index] && (
          <Typography className="tw-text-xs tw-font-bold tw-absolute tw-left-[-40px] tw-top-1/2 tw-transform tw--translate-y-1/2">
            {leftTitles[index]}
          </Typography>
        )}
        {getBoxContent(index)}
      </Box>
    ))

    let tableJSX = (
      <Card className="tw-p-4">
        {rJSX_ErrorBanner()}
        <Box className="tw-flex tw-justify-around tw-p-2">
          <Box className="tw-flex tw-flex-col tw-items-center">
            <Typography className="tw-text-xl tw-font-bold tw-pb-10">{rLIB('Average NPS')}</Typography>
            {rJSX_NpsScore(repData)}
          </Box>
          <Box></Box>
          <Box className="tw-flex tw-flex-col tw-items-center">
            <Typography className="tw-text-xl tw-font-bold tw-pb-10">{rLIB('Total Units')}</Typography>
            <Typography className="tw-text-5xl">{totalUnits}</Typography>
          </Box>
          <Box className="tw-flex tw-flex-col tw-items-center">
            <Typography className="tw-text-xl tw-font-bold tw-pb-10">{rLIB('Number of Projects')}</Typography>
            <Typography className="tw-text-5xl">{Object.keys(repData).length}</Typography>
          </Box>
        </Box>
        <Box className="tw-my-16 tw-p-2">
          <Box>
            <Typography className="tw-text-xl tw-font-bold tw-pb-4">{rLIB('NPS Distribution')}</Typography>
          </Box>
          <FeedbackNpsGraph
            feedbackData={structureNPSDataForChart(repData)}
            feedbackSettings={feedbackGraphSettings}
          />
        </Box>
        <Box className="tw-my-16">
          <Box>
            <Typography className="tw-text-xl tw-font-bold tw-pb-4">{rLIB('Cycle Time Distribution')}</Typography>
          </Box>
          <FeedbackCycleTimeGraph
            feedbackData={structureCycleTimeDataForChart(repData)}
            feedbackSettings={feedbackGraphSettings}
          />
        </Box>
        <Box className="tw-relative tw-w-full tw-max-w-4xl tw-mx-auto">
          <Typography
            variant="h5"
            className="tw-font-bold tw-text-center"
          >
            Cycle Time (Days)
          </Typography>
          <Typography
            variant="body1"
            className="tw-font-bold tw-text-center tw-mb-8 tw-opacity-50"
          >
            CAPa - PTO
          </Typography>
          <Box className="tw-flex tw-flex-row tw-items-center tw-justify-center">
            <Box
              style={{ transform: 'translateX(-200%)' }} // Move the NPS label closer to the grid
            >
              <Typography
                variant="h5"
                className="tw-font-bold"
                style={{ writingMode: 'vertical-lr', transform: 'rotate(180deg)' }}
              >
                NPS Score
              </Typography>
            </Box>
            <Box className="tw-grid tw-grid-cols-3 tw-gap-2 tw-py-4">{gridItems}</Box>
          </Box>
          <Typography className="tw-opacity-50 tw-text-center">
            This only includes V2 projects, if you have V1 projects not listed please reach out to the development team.
          </Typography>
        </Box>
      </Card>
    )
    return tableJSX
  }

  const structureNPSDataForChart = (repData: TsInterface_UnspecifiedObject) => {
    const feedbackRatings: TsInterface_FeedbackData = {}
    Object.entries(repData).forEach(([key, project]) => {
      if (project.nps && project.nps.feedback_rating !== undefined && project.nps.feedback_rating !== null) {
        feedbackRatings[key] = { feedback_rating: parseInt(project.nps.feedback_rating, 10) }
      }
    })
    return feedbackRatings
  }

  const structureCycleTimeDataForChart = (repData: TsInterface_UnspecifiedObject) => {
    const feedbackCycleTimes: TsInterface_FeedbackData = {}
    Object.entries(repData).forEach(([key, project]) => {
      if (project.daysInBetween != null) {
        feedbackCycleTimes[key] = { cycle_time: project.daysInBetween }
      }
    })
    return feedbackCycleTimes
  }

  const feedbackGraphSettings: TsInterface_FeedbackGraphSettings = {
    show_graph_score: false,
    show_graph_labels: true,
  }

  const tableColumns_CSSLeaderboard: TsInterface_TableColumns = {
    project_id: {
      ...TableCellBasic('cssName', rLIB('Project ID'), 'cssName'),
      cell: {
        cell_jsx: (rowData: any) => <>{rowData.nps && typeof rowData.nps === 'object' ? rowData.nps.associated_project_id_number : 'N/A'}</>,
      },
    },
    firstTimestamp: {
      header: {
        header_jsx: () => <Box>{rLIB('PTO Completion Date')}</Box>,
      },
      cell: {
        cell_jsx: (rowData: TsInterface_UnspecifiedObject) => (
          <>{rowData.tasks && rowData.tasks.length > 0 ? new Date(rowData.tasks[0].timestamp_completed.seconds * 1000).toLocaleDateString() : 'No tasks'}</>
        ),
      },
    },
    secondTimestamp: {
      header: {
        header_jsx: () => <Box>{rLIB('CAPa Completion Date')}</Box>,
      },
      cell: {
        cell_jsx: (rowData: TsInterface_UnspecifiedObject) => (
          <>{rowData.tasks && rowData.tasks.length > 1 ? new Date(rowData.tasks[1].timestamp_completed.seconds * 1000).toLocaleDateString() : 'No tasks'}</>
        ),
      },
    },

    cycleTime: {
      ...TableCellBasic('cycleTime', rLIB('Cycle Time'), 'cycleTime'),
      cell: {
        cell_jsx: (rowData) => (
          <>{rowData.nps && typeof rowData.nps === 'object' && rowData.daysInBetween !== 'Missing' ? rowData.daysInBetween + ' days' : 'Missing'}</>
        ),
      },
    },
    nps: {
      ...TableCellBasic('nps', rLIB('NPS'), 'nps'),
      cell: {
        cell_jsx: (rowData: any) => <>{rowData.nps && typeof rowData.nps === 'object' ? rowData.nps.feedback_rating : 'N/A'}</>,
      },
    },
    units: TableCellBasic('units', rLIB('Units'), 'units'),
  }

  const tableSettings_CSSLeaderboard: TsInterface_TableDatabaseSettings = {
    rows_per_page: 100,
    show_header: true,
    size: 'small',
    sort_direction: 'desc',
    sort_property: 'timestamp_created',
    use_live_data: true,
  }

  const rJSX_CssProjects = (us_selectedRepData: TsInterface_UnspecifiedObject) => {
    let tableJSX = (
      <Card>
        <Box className="">
          <TableBasic
            tableAdditionalData={{}}
            tableColumns={tableColumns_CSSLeaderboard}
            tableData={objectToArray(us_selectedRepData)}
            tableSettings={tableSettings_CSSLeaderboard}
          />
        </Box>
      </Card>
    )
    return tableJSX
  }

  const tableColumns_CSSWarningLeaderboard: TsInterface_TableColumns = {
    icon: {
      header: {
        header_jsx: (tableAdditionalData) => <></>,
      },
      cell: {
        cell_jsx: (rowData, tableAdditionalData, tableHooks) => (
          <Icon
            icon="circle-exclamation"
            className="tw-p-1"
            sx={{ color: themeVariables.warning_main }}
          />
        ),
      },
    },
    associated_project_id_number: TableCellBasic('associated_project_id_number', rLIB('Project ID'), 'associated_project_id_number'),
    text: TableCellBasic('text', rLIB('Warning'), 'text'),
    //associated_owner_name: TableCellBasic( "associated_owner_name", 'Name', "associated_owner_name" ),
  }

  const tableColumns_CSSErrorLeaderboard: TsInterface_TableColumns = {
    icon: {
      header: {
        header_jsx: (tableAdditionalData) => <></>,
      },
      cell: {
        cell_jsx: (rowData, tableAdditionalData, tableHooks) => (
          <Icon
            icon="triangle-exclamation"
            className="tw-p-1"
            sx={{ color: themeVariables.error_light }}
          />
        ),
      },
    },
    associated_project_id_number: TableCellBasic('associated_project_id_number', rLIB('Project ID'), 'associated_project_id_number'),
    text: TableCellBasic('text', rLIB('Error'), 'text'),
    //associated_owner_name: TableCellBasic( "associated_owner_name", 'Name', "associated_owner_name" ),
  }

  const rJSX_CssErrors = (us_selectedRepKey: string, us_warningErrorData: TsInterface_UnspecifiedObject) => {
    const filteredErrors = Object.keys(us_warningErrorData.errors)
      .filter((key) => us_warningErrorData.errors[key].associated_owner_key === us_selectedRepKey)
      .reduce((result: Record<string, any>, key) => {
        result[key] = us_warningErrorData.errors[key]
        return result
      }, {})
    let errorCount = objectToArray(filteredErrors).length
    if (us_errorCount !== errorCount) {
      us_setErrorCount(errorCount)
    }

    const filteredWarnings = Object.keys(us_warningErrorData.warnings)
      .filter((key) => us_warningErrorData.warnings[key].associated_owner_key === us_selectedRepKey)
      .reduce((result: Record<string, any>, key) => {
        result[key] = us_warningErrorData.warnings[key]
        return result
      }, {})

    let warningCount = objectToArray(filteredWarnings).length

    if (us_warningCount !== warningCount) {
      us_setWarningCount(warningCount)
    }

    let JSXErrorTable = (
      <Box>
        <Card className="tw-mb-8 ">
          <Box>
            <TableBasic
              tableAdditionalData={{}}
              tableColumns={tableColumns_CSSErrorLeaderboard}
              tableData={objectToArray(filteredErrors)}
              tableSettings={tableSettings_CSSLeaderboard}
            />
          </Box>
        </Card>
        <Card className="">
          <Box>
            <TableBasic
              tableAdditionalData={{}}
              tableColumns={tableColumns_CSSWarningLeaderboard}
              tableData={objectToArray(filteredWarnings)}
              tableSettings={tableSettings_CSSLeaderboard}
            />
          </Box>
        </Card>
      </Box>
    )
    return JSXErrorTable
  }

  const rJSX_CssTabContent = (us_selectedRepData: TsInterface_UnspecifiedObject) => {
    if (!us_selectedRepData) {
      return <Box>Select a CSS rep to view their data.</Box>
    }
    let summaryJSX = (
      <Box>
        <Box className="tw-text-right">
          <Button
            color="inherit"
            variant="outlined"
            onClick={() => {
              us_setDisplaySplitScreen(false)
            }}
            disableElevation
          >
            <Icon
              icon="x"
              className="tw-p-1"
            />
          </Button>
        </Box>
        <TabsBasic
          tabs={[
            {
              tabHeader: rLIB('Summary'),
              tabContent: rJSX_CssSummary(us_selectedRepData),
            },
            {
              tabHeader: rLIB('Projects'),
              tabContent: rJSX_CssProjects(us_selectedRepData),
            },
            {
              tabHeader: (
                <Box
                  display="flex"
                  alignItems="center"
                  gap="8px"
                >
                  {us_errorCount > 0 && (
                    <Box
                      display="flex"
                      alignItems="center"
                      className="tw-p-1"
                      style={{ color: themeVariables.error_main }}
                    >
                      <Icon
                        icon="triangle-exclamation"
                        className="tw-mr-1"
                      />
                      {us_errorCount}
                    </Box>
                  )}
                  {us_warningCount > 0 && (
                    <Box
                      display="flex"
                      alignItems="center"
                      className="tw-p-1"
                      style={{ color: themeVariables.warning_main }}
                    >
                      <Icon
                        icon="triangle-exclamation"
                        className="tw-mr-1"
                      />
                      {us_warningCount}
                    </Box>
                  )}
                  {rLIB('Errors')}
                </Box>
              ),
              tabContent: rJSX_CssErrors(us_selectedRepKey, us_warningErrorData),
            },
          ]}
          tabsSettings={{}}
        />
      </Box>
    )
    return summaryJSX
  }

  const rJSX_AllTimeIndicator = (): JSX.Element => {
    let indicatorJSX = (
      <Button
        color="inherit"
        variant="outlined"
        disabled={true}
        startIcon={
          <Icon
            icon="calendar"
            type="light"
          />
        }
      >
        {rLIB('All Time')}
      </Button>
    )
    return indicatorJSX
  }

  const rJSX_LeaderboardTabs = (): JSX.Element => {
    let tabsJSX = (
      <TabsUrl
        tabs={[
          {
            tabUrlKey: 'Panel_Pay',
            tabHeader: rLIB('Panel Pay'),
            tabContent: rJSX_PanelPayTable(),
            tabButtons: [
              { fullJSX: rJSX_StartDatePicker(false), minJSX: rJSX_StartDatePicker(false), sizeCutoff: 0 },
              { fullJSX: rJSX_EndDatePicker(false), minJSX: rJSX_EndDatePicker(false), sizeCutoff: 0 },
            ],
          },
          {
            tabUrlKey: 'Unit_Pay',
            tabHeader: rLIB('Unit Pay'),
            tabContent: rJSX_UnitPayTable(),
            tabButtons: [
              { fullJSX: rJSX_StartDatePicker(false), minJSX: rJSX_StartDatePicker(false), sizeCutoff: 0 },
              { fullJSX: rJSX_EndDatePicker(false), minJSX: rJSX_EndDatePicker(false), sizeCutoff: 0 },
            ],
          },
          {
            tabUrlKey: 'Panel_Efficiency',
            tabHeader: rLIB('Panel Efficiency'),
            tabContent: rJSX_PanelEfficiencyTable(),
            tabButtons: [
              { fullJSX: rJSX_StartDatePicker(false), minJSX: rJSX_StartDatePicker(false), sizeCutoff: 0 },
              { fullJSX: rJSX_EndDatePicker(false), minJSX: rJSX_EndDatePicker(false), sizeCutoff: 0 },
            ],
          },
          {
            tabUrlKey: 'Unit_Efficiency',
            tabHeader: rLIB('Unit Efficiency'),
            tabContent: rJSX_UnitEfficiencyTable(),
            tabButtons: [
              { fullJSX: rJSX_StartDatePicker(false), minJSX: rJSX_StartDatePicker(false), sizeCutoff: 0 },
              { fullJSX: rJSX_EndDatePicker(false), minJSX: rJSX_EndDatePicker(false), sizeCutoff: 0 },
            ],
          },
          {
            tabUrlKey: 'Quality',
            tabHeader: rLIB('Quality'),
            tabContent: rJSX_QualityTable(),
            tabButtons: [
              { fullJSX: rJSX_StartDatePicker(false), minJSX: rJSX_StartDatePicker(false), sizeCutoff: 0 },
              { fullJSX: rJSX_EndDatePicker(false), minJSX: rJSX_EndDatePicker(false), sizeCutoff: 0 },
            ],
          },
          {
            tabUrlKey: 'Lifetime_Panels',
            tabHeader: rLIB('Lifetime Panels'),
            tabButtons: [{ fullJSX: rJSX_AllTimeIndicator(), minJSX: rJSX_AllTimeIndicator(), sizeCutoff: 0 }],
            tabContent: rJSX_LifetimePanelsTable(),
          },
          {
            tabUrlKey: 'Lifetime_Units',
            tabHeader: rLIB('Lifetime Units'),
            tabButtons: [{ fullJSX: rJSX_AllTimeIndicator(), minJSX: rJSX_AllTimeIndicator(), sizeCutoff: 0 }],
            tabContent: rJSX_LifetimeUnitsTable(),
          },
          {
            tabUrlKey: 'CSS',
            tabHeader: rLIB('CSS Leaderboard'),
            tabContent: rJSX_CSS_Leaderboard(),
            tabButtons: [
              { fullJSX: rJSX_StartDatePicker(false), minJSX: rJSX_StartDatePicker(false), sizeCutoff: 0 },
              { fullJSX: rJSX_EndDatePicker(false), minJSX: rJSX_EndDatePicker(false), sizeCutoff: 0 },
            ],
          },
        ]}
        tabsSettings={{
          baseUrl: ApplicationPages.AdminDatabaseLeaderboardIndexPage.url(),
          tabQueryParam: 'tab',
          overridePageTitle: true,
          basePageTitle: rLIB('Leaderboard', false) as string,
        }}
      />
    )
    return tabsJSX
  }

  const rJSX_PageInner = (): JSX.Element => {
    let pageInnerJSX = <></>
    let queryString = window.location.search
    let queryParams = new URLSearchParams(queryString)
    let tabValue = queryParams.get('tab')
    if (us_displaySplitScreen === true && tabValue !== 'CSS') {
      pageInnerJSX = (
        <Box>
          <Box
            className="tw-inline-block tw-align-top"
            sx={{ width: '50%' }}
          >
            {rJSX_LeaderboardTabs()}
          </Box>
          <Box
            className="tw-inline-block tw-align-top tw-pl-1"
            sx={{ width: '50%' }}
          >
            {rJSX_SummaryTabs()}
          </Box>
        </Box>
      )
    } else if (us_displaySplitScreen === true && tabValue === 'CSS') {
      pageInnerJSX = (
        <Box>
          <Box
            className="tw-inline-block tw-align-top"
            sx={{ width: '50%' }}
          >
            {rJSX_LeaderboardTabs()}
          </Box>
          <Box
            className="tw-inline-block tw-align-top tw-pl-1"
            sx={{ width: '50%' }}
          >
            {us_selectedRepData ? rJSX_CssTabContent(us_selectedRepData) : <Box>Please select a representative.</Box>}
          </Box>
        </Box>
      )
    } else {
      pageInnerJSX = <Box>{rJSX_LeaderboardTabs()}</Box>
    }
    return pageInnerJSX
  }

  const rJSX_Page = (): JSX.Element => {
    let pageJSX = (
      <Box>
        <AuthenticatedContainer
          pageHeader={rLIB('Leaderboard')}
          pageKey={pageKey}
          content={<Box>{rJSX_PageInner()}</Box>}
        />

        {/* <Json data={us_tasksData}></Json> */}
      </Box>
    )
    return pageJSX
  }
  // Render
  return <>{rJSX_Page()}</>
}
