/* eslint-disable react/display-name */
///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			Component to display crew hours data with a heatmap and sorting functionality.

		TODO:
			- Implement any additional functionality as required.
*/

///////////////////////////////
// Imports
///////////////////////////////

import { Box, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material/'
import React, { forwardRef, useState } from 'react'
import { Icon } from 'rfbp_core/components/icons'
import { rLIB } from 'rfbp_core/localization/library'
import { getProp } from 'rfbp_core/services/helper_functions'
import { TsInterface_UnspecifiedObject } from 'rfbp_core/typescript/global_types'
import { objectToArray } from '../../../../rfbp_core/services/helper_functions/index'

///////////////////////////////
// Typescript
///////////////////////////////

export type TsType_GMDashboardHeatmapRowCellContent = {
  cellValue: string | number | JSX.Element
  dialogFunction?: (cellValue: string | number | JSX.Element, additionalCellData: TsInterface_UnspecifiedObject) => void
  conditionalFormatting?: TsInterface_UnspecifiedObject
  additionalCellData?: TsInterface_UnspecifiedObject
  onClick?: () => void // Add onClick as an optional property
  details?: (cellValue: string | number | JSX.Element, additionalCellData: TsInterface_UnspecifiedObject) => void
}

export interface TsInterface_GMDashboardHeatmapTableData {
  summary_rows: TsType_GMDashboardHeatmapRowCellContent[][]
  data_rows: TsType_GMDashboardHeatmapRowCellContent[][]
}

export interface TsInterface_GMDashboardHeatmap {
  tableData: TsInterface_GMDashboardHeatmapTableData
  heatmapLegend?: JSX.Element
  sortConfig?: { column: number; direction: string } | null // Make sortConfig optional
  setSortConfig?: React.Dispatch<React.SetStateAction<{ column: number; direction: string } | null>> // Make setSortConfig optional
}

///////////////////////////////
// Variables
///////////////////////////////

const columnWidths = '150px'

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Container
///////////////////////////////
export const GMDashboardHeatmap = forwardRef((props: TsInterface_GMDashboardHeatmap, ref: React.ForwardedRef<unknown>): JSX.Element => {
  const { tableData = { summary_rows: [], data_rows: [] }, heatmapLegend, sortConfig, setSortConfig } = props

  // Hooks - State management
  const [us_showFullHeatmapTableData, us_setShowFullHeatmapTableData] = useState<boolean>(false)

  ///////////////////////////////
  // Functions
  ///////////////////////////////

  const openHeatmapCellDialog = (cellData: TsType_GMDashboardHeatmapRowCellContent): void => {
    if (cellData.dialogFunction != null) {
      cellData.dialogFunction(cellData.cellValue, getProp(cellData, 'additionalCellData', {}))
    }
  }

  const handleSort = (columnIndex: number) => {
    if (!setSortConfig) return

    setSortConfig((prevConfig) => {
      if (prevConfig && prevConfig.column === columnIndex) {
        return {
          column: columnIndex,
          direction: prevConfig.direction === 'asc' ? 'desc' : 'asc',
        }
      }
      return { column: columnIndex, direction: 'asc' }
    })
  }

  ///////////////////////////////
  // JSX Generation
  ///////////////////////////////

  const rJSX_ToggleFullDataIcon = (): JSX.Element => {
    const handleIconClick = () => {
      us_setShowFullHeatmapTableData(!us_showFullHeatmapTableData)
    }

    return us_showFullHeatmapTableData ? (
      <Icon
        icon="square-caret-down"
        className="tw-mr-2 tw-text-info_main tw-cursor-pointer"
        tooltip={rLIB('Hide Full Data')}
        sx={{ fontSize: '18px' }}
        onClick={handleIconClick} // No event needed
      />
    ) : (
      <Icon
        icon="square-caret-up"
        className="tw-mr-2 tw-opacity-50 hover:tw-opacity-100 tw-cursor-pointer"
        tooltip={rLIB('Show Full Data')}
        sx={{ fontSize: '18px' }}
        onClick={handleIconClick} // No event needed
      />
    )
  }

  const rJSX_LegendIcon = (): JSX.Element | null => {
    return heatmapLegend ? (
      <Icon
        className="tw-mr-2 tw-opacity-50 hover:tw-opacity-100"
        icon="info-circle"
        sx={{ fontSize: '18px' }}
        tooltip={heatmapLegend} // Tooltip shows on hover
        onClick={() => {}} // No action on click, empty function
      />
    ) : null
  }

  const rJSX_TopLeftSummaryCellIcons = (rowIndex: number, cellIndex: number): JSX.Element => {
    if (rowIndex === 0 && cellIndex === 0) {
      return (
        <Box className="tw-inline-block">
          {rJSX_ToggleFullDataIcon()}
          {rJSX_LegendIcon()}
        </Box>
      )
    }
    return <></>
  }

  const rJSX_SummaryCellRows = (): JSX.Element => {
    return (
      <TableBody>
        {tableData.summary_rows?.map((summaryRow: TsType_GMDashboardHeatmapRowCellContent[], rowIndex: number) => (
          <TableRow key={rowIndex}>
            {objectToArray(summaryRow).map((summaryCell: TsType_GMDashboardHeatmapRowCellContent, cellIndex: number) => (
              <TableCell
                sx={{
                  ...getProp(summaryCell, 'conditionalFormatting', {}),
                  'width': columnWidths,
                  'padding': '8px',
                  'boxSizing': 'border-box',
                  'fontWeight': 'bold',
                  'cursor': rowIndex === 0 && cellIndex !== 0 ? 'pointer' : 'default', // Only make first row, excluding first column, clickable
                  '&:hover': rowIndex === 0 && cellIndex !== 0 ? { textDecoration: 'underline' } : {}, // Show underline on hover for first row
                }}
                key={cellIndex}
                onClick={() => rowIndex === 0 && cellIndex !== 0 && handleSort(cellIndex)} // Only allow sorting on non-first column
              >
                {rJSX_TopLeftSummaryCellIcons(rowIndex, cellIndex)}
                {summaryCell.cellValue}
                {rowIndex === 0 && cellIndex === sortConfig?.column ? (
                  <Icon
                    icon={sortConfig.direction === 'asc' ? 'arrow-up' : 'arrow-down'}
                    className="tw-ml-2"
                  />
                ) : null}
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    )
  }

  const rJSX_TableDataRows = (): JSX.Element | null => {
    return us_showFullHeatmapTableData ? (
      <TableBody>
        {tableData.data_rows?.map((dataRow: TsType_GMDashboardHeatmapRowCellContent[], index: number) => (
          <TableRow key={index}>
            {objectToArray(dataRow).map((dataCell: TsType_GMDashboardHeatmapRowCellContent, cellIndex: number) => (
              <TableCell
                sx={{
                  ...getProp(dataCell, 'conditionalFormatting', {}),
                  width: columnWidths,
                  padding: '8px',
                  boxSizing: 'border-box',
                }}
                key={cellIndex}
                onClick={() => dataCell.dialogFunction && dataCell.dialogFunction(dataCell.cellValue || '', dataCell.additionalCellData || {})}
              >
                {dataCell.cellValue}
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    ) : null // Return `null` as a JSX expression
  }

  const rJSX_Component = (): JSX.Element => {
    return (
      <Box>
        <TableContainer>
          <Table size="small">
            {rJSX_SummaryCellRows()}
            {rJSX_TableDataRows()}
          </Table>
        </TableContainer>
      </Box>
    )
  }

  ///////////////////////////////
  // Render
  ///////////////////////////////
  return <>{rJSX_Component()}</>
})
