///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			containers are pages / views used in the app and are made up of components and can interact with services and models

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Badge, Box, Button, Card, Switch, Tooltip, Typography } from '@mui/material/'
import { taskFormIconOptions } from 'app/data/general_form_options'
import { rJSX_TaskCategorySelector } from 'app/models/tasks/global_tasks'
import React, { useContext, useEffect, useReducer, useState } from 'react'
import { Trans } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import { AuthenticatedContainer } from 'rfbp_aux/containers/authenticated_container'
import { ApplicationPages } from 'rfbp_aux/data/application_structure'
import {
  DatabaseRef_TaskFormDefaults_Collection,
  DatabaseRef_TaskFormDefault_Document,
  DatabaseRef_TaskForms_Collection,
  DatabaseRef_TaskForms_Search,
  DatabaseRef_TaskForm_Document,
} from 'rfbp_aux/services/database_endpoints/directory/task_forms'
import { Json } from 'rfbp_core/components/code_display'
import {
  TsInterface_FormAdditionalData,
  TsInterface_FormData,
  TsInterface_FormHooksObject,
  TsInterface_FormInputs,
  TsInterface_FormSettings,
  TsInterface_FormSubmittedData,
} from 'rfbp_core/components/form'
import { Icon } from 'rfbp_core/components/icons'
import {
  TableCellManage,
  TableDatabase,
  TsInterface_TableAdditionalData,
  TsInterface_TableColumns,
  TsInterface_TableDatabaseEndpointQueryObject,
  TsInterface_TableDatabaseSettings,
  TsInterface_TableDataRow,
  TsInterface_TableHooks,
} from 'rfbp_core/components/table'
import { TabsBasic } from 'rfbp_core/components/tabs'
import { rLIB } from 'rfbp_core/localization/library'
import {
  Context_RootData_ClientKey,
  Context_UserInterface_ErrorDialog,
  Context_UserInterface_FormDialog,
  Context_UserInterface_PromptDialog,
  TsInterface_PromptDialogObject,
} from 'rfbp_core/services/context'
import {
  DatabaseAddDocument,
  DatabaseGetCollection,
  DatabaseGetLiveCollection,
  DatabaseSetMergeDocument,
  generateDatabaseQuery,
  TsInterface_OrderByArray,
  TsInterface_QueryCursorsObject,
  TsInterface_QueryOperatorsArray,
} from 'rfbp_core/services/database_management'
import { dynamicSort, getProp, objectToArray } from 'rfbp_core/services/helper_functions'
import { directAppNavigation, onClickAppNavigation } from 'rfbp_core/services/navigation/navigation_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject, TsType_VoidFunction } from 'rfbp_core/typescript/global_types'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

// Authenticated Nav Data
const pageKey: string = ApplicationPages['AdminDatabaseTaskFormsListPage']['key']

// Displayed Translatable Strings
// { sort-start } - displayed text - scoped sort plugin
const s_ARE_YOU_SURE_THAT_YOU_WANT_TO_DELETE_THIS_FORM: JSX.Element = <Trans>Are you sure that you want to delete this form?</Trans>
const s_ARE_YOU_SURE_THAT_YOU_WANT_TO_UNDELETE_THIS_FORM: JSX.Element = <Trans>Are you sure that you want to undelete this form?</Trans>
const s_AVAILABLE_FOR_USE: JSX.Element = <Trans>Available for use</Trans>
const s_CREATE: JSX.Element = <Trans>Create</Trans>
const s_CREATE_NEW_FORM: JSX.Element = <Trans>Create New Form</Trans>
const s_DEFAULTS: JSX.Element = <Trans>Defaults</Trans>
const s_DEFAULT_SITE_AUDIT_FORM: JSX.Element = <Trans>Default Site Audit Form</Trans>
const s_DELETE: JSX.Element = <Trans>Delete</Trans>
const s_DELETE_FORM: JSX.Element = <Trans>Delete Form</Trans>
const s_EDIT: JSX.Element = <Trans>Edit</Trans>
const s_EDIT_ICON: JSX.Element = <Trans>Edit Icon</Trans>
const s_EDIT_NOTES: JSX.Element = <Trans>Edit Notes</Trans>
const s_ENTER_A_NAME_FOR_THIS_FORM: JSX.Element = <Trans>Enter a name for this form</Trans>
const s_ENTER_A_NAME_FOR_YOUR_NEW_TASK_FORM: JSX.Element = <Trans>Enter a name for your new task form</Trans>
const s_FORM_NAME: JSX.Element = <Trans>Form Name</Trans>
const s_ICON: JSX.Element = <Trans>Icon</Trans>
const s_MISSING: JSX.Element = <Trans>Missing</Trans>
const s_NEW_TASK_FORM: JSX.Element = <Trans>New Task Form</Trans>
const s_NOTES: JSX.Element = <Trans>Notes</Trans>
const s_NO_NOTES: JSX.Element = <Trans>No Notes</Trans>
const s_NO_TASK_FORMS_FOUND_BY_SEARCH: JSX.Element = <Trans>No task forms found by search</Trans>
const s_RENAME: JSX.Element = <Trans>Rename</Trans>
const s_RENAME_FORM: JSX.Element = <Trans>Rename form</Trans>
const s_TASK_FORMS: JSX.Element = <Trans>Task Forms</Trans>
const s_UNDELETE: JSX.Element = <Trans>Undelete</Trans>
const s_UNDELETE_FORM: JSX.Element = <Trans>Undelete Form</Trans>
const s_VIEW: JSX.Element = <Trans>View</Trans>
const se_TASK_FORMS = 'Task Forms'
// { sort-end } - displayed text

// Table
const tableSettings_TaskForms: TsInterface_TableDatabaseSettings = {
  rows_per_page: 100,
  show_header: true,
  size: 'small',
  sort_direction: 'asc',
  sort_property: 'name',
  use_live_data: true,
  conditional_row_styles: [
    {
      className: 'tw-opacity-30 tw-line-through',
      conditional_display: {
        active: true,
        logic_type: 'comparison',
        source: 'rowData',
        prop: 'status',
        comparator: '==',
        value: 'deleted',
        conditions: [],
      },
    },
  ],
  searchable: true,
  search_settings_database: {
    search_type: 'firebase',
    search_no_data_message: s_NO_TASK_FORMS_FOUND_BY_SEARCH,
    search_property_options: [{ key: 'name', value: s_FORM_NAME }],
  },
}

const tableColumns_TaskForms: TsInterface_TableColumns = {
  manage: TableCellManage({
    view: {
      icon: (
        <Icon
          type="solid"
          icon="magnifying-glass"
        />
      ),
      label: <>{s_VIEW}</>,
      onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        if (rowData.key != null) {
          directAppNavigation(tableHooks.un_routerNavigation, ApplicationPages.AdminDatabaseTaskFormsViewPage.url(rowData.key as string))
        }
      },
    },
    rename: {
      icon: (
        <Icon
          type="solid"
          icon="pen-to-square"
        />
      ),
      label: <>{s_RENAME}</>,
      onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        if (rowData.key != null) {
          tableHooks.uc_setUserInterface_PromptDialogDisplay({
            display: true,
            prompt: {
              color: 'info',
              confirm_text: s_RENAME,
              default_value: rowData.name,
              header: s_RENAME_FORM,
              icon: (
                <Icon
                  icon="sitemap"
                  type="solid"
                />
              ),
              input_label: s_FORM_NAME,
              input_type: 'text',
              text: s_ENTER_A_NAME_FOR_THIS_FORM,
              submit_callback: (promptValue: string) => {
                return new Promise((resolve, reject) => {
                  let updateObject = {
                    name: promptValue,
                    task_category: 'project',
                  }
                  getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                    .then((res_GCK) => {
                      DatabaseSetMergeDocument(DatabaseRef_TaskForm_Document(res_GCK.clientKey, rowData.key as string), updateObject)
                        .then((res_DSMD) => {
                          resolve({ success: true })
                        })
                        .catch((rej_DSMD) => {
                          tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                        })
                    })
                    .catch((rej_GCK) => {
                      tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                    })
                })
              },
            },
          })
        }
      },
    },
    delete: {
      icon: (
        <Icon
          type="solid"
          icon="trash"
        />
      ),
      label: <>{s_DELETE}</>,
      conditional_display: {
        active: true,
        logic_type: 'comparison',
        source: 'rowData',
        prop: 'status',
        comparator: '!=',
        value: 'deleted',
        conditions: [],
      },
      onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        if (rowData.key != null) {
          tableHooks.uc_setUserInterface_ConfirmDialogDisplay({
            display: true,
            confirm: {
              color: 'error',
              header: s_DELETE_FORM,
              icon: (
                <Icon
                  icon="trash"
                  type="solid"
                />
              ),
              submit_text: s_DELETE,
              text: s_ARE_YOU_SURE_THAT_YOU_WANT_TO_DELETE_THIS_FORM,
              submit_callback: () => {
                return new Promise((resolve, reject) => {
                  getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                    .then((res_GCK) => {
                      let updateObject = {
                        status: 'deleted',
                      }
                      DatabaseSetMergeDocument(DatabaseRef_TaskForm_Document(res_GCK.clientKey, rowData.key as string), updateObject)
                        .then((res_DSMD) => {
                          resolve({ success: true })
                        })
                        .catch((rej_DSMD) => {
                          tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                        })
                    })
                    .catch((rej_GCK) => {
                      tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                    })
                })
              },
            },
          })
        }
      },
    },
    undelete: {
      icon: (
        <Icon
          type="solid"
          icon="trash-undo"
        />
      ),
      label: <>{s_UNDELETE}</>,
      conditional_display: {
        active: true,
        logic_type: 'comparison',
        source: 'rowData',
        prop: 'status',
        comparator: '==',
        value: 'deleted',
        conditions: [],
      },
      onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        if (rowData.key != null) {
          tableHooks.uc_setUserInterface_ConfirmDialogDisplay({
            display: true,
            confirm: {
              color: 'info',
              header: s_UNDELETE_FORM,
              icon: (
                <Icon
                  icon="trash-undo"
                  type="solid"
                />
              ),
              submit_text: s_UNDELETE,
              text: s_ARE_YOU_SURE_THAT_YOU_WANT_TO_UNDELETE_THIS_FORM,
              submit_callback: () => {
                return new Promise((resolve, reject) => {
                  getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                    .then((res_GCK) => {
                      let updateObject = {
                        status: 'active',
                      }
                      DatabaseSetMergeDocument(DatabaseRef_TaskForm_Document(res_GCK.clientKey, rowData.key as string), updateObject)
                        .then((res_DSMD) => {
                          resolve({ success: true })
                        })
                        .catch((rej_DSMD) => {
                          tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                        })
                    })
                    .catch((rej_GCK) => {
                      tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                    })
                })
              },
            },
          })
        }
      },
    },
  }),
  ui_icon: {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return <></>
      },
      header_sort_by: '',
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let iconJSX = <></>
        if (
          rowData != null &&
          rowData.ui_icon != null &&
          taskFormIconOptions != null &&
          taskFormIconOptions[rowData.ui_icon as string] != null &&
          taskFormIconOptions[rowData.ui_icon as string]['value'] != null
        ) {
          iconJSX = taskFormIconOptions[rowData.ui_icon as string]['value']
        } else {
          iconJSX = taskFormIconOptions['none']['value']
        }
        let cellJSX = (
          <Box
            onClick={() => {
              tableHooks.uc_setUserInterface_FormDialogDisplay({
                display: true,
                form: {
                  form: {
                    formAdditionalData: {},
                    formData: rowData,
                    formInputs: {
                      ui_icon: {
                        data_type: 'string',
                        input_type: 'multiple_choice_radio',
                        key: 'ui_icon',
                        label: s_ICON,
                        required: false,
                        options: objectToArray(taskFormIconOptions),
                      },
                    },
                    formOnChange: (
                      formAdditionalData: TsInterface_FormAdditionalData,
                      formData: TsInterface_FormData,
                      formInputs: TsInterface_FormInputs,
                      formSettings: TsInterface_FormSettings,
                    ) => {},
                    formSettings: {},
                    formSubmission: (
                      formSubmittedData: TsInterface_FormSubmittedData,
                      formAdditionalData: TsInterface_FormAdditionalData,
                      formHooks: TsInterface_FormHooksObject,
                    ) => {
                      return new Promise((resolve, reject) => {
                        getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                          .then((res_GCK) => {
                            let updateObject = {
                              ui_icon: getProp(formSubmittedData, 'ui_icon', null),
                            }
                            DatabaseSetMergeDocument(DatabaseRef_TaskForm_Document(res_GCK.clientKey, rowData.key as string), updateObject)
                              .then((res_DSMD) => {
                                resolve(res_DSMD)
                              })
                              .catch((rej_DSMD) => {
                                reject(rej_DSMD)
                              })
                          })
                          .catch((rej_GCK) => {
                            formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                          })
                      })
                    },
                  },
                  dialog: {
                    formDialogHeaderColor: 'success',
                    formDialogHeaderText: (
                      <>
                        {s_EDIT_ICON} - {rowData.name}
                      </>
                    ),
                    formDialogIcon: (
                      <Icon
                        type="solid"
                        icon="pen-to-square"
                      />
                    ),
                  },
                },
              })
            }}
          >
            {iconJSX}
          </Box>
        )
        return cellJSX
      },
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        let cellCSS = 'tw-w-5'
        return cellCSS
      },
    },
  },
  // name: TableCellBasic( "name", s_FORM_NAME, "name" ),
  name: {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return s_FORM_NAME
      },
      header_sort_by: 'name',
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = (
          <>
            <Box
              className="tw-cursor-pointer tw-rounded-md tw-p-1 tw-text-center tw-inline-block"
              sx={{ 'background': themeVariables.background_default, '&:hover': { background: themeVariables.background_json } }}
              onClick={(event) => {
                if (rowData.key != null) {
                  // TODO - handle other user types probably
                  onClickAppNavigation(event, tableHooks.un_routerNavigation, ApplicationPages.AdminDatabaseTaskFormsViewPage.url(rowData.key as string))
                }
              }}
            >
              {rowData.name}
            </Box>
          </>
        )
        return cellJSX
      },
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        let cellCSS = ''
        return cellCSS
      },
    },
  },
  notes: {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return s_NOTES
      },
      header_sort_by: 'notes',
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = <Box></Box>
        let editIconJSX = (
          <Tooltip
            title={s_EDIT_NOTES}
            placement="right"
          >
            <Box
              className="tw-inline-block tw-ml-2 tw-opacity-30 tw-cursor-pointer"
              onClick={() => {
                tableHooks.uc_setUserInterface_FormDialogDisplay({
                  display: true,
                  form: {
                    form: {
                      formAdditionalData: {},
                      formData: rowData,
                      formInputs: {
                        notes: {
                          data_type: 'string',
                          input_type: 'text_multiline',
                          key: 'notes',
                          label: s_NOTES,
                          required: false,
                        },
                      },
                      formOnChange: (
                        formAdditionalData: TsInterface_FormAdditionalData,
                        formData: TsInterface_FormData,
                        formInputs: TsInterface_FormInputs,
                        formSettings: TsInterface_FormSettings,
                      ) => {},
                      formSettings: {},
                      formSubmission: (
                        formSubmittedData: TsInterface_FormSubmittedData,
                        formAdditionalData: TsInterface_FormAdditionalData,
                        formHooks: TsInterface_FormHooksObject,
                      ) => {
                        return new Promise((resolve, reject) => {
                          getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                            .then((res_GCK) => {
                              let updateObject = {
                                notes: getProp(formSubmittedData, 'notes', null),
                              }
                              DatabaseSetMergeDocument(DatabaseRef_TaskForm_Document(res_GCK.clientKey, rowData.key as string), updateObject)
                                .then((res_DSMD) => {
                                  resolve(res_DSMD)
                                })
                                .catch((rej_DSMD) => {
                                  reject(rej_DSMD)
                                })
                            })
                            .catch((rej_GCK) => {
                              formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                            })
                        })
                      },
                    },
                    dialog: {
                      formDialogHeaderColor: 'success',
                      formDialogHeaderText: (
                        <>
                          {s_EDIT_NOTES} - {rowData.name}
                        </>
                      ),
                      formDialogIcon: (
                        <Icon
                          type="solid"
                          icon="pen-to-square"
                        />
                      ),
                    },
                  },
                })
              }}
            >
              <Icon icon="pen-to-square" />
            </Box>
          </Tooltip>
        )
        if (rowData != null && rowData.notes != null && rowData.notes !== '') {
          cellJSX = (
            <Box>
              <Typography className="tw-inline-block">{rowData.notes}</Typography>
              {editIconJSX}
            </Box>
          )
        } else {
          cellJSX = (
            <Box>
              <Typography className="tw-opacity-30 tw-italic tw-inline-block">{s_NO_NOTES}</Typography>
              {editIconJSX}
            </Box>
          )
        }
        return cellJSX
      },
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        let cellCSS = ''
        return cellCSS
      },
    },
  },
  available_for_use: {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return s_AVAILABLE_FOR_USE
      },
      header_sort_by: 'available_for_use',
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        if (rowData['available_for_use'] == null) {
          rowData['available_for_use'] = false
        }
        let cellJSX = (
          <Box>
            <Switch
              disabled={rowData == null || rowData['published_to_prod'] !== true}
              checked={rowData.available_for_use as boolean}
              onChange={(event: any) => {
                if (event != null && event.target != null && event.target.checked != null && rowData != null && rowData.key != null) {
                  let updateObject = {
                    available_for_use: event.target.checked,
                  }
                  getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                    .then((res_GCK) => {
                      DatabaseSetMergeDocument(DatabaseRef_TaskForm_Document(res_GCK.clientKey, rowData.key as string), updateObject)
                        .then((res_DSMD) => {
                          tableHooks.ur_forceRerender()
                        })
                        .catch((rej_DSMD) => {
                          tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                        })
                    })
                    .catch((rej_GCK) => {
                      tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                    })
                }
              }}
            />
          </Box>
        )
        return cellJSX
      },
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        let cellCSS = ''
        return cellCSS
      },
    },
  },
  project_milestone_mapping: {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return <></>
      },
      header_sort_by: null,
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = <></>
        if (rowData != null && rowData.project_milestone_mapping != null) {
          cellJSX = (
            <Box>
              <Icon
                tooltipPlacement="left"
                tooltip={rLIB('Project Milestone Mapping Config')}
                onClick={() => {
                  tableHooks.uc_setUserInterface_CustomDialogDisplay({
                    display: true,
                    dialog: {
                      dialog_jsx: (
                        <Json
                          alphabetized={true}
                          data={{
                            project_milestone_mapping: getProp(rowData, 'project_milestone_mapping', {}),
                          }}
                        />
                      ),
                      settings: {
                        max_width: 'lg',
                      },
                    },
                  })
                }}
                icon="diamond-turn-right"
                sx={{ color: themeVariables.warning_main, fontSize: '20px' }}
              />
            </Box>
          )
        }
        return cellJSX
      },
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        let cellCSS = ''
        if (tableAdditionalData.lastEditedTaskKey === rowData.key) {
          cellCSS = 'workflow_table_highlight'
        }
        return cellCSS
      },
    },
  },
}

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Container
///////////////////////////////

export const Container: React.FC = (): JSX.Element => {
  // Props

  // Hooks - useContext, useState, useReducer, other
  const [us_taskFormDefaults, us_setTaskFormDefaults] = useState<TsInterface_UnspecifiedObject>({})
  const un_routerNavigation = useNavigate()
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)
  const { uc_setUserInterface_ErrorDialogDisplay } = useContext(Context_UserInterface_ErrorDialog)
  const { uc_setUserInterface_FormDialogDisplay } = useContext(Context_UserInterface_FormDialog)
  const { uc_setUserInterface_PromptDialogDisplay } = useContext(Context_UserInterface_PromptDialog)
  const [us_taskCategory, us_setTaskCategory] = useState<string>('project')
  // { sort-end } - hooks

  // Hooks - useEffect

  useEffect(() => {
    document.title = se_TASK_FORMS
  }, [])

  useEffect(() => {
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
    return () => {}
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setTaskFormDefaults(newData)
      ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_TaskFormDefaults_Collection(res_GCK.clientKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

  // Other Variables
  const tableAdditionalData_TaskForms: TsInterface_TableAdditionalData = {}

  // Functions
  const tableDatabaseEndpoint_TaskForms = (
    queryGenerationData: TsInterface_TableDatabaseEndpointQueryObject,
    tableAdditionalData: TsInterface_TableAdditionalData,
  ) => {
    let queryOperatorsArray: TsInterface_QueryOperatorsArray = [
      { prop: 'status', comparator: '==', value: 'active' },
      { prop: 'task_category', comparator: '==', value: us_taskCategory },
    ]
    let orderByArray: TsInterface_OrderByArray = [{ prop: 'name', desc: false }]
    let queryCursorsObject: TsInterface_QueryCursorsObject = {}
    if (queryGenerationData['startAfter'] != null) {
      queryCursorsObject['startAfter'] = queryGenerationData.startAfter
    }
    if (queryGenerationData['startAt'] != null) {
      queryCursorsObject['startAt'] = queryGenerationData.startAt
    }
    if (queryGenerationData['endAt'] != null) {
      queryCursorsObject['endAt'] = queryGenerationData.endAt
    }
    if (queryGenerationData['endBefore'] != null) {
      queryCursorsObject['endBefore'] = queryGenerationData.endBefore
    }
    let limit = getProp(queryGenerationData, 'limit', 5)
    return generateDatabaseQuery(
      DatabaseRef_TaskForms_Collection(uc_RootData_ClientKey as string),
      queryOperatorsArray,
      orderByArray,
      queryCursorsObject,
      limit,
    )
  }

  const tableSearchEndpoint_TaskForms = (searchProperty: string, searchInput: string) => {
    let limit = tableSettings_TaskForms.rows_per_page
    if (limit === null) {
      limit = 30
    }
    return DatabaseRef_TaskForms_Search(uc_RootData_ClientKey as string, searchProperty, searchInput, limit)
  }

  const promptObject: TsInterface_PromptDialogObject = {
    color: 'info',
    confirm_text: s_CREATE,
    default_value: null,
    header: s_CREATE_NEW_FORM,
    icon: (
      <Icon
        icon="sitemap"
        type="solid"
      />
    ),
    input_label: s_FORM_NAME,
    input_type: 'text',
    text: s_ENTER_A_NAME_FOR_YOUR_NEW_TASK_FORM,
    submit_callback: (promptValue: string) => {
      return new Promise((resolve, reject) => {
        let updateObject = {
          name: promptValue,
          status: 'active',
          task_category: us_taskCategory,
        }
        getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
          .then((res_GCK) => {
            DatabaseAddDocument(DatabaseRef_TaskForms_Collection(res_GCK.clientKey), updateObject, true)
              .then((res_DAD) => {
                directAppNavigation(un_routerNavigation, ApplicationPages.AdminDatabaseTaskFormsViewPage.url(res_DAD.key))
                resolve({ success: true })
              })
              .catch((rej_DAD) => {
                uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DAD.error })
              })
          })
          .catch((rej_GCK) => {
            uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
          })
      })
    },
  }

  const openEditDefaultDialog = (label: JSX.Element, formInputBaseKey: string): void => {
    // TODO - make dynamic for future defaults when needed
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        DatabaseGetCollection(DatabaseRef_TaskForms_Collection(res_GCK.clientKey))
          .then((res_DGC) => {
            let taskForms = res_DGC.data
            let taskFormOptions: TsInterface_UnspecifiedObject = {}
            for (let loopFormKey in taskForms) {
              taskFormOptions[loopFormKey] = {
                key: loopFormKey,
                value: taskForms[loopFormKey]['name'],
              }
            }
            let formInputs: TsInterface_FormInputs = {}
            formInputs['associated_form_key'] = {
              data_type: 'string',
              input_type: 'multiple_choice_select',
              key: 'associated_form_key',
              label: label,
              required: false,
              options: objectToArray(taskFormOptions).sort(dynamicSort('value', 'asc')),
            }
            // Import Dialog
            uc_setUserInterface_FormDialogDisplay({
              display: true,
              form: {
                form: {
                  formAdditionalData: {},
                  formData: us_taskFormDefaults,
                  formInputs: formInputs,
                  formOnChange: (
                    formAdditionalData: TsInterface_FormAdditionalData,
                    formData: TsInterface_FormData,
                    formInputs: TsInterface_FormInputs,
                    formSettings: TsInterface_FormSettings,
                  ) => {},
                  formSettings: {},
                  formSubmission: (
                    formSubmittedData: TsInterface_FormSubmittedData,
                    formAdditionalData: TsInterface_FormAdditionalData,
                    formHooks: TsInterface_FormHooksObject,
                  ) => {
                    return new Promise((resolve, reject) => {
                      getClientKey(formHooks.uc_RootData_ClientKey, formHooks.uc_setRootData_ClientKey)
                        .then((res_GCK) => {
                          let updateObject: TsInterface_UnspecifiedObject = {}
                          updateObject['associated_form_key'] = formSubmittedData['associated_form_key']
                          updateObject['associated_form_name'] = taskFormOptions[formSubmittedData['associated_form_key']]['value']
                          DatabaseSetMergeDocument(DatabaseRef_TaskFormDefault_Document(res_GCK.clientKey, formInputBaseKey), updateObject)
                            .then((res_DSMD) => {
                              resolve(res_DSMD)
                            })
                            .catch((rej_DSMD) => {
                              reject(rej_DSMD)
                            })
                        })
                        .catch((rej_GCK) => {
                          formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                        })
                    })
                  },
                },
                dialog: {
                  formDialogHeaderColor: 'success',
                  formDialogHeaderText: <>{label}</>,
                  formDialogIcon: (
                    <Icon
                      type="solid"
                      icon="pen-to-square"
                    />
                  ),
                },
              },
            })
          })
          .catch((rej_DGC) => {
            console.error(rej_DGC)
            uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DGC.error })
          })
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
        uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
      })
  }

  // JSX Generation
  const rJSX_Table = (): JSX.Element => {
    let tableJSX = <></>
    if (tableSettings_TaskForms.search_settings_database == null) {
      tableSettings_TaskForms.search_settings_database = { search_type: 'firebase' }
    }
    tableSettings_TaskForms.search_settings_database.search_endpoint = tableSearchEndpoint_TaskForms
    if (uc_RootData_ClientKey != null) {
      tableJSX = (
        <Card className="">
          <TableDatabase
            tableAdditionalData={tableAdditionalData_TaskForms}
            tableColumns={tableColumns_TaskForms}
            tableDatabaseEndpoint={tableDatabaseEndpoint_TaskForms}
            tableSettings={tableSettings_TaskForms}
          />
        </Card>
      )
    } else {
      tableJSX = <></>
    }
    return tableJSX
  }

  const rJSX_DefaultFormsTab = (): JSX.Element => {
    let tabJSX = <></>
    let missingJSX = <Box className="tw-inline-block tw-italic tw-opacity-30">{s_MISSING}</Box>
    tabJSX = (
      <Card className="tw-p-4">
        <Typography variant="h6">
          {s_DEFAULT_SITE_AUDIT_FORM}:{' '}
          <Box className="tw-inline-block tw-italic tw-opacity-30">{getProp(us_taskFormDefaults.site_audit, 'associated_form_name', missingJSX)}</Box>
          <Tooltip
            title={s_EDIT}
            placement="right"
          >
            <Box
              className="tw-inline-block"
              onClick={() => {
                openEditDefaultDialog(s_DEFAULT_SITE_AUDIT_FORM, 'site_audit')
              }}
            >
              <Icon
                className="tw-ml-2 tw-opacity-30 hover:tw-opacity-100 tw-cursor-pointer"
                icon="pen-to-square"
              />
            </Box>
          </Tooltip>
        </Typography>
      </Card>
    )
    return tabJSX
  }

  const rJSX_DefaultTabHeader = () => {
    let headerJSX = <>{s_DEFAULTS}</>
    if (getProp(us_taskFormDefaults.site_audit, 'associated_form_name', null) == null) {
      headerJSX = (
        <Box>
          <Badge
            badgeContent={<Icon icon="triangle-exclamation" />}
            color="error"
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          >
            <>{s_DEFAULTS}</>
          </Badge>
        </Box>
      )
    }
    return headerJSX
  }

  const rJSX_Page = (): JSX.Element => {
    let pageJSX = (
      <AuthenticatedContainer
        pageHeader={s_TASK_FORMS}
        pageKey={pageKey}
        content={
          <Box>
            {rJSX_TaskCategorySelector(us_taskCategory, us_setTaskCategory)}
            <Button
              color="success"
              variant="contained"
              onClick={() => {
                uc_setUserInterface_PromptDialogDisplay({
                  display: true,
                  prompt: promptObject,
                })
              }}
              disableElevation
              startIcon={
                <Icon
                  icon="circle-plus"
                  type="solid"
                />
              }
            >
              {s_NEW_TASK_FORM}
            </Button>
            <TabsBasic
              tabs={[
                {
                  tabHeader: s_TASK_FORMS,
                  tabContent: <Box>{rJSX_Table()}</Box>,
                },
                {
                  tabHeader: rJSX_DefaultTabHeader(),
                  tabContent: <Box>{rJSX_DefaultFormsTab()}</Box>,
                },
              ]}
              tabsSettings={{}}
            />
          </Box>
        }
      />
    )
    return pageJSX
  }

  // Render
  return <>{rJSX_Page()}</>
}
