///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			containers are pages / views used in the app and are made up of components and can interact with services and models

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Box, Card, TextField } from '@mui/material/'
import { formSettings_FeedbackNew } from 'app/models/feedback/feedback_form'
import { useContext, useEffect, useReducer, useState } from 'react'
import { AuthenticatedContainer } from 'rfbp_aux/containers/authenticated_container'
import { ApplicationPages } from 'rfbp_aux/data/application_structure'
import { DatabaseRef_Feedback_Collection, DatabaseRef_Feedback_Document } from 'rfbp_aux/services/database_endpoints/operations/feedback'
import {
  TsInterface_FormAdditionalData,
  TsInterface_FormData,
  TsInterface_FormHooksObject,
  TsInterface_FormInputs,
  TsInterface_FormSettings,
  TsInterface_FormSubmittedData,
} from 'rfbp_core/components/form'
import { Icon } from 'rfbp_core/components/icons'
import {
  TableBasic,
  TableCellBasic,
  TableCellManage,
  TableCellTimestamp,
  TsInterface_TableAdditionalData,
  TsInterface_TableColumns,
  TsInterface_TableDataRow,
  TsInterface_TableHooks,
} from 'rfbp_core/components/table'
import { rLIB } from 'rfbp_core/localization/library'
import { Context_RootData_ClientKey } from 'rfbp_core/services/context'
import { DatabaseDeleteDocument, DatabaseGetLiveCollection, DatabaseSetMergeDocument } from 'rfbp_core/services/database_management'
import { objectToArray, returnFormattedDate } from 'rfbp_core/services/helper_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject, TsType_VoidFunction } from 'rfbp_core/typescript/global_types'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

// Authenticated Nav Data
const pageKey: string = ApplicationPages['TempNPSEditPage']['key']

let userOptions: TsInterface_UnspecifiedObject = {
  // fwksOjGsL0XPdLln2cSKBdkX2VA3: { key: "fwksOjGsL0XPdLln2cSKBdkX2VA3", value: "Christine Lim" },
  // F9Bn0ZEwHhas2XdtgNSYc3IWXDK2: { key: "F9Bn0ZEwHhas2XdtgNSYc3IWXDK2", value: "Christian Boydstun" },
  // Odjcewx56AaTt6Ll7AOInopWOjA2: { key: "Odjcewx56AaTt6Ll7AOInopWOjA2", value: "Andrew Miale" },
  'Christine Lim': { value: 'Christine Lim', key: 'Christine Lim' },
  'Christian Boydstun': { value: 'Christian Boydstun', key: 'Christian Boydstun' },
  'Andrew Miale': { value: 'Andrew Miale', key: 'Andrew Miale' },
}

let userOptionsLookup: TsInterface_UnspecifiedObject = {
  'Christine Lim': 'fwksOjGsL0XPdLln2cSKBdkX2VA3',
  'Christian Boydstun': 'F9Bn0ZEwHhas2XdtgNSYc3IWXDK2',
  'Andrew Miale': 'Odjcewx56AaTt6Ll7AOInopWOjA2',
}

const tableColumns_NPS: TsInterface_TableColumns = {
  manage: TableCellManage({
    edit: {
      icon: (
        <Icon
          type="solid"
          icon="pen-to-square"
        />
      ),
      label: <>{'Edit'}</>,
      onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        if (rowData.key != null) {
          rowData.timestamp_created = returnFormattedDate(rowData.timestamp_created, 'YYYY-MM-DDTHH:mm')
          tableHooks.uc_setUserInterface_FormDialogDisplay({
            display: true,
            form: {
              form: {
                formAdditionalData: {},
                formData: rowData,
                formInputs: {
                  timestamp_created: {
                    data_type: 'string',
                    input_type: 'timestamp_datetime',
                    key: 'timestamp_created',
                    label: 'timestamp_created',
                    required: false,
                  },
                  feedback_topic: {
                    data_type: 'string',
                    input_type: 'text_basic',
                    key: 'feedback_topic',
                    label: 'feedback_topic',
                    required: false,
                  },
                  feedback_rating: {
                    data_type: 'number',
                    input_type: 'text_number',
                    key: 'feedback_rating',
                    label: 'feedback_rating',
                    required: false,
                  },
                  associated_user_name: {
                    key: 'associated_user_name',
                    label: 'associated_user_name',
                    input_type: 'multiple_choice_radio',
                    required: false,
                    data_type: 'string',
                    options: objectToArray(userOptions),
                  },
                  feedback_additional_identifier: {
                    data_type: 'string',
                    input_type: 'text_basic',
                    key: 'feedback_additional_identifier',
                    label: 'feedback_additional_identifier',
                    required: false,
                  },
                  feedback_notes: {
                    data_type: 'string',
                    input_type: 'text_multiline',
                    key: 'feedback_notes',
                    label: 'feedback_notes',
                    required: false,
                  },
                },
                formOnChange: (
                  formAdditionalData: TsInterface_FormAdditionalData,
                  formData: TsInterface_FormData,
                  formInputs: TsInterface_FormInputs,
                  formSettings: TsInterface_FormSettings,
                ) => {},
                formSettings: formSettings_FeedbackNew,
                formSubmission: (
                  formSubmittedData: TsInterface_FormSubmittedData,
                  formAdditionalData: TsInterface_FormAdditionalData,
                  formHooks: TsInterface_FormHooksObject,
                ) => {
                  return new Promise((resolve, reject) => {
                    getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                      .then((res_GCK) => {
                        let updateObject = formSubmittedData
                        updateObject.timestamp_created = new Date(updateObject.timestamp_created)
                        if (
                          updateObject.associated_user_name != null &&
                          userOptionsLookup[updateObject.associated_user_name] != null &&
                          userOptionsLookup[updateObject.associated_user_name] != null
                        ) {
                          updateObject.associated_user_key = userOptionsLookup[updateObject.associated_user_name]
                        }
                        DatabaseSetMergeDocument(DatabaseRef_Feedback_Document(res_GCK.clientKey, rowData.key as string), updateObject)
                          .then((res_DAD) => {
                            resolve(res_DAD)
                          })
                          .catch((rej_DAD) => {
                            reject(rej_DAD)
                          })
                      })
                      .catch((rej_GCK) => {
                        formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                        reject(rej_GCK)
                      })
                  })
                },
              },
              dialog: {
                formDialogHeaderColor: 'success',
                formDialogHeaderText: 'Edit Feedback',
                formDialogIcon: (
                  <Icon
                    type="solid"
                    icon="pen-to-square"
                  />
                ),
              },
            },
          })
        }
      },
    },
    delete: {
      icon: (
        <Icon
          icon="trash"
          type="solid"
        />
      ),
      label: <>{'Delete'}</>,
      conditional_display: {
        active: true,
        logic_type: 'comparison',
        source: 'rowData',
        prop: 'status',
        comparator: '!=',
        value: 'deleted',
        conditions: [],
      },
      onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        if (rowData != null && rowData.key != null && tableHooks != null && tableHooks.uc_RootData_ClientKey != null) {
          tableHooks.uc_setUserInterface_ConfirmDialogDisplay({
            display: true,
            confirm: {
              color: 'error',
              header: 'Delete NPS',
              icon: (
                <Icon
                  icon="trash"
                  type="solid"
                />
              ),
              submit_text: 'Delete',
              text: 'Are you sure you want to delete this NPS? AJ????????',
              submit_callback: () => {
                return new Promise((resolve, reject) => {
                  DatabaseDeleteDocument(DatabaseRef_Feedback_Document(tableHooks.uc_RootData_ClientKey, rowData.key as string))
                    .then((res_DSMD) => {
                      resolve({ success: true })
                    })
                    .catch((rej_DSMD) => {
                      tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                      reject(rej_DSMD)
                    })
                })
              },
            },
          })
        }
      },
    },
  }),
  icon: {
    cell: {
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = <></>
        if (rowData != null && rowData.associated_user_key != null) {
          cellJSX = (
            <Icon
              icon="badge-check"
              className="tw-text-success_main"
            />
          )
        } else {
          cellJSX = (
            <Icon
              icon="circle-exclamation"
              className="tw-text-error_main"
            />
          )
        }
        return cellJSX
      },
    },
    header: {
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return <></>
      },
      header_sort_by: 'input_type',
    },
  },
  timestamp_created: TableCellTimestamp('timestamp_created', 'timestamp_created', 'timestamp_created', 'D MMM YYYY', false),
  feedback_topic: TableCellBasic('feedback_topic', 'feedback_topic', 'feedback_topic'),
  associated_project_id_number: TableCellBasic('associated_project_id_number', 'associated_project_id_number', 'associated_project_id_number'),
  feedback_rating: TableCellBasic('feedback_rating', 'feedback_rating', 'feedback_rating'),
  // associated_project_key: TableCellBasic("associated_project_key", "associated_project_key", "associated_project_key"),
  associated_user_name: TableCellBasic('associated_user_name', 'associated_user_name', 'associated_user_name'),
  feedback_additional_identifier: TableCellBasic('feedback_additional_identifier', 'feedback_additional_identifier', 'feedback_additional_identifier'),
  feedback_notes: TableCellBasic('feedback_notes', 'feedback_notes', 'feedback_notes'),
  key: TableCellBasic('key', 'key', 'key'),
}

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Container
///////////////////////////////

export const Container: React.FC = (): JSX.Element => {
  // Props
  // const params = useParams()
  // const itemKey: string = params.id as string

  // Hooks - useContext, useState, useReducer, other
  // { sort-start } - hooks
  const [us_accessCode, us_setAccessCode] = useState<string>('')
  const [us_accessGranted, us_setAccessGranted] = useState<boolean>(false)
  const [us_npsItems, us_setNPSItems] = useState<TsInterface_UnspecifiedObject>({})
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)
  // { sort-end } - hooks

  // Hooks - useEffect
  useEffect(() => {
    document.title = rLIB('NPS Edit', false) as string
  }, [])

  useEffect(() => {
    if (us_accessCode === 'devteam') {
      us_setAccessGranted(true)
    }
  }, [us_accessCode])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setNPSItems(newData)
      ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_Feedback_Collection(res_GCK.clientKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

  // Functions

  // JSX Generation
  const rJSX_PageContent = (): JSX.Element => {
    let contentJSX = <></>
    if (us_accessGranted === true) {
      contentJSX = (
        <Card>
          <TableBasic
            tableAdditionalData={{}}
            tableColumns={tableColumns_NPS}
            tableData={objectToArray(us_npsItems)}
            tableSettings={{
              paginated: true,
              pagination_rows_per_page_default: 100,
              pagination_rows_per_page_options: [10, 25, 50, 100],
              show_header: true,
              size: 'small',
              sort_direction: 'desc',
              sort_property_default: 'timestamp_created',
              sortable: true,
              collapsible_columns: true,
              sticky_header: true,
              sticky_table_height: 'calc(100vh - 160px)',
            }}
          />
        </Card>
      )
    } else {
      contentJSX = (
        <Box className="tw-text-center">
          <TextField
            label={rLIB('Access Code')}
            variant="outlined"
            value={us_accessCode}
            onChange={(e) => {
              if (e.target.value != null) {
                us_setAccessCode(e.target.value)
              }
            }}
          />
        </Box>
      )
    }
    return contentJSX
  }

  const rJSX_Page = (): JSX.Element => {
    let pageJSX = (
      <AuthenticatedContainer
        pageHeader={rLIB('NPS Admin')}
        pageKey={pageKey}
        content={rJSX_PageContent()}
      />
    )
    return pageJSX
  }

  // Render
  return <>{rJSX_Page()}</>
}
