///////////////////////////////
// Imports
///////////////////////////////

import { collection, CollectionReference, doc, DocumentData, DocumentReference, getFirestore, Query } from 'firebase/firestore'
import { generateDatabaseQuery, TsInterface_QueryOperatorsArray } from 'rfbp_core/services/database_management'

///////////////////////////////
// Exports
///////////////////////////////

export const DatabaseRef_SpendingLimits_Collection = (clientKey: string): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'finances', 'spending_limits', 'main')
}

export const DatabaseRef_Approvals_Collection = (clientKey: string): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'finances', 'purchase_approvals', 'main')
}

export const DatabaseRef_Approvals_Document = (clientKey: string, approvalKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'finances', 'purchase_approvals', 'main', approvalKey)
}

export const DatabaseRef_SpendingProfiles_Collection = (clientKey: string): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'finances', 'spending_profiles', 'main')
}

export const DatabaseRef_SpendingProfiles_Document = (clientKey: string, profileKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'finances', 'spending_profiles', 'main', profileKey)
}

export const DatabaseRef_GeocodedLocations_Document = (clientKey: string, locationKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'operations', 'gm_dashboards', 'cached_data', 'geocoded_locations', 'main', locationKey)
}

export const DatabaseRef_GeocodedLocations_Collection = (clientKey: string): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'operations', 'gm_dashboards', 'cached_data', 'geocoded_locations', 'main')
}

export const DatabaseRef_Transactions_Collection = (clientKey: string): CollectionReference<DocumentData> => {
  // TODO - This is a placeholder, need to update this to use Stripe API
  return collection(getFirestore(), 'clients', clientKey, 'finances', 'transactions', 'main')
}

export const DatabaseRef_GeocodedLocations_Query = (clientKey: string, address: string): Query<unknown> => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [{ prop: 'address', comparator: '==', value: address }]
  let queryCursorsObject = {}
  return generateDatabaseQuery(
    collection(getFirestore(), 'clients', clientKey, 'operations', 'gm_dashboards', 'cached_data', 'geocoded_locations', 'main'),
    queryOperatorsArray,
    [],
    queryCursorsObject,
    null,
  )
}
