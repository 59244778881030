///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			containers are pages / views used in the app and are made up of components and can interact with services and models

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Box, Button, Card } from '@mui/material/'
import { useContext, useEffect, useReducer } from 'react'
import { Trans } from 'react-i18next'
import { AuthenticatedContainer } from 'rfbp_aux/containers/authenticated_container'
import { ApplicationPages } from 'rfbp_aux/data/application_structure'
import { DatabaseRef_Batteries_Collection, DatabaseRef_Battery_Document } from 'rfbp_aux/services/database_endpoints/directory/batteries'
import { DatabaseRef_DesignPartners_Collection, DatabaseRef_DesignPartner_Document } from 'rfbp_aux/services/database_endpoints/directory/design_partners'
import { DatabaseRef_Inverters_Collection, DatabaseRef_Inverter_Document } from 'rfbp_aux/services/database_endpoints/directory/inverters'
import { DatabaseRef_Modules_Collection, DatabaseRef_Module_Document } from 'rfbp_aux/services/database_endpoints/directory/modules'
import { DatabaseRef_Racking_Collection, DatabaseRef_Racking_Document } from 'rfbp_aux/services/database_endpoints/directory/racking'
import { Icon } from 'rfbp_core/components/icons'
import { BasicImportButtonAndDialog } from 'rfbp_core/components/imports/basic_import_button_and_dialog'
import {
  TableCellBasic,
  TableCellManage,
  TableDatabase,
  TsInterface_TableAdditionalData,
  TsInterface_TableColumns,
  TsInterface_TableDatabaseEndpointQueryObject,
  TsInterface_TableDatabaseSettings,
  TsInterface_TableDataRow,
  TsInterface_TableHooks,
} from 'rfbp_core/components/table'
import { TabsUrl } from 'rfbp_core/components/tabs'
import { rLIB } from 'rfbp_core/localization/library'
import {
  Context_RootData_ClientKey,
  Context_UserInterface_ErrorDialog,
  Context_UserInterface_PromptDialog,
  TsInterface_PromptDialogObject,
} from 'rfbp_core/services/context'
import {
  DatabaseSetMergeDocument,
  DatabaseStagedBatchUpdate,
  generateDatabaseQuery,
  TsInterface_DatabaseBatchUpdatesArray,
  TsInterface_OrderByArray,
  TsInterface_QueryCursorsObject,
  TsInterface_QueryOperatorsArray,
} from 'rfbp_core/services/database_management'
import { getProp, keyFromString } from 'rfbp_core/services/helper_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

// Authenticated Nav Data
const pageKey: string = ApplicationPages['DesignIndexPage']['key']

// Table
const tableSettings_Design: TsInterface_TableDatabaseSettings = {
  rows_per_page: 100,
  show_header: true,
  size: 'small',
  sort_direction: 'asc',
  sort_property: 'name',
  use_live_data: false,
  conditional_row_styles: [
    {
      className: 'tw-opacity-30 tw-line-through',
      conditional_display: {
        active: true,
        logic_type: 'comparison',
        source: 'rowData',
        prop: 'status',
        comparator: '==',
        value: 'deleted',
        conditions: [],
      },
    },
  ],
}

const tableColumns_Modules: TsInterface_TableColumns = {
  manage: TableCellManage({
    delete: {
      icon: (
        <Icon
          type="solid"
          icon="trash-can"
        />
      ),
      label: <Trans>Delete</Trans>,
      onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        tableHooks.uc_setUserInterface_ConfirmDialogDisplay({
          display: true,
          confirm: {
            color: 'error',
            header: rLIB('Delete Module'),
            icon: (
              <Icon
                icon="trash"
                type="solid"
              />
            ),
            submit_text: rLIB('Delete'),
            text: rLIB('Are you sure you want to delete this module?'),
            submit_callback: () => {
              return new Promise((resolve, reject) => {
                getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey).then((res_GCK) => {
                  DatabaseSetMergeDocument(DatabaseRef_Module_Document(res_GCK.clientKey, rowData.key as string), { status: 'deleted' })
                    .then((res_DDD) => {
                      resolve(res_DDD)
                    })
                    .catch((rej_DDD) => {
                      reject(rej_DDD)
                      tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DDD.error })
                    })
                })
              })
            },
          },
        })
      },
    },
  }),
  name: TableCellBasic('name', rLIB('Module'), 'name'),
}

const tableColumns_DesignPartners: TsInterface_TableColumns = {
  manage: TableCellManage({
    // New delete functionality
    delete: {
      icon: (
        <Icon
          type="solid"
          icon="trash-can"
        />
      ),
      label: <Trans>Delete</Trans>,
      onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        tableHooks.uc_setUserInterface_ConfirmDialogDisplay({
          display: true,
          confirm: {
            color: 'error',
            header: rLIB('Delete Design Partner'),
            icon: (
              <Icon
                icon="trash"
                type="solid"
              />
            ),
            submit_text: rLIB('Delete'),
            text: rLIB('Are you sure you want to delete this design partner?'),
            submit_callback: () => {
              return new Promise((resolve, reject) => {
                getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey).then((res_GCK) => {
                  DatabaseSetMergeDocument(DatabaseRef_DesignPartner_Document(res_GCK.clientKey, rowData.key as string), { status: 'deleted' })
                    .then((res_DDD) => {
                      resolve(res_DDD)
                    })
                    .catch((rej_DDD) => {
                      reject(rej_DDD)
                      tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DDD.error })
                    })
                })
              })
            },
          },
        })
      },
    },
  }),
  name: TableCellBasic('name', rLIB('Design Partner'), 'name'),
}

const tableColumns_Inverters: TsInterface_TableColumns = {
  manage: TableCellManage({
    // New delete functionality
    delete: {
      icon: (
        <Icon
          type="solid"
          icon="trash-can"
        />
      ),
      label: <Trans>Delete</Trans>,
      onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        tableHooks.uc_setUserInterface_ConfirmDialogDisplay({
          display: true,
          confirm: {
            color: 'error',
            header: rLIB('Delete Inverter'),
            icon: (
              <Icon
                icon="trash"
                type="solid"
              />
            ),
            submit_text: rLIB('Delete'),
            text: rLIB('Are you sure you want to delete this inverter?'),
            submit_callback: () => {
              return new Promise((resolve, reject) => {
                getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey).then((res_GCK) => {
                  DatabaseSetMergeDocument(DatabaseRef_Inverter_Document(res_GCK.clientKey, rowData.key as string), { status: 'deleted' })
                    .then((res_DDD) => {
                      resolve(res_DDD)
                    })
                    .catch((rej_DDD) => {
                      reject(rej_DDD)
                      tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DDD.error })
                    })
                })
              })
            },
          },
        })
      },
    },
  }),
  name: TableCellBasic('name', rLIB('Inverter'), 'name'),
}

const tableColumns_Racking: TsInterface_TableColumns = {
  manage: TableCellManage({
    // New delete functionality
    delete: {
      icon: (
        <Icon
          type="solid"
          icon="trash-can"
        />
      ),
      label: <Trans>Delete</Trans>,
      onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        tableHooks.uc_setUserInterface_ConfirmDialogDisplay({
          display: true,
          confirm: {
            color: 'error',
            header: rLIB('Delete Racking'),
            icon: (
              <Icon
                icon="trash"
                type="solid"
              />
            ),
            submit_text: rLIB('Delete'),
            text: rLIB('Are you sure you want to delete this racking?'),
            submit_callback: () => {
              return new Promise((resolve, reject) => {
                getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey).then((res_GCK) => {
                  DatabaseSetMergeDocument(DatabaseRef_Racking_Document(res_GCK.clientKey, rowData.key as string), { status: 'deleted' })
                    .then((res_DDD) => {
                      resolve(res_DDD)
                    })
                    .catch((rej_DDD) => {
                      reject(rej_DDD)
                      tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DDD.error })
                    })
                })
              })
            },
          },
        })
      },
    },
  }),
  name: TableCellBasic('name', rLIB('Racking'), 'name'),
}

const tableColumns_Batteries: TsInterface_TableColumns = {
  manage: TableCellManage({
    // New delete functionality
    delete: {
      icon: (
        <Icon
          type="solid"
          icon="trash-can"
        />
      ),
      label: <Trans>Delete</Trans>,
      onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        tableHooks.uc_setUserInterface_ConfirmDialogDisplay({
          display: true,
          confirm: {
            color: 'error',
            header: rLIB('Delete Battery'),
            icon: (
              <Icon
                icon="trash"
                type="solid"
              />
            ),
            submit_text: rLIB('Delete'),
            text: rLIB('Are you sure you want to delete this battery?'),
            submit_callback: () => {
              return new Promise((resolve, reject) => {
                getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey).then((res_GCK) => {
                  DatabaseSetMergeDocument(DatabaseRef_Battery_Document(res_GCK.clientKey, rowData.key as string), { status: 'deleted' })
                    .then((res_DDD) => {
                      resolve(res_DDD)
                    })
                    .catch((rej_DDD) => {
                      reject(rej_DDD)
                      tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DDD.error })
                    })
                })
              })
            },
          },
        })
      },
    },
  }),
  name: TableCellBasic('name', rLIB('Battery'), 'name'),
}

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Container
///////////////////////////////

export const Container: React.FC = (): JSX.Element => {
  // Props

  // Hooks - useContext, useState, useReducer, other
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)
  const { uc_setUserInterface_ErrorDialogDisplay } = useContext(Context_UserInterface_ErrorDialog)
  const { uc_setUserInterface_PromptDialogDisplay } = useContext(Context_UserInterface_PromptDialog)

  // Hooks - useEffect
  useEffect(() => {
    document.title = rLIB('Finance Partners', false) as string
  }, [])

  useEffect(() => {
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
    return () => {}
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

  // Other Variables
  const tableAdditionalData_System: TsInterface_TableAdditionalData = {}

  // Functions

  //database endpoints
  const tableDatabaseEndpoint_DesignPartners = (
    queryGenerationData: TsInterface_TableDatabaseEndpointQueryObject,
    tableAdditionalData: TsInterface_TableAdditionalData,
  ) => {
    let queryOperatorsArray: TsInterface_QueryOperatorsArray = [{ prop: 'status', comparator: '==', value: 'active' }]
    let orderByArray: TsInterface_OrderByArray = [{ prop: 'name', desc: false }]
    let queryCursorsObject: TsInterface_QueryCursorsObject = {}
    if (queryGenerationData['startAfter'] != null) {
      queryCursorsObject['startAfter'] = queryGenerationData.startAfter
    }
    if (queryGenerationData['startAt'] != null) {
      queryCursorsObject['startAt'] = queryGenerationData.startAt
    }
    if (queryGenerationData['endAt'] != null) {
      queryCursorsObject['endAt'] = queryGenerationData.endAt
    }
    if (queryGenerationData['endBefore'] != null) {
      queryCursorsObject['endBefore'] = queryGenerationData.endBefore
    }
    let limit = getProp(queryGenerationData, 'limit', 5)
    return generateDatabaseQuery(
      DatabaseRef_DesignPartners_Collection(uc_RootData_ClientKey as string),
      queryOperatorsArray,
      orderByArray,
      queryCursorsObject,
      limit,
    )
  }

  const promptObject_DesignPartner: TsInterface_PromptDialogObject = {
    color: 'success',
    confirm_text: rLIB('Create') as JSX.Element,
    default_value: null,
    header: rLIB('Create New Design Partner'),
    icon: (
      <Icon
        icon="pen"
        type="solid"
      />
    ),
    input_label: rLIB('Design Partner') as JSX.Element,
    input_type: 'text',
    text: rLIB('Enter a name for your new design partner'),
    submit_callback: (promptValue: string) => {
      return new Promise((resolve, reject) => {
        let designPartnerKey = keyFromString(promptValue)
        let updateObject = {
          name: promptValue,
          status: 'active',
          key: designPartnerKey,
        }
        getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
          .then((res_GCK) => {
            DatabaseSetMergeDocument(DatabaseRef_DesignPartner_Document(res_GCK.clientKey, designPartnerKey), updateObject)
              .then((res_DSMD) => {
                // Nothing
              })
              .catch((rej_DSMD) => {
                uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
              })
          })
          .catch((rej_GCK) => {
            uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
          })
        resolve({ success: true })
      })
    },
  }
  const tableDatabaseEndpoint_Modules = (
    queryGenerationData: TsInterface_TableDatabaseEndpointQueryObject,
    tableAdditionalData: TsInterface_TableAdditionalData,
  ) => {
    let queryOperatorsArray: TsInterface_QueryOperatorsArray = [{ prop: 'status', comparator: '==', value: 'active' }]
    let orderByArray: TsInterface_OrderByArray = [{ prop: 'name', desc: false }]
    let queryCursorsObject: TsInterface_QueryCursorsObject = {}
    if (queryGenerationData['startAfter'] != null) {
      queryCursorsObject['startAfter'] = queryGenerationData.startAfter
    }
    if (queryGenerationData['startAt'] != null) {
      queryCursorsObject['startAt'] = queryGenerationData.startAt
    }
    if (queryGenerationData['endAt'] != null) {
      queryCursorsObject['endAt'] = queryGenerationData.endAt
    }
    if (queryGenerationData['endBefore'] != null) {
      queryCursorsObject['endBefore'] = queryGenerationData.endBefore
    }
    let limit = getProp(queryGenerationData, 'limit', 5)
    return generateDatabaseQuery(DatabaseRef_Modules_Collection(uc_RootData_ClientKey as string), queryOperatorsArray, orderByArray, queryCursorsObject, limit)
  }

  const promptObject_Module: TsInterface_PromptDialogObject = {
    color: 'success',
    confirm_text: rLIB('Create') as JSX.Element,
    default_value: null,
    header: rLIB('Create New Inverter'),
    icon: (
      <Icon
        icon="transformer-bolt"
        type="solid"
      />
    ),
    input_label: rLIB('Inverter') as JSX.Element,
    input_type: 'text',
    text: rLIB('Enter a name for your new inverter'),
    submit_callback: (promptValue: string) => {
      return new Promise((resolve, reject) => {
        let moduleKey = keyFromString(promptValue)
        let updateObject = {
          name: promptValue,
          status: 'active',
          key: moduleKey,
        }
        getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
          .then((res_GCK) => {
            DatabaseSetMergeDocument(DatabaseRef_Module_Document(res_GCK.clientKey, moduleKey), updateObject)
              .then((res_DSMD) => {
                // Nothing
              })
              .catch((rej_DSMD) => {
                uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
              })
          })
          .catch((rej_GCK) => {
            uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
          })
        resolve({ success: true })
      })
    },
  }

  const promptObject_Inverters: TsInterface_PromptDialogObject = {
    color: 'success',
    confirm_text: rLIB('Create') as JSX.Element,
    default_value: null,
    header: rLIB('Create New Inverter'),
    icon: (
      <Icon
        icon="transformer-bolt"
        type="solid"
      />
    ),
    input_label: rLIB('Inverter') as JSX.Element,
    input_type: 'text',
    text: rLIB('Enter a name for your new inverter'),
    submit_callback: (promptValue: string) => {
      return new Promise((resolve, reject) => {
        let inverterKey = keyFromString(promptValue)
        let updateObject = {
          name: promptValue,
          status: 'active',
          key: inverterKey,
        }
        getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
          .then((res_GCK) => {
            DatabaseSetMergeDocument(DatabaseRef_Inverter_Document(res_GCK.clientKey, inverterKey), updateObject)
              .then((res_DSMD) => {
                // Nothing
              })
              .catch((rej_DSMD) => {
                uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
              })
          })
          .catch((rej_GCK) => {
            uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
          })
        resolve({ success: true })
      })
    },
  }

  const tableDatabaseEndpoint_Inverters = (
    queryGenerationData: TsInterface_TableDatabaseEndpointQueryObject,
    tableAdditionalData: TsInterface_TableAdditionalData,
  ) => {
    let queryOperatorsArray: TsInterface_QueryOperatorsArray = [{ prop: 'status', comparator: '==', value: 'active' }]
    let orderByArray: TsInterface_OrderByArray = [{ prop: 'name', desc: false }]
    let queryCursorsObject: TsInterface_QueryCursorsObject = {}
    if (queryGenerationData['startAfter'] != null) {
      queryCursorsObject['startAfter'] = queryGenerationData.startAfter
    }
    if (queryGenerationData['startAt'] != null) {
      queryCursorsObject['startAt'] = queryGenerationData.startAt
    }
    if (queryGenerationData['endAt'] != null) {
      queryCursorsObject['endAt'] = queryGenerationData.endAt
    }
    if (queryGenerationData['endBefore'] != null) {
      queryCursorsObject['endBefore'] = queryGenerationData.endBefore
    }
    let limit = getProp(queryGenerationData, 'limit', 5)
    return generateDatabaseQuery(
      DatabaseRef_Inverters_Collection(uc_RootData_ClientKey as string),
      queryOperatorsArray,
      orderByArray,
      queryCursorsObject,
      limit,
    )
  }

  const promptObject_Racking: TsInterface_PromptDialogObject = {
    color: 'success',
    confirm_text: rLIB('Create') as JSX.Element,
    default_value: null,
    header: rLIB('Create New Racking'),
    icon: (
      <Icon
        icon="brackets-square"
        type="solid"
      />
    ),
    input_label: rLIB('Racking') as JSX.Element,
    input_type: 'text',
    text: rLIB('Enter a name for your new racking'),
    submit_callback: (promptValue: string) => {
      return new Promise((resolve, reject) => {
        let rackingKey = keyFromString(promptValue)
        let updateObject = {
          name: promptValue,
          status: 'active',
          key: rackingKey,
        }
        getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
          .then((res_GCK) => {
            DatabaseSetMergeDocument(DatabaseRef_Racking_Document(res_GCK.clientKey, rackingKey), updateObject)
              .then((res_DSMD) => {
                // Nothing
              })
              .catch((rej_DSMD) => {
                uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
              })
          })
          .catch((rej_GCK) => {
            uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
          })
        resolve({ success: true })
      })
    },
  }

  const tableDatabaseEndpoint_Racking = (
    queryGenerationData: TsInterface_TableDatabaseEndpointQueryObject,
    tableAdditionalData: TsInterface_TableAdditionalData,
  ) => {
    let queryOperatorsArray: TsInterface_QueryOperatorsArray = [{ prop: 'status', comparator: '==', value: 'active' }]
    let orderByArray: TsInterface_OrderByArray = [{ prop: 'name', desc: false }]
    let queryCursorsObject: TsInterface_QueryCursorsObject = {}
    if (queryGenerationData['startAfter'] != null) {
      queryCursorsObject['startAfter'] = queryGenerationData.startAfter
    }
    if (queryGenerationData['startAt'] != null) {
      queryCursorsObject['startAt'] = queryGenerationData.startAt
    }
    if (queryGenerationData['endAt'] != null) {
      queryCursorsObject['endAt'] = queryGenerationData.endAt
    }
    if (queryGenerationData['endBefore'] != null) {
      queryCursorsObject['endBefore'] = queryGenerationData.endBefore
    }
    let limit = getProp(queryGenerationData, 'limit', 5)
    return generateDatabaseQuery(DatabaseRef_Racking_Collection(uc_RootData_ClientKey as string), queryOperatorsArray, orderByArray, queryCursorsObject, limit)
  }

  const promptObject_Batteries: TsInterface_PromptDialogObject = {
    color: 'success',
    confirm_text: rLIB('Create') as JSX.Element,
    default_value: null,
    header: rLIB('Create New Battery'),
    icon: (
      <Icon
        icon="battery-three-quarters"
        type="solid"
      />
    ),
    input_label: rLIB('Battery') as JSX.Element,
    input_type: 'text',
    text: rLIB('Enter a name for your new battery'),
    submit_callback: (promptValue: string) => {
      return new Promise((resolve, reject) => {
        let batteryKey = keyFromString(promptValue)
        let updateObject = {
          name: promptValue,
          status: 'active',
          key: batteryKey,
        }
        getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
          .then((res_GCK) => {
            DatabaseSetMergeDocument(DatabaseRef_Battery_Document(res_GCK.clientKey, batteryKey), updateObject)
              .then((res_DSMD) => {
                // Nothing
              })
              .catch((rej_DSMD) => {
                uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
              })
          })
          .catch((rej_GCK) => {
            uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
          })
        resolve({ success: true })
      })
    },
  }

  const tableDatabaseEndpoint_Batteries = (
    queryGenerationData: TsInterface_TableDatabaseEndpointQueryObject,
    tableAdditionalData: TsInterface_TableAdditionalData,
  ) => {
    let queryOperatorsArray: TsInterface_QueryOperatorsArray = [{ prop: 'status', comparator: '==', value: 'active' }]
    let orderByArray: TsInterface_OrderByArray = [{ prop: 'name', desc: false }]
    let queryCursorsObject: TsInterface_QueryCursorsObject = {}
    if (queryGenerationData['startAfter'] != null) {
      queryCursorsObject['startAfter'] = queryGenerationData.startAfter
    }
    if (queryGenerationData['startAt'] != null) {
      queryCursorsObject['startAt'] = queryGenerationData.startAt
    }
    if (queryGenerationData['endAt'] != null) {
      queryCursorsObject['endAt'] = queryGenerationData.endAt
    }
    if (queryGenerationData['endBefore'] != null) {
      queryCursorsObject['endBefore'] = queryGenerationData.endBefore
    }
    let limit = getProp(queryGenerationData, 'limit', 5)
    return generateDatabaseQuery(
      DatabaseRef_Batteries_Collection(uc_RootData_ClientKey as string),
      queryOperatorsArray,
      orderByArray,
      queryCursorsObject,
      limit,
    )
  }

  // JSX Generation
  //buttons
  const rJSX_NewDesignPartnerButton = (): JSX.Element => {
    return (
      <Button
        color="success"
        variant="contained"
        onClick={() => {
          uc_setUserInterface_PromptDialogDisplay({
            display: true,
            prompt: promptObject_DesignPartner,
          })
        }}
        disableElevation
        startIcon={
          <Icon
            icon="circle-plus"
            type="solid"
          />
        }
        sx={{ marginRight: 2 }}
      >
        {rLIB('New Design Partner')}
      </Button>
    )
  }

  const rJSX_NewModulesButton = (): JSX.Element => {
    return (
      <Button
        color="success"
        variant="contained"
        onClick={() => {
          uc_setUserInterface_PromptDialogDisplay({
            display: true,
            prompt: promptObject_Module,
          })
        }}
        disableElevation
        startIcon={
          <Icon
            icon="circle-plus"
            type="solid"
          />
        }
        sx={{ marginRight: 2 }}
      >
        {rLIB('New Module')}
      </Button>
    )
  }

  const rJSX_NewInvertersButton = (): JSX.Element => {
    return (
      <Button
        color="success"
        variant="contained"
        onClick={() => {
          uc_setUserInterface_PromptDialogDisplay({
            display: true,
            prompt: promptObject_Inverters,
          })
        }}
        disableElevation
        startIcon={
          <Icon
            icon="circle-plus"
            type="solid"
          />
        }
        sx={{ marginRight: 2 }}
      >
        {rLIB('New Inverter')}
      </Button>
    )
  }

  const rJSX_NewRackingButton = (): JSX.Element => {
    return (
      <Button
        color="success"
        variant="contained"
        onClick={() => {
          uc_setUserInterface_PromptDialogDisplay({
            display: true,
            prompt: promptObject_Racking,
          })
        }}
        disableElevation
        startIcon={
          <Icon
            icon="circle-plus"
            type="solid"
          />
        }
        sx={{ marginRight: 2 }}
      >
        {rLIB('New Racking')}
      </Button>
    )
  }

  const rJSX_NewBatteriesButton = (): JSX.Element => {
    return (
      <Button
        color="success"
        variant="contained"
        onClick={() => {
          uc_setUserInterface_PromptDialogDisplay({
            display: true,
            prompt: promptObject_Batteries,
          })
        }}
        disableElevation
        startIcon={
          <Icon
            icon="circle-plus"
            type="solid"
          />
        }
        sx={{ marginRight: 2 }}
      >
        {rLIB('New Battery')}
      </Button>
    )
  }

  //system tables
  const rJSX_DesignTable_Modules = (): JSX.Element => {
    let tableJSX = <></>
    if (uc_RootData_ClientKey != null) {
      tableJSX = (
        <Card>
          <TableDatabase
            tableAdditionalData={tableAdditionalData_System}
            tableColumns={tableColumns_Modules}
            tableDatabaseEndpoint={tableDatabaseEndpoint_Modules}
            tableSettings={tableSettings_Design}
          />
        </Card>
      )
    } else {
      tableJSX = <></>
    }
    return tableJSX
  }

  const rJSX_DesignTable_DesignPartners = (): JSX.Element => {
    let tableJSX = <></>
    if (uc_RootData_ClientKey != null) {
      tableJSX = (
        <Card>
          <TableDatabase
            tableAdditionalData={tableAdditionalData_System}
            tableColumns={tableColumns_DesignPartners}
            tableDatabaseEndpoint={tableDatabaseEndpoint_DesignPartners}
            tableSettings={tableSettings_Design}
          />
        </Card>
      )
    } else {
      tableJSX = <></>
    }
    return tableJSX
  }

  const rJSX_DesignTable_Inverters = (): JSX.Element => {
    let tableJSX = <></>
    if (uc_RootData_ClientKey != null) {
      tableJSX = (
        <Card>
          <TableDatabase
            tableAdditionalData={tableAdditionalData_System}
            tableColumns={tableColumns_Inverters}
            tableDatabaseEndpoint={tableDatabaseEndpoint_Inverters}
            tableSettings={tableSettings_Design}
          />
        </Card>
      )
    } else {
      tableJSX = <></>
    }
    return tableJSX
  }

  const rJSX_DesignTable_Racking = (): JSX.Element => {
    let tableJSX = <></>
    if (uc_RootData_ClientKey != null) {
      tableJSX = (
        <Card>
          <TableDatabase
            tableAdditionalData={tableAdditionalData_System}
            tableColumns={tableColumns_Racking}
            tableDatabaseEndpoint={tableDatabaseEndpoint_Racking}
            tableSettings={tableSettings_Design}
          />
        </Card>
      )
    } else {
      tableJSX = <></>
    }
    return tableJSX
  }

  const rJSX_DesignTable_Batteries = (): JSX.Element => {
    let tableJSX = <></>
    if (uc_RootData_ClientKey != null) {
      tableJSX = (
        <Card>
          <TableDatabase
            tableAdditionalData={tableAdditionalData_System}
            tableColumns={tableColumns_Batteries}
            tableDatabaseEndpoint={tableDatabaseEndpoint_Batteries}
            tableSettings={tableSettings_Design}
          />
        </Card>
      )
    } else {
      tableJSX = <></>
    }
    return tableJSX
  }

  //import
  const rJSX_ImportDesignPartnerButton = (shrink: boolean): JSX.Element => {
    let buttonJSX = (
      <BasicImportButtonAndDialog
        importAdditionalData={{}}
        importButtonColor={'info'}
        importButtonShrink={shrink}
        importButtonText={rLIB('Import Design Partners')}
        importDialogHeader={rLIB('Import Design Partners')}
        importMappingOptions={{
          name: { key: 'name', required: true, label: rLIB('Name'), automatch_properties: ['Name'] },
        }}
        importSubmission={(spreadsheetData, importAdditionalData, importHooks) => {
          return new Promise((resolve, reject) => {
            try {
              getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                .then((res_GCK) => {
                  // Create Update Array
                  let updateArray: TsInterface_DatabaseBatchUpdatesArray = []
                  for (let loopRowKey in spreadsheetData) {
                    updateArray.push({
                      type: 'setMerge',
                      ref: DatabaseRef_DesignPartner_Document(res_GCK.clientKey as string, keyFromString(spreadsheetData[loopRowKey].name)),
                      data: { name: spreadsheetData[loopRowKey].name, status: 'active', key: keyFromString(spreadsheetData[loopRowKey].name) },
                    })
                  }
                  // Save to Database
                  DatabaseStagedBatchUpdate(updateArray)
                    .then((res_DSBU) => {
                      resolve(res_DSBU)
                    })
                    .catch((rej_DSBU) => {
                      console.error('Batch Update Error:', rej_DSBU)
                      uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSBU.error })
                      reject(rej_DSBU)
                    })
                })
                .catch((rej_GCK) => {
                  console.error('Client Key Error:', rej_GCK)
                  uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                  reject(rej_GCK)
                })
            } catch (error) {
              console.error('Error during import submission:', error)
              reject(error)
            }
          })
        }}
      />
    )
    return buttonJSX
  }

  const rJSX_ImportModulesButton = (shrink: boolean): JSX.Element => {
    let buttonJSX = (
      <BasicImportButtonAndDialog
        importAdditionalData={{}}
        importButtonColor={'info'}
        importButtonShrink={shrink}
        importButtonText={rLIB('Import Modules')}
        importDialogHeader={rLIB('Import Modules')}
        importMappingOptions={{
          name: { key: 'name', required: true, label: rLIB('Name'), automatch_properties: ['Name'] },
        }}
        importSubmission={(spreadsheetData, importAdditionalData, importHooks) => {
          return new Promise((resolve, reject) => {
            try {
              getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                .then((res_GCK) => {
                  // Create Update Array
                  let updateArray: TsInterface_DatabaseBatchUpdatesArray = []
                  for (let loopRowKey in spreadsheetData) {
                    updateArray.push({
                      type: 'setMerge',
                      ref: DatabaseRef_Module_Document(res_GCK.clientKey as string, keyFromString(spreadsheetData[loopRowKey].name)),
                      data: { name: spreadsheetData[loopRowKey].name, status: 'active', key: keyFromString(spreadsheetData[loopRowKey].name) },
                    })
                  }
                  // Save to Database
                  DatabaseStagedBatchUpdate(updateArray)
                    .then((res_DSBU) => {
                      resolve(res_DSBU)
                    })
                    .catch((rej_DSBU) => {
                      console.error('Batch Update Error:', rej_DSBU)
                      uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSBU.error })
                      reject(rej_DSBU)
                    })
                })
                .catch((rej_GCK) => {
                  console.error('Client Key Error:', rej_GCK)
                  uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                  reject(rej_GCK)
                })
            } catch (error) {
              console.error('Error during import submission:', error)
              reject(error)
            }
          })
        }}
      />
    )
    return buttonJSX
  }

  const rJSX_ImportInvertersButton = (shrink: boolean): JSX.Element => {
    let buttonJSX = (
      <BasicImportButtonAndDialog
        importAdditionalData={{}}
        importButtonColor={'info'}
        importButtonShrink={shrink}
        importButtonText={rLIB('Import Inverters')}
        importDialogHeader={rLIB('Import Inverters')}
        importMappingOptions={{
          name: { key: 'name', required: true, label: rLIB('Name'), automatch_properties: ['Name'] },
        }}
        importSubmission={(spreadsheetData, importAdditionalData, importHooks) => {
          return new Promise((resolve, reject) => {
            try {
              getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                .then((res_GCK) => {
                  // Create Update Array
                  let updateArray: TsInterface_DatabaseBatchUpdatesArray = []
                  for (let loopRowKey in spreadsheetData) {
                    updateArray.push({
                      type: 'setMerge',
                      ref: DatabaseRef_Inverter_Document(res_GCK.clientKey as string, keyFromString(spreadsheetData[loopRowKey].name)),
                      data: { name: spreadsheetData[loopRowKey].name, status: 'active', key: keyFromString(spreadsheetData[loopRowKey].name) },
                    })
                  }
                  // Save to Database
                  DatabaseStagedBatchUpdate(updateArray)
                    .then((res_DSBU) => {
                      resolve(res_DSBU)
                    })
                    .catch((rej_DSBU) => {
                      console.error('Batch Update Error:', rej_DSBU)
                      uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSBU.error })
                      reject(rej_DSBU)
                    })
                })
                .catch((rej_GCK) => {
                  console.error('Client Key Error:', rej_GCK)
                  uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                  reject(rej_GCK)
                })
            } catch (error) {
              console.error('Error during import submission:', error)
              reject(error)
            }
          })
        }}
      />
    )
    return buttonJSX
  }

  const rJSX_ImportRackingButton = (shrink: boolean): JSX.Element => {
    let buttonJSX = (
      <BasicImportButtonAndDialog
        importAdditionalData={{}}
        importButtonColor={'info'}
        importButtonShrink={shrink}
        importButtonText={rLIB('Import Rackings')}
        importDialogHeader={rLIB('Import Rackings')}
        importMappingOptions={{
          name: { key: 'name', required: true, label: rLIB('Name'), automatch_properties: ['Name'] },
        }}
        importSubmission={(spreadsheetData, importAdditionalData, importHooks) => {
          return new Promise((resolve, reject) => {
            try {
              getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                .then((res_GCK) => {
                  // Create Update Array
                  let updateArray: TsInterface_DatabaseBatchUpdatesArray = []
                  for (let loopRowKey in spreadsheetData) {
                    updateArray.push({
                      type: 'setMerge',
                      ref: DatabaseRef_Racking_Document(res_GCK.clientKey as string, keyFromString(spreadsheetData[loopRowKey].name)),
                      data: { name: spreadsheetData[loopRowKey].name, status: 'active', key: keyFromString(spreadsheetData[loopRowKey].name) },
                    })
                  }
                  // Save to Database
                  DatabaseStagedBatchUpdate(updateArray)
                    .then((res_DSBU) => {
                      resolve(res_DSBU)
                    })
                    .catch((rej_DSBU) => {
                      console.error('Batch Update Error:', rej_DSBU)
                      uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSBU.error })
                      reject(rej_DSBU)
                    })
                })
                .catch((rej_GCK) => {
                  console.error('Client Key Error:', rej_GCK)
                  uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                  reject(rej_GCK)
                })
            } catch (error) {
              console.error('Error during import submission:', error)
              reject(error)
            }
          })
        }}
      />
    )
    return buttonJSX
  }

  const rJSX_ImportBatteriesButton = (shrink: boolean): JSX.Element => {
    let buttonJSX = (
      <BasicImportButtonAndDialog
        importAdditionalData={{}}
        importButtonColor={'info'}
        importButtonShrink={shrink}
        importButtonText={rLIB('Import Batteries')}
        importDialogHeader={rLIB('Import Batteries')}
        importMappingOptions={{
          name: { key: 'name', required: true, label: rLIB('Name'), automatch_properties: ['Name'] },
        }}
        importSubmission={(spreadsheetData, importAdditionalData, importHooks) => {
          return new Promise((resolve, reject) => {
            try {
              getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                .then((res_GCK) => {
                  // Create Update Array
                  let updateArray: TsInterface_DatabaseBatchUpdatesArray = []
                  for (let loopRowKey in spreadsheetData) {
                    updateArray.push({
                      type: 'setMerge',
                      ref: DatabaseRef_Battery_Document(res_GCK.clientKey as string, keyFromString(spreadsheetData[loopRowKey].name)),
                      data: { name: spreadsheetData[loopRowKey].name, status: 'active', key: keyFromString(spreadsheetData[loopRowKey].name) },
                    })
                  }
                  // Save to Database
                  DatabaseStagedBatchUpdate(updateArray)
                    .then((res_DSBU) => {
                      resolve(res_DSBU)
                    })
                    .catch((rej_DSBU) => {
                      console.error('Batch Update Error:', rej_DSBU)
                      uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSBU.error })
                      reject(rej_DSBU)
                    })
                })
                .catch((rej_GCK) => {
                  console.error('Client Key Error:', rej_GCK)
                  uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                  reject(rej_GCK)
                })
            } catch (error) {
              console.error('Error during import submission:', error)
              reject(error)
            }
          })
        }}
      />
    )
    return buttonJSX
  }

  const rJSX_Page = (): JSX.Element => {
    let pageJSX = (
      <AuthenticatedContainer
        pageHeader={rLIB('System')}
        pageKey={pageKey}
        content={
          <Box>
            <TabsUrl
              tabsSettings={{
                baseUrl: ApplicationPages.DesignIndexPage.url(),
                tabQueryParam: 'tab',
                overridePageTitle: true,
                basePageTitle: rLIB('System', false) as string,
              }}
              tabs={[
                {
                  tabHeader: rLIB('Design Partners'),
                  tabUrlKey: 'design_partners',
                  tabButtons: [
                    {
                      fullJSX: (
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                          <Box>
                            {rJSX_NewDesignPartnerButton()}
                            {rJSX_ImportDesignPartnerButton(true)}
                          </Box>
                        </Box>
                      ),
                      minJSX: (
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                          <Box>
                            {rJSX_NewDesignPartnerButton()}
                            {rJSX_ImportDesignPartnerButton(true)}
                          </Box>
                        </Box>
                      ),
                      sizeCutoff: 0,
                    },
                  ],
                  tabContent: rJSX_DesignTable_DesignPartners(),
                },
                {
                  tabHeader: rLIB('Modules'),
                  tabUrlKey: 'modules',
                  tabButtons: [
                    {
                      fullJSX: (
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                          <Box>
                            {rJSX_NewModulesButton()}
                            {rJSX_ImportModulesButton(true)}
                          </Box>
                        </Box>
                      ),
                      minJSX: (
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                          <Box>
                            {rJSX_NewModulesButton()}
                            {rJSX_ImportModulesButton(true)}
                          </Box>
                        </Box>
                      ),
                      sizeCutoff: 0,
                    },
                  ],
                  tabContent: rJSX_DesignTable_Modules(),
                },
                {
                  tabHeader: rLIB('Inverters'),
                  tabUrlKey: 'inverters',
                  tabButtons: [
                    {
                      fullJSX: (
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                          <Box>
                            {rJSX_NewInvertersButton()}
                            {rJSX_ImportInvertersButton(true)}
                          </Box>
                        </Box>
                      ),
                      minJSX: (
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                          <Box>
                            {rJSX_NewInvertersButton()}
                            {rJSX_ImportInvertersButton(true)}
                          </Box>
                        </Box>
                      ),
                      sizeCutoff: 0,
                    },
                  ],
                  tabContent: rJSX_DesignTable_Inverters(),
                },
                {
                  tabHeader: rLIB('Racking'),
                  tabUrlKey: 'racking',
                  tabButtons: [
                    {
                      fullJSX: (
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                          <Box>
                            {rJSX_NewRackingButton()}
                            {rJSX_ImportRackingButton(true)}
                          </Box>
                        </Box>
                      ),
                      minJSX: (
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                          <Box>
                            {rJSX_NewRackingButton()}
                            {rJSX_ImportRackingButton(true)}
                          </Box>
                        </Box>
                      ),
                      sizeCutoff: 0,
                    },
                  ],
                  tabContent: rJSX_DesignTable_Racking(),
                },
                {
                  tabHeader: rLIB('Batteries'),
                  tabUrlKey: 'batteries',
                  tabButtons: [
                    {
                      fullJSX: (
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                          <Box>
                            {rJSX_NewBatteriesButton()}
                            {rJSX_ImportBatteriesButton(true)}
                          </Box>
                        </Box>
                      ),
                      minJSX: (
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                          <Box>
                            {rJSX_NewBatteriesButton()}
                            {rJSX_ImportBatteriesButton(true)}
                          </Box>
                        </Box>
                      ),
                      sizeCutoff: 0,
                    },
                  ],
                  tabContent: rJSX_DesignTable_Batteries(),
                },
              ]}
            />
          </Box>
        }
      />
    )
    return pageJSX
  }

  // Render
  return <>{rJSX_Page()}</>
}
