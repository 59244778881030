///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			containers are pages / views used in the app and are made up of components and can interact with services and models

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import {
  AppBar,
  Box,
  Button,
  Card,
  Dialog,
  DialogContent,
  Divider,
  FormControl,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  Slide,
  TextField,
  Toolbar,
  Typography,
} from '@mui/material/'
import { TransitionProps } from '@mui/material/transitions'
import React, { useContext, useEffect, useReducer, useState } from 'react'
import { Trans } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { AuthenticatedContainer } from 'rfbp_aux/containers/authenticated_container'
import { ApplicationPages } from 'rfbp_aux/data/application_structure'
import {
  DatabaseRef_TrailerFiles_Collection,
  DatabaseRef_TrailerFile_Document,
  DatabaseRef_TrailerForumMessages_Collection,
  DatabaseRef_TrailerForumMessage_Document,
  DatabaseRef_TrailerForumThreads_Collection,
  DatabaseRef_TrailerForumThread_Document,
  DatabaseRef_Trailer_Document,
} from 'rfbp_aux/services/database_endpoints/directory/trailers'
import {
  DatabaseRef_TrailerAssignmentHistory_Document,
  DatabaseRef_TrailerAssignmentHistory_SpecificTrailer_Query,
} from 'rfbp_aux/services/database_endpoints/operations/trailer_assignment_history'
import { StorageRef_TrailerFile, StorageRef_Trailer_MessageThread_File } from 'rfbp_aux/services/storage_endpoints/trailers'
import { FileSystemBasic } from 'rfbp_core/components/file_system/file_system_basic'
import {
  TsInterface_FormAdditionalData,
  TsInterface_FormData,
  TsInterface_FormHooksObject,
  TsInterface_FormInputs,
  TsInterface_FormSettings,
  TsInterface_FormSubmittedData,
} from 'rfbp_core/components/form'
import { TsInterface_InputHooksObject } from 'rfbp_core/components/form/form_types'
import { ForumBasic } from 'rfbp_core/components/forum/forum_basic'
import { Icon } from 'rfbp_core/components/icons'
import { rJSX_HighlightedSearchString, SearchInput } from 'rfbp_core/components/search'
import {
  TableBasic,
  TableCellBasic,
  TableCellManage,
  TableCellTimestamp,
  TsInterface_TableAdditionalData,
  TsInterface_TableDataRow,
  TsInterface_TableHooks,
  TsInterface_TableSettings,
} from 'rfbp_core/components/table'
import { TabsUrl } from 'rfbp_core/components/tabs'
import { rLIB } from 'rfbp_core/localization/library'
import {
  Context_RootData_ClientKey,
  Context_UserInterface_CustomDialog,
  Context_UserInterface_ErrorDialog,
  Context_UserInterface_FormDialog,
  UserInterface_Default_CustomDialogDisplayState,
} from 'rfbp_core/services/context'
import {
  DatabaseBatchUpdate,
  DatabaseDeleteDocument,
  DatabaseGetLiveCollection,
  DatabaseGetLiveDocument,
  DatabaseSetMergeDocument,
  TsInterface_DatabaseBatchUpdatesArray,
} from 'rfbp_core/services/database_management'
import { cloneObjectWithoutReference, dynamicSort, getProp, objectToArray, returnDateFromUnknownDateFormat } from 'rfbp_core/services/helper_functions'
import { onClickAppNavigation } from 'rfbp_core/services/navigation/navigation_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject, TsType_VoidFunction } from 'rfbp_core/typescript/global_types'
import { v4 as uuidv4 } from 'uuid'

///////////////////////////////
// Typescript
///////////////////////////////

interface TsInterfaceNewDriverAssignmentCustomDialog {
  us_trailer: TsInterface_UnspecifiedObject
}

///////////////////////////////
// Variables
///////////////////////////////

// Authenticated Nav Data
const pageKey: string = ApplicationPages['AdminDatabaseTrailerViewPage']['key']

// Displayed Translatable Strings
// { sort-start } - displayed text - scoped sort plugin
const s_ADD_DRIVER_ASSIGNMENT: JSX.Element = <Trans>Add Driver Assignment</Trans>
const s_ARE_YOU_SURE_THAT_YOU_WANT_TO_DELETE_THIS_CUSTODY_RECORD: JSX.Element = <Trans>Are you sure that you want to delete this custody record?</Trans>
const s_ASSIGNMENT: JSX.Element = <Trans>Assignment</Trans>
const s_BACK_TO_ALL_TRAILERS: JSX.Element = <Trans>Back to all trailers</Trans>
const s_CURRENTLY_ASSIGNED_TO: JSX.Element = <Trans>Currently assigned to</Trans>
const s_CUSTODY_END_DATE: JSX.Element = <Trans>Custody End Date</Trans>
const s_CUSTODY_START_DATE: JSX.Element = <Trans>Custody Start Date</Trans>
const s_DELETE: JSX.Element = <Trans>Delete</Trans>
const s_DELETE_CUSTODY_RECORD: JSX.Element = <Trans>Delete Custody Record</Trans>
const s_DETAILS: JSX.Element = <Trans>Details</Trans>
const s_DONT_CHANGE_ACTIVE_ASSIGNED_DRIVER: JSX.Element = <Trans>Don't Change Active Assigned Driver</Trans>
const s_EDIT: JSX.Element = <Trans>Edit</Trans>
const s_EDIT_TRAILER: JSX.Element = <Trans>Edit Trailer</Trans>
const s_EMPLOYEE: JSX.Element = <Trans>Employee</Trans>
const s_FILES: JSX.Element = <Trans>Files</Trans>
const s_LICENSE_PLATE: JSX.Element = <Trans>License Plate</Trans>
const s_MISSING: JSX.Element = <Trans>Missing</Trans>
const s_MODEL: JSX.Element = <Trans>Model</Trans>
const s_NOTES: JSX.Element = <Trans>Notes</Trans>
const s_TRAILER: JSX.Element = <Trans>Trailer</Trans>
const s_TRAILER_DOESNT_HAVE_AN_ASSIGNMENT_HISTORY_YET: JSX.Element = <Trans>Trailer doesn't have an assignment history yet</Trans>
const s_UPDATE_ACTIVE_ASSIGNED_DRIVER: JSX.Element = <Trans>Update Active Assigned Driver</Trans>
const s_USER: JSX.Element = <Trans>User</Trans>
const s_VIN: JSX.Element = <Trans>VIN</Trans>
const s_YEAR: JSX.Element = <Trans>Year</Trans>
const s_TAG_EXPIRATION: JSX.Element = <Trans>Tag Expiration</Trans>
const s_COLOR: JSX.Element = <Trans>Color</Trans>
const s_TRAILER_NAME: JSX.Element = <Trans>Trailer Name</Trans>
const se_TRAILER: string = 'Trailer'
// { sort-end } - displayed text

// Forms
const formInputs_TrailerLicenseName: TsInterface_FormInputs = {
  name: {
    data_type: 'string',
    input_type: 'text_basic',
    key: 'name',
    label: s_TRAILER_NAME,
    required: true,
  },
}
const formInputs_TrailerLicensePlate: TsInterface_FormInputs = {
  license_plate: {
    data_type: 'string',
    input_type: 'text_basic',
    key: 'license_plate',
    label: s_LICENSE_PLATE,
    required: true,
  },
}

const formInputs_TrailerVin: TsInterface_FormInputs = {
  vin: {
    data_type: 'string',
    input_type: 'text_basic',
    key: 'vin',
    label: s_VIN,
    required: true,
  },
}

const formInputs_TrailerTagExpiration: TsInterface_FormInputs = {
  trailer_tag_expiration: {
    data_type: 'string',
    input_type: 'timestamp_date',
    key: 'trailer_tag_expiration',
    label: s_TAG_EXPIRATION,
    required: true,
  },
}

const formInputs_TrailerColor: TsInterface_FormInputs = {
  trailer_color: {
    data_type: 'string',
    input_type: 'text_basic',
    key: 'trailer_color',
    label: s_COLOR,
    required: true,
  },
}

const formInputs_TrailerMake: TsInterface_FormInputs = {
  trailer_make: {
    data_type: 'string',
    input_type: 'text_basic',
    key: 'trailer_make',
    label: rLIB('Make'),
    required: true,
  },
}

const formInputs_TrailerModel: TsInterface_FormInputs = {
  trailer_model: {
    data_type: 'string',
    input_type: 'text_basic',
    key: 'trailer_model',
    label: s_MODEL,
    required: true,
  },
}

const formInputs_TrailerYear: TsInterface_FormInputs = {
  trailer_year: {
    data_type: 'string',
    input_type: 'text_basic',
    key: 'trailer_year',
    label: s_YEAR,
    required: true,
  },
}

// Tables
const tableSettings_AssignmentHistoryData: TsInterface_TableSettings = {
  paginated: false,
  show_header: true,
  size: 'small',
  sort_direction: 'asc',
  sort_property_default: 'timestamp_start',
  sortable: false,
}

const tableColumns_AssignmentHistoryData = {
  manage: TableCellManage({
    delete: {
      icon: <Icon icon="trash" />,
      label: <>{s_DELETE}</>,
      onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        tableHooks.uc_setUserInterface_ConfirmDialogDisplay({
          display: true,
          confirm: {
            color: 'error',
            header: s_DELETE_CUSTODY_RECORD,
            icon: (
              <Icon
                icon="trash"
                type="solid"
              />
            ),
            submit_text: s_DELETE,
            text: s_ARE_YOU_SURE_THAT_YOU_WANT_TO_DELETE_THIS_CUSTODY_RECORD,
            submit_callback: () => {
              return new Promise((resolve, reject) => {
                getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey).then((res_GCK) => {
                  DatabaseDeleteDocument(DatabaseRef_TrailerAssignmentHistory_Document(res_GCK.clientKey, rowData.key as string))
                    .then((res_DDD) => {
                      resolve(res_DDD)
                    })
                    .catch((rej_DDD) => {
                      reject(rej_DDD)
                      tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DDD.error })
                    })
                })
              })
            },
          },
        })
      },
    },
  }),
  associated_user_name: TableCellBasic('associated_user_name', s_EMPLOYEE, 'associated_user_name'),
  timestamp_start: TableCellTimestamp('timestamp_start', s_CUSTODY_START_DATE, 'timestamp_start', 'D MMM YYYY h:mm a', true),
  timestamp_end: TableCellTimestamp('timestamp_end', s_CUSTODY_END_DATE, 'timestamp_end', 'D MMM YYYY h:mm a', true),
}

// Dialogs
const NewDriverAssignmentCustomDialog: React.FC<TsInterfaceNewDriverAssignmentCustomDialog> = ({ us_trailer }): JSX.Element => {
  // Props

  // Hooks - useContext, useState, useReducer, other
  // { sort-start } - hooks
  const [us_newDriverAssignmentDate, us_setNewDriverAssignmentDate] = useState<string | null>(null)
  const [us_newDriverAssignmentDriverKey, us_setNewDriverAssignmentDriverKey] = useState<string | null>(null)
  const [us_newDriverAssignmentDriverName, us_setNewDriverAssignmentDriverName] = useState<string | null>(null)
  const [us_newDriverAssignmentUpdateRoot, us_setNewDriverAssignmentUpdateRoot] = useState<boolean>(true)
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)
  const { uc_setUserInterface_CustomDialogDisplay } = useContext(Context_UserInterface_CustomDialog)
  const { uc_setUserInterface_ErrorDialogDisplay } = useContext(Context_UserInterface_ErrorDialog)
  // { sort-end } - hooks

  // Hooks - useEffect
  useEffect(() => {
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
    return () => {}
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

  useEffect(() => {
    // Get Date Props
    let date = new Date()
    let year = date.getFullYear()
    let month = date.getMonth() + 1
    let day = date.getDate()
    let hours = date.getHours() + 1
    let minutes = date.getMinutes()
    // Generate Composite
    let compositeDate = year.toString() + '-'
    if (month < 10) {
      compositeDate += '0' + month.toString() + '-'
    } else {
      compositeDate += month.toString() + '-'
    }
    if (day < 10) {
      compositeDate += '0' + day.toString()
    } else {
      compositeDate += day.toString()
    }
    compositeDate += 'T'
    if (hours < 10) {
      // @ts-expect-error - TODO: reason for error
      hours = '0' + hours.toString()
    }
    compositeDate += hours
    if (minutes < 10) {
      // @ts-expect-error - TODO: reason for error
      minutes = '0' + minutes.toString()
    } else {
      // @ts-expect-error - TODO: reason for error
      minutes = minutes.toString()
    }
    compositeDate += ':'
    compositeDate += minutes
    // Set to field value
    us_setNewDriverAssignmentDate(compositeDate)
    return () => {}
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

  // Functions

  // JSX Generation
  const rJSX_UserSearchResult = (
    option: TsInterface_UnspecifiedObject,
    searchInputValue: string | null,
    inputHooks: TsInterface_InputHooksObject,
    additionalSearchData: TsInterface_UnspecifiedObject,
  ): JSX.Element => {
    let searchResultJSX = (
      <Box
        sx={{ marginLeft: '8px', marginRight: '8px' }}
        onClick={() => {
          us_setNewDriverAssignmentDriverKey(option.id)
          us_setNewDriverAssignmentDriverName(option.name)
        }}
      >
        <Typography className="tw-cursor-pointer">{rJSX_HighlightedSearchString(searchInputValue, option.name)} </Typography>
      </Box>
    )
    return searchResultJSX
  }

  const rJSX_DatePicker = (): JSX.Element => {
    let datePickerJSX = (
      <Box className="tw-align-top">
        <FormControl sx={{ width: '100%' }}>
          <TextField
            sx={{
              width: '100%',
            }}
            color="primary"
            value={us_newDriverAssignmentDate || ''}
            label={s_CUSTODY_START_DATE}
            margin="normal"
            onChange={(event: any) => {
              if (event != null && event.target != null && event.target.value != null) {
                us_setNewDriverAssignmentDate(event.target.value)
              }
            }}
            type="datetime-local"
            variant="outlined"
            InputLabelProps={{ shrink: true }}
          />
        </FormControl>
      </Box>
    )
    return datePickerJSX
  }

  const rJSX_UpdateRootSelection = (): JSX.Element => {
    let updateRootSelectionJSX = <></>
    updateRootSelectionJSX = (
      <FormControl fullWidth>
        <RadioGroup
          onChange={(event, value) => {
            if (value != null) {
              us_setNewDriverAssignmentUpdateRoot(value as unknown as boolean)
            }
          }}
          value={us_newDriverAssignmentUpdateRoot}
          sx={{ display: 'block' }}
        >
          <FormControlLabel
            sx={{ display: 'flex' }}
            control={<Radio />}
            label={s_UPDATE_ACTIVE_ASSIGNED_DRIVER}
            value={true}
          />
          <FormControlLabel
            sx={{ display: 'flex' }}
            control={<Radio />}
            label={s_DONT_CHANGE_ACTIVE_ASSIGNED_DRIVER}
            value={false}
          />
        </RadioGroup>
      </FormControl>
    )
    return updateRootSelectionJSX
  }

  const rJSX_UserSelection = (): JSX.Element => {
    let userSelectionJSX = <></>
    if (us_newDriverAssignmentDriverKey == null || us_newDriverAssignmentDriverName == null) {
      userSelectionJSX = (
        <Box>
          <SearchInput
            clientKey={uc_RootData_ClientKey as string}
            searchIndexKey={'users'}
            searchFilters={['status = active']}
            searchResultRenderer={rJSX_UserSearchResult}
            additionalSearchData={{}}
            defaultSearchValue={''}
            sx={{ minWidth: '100%' }}
          />
          <Box className="tw-mt-4">{rJSX_DatePicker()}</Box>
          <Box className="tw-mt-4">{rJSX_UpdateRootSelection()}</Box>
        </Box>
      )
    } else {
      userSelectionJSX = (
        <Box>
          <Typography variant="h6">
            {s_USER}: {us_newDriverAssignmentDriverName}
          </Typography>
          <Box className="tw-mt-4">{rJSX_DatePicker()}</Box>
          <Box className="tw-mt-4">{rJSX_UpdateRootSelection()}</Box>
        </Box>
      )
    }
    return userSelectionJSX
  }

  const rJSX_SubmitButton = (): JSX.Element => {
    let buttonJSX = <></>
    buttonJSX = (
      <Button
        variant="contained"
        className="tw-mt-2"
        color="success"
        disabled={us_newDriverAssignmentDate == null || us_newDriverAssignmentDriverKey == null || us_newDriverAssignmentDriverName == null}
        onClick={() => {
          if (us_newDriverAssignmentDate != null || us_newDriverAssignmentDriverKey != null || us_newDriverAssignmentDriverName != null) {
            let rootUpdateObject = {
              associated_user_key: us_newDriverAssignmentDriverKey,
              associated_user_name: us_newDriverAssignmentDriverName,
            }
            let logKey = uuidv4()
            let updateObject = {
              key: logKey,
              associated_user_key: us_newDriverAssignmentDriverKey,
              associated_user_name: us_newDriverAssignmentDriverName,
              associated_trailer_key: us_trailer.key,
              timestamp_start: new Date(us_newDriverAssignmentDate as string),
            }
            getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
              .then((res_GCK) => {
                let updateArray: TsInterface_DatabaseBatchUpdatesArray = [
                  {
                    type: 'setMerge',
                    ref: DatabaseRef_TrailerAssignmentHistory_Document(res_GCK.clientKey, logKey),
                    data: updateObject,
                  },
                ]
                if (us_newDriverAssignmentUpdateRoot === true) {
                  updateArray.push({
                    type: 'setMerge',
                    ref: DatabaseRef_Trailer_Document(res_GCK.clientKey, us_trailer.key),
                    data: rootUpdateObject,
                  })
                }
                DatabaseBatchUpdate(updateArray)
                  .then((res_DBU) => {
                    uc_setUserInterface_CustomDialogDisplay(UserInterface_Default_CustomDialogDisplayState)
                  })
                  .catch((rej_DBU) => {
                    uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DBU.error })
                  })
              })
              .catch((rej_GCK) => {
                uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
              })
          }
        }}
      >
        <Icon
          icon="user-plus"
          className="tw-mr-2"
        />
        {s_ADD_DRIVER_ASSIGNMENT}
      </Button>
    )
    return buttonJSX
  }

  const rJSX_Dialog = (): JSX.Element => {
    let dialogJSX = (
      <Box>
        <Dialog
          className="bp_dialog_lg_width"
          TransitionComponent={Transition}
          keepMounted
          onClose={() => {
            uc_setUserInterface_CustomDialogDisplay(UserInterface_Default_CustomDialogDisplayState)
          }}
          open={true}
        >
          <AppBar position="static">
            <Toolbar>
              <IconButton
                disabled
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                sx={{ mr: 2, color: '#fff !important' }}
              >
                <Icon icon="pen-to-square" />
              </IconButton>
              <Typography
                component={'span'}
                variant={'h6'}
                sx={{ flexGrow: 1 }}
              >
                {s_EDIT_TRAILER}
              </Typography>
            </Toolbar>
          </AppBar>
          <DialogContent>
            <Box>
              {rJSX_UserSelection()}
              <Box className="tw-mt-2 tw-text-right">{rJSX_SubmitButton()}</Box>
            </Box>
          </DialogContent>
        </Dialog>
      </Box>
    )
    return dialogJSX
  }

  return <>{rJSX_Dialog()}</>
}

///////////////////////////////
// Functions
///////////////////////////////

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>
  },
  ref: React.Ref<unknown>,
) {
  return (
    <Slide
      direction="up"
      ref={ref}
      {...props}
    />
  )
})

///////////////////////////////
// Container
///////////////////////////////

export const Container: React.FC = (): JSX.Element => {
  // Props
  const params = useParams()
  const trailerKey: string = params.id as string

  // Hooks - useContext, useState, useReducer, other
  const [us_formattedAssignmentHistoryData, us_setFormattedAssignmentHistoryData] = useState<TsInterface_UnspecifiedObject[]>([])
  const [us_trailer, us_setTrailer] = useState<TsInterface_UnspecifiedObject>({})
  const [us_trailerAssignmentHistory, us_setTrailerAssignmentHistory] = useState<TsInterface_UnspecifiedObject>({})
  const un_routerNavigation = useNavigate()
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)
  const { uc_setUserInterface_CustomDialogDisplay } = useContext(Context_UserInterface_CustomDialog)
  const { uc_setUserInterface_ErrorDialogDisplay } = useContext(Context_UserInterface_ErrorDialog)
  const { uc_setUserInterface_FormDialogDisplay } = useContext(Context_UserInterface_FormDialog)

  // Hooks - useEffect
  useEffect(() => {
    document.title = se_TRAILER
  }, [])

  useEffect(() => {
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
    return () => {}
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setTrailer(newData)
      ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveDocument(DatabaseRef_Trailer_Document(res_GCK.clientKey, trailerKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, ur_forceRerender, uc_setRootData_ClientKey, trailerKey])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setTrailerAssignmentHistory(newData)
      ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveCollection(
          DatabaseRef_TrailerAssignmentHistory_SpecificTrailer_Query(res_GCK.clientKey, trailerKey),
          updateLiveData,
        )
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, ur_forceRerender, uc_setRootData_ClientKey, trailerKey])

  useEffect(() => {
    // Format Assignment History Data
    let startData = objectToArray(cloneObjectWithoutReference(us_trailerAssignmentHistory))
    let unformattedAssignmentHistoryData: TsInterface_UnspecifiedObject[] = []
    for (let loopHistoryIndex in startData) {
      let loopHistory = startData[loopHistoryIndex]
      loopHistory.TEMP_timestamp_start_number = returnDateFromUnknownDateFormat(loopHistory.timestamp_start).getTime()
      unformattedAssignmentHistoryData.push(loopHistory)
    }
    let formattedAssignmentHistoryData: TsInterface_UnspecifiedObject[] = []
    let sortedAssignmentHistoryData = unformattedAssignmentHistoryData.sort(dynamicSort('TEMP_timestamp_start_number', 'asc'))
    for (let loopHistoryIndex in sortedAssignmentHistoryData) {
      let loopHistory = sortedAssignmentHistoryData[loopHistoryIndex]
      if (sortedAssignmentHistoryData[parseInt(loopHistoryIndex) + 1] != null) {
        loopHistory['timestamp_end'] = sortedAssignmentHistoryData[parseInt(loopHistoryIndex) + 1]['timestamp_start']
      }
      formattedAssignmentHistoryData.push(loopHistory)
    }
    us_setFormattedAssignmentHistoryData(formattedAssignmentHistoryData)
    return () => {}
  }, [us_trailerAssignmentHistory])

  // Functions
  const editItemProperty = (formInputs: TsInterface_FormInputs) => {
    uc_setUserInterface_FormDialogDisplay({
      display: true,
      form: {
        form: {
          formAdditionalData: {},
          formData: us_trailer,
          formInputs: formInputs,
          formOnChange: (
            formAdditionalData: TsInterface_FormAdditionalData,
            formData: TsInterface_FormData,
            formInputs: TsInterface_FormInputs,
            formSettings: TsInterface_FormSettings,
          ) => {},
          formSettings: {},
          formSubmission: (
            formSubmittedData: TsInterface_FormSubmittedData,
            formAdditionalData: TsInterface_FormAdditionalData,
            formHooks: TsInterface_FormHooksObject,
          ) => {
            return new Promise((resolve, reject) => {
              getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                .then((res_GCK) => {
                  DatabaseSetMergeDocument(DatabaseRef_Trailer_Document(res_GCK.clientKey, trailerKey), formSubmittedData)
                    .then((res_DSMD) => {
                      resolve({ success: true })
                    })
                    .catch((rej_DSMD) => {
                      uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                      reject(rej_DSMD)
                    })
                })
                .catch((rej_GCK) => {
                  uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                  reject(rej_GCK)
                })
            })
          },
        },
        dialog: {
          formDialogHeaderColor: 'success',
          formDialogHeaderText: <>{s_EDIT_TRAILER}</>,
          formDialogIcon: (
            <Icon
              type="solid"
              icon="pen-to-square"
            />
          ),
        },
      },
    })
  }

  const rJSX_PageHeader = (): JSX.Element => {
    let pageHeader = <>{s_TRAILER}</>
    if (us_trailer != null && us_trailer.name != null) {
      pageHeader = (
        <>
          {s_TRAILER} - {us_trailer.name}
        </>
      )
    }
    return pageHeader
  }

  // JSX Generation
  const rJSX_BackButton = (): JSX.Element => {
    let buttonJSX = (
      <Button
        color="inherit"
        variant="outlined"
        onClick={(event) => {
          onClickAppNavigation(event, un_routerNavigation, ApplicationPages.AdminDatabaseVehiclesListPage.url() + '?tab=Trailers')
        }}
        disableElevation
        startIcon={<Icon icon="chevron-left" />}
        className="tw-mr-2"
      >
        {s_BACK_TO_ALL_TRAILERS}
      </Button>
    )
    return buttonJSX
  }

  // Details Tab
  const rJSX_DetailsTab = (): JSX.Element => {
    let tabJSX = <></>
    tabJSX = (
      <Card className="tw-p-2">
        {rJSX_EditableLineItem(s_TRAILER_NAME, 'name', formInputs_TrailerLicenseName)}
        {rJSX_EditableLineItem(s_LICENSE_PLATE, 'license_plate', formInputs_TrailerLicensePlate)}
        {rJSX_EditableLineItem(s_VIN, 'vin', formInputs_TrailerVin)}
        {rJSX_EditableLineItem(rLIB('Make'), 'trailer_make', formInputs_TrailerMake)}
        {rJSX_EditableLineItem(s_MODEL, 'trailer_model', formInputs_TrailerModel)}
        {rJSX_EditableLineItem(s_YEAR, 'trailer_year', formInputs_TrailerYear)}
        {rJSX_EditableLineItem(s_TAG_EXPIRATION, 'trailer_tag_expiration', formInputs_TrailerTagExpiration)}
        {rJSX_EditableLineItem(s_COLOR, 'trailer_color', formInputs_TrailerColor)}
        <Divider />
        {rJSX_EditableAssociationLineItem(s_CURRENTLY_ASSIGNED_TO, 'associated_user_name', 'users', <></>)}
      </Card>
    )
    return tabJSX
  }

  const rJSX_EditableLineItem = (displayText: JSX.Element | string, propKey: string, formInputs: TsInterface_FormInputs): JSX.Element => {
    let lineJSX = <></>
    // Missing Prop
    let missingTextJSX = (
      <Box
        component={'span'}
        className="tw-text-error_light tw-italic"
      >
        {s_MISSING}
      </Box>
    )
    // Edit Icon
    let editIconJSX = (
      <Icon
        icon="pen-to-square"
        className="tw-opacity-30 hover:tw-opacity-100 tw-cursor-pointer tw-ml-2"
        tooltip={s_EDIT}
        tooltipPlacement="right"
        onClick={() => {
          editItemProperty(formInputs)
        }}
      />
    )
    // Full JSX
    lineJSX = (
      <Box>
        <Typography variant="h6">
          <Box
            className="tw-pr-2 tw-font-bold"
            component={'span'}
          >
            {displayText}:
          </Box>
          <Box
            className="tw-pr-1 tw-opacity-50 tw-font-normal"
            component={'span'}
          >
            {getProp(us_trailer, propKey, missingTextJSX)}
          </Box>
          {editIconJSX}
        </Typography>
      </Box>
    )
    return lineJSX
  }

  const rJSX_EditableAssociationLineItem = (displayText: JSX.Element, propKey: string, searchIndexKey: string, rJSX_SearchResult: any): JSX.Element => {
    let lineJSX = <></>
    // Missing Prop
    let missingTextJSX = (
      <Box
        component={'span'}
        className="tw-opacity-80 tw-italic"
      >
        {s_MISSING}
      </Box>
    )
    // Edit Icon
    let editIconJSX = (
      <Icon
        icon="pen-to-square"
        className="tw-opacity-30 hover:tw-opacity-100 tw-cursor-pointer tw-ml-2"
        tooltip={s_EDIT}
        tooltipPlacement="right"
        onClick={() => {
          uc_setUserInterface_CustomDialogDisplay({
            display: true,
            dialog: {
              dialog_jsx: <NewDriverAssignmentCustomDialog us_trailer={us_trailer} />,
              settings: {
                max_width: 'lg',
              },
            },
          })
        }}
      />
    )
    // Full JSX
    lineJSX = (
      <Box>
        <Typography variant="h6">
          <Box
            className="tw-pr-2 tw-font-bold"
            component={'span'}
          >
            {displayText}:
          </Box>
          <Box
            className="tw-pr-1 tw-opacity-50 tw-font-normal"
            component={'span'}
          >
            {getProp(us_trailer, propKey, missingTextJSX)}
          </Box>
          {editIconJSX}
        </Typography>
      </Box>
    )
    return lineJSX
  }

  // Assignment Tab
  const rJSX_AddAssignmentButton = (): JSX.Element => {
    let buttonJSX = <></>
    buttonJSX = (
      <Button
        variant="contained"
        color="success"
        onClick={() => {
          uc_setUserInterface_CustomDialogDisplay({
            display: true,
            dialog: {
              dialog_jsx: <NewDriverAssignmentCustomDialog us_trailer={us_trailer} />,
              settings: {
                max_width: 'lg',
              },
            },
          })
        }}
      >
        <Icon
          icon="user-plus"
          className="tw-mr-2"
        />
        {s_ADD_DRIVER_ASSIGNMENT}
      </Button>
    )
    return buttonJSX
  }

  const rJSX_AssignmentTab = (): JSX.Element => {
    let tabJSX = <></>
    let tableJSX = <></>
    if (objectToArray(us_trailerAssignmentHistory).length === 0) {
      tableJSX = <Card className="tw-p-4 tw-mt-2 tw-text-center">{s_TRAILER_DOESNT_HAVE_AN_ASSIGNMENT_HISTORY_YET}</Card>
    } else {
      tableJSX = (
        <Card className="tw-mt-2">
          <TableBasic
            tableAdditionalData={{}}
            tableColumns={tableColumns_AssignmentHistoryData}
            tableData={us_formattedAssignmentHistoryData}
            tableSettings={tableSettings_AssignmentHistoryData}
          />
        </Card>
      )
    }
    // Full JSX
    tabJSX = (
      <Box>
        <Box>{rJSX_AddAssignmentButton()}</Box>
        {tableJSX}
      </Box>
    )
    return tabJSX
  }

  // Notes Tab
  const rJSX_NotesTab = (): JSX.Element => {
    let tabJSX = <></>
    tabJSX = (
      <Box>
        <ForumBasic
          forumThreadCollectionDatabaseEndpoint={(clientKey) => {
            return DatabaseRef_TrailerForumThreads_Collection(clientKey, trailerKey)
          }}
          forumThreadDocumentDatabaseEndpoint={(clientKey, threadKey) => {
            return DatabaseRef_TrailerForumThread_Document(clientKey, trailerKey, threadKey)
          }}
          forumMessageCollectionDatabaseEndpoint={(clientKey, threadKey) => {
            return DatabaseRef_TrailerForumMessages_Collection(clientKey, trailerKey, threadKey)
          }}
          forumMessageDocumentDatabaseEndpoint={(clientKey, threadKey, messageKey) => {
            return DatabaseRef_TrailerForumMessage_Document(clientKey, trailerKey, threadKey, messageKey)
          }}
          forumMessageStorageEndpoint={(clientKey, threadKey, messageKey, fileName) => {
            return StorageRef_Trailer_MessageThread_File(clientKey, trailerKey, threadKey, fileName)
          }}
          forumSettings={{
            allow_thread_creation: true,
            scrollbar_track_color: '#272b33',
            scrollbar_thumb_color: '#888',
            input_text_color: '#fff',
            base_message_container_offset_px: 336,
          }}
        />
      </Box>
    )
    return tabJSX
  }

  // Files Tab
  const rJSX_FilesTab = (): JSX.Element => {
    let tabJSX = (
      <FileSystemBasic
        fileSystemCollectionDatabaseEndpoint={(clientKey) => {
          return DatabaseRef_TrailerFiles_Collection(clientKey, trailerKey)
        }}
        fileSystemDocumentDatabaseEndpoint={(clientKey, fileKey) => {
          return DatabaseRef_TrailerFile_Document(clientKey, trailerKey, fileKey)
        }}
        fileSystemStorageEndpoint={(clientKey, fileName) => {
          return StorageRef_TrailerFile(clientKey, trailerKey, fileName)
        }}
        fileSystemHardCodedFolders={{}}
        fileSystemSettings={{
          allow_file_archiving: true,
          allow_file_movement: true,
          allow_file_unarchiving: true,
          allow_file_uploads: true,
          allow_folder_creation: true,
          allow_folder_deletion: true,
          allow_folder_movement: true,
          allow_folder_rename: true,
          archive_filter_visible: true,
        }}
      />
    )
    return tabJSX
  }

  const rJSX_Page = (): JSX.Element => {
    let pageJSX = (
      <AuthenticatedContainer
        pageHeader={rJSX_PageHeader()}
        pageKey={pageKey}
        content={
          <Box>
            <Box>{rJSX_BackButton()}</Box>
            <Box>
              <TabsUrl
                tabsSettings={{
                  baseUrl: ApplicationPages.AdminDatabaseTrailerViewPage.url(trailerKey),
                  tabQueryParam: 'tab',
                  overridePageTitle: true,
                  basePageTitle: se_TRAILER,
                }}
                tabs={[
                  {
                    tabUrlKey: 'Details',
                    tabHeader: s_DETAILS,
                    tabContent: rJSX_DetailsTab(),
                  },
                  {
                    tabUrlKey: 'Assignment',
                    tabHeader: s_ASSIGNMENT,
                    tabContent: rJSX_AssignmentTab(),
                  },
                  {
                    tabUrlKey: 'Notes',
                    tabHeader: s_NOTES,
                    tabContent: rJSX_NotesTab(),
                  },
                  {
                    tabUrlKey: 'Files',
                    tabHeader: s_FILES,
                    tabContent: rJSX_FilesTab(),
                  },
                ]}
              />
            </Box>
          </Box>
        }
      />
    )
    return pageJSX
  }

  // Render
  return <>{rJSX_Page()}</>
}
