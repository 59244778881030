///////////////////////////////
// Imports
///////////////////////////////

import { Box, FormControl, MenuItem, Select, Stack } from '@mui/material'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import { DatabaseRef_SalesPartner_InvoiceRates_Task_Document } from 'rfbp_aux/services/database_endpoints/finances/invoice_rates'
import {
  TsInterface_FormAdditionalData,
  TsInterface_FormData,
  TsInterface_FormHooksObject,
  TsInterface_FormInputs,
  TsInterface_FormSettings,
  TsInterface_FormSubmittedData,
} from 'rfbp_core/components/form'
import { Icon } from 'rfbp_core/components/icons'
import {
  TableCellBasic,
  TsInterface_TableAdditionalData,
  TsInterface_TableColumns,
  TsInterface_TableDatabaseSettings,
  TsInterface_TableDataRow,
  TsInterface_TableHooks,
  TsInterface_TableSettings,
} from 'rfbp_core/components/table'
import { rLIB } from 'rfbp_core/localization/library'
import { DatabaseSetMergeDocument } from 'rfbp_core/services/database_management'
import { dynamicSort, formatCurrency, getProp, objectToArray } from 'rfbp_core/services/helper_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject } from 'rfbp_core/typescript/global_types'
import { v4 as uuidv4 } from 'uuid'

///////////////////////////////
// Functions
///////////////////////////////

const rJSX_invoiceLineItemCell = (
  rowData: TsInterface_TableDataRow,
  tableAdditionalData: TsInterface_TableAdditionalData,
  tableHooks: TsInterface_TableHooks,
  regionKey: string,
): JSX.Element => {
  let cellJSX = <></>
  const rJSX_AddLineIcon = (regionKey: string) => {
    let addLineIconJSX = (
      <Icon
        icon="circle-plus"
        className="tw-cursor-pointer tw-opacity-30 hover:tw-opacity-100 hover:tw-text-success_main"
        tooltip={rLIB('Add line item')}
        tooltipPlacement="right"
        onClick={() => {
          tableHooks.uc_setUserInterface_FormDialogDisplay({
            display: true,
            form: {
              form: {
                formAdditionalData: {},
                formData: {},
                formInputs: {
                  name: {
                    data_type: 'string',
                    input_type: 'text_basic',
                    key: 'name',
                    label: rLIB('Line item name'),
                    required: true,
                  },
                  price: {
                    data_type: 'number',
                    input_type: 'text_number',
                    key: 'price',
                    label: rLIB('Price'),
                    required: true,
                  },
                },
                formOnChange: (
                  formAdditionalData: TsInterface_FormAdditionalData,
                  formData: TsInterface_FormData,
                  formInputs: TsInterface_FormInputs,
                  formSettings: TsInterface_FormSettings,
                ) => {},
                formSettings: {},
                formSubmission: (
                  formSubmittedData: TsInterface_FormSubmittedData,
                  formAdditionalData: TsInterface_FormAdditionalData,
                  formHooks: TsInterface_FormHooksObject,
                ) => {
                  return new Promise((resolve, reject) => {
                    let lineItemKey = uuidv4()
                    let updateObject: TsInterface_UnspecifiedObject = {
                      ['line_items_' + regionKey]: {
                        [lineItemKey]: {
                          name: formSubmittedData['name'],
                          price: formSubmittedData['price'],
                          key: lineItemKey,
                          order: new Date().getTime(),
                        },
                      },
                    }
                    getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                      .then((res_GCK) => {
                        DatabaseSetMergeDocument(
                          DatabaseRef_SalesPartner_InvoiceRates_Task_Document(
                            res_GCK.clientKey,
                            tableAdditionalData.us_selectedSalesPartnerKey,
                            rowData.key as string,
                          ),
                          updateObject,
                        )
                          .then((res_DSMD) => {
                            resolve(res_DSMD)
                          })
                          .catch((rej_DSMD) => {
                            reject(rej_DSMD)
                          })
                      })
                      .catch((rej_GCK) => {
                        console.error(rej_GCK)
                        reject(rej_GCK)
                      })
                  })
                },
              },
              dialog: {
                formDialogHeaderColor: 'success',
                formDialogHeaderText: rLIB('Add line item'),
                formDialogIcon: (
                  <Icon
                    type="solid"
                    icon="pen-to-square"
                  />
                ),
              },
            },
          })
        }}
      />
    )
    return addLineIconJSX
  }
  const rJSX_EditLineItemIcon = (lineItem: TsInterface_UnspecifiedObject, regionKey: string) => {
    let editLineItemIconJSX = (
      <Icon
        icon="pen-to-square"
        className="tw-cursor-pointer tw-opacity-30 hover:tw-opacity-100 hover:tw-text-success_main"
        tooltip={rLIB('Edit line item')}
        tooltipPlacement="right"
        onClick={() => {
          tableHooks.uc_setUserInterface_FormDialogDisplay({
            display: true,
            form: {
              form: {
                formAdditionalData: {},
                formData: lineItem,
                formInputs: {
                  name: {
                    data_type: 'string',
                    input_type: 'text_basic',
                    key: 'name',
                    label: rLIB('Line item name'),
                    required: true,
                  },
                  price: {
                    data_type: 'number',
                    input_type: 'text_number',
                    key: 'price',
                    label: rLIB('Price'),
                    required: true,
                  },
                },
                formOnChange: (
                  formAdditionalData: TsInterface_FormAdditionalData,
                  formData: TsInterface_FormData,
                  formInputs: TsInterface_FormInputs,
                  formSettings: TsInterface_FormSettings,
                ) => {},
                formSettings: {},
                formSubmission: (
                  formSubmittedData: TsInterface_FormSubmittedData,
                  formAdditionalData: TsInterface_FormAdditionalData,
                  formHooks: TsInterface_FormHooksObject,
                ) => {
                  return new Promise((resolve, reject) => {
                    let updateObject: TsInterface_UnspecifiedObject = {
                      [rowData.key as string]: {
                        ['line_items_' + regionKey]: {
                          [lineItem.key]: {
                            name: formSubmittedData['name'],
                            price: formSubmittedData['price'],
                          },
                        },
                      },
                    }
                    getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                      .then((res_GCK) => {
                        DatabaseSetMergeDocument(
                          DatabaseRef_SalesPartner_InvoiceRates_Task_Document(
                            res_GCK.clientKey,
                            tableAdditionalData.us_selectedSalesPartnerKey,
                            rowData.key as string,
                          ),
                          updateObject,
                        )
                          .then((res_DSMD) => {
                            // Nothing
                            resolve(res_DSMD)
                          })
                          .catch((rej_DSMD) => {
                            tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                            reject(rej_DSMD)
                          })
                      })
                      .catch((rej_GCK) => {
                        console.error(rej_GCK)
                        reject(rej_GCK)
                      })
                  })
                },
              },
              dialog: {
                formDialogHeaderColor: 'success',
                formDialogHeaderText: rLIB('Edit line item'),
                formDialogIcon: (
                  <Icon
                    type="solid"
                    icon="pen-to-square"
                  />
                ),
              },
            },
          })
        }}
      />
    )
    return editLineItemIconJSX
  }
  const rJSX_DeleteLineItemIcon = (lineItemKey: string, regionKey: string) => {
    let deleteLineItemIconJSX = (
      <Icon
        icon="trash"
        className="tw-cursor-pointer tw-opacity-30 hover:tw-opacity-100 hover:tw-text-error_main"
        tooltip={rLIB('Delete line item')}
        tooltipPlacement="right"
        onClick={() => {
          tableHooks.uc_setUserInterface_ConfirmDialogDisplay({
            display: true,
            confirm: {
              color: 'error',
              header: rLIB('Delete line item'),
              icon: (
                <Icon
                  icon="trash"
                  type="solid"
                />
              ),
              submit_text: rLIB('Delete'),
              text: rLIB('Are you sure you want to delete this line item?'),
              submit_callback: () => {
                return new Promise((resolve, reject) => {
                  let updateObject: TsInterface_UnspecifiedObject = {
                    ['line_items_' + regionKey]: {
                      [lineItemKey]: null,
                    },
                  }
                  getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                    .then((res_GCK) => {
                      DatabaseSetMergeDocument(
                        DatabaseRef_SalesPartner_InvoiceRates_Task_Document(
                          res_GCK.clientKey,
                          tableAdditionalData.us_selectedSalesPartnerKey,
                          rowData.key as string,
                        ),
                        updateObject,
                      )
                        .then((res_DSMD) => {
                          // Nothing
                          resolve(res_DSMD)
                        })
                        .catch((rej_DSMD) => {
                          tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                          reject(rej_DSMD)
                        })
                    })
                    .catch((rej_GCK) => {
                      console.error(rej_GCK)
                      reject(rej_GCK)
                    })
                })
              },
            },
          })
        }}
      />
    )
    return deleteLineItemIconJSX
  }
  const rJSX_LineItemPrice = (lineItem: TsInterface_UnspecifiedObject) => {
    let lineItemPriceJSX = (
      <Box
        className="tw-px-2"
        sx={{
          background: getProp(lineItem, 'price', 0) > 0 ? themeVariables.success_main : themeVariables.gray_600,
          borderRadius: '5px',
        }}
      >
        {formatCurrency(getProp(lineItem, 'price', 0))}
      </Box>
    )
    return lineItemPriceJSX
  }
  const rJSX_LineItems = (regionKey: string) => {
    let lineItemsJSX = <></>
    if (
      rowData != null &&
      rowData.key != null &&
      tableAdditionalData != null &&
      tableAdditionalData.us_invoiceRates != null &&
      tableAdditionalData.us_invoiceRates[rowData.key as string] != null &&
      tableAdditionalData.us_invoiceRates[rowData.key as string]['line_items_' + regionKey] != null
    ) {
      lineItemsJSX = (
        <Box>
          {objectToArray(tableAdditionalData.us_invoiceRates[rowData.key as string]['line_items_' + regionKey])
            .sort(dynamicSort('order', null))
            .map((lineItem: TsInterface_UnspecifiedObject, index: number) => (
              <Box key={index}>
                <Stack
                  direction="row"
                  spacing={1}
                  className="tw-mb-1"
                >
                  <Box>{lineItem.name}</Box>
                  <Box>{rJSX_LineItemPrice(lineItem)}</Box>
                  <Box>{rJSX_EditLineItemIcon(lineItem, regionKey)}</Box>
                  <Box>{rJSX_DeleteLineItemIcon(lineItem.key, regionKey)}</Box>
                </Stack>
              </Box>
            ))}
        </Box>
      )
    }
    return lineItemsJSX
  }
  // Full JSX
  cellJSX = (
    <Box>
      {rJSX_LineItems(regionKey)}
      {rJSX_AddLineIcon(regionKey)}
    </Box>
  )
  return cellJSX
}

const invoiceBillToOptions = {
  sales_partner: { key: 'sales_partner', name: 'Sales Partner' },
  customer: { key: 'customer', name: 'Customer' },
  sales_rep: { key: 'sales_rep', name: 'Sales Rep' },
  internal_cost: { key: 'internal_cost', name: 'Internal Cost' },
}

///////////////////////////////
// Exports
///////////////////////////////

export const tableSettings_V2TasksInvoiceMapping: TsInterface_TableDatabaseSettings = {
  rows_per_page: 100,
  show_header: true,
  size: 'small',
  sort_direction: 'asc',
  sort_property: 'name',
  use_live_data: true,
  sticky_header: true,
  sticky_table_height: 'calc(100vh - 350px)',
  alternate_row_colors: true,
  alternate_row_color_hex: themeVariables.background_hover,
}

export const tableSettings_V1TasksInvoiceMapping: TsInterface_TableSettings = {
  paginated: false,
  show_header: true,
  size: 'small',
  sort_direction: 'asc',
  sort_property_default: 'name',
  sortable: false,
  sticky_header: true,
  sticky_table_height: 'calc(100vh - 300px)',
  alternate_row_colors: true,
  alternate_row_color_hex: themeVariables.background_hover,
}

export const tableColumns_V1TasksInvoiceMapping: TsInterface_TableColumns = {
  name: TableCellBasic('name', rLIB('Task Name'), null),
  // TODO: Implement if V1 is needed - probably won't do it
}

export const tableColumns_V2TasksInvoiceMapping: TsInterface_TableColumns = {
  name: TableCellBasic('name', rLIB('Task Name'), null),
  TEMP_bill_to: {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        let headerJSX = rLIB('Bill To')
        return headerJSX
      },
      header_sort_by: null,
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = <></>
        const rJSX_BillToDropdown = () => {
          let dropdownJSX = <></>
          if (rowData != null && rowData.key != null && tableAdditionalData != null && tableAdditionalData.us_selectedSalesPartnerKey != null) {
            let dropdownValue = ''
            if (
              rowData != null &&
              rowData.key != null &&
              tableAdditionalData != null &&
              tableAdditionalData.us_invoiceRates != null &&
              tableAdditionalData.us_invoiceRates[rowData.key as string] != null &&
              tableAdditionalData.us_invoiceRates[rowData.key as string]['bill_to'] != null
            ) {
              dropdownValue = tableAdditionalData.us_invoiceRates[rowData.key as string]['bill_to']
            }
            dropdownJSX = (
              <Box>
                <FormControl className="bp_thin_select_input">
                  <Select
                    color="primary"
                    value={dropdownValue}
                    onChange={(event: any) => {
                      if (event != null && event.target != null && event.target.value != null) {
                        let updateObject: TsInterface_UnspecifiedObject = {
                          bill_to: event.target.value,
                        }
                        getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                          .then((res_GCK) => {
                            DatabaseSetMergeDocument(
                              DatabaseRef_SalesPartner_InvoiceRates_Task_Document(
                                res_GCK.clientKey,
                                tableAdditionalData.us_selectedSalesPartnerKey,
                                rowData.key as string,
                              ),
                              updateObject,
                            )
                              .then((res_DSMD) => {
                                // Nothing
                              })
                              .catch((rej_DSMD) => {
                                tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                              })
                          })
                          .catch((rej_GCK) => {
                            console.error(rej_GCK)
                          })
                      }
                    }}
                    variant="outlined"
                  >
                    {objectToArray(invoiceBillToOptions).map((option: TsInterface_UnspecifiedObject, index: number) => (
                      <MenuItem
                        key={index}
                        value={option['key']}
                      >
                        {option['name']}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            )
          }
          return dropdownJSX
        }
        // Full JSX
        cellJSX = <Box>{rJSX_BillToDropdown()}</Box>
        return cellJSX
      },
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        let cellCSS = ''
        return cellCSS
      },
    },
  },
  TEMP_default_sales_partner_data: {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        let headerJSX = <></>
        if (
          tableAdditionalData != null &&
          tableAdditionalData.us_selectedSalesPartnerKey != null &&
          tableAdditionalData.us_salesPartners != null &&
          tableAdditionalData.us_salesPartners[tableAdditionalData.us_selectedSalesPartnerKey] != null &&
          tableAdditionalData.us_salesPartners[tableAdditionalData.us_selectedSalesPartnerKey]['name'] != null
        ) {
          headerJSX = (
            <Box>
              {rLIB('Default rates for ')} {tableAdditionalData.us_salesPartners[tableAdditionalData.us_selectedSalesPartnerKey]['name']}
            </Box>
          )
        } else {
          headerJSX = (
            <Box>
              {rLIB('Default rates for ')} {rLIB('sales partner')}
            </Box>
          )
        }
        return headerJSX
      },
      header_sort_by: null,
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        return rJSX_invoiceLineItemCell(rowData, tableAdditionalData, tableHooks, 'default')
      },
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        let cellCSS = ''
        return cellCSS
      },
    },
  },
  TEMP_region_sales_partner_data: {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        let headerJSX = <></>
        if (
          tableAdditionalData != null &&
          tableAdditionalData.us_regionKey != null &&
          tableAdditionalData.us_setRegionKey != null &&
          tableAdditionalData.us_regionsList != null
        ) {
          headerJSX = (
            <Box>
              <FormControl className="bp_thin_select_input">
                <Select
                  color="primary"
                  value={tableAdditionalData.us_regionKey || ''}
                  onChange={(event: any) => {
                    if (event != null && event.target != null && event.target.value != null) {
                      tableAdditionalData.us_setRegionKey(event.target.value)
                    }
                  }}
                  variant="outlined"
                >
                  {objectToArray(tableAdditionalData.us_regionsList)
                    .sort(dynamicSort('name', null))
                    .map((option: TsInterface_UnspecifiedObject, index: number) => (
                      <MenuItem
                        key={index}
                        value={option['key']}
                      >
                        {option['name']}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Box>
          )
        } else {
          headerJSX = <Box>{rLIB('Region rates ')}</Box>
        }
        return headerJSX
      },
      header_sort_by: null,
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = <></>
        if (tableAdditionalData.us_regionKey != null && tableAdditionalData.us_regionKey != '') {
          cellJSX = rJSX_invoiceLineItemCell(rowData, tableAdditionalData, tableHooks, tableAdditionalData.us_regionKey)
        }
        return cellJSX
      },
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        let cellCSS = ''
        return cellCSS
      },
    },
  },
}
