///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			containers are pages / views used in the app and are made up of components and can interact with services and models

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Box, Button, Card, Chip, FormControl, FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material/'
import { useContext, useEffect } from 'react'
import { Trans } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import { AuthenticatedContainer } from 'rfbp_aux/containers/authenticated_container'
import { ApplicationPages } from 'rfbp_aux/data/application_structure'
import { DatabaseRef_VehiclesForSpecificUser_Query } from 'rfbp_aux/services/database_endpoints/directory/vehicles'
import { DatabaseRef_IncidentReports_Collection, DatabaseRef_IncidentReport_Document } from 'rfbp_aux/services/database_endpoints/operations/incident_reports'
import {
  TsInterface_FormAdditionalData,
  TsInterface_FormData,
  TsInterface_FormHooksObject,
  TsInterface_FormInputs,
  TsInterface_FormSettings,
  TsInterface_FormSubmittedData,
} from 'rfbp_core/components/form'
import { TsInterface_InputHooksObject } from 'rfbp_core/components/form/form_types'
import { Icon } from 'rfbp_core/components/icons'
import { rJSX_HighlightedSearchString, SearchInput } from 'rfbp_core/components/search'
import {
  TableCellBasic,
  TableCellManage,
  TableDatabase,
  TsInterface_TableAdditionalData,
  TsInterface_TableColumns,
  TsInterface_TableDatabaseEndpointQueryObject,
  TsInterface_TableDatabaseSettings,
  TsInterface_TableDataRow,
  TsInterface_TableHooks,
} from 'rfbp_core/components/table'
import { TabButtons } from 'rfbp_core/components/tabs/tab_buttons'
import { rLIB } from 'rfbp_core/localization/library'
import { Context_RootData_ClientKey, Context_UserInterface_ErrorDialog, Context_UserInterface_FormDialog } from 'rfbp_core/services/context'
import {
  DatabaseAddDocument,
  DatabaseGetCollection,
  DatabaseSetMergeDocument,
  generateDatabaseQuery,
  TsInterface_OrderByArray,
  TsInterface_QueryCursorsObject,
  TsInterface_QueryOperatorsArray,
} from 'rfbp_core/services/database_management'
import { getProp, objectToArray, returnFormattedDate } from 'rfbp_core/services/helper_functions'
import { directAppNavigation, onClickAppNavigation } from 'rfbp_core/services/navigation/navigation_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject } from 'rfbp_core/typescript/global_types'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

// Authenticated Nav Data
const pageKey: string = ApplicationPages['AdminDatabaseIncidentReportsListPage']['key']

// Displayed Translatable Strings
// { sort-start } - displayed text - scoped sort plugin
const s_ARE_YOU_SURE_THAT_YOU_WANT_TO_DELETE_THIS_INCIDENT_REPORT: JSX.Element = <Trans>Are you sure that you want to delete this incident report?</Trans>
const s_ARE_YOU_SURE_THAT_YOU_WANT_TO_UNDELETE_THIS_INCIDENT_REPORT: JSX.Element = <Trans>Are you sure that you want to undelete this incident report</Trans>
const s_CREATE_INCIDENT_REPORT: JSX.Element = <Trans>Create Incident Report</Trans>
const s_DELETE: JSX.Element = <Trans>Delete</Trans>
const s_DELETE_INCIDENT_REPORT: JSX.Element = <Trans>Delete Incident Report</Trans>
const s_DRIVER: JSX.Element = <Trans>Driver</Trans>
const s_INCIDENT_DATE: JSX.Element = <Trans>Incident Date</Trans>
const s_INCIDENT_LOCATION: JSX.Element = <Trans>Incident Location</Trans>
const s_INCIDENT_REPORTS: JSX.Element = <Trans>Incident Reports</Trans>
const s_INCIDENT_TYPE: JSX.Element = <Trans>Incident Type</Trans>
const s_NEW: JSX.Element = <Trans>New</Trans>
const s_NEW_INCIDENT_REPORT: JSX.Element = <Trans>New Incident Report</Trans>
const s_NO_VEHICLES_FOR_SELECTED_DRIVER: JSX.Element = <Trans>No vehicles for selected driver</Trans>
const s_REMOVE: JSX.Element = <Trans>Remove</Trans>
const s_STATUS: JSX.Element = <Trans>Status</Trans>
const s_UNDELETE: JSX.Element = <Trans>Undelete</Trans>
const s_UNDELETE_INCIDENT_REPORT: JSX.Element = <Trans>Undelete Incident Report</Trans>
const s_VEHICLE: JSX.Element = <Trans>Vehicle</Trans>
const s_VEHICLE_VIN: JSX.Element = <Trans>Vehicle VIN</Trans>
const s_VIEW: JSX.Element = <Trans>View</Trans>
const s_WHAT_HAPPENED: JSX.Element = <Trans>What happened?</Trans>
const se_INCIDENT_REPORTS: string = 'Incident Reports'
// { sort-end } - displayed text

export const incidentReportTypeOptions: TsInterface_UnspecifiedObject = {
  damage_to_vehicle_or_trailer: {
    key: 'damage_to_vehicle_or_trailer',
    value: rLIB('Damage to vehicle or trailer'),
    chipJSX: (
      <Box>
        <Chip
          color="warning"
          label={
            <Box>
              <Icon
                icon="car-burst"
                className="tw-mr-2"
              />
              {rLIB('Damage to vehicle or trailer')}
            </Box>
          }
        />
      </Box>
    ),
  },
  accident_with_another_party: {
    key: 'accident_with_another_party',
    value: rLIB('Accident with another party'),
    chipJSX: (
      <Box>
        <Chip
          color="error"
          label={
            <Box>
              <Icon
                icon="cars"
                className="tw-mr-2"
              />
              {rLIB('Accident with another party')}
            </Box>
          }
        />
      </Box>
    ),
  },
  safety_incident: {
    key: 'safety_incident',
    value: rLIB('Safety Incident'),
    chipJSX: (
      <Box>
        <Chip
          color="warning"
          label={
            <Box>
              <Icon
                icon="helmet-safety"
                className="tw-mr-2"
              />
              {rLIB('Safety Incident')}
            </Box>
          }
        />
      </Box>
    ),
  },
  traffic_violation: {
    key: 'traffic_violation',
    value: rLIB('Traffic Violation'),
    chipJSX: (
      <Box>
        <Chip
          color="info"
          label={
            <Box>
              <Icon
                icon="user-police-tie"
                className="tw-mr-2"
              />
              {rLIB('Traffic Violation')}
            </Box>
          }
        />
      </Box>
    ),
  },
}

// Form
const incidentReportStatusOptions: TsInterface_UnspecifiedObject = {
  new: {
    key: 'new',
    value: s_NEW,
    chipJSX: (
      <Box>
        <Chip
          color="error"
          label={
            <Box>
              <Icon
                icon="circle-exclamation"
                className="tw-mr-2"
              />
              {s_NEW}
            </Box>
          }
        />
      </Box>
    ),
  },
  deleted: {
    key: 'deleted',
    value: rLIB('Deleted'),
    chipJSX: (
      <Box>
        <Chip
          color="default"
          label={
            <Box>
              <Icon
                icon="trash"
                className="tw-mr-2"
              />
              {rLIB('Deleted')}
            </Box>
          }
        />
      </Box>
    ),
  },
}

const formInputs_IncidentReport: TsInterface_FormInputs = {
  incident_type: {
    data_type: 'string',
    input_type: 'multiple_choice_radio',
    key: 'incident_type',
    label: s_INCIDENT_TYPE,
    required: true,
    options: objectToArray(incidentReportTypeOptions),
  },
  associated_user_key: {
    data_type: 'string',
    key: 'associated_user_key',
    input_type: 'custom_form_input_jsx',
    label: <></>,
    required: true,
    renderCustomFormInput: (formInput, formInputs, formData, formInputChange, formSettings, formAdditionalData, formHooks) => {
      // User Search
      const rJSX_UserSearchResult = (
        option: TsInterface_UnspecifiedObject,
        searchInputValue: string | null,
        inputHooks: TsInterface_InputHooksObject,
        additionalSearchData: TsInterface_UnspecifiedObject,
      ): JSX.Element => {
        let searchResultJSX = (
          <Box
            sx={{ marginLeft: '8px', marginRight: '8px' }}
            onClick={() => {
              formInputChange('associated_user_key', option.id, true)
              formInputChange('associated_user_name', option.name, true)
              // Get Vehicles linked to user
              if (option.id != null) {
                DatabaseGetCollection(DatabaseRef_VehiclesForSpecificUser_Query(formHooks.uc_RootData_ClientKey, option.id))
                  .then((res_DGC) => {
                    formAdditionalData['vehicles'] = res_DGC.data
                    formHooks.ur_forceRerender()
                  })
                  .catch((rej_DGC) => {
                    console.error(rej_DGC)
                    formAdditionalData['vehicles'] = {}
                    formHooks.ur_forceRerender()
                  })
              } else {
                formAdditionalData['vehicles'] = {}
                formHooks.ur_forceRerender()
              }
            }}
          >
            <Typography className="tw-cursor-pointer">{rJSX_HighlightedSearchString(searchInputValue, option.name)} </Typography>
          </Box>
        )
        return searchResultJSX
      }
      // Full User and Vehicle Selection
      const rJSX_UserSelection = (): JSX.Element => {
        let userSelectionJSX = <></>
        if (formHooks.uc_RootData_ClientKey != null) {
          if (formData['associated_user_key'] == null || formData['associated_user_name'] == null) {
            userSelectionJSX = (
              <Box>
                <Typography
                  variant="body1"
                  className="tw-opacity-70"
                >
                  {s_DRIVER}*
                </Typography>
                <SearchInput
                  clientKey={formHooks.uc_RootData_ClientKey}
                  searchIndexKey={'users'}
                  searchFilters={[]}
                  searchResultRenderer={rJSX_UserSearchResult}
                  additionalSearchData={{}}
                  defaultSearchValue={''}
                  sx={{ minWidth: '100%' }}
                />
              </Box>
            )
          } else {
            userSelectionJSX = (
              <Box>
                <Typography
                  variant="body1"
                  className=""
                >
                  <Box
                    component="span"
                    className="tw-opacity-70 tw-mr-2"
                  >
                    {s_DRIVER}:
                  </Box>
                  <Box
                    component="span"
                    className="tw-font-bold"
                  >
                    {getProp(formData, 'associated_user_name', <></>)}
                  </Box>
                  <Icon
                    icon="square-xmark"
                    tooltip={s_REMOVE}
                    tooltipPlacement="right"
                    className="tw-ml-2 tw-opacity-30 hover:tw-opacity-100 tw-cursor-pointer hover:tw-text-error_main"
                    onClick={() => {
                      formInputChange('associated_user_key', null, true)
                      formInputChange('associated_user_name', null, true)
                      formInputChange('associated_vehicle_key', null, true)
                      formInputChange('associated_vehicle_vin', null, true)
                      formAdditionalData['vehicles'] = {}
                    }}
                  />
                </Typography>
              </Box>
            )
          }
        }
        return userSelectionJSX
      }
      let inputJSX = <Box className="tw-mt-4 tw-mb-4">{rJSX_UserSelection()}</Box>
      return inputJSX
    },
  },
  associated_vehicle_key: {
    data_type: 'string',
    key: 'associated_vehicle_key',
    input_type: 'custom_form_input_jsx',
    label: <></>,
    required: true,
    renderCustomFormInput: (formInput, formInputs, formData, formInputChange, formSettings, formAdditionalData, formHooks) => {
      let inputJSX = <></>
      if (formAdditionalData != null && formAdditionalData.vehicles != null && objectToArray(getProp(formAdditionalData, 'vehicles', {})).length > 0) {
        inputJSX = (
          <Box className="tw-mt-4 tw-mb-4">
            <Typography
              variant="body1"
              className="tw-opacity-70"
            >
              {s_VEHICLE}*
            </Typography>
            <FormControl fullWidth>
              <RadioGroup
                onChange={(event, value) => {
                  if (value != null) {
                    let vehicleVin = null
                    if (formAdditionalData != null && formAdditionalData.vehicles != null) {
                      vehicleVin = getProp(getProp(formAdditionalData.vehicles, value, {}), 'vin', null)
                    }
                    formInputChange('associated_vehicle_key', value, true)
                    formInputChange('associated_vehicle_vin', vehicleVin, true)
                  }
                }}
                value={formData.associated_vehicle_key || ''}
                sx={{ display: 'block' }}
              >
                {objectToArray(getProp(formAdditionalData, 'vehicles', {})).map((vehicle: TsInterface_UnspecifiedObject, vehicleIndex: number) => (
                  <FormControlLabel
                    key={vehicleIndex}
                    sx={{ display: 'flex' }}
                    control={<Radio />}
                    label={vehicle.vin}
                    value={vehicle.key}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </Box>
        )
      } else {
        inputJSX = (
          <Box className="tw-mt-4 tw-mb-4">
            <Typography
              variant="body1"
              className="tw-opacity-70"
            >
              {s_VEHICLE}*
            </Typography>
            <Typography
              variant="body1"
              className="tew-mt-2"
            >
              <Icon
                icon="circle-exclamation"
                className="tw-mr-2"
                sx={{ color: themeVariables.warning_main }}
              />
              {s_NO_VEHICLES_FOR_SELECTED_DRIVER}
            </Typography>
          </Box>
        )
      }
      return inputJSX
    },
  },
  associated_user_name: {
    data_type: 'string',
    key: 'associated_user_name',
    input_type: 'custom_form_input_jsx',
    label: <></>,
    required: false,
    renderCustomFormInput: (formInput, formInputs, formData, formInputChange, formSettings, formAdditionalData, formHooks) => {
      return <></>
    },
  },
  associated_vehicle_vin: {
    data_type: 'string',
    key: 'associated_vehicle_vin',
    input_type: 'custom_form_input_jsx',
    label: <></>,
    required: false,
    renderCustomFormInput: (formInput, formInputs, formData, formInputChange, formSettings, formAdditionalData, formHooks) => {
      return <></>
    },
  },
  timestamp_incident: {
    key: 'timestamp_incident',
    label: s_INCIDENT_DATE,
    input_type: 'timestamp_datetime',
    required: true,
    data_type: 'string',
  },
  incident_location: {
    key: 'incident_location',
    label: s_INCIDENT_LOCATION,
    input_type: 'text_basic',
    required: true,
    data_type: 'string',
  },
  notes: {
    key: 'notes',
    label: s_WHAT_HAPPENED,
    input_type: 'text_multiline',
    required: true,
    data_type: 'string',
  },
}

// Tables
const tableColumns_IncidentReports: TsInterface_TableColumns = {
  manage: TableCellManage({
    view: {
      icon: (
        <Icon
          type="solid"
          icon="magnifying-glass"
        />
      ),
      label: <>{s_VIEW}</>,
      onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        if (rowData.key != null) {
          directAppNavigation(tableHooks.un_routerNavigation, ApplicationPages.AdminDatabaseIncidentReportViewPage.url(rowData.key as string))
        }
      },
    },
    delete: {
      icon: (
        <Icon
          icon="trash"
          type="solid"
        />
      ),
      label: <>{s_DELETE}</>,
      conditional_display: {
        active: true,
        logic_type: 'comparison',
        source: 'rowData',
        prop: 'status',
        comparator: '!=',
        value: 'deleted',
        conditions: [],
      },
      onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        if (rowData != null && rowData.key != null && tableHooks != null && tableHooks.uc_RootData_ClientKey != null) {
          tableHooks.uc_setUserInterface_ConfirmDialogDisplay({
            display: true,
            confirm: {
              color: 'error',
              header: s_DELETE_INCIDENT_REPORT,
              icon: (
                <Icon
                  icon="trash"
                  type="solid"
                />
              ),
              submit_text: s_DELETE,
              text: s_ARE_YOU_SURE_THAT_YOU_WANT_TO_DELETE_THIS_INCIDENT_REPORT,
              submit_callback: () => {
                return new Promise((resolve, reject) => {
                  let updateObject = {
                    status: 'deleted',
                    status_before_deletion: getProp(rowData, 'status', null),
                  }
                  DatabaseSetMergeDocument(DatabaseRef_IncidentReport_Document(tableHooks.uc_RootData_ClientKey, rowData.key as string), updateObject)
                    .then((res_DSMD) => {
                      resolve({ success: true })
                    })
                    .catch((rej_DSMD) => {
                      tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                      reject(rej_DSMD)
                    })
                })
              },
            },
          })
        }
      },
    },
    undelete: {
      icon: (
        <Icon
          type="solid"
          icon="trash-undo"
        />
      ),
      label: <>{s_UNDELETE}</>,
      conditional_display: {
        active: true,
        logic_type: 'comparison',
        source: 'rowData',
        prop: 'status',
        comparator: '==',
        value: 'deleted',
        conditions: [],
      },
      onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        if (rowData.key != null) {
          tableHooks.uc_setUserInterface_ConfirmDialogDisplay({
            display: true,
            confirm: {
              color: 'info',
              header: s_UNDELETE_INCIDENT_REPORT,
              icon: (
                <Icon
                  icon="trash-undo"
                  type="solid"
                />
              ),
              submit_text: s_UNDELETE,
              text: s_ARE_YOU_SURE_THAT_YOU_WANT_TO_UNDELETE_THIS_INCIDENT_REPORT,
              submit_callback: () => {
                return new Promise((resolve, reject) => {
                  getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                    .then((res_GCK) => {
                      let updateObject = {
                        status: getProp(rowData, 'status_before_deletion', 'new'),
                      }
                      DatabaseSetMergeDocument(DatabaseRef_IncidentReport_Document(res_GCK.clientKey, rowData.key as string), updateObject)
                        .then((res_DSMD) => {
                          resolve({ success: true })
                        })
                        .catch((rej_DSMD) => {
                          tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                        })
                    })
                    .catch((rej_GCK) => {
                      tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                    })
                })
              },
            },
          })
        }
      },
    },
  }),
  timestamp_incident: {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return s_INCIDENT_DATE
      },
      header_sort_by: 'timestamp_incident',
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = (
          <>
            <Box
              className="tw-cursor-pointer tw-rounded-md tw-p-1 tw-text-center tw-inline-block"
              sx={{ 'background': themeVariables.background_default, '&:hover': { background: themeVariables.background_json } }}
              onClick={(event) => {
                if (rowData.key != null) {
                  // TODO - handle other user types probably
                  onClickAppNavigation(event, tableHooks.un_routerNavigation, ApplicationPages.AdminDatabaseIncidentReportViewPage.url(rowData.key as string))
                }
              }}
            >
              {returnFormattedDate(rowData.timestamp_incident, 'D MMM YYYY h:mm a')}
            </Box>
          </>
        )
        return cellJSX
      },
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        let cellCSS = ''
        return cellCSS
      },
    },
  },
  status: {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return s_STATUS
      },
      header_sort_by: 'status',
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = <></>
        if (
          rowData != null &&
          rowData.status != null &&
          incidentReportStatusOptions[rowData.status as string] != null &&
          incidentReportStatusOptions[rowData.status as string]['chipJSX'] != null
        ) {
          cellJSX = <>{incidentReportStatusOptions[rowData.status as string]['chipJSX']}</>
        } else {
          cellJSX = <>{rowData.status}</>
        }
        return cellJSX
      },
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        let cellCSS = ''
        return cellCSS
      },
    },
  },
  associated_user_name: TableCellBasic('associated_user_name', s_DRIVER, 'associated_user_name'),
  associated_vehicle_vin: TableCellBasic('associated_vehicle_vin', s_VEHICLE_VIN, 'associated_vehicle_vin'),
  incident_type: {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return s_INCIDENT_TYPE
      },
      header_sort_by: 'incident_type',
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = <></>
        if (
          rowData != null &&
          rowData.incident_type != null &&
          incidentReportTypeOptions[rowData.incident_type as string] != null &&
          incidentReportTypeOptions[rowData.incident_type as string]['chipJSX'] != null
        ) {
          cellJSX = <>{incidentReportTypeOptions[rowData.incident_type as string]['chipJSX']}</>
        } else {
          cellJSX = <>{rowData.incident_type}</>
        }
        return cellJSX
      },
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        let cellCSS = ''
        return cellCSS
      },
    },
  },
  incident_location: TableCellBasic('incident_location', s_INCIDENT_LOCATION, 'incident_location'),
  notes: TableCellBasic('notes', s_WHAT_HAPPENED, 'notes'),
}

const tableSettings_IncidentReports: TsInterface_TableDatabaseSettings = {
  rows_per_page: 100,
  show_header: true,
  size: 'small',
  sort_direction: 'desc',
  sort_property: 'timestamp_incident',
  use_live_data: true,
  conditional_row_styles: [
    {
      className: 'tw-opacity-30 tw-line-through',
      conditional_display: {
        active: true,
        logic_type: 'comparison',
        source: 'rowData',
        prop: 'status',
        comparator: '==',
        value: 'deleted',
        conditions: [],
      },
    },
  ],
}

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Container
///////////////////////////////

export const Container: React.FC = (): JSX.Element => {
  // Props
  // const params = useParams()
  // const itemKey: string = params.id as string

  // Hooks - useContext, useState, useReducer, other
  const un_routerNavigation = useNavigate()
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)
  const { uc_setUserInterface_ErrorDialogDisplay } = useContext(Context_UserInterface_ErrorDialog)
  const { uc_setUserInterface_FormDialogDisplay } = useContext(Context_UserInterface_FormDialog)

  // Hooks - useEffect

  useEffect(() => {
    document.title = se_INCIDENT_REPORTS
  }, [])

  // Functions
  const tableDatabaseEndpoint_IncidentReports = (
    queryGenerationData: TsInterface_TableDatabaseEndpointQueryObject,
    tableAdditionalData: TsInterface_TableAdditionalData,
  ) => {
    let queryOperatorsArray: TsInterface_QueryOperatorsArray = []
    // let queryOperatorsArray: TsInterface_QueryOperatorsArray = [ { prop: "status", comparator: "==", value: "active" } ]
    let orderByArray: TsInterface_OrderByArray = [{ prop: 'timestamp_incident', desc: true }]
    let queryCursorsObject: TsInterface_QueryCursorsObject = {}
    if (queryGenerationData['startAfter'] != null) {
      queryCursorsObject['startAfter'] = queryGenerationData.startAfter
    }
    if (queryGenerationData['startAt'] != null) {
      queryCursorsObject['startAt'] = queryGenerationData.startAt
    }
    if (queryGenerationData['endAt'] != null) {
      queryCursorsObject['endAt'] = queryGenerationData.endAt
    }
    if (queryGenerationData['endBefore'] != null) {
      queryCursorsObject['endBefore'] = queryGenerationData.endBefore
    }
    let limit = getProp(queryGenerationData, 'limit', 100)
    return generateDatabaseQuery(
      DatabaseRef_IncidentReports_Collection(uc_RootData_ClientKey as string),
      queryOperatorsArray,
      orderByArray,
      queryCursorsObject,
      limit,
    )
  }

  const createIncidentReport = (): void => {
    uc_setUserInterface_FormDialogDisplay({
      display: true,
      form: {
        form: {
          formAdditionalData: {},
          formData: {},
          formInputs: formInputs_IncidentReport,
          formOnChange: (
            formAdditionalData: TsInterface_FormAdditionalData,
            formData: TsInterface_FormData,
            formInputs: TsInterface_FormInputs,
            formSettings: TsInterface_FormSettings,
          ) => {},
          formSettings: {},
          formSubmission: (
            formSubmittedData: TsInterface_FormSubmittedData,
            formAdditionalData: TsInterface_FormAdditionalData,
            formHooks: TsInterface_FormHooksObject,
          ) => {
            return new Promise((resolve, reject) => {
              getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                .then((res_GCK) => {
                  formSubmittedData.status = 'new'
                  formSubmittedData.timestamp_incident = new Date(formSubmittedData.timestamp_incident)
                  DatabaseAddDocument(DatabaseRef_IncidentReports_Collection(res_GCK.clientKey), formSubmittedData, true)
                    .then((res_DSMD) => {
                      resolve({ success: true })
                      directAppNavigation(un_routerNavigation, ApplicationPages.AdminDatabaseIncidentReportViewPage.url(res_DSMD.key))
                    })
                    .catch((rej_DSMD) => {
                      uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                    })
                })
                .catch((rej_GCK) => {
                  uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                })
            })
          },
        },
        dialog: {
          formDialogHeaderColor: 'success',
          formDialogHeaderText: <>{s_CREATE_INCIDENT_REPORT}</>,
          formDialogIcon: (
            <Icon
              type="solid"
              icon="pen-to-square"
            />
          ),
        },
      },
    })
  }

  // JSX Generation
  const rJSX_NewIncidentReportButton = (): JSX.Element => {
    let buttonJSX = <></>
    buttonJSX = (
      <Button
        color="success"
        variant="contained"
        onClick={() => {
          createIncidentReport()
        }}
        disableElevation
        className="tw-mr-2"
        startIcon={
          <Icon
            icon="circle-plus"
            type="solid"
          />
        }
      >
        {s_NEW_INCIDENT_REPORT}
      </Button>
    )
    return buttonJSX
  }

  const rJSX_IncidentReportTable = (): JSX.Element => {
    let tableJSX = <></>
    if (uc_RootData_ClientKey != null) {
      tableJSX = (
        <Box>
          <Card className="tw-mt-2">
            <TableDatabase
              tableAdditionalData={{}}
              tableColumns={tableColumns_IncidentReports}
              tableDatabaseEndpoint={tableDatabaseEndpoint_IncidentReports}
              tableSettings={tableSettings_IncidentReports}
            />
          </Card>
        </Box>
      )
    } else {
      tableJSX = <></>
    }
    return tableJSX
  }

  const rJSX_Page = (): JSX.Element => {
    let pageJSX = (
      <AuthenticatedContainer
        pageHeader={s_INCIDENT_REPORTS}
        pageKey={pageKey}
        content={
          <Box>
            <TabButtons tabButtons={[{ fullJSX: rJSX_NewIncidentReportButton(), minJSX: rJSX_NewIncidentReportButton(), sizeCutoff: 0 }]} />
            {rJSX_IncidentReportTable()}
          </Box>
        }
      />
    )
    return pageJSX
  }

  // Render
  return <>{rJSX_Page()}</>
}
