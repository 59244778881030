///////////////////////////////
// Imports
///////////////////////////////

import { underscoresToSpacesAndCapitalize } from 'rfbp_core/services/helper_functions'
import { TsInterface_TableAdditionalData, TsInterface_TableColumn, TsInterface_TableDataRow, TsInterface_TableHooks, TsType_TableSortBy } from '../table_types'

///////////////////////////////
// Exports
///////////////////////////////

export const TableCellCommaSeparated = (propKey: string, propName: string | JSX.Element, sortBy: TsType_TableSortBy): TsInterface_TableColumn => {
  let basicCell: TsInterface_TableColumn = {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return <>{propName}</>
      },
      header_sort_by: sortBy,
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        const data = rowData[propKey]

        if (data === null || data === undefined) {
          return <>{''}</>
        } else if (Array.isArray(data)) {
          const capitalizedData = data.map((item) => underscoresToSpacesAndCapitalize(item))
          return <>{capitalizedData.join(', ')}</>
        } else {
          return <>{data}</>
        }
      },
    },
  }
  return basicCell
}
