///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			Model files contains data and business logic specific to an individual database collection type

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import {
  AppBar,
  Box,
  Chip,
  Dialog,
  DialogContent,
  Divider,
  IconButton,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material'
import React, { useContext, useEffect, useReducer, useState } from 'react'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import { ApplicationPages } from 'rfbp_aux/data/application_structure'
import { DatabaseRef_FinalizedPayroll_Document } from 'rfbp_aux/services/database_endpoints/finances/finalized_payroll'
import { DatabaseRef_PayrollAdjustments_Document } from 'rfbp_aux/services/database_endpoints/finances/payroll_adjustments'
import { DatabaseRef_PayrollLogs_Document } from 'rfbp_aux/services/database_endpoints/finances/payroll_logs'
import { DatabaseRef_Task_Document } from 'rfbp_aux/services/database_endpoints/operations/tasks'
import {
  TsInterface_FormAdditionalData,
  TsInterface_FormData,
  TsInterface_FormHooksObject,
  TsInterface_FormInputs,
  TsInterface_FormSettings,
  TsInterface_FormSubmittedData,
} from 'rfbp_core/components/form'
import { Icon } from 'rfbp_core/components/icons'
import {
  TableCellBasic,
  TableCellManage,
  TableCellTimestamp,
  TsInterface_TableAdditionalData,
  TsInterface_TableColumns,
  TsInterface_TableDataRow,
  TsInterface_TableHooks,
  TsInterface_TableSettings,
} from 'rfbp_core/components/table'
import { rLIB } from 'rfbp_core/localization/library'
import {
  Context_RootData_ClientKey,
  Context_UserInterface_CustomDialog,
  Context_UserInterface_ErrorDialog,
  Context_UserInterface_FormDialog,
  UserInterface_Default_CustomDialogDisplayState,
} from 'rfbp_core/services/context'
import {
  DatabaseBatchUpdate,
  DatabaseGetDocument,
  DatabaseGetLiveDocument,
  DatabaseSetMergeDocument,
  TsInterface_DatabaseBatchUpdatesArray,
} from 'rfbp_core/services/database_management'
import {
  dynamicSort,
  formatCurrency,
  getProp,
  objectToArray,
  returnDateFromUnknownDateFormat,
  returnFormattedDate,
  returnFormattedDateKey,
} from 'rfbp_core/services/helper_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject, TsType_UnknownPromise, TsType_VoidFunction } from 'rfbp_core/typescript/global_types'
import { v4 as uuidv4 } from 'uuid'
import { getLastChronologicalDate, getStartAndEndOfWeek } from '../data/payroll_calculations'

///////////////////////////////
// Typescript
///////////////////////////////

interface TsInterface_PayrollAdjustmentsDialog {
  payrollAdjustmentKey: string
  payrollMondayDateKey: string
  payrollLocked: boolean
  employeePayrollData: TsInterface_UnspecifiedObject
}

///////////////////////////////
// Variables
///////////////////////////////

///////////////////////////////
// Functions
///////////////////////////////

const returnDiscrepancyHighlightClassName = (
  propToCheckAgainst: string,
  tableAdditionalData: TsInterface_UnspecifiedObject,
  rowData: TsInterface_UnspecifiedObject,
): string => {
  let cellCSS = ''
  if (
    tableAdditionalData != null &&
    tableAdditionalData.us_discrepancyWeekPayrollData != null &&
    tableAdditionalData.us_discrepancyWeekPayrollData.employee_full_payroll_data != null &&
    rowData != null &&
    rowData.key != null &&
    tableAdditionalData.us_discrepancyWeekPayrollData.employee_full_payroll_data[rowData.key as string] != null &&
    tableAdditionalData.us_discrepancyWeekPayrollData.employee_full_payroll_data[rowData.key as string][propToCheckAgainst] !== rowData[propToCheckAgainst]
  ) {
    cellCSS = 'tw-border-warning_main tw-border-2'
  }
  return cellCSS
}

const rJSX_SummaryTableCell = (
  propToCheckAgainst: string,
  rowData: TsInterface_UnspecifiedObject,
  tableAdditionalData: TsInterface_UnspecifiedObject,
  tableHooks: TsInterface_TableHooks,
  returnCellJSX: any,
): JSX.Element => {
  let contentWithContainerJSX = <></>
  if (getProp(tableAdditionalData, 'payroll_week_data_locked', null) === false) {
    contentWithContainerJSX = returnCellJSX(rowData, tableAdditionalData, tableHooks)
  } else if (getProp(tableAdditionalData, 'payroll_week_data_locked', null) === true) {
    if (
      tableAdditionalData != null &&
      tableAdditionalData.us_discrepancyWeekPayrollData != null &&
      tableAdditionalData.us_discrepancyWeekPayrollData.employee_full_payroll_data != null &&
      rowData != null &&
      rowData.key != null &&
      tableAdditionalData.us_discrepancyWeekPayrollData.employee_full_payroll_data[rowData.key as string] != null &&
      tableAdditionalData.us_discrepancyWeekPayrollData.employee_full_payroll_data[rowData.key as string][propToCheckAgainst] !== rowData[propToCheckAgainst]
    ) {
      let liveData = tableAdditionalData.us_discrepancyWeekPayrollData.employee_full_payroll_data[rowData.key as string]
      contentWithContainerJSX = (
        <Box>
          <Box className="tw-inline-block tw-mr-2">{returnCellJSX(rowData, tableAdditionalData, tableHooks)}</Box>
          <Box
            className="tw-inline-block"
            sx={{ color: themeVariables.warning_main }}
          >
            ({rLIB('now')} {returnCellJSX(liveData, tableAdditionalData, tableHooks)})
          </Box>
        </Box>
      )
    } else {
      contentWithContainerJSX = returnCellJSX(rowData, tableAdditionalData, tableHooks)
    }
  }
  return contentWithContainerJSX
}

function parseDateNumber(dateNumber: number) {
  // Convert the dateNumber to a string
  const dateString = dateNumber.toString()
  // Extract year, month, and day components from the string
  const year = parseInt(dateString.substring(0, 4), 10)
  const month = parseInt(dateString.substring(4, 6), 10) - 1 // Months in JavaScript are 0-based
  const day = parseInt(dateString.substring(6, 8), 10)
  // Create a JavaScript Date object
  const parsedDate = new Date(year, month, day)
  return parsedDate
}

export const includeAdjustmentInPayrollWeek = (
  clientKey: string,
  payrollMondayDateKey: string,
  payrollAdjustmentKey: string,
  adjustment: TsInterface_UnspecifiedObject,
  confirmedAmount: number, // Must be less than us_payrollAdjustment.amount
  returnOrSave: 'return' | 'save',
) => {
  return new Promise((resolve, reject) => {
    confirmedAmount = Math.abs(confirmedAmount)
    if (adjustment['type'] === 'payroll_bonus_decrease') {
      // Cannot exceed remaining balance of adjustment
      let remainingAdjustmentAmount = getProp(adjustment, 'total_adjustment_amount', 0)
      for (let loopWeekKey in adjustment.associated_payroll_week_adjustment_amounts) {
        if (loopWeekKey !== payrollMondayDateKey) {
          remainingAdjustmentAmount -= adjustment.associated_payroll_week_adjustment_amounts[loopWeekKey]
        }
      }
      if (remainingAdjustmentAmount < confirmedAmount) {
        confirmedAmount = remainingAdjustmentAmount
      }
      // Cannot exceed bonus pay
      // if( getProp( employeePayrollData, "finalBonusPay", 0 ) < confirmedAmount ){
      // 	confirmedAmount = getProp( employeePayrollData, "finalBonusPay", 0 )
      // }
    } else if (adjustment['type'] === 'payroll_bonus_increase') {
      // No Limit
    }
    let updateObject: TsInterface_UnspecifiedObject = {
      associated_payroll_weeks: {
        [payrollMondayDateKey]: true,
      },
      associated_payroll_week_adjustment_amounts: {
        [payrollMondayDateKey]: confirmedAmount,
      },
    }
    // Determine Remaining Adjustment Amount
    let remainingAdjustmentAmount = getProp(adjustment, 'total_adjustment_amount', 0) - confirmedAmount
    for (let loopWeekKey in adjustment.associated_payroll_week_adjustment_amounts) {
      if (loopWeekKey !== payrollMondayDateKey) {
        remainingAdjustmentAmount -= adjustment.associated_payroll_week_adjustment_amounts[loopWeekKey]
      }
    }
    updateObject['remaining_adjustment_amount'] = remainingAdjustmentAmount
    if (remainingAdjustmentAmount <= 0) {
      updateObject['status'] = 'resolved'
    } else {
      updateObject['status'] = 'unresolved'
    }
    if (returnOrSave === 'return') {
      let updateArray: TsInterface_DatabaseBatchUpdatesArray = [
        { type: 'setMerge', ref: DatabaseRef_PayrollAdjustments_Document(clientKey, payrollAdjustmentKey), data: updateObject },
      ]
      resolve({ success: true, updateArray: updateArray })
    } else {
      DatabaseSetMergeDocument(DatabaseRef_PayrollAdjustments_Document(clientKey, payrollAdjustmentKey), updateObject)
        .then((res_DSMD) => {
          resolve(res_DSMD)
        })
        .catch((rej_DSMD) => {
          reject(rej_DSMD)
        })
    }
  })
}

export const removeAdjustmentFromPayrollWeek = (
  clientKey: string,
  payrollMondayDateKey: string,
  payrollAdjustmentKey: string,
  adjustment: TsInterface_UnspecifiedObject,
  returnOrSave: 'return' | 'save',
) => {
  return new Promise((resolve, reject) => {
    let updateObject: TsInterface_UnspecifiedObject = {
      status: 'unresolved',
      associated_payroll_weeks: {
        [payrollMondayDateKey]: false,
      },
      associated_payroll_week_adjustment_amounts: {
        [payrollMondayDateKey]: 0,
      },
    }
    // Determine Remaining Adjustment Amount
    let remainingAdjustmentAmount = getProp(adjustment, 'total_adjustment_amount', 0)
    for (let loopWeekKey in adjustment.associated_payroll_week_adjustment_amounts) {
      if (loopWeekKey !== payrollMondayDateKey) {
        remainingAdjustmentAmount -= adjustment.associated_payroll_week_adjustment_amounts[loopWeekKey]
      }
    }
    updateObject['remaining_adjustment_amount'] = remainingAdjustmentAmount
    if (returnOrSave === 'return') {
      let updateArray: TsInterface_DatabaseBatchUpdatesArray = [
        { type: 'setMerge', ref: DatabaseRef_PayrollAdjustments_Document(clientKey, payrollAdjustmentKey), data: updateObject },
      ]
      resolve({ success: true, updateArray: updateArray })
    } else {
      DatabaseSetMergeDocument(DatabaseRef_PayrollAdjustments_Document(clientKey, payrollAdjustmentKey), updateObject)
        .then((res_DSMD) => {
          resolve(res_DSMD)
        })
        .catch((rej_DSMD) => {
          reject(rej_DSMD)
        })
    }
  })
}

// Custom Dialog for managing payroll adjustments
const PayrollAdjustmentsCustomDialog: React.FC<TsInterface_PayrollAdjustmentsDialog> = ({
  payrollAdjustmentKey,
  payrollMondayDateKey,
  payrollLocked,
  employeePayrollData,
}): JSX.Element => {
  // Props

  // Hooks - useContext, useState, useReducer, other
  // { sort-start } - hooks
  const [us_payrollAdjustment, us_setPayrollAdjustment] = useState<TsInterface_UnspecifiedObject>({})
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)
  const { uc_setUserInterface_CustomDialogDisplay } = useContext(Context_UserInterface_CustomDialog)
  const { uc_setUserInterface_ErrorDialogDisplay } = useContext(Context_UserInterface_ErrorDialog)
  const { uc_setUserInterface_FormDialogDisplay } = useContext(Context_UserInterface_FormDialog)
  // { sort-end } - hooks

  // Hooks - useEffect
  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setPayrollAdjustment(newData)
      ur_forceRerender()
    }
    if (payrollAdjustmentKey != null) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          unsubscribeLiveData = DatabaseGetLiveDocument(DatabaseRef_PayrollAdjustments_Document(res_GCK.clientKey, payrollAdjustmentKey), updateLiveData)
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, ur_forceRerender, uc_setRootData_ClientKey, payrollAdjustmentKey])

  // Functions

  // JSX Generation
  const rJSX_AdjustmentTypeChip = (): JSX.Element => {
    let chipJSX = <></>
    if (us_payrollAdjustment['type'] === 'payroll_bonus_decrease') {
      chipJSX = (
        <Chip
          component={'span'}
          label={rLIB('Reduction')}
          color="error"
          variant="filled"
        />
      )
    } else if (us_payrollAdjustment['type'] === 'payroll_bonus_increase') {
      chipJSX = (
        <Chip
          component={'span'}
          label={rLIB('Addition')}
          color="success"
          variant="filled"
        />
      )
    } else if (us_payrollAdjustment['type'] != null) {
      chipJSX = (
        <Chip
          component={'span'}
          label={us_payrollAdjustment['type']}
          color="warning"
          variant="filled"
        />
      )
    }
    return chipJSX
  }

  const rJSX_EditIcon = (matchObject: TsInterface_UnspecifiedObject): JSX.Element => {
    let iconJSX = <></>
    if (payrollLocked === false) {
      iconJSX = (
        <Icon
          className="tw-inline-block tw-ml-2 tw-cursor-pointer tw-opacity-30 hover:tw-opacity-100 hover:tw-text-success_main"
          icon="pen-to-square"
          tooltip={rLIB('Edit')}
          tooltipPlacement="right"
          onClick={() => {
            uc_setUserInterface_FormDialogDisplay({
              display: true,
              form: {
                form: {
                  formAdditionalData: {},
                  formData: {
                    amount: getProp(matchObject, 'amount', 0),
                  },
                  formInputs: {
                    amount: {
                      data_type: 'number',
                      input_type: 'text_number',
                      key: 'amount',
                      label: rLIB('Amount'),
                      required: true,
                    },
                  },
                  formOnChange: (
                    formAdditionalData: TsInterface_FormAdditionalData,
                    formData: TsInterface_FormData,
                    formInputs: TsInterface_FormInputs,
                    formSettings: TsInterface_FormSettings,
                  ) => {},
                  formSettings: {},
                  formSubmission: (
                    formSubmittedData: TsInterface_FormSubmittedData,
                    formAdditionalData: TsInterface_FormAdditionalData,
                    formHooks: TsInterface_FormHooksObject,
                  ) => {
                    return new Promise((resolve, reject) => {
                      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                        .then((res_GCK) => {
                          if (getProp(formSubmittedData, 'amount', 0) > 0) {
                            includeAdjustmentInPayrollWeek(
                              res_GCK.clientKey,
                              payrollMondayDateKey,
                              payrollAdjustmentKey,
                              us_payrollAdjustment,
                              getProp(formSubmittedData, 'amount', 0),
                              'save',
                            ).finally(() => {
                              resolve({ success: true })
                            })
                          } else {
                            removeAdjustmentFromPayrollWeek(
                              res_GCK.clientKey,
                              payrollMondayDateKey,
                              payrollAdjustmentKey,
                              us_payrollAdjustment,
                              'save',
                            ).finally(() => {
                              resolve({ success: true })
                            })
                          }
                        })
                        .catch((rej_GCK) => {
                          uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                        })
                    })
                  },
                },
                dialog: {
                  formDialogHeaderColor: 'success',
                  formDialogHeaderText: (
                    <>
                      {rLIB('Edit Adjustment Amount for')} {payrollMondayDateKey}
                    </>
                  ),
                  formDialogIcon: (
                    <Icon
                      type="solid"
                      icon="pen-to-square"
                    />
                  ),
                },
              },
            })
          }}
        />
      )
    } else {
      iconJSX = <></>
    }
    return iconJSX
  }

  const rJSX_PayrollAdjustmentHistory = (): JSX.Element => {
    let historyJSX = <></>
    let adjustmentHistoryObject: TsInterface_UnspecifiedObject = {}
    let adjustmentHistoryArray: TsInterface_UnspecifiedObject[] = []
    for (let loopDateKey in getProp(us_payrollAdjustment, 'associated_payroll_week_adjustment_amounts', {})) {
      if (us_payrollAdjustment.associated_payroll_weeks[loopDateKey] === true) {
        adjustmentHistoryObject[loopDateKey] = {
          date: loopDateKey,
          amount: getProp(getProp(us_payrollAdjustment, 'associated_payroll_week_adjustment_amounts', {}), loopDateKey, 0),
        }
      }
    }
    if (adjustmentHistoryObject[payrollMondayDateKey] == null) {
      adjustmentHistoryObject[payrollMondayDateKey] = {
        date: payrollMondayDateKey,
        amount: 0,
      }
    }
    adjustmentHistoryArray = objectToArray(adjustmentHistoryObject).sort(dynamicSort('date', 'asc'))
    historyJSX = (
      <Box>
        <TableContainer sx={{ background: themeVariables.background_default }}>
          <Table size="small">
            <TableBody>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>
                  <Typography
                    variant="subtitle1"
                    className="tw-bold"
                  >
                    {rLIB('Date')}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    variant="subtitle1"
                    className="tw-bold"
                  >
                    {rLIB('Amount')}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow sx={{ background: themeVariables.background_json }}>
                <TableCell></TableCell>
                <TableCell>
                  <Typography
                    variant="subtitle1"
                    className="tw-bold"
                  >
                    {rLIB('Total Adjustment Amount')}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    variant="subtitle1"
                    className="tw-bold"
                  >
                    {formatCurrency(getProp(us_payrollAdjustment, 'total_adjustment_amount', 0))}
                  </Typography>
                </TableCell>
              </TableRow>
              {adjustmentHistoryArray.map((matchObject, index) => (
                <TableRow key={index}>
                  <TableCell sx={{ width: '30px' }}>
                    {matchObject.date === payrollMondayDateKey ? (
                      <Icon
                        icon="star"
                        sx={{ color: themeVariables.warning_main, fontSize: '18px' }}
                      />
                    ) : (
                      <></>
                    )}
                  </TableCell>
                  <TableCell>
                    <Typography variant="subtitle1">{matchObject.date}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="subtitle1">
                      {formatCurrency(matchObject.amount)}
                      {matchObject.date === payrollMondayDateKey ? rJSX_EditIcon(matchObject) : <></>}
                    </Typography>
                  </TableCell>
                </TableRow>
              ))}
              <TableRow sx={{ background: themeVariables.background_json }}>
                <TableCell></TableCell>
                <TableCell>
                  <Typography
                    variant="subtitle1"
                    className="tw-bold"
                  >
                    {rLIB('Remaining Adjustment Amount')}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    variant="subtitle1"
                    className="tw-bold"
                  >
                    {formatCurrency(getProp(us_payrollAdjustment, 'remaining_adjustment_amount', 0))}
                  </Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    )
    return historyJSX
  }

  const rJSX_Dialog = (): JSX.Element => {
    let dialogJSX = (
      <Box>
        <Dialog
          className="bp_dialog_xl_width"
          keepMounted
          onClose={() => {
            uc_setUserInterface_CustomDialogDisplay(UserInterface_Default_CustomDialogDisplayState)
          }}
          open={true}
        >
          <AppBar
            position="static"
            color="inherit"
          >
            <Toolbar>
              <IconButton
                aria-label="menu"
                color="inherit"
                disabled
                edge="start"
                size="large"
                sx={{ mr: 2, color: '#fff !important' }}
              >
                <Icon icon="message-dollar" />
              </IconButton>
              <Typography
                component={'span'}
                variant={'h6'}
                sx={{ flexGrow: 1 }}
              >
                {rLIB('Manage Payroll Adjustment')}
              </Typography>
            </Toolbar>
          </AppBar>
          <DialogContent sx={{ padding: '0px' }}>
            <Box className="tw-p-4">
              <Typography variant="h6">
                <Box
                  component="span"
                  className="tw-font-bold tw-mr-1"
                >
                  {rLIB('Employee')}:{' '}
                </Box>
                <Box
                  component="span"
                  className="tw-opacity-50"
                >
                  {getProp(us_payrollAdjustment, 'associated_user_name', '')}
                </Box>
              </Typography>
              <Typography variant="h6">
                <Box
                  component="span"
                  className="tw-font-bold tw-mr-1"
                >
                  {rLIB('Total Adjustment Amount')}:{' '}
                </Box>
                <Box
                  component="span"
                  className="tw-opacity-50 tw-mr-2"
                >
                  {formatCurrency(getProp(us_payrollAdjustment, 'total_adjustment_amount', 0))}
                </Box>
                {rJSX_AdjustmentTypeChip()}
              </Typography>
              <Typography variant="h6">
                <Box
                  component="span"
                  className="tw-font-bold tw-mr-1"
                >
                  {rLIB('Remaining Adjustment Amount')}:{' '}
                </Box>
                <Box
                  component="span"
                  className="tw-opacity-50 tw-mr-2"
                >
                  {formatCurrency(getProp(us_payrollAdjustment, 'remaining_adjustment_amount', 0))}
                </Box>
              </Typography>
              <Typography variant="h6">
                <Box
                  component="span"
                  className="tw-font-bold tw-mr-1"
                >
                  {rLIB('Notes')}:{' '}
                </Box>
                <Box
                  component="span"
                  className="tw-opacity-50"
                >
                  {getProp(us_payrollAdjustment, 'notes', '')}
                </Box>
              </Typography>
              <Typography variant="h6">
                <Box
                  component="span"
                  className="tw-font-bold tw-mr-1"
                >
                  {rLIB('Adjustment Created By')}:{' '}
                </Box>
                <Box
                  component="span"
                  className="tw-opacity-50 tw-mr-1"
                >
                  {getProp(us_payrollAdjustment, 'associated_creator_name', '')}
                </Box>
                <Box
                  component="span"
                  className="tw-opacity-50"
                >
                  ({returnFormattedDate(us_payrollAdjustment.timestamp_created, 'D MMM YYYY h:mm a')})
                </Box>
              </Typography>
              <Divider className="tw-my-4" />
              <Typography variant="h6">
                <Box
                  component="span"
                  className="tw-font-bold tw-mr-1"
                >
                  {rLIB('Unit Pay Bonus')}:{' '}
                </Box>
                <Box
                  component="span"
                  className="tw-opacity-50 tw-mr-1"
                >
                  {formatCurrency(getProp(employeePayrollData, 'unitPayBonus', 0))}
                </Box>
              </Typography>
              <Typography variant="h6">
                <Box
                  component="span"
                  className="tw-font-bold tw-mr-1"
                >
                  {rLIB('UPB after adjustments')}:{' '}
                </Box>
                <Box
                  component="span"
                  className="tw-opacity-50 tw-mr-1"
                >
                  {formatCurrency(getProp(employeePayrollData, 'finalBonusPay', 0))}
                </Box>
              </Typography>
              <Typography
                variant="body1"
                className="tw-opacity-30"
              >
                *{rLIB('Bonus Values will not update without reloading window')}
              </Typography>
              <Divider className="tw-my-4" />
              {rJSX_PayrollAdjustmentHistory()}
            </Box>
          </DialogContent>
        </Dialog>
      </Box>
    )
    return dialogJSX
  }

  return <>{rJSX_Dialog()}</>
}

///////////////////////////////
// Exports
///////////////////////////////

export const formatDateRange = (dateArray: string[]) => {
  if (!Array.isArray(dateArray) || dateArray.length === 0) {
    return ''
  }
  const formatDate = (date: any) => {
    const day = date.getDate()
    const month = date.toLocaleString('default', { month: 'short' })
    const year = date.getFullYear().toString().slice(-2) // Get the last 2 digits of the year
    return `${day} ${month} ${year}`
  }
  if (dateArray.length === 1) {
    const date = new Date(dateArray[0])
    date.setDate(date.getDate() + 1) // Add 1 day to avoid date modification
    return formatDate(date)
  } else {
    const firstDate = new Date(dateArray[0])
    const lastDate = new Date(dateArray[dateArray.length - 1])
    firstDate.setDate(firstDate.getDate() + 1) // Add 1 day to avoid date modification
    lastDate.setDate(lastDate.getDate() + 1) // Add 1 day to avoid date modification
    return `${formatDate(firstDate)} - ${formatDate(lastDate)}`
  }
}

export const getDaysBetweenDates = (date1: Date, date2: Date) => {
  // Convert the input dates to JavaScript Date objects
  const startDate = new Date(date1)
  const endDate = new Date(date2)
  // Calculate the time difference in milliseconds
  // @ts-expect-error - TODO: reason for error
  const timeDifference = endDate - startDate
  // Convert milliseconds to days (1 day = 24 * 60 * 60 * 1000 milliseconds)
  const daysDifference = Math.floor(timeDifference / (24 * 60 * 60 * 1000))
  return daysDifference
}

export const tableSettings_AdHocTasks: TsInterface_TableSettings = {
  paginated: false,
  show_header: true,
  size: 'small',
  sort_direction: 'asc',
  sort_property_default: 'name',
  sortable: true,
  collapsible_columns: true,
}

export const tableColumns_UnitPayBreakdown: TsInterface_TableColumns = {
  manage: TableCellManage({
    wait: {
      icon: (
        <Icon
          type="solid"
          icon="pause"
        />
      ),
      label: <>{rLIB('Wait for Sales Partner Approval')}</>,
      onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        if (rowData.key != null) {
          tableHooks.uc_setUserInterface_ConfirmDialogDisplay({
            display: true,
            confirm: {
              color: 'warning',
              icon: <Icon icon="pause" />,
              header: rLIB('Wait for Sales Partner Approval'),
              text: <>{rLIB('Are you sure that you want to remove this task from the current payroll period until sales partner approval?')}</>,
              submit_text: rLIB('Temporarily Remove From Payroll'),
              submit_callback: () => {
                return new Promise((resolve, reject) => {
                  getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                    .then((res_GCK) => {
                      let updateObject = {
                        sales_partner_approval_required: true,
                        sales_partner_approval_granted: false,
                      }
                      DatabaseSetMergeDocument(DatabaseRef_Task_Document(res_GCK.clientKey, rowData.associated_task_key as string), updateObject)
                        .then((res_DSMD) => {
                          resolve(res_DSMD)
                        })
                        .catch((rej_DSMD) => {
                          tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                          reject(rej_DSMD)
                        })
                    })
                    .catch((rej_GCK) => {
                      tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                      reject(rej_GCK)
                    })
                })
              },
            },
          })
        }
      },
    },
  }),
  TEMP_unit_pay_errors: {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return <></>
      },
      header_sort_by: null,
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let errorIconJSX = <></>
        let warningIconJSX = <></>
        let noErrorOrWarningIconJSX = (
          <Box className="tw-inline-block tw-mr-2">
            <Icon
              icon="badge-check"
              sx={{ color: themeVariables.success_light, fontSize: '18px' }}
              tooltip={rLIB('No Errors or Warnings')}
              tooltipPlacement="top"
              className="tw-mr-0"
            />
          </Box>
        )
        if (objectToArray(getProp(rowData, 'unit_pay_errors', {})).length > 0) {
          errorIconJSX = (
            <Box className="tw-inline-block tw-mr-2">
              <Icon
                icon="triangle-exclamation"
                sx={{ color: themeVariables.error_main, fontSize: '18px' }}
                tooltip={
                  <>
                    {rLIB('Errors')}: {objectToArray(getProp(rowData, 'unit_pay_errors', {})).join(', ')}
                  </>
                }
                tooltipPlacement="top"
                className="tw-mr-1"
              />
              <Typography
                className="tw-inline-block"
                variant="body1"
                sx={{ color: themeVariables.error_main }}
              >
                {objectToArray(getProp(rowData, 'unit_pay_errors', {})).length}
              </Typography>
            </Box>
          )
          noErrorOrWarningIconJSX = <></>
        }
        if (objectToArray(getProp(rowData, 'unit_pay_warnings', {})).length > 0) {
          warningIconJSX = (
            <Box className="tw-inline-block tw-mr-2">
              <Icon
                icon="triangle-exclamation"
                sx={{ color: themeVariables.warning_main, fontSize: '18px' }}
                tooltip={
                  <>
                    {rLIB('Warnings')}: {objectToArray(getProp(rowData, 'unit_pay_warnings', {})).join(', ')}
                  </>
                }
                tooltipPlacement="top"
                className="tw-mr-1"
              />
              <Typography
                className="tw-inline-block"
                variant="body1"
                sx={{ color: themeVariables.warning_main }}
              >
                {objectToArray(getProp(rowData, 'unit_pay_warnings', {})).length}
              </Typography>
            </Box>
          )
          noErrorOrWarningIconJSX = <></>
        }

        let cellJSX = (
          <Box>
            {noErrorOrWarningIconJSX}
            {errorIconJSX}
            {warningIconJSX}
          </Box>
        )
        return cellJSX
      },
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        let cellCSS = ''
        return cellCSS
      },
    },
  },
  exclude_from_unit_pay: {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rLIB('Exclude from unit pay')
      },
      header_sort_by: 'exclude_from_unit_pay',
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let disabledSwitch = false
        if (
          tableAdditionalData != null &&
          tableAdditionalData.us_finalizedWeekPayrollRoot != null &&
          tableAdditionalData.us_finalizedWeekPayrollRoot.locked === true
        ) {
          disabledSwitch = true
        }
        let cellJSX = (
          <Box>
            <Switch
              disabled={disabledSwitch}
              color="info"
              checked={(rowData.exclude_from_unit_pay as boolean) || false}
              onChange={(event, value) => {
                let updateObject: TsInterface_UnspecifiedObject = {
                  exclude_from_unit_pay: value,
                }
                getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                  .then((res_GCK) => {
                    DatabaseSetMergeDocument(DatabaseRef_Task_Document(res_GCK.clientKey, rowData.associated_task_key as string), updateObject)
                      .then((res_DSMD) => {
                        // nothing
                      })
                      .catch((rej_DSMD) => {
                        console.error(rej_DSMD)
                      })
                  })
                  .catch((rej_GCK) => {
                    console.error(rej_GCK)
                  })
              }}
            />
          </Box>
        )
        return cellJSX
      },
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        let cellCSS = ''
        if (rowData != null && rowData.exclude_from_unit_pay === true) {
          cellCSS = 'tw-bg-warning_main tw-text-white'
        }
        return cellCSS
      },
    },
  },
  associated_project_id_number: {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rLIB('Job Code')
      },
      header_sort_by: 'associated_project_id_number',
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = (
          <Box>
            {rowData.associated_project_id_number}
            <Icon
              icon="arrow-up-right-from-square"
              className="tw-ml-2 tw-opacity-20 hover:tw-opacity-100 tw-cursor-pointer tw-text-info-main"
              tooltip={rLIB('Open Project')}
              tooltipPlacement="right"
              onClick={() => {
                let url = window.location.origin + ApplicationPages.AdminActiveProjectViewPage.url(rowData.associated_project_key as string)
                window.open(url, '_blank')
              }}
            />
          </Box>
        )
        return cellJSX
      },
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        let cellCSS = ''
        return cellCSS
      },
    },
  },
  name: TableCellBasic('name', rLIB('Task Name'), 'name'),
  associated_team_name: {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rLIB('Team Name')
      },
      header_sort_by: 'associated_team_name',
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = <Box>{rowData.associated_team_name}</Box>
        return cellJSX
      },
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        let cellCSS = ''
        return cellCSS
      },
    },
  },
  task_completion_scheduled_dates: {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rLIB('Scheduled Dates')
      },
      header_sort_by: 'task_completion_scheduled_dates',
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = <></>
        if (rowData != null && rowData.task_completion_scheduled_dates != null) {
          let goToCalendarIconJSX = (
            <Icon
              icon="calendar-arrow-up"
              className="tw-cursor-pointer tw-opacity-30 hover:tw-opacity-100 tw-text-info-main tw-ml-2"
              tooltip={rLIB('View on Calendar')}
              tooltipPlacement="right"
              onClick={() => {
                let datesArray = getProp(rowData, 'task_completion_scheduled_dates', [])
                if (datesArray.length > 0) {
                  let url = window.location.origin + ApplicationPages.TaskCalendarSchedulePage.url(datesArray[datesArray.length - 1]) + '?tab=Day'
                  window.open(url, '_blank')
                }
              }}
            />
          )
          cellJSX = (
            <Box>
              {formatDateRange(getProp(rowData, 'task_completion_scheduled_dates', []))}
              {goToCalendarIconJSX}
            </Box>
          )
        }
        return cellJSX
      },
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        let cellCSS = ''
        let differenceBetweenDates = 0
        if (
          rowData != null &&
          rowData.timestamp_completed != null &&
          rowData.task_completion_scheduled_dates != null &&
          objectToArray(getProp(rowData, 'task_completion_scheduled_dates', [])).length > 0
        ) {
          let lastScheduledWorkDay = getLastChronologicalDate(getProp(rowData, 'task_completion_scheduled_dates', []))
          differenceBetweenDates = Math.abs(
            getDaysBetweenDates(returnDateFromUnknownDateFormat(lastScheduledWorkDay), returnDateFromUnknownDateFormat(rowData.timestamp_completed)),
          )
        }
        if (differenceBetweenDates > 12) {
          cellCSS = 'tw-bg-error_main tw-text-white'
        } else if (differenceBetweenDates > 5) {
          cellCSS = 'tw-bg-warning_main tw-text-white'
        }
        return cellCSS
      },
    },
  },
  timestamp_completed: {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rLIB('Task Completed On')
      },
      header_sort_by: 'timestamp_completed',
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = <></>
        let editIconJSX = (
          <Icon
            icon="pen-to-square"
            className="tw-ml-2 tw-opacity-30 hover:tw-opacity-100 tw-cursor-pointer"
            tooltip={rLIB('Edit')}
            tooltipPlacement="right"
            onClick={() => {
              let formData: TsInterface_UnspecifiedObject = {}
              if (rowData != null && rowData.timestamp_completed != null) {
                formData['timestamp_completed'] = returnFormattedDate(rowData.timestamp_completed, 'YYYY-MM-DDTHH:mm')
              }
              // Open Edit Form
              tableHooks.uc_setUserInterface_FormDialogDisplay({
                display: true,
                form: {
                  form: {
                    formAdditionalData: {},
                    formData: formData,
                    formInputs: {
                      header: {
                        data_type: 'string',
                        key: 'header',
                        input_type: 'custom_form_input_jsx',
                        label: <></>,
                        required: false,
                        renderCustomFormInput: (
                          formInput: any,
                          formInputs: any,
                          formData: any,
                          formInputChange: any,
                          formSettings: any,
                          formAdditionalData: any,
                        ) => {
                          let inputJSX = (
                            <Box className="tw-text-left tw-mb-4">
                              <Typography
                                variant="h6"
                                className="tw-mt-2"
                              >
                                <Icon
                                  icon="exclamation-triangle"
                                  className="tw-mr-2"
                                  sx={{ color: themeVariables.error_main }}
                                />
                                {rLIB('Changing a task date can majorly impact payroll. Proceed with caution.')}
                              </Typography>
                            </Box>
                          )
                          return inputJSX
                        },
                      },
                      timestamp_completed: {
                        key: 'timestamp_completed',
                        label: rLIB('Task Completion Date'),
                        input_type: 'timestamp_datetime',
                        required: true,
                        data_type: 'number',
                      },
                      notes: {
                        key: 'notes',
                        label: rLIB('Reason for changing the completion date'),
                        input_type: 'text_multiline',
                        required: true,
                        data_type: 'string',
                      },
                      reviewed: {
                        data_type: 'boolean',
                        input_type: 'boolean_checkbox',
                        key: 'reviewed',
                        label: rLIB("I've talked to company leadership and received approval for this task's completion date to be changed"),
                        required: true,
                      },
                    },
                    formOnChange: (
                      formAdditionalData: TsInterface_FormAdditionalData,
                      formData: TsInterface_FormData,
                      formInputs: TsInterface_FormInputs,
                      formSettings: TsInterface_FormSettings,
                    ) => {},
                    formSettings: {},
                    formSubmission: (
                      formSubmittedData: TsInterface_FormSubmittedData,
                      formAdditionalData: TsInterface_FormAdditionalData,
                      formHooks: TsInterface_FormHooksObject,
                    ) => {
                      return new Promise((resolve, reject) => {
                        // Instantiate Variables
                        let originTimestamp = returnDateFromUnknownDateFormat(rowData['timestamp_completed'])
                        let destinationTimestamp = returnDateFromUnknownDateFormat(formSubmittedData['timestamp_completed'])
                        let originWeekBoundingDates = getStartAndEndOfWeek(originTimestamp)
                        let destinationWeekBoundingDates = getStartAndEndOfWeek(destinationTimestamp)
                        let originDateKey = returnFormattedDateKey(originWeekBoundingDates.startOfWeek)
                        let destinationDateKey = returnFormattedDateKey(destinationWeekBoundingDates.startOfWeek)
                        let daysBetweenDates = getDaysBetweenDates(originTimestamp, destinationTimestamp)
                        // Update Objects
                        let taskUpdateObject: TsInterface_UnspecifiedObject = {
                          timestamp_completed: returnDateFromUnknownDateFormat(formSubmittedData['timestamp_completed']),
                        }
                        let taskKey = rowData.associated_task_key as string
                        if (taskKey == null) {
                          taskKey = rowData.key as string
                        }
                        let logUpdateObject: TsInterface_UnspecifiedObject = {
                          associated_editor_key: getProp(tableHooks['uc_RootData_ClientUser'], 'key', null),
                          associated_editor_name: getProp(tableHooks['uc_RootData_ClientUser'], 'name', null),
                          associated_project_id_number: getProp(rowData, 'associated_project_id_number', null),
                          associated_task_name: getProp(rowData, 'name', null),
                          new_timestamp_completed: returnDateFromUnknownDateFormat(formSubmittedData['timestamp_completed']),
                          notes: formSubmittedData['notes'],
                          previous_timestamp_completed: returnDateFromUnknownDateFormat(rowData['timestamp_completed']),
                          timestamp_created: new Date(),
                          log_type: 'task_completion_date_change',
                        }
                        // Original Timestamp
                        if (rowData != null && rowData['timestamp_completed'] != null && rowData['timestamp_completed_original'] == null) {
                          taskUpdateObject['timestamp_completed_original'] = returnDateFromUnknownDateFormat(rowData['timestamp_completed'])
                        }
                        // Load Root Payroll Documents
                        let promiseArray: TsType_UnknownPromise[] = []
                        let originPayrollDocument: TsInterface_UnspecifiedObject = {}
                        let destinationPayrollDocument: TsInterface_UnspecifiedObject = {}
                        getClientKey(formHooks['uc_RootData_ClientKey'], formHooks['uc_setRootData_ClientKey'])
                          .then((res_GCK) => {
                            promiseArray.push(
                              DatabaseGetDocument(DatabaseRef_FinalizedPayroll_Document(res_GCK.clientKey, originDateKey))
                                .then((res_DGD) => {
                                  originPayrollDocument = res_DGD.data
                                })
                                .catch((rej_DGD) => {
                                  console.error(rej_DGD)
                                }),
                            )
                            promiseArray.push(
                              DatabaseGetDocument(DatabaseRef_FinalizedPayroll_Document(res_GCK.clientKey, destinationDateKey))
                                .then((res_DGD) => {
                                  destinationPayrollDocument = res_DGD.data
                                })
                                .catch((rej_DGD) => {
                                  console.error(rej_DGD)
                                }),
                            )
                            // After data is loaded
                            Promise.all(promiseArray).finally(() => {
                              if (getProp(originPayrollDocument, 'locked', false) === true) {
                                let error = {
                                  message: rLIB('Failed to update completion timestamp'),
                                  details: rLIB('Payroll is locked for the week that this task is currently in'),
                                  code: 'ER-D-PT-FS-01',
                                }
                                formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: error })
                                reject({ success: false, error: error })
                              } else if (getProp(destinationPayrollDocument, 'locked', false) === true) {
                                let error = {
                                  message: rLIB('Failed to update completion timestamp'),
                                  details: rLIB('Payroll is locked for the week that this task is being moved to'),
                                  code: 'ER-D-PT-FS-02',
                                }
                                formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: error })
                                reject({ success: false, error: error })
                              } else if (Math.abs(daysBetweenDates) > 7) {
                                formHooks.uc_setUserInterface_ConfirmDialogDisplay({
                                  display: true,
                                  confirm: {
                                    color: 'success',
                                    header: <>{rLIB('Update task completion date')}</>,
                                    icon: (
                                      <Icon
                                        icon="pen-to-square"
                                        type="solid"
                                      />
                                    ),
                                    submit_text: rLIB('Confirm Update'),
                                    text: (
                                      <>
                                        {rLIB('Confirm that you are updating the completion date by')} {Math.abs(daysBetweenDates)} {rLIB('days')}
                                      </>
                                    ),
                                    submit_callback: () => {
                                      return new Promise((resolve2, reject2) => {
                                        let logKey = uuidv4() + '_' + new Date().getTime()
                                        logUpdateObject['key'] = logKey
                                        let updateArray: TsInterface_DatabaseBatchUpdatesArray = [
                                          {
                                            type: 'setMerge',
                                            ref: DatabaseRef_Task_Document(res_GCK.clientKey, taskKey as string),
                                            data: taskUpdateObject,
                                          },
                                          {
                                            type: 'setMerge',
                                            ref: DatabaseRef_PayrollLogs_Document(res_GCK.clientKey, logKey),
                                            data: logUpdateObject,
                                          },
                                        ]
                                        DatabaseBatchUpdate(updateArray)
                                          .then((res_DBU) => {
                                            resolve2(res_DBU)
                                            resolve(res_DBU)
                                          })
                                          .catch((rej_DBU) => {
                                            formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DBU.error })
                                            reject2(rej_DBU)
                                            reject(rej_DBU)
                                          })
                                      })
                                    },
                                  },
                                })
                              } else {
                                let logKey = uuidv4() + '_' + new Date().getTime()
                                logUpdateObject['key'] = logKey
                                let updateArray: TsInterface_DatabaseBatchUpdatesArray = [
                                  {
                                    type: 'setMerge',
                                    ref: DatabaseRef_Task_Document(res_GCK.clientKey, taskKey as string),
                                    data: taskUpdateObject,
                                  },
                                  {
                                    type: 'setMerge',
                                    ref: DatabaseRef_PayrollLogs_Document(res_GCK.clientKey, logKey),
                                    data: logUpdateObject,
                                  },
                                ]
                                DatabaseBatchUpdate(updateArray)
                                  .then((res_DBU) => {
                                    resolve(res_DBU)
                                  })
                                  .catch((rej_DBU) => {
                                    formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DBU.error })
                                    reject(rej_DBU)
                                  })
                              }
                            })
                          })
                          .catch((rej_GCK) => {
                            formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                            reject(rej_GCK)
                          })
                      })
                    },
                  },
                  dialog: {
                    formDialogHeaderColor: 'success',
                    formDialogHeaderText: <>{rLIB('Edit Task Completion Date')}</>,
                    formDialogIcon: (
                      <Icon
                        type="solid"
                        icon="pen-to-square"
                      />
                    ),
                  },
                },
              })
            }}
          />
        )
        if (rowData != null && rowData.timestamp_completed != null) {
          cellJSX = (
            <Box>
              {returnFormattedDate(rowData['timestamp_completed'], 'D MMM YY h:mm a')}
              {editIconJSX}
            </Box>
          )
        }
        return cellJSX
      },
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        let cellCSS = ''
        return cellCSS
      },
    },
  },
  TEMP_payroll_monday_date: {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rLIB('Payroll Date')
      },
      header_sort_by: 'timestamp_completed',
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = <></>
        if (rowData != null && rowData.timestamp_completed != null) {
          let weekBoundingDates = getStartAndEndOfWeek(returnDateFromUnknownDateFormat(rowData.timestamp_completed))
          cellJSX = <Box>{returnFormattedDate(weekBoundingDates.startOfWeek, 'D MMM YY')}</Box>
        }
        return cellJSX
      },
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        let cellCSS = ''
        return cellCSS
      },
    },
  },
  unit_pay_totals: {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rLIB('Unit Pay Total')
      },
      header_sort_by: null,
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let totalUnitPay = 0
        // @ts-expect-error - TODO: reason for error
        for (let loopUserKey in rowData.unit_pay_totals) {
          if (
            rowData != null &&
            rowData.unit_pay_totals != null &&
            // @ts-expect-error - TODO: reason for error
            rowData.unit_pay_totals[loopUserKey] != null &&
            // @ts-expect-error - TODO: reason for error
            rowData.unit_pay_totals[loopUserKey]['total_unit_pay'] != null
          ) {
            // @ts-expect-error - TODO: reason for error
            totalUnitPay += rowData.unit_pay_totals[loopUserKey]['total_unit_pay']
          }
        }
        let cellJSX = <Box>{formatCurrency(totalUnitPay)}</Box>
        return cellJSX
      },
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        let cellCSS = ''
        return cellCSS
      },
    },
  },
  unit_pay_breakdown: {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rLIB('Unit Pay Breakdown')
      },
      header_sort_by: null,
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let missingUserNameJSX = (
          <Box
            className="tw-inline-block"
            sx={{ color: themeVariables.error_main }}
          >
            {rLIB('Missing Name')}
          </Box>
        )
        let cellJSX = (
          <Box>
            <Box>
              {objectToArray(getProp(rowData, 'unit_pay_totals', {}))
                .sort(dynamicSort('name', null))
                .map((user: TsInterface_UnspecifiedObject, index: number) => (
                  <Box key={index}>
                    <Box className="tw-inline-block">
                      <Box className="tw-inline-block tw-opacity-50 tw-mr-1">{getProp(user, 'name', missingUserNameJSX)}:</Box>
                      <Tooltip
                        title={
                          <Box>
                            <Box>{getProp(user, 'name', missingUserNameJSX)}</Box>
                            <Divider />
                            <Box>
                              {rLIB('Task Units')}: {getProp(user, 'task_units', 0)}
                            </Box>
                            <Box>
                              {rLIB('Distance Adder')}: {getProp(user, 'distance_units', 0)}
                            </Box>
                            <Box>
                              {rLIB('Pitch Adder')}: {getProp(user, 'pitch_units', 0)}
                            </Box>
                            <Box>
                              {rLIB('Battery Adder')}: {getProp(user, 'battery_units', 0)}
                            </Box>
                            <Divider />
                            <Box>
                              {rLIB('Total Units')}: {getProp(user, 'total_units', 0)}
                            </Box>
                            <Box>
                              {rLIB('Rate')}: @ {formatCurrency(getProp(user, 'rate', 0))}
                            </Box>
                            <Divider />
                            <Box>
                              {rLIB('Total Unit Pay')}: {formatCurrency(getProp(user, 'total_unit_pay', 0))}
                            </Box>
                          </Box>
                        }
                        placement="bottom-start"
                      >
                        <Box className="tw-inline-block">{formatCurrency(getProp(user, 'total_unit_pay', 0))}</Box>
                      </Tooltip>
                    </Box>
                    <Box className="tw-inline-block tw-opacity-30 tw-ml-1">
                      {getProp(user, 'total_units', 0)} {rLIB('Units')} @ {formatCurrency(getProp(user, 'rate', 0))}
                    </Box>
                  </Box>
                ))}
            </Box>
          </Box>
        )
        return cellJSX
      },
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        let cellCSS = ''
        return cellCSS
      },
    },
  },
}

export const us_tableSettings_Adjustments: TsInterface_TableSettings = {
  paginated: false,
  show_header: true,
  size: 'small',
  sort_direction: 'asc',
  sort_property_default: 'associated_user_name',
  sortable: true,
  collapsible_columns: true,
}

const openPayrollAdjustmentsCustomDialog = (
  rowData: TsInterface_TableDataRow,
  tableAdditionalData: TsInterface_TableAdditionalData,
  tableHooks: TsInterface_TableHooks,
) => {
  let basePay = 0
  let unitPay = 0
  let unitPayBonus = 0
  let finalBonusPay = 0
  if (
    rowData != null &&
    rowData['associated_user_key'] != null &&
    tableAdditionalData != null &&
    tableAdditionalData['us_displayedWeekPayrollData'] != null &&
    tableAdditionalData['us_displayedWeekPayrollData']['employee_full_payroll_data'] != null &&
    tableAdditionalData['us_displayedWeekPayrollData']['employee_full_payroll_data'][rowData['associated_user_key'] as string] != null
  ) {
    let userPayrollData = tableAdditionalData['us_displayedWeekPayrollData']['employee_full_payroll_data'][rowData['associated_user_key'] as string]
    basePay = getProp(userPayrollData, 'total_pay', 0)
    unitPay = getProp(userPayrollData, 'unit_pay_all', 0)
    unitPayBonus = getProp(userPayrollData, 'unit_pay_bonus', 0)
    finalBonusPay = getProp(userPayrollData, 'final_bonus_pay', 0)
  }
  // Max Amount
  tableHooks.uc_setUserInterface_CustomDialogDisplay({
    display: true,
    dialog: {
      dialog_jsx: (
        <Box>
          <PayrollAdjustmentsCustomDialog
            payrollAdjustmentKey={getProp(rowData, 'key', null)}
            payrollMondayDateKey={getProp(tableAdditionalData, 'us_mondayDateKey', null)}
            payrollLocked={getProp(tableAdditionalData, 'payroll_week_locked', null)}
            employeePayrollData={{
              basePay: basePay,
              unitPay: unitPay,
              unitPayBonus: unitPayBonus,
              finalBonusPay: finalBonusPay,
            }}
          />
        </Box>
      ),
      settings: {
        max_width: 'md',
      },
    },
  })
}

export const tableColumns_Adjustments: TsInterface_TableColumns = {
  manage: TableCellManage({
    manage_payroll: {
      icon: <Icon icon="message-dollar" />,
      label: <>{rLIB('Manage Payroll')}</>,
      conditional_disable: {
        active: true,
        logic_type: 'comparison',
        source: 'tableAdditionalData',
        prop: 'locked',
        comparator: '==',
        value: true,
        conditions: [],
      },
      onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        openPayrollAdjustmentsCustomDialog(rowData, tableAdditionalData, tableHooks)
      },
    },
    delete: {
      icon: <Icon icon="trash" />,
      label: <>{rLIB('Delete Adjustment')}</>,
      conditional_disable: {
        active: true,
        logic_type: 'comparison',
        source: 'tableAdditionalData',
        prop: 'locked',
        comparator: '==',
        value: true,
        conditions: [],
      },
      onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        if (rowData.key != null) {
          tableHooks.uc_setUserInterface_PromptDialogDisplay({
            display: true,
            prompt: {
              color: 'error',
              confirm_text: rLIB('Confirm Deletion'),
              default_value: '',
              header: rLIB('Delete Adjustment'),
              icon: (
                <Icon
                  icon="siren-on"
                  type="solid"
                />
              ),
              input_label: rLIB('Type DANGER to proceed'),
              input_type: 'text',
              text: rLIB('Are you sure that you want to delete this adjustment?'),
              submit_callback: (promptValue: string) => {
                return new Promise((resolve, reject) => {
                  if (promptValue === 'DANGER') {
                    getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                      .then((res_GCK) => {
                        let updateObject = {
                          timestamp_deleted: new Date(),
                          status: 'deleted',
                          associated_deleter_name: getProp(tableHooks.uc_RootData_ClientUser, 'name', null),
                          associated_deleter_key: getProp(tableHooks.uc_RootData_ClientUser, 'uid,', null),
                        }
                        DatabaseSetMergeDocument(DatabaseRef_PayrollAdjustments_Document(res_GCK.clientKey, rowData.key as string), updateObject)
                          .then((res_UD) => {
                            resolve({ close_dialog: true })
                          })
                          .catch((rej_UD) => {
                            tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_UD.error })
                            reject(rej_UD)
                          })
                      })
                      .catch((rej_GCK) => {
                        tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                        reject(rej_GCK)
                      })
                  } else {
                    tableHooks.uc_setUserInterface_ErrorDialogDisplay({
                      display: true,
                      error: {
                        message: rLIB('Failed to unlock payroll'),
                        details: (
                          <>
                            {rLIB('You must enter DANGER in order to proceed.')} {rLIB('Otherwise click dismiss')}
                          </>
                        ),
                        code: 'ER-D-PT-DO-01',
                      },
                    })
                    resolve({ close_dialog: false })
                  }
                })
              },
            },
          })
        }
      },
    },
  }),

  TEMP_ErrorIcon: {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return <></>
      },
      header_sort_by: null,
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = <></>
        let errorOrWarningIcon = <></>
        if (
          rowData != null &&
          tableAdditionalData != null &&
          tableAdditionalData.us_mondayDateKey != null &&
          rowData['associated_user_key'] != null &&
          (rowData['associated_payroll_weeks'] == null || getProp(rowData['associated_payroll_weeks'], tableAdditionalData.us_mondayDateKey, false) != true) &&
          tableAdditionalData.us_displayedWeekPayrollData != null &&
          tableAdditionalData.us_displayedWeekPayrollData['employee_full_payroll_data'] != null &&
          tableAdditionalData.us_displayedWeekPayrollData['employee_full_payroll_data'][rowData['associated_user_key'] as string] != null
          // (tableAdditionalData.us_usersWithNegativeBonuses == null ||
          //   tableAdditionalData.us_usersWithNegativeBonuses[rowData.associated_user_key as string] !== true)
        ) {
          errorOrWarningIcon = (
            <Icon
              icon="exclamation-triangle"
              className="tw-text-warning_main"
              tooltip={rLIB('Adjustment not applied')}
              tooltipPlacement="right"
            />
          )
        } else if (
          rowData != null &&
          rowData.associated_user_key != null &&
          tableAdditionalData != null &&
          tableAdditionalData.us_usersWithNegativeBonuses != null &&
          tableAdditionalData.us_usersWithNegativeBonuses[rowData.associated_user_key as string] === true
        ) {
          errorOrWarningIcon = (
            <Icon
              icon="exclamation-triangle"
              className="tw-text-error_main"
              tooltip={rLIB('Negative Bonus')}
              tooltipPlacement="right"
            />
          )
        } else if (
          rowData != null &&
          tableAdditionalData != null &&
          rowData.associated_user_key != null &&
          tableAdditionalData.us_usersWithAdjustmentsButNoPayroll != null &&
          tableAdditionalData.us_usersWithAdjustmentsButNoPayroll[rowData.associated_user_key as string] === true &&
          tableAdditionalData.us_mondayDateKey != null &&
          rowData.associated_payroll_week_adjustment_amounts != null &&
          getProp(rowData.associated_payroll_week_adjustment_amounts, tableAdditionalData.us_mondayDateKey, null) != null &&
          getProp(rowData.associated_payroll_week_adjustment_amounts, tableAdditionalData.us_mondayDateKey, null) > 0
        ) {
          errorOrWarningIcon = (
            <Icon
              icon="exclamation-triangle"
              className="tw-text-error_main"
              tooltip={rLIB('Adjustments with no payroll')}
              tooltipPlacement="right"
            />
          )
        }
        cellJSX = <Box>{errorOrWarningIcon}</Box>
        return cellJSX
      },
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        let cellCSS = ''
        if (
          rowData != null &&
          tableAdditionalData != null &&
          tableAdditionalData.us_mondayDateKey != null &&
          rowData['associated_user_key'] != null &&
          (rowData['associated_payroll_weeks'] == null || getProp(rowData['associated_payroll_weeks'], tableAdditionalData.us_mondayDateKey, false) != true) &&
          tableAdditionalData.us_displayedWeekPayrollData != null &&
          tableAdditionalData.us_displayedWeekPayrollData['employee_full_payroll_data'] != null &&
          tableAdditionalData.us_displayedWeekPayrollData['employee_full_payroll_data'][rowData['associated_user_key'] as string] != null
          // (tableAdditionalData.us_usersWithNegativeBonuses == null ||
          //   tableAdditionalData.us_usersWithNegativeBonuses[rowData.associated_user_key as string] !== true)
        ) {
          // No background color
        } else if (
          rowData != null &&
          rowData.associated_user_key != null &&
          tableAdditionalData != null &&
          tableAdditionalData.us_usersWithNegativeBonuses != null &&
          tableAdditionalData.us_usersWithNegativeBonuses[rowData.associated_user_key as string] === true
        ) {
          cellCSS = 'tw-bg-warning_main tw-text-white'
        }
        if (
          rowData != null &&
          rowData.associated_user_key != null &&
          tableAdditionalData != null &&
          tableAdditionalData.us_usersWithAdjustmentsButNoPayroll != null &&
          tableAdditionalData.us_usersWithAdjustmentsButNoPayroll[rowData.associated_user_key as string] === true &&
          tableAdditionalData.us_mondayDateKey != null &&
          rowData.associated_payroll_week_adjustment_amounts != null &&
          getProp(rowData.associated_payroll_week_adjustment_amounts, tableAdditionalData.us_mondayDateKey, null) != null &&
          getProp(rowData.associated_payroll_week_adjustment_amounts, tableAdditionalData.us_mondayDateKey, null) > 0
        ) {
          cellCSS = 'tw-bg-warning_main tw-text-white'
        }
        return cellCSS
      },
    },
  },
  associated_user_name: TableCellBasic('associated_user_name', rLIB('Employee'), 'associated_user_name'),
  type: {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rLIB('Adjustment Type')
      },
      header_sort_by: null,
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = <></>
        if (rowData['type'] === 'payroll_bonus_decrease') {
          cellJSX = (
            <Chip
              label={rLIB('Reduction')}
              color="error"
              variant="filled"
            />
          )
        } else if (rowData['type'] === 'payroll_bonus_increase') {
          cellJSX = (
            <Chip
              label={rLIB('Addition')}
              color="success"
              variant="filled"
            />
          )
        } else {
          cellJSX = (
            <Chip
              label={rowData['type']}
              color="warning"
              variant="filled"
            />
          )
        }
        return cellJSX
      },
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        let cellCSS = ''
        return cellCSS
      },
    },
  },
  total_adjustment_amount: {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rLIB('Total Adjustment Amount')
      },
      header_sort_by: 'total_adjustment_amount',
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = <></>
        if (rowData != null && rowData.total_adjustment_amount != null) {
          cellJSX = <>{formatCurrency(getProp(rowData, 'total_adjustment_amount', 0))}</>
        } else {
          cellJSX = <Box className="tw-opacity-20">{rLIB('Missing')}</Box>
        }
        return cellJSX
      },
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        let cellCSS = ''
        return cellCSS
      },
    },
  },
  TEMP_associated_payroll_week_adjustment_amounts: {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rLIB('Amount applied this week')
      },
      header_sort_by: null,
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = <></>
        let userHasPayrollData = true
        if (
          rowData != null &&
          rowData.associated_user_key != null &&
          tableAdditionalData != null &&
          tableAdditionalData.us_displayedWeekPayrollData != null &&
          tableAdditionalData.us_displayedWeekPayrollData.employee_full_payroll_data != null &&
          tableAdditionalData.us_displayedWeekPayrollData.employee_full_payroll_data[rowData.associated_user_key as string] == null
        ) {
          userHasPayrollData = false
        }
        if (
          tableAdditionalData != null &&
          tableAdditionalData.us_mondayDateKey != null &&
          rowData != null &&
          rowData.associated_payroll_week_adjustment_amounts != null &&
          getProp(rowData.associated_payroll_week_adjustment_amounts, tableAdditionalData.us_mondayDateKey, null) != null &&
          getProp(rowData.associated_payroll_week_adjustment_amounts, tableAdditionalData.us_mondayDateKey, null) > 0
        ) {
          cellJSX = (
            <Box
              className="tw-cursor-pointer"
              onClick={() => {
                openPayrollAdjustmentsCustomDialog(rowData, tableAdditionalData, tableHooks)
              }}
            >
              <>{formatCurrency(getProp(rowData.associated_payroll_week_adjustment_amounts, tableAdditionalData.us_mondayDateKey, 0))}</>
            </Box>
          )
        } else {
          if (userHasPayrollData === true) {
            cellJSX = (
              <Box
                className="tw-cursor-pointer"
                onClick={() => {
                  openPayrollAdjustmentsCustomDialog(rowData, tableAdditionalData, tableHooks)
                }}
              >
                {rLIB('Not applied this week')}
              </Box>
            )
          } else {
            cellJSX = (
              <Box
                className="tw-cursor-pointer"
                onClick={() => {
                  openPayrollAdjustmentsCustomDialog(rowData, tableAdditionalData, tableHooks)
                }}
              >
                {rLIB('Employee did not work this week')}
              </Box>
            )
          }
        }
        return cellJSX
      },
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        let cellCSS = ''
        let userHasPayrollData = true
        if (
          rowData != null &&
          rowData.associated_user_key != null &&
          tableAdditionalData != null &&
          tableAdditionalData.us_displayedWeekPayrollData != null &&
          tableAdditionalData.us_displayedWeekPayrollData.employee_full_payroll_data != null &&
          tableAdditionalData.us_displayedWeekPayrollData.employee_full_payroll_data[rowData.associated_user_key as string] == null
        ) {
          userHasPayrollData = false
        }
        if (
          tableAdditionalData != null &&
          tableAdditionalData.us_mondayDateKey != null &&
          rowData != null &&
          rowData.associated_payroll_week_adjustment_amounts != null &&
          getProp(rowData.associated_payroll_week_adjustment_amounts, tableAdditionalData.us_mondayDateKey, null) != null &&
          getProp(rowData.associated_payroll_week_adjustment_amounts, tableAdditionalData.us_mondayDateKey, null) > 0
        ) {
          if (
            tableAdditionalData != null &&
            tableAdditionalData.us_usersWithNegativeBonuses != null &&
            tableAdditionalData.us_usersWithNegativeBonuses[rowData.associated_user_key as string] === true
          ) {
            cellCSS = 'tw-bg-error_main tw-text-white'
          } else if (userHasPayrollData === false) {
            cellCSS = 'tw-bg-error_main tw-text-white'
          }
          // Nothing
        } else {
          if (userHasPayrollData === true) {
            cellCSS = 'tw-bg-warning_main tw-text-white'
          }
        }
        return cellCSS
      },
    },
  },
  remaining_adjustment_amount: {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rLIB('Remaining Adjustment Amount')
      },
      header_sort_by: 'remaining_adjustment_amount',
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = <></>
        if (rowData != null && rowData.remaining_adjustment_amount != null) {
          cellJSX = <>{formatCurrency(getProp(rowData, 'remaining_adjustment_amount', 0))}</>
        } else {
          cellJSX = <Box className="tw-opacity-20">{rLIB('Missing')}</Box>
        }
        return cellJSX
      },
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        let cellCSS = ''
        if (rowData != null && rowData.remaining_adjustment_amount != null && rowData.remaining_adjustment_amount !== 0) {
          // cellCSS = 'tw-bg-warning_main tw-text-white'
        }
        return cellCSS
      },
    },
  },
  notes: TableCellBasic('notes', rLIB('Notes'), null),
  associated_creator_name: TableCellBasic('associated_creator_name', rLIB('Adjustment Created By'), 'associated_creator_name'),
  timestamp_apply_after: TableCellTimestamp('timestamp_apply_after', rLIB('Applied After'), 'timestamp_apply_after', 'D MMM YYYY h:mm a', false),
  key: TableCellBasic('key', rLIB('Notes'), null),
  // timestamp_created: TableCellTimestamp('timestamp_created', rLIB('Date'), 'timestamp_created', 'D MMM YYYY h:mm a', false),
}

export const tableColumns_PayrollSummary: TsInterface_TableColumns = {
  name: {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        let headerJSX = (
          <Box>
            <Box>{rLIB('Employee')}</Box>
            <Box>&nbsp;</Box>
          </Box>
        )
        return headerJSX
      },
      header_sort_by: 'name',
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = <></>
        if (rowData != null && rowData.name != null) {
          cellJSX = <>{getProp(rowData, 'name', '')}</>
        } else {
          cellJSX = <Box className="tw-opacity-20">{rowData.key}</Box>
        }
        return cellJSX
      },
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        let cellCSS = ''
        return cellCSS
      },
    },
  },
  admin_hours: {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        let total = 0
        if (
          tableAdditionalData != null &&
          tableAdditionalData.us_employeePayrollDataTotals != null &&
          tableAdditionalData.us_employeePayrollDataTotals['admin_hours'] != null
        ) {
          total = tableAdditionalData.us_employeePayrollDataTotals['admin_hours']
        }
        let headerJSX = (
          <Box>
            <Box>{rLIB('Admin Hrs')}</Box>
            <Box className="tw-opacity-50 tw-italic">{total.toFixed(1)}</Box>
          </Box>
        )
        return headerJSX
      },
      header_sort_by: 'admin_hours',
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return 'tw-bg-warning_main tw-text-white'
      },
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        const returnInnerCellJSX = (
          rowData: TsInterface_TableDataRow,
          tableAdditionalData: TsInterface_TableAdditionalData,
          tableHooks: TsInterface_TableHooks,
        ) => {
          let cellJSX = <></>
          if (rowData != null && rowData.admin_hours != null && rowData.admin_hours !== 0) {
            // Breakdown
            let hourBreakdown: TsInterface_UnspecifiedObject = {}
            let groupCodeKey = 'OFFICE'
            if (
              rowData != null &&
              rowData.grouped_codes != null &&
              // @ts-expect-error - TODO: reason for error
              rowData.grouped_codes[groupCodeKey] != null
            ) {
              // @ts-expect-error - TODO: reason for error
              for (let loopKey in rowData.grouped_codes[groupCodeKey]) {
                hourBreakdown[loopKey] = {
                  category: loopKey,
                  // @ts-expect-error - TODO: reason for error
                  hours: rowData.grouped_codes[groupCodeKey][loopKey].toFixed(1),
                }
              }
            }
            // JSX
            cellJSX = (
              <Box className="tw-inline-block">
                <Tooltip
                  title={
                    <Box>
                      {objectToArray(hourBreakdown)
                        .sort(dynamicSort('hours', 'desc'))
                        .map((hourBreakdown: TsInterface_UnspecifiedObject, index: number) => (
                          <Box
                            key={index}
                            className="tw-mb-1"
                          >
                            <Box sx={{ fontSize: '14px' }}>
                              {hourBreakdown.category}:
                              <Box sx={{ color: themeVariables.primary_main, display: 'inline-block', marginLeft: '4px' }}>
                                {hourBreakdown.hours} {rLIB('hrs')}
                              </Box>
                            </Box>
                            <Divider />
                          </Box>
                        ))}
                    </Box>
                  }
                  placement="bottom-start"
                >
                  <Box className="tw-inline-block">{(rowData.admin_hours as number).toFixed(1)}</Box>
                </Tooltip>
              </Box>
            )
          } else {
            cellJSX = <Box className="tw-opacity-30 tw-inline-block">0</Box>
          }
          return cellJSX
        }
        return rJSX_SummaryTableCell('admin_hours', rowData, tableAdditionalData, tableHooks, returnInnerCellJSX)
      },
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        let cellCSS = ''
        if (getProp(tableAdditionalData, 'payroll_week_data_locked', null) === false) {
          // Nothing
        } else if (getProp(tableAdditionalData, 'payroll_week_data_locked', null) === true) {
          cellCSS = returnDiscrepancyHighlightClassName('admin_hours', tableAdditionalData, rowData)
        }
        return cellCSS
      },
    },
  },
  field_hours: {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        let total = 0
        if (
          tableAdditionalData != null &&
          tableAdditionalData.us_employeePayrollDataTotals != null &&
          tableAdditionalData.us_employeePayrollDataTotals['field_hours'] != null
        ) {
          total = tableAdditionalData.us_employeePayrollDataTotals['field_hours']
        }
        let headerJSX = (
          <Box>
            <Box>{rLIB('Field Hrs')}</Box>
            <Box className="tw-opacity-50 tw-italic">{total.toFixed(1)}</Box>
          </Box>
        )
        return headerJSX
      },
      header_sort_by: 'field_hours',
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return 'tw-bg-warning_main tw-text-white'
      },
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        const returnInnerCellJSX = (
          rowData: TsInterface_TableDataRow,
          tableAdditionalData: TsInterface_TableAdditionalData,
          tableHooks: TsInterface_TableHooks,
        ) => {
          let cellJSX = <></>
          if (rowData != null && rowData.field_hours != null && rowData.field_hours !== 0) {
            // Breakdown
            let hourBreakdown: TsInterface_UnspecifiedObject = {}
            let groupCodeKey = 'FIELD'
            if (
              rowData != null &&
              rowData.grouped_codes != null &&
              // @ts-expect-error - TODO: reason for error
              rowData.grouped_codes[groupCodeKey] != null
            ) {
              // @ts-expect-error - TODO: reason for error
              for (let loopKey in rowData.grouped_codes[groupCodeKey]) {
                hourBreakdown[loopKey] = {
                  category: loopKey,
                  // @ts-expect-error - TODO: reason for error
                  hours: rowData.grouped_codes[groupCodeKey][loopKey].toFixed(1),
                }
              }
            }
            cellJSX = (
              <Box className="tw-inline-block">
                <Tooltip
                  title={
                    <Box>
                      {objectToArray(hourBreakdown)
                        .sort(dynamicSort('hours', 'desc'))
                        .map((hourBreakdown: TsInterface_UnspecifiedObject, index: number) => (
                          <Box
                            key={index}
                            className="tw-mb-1"
                          >
                            <Box sx={{ fontSize: '14px' }}>
                              {hourBreakdown.category}:
                              <Box sx={{ color: themeVariables.primary_main, display: 'inline-block', marginLeft: '4px' }}>
                                {hourBreakdown.hours} {rLIB('hrs')}
                              </Box>
                            </Box>
                            <Divider />
                          </Box>
                        ))}
                    </Box>
                  }
                  placement="bottom-start"
                >
                  <Box className="tw-inline-block">{(rowData.field_hours as number).toFixed(1)}</Box>
                </Tooltip>
              </Box>
            )
          } else {
            cellJSX = <Box className="tw-opacity-30 tw-inline-block">0</Box>
          }
          return cellJSX
        }
        return rJSX_SummaryTableCell('field_hours', rowData, tableAdditionalData, tableHooks, returnInnerCellJSX)
      },
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        let cellCSS = ''
        if (getProp(tableAdditionalData, 'payroll_week_data_locked', null) === false) {
          // Nothing
        } else if (getProp(tableAdditionalData, 'payroll_week_data_locked', null) === true) {
          cellCSS = returnDiscrepancyHighlightClassName('field_hours', tableAdditionalData, rowData)
        }
        return cellCSS
      },
    },
  },
  non_working_hours: {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        let total = 0
        if (
          tableAdditionalData != null &&
          tableAdditionalData.us_employeePayrollDataTotals != null &&
          tableAdditionalData.us_employeePayrollDataTotals['non_working_hours'] != null
        ) {
          total = tableAdditionalData.us_employeePayrollDataTotals['non_working_hours']
        }
        let headerJSX = (
          <Box>
            <Box>{rLIB('NWT Hrs')}</Box>
            <Box className="tw-opacity-50 tw-italic">{total.toFixed(1)}</Box>
          </Box>
        )
        return headerJSX
      },
      header_sort_by: 'non_working_hours',
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return 'tw-bg-warning_main tw-text-white'
      },
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        const returnInnerCellJSX = (
          rowData: TsInterface_TableDataRow,
          tableAdditionalData: TsInterface_TableAdditionalData,
          tableHooks: TsInterface_TableHooks,
        ) => {
          let cellJSX = <></>
          if (rowData != null && rowData.non_working_hours != null && rowData.non_working_hours !== 0) {
            // Breakdown
            let hourBreakdown: TsInterface_UnspecifiedObject = {}
            let groupCodeKey = 'OFF'
            if (
              rowData != null &&
              rowData.grouped_codes != null &&
              // @ts-expect-error - TODO: reason for error
              rowData.grouped_codes[groupCodeKey] != null
            ) {
              // @ts-expect-error - TODO: reason for error
              for (let loopKey in rowData.grouped_codes[groupCodeKey]) {
                hourBreakdown[loopKey] = {
                  category: loopKey,
                  // @ts-expect-error - TODO: reason for error
                  hours: rowData.grouped_codes[groupCodeKey][loopKey].toFixed(1),
                }
              }
            }
            cellJSX = (
              <Box className="tw-inline-block">
                <Tooltip
                  title={
                    <Box>
                      {objectToArray(hourBreakdown)
                        .sort(dynamicSort('hours', 'desc'))
                        .map((hourBreakdown: TsInterface_UnspecifiedObject, index: number) => (
                          <Box
                            key={index}
                            className="tw-mb-1"
                          >
                            <Box sx={{ fontSize: '14px' }}>
                              {hourBreakdown.category}:
                              <Box sx={{ color: themeVariables.primary_main, display: 'inline-block', marginLeft: '4px' }}>
                                {hourBreakdown.hours} {rLIB('hrs')}
                              </Box>
                            </Box>
                            <Divider />
                          </Box>
                        ))}
                    </Box>
                  }
                  placement="bottom-start"
                >
                  <Box className="tw-inline-block">{(rowData.non_working_hours as number).toFixed(1)}</Box>
                </Tooltip>
              </Box>
            )
          } else {
            cellJSX = <Box className="tw-opacity-30 tw-inline-block">0</Box>
          }
          return cellJSX
        }
        return rJSX_SummaryTableCell('non_working_hours', rowData, tableAdditionalData, tableHooks, returnInnerCellJSX)
      },
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        let cellCSS = ''
        if (getProp(tableAdditionalData, 'payroll_week_data_locked', null) === false) {
          // Nothing
        } else if (getProp(tableAdditionalData, 'payroll_week_data_locked', null) === true) {
          cellCSS = returnDiscrepancyHighlightClassName('non_working_hours', tableAdditionalData, rowData)
        }
        return cellCSS
      },
    },
  },
  work_reg_hours: {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        let total = 0
        if (
          tableAdditionalData != null &&
          tableAdditionalData.us_employeePayrollDataTotals != null &&
          tableAdditionalData.us_employeePayrollDataTotals['work_reg_hours'] != null
        ) {
          total = tableAdditionalData.us_employeePayrollDataTotals['work_reg_hours']
        }
        let headerJSX = (
          <Box>
            <Box>{rLIB('Reg Hrs')}</Box>
            <Box className="tw-opacity-50 tw-italic">{total.toFixed(1)}</Box>
          </Box>
        )
        return headerJSX
      },
      header_sort_by: 'work_reg_hours',
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return 'tw-bg-warning_main tw-text-white'
      },
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        const returnInnerCellJSX = (
          rowData: TsInterface_TableDataRow,
          tableAdditionalData: TsInterface_TableAdditionalData,
          tableHooks: TsInterface_TableHooks,
        ) => {
          let cellJSX = <></>
          if (rowData != null && rowData.work_reg_hours != null) {
            cellJSX = (
              <Box className="tw-inline-block">
                <Tooltip
                  title={
                    <Box sx={{ fontSize: '14px' }}>
                      <Box>
                        {rLIB('Admin Hrs')}:
                        <Box sx={{ color: themeVariables.primary_main, display: 'inline-block', marginLeft: '4px' }}>
                          {getProp(rowData, 'admin_reg_hours', 0).toFixed(1)} {rLIB('hrs')}
                        </Box>
                      </Box>
                      <Box>
                        {rLIB('Field Hrs')}:
                        <Box sx={{ color: themeVariables.primary_main, display: 'inline-block', marginLeft: '4px' }}>
                          {getProp(rowData, 'field_reg_hours', 0).toFixed(1)} {rLIB('hrs')}
                        </Box>
                      </Box>
                    </Box>
                  }
                  placement="bottom-start"
                >
                  <Box className="tw-inline-block">{getProp(rowData, 'work_reg_hours', 0).toFixed(1)}</Box>
                </Tooltip>
              </Box>
            )
          } else {
            cellJSX = <Box className="tw-opacity-30 tw-inline-block">0.0</Box>
          }
          return cellJSX
        }
        return rJSX_SummaryTableCell('work_reg_hours', rowData, tableAdditionalData, tableHooks, returnInnerCellJSX)
      },
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        let cellCSS = ''
        if (getProp(tableAdditionalData, 'payroll_week_data_locked', null) === false) {
          // Nothing
        } else if (getProp(tableAdditionalData, 'payroll_week_data_locked', null) === true) {
          cellCSS = returnDiscrepancyHighlightClassName('work_reg_hours', tableAdditionalData, rowData)
        }
        return cellCSS
      },
    },
  },
  work_ovt_hours: {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        let total = 0
        if (
          tableAdditionalData != null &&
          tableAdditionalData.us_employeePayrollDataTotals != null &&
          tableAdditionalData.us_employeePayrollDataTotals['work_ovt_hours'] != null
        ) {
          total = tableAdditionalData.us_employeePayrollDataTotals['work_ovt_hours']
        }
        let headerJSX = (
          <Box>
            <Box>{rLIB('Ovt Hrs')}</Box>
            <Box className="tw-opacity-50 tw-italic">{total.toFixed(1)}</Box>
          </Box>
        )
        return headerJSX
      },
      header_sort_by: 'work_ovt_hours',
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return 'tw-bg-warning_main tw-text-white'
      },
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        const returnInnerCellJSX = (
          rowData: TsInterface_TableDataRow,
          tableAdditionalData: TsInterface_TableAdditionalData,
          tableHooks: TsInterface_TableHooks,
        ) => {
          let cellJSX = <></>
          if (rowData != null && rowData.work_ovt_hours != null && rowData.work_ovt_hours !== 0) {
            cellJSX = (
              <Box className="tw-inline-block">
                <Tooltip
                  title={
                    <Box sx={{ fontSize: '14px' }}>
                      <Box>
                        {rLIB('Admin Hrs')}:
                        <Box sx={{ color: themeVariables.primary_main, display: 'inline-block', marginLeft: '4px' }}>
                          {getProp(rowData, 'admin_ovt_hours', 0).toFixed(1)} {rLIB('hrs')}
                        </Box>
                      </Box>
                      <Box>
                        {rLIB('Field Hrs')}:
                        <Box sx={{ color: themeVariables.primary_main, display: 'inline-block', marginLeft: '4px' }}>
                          {getProp(rowData, 'field_ovt_hours', 0).toFixed(1)} {rLIB('hrs')}
                        </Box>
                      </Box>
                    </Box>
                  }
                  placement="bottom-start"
                >
                  <Box className="tw-inline-block">{getProp(rowData, 'work_ovt_hours', 0).toFixed(1)}</Box>
                </Tooltip>
              </Box>
            )
          } else {
            cellJSX = <Box className="tw-opacity-30 tw-inline-block">0.0</Box>
          }
          return cellJSX
        }
        return rJSX_SummaryTableCell('work_ovt_hours', rowData, tableAdditionalData, tableHooks, returnInnerCellJSX)
      },
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        let cellCSS = ''
        if (getProp(tableAdditionalData, 'payroll_week_data_locked', null) === false) {
          if (rowData != null && rowData.work_ovt_hours != null && rowData.work_ovt_hours !== 0) {
            cellCSS = 'tw-bg-warning_main tw-text-white'
          }
        } else if (getProp(tableAdditionalData, 'payroll_week_data_locked', null) === true) {
          cellCSS = returnDiscrepancyHighlightClassName('work_ovt_hours', tableAdditionalData, rowData)
        }
        return cellCSS
      },
    },
  },
  all_hours: {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        let total = 0
        if (
          tableAdditionalData != null &&
          tableAdditionalData.us_employeePayrollDataTotals != null &&
          tableAdditionalData.us_employeePayrollDataTotals['all_hours'] != null
        ) {
          total = tableAdditionalData.us_employeePayrollDataTotals['all_hours']
        }
        let headerJSX = (
          <Box>
            <Box>{rLIB('All Hrs')}</Box>
            <Box className="tw-opacity-50 tw-italic">{total.toFixed(1)}</Box>
          </Box>
        )
        return headerJSX
      },
      header_sort_by: 'all_hours',
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return 'tw-bg-warning_dark tw-text-white'
      },
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        const returnInnerCellJSX = (
          rowData: TsInterface_TableDataRow,
          tableAdditionalData: TsInterface_TableAdditionalData,
          tableHooks: TsInterface_TableHooks,
        ) => {
          let cellJSX = <></>
          // Create Hours Breakdown Array
          let hoursBreakdownArray: TsInterface_UnspecifiedObject[] = []
          for (let codeKey in getProp(rowData, 'raw_codes', {})) {
            let codeValue = getProp(rowData, 'raw_codes', {})[codeKey]
            hoursBreakdownArray.push({
              key: codeKey,
              value: codeValue,
            })
          }
          if (rowData != null && rowData.all_hours != null) {
            cellJSX = (
              <Box className="tw-inline-block">
                <Tooltip
                  title={
                    <Box>
                      {hoursBreakdownArray.sort(dynamicSort('key', 'asc')).map((hourBreakdown: TsInterface_UnspecifiedObject, index: number) => (
                        <Box
                          key={hourBreakdown.key}
                          className="tw-mb-1"
                        >
                          <Box sx={{ fontSize: '14px' }}>
                            {hourBreakdown.key}:
                            <Box sx={{ color: themeVariables.primary_main, display: 'inline-block', marginLeft: '4px' }}>
                              {hourBreakdown.value.toFixed(1)} {rLIB('hrs')}
                            </Box>
                          </Box>
                          <Divider />
                        </Box>
                      ))}
                    </Box>
                  }
                  placement="bottom-start"
                >
                  <Box className="tw-inline-block">{getProp(rowData, 'all_hours', 0).toFixed(1)}</Box>
                </Tooltip>
              </Box>
            )
          } else {
            cellJSX = <Box className="tw-opacity-30 tw-inline-block">0.0</Box>
          }
          return cellJSX
        }
        return rJSX_SummaryTableCell('all_hours', rowData, tableAdditionalData, tableHooks, returnInnerCellJSX)
      },
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        let cellCSS = 'tw-bg-background_json tw-text-white'
        if (getProp(tableAdditionalData, 'payroll_week_data_locked', null) === false) {
          if (rowData != null && rowData.all_hours != null && getProp(rowData, 'all_hours', 0) > 40) {
            cellCSS = 'tw-bg-warning_dark tw-text-white'
          }
        } else if (getProp(tableAdditionalData, 'payroll_week_data_locked', null) === true) {
          cellCSS = returnDiscrepancyHighlightClassName('all_hours', tableAdditionalData, rowData)
        }
        return cellCSS
      },
    },
  },
  employee_in_combine: {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        let headerJSX = (
          <Box>
            <Box>{rLIB('In Combine')}</Box>
            <Box>&nbsp;</Box>
          </Box>
        )
        return headerJSX
      },
      header_sort_by: 'employee_in_combine',
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return 'tw-bg-info_light tw-text-white'
      },
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        const returnInnerCellJSX = (
          rowData: TsInterface_TableDataRow,
          tableAdditionalData: TsInterface_TableAdditionalData,
          tableHooks: TsInterface_TableHooks,
        ) => {
          let cellJSX = <></>
          if (rowData != null && rowData.employee_in_combine === true && getProp(rowData, 'combine_status_timestamp_key', null) != null) {
            let dateNumber = getProp(rowData, 'combine_status_timestamp_key', null)
            let date = parseDateNumber(dateNumber)
            cellJSX = <Box className="tw-inline-block">{returnFormattedDate(date, 'D MMM YY')}</Box>
          } else if (rowData != null && rowData.employee_in_combine === true) {
            cellJSX = <Box className="tw-inline-block">{rLIB('In Combine')}</Box>
          }
          return cellJSX
        }
        return rJSX_SummaryTableCell('employee_in_combine', rowData, tableAdditionalData, tableHooks, returnInnerCellJSX)
      },
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        let cellCSS = ''
        if (getProp(tableAdditionalData, 'payroll_week_data_locked', null) === false) {
          if (rowData != null && rowData.employee_in_combine === true) {
            cellCSS = 'tw-bg-error_light tw-text-white'
          }
        } else if (getProp(tableAdditionalData, 'payroll_week_data_locked', null) === true) {
          cellCSS = returnDiscrepancyHighlightClassName('employee_in_combine', tableAdditionalData, rowData)
        }
        return cellCSS
      },
    },
  },
  base_wage: {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        let headerJSX = (
          <Box>
            <Box>{rLIB('Base Wage')}</Box>
            <Box>&nbsp;</Box>
          </Box>
        )
        return headerJSX
      },
      header_sort_by: 'base_wage',
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return 'tw-bg-info_light tw-text-white'
      },
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        const returnInnerCellJSX = (
          rowData: TsInterface_TableDataRow,
          tableAdditionalData: TsInterface_TableAdditionalData,
          tableHooks: TsInterface_TableHooks,
        ) => {
          let cellJSX = <Box className="tw-opacity-30 tw-inline-block">{rLIB('Missing')}</Box>
          if (rowData != null && rowData.base_wage != null) {
            cellJSX = <Box className="tw-inline-block">{formatCurrency(rowData.base_wage as number)}</Box>
          } else {
            cellJSX = <Box className="tw-opacity-30 tw-inline-block">{rLIB('Missing')}</Box>
          }
          return cellJSX
        }
        return rJSX_SummaryTableCell('base_wage', rowData, tableAdditionalData, tableHooks, returnInnerCellJSX)
      },
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        let cellCSS = ''
        if (getProp(tableAdditionalData, 'payroll_week_data_locked', null) === false) {
          if (rowData == null || rowData.base_wage == null) {
            cellCSS = 'tw-bg-error_main tw-text-white'
          }
        } else if (getProp(tableAdditionalData, 'payroll_week_data_locked', null) === true) {
          cellCSS = returnDiscrepancyHighlightClassName('base_wage', tableAdditionalData, rowData)
        }
        return cellCSS
      },
    },
  },
  minimum_wage: {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        let headerJSX = (
          <Box>
            <Box>{rLIB('Min Wage')}</Box>
            <Box>&nbsp;</Box>
          </Box>
        )
        return headerJSX
      },
      header_sort_by: 'minimum_wage',
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return 'tw-bg-info_light tw-text-white'
      },
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        const returnInnerCellJSX = (
          rowData: TsInterface_TableDataRow,
          tableAdditionalData: TsInterface_TableAdditionalData,
          tableHooks: TsInterface_TableHooks,
        ) => {
          let cellJSX = <Box className="tw-opacity-30 tw-inline-block">{rLIB('Missing')}</Box>
          if (rowData != null && rowData.minimum_wage != null) {
            let usingDefaultIconJSX = <></>
            if (getProp(rowData, 'using_minimum_wage_default', null) === true && getProp(tableAdditionalData, 'payroll_week_data_locked', null) === false) {
              usingDefaultIconJSX = (
                <Icon
                  icon="circle-info"
                  type="regular"
                  sx={{ color: themeVariables.warning_main, fontSize: '16px' }}
                  className="tw-ml-2"
                  tooltip={rLIB('Using Default Minimum Wage')}
                />
              )
            }
            cellJSX = (
              <Box className="tw-inline-block">
                {formatCurrency(rowData.minimum_wage as number)}
                {usingDefaultIconJSX}
              </Box>
            )
          } else {
            cellJSX = <Box className="tw-opacity-30 tw-inline-block">{rLIB('Missing')}</Box>
          }
          return cellJSX
        }
        return rJSX_SummaryTableCell('minimum_wage', rowData, tableAdditionalData, tableHooks, returnInnerCellJSX)
      },
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        let cellCSS = ''
        if (getProp(tableAdditionalData, 'payroll_week_data_locked', null) === false) {
          if (rowData != null && rowData.minimum_wage == null) {
            cellCSS = 'tw-bg-error_main tw-text-white'
          }
        } else if (getProp(tableAdditionalData, 'payroll_week_data_locked', null) === true) {
          cellCSS = returnDiscrepancyHighlightClassName('minimum_wage', tableAdditionalData, rowData)
        }
        return cellCSS
      },
    },
  },
  effective_wage: {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        let headerJSX = (
          <Box>
            <Box>{rLIB('Effective Wage')}</Box>
            <Box>&nbsp;</Box>
          </Box>
        )
        return headerJSX
      },
      header_sort_by: 'effective_wage',
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return 'tw-bg-info_light tw-text-white'
      },
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        const returnInnerCellJSX = (
          rowData: TsInterface_TableDataRow,
          tableAdditionalData: TsInterface_TableAdditionalData,
          tableHooks: TsInterface_TableHooks,
        ) => {
          let cellJSX = <Box className="tw-opacity-30 tw-inline-block">{rLIB('Missing')}</Box>
          if (rowData != null && rowData.effective_wage != null) {
            cellJSX = <Box className="tw-inline-block">{formatCurrency(rowData.effective_wage as number)}</Box>
          } else {
            cellJSX = <Box className="tw-opacity-30 tw-inline-block">{rLIB('Missing')}</Box>
          }
          return cellJSX
        }
        return rJSX_SummaryTableCell('effective_wage', rowData, tableAdditionalData, tableHooks, returnInnerCellJSX)
      },
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        let cellCSS = ''
        if (getProp(tableAdditionalData, 'payroll_week_data_locked', null) === false) {
          if (rowData == null || rowData.effective_wage == null) {
            cellCSS = 'tw-bg-error_main tw-text-white'
          }
        } else if (getProp(tableAdditionalData, 'payroll_week_data_locked', null) === true) {
          cellCSS = returnDiscrepancyHighlightClassName('effective_wage', tableAdditionalData, rowData)
        }
        return cellCSS
      },
    },
  },
  admin_reg_pay: {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        let total = 0
        if (
          tableAdditionalData != null &&
          tableAdditionalData.us_employeePayrollDataTotals != null &&
          tableAdditionalData.us_employeePayrollDataTotals['admin_reg_pay'] != null
        ) {
          total = tableAdditionalData.us_employeePayrollDataTotals['admin_reg_pay']
        }
        let headerJSX = (
          <Box>
            <Box>{rLIB('Admin Reg Pay')}</Box>
            <Box className="tw-opacity-50 tw-italic">{formatCurrency(total)}</Box>
          </Box>
        )
        return headerJSX
      },
      header_sort_by: 'admin_reg_pay',
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return 'tw-bg-info_light tw-text-white'
      },
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        const returnInnerCellJSX = (
          rowData: TsInterface_TableDataRow,
          tableAdditionalData: TsInterface_TableAdditionalData,
          tableHooks: TsInterface_TableHooks,
        ) => {
          let cellJSX = <></>
          if (rowData != null && rowData.admin_reg_pay != null && rowData.admin_reg_pay !== 0) {
            cellJSX = (
              <Box className="tw-inline-block">
                <Tooltip
                  title={
                    <Box sx={{ fontSize: '14px' }}>
                      <Box>
                        <Box sx={{ color: themeVariables.white, display: 'inline-block', marginLeft: '4px' }}>
                          {formatCurrency(getProp(rowData, '_admin_reg_pay', 0))} = {getProp(rowData, 'admin_reg_hours', 0).toFixed(1)} {rLIB('hrs')} @{' '}
                          {formatCurrency(getProp(rowData, 'base_wage', 0))}
                        </Box>
                      </Box>
                    </Box>
                  }
                  placement="bottom-start"
                >
                  <Box className="tw-inline-block">{formatCurrency(rowData.admin_reg_pay as number)}</Box>
                </Tooltip>
              </Box>
            )
          } else {
            cellJSX = <Box className="tw-opacity-30 tw-inline-block">{formatCurrency(0)}</Box>
          }
          return cellJSX
        }
        return rJSX_SummaryTableCell('admin_reg_pay', rowData, tableAdditionalData, tableHooks, returnInnerCellJSX)
      },
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        let cellCSS = ''
        if (getProp(tableAdditionalData, 'payroll_week_data_locked', null) === false) {
          // Nothing
        } else if (getProp(tableAdditionalData, 'payroll_week_data_locked', null) === true) {
          cellCSS = returnDiscrepancyHighlightClassName('admin_reg_pay', tableAdditionalData, rowData)
        }
        return cellCSS
      },
    },
  },
  field_reg_pay: {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        let total = 0
        if (
          tableAdditionalData != null &&
          tableAdditionalData.us_employeePayrollDataTotals != null &&
          tableAdditionalData.us_employeePayrollDataTotals['field_reg_pay'] != null
        ) {
          total = tableAdditionalData.us_employeePayrollDataTotals['field_reg_pay']
        }
        let headerJSX = (
          <Box>
            <Box>{rLIB('Field Reg Pay')}</Box>
            <Box className="tw-opacity-50 tw-italic">{formatCurrency(total)}</Box>
          </Box>
        )
        return headerJSX
      },
      header_sort_by: 'field_reg_pay',
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return 'tw-bg-info_light tw-text-white'
      },
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        const returnInnerCellJSX = (
          rowData: TsInterface_TableDataRow,
          tableAdditionalData: TsInterface_TableAdditionalData,
          tableHooks: TsInterface_TableHooks,
        ) => {
          let cellJSX = <></>
          if (rowData != null && rowData.field_reg_pay != null && rowData.field_reg_pay !== 0) {
            cellJSX = (
              <Box className="tw-inline-block">
                <Tooltip
                  title={
                    <Box sx={{ fontSize: '14px' }}>
                      <Box>
                        <Box sx={{ color: themeVariables.white, display: 'inline-block', marginLeft: '4px' }}>
                          {formatCurrency(getProp(rowData, 'field_reg_pay', 0))} = {getProp(rowData, 'field_reg_hours', 0).toFixed(1)} {rLIB('hrs')} @{' '}
                          {formatCurrency(getProp(rowData, 'effective_wage', 0))}
                        </Box>
                      </Box>
                    </Box>
                  }
                  placement="bottom-start"
                >
                  <Box className="tw-inline-block">{formatCurrency(rowData.field_reg_pay as number)}</Box>
                </Tooltip>
              </Box>
            )
          } else {
            cellJSX = <Box className="tw-opacity-30 tw-inline-block">{formatCurrency(0)}</Box>
          }
          return cellJSX
        }
        return rJSX_SummaryTableCell('field_reg_pay', rowData, tableAdditionalData, tableHooks, returnInnerCellJSX)
      },
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        let cellCSS = ''
        if (getProp(tableAdditionalData, 'payroll_week_data_locked', null) === false) {
          // Nothing
        } else if (getProp(tableAdditionalData, 'payroll_week_data_locked', null) === true) {
          cellCSS = returnDiscrepancyHighlightClassName('field_reg_pay', tableAdditionalData, rowData)
        }
        return cellCSS
      },
    },
  },
  admin_ovt_pay: {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        let total = 0
        if (
          tableAdditionalData != null &&
          tableAdditionalData.us_employeePayrollDataTotals != null &&
          tableAdditionalData.us_employeePayrollDataTotals['admin_ovt_pay'] != null
        ) {
          total = tableAdditionalData.us_employeePayrollDataTotals['admin_ovt_pay']
        }
        let headerJSX = (
          <Box>
            <Box>{rLIB('Admin Ovt Pay')}</Box>
            <Box className="tw-opacity-50 tw-italic">{formatCurrency(total)}</Box>
          </Box>
        )
        return headerJSX
      },
      header_sort_by: 'admin_ovt_pay',
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return 'tw-bg-info_light tw-text-white'
      },
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        const returnInnerCellJSX = (
          rowData: TsInterface_TableDataRow,
          tableAdditionalData: TsInterface_TableAdditionalData,
          tableHooks: TsInterface_TableHooks,
        ) => {
          let cellJSX = <></>
          if (rowData != null && rowData.admin_ovt_pay != null && rowData.admin_ovt_pay !== 0) {
            cellJSX = (
              <Box className="tw-inline-block">
                <Tooltip
                  title={
                    <Box sx={{ fontSize: '14px' }}>
                      <Box>
                        <Box sx={{ color: themeVariables.white, display: 'inline-block', marginLeft: '4px' }}>
                          {formatCurrency(getProp(rowData, 'admin_ovt_pay', 0))} = {getProp(rowData, 'admin_ovt_hours', 0).toFixed(1)} {rLIB('hrs')} @{' '}
                          {formatCurrency(getProp(rowData, 'base_wage', 0) * 1.5)}
                        </Box>
                      </Box>
                    </Box>
                  }
                  placement="bottom-start"
                >
                  <Box className="tw-inline-block">{formatCurrency(rowData.admin_ovt_pay as number)}</Box>
                </Tooltip>
              </Box>
            )
          } else {
            cellJSX = <Box className="tw-opacity-30 tw-inline-block">{formatCurrency(0)}</Box>
          }
          return cellJSX
        }
        return rJSX_SummaryTableCell('admin_ovt_pay', rowData, tableAdditionalData, tableHooks, returnInnerCellJSX)
      },
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        let cellCSS = ''
        if (getProp(tableAdditionalData, 'payroll_week_data_locked', null) === false) {
          // Nothing
        } else if (getProp(tableAdditionalData, 'payroll_week_data_locked', null) === true) {
          cellCSS = returnDiscrepancyHighlightClassName('admin_ovt_pay', tableAdditionalData, rowData)
        }
        return cellCSS
      },
    },
  },
  field_ovt_pay: {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        let total = 0
        if (
          tableAdditionalData != null &&
          tableAdditionalData.us_employeePayrollDataTotals != null &&
          tableAdditionalData.us_employeePayrollDataTotals['field_ovt_pay'] != null
        ) {
          total = tableAdditionalData.us_employeePayrollDataTotals['field_ovt_pay']
        }
        let headerJSX = (
          <Box>
            <Box>{rLIB('Field Ovt Pay')}</Box>
            <Box className="tw-opacity-50 tw-italic">{formatCurrency(total)}</Box>
          </Box>
        )
        return headerJSX
      },
      header_sort_by: 'field_ovt_pay',
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return 'tw-bg-info_light tw-text-white'
      },
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        const returnInnerCellJSX = (
          rowData: TsInterface_TableDataRow,
          tableAdditionalData: TsInterface_TableAdditionalData,
          tableHooks: TsInterface_TableHooks,
        ) => {
          let cellJSX = <></>
          if (rowData != null && rowData.field_ovt_pay != null && rowData.field_ovt_pay !== 0) {
            cellJSX = (
              <Box className="tw-inline-block">
                <Tooltip
                  title={
                    <Box sx={{ fontSize: '14px' }}>
                      <Box>
                        <Box sx={{ color: themeVariables.white, display: 'inline-block', marginLeft: '4px' }}>
                          {formatCurrency(getProp(rowData, 'field_ovt_pay', 0))} = {getProp(rowData, 'field_ovt_hours', 0).toFixed(1)} {rLIB('hrs')} @{' '}
                          {formatCurrency(getProp(rowData, 'effective_wage', 0) * 1.5)}
                        </Box>
                      </Box>
                    </Box>
                  }
                  placement="bottom-start"
                >
                  <Box className="tw-inline-block">{formatCurrency(rowData.field_ovt_pay as number)}</Box>
                </Tooltip>
              </Box>
            )
          } else {
            cellJSX = <Box className="tw-opacity-30 tw-inline-block">{formatCurrency(0)}</Box>
          }
          return cellJSX
        }
        return rJSX_SummaryTableCell('field_ovt_pay', rowData, tableAdditionalData, tableHooks, returnInnerCellJSX)
      },
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        let cellCSS = ''
        if (getProp(tableAdditionalData, 'payroll_week_data_locked', null) === false) {
          // Nothing
        } else if (getProp(tableAdditionalData, 'payroll_week_data_locked', null) === true) {
          cellCSS = returnDiscrepancyHighlightClassName('field_ovt_pay', tableAdditionalData, rowData)
        }
        return cellCSS
      },
    },
  },
  non_working_hours_pay: {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        let total = 0
        if (
          tableAdditionalData != null &&
          tableAdditionalData.us_employeePayrollDataTotals != null &&
          tableAdditionalData.us_employeePayrollDataTotals['non_working_hours_pay'] != null
        ) {
          total = tableAdditionalData.us_employeePayrollDataTotals['non_working_hours_pay']
        }
        let headerJSX = (
          <Box>
            <Box>{rLIB('NWT Pay')}</Box>
            <Box className="tw-opacity-50 tw-italic">{formatCurrency(total)}</Box>
          </Box>
        )
        return headerJSX
      },
      header_sort_by: 'non_working_hours_pay',
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return 'tw-bg-info_light tw-text-white'
      },
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        const returnInnerCellJSX = (
          rowData: TsInterface_TableDataRow,
          tableAdditionalData: TsInterface_TableAdditionalData,
          tableHooks: TsInterface_TableHooks,
        ) => {
          let cellJSX = <></>
          if (rowData != null && rowData.non_working_hours_pay != null && rowData.non_working_hours_pay !== 0) {
            cellJSX = (
              <Box className="tw-inline-block">
                <Tooltip
                  title={
                    <Box sx={{ fontSize: '14px' }}>
                      {rLIB('Non Working Hours')}:
                      <Box sx={{ color: themeVariables.primary_main, display: 'inline-block', marginLeft: '4px' }}>
                        {formatCurrency(getProp(rowData, 'non_working_hours_pay', 0))} = {getProp(rowData, 'non_working_hours', 0).toFixed(1)} {rLIB('hrs')} @{' '}
                        {formatCurrency(getProp(rowData, 'base_wage', 0))}
                      </Box>
                    </Box>
                  }
                  placement="bottom-start"
                >
                  <Box className="tw-inline-block">{formatCurrency(rowData.non_working_hours_pay as number)}</Box>
                </Tooltip>
              </Box>
            )
          } else {
            cellJSX = <Box className="tw-opacity-30 tw-inline-block">{formatCurrency(0)}</Box>
          }
          return cellJSX
        }
        return rJSX_SummaryTableCell('non_working_hours_pay', rowData, tableAdditionalData, tableHooks, returnInnerCellJSX)
      },
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        let cellCSS = ''
        if (getProp(tableAdditionalData, 'payroll_week_data_locked', null) === false) {
          // Nothing
        } else if (getProp(tableAdditionalData, 'payroll_week_data_locked', null) === true) {
          cellCSS = returnDiscrepancyHighlightClassName('non_working_hours_pay', tableAdditionalData, rowData)
        }
        return cellCSS
      },
    },
  },
  total_pay: {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        let total = 0
        if (
          tableAdditionalData != null &&
          tableAdditionalData.us_employeePayrollDataTotals != null &&
          tableAdditionalData.us_employeePayrollDataTotals['total_pay'] != null
        ) {
          total = tableAdditionalData.us_employeePayrollDataTotals['total_pay']
        }
        let headerJSX = (
          <Box>
            <Box>{rLIB('Base Pay')}</Box>
            <Box className="tw-opacity-50 tw-italic">{formatCurrency(total)}</Box>
          </Box>
        )
        return headerJSX
      },
      header_sort_by: 'total_pay',
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return 'tw-bg-info_dark tw-text-white'
      },
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        const returnInnerCellJSX = (
          rowData: TsInterface_TableDataRow,
          tableAdditionalData: TsInterface_TableAdditionalData,
          tableHooks: TsInterface_TableHooks,
        ) => {
          let cellJSX = <Box className="tw-opacity-30">{rLIB('Missing')}</Box>
          if (rowData != null && rowData.total_pay != null && rowData.total_pay !== 0) {
            cellJSX = (
              <Box className="tw-inline-block">
                <Tooltip
                  title={
                    <Box sx={{ fontSize: '14px' }}>
                      <Box>
                        {rLIB('Admin Reg Pay')}:
                        <Box
                          sx={{
                            color: getProp(rowData, 'admin_reg_pay', 0) > 0 ? themeVariables.primary_main : themeVariables.gray_500,
                            display: 'inline-block',
                            marginLeft: '4px',
                          }}
                        >
                          {formatCurrency(getProp(rowData, 'admin_reg_pay', 0))}
                        </Box>
                      </Box>
                      <Box>
                        {rLIB('Field Reg Pay')}:
                        <Box
                          sx={{
                            color: getProp(rowData, 'field_reg_pay', 0) > 0 ? themeVariables.primary_main : themeVariables.gray_500,
                            display: 'inline-block',
                            marginLeft: '4px',
                          }}
                        >
                          {formatCurrency(getProp(rowData, 'field_reg_pay', 0))}
                        </Box>
                      </Box>
                      <Box>
                        {rLIB('Admin Ovt Pay')}:
                        <Box
                          sx={{
                            color: getProp(rowData, 'admin_ovt_pay', 0) > 0 ? themeVariables.primary_main : themeVariables.gray_500,
                            display: 'inline-block',
                            marginLeft: '4px',
                          }}
                        >
                          {formatCurrency(getProp(rowData, 'admin_ovt_pay', 0))}
                        </Box>
                      </Box>
                      <Box>
                        {rLIB('Field Ovt Pay')}:
                        <Box
                          sx={{
                            color: getProp(rowData, 'field_ovt_pay', 0) > 0 ? themeVariables.primary_main : themeVariables.gray_500,
                            display: 'inline-block',
                            marginLeft: '4px',
                          }}
                        >
                          {formatCurrency(getProp(rowData, 'field_ovt_pay', 0))}
                        </Box>
                      </Box>
                      <Box>
                        {rLIB('NWT Pay')}:
                        <Box
                          sx={{
                            color: getProp(rowData, 'non_working_hours_pay', 0) > 0 ? themeVariables.primary_main : themeVariables.gray_500,
                            display: 'inline-block',
                            marginLeft: '4px',
                          }}
                        >
                          {formatCurrency(getProp(rowData, 'non_working_hours_pay', 0))}
                        </Box>
                      </Box>
                      <Divider className="tw-my-2" />
                      <Box>
                        {rLIB('Total Base Pay')}:
                        <Box
                          sx={{
                            color: getProp(rowData, 'total_pay', 0) > 0 ? themeVariables.primary_main : themeVariables.gray_500,
                            display: 'inline-block',
                            marginLeft: '4px',
                          }}
                        >
                          {formatCurrency(getProp(rowData, 'total_pay', 0))}
                        </Box>
                      </Box>
                    </Box>
                  }
                  placement="bottom-start"
                >
                  <Box className="tw-inline-block">{formatCurrency(rowData.total_pay as number)}</Box>
                </Tooltip>
              </Box>
            )
          } else {
            cellJSX = <Box className="tw-opacity-30 tw-inline-block">{formatCurrency(0)}</Box>
          }
          return cellJSX
        }
        return rJSX_SummaryTableCell('total_pay', rowData, tableAdditionalData, tableHooks, returnInnerCellJSX)
      },
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        let cellCSS = 'tw-bg-background_json'
        if (getProp(tableAdditionalData, 'payroll_week_data_locked', null) === false) {
          // Nothing
        } else if (getProp(tableAdditionalData, 'payroll_week_data_locked', null) === true) {
          cellCSS = returnDiscrepancyHighlightClassName('total_pay', tableAdditionalData, rowData)
        }
        return cellCSS
      },
    },
  },
  unit_pay_all: {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        let total = 0
        if (
          tableAdditionalData != null &&
          tableAdditionalData.us_employeePayrollDataTotals != null &&
          tableAdditionalData.us_employeePayrollDataTotals['unit_pay_all'] != null
        ) {
          total = tableAdditionalData.us_employeePayrollDataTotals['unit_pay_all']
        }
        let headerJSX = (
          <Box>
            <Box>{rLIB('Unit Pay')}</Box>
            <Box className="tw-opacity-50 tw-italic">{formatCurrency(total)}</Box>
          </Box>
        )
        return headerJSX
      },
      header_sort_by: 'unit_pay_all',
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return 'tw-bg-success_main tw-text-center'
      },
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        const returnInnerCellJSX = (
          rowData: TsInterface_TableDataRow,
          tableAdditionalData: TsInterface_TableAdditionalData,
          tableHooks: TsInterface_TableHooks,
        ) => {
          let cellJSX = <></>
          if (rowData != null && rowData.unit_pay_all != null && rowData.unit_pay_all !== 0) {
            cellJSX = (
              <Box className="tw-inline-block">
                <Tooltip
                  title={
                    <Box>
                      {objectToArray(getProp(rowData, 'unit_pay_task_breakdown', {}))
                        .sort(dynamicSort('key', 'asc'))
                        .map((payBreakdown: TsInterface_UnspecifiedObject, index: number) => (
                          <Box
                            key={index}
                            className="tw-mb-1"
                          >
                            <Box sx={{ fontSize: '14px' }}>
                              {payBreakdown.key}:
                              <Box sx={{ color: themeVariables.primary_main, display: 'inline-block', marginLeft: '4px' }}>
                                {formatCurrency(payBreakdown.value)}
                              </Box>
                            </Box>
                            <Divider />
                          </Box>
                        ))}
                    </Box>
                  }
                  placement="bottom-start"
                >
                  <Box className="tw-inline-block">{formatCurrency(rowData.unit_pay_all as number)}</Box>
                </Tooltip>
              </Box>
            )
          } else {
            cellJSX = <Box className="tw-opacity-30 tw-inline-block">{formatCurrency(0)}</Box>
          }
          return cellJSX
        }
        return rJSX_SummaryTableCell('unit_pay_all', rowData, tableAdditionalData, tableHooks, returnInnerCellJSX)
      },
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        let cellCSS = ''
        if (getProp(tableAdditionalData, 'payroll_week_data_locked', null) === false) {
          // Nothing
        } else if (getProp(tableAdditionalData, 'payroll_week_data_locked', null) === true) {
          cellCSS = returnDiscrepancyHighlightClassName('unit_pay_all', tableAdditionalData, rowData)
        }
        return cellCSS
      },
    },
  },
  unit_pay_bonus: {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        let total = 0
        if (
          tableAdditionalData != null &&
          tableAdditionalData.us_employeePayrollDataTotals != null &&
          tableAdditionalData.us_employeePayrollDataTotals['unit_pay_bonus'] != null
        ) {
          total = tableAdditionalData.us_employeePayrollDataTotals['unit_pay_bonus']
        }
        let headerJSX = (
          <Box>
            <Box>{rLIB('Unit Pay Bonus')}</Box>
            <Box className="tw-opacity-50 tw-italic">{formatCurrency(total)}</Box>
          </Box>
        )
        return headerJSX
      },
      header_sort_by: 'unit_pay_bonus',
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return 'tw-bg-success_main'
      },
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        const returnInnerCellJSX = (
          rowData: TsInterface_TableDataRow,
          tableAdditionalData: TsInterface_TableAdditionalData,
          tableHooks: TsInterface_TableHooks,
        ) => {
          let cellJSX = <></>
          if (rowData != null && rowData.unit_pay_bonus != null && rowData.unit_pay_bonus !== 0) {
            cellJSX = (
              <Box className="tw-inline-block">
                <Tooltip
                  title={
                    <Box sx={{ fontSize: '14px' }}>
                      <Box>
                        {rLIB('Unit Pay')}:
                        <Box
                          sx={{
                            color: getProp(rowData, 'unit_pay_all', 0) > 0 ? themeVariables.primary_main : themeVariables.gray_500,
                            display: 'inline-block',
                            marginLeft: '4px',
                          }}
                        >
                          {formatCurrency(getProp(rowData, 'unit_pay_all', 0))}
                        </Box>
                      </Box>
                      <Box sx={{ color: themeVariables.error_main }}>({rLIB('Minus')})</Box>
                      <Box>
                        {rLIB('Field Reg Pay')}:
                        <Box
                          sx={{
                            color: getProp(rowData, 'field_reg_pay', 0) > 0 ? themeVariables.primary_main : themeVariables.gray_500,
                            display: 'inline-block',
                            marginLeft: '4px',
                          }}
                        >
                          {formatCurrency(getProp(rowData, 'field_reg_pay', 0))}
                        </Box>
                      </Box>
                      <Box>
                        {rLIB('Field Ovt Pay')}:
                        <Box
                          sx={{
                            color: getProp(rowData, 'field_ovt_pay', 0) > 0 ? themeVariables.primary_main : themeVariables.gray_500,
                            display: 'inline-block',
                            marginLeft: '4px',
                          }}
                        >
                          {formatCurrency(getProp(rowData, 'field_ovt_pay', 0))}
                        </Box>
                      </Box>
                      <Divider className="tw-my-2" />
                      <Box>
                        {rLIB('Total')}:
                        <Box
                          sx={{
                            color: getProp(rowData, 'unit_pay_bonus', 0) > 0 ? themeVariables.primary_main : themeVariables.gray_500,
                            display: 'inline-block',
                            marginLeft: '4px',
                          }}
                        >
                          {formatCurrency(getProp(rowData, 'unit_pay_bonus', 0))}
                        </Box>
                      </Box>
                    </Box>
                  }
                  placement="bottom-start"
                >
                  <Box className="tw-inline-block">{formatCurrency(rowData.unit_pay_bonus as number)}</Box>
                </Tooltip>
              </Box>
            )
          } else {
            cellJSX = <Box className="tw-opacity-30 tw-inline-block">{formatCurrency(0)}</Box>
          }
          return cellJSX
        }
        return rJSX_SummaryTableCell('unit_pay_bonus', rowData, tableAdditionalData, tableHooks, returnInnerCellJSX)
      },
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        let cellCSS = ''
        if (getProp(tableAdditionalData, 'payroll_week_data_locked', null) === false) {
          // Nothing
        } else if (getProp(tableAdditionalData, 'payroll_week_data_locked', null) === true) {
          cellCSS = returnDiscrepancyHighlightClassName('unit_pay_bonus', tableAdditionalData, rowData)
        }
        return cellCSS
      },
    },
  },
  pay_adjustments: {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        let total = 0
        if (
          tableAdditionalData != null &&
          tableAdditionalData.us_employeePayrollDataTotals != null &&
          tableAdditionalData.us_employeePayrollDataTotals['pay_adjustments'] != null
        ) {
          total = tableAdditionalData.us_employeePayrollDataTotals['pay_adjustments']
        }
        let headerJSX = (
          <Box>
            <Box>{rLIB('Adjustments')}</Box>
            <Box className="tw-opacity-50 tw-italic">{formatCurrency(total)}</Box>
          </Box>
        )
        return headerJSX
      },
      header_sort_by: 'pay_adjustments',
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return 'tw-bg-success_main'
      },
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        const returnInnerCellJSX = (
          rowData: TsInterface_TableDataRow,
          tableAdditionalData: TsInterface_TableAdditionalData,
          tableHooks: TsInterface_TableHooks,
        ) => {
          let cellJSX = <></>
          if (rowData != null && rowData.pay_adjustments != null && rowData.pay_adjustments !== 0) {
            let warningIconJSX = <></>
            if (getProp(tableAdditionalData, 'payroll_week_data_locked', null) === false) {
              if (rowData != null && rowData.final_bonus_pay != null && getProp(rowData, 'final_bonus_pay', 0) < 0) {
                warningIconJSX = (
                  <Icon
                    icon="triangle-exclamation"
                    className="tw-mr-2"
                    sx={{ color: themeVariables.error_main }}
                  />
                )
              }
            }
            cellJSX = (
              <Box>
                <Tooltip
                  title={
                    <Box sx={{ fontSize: '14px' }}>
                      {objectToArray(getProp(rowData, 'pay_adjustment_details', {}))
                        .sort(dynamicSort('amount', 'desc'))
                        .map((adjustment: TsInterface_UnspecifiedObject, index: number) => (
                          <Box
                            key={index}
                            className="tw-mb-1"
                          >
                            <Box sx={{ fontSize: '14px' }}>
                              {adjustment.notes}:
                              <Box
                                sx={{
                                  color: adjustment.type === 'payroll_bonus_decrease' ? themeVariables.error_light : themeVariables.success_main,
                                  display: 'inline-block',
                                  marginLeft: '4px',
                                }}
                              >
                                {adjustment.type === 'payroll_bonus_decrease' ? '-' : ''}
                                {formatCurrency(adjustment.amount)}
                              </Box>
                            </Box>
                            <Divider />
                          </Box>
                        ))}
                    </Box>
                  }
                  placement="bottom-start"
                >
                  <Box className="tw-inline-block">
                    {warningIconJSX}
                    {formatCurrency(rowData.pay_adjustments as number)}
                  </Box>
                </Tooltip>
              </Box>
            )
          } else {
            cellJSX = <Box className="tw-opacity-30">{formatCurrency(0)}</Box>
          }
          return cellJSX
        }
        return rJSX_SummaryTableCell('pay_adjustments', rowData, tableAdditionalData, tableHooks, returnInnerCellJSX)
      },
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        let cellCSS = ''
        if (getProp(tableAdditionalData, 'payroll_week_data_locked', null) === false) {
          // Nothing
        } else if (getProp(tableAdditionalData, 'payroll_week_data_locked', null) === true) {
          cellCSS = returnDiscrepancyHighlightClassName('pay_adjustments', tableAdditionalData, rowData)
        }
        return cellCSS
      },
    },
  },
  final_bonus_pay: {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        let total = 0
        if (
          tableAdditionalData != null &&
          tableAdditionalData.us_employeePayrollDataTotals != null &&
          tableAdditionalData.us_employeePayrollDataTotals['final_bonus_pay'] != null
        ) {
          total = tableAdditionalData.us_employeePayrollDataTotals['final_bonus_pay']
        }
        let headerJSX = (
          <Box>
            <Box>{rLIB('UPB after adjustments')}</Box>
            <Box className="tw-opacity-50 tw-italic">{formatCurrency(total)}</Box>
          </Box>
        )
        return headerJSX
      },
      header_sort_by: 'final_bonus_pay',
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return 'tw-bg-success_dark'
      },
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        const returnInnerCellJSX = (
          rowData: TsInterface_TableDataRow,
          tableAdditionalData: TsInterface_TableAdditionalData,
          tableHooks: TsInterface_TableHooks,
        ) => {
          let cellJSX = <></>
          if (rowData != null && rowData.final_bonus_pay != null && rowData.final_bonus_pay !== 0) {
            cellJSX = (
              <Box className="tw-inline-block">
                <Tooltip
                  title={
                    <Box sx={{ fontSize: '14px' }}>
                      <Box>
                        {rLIB('Unit Pay Bonus')}:
                        <Box sx={{ color: themeVariables.primary_main, display: 'inline-block', marginLeft: '4px' }}>
                          {formatCurrency(getProp(rowData, 'unit_pay_bonus', 0))}
                        </Box>
                      </Box>
                      <Box sx={{ color: themeVariables.error_main }}>({rLIB('Minus')})</Box>
                      <Box>
                        {rLIB('Adjustments')}:
                        <Box sx={{ color: themeVariables.primary_main, display: 'inline-block', marginLeft: '4px' }}>
                          {formatCurrency(getProp(rowData, 'pay_adjustments', 0))}
                        </Box>
                      </Box>
                      <Divider className="tw-my-2" />
                      <Box>
                        {rLIB('Total')}:
                        <Box sx={{ color: themeVariables.primary_main, display: 'inline-block', marginLeft: '4px' }}>
                          {formatCurrency(getProp(rowData, 'final_bonus_pay', 0))}
                        </Box>
                      </Box>
                    </Box>
                  }
                  placement="bottom-start"
                >
                  <Box className="tw-inline-block">{formatCurrency(rowData.final_bonus_pay as number)}</Box>
                </Tooltip>
              </Box>
            )
          } else {
            cellJSX = <Box className="tw-opacity-30 tw-inline-block">{formatCurrency(0)}</Box>
          }
          return cellJSX
        }
        return rJSX_SummaryTableCell('final_bonus_pay', rowData, tableAdditionalData, tableHooks, returnInnerCellJSX)
      },
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        let cellCSS = 'tw-bg-background_json'
        if (getProp(tableAdditionalData, 'payroll_week_data_locked', null) === false) {
          if (rowData != null && rowData.final_bonus_pay != null && getProp(rowData, 'final_bonus_pay', 0) < 0) {
            cellCSS = 'tw-bg-error_main'
          }
        } else if (getProp(tableAdditionalData, 'payroll_week_data_locked', null) === true) {
          cellCSS = returnDiscrepancyHighlightClassName('final_bonus_pay', tableAdditionalData, rowData)
        }
        return cellCSS
      },
    },
  },
  total_base_plus_bonus_pay: {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        let total = 0
        if (
          tableAdditionalData != null &&
          tableAdditionalData.us_employeePayrollDataTotals != null &&
          tableAdditionalData.us_employeePayrollDataTotals['total_base_plus_bonus_pay'] != null
        ) {
          total = tableAdditionalData.us_employeePayrollDataTotals['total_base_plus_bonus_pay']
        }
        let headerJSX = (
          <Box>
            <Box>{rLIB('Base plus Bonus')}</Box>
            <Box className="tw-opacity-50 tw-italic">{formatCurrency(total)}</Box>
          </Box>
        )
        return headerJSX
      },
      header_sort_by: 'total_base_plus_bonus_pay',
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return 'tw-bg-success_dark'
      },
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        const returnInnerCellJSX = (
          rowData: TsInterface_TableDataRow,
          tableAdditionalData: TsInterface_TableAdditionalData,
          tableHooks: TsInterface_TableHooks,
        ) => {
          let cellJSX = <></>
          if (rowData != null && rowData.total_base_plus_bonus_pay != null && rowData.total_base_plus_bonus_pay !== 0) {
            cellJSX = (
              <Box className="tw-inline-block">
                <Tooltip
                  title={
                    <Box sx={{ fontSize: '14px' }}>
                      <Box>
                        {rLIB('Base Pay')}:
                        <Box sx={{ color: themeVariables.primary_main, display: 'inline-block', marginLeft: '4px' }}>
                          {formatCurrency(getProp(rowData, 'total_pay', 0))}
                        </Box>
                      </Box>
                      <Box>
                        {rLIB('UPB after adjustments')}:
                        <Box sx={{ color: themeVariables.primary_main, display: 'inline-block', marginLeft: '4px' }}>
                          {formatCurrency(getProp(rowData, 'final_bonus_pay', 0))}
                        </Box>
                      </Box>
                      <Divider className="tw-my-2" />
                      <Box>
                        {rLIB('Total')}:
                        <Box sx={{ color: themeVariables.primary_main, display: 'inline-block', marginLeft: '4px' }}>
                          {formatCurrency(getProp(rowData, 'total_base_plus_bonus_pay', 0))}
                        </Box>
                      </Box>
                    </Box>
                  }
                  placement="bottom-start"
                >
                  <Box className="tw-inline-block">{formatCurrency(rowData.total_base_plus_bonus_pay as number)}</Box>
                </Tooltip>
              </Box>
            )
          } else {
            cellJSX = <Box className="tw-opacity-30 tw-inline-block">{formatCurrency(0)}</Box>
          }
          return cellJSX
        }
        return rJSX_SummaryTableCell('total_base_plus_bonus_pay', rowData, tableAdditionalData, tableHooks, returnInnerCellJSX)
      },
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        let cellCSS = 'tw-font-bold'
        if (getProp(tableAdditionalData, 'payroll_week_data_locked', null) === false) {
          // Nothing
        } else if (getProp(tableAdditionalData, 'payroll_week_data_locked', null) === true) {
          cellCSS = returnDiscrepancyHighlightClassName('total_base_plus_bonus_pay', tableAdditionalData, rowData)
        }
        return cellCSS
      },
    },
  },
  pay_inefficiency: {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        let total = 0
        if (
          tableAdditionalData != null &&
          tableAdditionalData.us_employeePayrollDataTotals != null &&
          tableAdditionalData.us_employeePayrollDataTotals['pay_inefficiency'] != null
        ) {
          total = tableAdditionalData.us_employeePayrollDataTotals['pay_inefficiency']
        }
        let headerJSX = (
          <Box>
            <Box>{rLIB('Inefficiency')}</Box>
            <Box className="tw-opacity-50 tw-italic">{formatCurrency(total)}</Box>
          </Box>
        )
        return headerJSX
      },
      header_sort_by: 'pay_inefficiency',
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return 'tw-bg-error_main'
      },
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        const returnInnerCellJSX = (
          rowData: TsInterface_TableDataRow,
          tableAdditionalData: TsInterface_TableAdditionalData,
          tableHooks: TsInterface_TableHooks,
        ) => {
          let cellJSX = <></>
          if (rowData != null && rowData.pay_inefficiency != null && rowData.pay_inefficiency !== 0) {
            cellJSX = (
              <Box className="tw-inline-block">
                <Tooltip
                  title={
                    <Box sx={{ fontSize: '14px' }}>
                      <Box>
                        {rLIB('Unit Pay')}:
                        <Box sx={{ color: themeVariables.primary_main, display: 'inline-block', marginLeft: '4px' }}>
                          {formatCurrency(getProp(rowData, 'unit_pay_all', 0))}
                        </Box>
                      </Box>
                      <Box sx={{ color: themeVariables.error_main }}>({rLIB('Minus')})</Box>
                      <Box>
                        {rLIB('Field Reg Pay')}:
                        <Box sx={{ color: themeVariables.primary_main, display: 'inline-block', marginLeft: '4px' }}>
                          {formatCurrency(getProp(rowData, 'field_reg_pay', 0))}
                        </Box>
                      </Box>
                      <Box>
                        {rLIB('Field Ovt Pay')}:
                        <Box sx={{ color: themeVariables.primary_main, display: 'inline-block', marginLeft: '4px' }}>
                          {formatCurrency(getProp(rowData, 'field_ovt_pay', 0))}
                        </Box>
                      </Box>
                      <Divider className="tw-my-2" />
                      <Box>
                        {rLIB('Total')}:
                        <Box sx={{ color: themeVariables.primary_main, display: 'inline-block', marginLeft: '4px' }}>
                          {formatCurrency(getProp(rowData, 'pay_inefficiency', 0))}
                        </Box>
                      </Box>
                    </Box>
                  }
                  placement="bottom-start"
                >
                  <Box className="tw-inline-block">{formatCurrency(rowData.pay_inefficiency as number)}</Box>
                </Tooltip>
              </Box>
            )
          } else {
            cellJSX = <Box className="tw-opacity-30 tw-inline-block">{formatCurrency(0)}</Box>
          }
          return cellJSX
        }
        return rJSX_SummaryTableCell('pay_inefficiency', rowData, tableAdditionalData, tableHooks, returnInnerCellJSX)
      },
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        let cellCSS = ''
        if (getProp(tableAdditionalData, 'payroll_week_data_locked', null) === false) {
          if (rowData != null && rowData.pay_inefficiency != null && getProp(rowData, 'pay_inefficiency', 0) > 0) {
            cellCSS = 'tw-bg-warning_dark tw-text-white'
          }
        } else if (getProp(tableAdditionalData, 'payroll_week_data_locked', null) === true) {
          cellCSS = returnDiscrepancyHighlightClassName('pay_inefficiency', tableAdditionalData, rowData)
        }
        return cellCSS
      },
    },
  },
  name2: {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        let headerJSX = (
          <Box>
            <Box>{rLIB('Employee')}</Box>
            <Box>&nbsp;</Box>
          </Box>
        )
        return headerJSX
      },
      header_sort_by: 'name',
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = <></>
        if (rowData != null && rowData.name != null) {
          cellJSX = <>{getProp(rowData, 'name', '')}</>
        } else {
          cellJSX = <Box className="tw-opacity-20">{rowData.key}</Box>
        }
        return cellJSX
      },
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        let cellCSS = ''
        return cellCSS
      },
    },
  },
}
