import { themeVariables } from 'rfbp_aux/config/app_theme'
import { objectToArray } from 'rfbp_core/services/helper_functions'

export const processCrewHoursData = (rawCrewHours: any, mondaysInDateRange: string[]): { summary_rows: any[]; data_rows: any[] } => {
  const today = new Date()
  today.setHours(0, 0, 0, 0) // Normalize today's date

  // Adjusted function to get the previous Monday
  const getPreviousMonday = (date: Date) => {
    const currentDate = new Date(date.getTime()) // Clone the date to avoid modifying the original
    const day = currentDate.getDay() // Get the day of the week (0 = Sunday, 1 = Monday, etc.)
    const diff = day === 0 ? 6 : day - 1 // Subtract the days to get to Monday
    currentDate.setDate(currentDate.getDate() - diff) // Adjust the date to the most recent Monday

    currentDate.setHours(0, 0, 0, 0) // Set time to midnight to avoid timezone issues

    return currentDate
  }

  // Get the previous Monday by subtracting 7 days
  const previousMondayDate = getPreviousMonday(new Date(new Date().setDate(new Date().getDate() - 7)))
  const previousMondayString = previousMondayDate.toISOString().split('T')[0] // Convert to 'yyyy-mm-dd' format

  const formattedData: any = {
    summary_rows: [],
    data_rows: [],
  }

  const dateHeaders = [{ cellValue: 'Crew Hours', conditionalFormatting: { fontWeight: 'bold', width: '200px' } }]
  const totals: any = {}
  const personData: any = {}

  // Initialize each date with a zero total custom metric and check if the date is in the future
  objectToArray(mondaysInDateRange).forEach((monday: string) => {
    const date = new Date(monday)
    date.setDate(date.getDate() + 1) // Add one day to the Monday date
    const formattedDateKey = monday // Keep using the original Monday as the key
    const formattedDate = date.toLocaleDateString('en-US', { month: 'short', day: '2-digit' })

    totals[formattedDateKey] = null // Initialize totals as null

    dateHeaders.push({
      cellValue: formattedDate,
      conditionalFormatting: { fontWeight: 'bold', width: '120px' },
    })
  })

  // Process each week's data
  Object.entries(rawCrewHours || {}).forEach(([dateStr, weekData]: [string, any]) => {
    const formattedDateKey = dateStr // Use dateStr directly as the key

    Object.entries(weekData.data || {}).forEach(([personId, details]: [string, any]) => {
      if (
        details &&
        'field_hours' in details &&
        'total_panels' in details &&
        'total_units' in details &&
        'total_battery_units' in details &&
        'total_pitch_units' in details &&
        'total_distance_units' in details &&
        'name' in details
      ) {
        const rightSideOfEquation =
          details.total_panels + details.total_battery_units + (details.total_pitch_units || 0) + details.total_units + (details.total_distance_units || 0)

        // Subtract the sum of units/panels from field_hours
        const customMetric = details.field_hours !== 0 && rightSideOfEquation !== 0 ? details.field_hours - rightSideOfEquation : null

        if (!personData[details.name]) {
          personData[details.name] = { details: {} }
          objectToArray(mondaysInDateRange).forEach((monday: string) => {
            personData[details.name].details[monday] = null
          })
        }

        personData[details.name].details[formattedDateKey] = customMetric

        if (customMetric !== null && customMetric > 0) {
          // Only sum positive numbers
          if (totals[formattedDateKey] === null) {
            totals[formattedDateKey] = 0
          }
          totals[formattedDateKey] += customMetric
        }
      }
    })

    // Ensure tasks from `unit_pay_task_breakdown` without corresponding hours in `grouped_codes` are included
    if (weekData.unit_pay_task_breakdown) {
      Object.entries(weekData.unit_pay_task_breakdown).forEach(([taskKey, taskDetails]: [string, any]) => {
        const units = taskDetails.breakdown.task_units || 0
        const batteryUnits = taskDetails.breakdown.battery_units || 0
        const pitchUnits = taskDetails.breakdown.pitch_units || 0
        const distanceUnits = taskDetails.breakdown.distance_units || 0

        const totalUnits = units + batteryUnits + pitchUnits + distanceUnits

        if (!personData[taskDetails.name]) {
          personData[taskDetails.name] = { details: {} }
          objectToArray(mondaysInDateRange).forEach((monday: string) => {
            personData[taskDetails.name].details[monday] = null
          })
        }

        // If no corresponding field_hours are present, add these tasks as 0 hours but with units included
        if (!personData[taskDetails.name].details[formattedDateKey]) {
          personData[taskDetails.name].details[formattedDateKey] = totalUnits > 0 ? -totalUnits : null
        }
      })
    }
  })

  // Filter out people who have `null` for all the weeks
  const filteredPersons = Object.keys(personData).filter((personName) => {
    return mondaysInDateRange.some((monday) => personData[personName].details[monday] !== null)
  })

  // Construct the data rows for each person
  filteredPersons.forEach((personName: string) => {
    const rowData = [
      {
        cellValue: personName,
        conditionalFormatting: { fontWeight: 'bold', backgroundColor: '', opacity: 1, width: '200px' },
      },
    ]
    objectToArray(mondaysInDateRange).forEach((monday: string) => {
      const metricThisWeek = personData[personName].details[monday]
      const isMetricDefined = metricThisWeek !== null && !isNaN(Number(metricThisWeek))
      const isFuture = new Date(monday) > today
      rowData.push({
        cellValue: isMetricDefined ? Number(metricThisWeek).toFixed(2) : '-',
        conditionalFormatting: {
          fontWeight: 'normal',
          backgroundColor: isMetricDefined
            ? Number(metricThisWeek) > 0
              ? themeVariables.error_main
              : Number(metricThisWeek) < 0
                ? themeVariables.success_main
                : ''
            : 'inherit',
          opacity: isFuture ? 0.5 : 1,
          width: '120px',
        },
      })
    })
    formattedData.data_rows.push(rowData)
  })

  // Append the total metrics summary row
  formattedData.summary_rows.push(dateHeaders)
  const totalMetricsRow = [
    {
      cellValue: 'Total',
      conditionalFormatting: { fontWeight: 'bold', backgroundColor: '', opacity: 1, width: '200px' },
    },
  ]
  objectToArray(mondaysInDateRange).forEach((monday: string) => {
    const totalValue = totals[monday] !== null && !isNaN(totals[monday]) ? totals[monday].toFixed(2) : '-'
    const isFuture = new Date(monday) > today
    totalMetricsRow.push({
      cellValue: totalValue,
      conditionalFormatting: {
        fontWeight: 'normal',
        backgroundColor: totalValue > 0 ? themeVariables.error_main : totalValue < 0 ? themeVariables.success_main : '',
        opacity: isFuture ? 0.5 : 1,
        width: '120px',
      },
    })
  })
  formattedData.summary_rows.push(totalMetricsRow)

  return formattedData
}
