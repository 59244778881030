///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			containers are pages / views used in the app and are made up of components and can interact with services and models

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { AppBar, Avatar, Box, Button, Card, Dialog, DialogContent, IconButton, Toolbar, Typography } from '@mui/material/'
import React, { useContext, useEffect, useReducer } from 'react'
import { Trans } from 'react-i18next'
import { AuthenticatedContainer } from 'rfbp_aux/containers/authenticated_container'
import { ApplicationPages } from 'rfbp_aux/data/application_structure'
import { DatabaseRef_Regions_Collection, DatabaseRef_Regions_Search, DatabaseRef_Region_Document } from 'rfbp_aux/services/database_endpoints/directory/regions'
import { StorageRef_RegionPictures } from 'rfbp_aux/services/storage_endpoints/regions'
import { FileUploadButton } from 'rfbp_core/components/file_upload'
import {
  TsInterface_FormAdditionalData,
  TsInterface_FormData,
  TsInterface_FormHooksObject,
  TsInterface_FormInputs,
  TsInterface_FormSettings,
  TsInterface_FormSubmittedData,
} from 'rfbp_core/components/form'
import { Icon } from 'rfbp_core/components/icons'
import {
  TableCellBasic,
  TableCellManage,
  TableDatabase,
  TsInterface_TableAdditionalData,
  TsInterface_TableColumns,
  TsInterface_TableDatabaseEndpointQueryObject,
  TsInterface_TableDatabaseSettings,
  TsInterface_TableDataRow,
  TsInterface_TableHooks,
} from 'rfbp_core/components/table'
import { rLIB } from 'rfbp_core/localization/library'
import {
  Context_RootData_ClientKey,
  Context_UserInterface_ErrorDialog,
  Context_UserInterface_PromptDialog,
  TsInterface_PromptDialogObject,
  UserInterface_Default_CustomDialogDisplayState,
} from 'rfbp_core/services/context'
import {
  DatabaseAddDocument,
  DatabaseSetMergeDocument,
  generateDatabaseQuery,
  StorageUploadFile,
  TsInterface_OrderByArray,
  TsInterface_QueryCursorsObject,
  TsInterface_QueryOperatorsArray,
} from 'rfbp_core/services/database_management'
import { getProp } from 'rfbp_core/services/helper_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject, TsType_UnknownPromise } from 'rfbp_core/typescript/global_types'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

// Authenticated Nav Data
const pageKey: string = ApplicationPages['AdminDatabaseRegionsListPage']['key']

// Displayed Translatable Strings
// { sort-start } - displayed text - scoped sort plugin
const s_ARE_YOU_SURE_THAT_YOU_WANT_TO_DELETE_THIS_REGION: JSX.Element = <Trans>Are you sure that you want to delete this region?</Trans>
const s_ARE_YOU_SURE_THAT_YOU_WANT_TO_UNDELETE_THIS_REGION: JSX.Element = <Trans>Are you sure that you want to undelete this region?</Trans>
const s_CITY: JSX.Element = <Trans>City</Trans>
const s_CREATE: JSX.Element = <Trans>Create</Trans>
const s_CREATE_NEW_REGION: JSX.Element = <Trans>Create New Region</Trans>
const s_DELETE: JSX.Element = <Trans>Delete</Trans>
const s_DELETE_REGION: JSX.Element = <Trans>Delete Region</Trans>
const s_EDIT_ADDRESS: JSX.Element = <Trans>Edit Address</Trans>
const s_ENTER_A_NAME_FOR_THIS_REGION: JSX.Element = <Trans>Enter a name for your this region</Trans>
const s_ENTER_A_NAME_FOR_YOUR_NEW_REGION: JSX.Element = <Trans>Enter a name for your new region</Trans>
const s_FAILED_TO_UPLOAD_FILE: JSX.Element = <Trans>Failed to upload file</Trans>
const s_INVALID_DOCUMENT_SELECTION: JSX.Element = <Trans>Invalid document selection</Trans>
const s_MISSING_REQUIRED_KEYS: JSX.Element = <Trans>Missing required keys</Trans>
const s_NEW_REGION: JSX.Element = <Trans>New Region</Trans>
const s_NO_REGIONS_FOUND_BY_SEARCH: JSX.Element = <Trans>No regions found by search</Trans>
const s_NO_REGION_PICTURE_UPLOADED: JSX.Element = <Trans>No region picture uploaded</Trans>
const s_REGIONS: JSX.Element = <Trans>Regions</Trans>
const s_REGION_NAME: JSX.Element = <Trans>Region Name</Trans>
const s_RENAME: JSX.Element = <Trans>Rename</Trans>
const s_STATE: JSX.Element = <Trans>State</Trans>
const s_STREET_ADDRESS: JSX.Element = <Trans>Street Address</Trans>
const s_UNDELETE: JSX.Element = <Trans>Undelete</Trans>
const s_UNDELETE_REGION: JSX.Element = <Trans>Undelete Region</Trans>
const s_UPDATE: JSX.Element = <Trans>Update</Trans>
const s_UPDATE_REGION_NAME: JSX.Element = <Trans>Update Region Name</Trans>
const s_UPLOAD_IMAGE: JSX.Element = <Trans>Upload Image</Trans>
const s_UPLOAD_PHOTO: JSX.Element = <Trans>Upload Photo</Trans>
const s_ZIP: JSX.Element = <Trans>Zip</Trans>
const se_REGIONS = 'Regions'
// { sort-end } - displayed text

// Form
const formInputs_LocationAddress: TsInterface_FormInputs = {
  location_address: {
    data_type: 'string',
    input_type: 'text_basic',
    key: 'location_address',
    label: s_STREET_ADDRESS,
    required: true,
  },
  location_city: {
    data_type: 'string',
    input_type: 'text_basic',
    key: 'location_city',
    label: s_CITY,
    required: true,
  },
  location_state: {
    data_type: 'string',
    input_type: 'multiple_choice_select_state_usa',
    key: 'location_state',
    label: s_STATE,
    required: true,
  },
  location_zip: {
    data_type: 'string',
    input_type: 'text_basic',
    key: 'location_zip',
    label: s_ZIP,
    required: true,
  },
}

// Table
const tableSettings_Regions: TsInterface_TableDatabaseSettings = {
  rows_per_page: 100,
  show_header: true,
  size: 'small',
  sort_direction: 'asc',
  sort_property: 'name',
  use_live_data: false,
  conditional_row_styles: [
    {
      className: 'tw-opacity-30 tw-line-through',
      conditional_display: {
        active: true,
        logic_type: 'comparison',
        source: 'rowData',
        prop: 'status',
        comparator: '==',
        value: 'deleted',
        conditions: [],
      },
    },
  ],
  searchable: true,
  search_settings_database: {
    search_type: 'firebase',
    search_no_data_message: s_NO_REGIONS_FOUND_BY_SEARCH,
    search_property_options: [{ key: 'name', value: s_REGION_NAME }],
  },
}

const tableColumns_Regions: TsInterface_TableColumns = {
  manage: TableCellManage({
    rename: {
      icon: (
        <Icon
          type="solid"
          icon="pen-to-square"
        />
      ),
      label: <>{s_RENAME}</>,
      onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        if (rowData != null && rowData.key != null && tableHooks != null && tableHooks.uc_RootData_ClientKey != null) {
          tableHooks.uc_setUserInterface_PromptDialogDisplay({
            display: true,
            prompt: {
              color: 'info',
              confirm_text: s_UPDATE,
              default_value: rowData.name,
              header: s_UPDATE_REGION_NAME,
              icon: (
                <Icon
                  icon="pen-to-square"
                  type="solid"
                />
              ),
              input_label: s_REGION_NAME,
              input_type: 'text',
              text: s_ENTER_A_NAME_FOR_THIS_REGION,
              submit_callback: (promptValue: string) => {
                return new Promise((resolve, reject) => {
                  let updateObject = {
                    name: promptValue,
                  }
                  DatabaseSetMergeDocument(DatabaseRef_Region_Document(tableHooks.uc_RootData_ClientKey, rowData.key as string), updateObject)
                    .then((res_DSMD) => {
                      // TODO - Log
                      resolve({ success: true })
                    })
                    .catch((rej_DSMD) => {
                      tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                      reject(rej_DSMD)
                    })
                })
              },
            },
          })
        }
      },
    },
    edit_address: {
      icon: (
        <Icon
          type="solid"
          icon="location-dot"
        />
      ),
      label: <>{s_EDIT_ADDRESS}</>,
      onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        if (rowData != null && rowData.key != null && tableHooks != null && tableHooks.uc_RootData_ClientKey != null) {
          tableHooks.uc_setUserInterface_FormDialogDisplay({
            display: true,
            form: {
              form: {
                formAdditionalData: {},
                formData: rowData,
                formInputs: formInputs_LocationAddress,
                formOnChange: (
                  formAdditionalData: TsInterface_FormAdditionalData,
                  formData: TsInterface_FormData,
                  formInputs: TsInterface_FormInputs,
                  formSettings: TsInterface_FormSettings,
                ) => {},
                formSettings: {},
                formSubmission: (
                  formSubmittedData: TsInterface_FormSubmittedData,
                  formAdditionalData: TsInterface_FormAdditionalData,
                  formHooks: TsInterface_FormHooksObject,
                ) => {
                  return new Promise((resolve, reject) => {
                    getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                      .then((res_GCK) => {
                        let updateObject = formSubmittedData
                        DatabaseSetMergeDocument(DatabaseRef_Region_Document(res_GCK.clientKey, rowData.key as string), updateObject)
                          .then((res_DSMD) => {
                            resolve(res_DSMD)
                          })
                          .catch((rej_DSMD) => {
                            reject(rej_DSMD)
                          })
                      })
                      .catch((rej_GCK) => {
                        formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                      })
                  })
                },
              },
              dialog: {
                formDialogHeaderColor: 'success',
                formDialogHeaderText: (
                  <>
                    {s_EDIT_ADDRESS} - {rowData.name}
                  </>
                ),
                formDialogIcon: (
                  <Icon
                    type="solid"
                    icon="pen-to-square"
                  />
                ),
              },
            },
          })
        }
      },
    },
    upload_image: {
      icon: (
        <Icon
          type="solid"
          icon="cloud-arrow-up"
        />
      ),
      label: <>{s_UPLOAD_IMAGE}</>,
      onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        if (rowData != null && rowData.key != null) {
          // File Selection
          const readAsDataURL = (file: any) => {
            return new Promise((resolve, reject) => {
              const fr = new FileReader()
              fr.onerror = reject
              fr.onload = () => {
                resolve(fr.result)
              }
              fr.readAsDataURL(file)
            })
          }
          const fileOnSelect = (
            event: React.ChangeEvent<HTMLInputElement>,
            additionalFileUploadParams: TsInterface_UnspecifiedObject,
          ): TsType_UnknownPromise => {
            return new Promise((resolve, reject) => {
              if (event != null && event.target != null && event.target.files !== null && event.target?.files?.length > 0) {
                let promiseArray: TsType_UnknownPromise[] = []
                let files = event.target.files
                let readFiles: TsInterface_UnspecifiedObject = {}
                let file = files[0]
                if (file != null && typeof file === 'object') {
                  promiseArray.push(
                    readAsDataURL(file)
                      .then((res_RADURL) => {
                        readFiles[0] = {
                          file_name: file.name,
                          file: file,
                          data_url: res_RADURL,
                        }
                      })
                      .catch((rej_RADURL) => {
                        // Nothing
                      }),
                  )
                }
                Promise.all(promiseArray).finally(() => {
                  let clientKey = additionalFileUploadParams.clientKey
                  if (clientKey != null && rowData.key != null) {
                    StorageUploadFile(StorageRef_RegionPictures(clientKey, rowData.key as string, readFiles[0].file_name), readFiles[0].file, {
                      clientKey: clientKey,
                      regionKey: rowData.key,
                    })
                      .then((res_SUF: any) => {
                        // files[ fileIndex ]["storage_url"] = getProp(res_SUF, "url", null)
                        let updateObject = {
                          region_photo_url: getProp(res_SUF, 'url', null),
                        }
                        DatabaseSetMergeDocument(DatabaseRef_Region_Document(clientKey, rowData.key as string), updateObject)
                          .then((res_DSMD) => {
                            tableHooks.uc_setUserInterface_CustomDialogDisplay(UserInterface_Default_CustomDialogDisplayState)
                            resolve(res_DSMD)
                          })
                          .catch((rej_DSMD) => {
                            reject(rej_DSMD)
                          })
                      })
                      .catch((rej_SUF) => {
                        console.error(rej_SUF)
                        reject(rej_SUF)
                      })
                  } else {
                    reject({
                      success: false,
                      error: {
                        message: s_FAILED_TO_UPLOAD_FILE,
                        details: s_MISSING_REQUIRED_KEYS,
                        code: 'ER-D-RL-FOS-01',
                      },
                    })
                  }
                })
              } else {
                reject({
                  success: false,
                  error: {
                    message: s_FAILED_TO_UPLOAD_FILE,
                    details: s_INVALID_DOCUMENT_SELECTION,
                    code: 'ER-D-RL-FOS-02',
                  },
                })
              }
            })
          }
          const openRegionPhotoDialog = () => {
            getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
              .then((res_GCK) => {
                let profilePicturePreview = <></>
                if (rowData != null && rowData.region_photo_url != null) {
                  profilePicturePreview = (
                    <Box className="tw-text-center">
                      <Avatar
                        variant="rounded"
                        className="tw-mx-auto tw-my-2"
                        // @ts-expect-error - TODO: reason for error
                        src={rowData.region_photo_url}
                        sx={{ width: 300, height: 300 }}
                      />
                    </Box>
                  )
                } else {
                  profilePicturePreview = <Typography variant="h6">{s_NO_REGION_PICTURE_UPLOADED}</Typography>
                }
                let dialogJSX = (
                  <Box>
                    <Dialog
                      className="bp_dialog_md_width"
                      keepMounted
                      onClose={() => {
                        tableHooks.uc_setUserInterface_CustomDialogDisplay(UserInterface_Default_CustomDialogDisplayState)
                      }}
                      open={true}
                    >
                      <AppBar position="static">
                        <Toolbar>
                          <IconButton
                            aria-label="menu"
                            color="inherit"
                            disabled
                            edge="start"
                            size="large"
                            sx={{ mr: 2, color: '#fff !important' }}
                          >
                            <Icon icon="compass" />
                          </IconButton>
                          <Typography
                            component={'span'}
                            variant={'h6'}
                            sx={{ flexGrow: 1 }}
                          >
                            <Box className="tw-inline-block">{rowData.name}</Box>
                          </Typography>
                        </Toolbar>
                      </AppBar>
                      <DialogContent sx={{ padding: '0px' }}>
                        <Box className="tw-p-4 tw-text-center">
                          {profilePicturePreview}
                          <FileUploadButton
                            multiple={true}
                            accept="image/*"
                            onChange={fileOnSelect}
                            button={{
                              text: s_UPLOAD_PHOTO,
                              icon: (
                                <Icon
                                  icon="cloud-arrow-up"
                                  className="tw-mr-2"
                                ></Icon>
                              ),
                              color: 'info',
                              className: '',
                              variant: 'contained',
                              disabled: false,
                            }}
                            additionalFileUploadParams={{
                              clientKey: res_GCK.clientKey,
                              // region: getProp( uc_RootData_GlobalUser, "key", null ),
                            }}
                          />
                        </Box>
                      </DialogContent>
                    </Dialog>
                  </Box>
                )
                // Open Dialog
                tableHooks.uc_setUserInterface_CustomDialogDisplay({
                  display: true,
                  dialog: {
                    dialog_jsx: dialogJSX,
                    settings: {
                      max_width: 'lg',
                    },
                  },
                })
              })
              .catch((rej_GCK) => {
                console.error(rej_GCK)
              })
          }
          // Open Dialog
          openRegionPhotoDialog()
        }
      },
    },
    delete: {
      icon: (
        <Icon
          icon="trash"
          type="solid"
        />
      ),
      label: <>{s_DELETE}</>,
      conditional_display: {
        active: true,
        logic_type: 'comparison',
        source: 'rowData',
        prop: 'status',
        comparator: '!=',
        value: 'deleted',
        conditions: [],
      },
      onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        if (rowData != null && rowData.key != null && tableHooks != null && tableHooks.uc_RootData_ClientKey != null) {
          tableHooks.uc_setUserInterface_ConfirmDialogDisplay({
            display: true,
            confirm: {
              color: 'error',
              header: s_DELETE_REGION,
              icon: (
                <Icon
                  icon="trash"
                  type="solid"
                />
              ),
              submit_text: s_DELETE,
              text: s_ARE_YOU_SURE_THAT_YOU_WANT_TO_DELETE_THIS_REGION,
              submit_callback: () => {
                return new Promise((resolve, reject) => {
                  let updateObject = {
                    status: 'deleted',
                  }
                  DatabaseSetMergeDocument(DatabaseRef_Region_Document(tableHooks.uc_RootData_ClientKey, rowData.key as string), updateObject)
                    .then((res_DSMD) => {
                      // TODO - Log
                      resolve({ success: true })
                    })
                    .catch((rej_DSMD) => {
                      tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                      reject(rej_DSMD)
                    })
                })
              },
            },
          })
        }
      },
    },
    undelete: {
      icon: (
        <Icon
          type="solid"
          icon="trash-undo"
        />
      ),
      label: <>{s_UNDELETE}</>,
      conditional_display: {
        active: true,
        logic_type: 'comparison',
        source: 'rowData',
        prop: 'status',
        comparator: '==',
        value: 'deleted',
        conditions: [],
      },
      onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        if (rowData.key != null) {
          tableHooks.uc_setUserInterface_ConfirmDialogDisplay({
            display: true,
            confirm: {
              color: 'info',
              header: s_UNDELETE_REGION,
              icon: (
                <Icon
                  icon="trash-undo"
                  type="solid"
                />
              ),
              submit_text: s_UNDELETE,
              text: s_ARE_YOU_SURE_THAT_YOU_WANT_TO_UNDELETE_THIS_REGION,
              submit_callback: () => {
                return new Promise((resolve, reject) => {
                  getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                    .then((res_GCK) => {
                      let updateObject = {
                        status: 'active',
                      }
                      DatabaseSetMergeDocument(DatabaseRef_Region_Document(res_GCK.clientKey, rowData.key as string), updateObject)
                        .then((res_DSMD) => {
                          resolve({ success: true })
                        })
                        .catch((rej_DSMD) => {
                          tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                        })
                    })
                    .catch((rej_GCK) => {
                      tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                    })
                })
              },
            },
          })
        }
      },
    },
  }),
  name: TableCellBasic('name', s_REGION_NAME, 'name'),
  location_address: TableCellBasic('location_address', rLIB('Address'), 'location_address'),
  location_city: TableCellBasic('location_city', s_CITY, 'location_city'),
  location_state: TableCellBasic('location_state', s_STATE, 'location_state'),
  location_zip: TableCellBasic('location_zip', s_ZIP, 'location_zip'),
}

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Container
///////////////////////////////

export const Container: React.FC = (): JSX.Element => {
  // Props

  // Hooks - useContext, useState, useReducer, other
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)
  const { uc_setUserInterface_ErrorDialogDisplay } = useContext(Context_UserInterface_ErrorDialog)
  const { uc_setUserInterface_PromptDialogDisplay } = useContext(Context_UserInterface_PromptDialog)

  // Hooks - useEffect

  useEffect(() => {
    document.title = se_REGIONS
  }, [])

  useEffect(() => {
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
    return () => {}
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

  // Other Variables
  const tableAdditionalData_Regions: TsInterface_TableAdditionalData = {}

  // Functions
  const tableDatabaseEndpoint_Regions = (
    queryGenerationData: TsInterface_TableDatabaseEndpointQueryObject,
    tableAdditionalData: TsInterface_TableAdditionalData,
  ) => {
    let queryOperatorsArray: TsInterface_QueryOperatorsArray = [{ prop: 'status', comparator: '==', value: 'active' }]
    let orderByArray: TsInterface_OrderByArray = [{ prop: 'name', desc: false }]
    let queryCursorsObject: TsInterface_QueryCursorsObject = {}
    if (queryGenerationData['startAfter'] != null) {
      queryCursorsObject['startAfter'] = queryGenerationData.startAfter
    }
    if (queryGenerationData['startAt'] != null) {
      queryCursorsObject['startAt'] = queryGenerationData.startAt
    }
    if (queryGenerationData['endAt'] != null) {
      queryCursorsObject['endAt'] = queryGenerationData.endAt
    }
    if (queryGenerationData['endBefore'] != null) {
      queryCursorsObject['endBefore'] = queryGenerationData.endBefore
    }
    let limit = getProp(queryGenerationData, 'limit', 100)
    return generateDatabaseQuery(DatabaseRef_Regions_Collection(uc_RootData_ClientKey as string), queryOperatorsArray, orderByArray, queryCursorsObject, limit)
  }

  const tableSearchEndpoint_Regions = (searchProperty: string, searchInput: string) => {
    let limit = tableSettings_Regions.rows_per_page
    if (limit === null) {
      limit = 20
    }
    return DatabaseRef_Regions_Search(uc_RootData_ClientKey as string, searchProperty, searchInput, limit)
  }

  const promptObject: TsInterface_PromptDialogObject = {
    color: 'info',
    confirm_text: s_CREATE,
    default_value: null,
    header: s_CREATE_NEW_REGION,
    icon: (
      <Icon
        icon="sitemap"
        type="solid"
      />
    ),
    input_label: s_REGION_NAME,
    input_type: 'text',
    text: s_ENTER_A_NAME_FOR_YOUR_NEW_REGION,
    submit_callback: (promptValue: string) => {
      return new Promise((resolve, reject) => {
        let updateObject = {
          name: promptValue,
          status: 'active',
        }
        getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
          .then((res_GCK) => {
            DatabaseAddDocument(DatabaseRef_Regions_Collection(res_GCK.clientKey), updateObject, true)
              .then((res_DAD) => {
                // Nothing
              })
              .catch((rej_DAD) => {
                uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DAD.error })
              })
          })
          .catch((rej_GCK) => {
            uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
          })
        resolve({ success: true })
      })
    },
  }

  // JSX Generation
  const rJSX_Table = (): JSX.Element => {
    let tableJSX = <></>
    if (tableSettings_Regions.search_settings_database == null) {
      tableSettings_Regions.search_settings_database = { search_type: 'firebase' }
    }
    tableSettings_Regions.search_settings_database.search_endpoint = tableSearchEndpoint_Regions
    if (uc_RootData_ClientKey != null) {
      tableJSX = (
        <Card className="tw-mt-4">
          <TableDatabase
            tableAdditionalData={tableAdditionalData_Regions}
            tableColumns={tableColumns_Regions}
            tableDatabaseEndpoint={tableDatabaseEndpoint_Regions}
            tableSettings={tableSettings_Regions}
          />
        </Card>
      )
    } else {
      tableJSX = <></>
    }
    return tableJSX
  }

  const rJSX_Page = (): JSX.Element => {
    let pageJSX = (
      <AuthenticatedContainer
        pageHeader={s_REGIONS}
        pageKey={pageKey}
        content={
          <Box>
            <Button
              color="success"
              variant="contained"
              onClick={() => {
                uc_setUserInterface_PromptDialogDisplay({
                  display: true,
                  prompt: promptObject,
                })
              }}
              disableElevation
              startIcon={
                <Icon
                  icon="circle-plus"
                  type="solid"
                />
              }
            >
              {s_NEW_REGION}
            </Button>
            {rJSX_Table()}
          </Box>
        }
      />
    )
    return pageJSX
  }

  // Render
  return <>{rJSX_Page()}</>
}
