import { Box, Typography } from '@mui/material'
import { TsInterface_DataViewerAdditionalData, TsInterface_DataViewerLines, ViewerLineSpacer } from 'rfbp_core/components/data_viewer'
import { rLIB } from 'rfbp_core/localization/library'
import { TsInterface_UnspecifiedObject } from 'rfbp_core/typescript/global_types'

export const viewerLines_Team: TsInterface_DataViewerLines = {
  // Team Name
  team_name: {
    line_jsx: (teamData: TsInterface_UnspecifiedObject) => (
      <Box>
        <Typography variant="h6">
          <strong>{rLIB('Team Name:')}</strong> {teamData.name ? teamData.name : 'Unnamed Team'}
        </Typography>
      </Box>
    ),
  },

  // Associated Members
  associated_members: {
    line_jsx: (teamData: TsInterface_UnspecifiedObject) => {
      const memberNames = teamData.associated_member_names ? Object.values(teamData.associated_member_names).join(', ') : 'No Members'

      return (
        <Box>
          <Typography variant="h6">
            <strong>{rLIB('Team Members:')}</strong> {memberNames}
          </Typography>
        </Box>
      )
    },
  },

  // Region
  region: {
    line_jsx: (teamData: TsInterface_UnspecifiedObject, viewerAdditionalData: TsInterface_DataViewerAdditionalData) => (
      <Box>
        <Typography variant="h6">
          <strong>{rLIB('Region:')}</strong> {typeof viewerAdditionalData.regionName === 'string' ? viewerAdditionalData.regionName : 'No Region'}
        </Typography>
      </Box>
    ),
  },

  spacer1: ViewerLineSpacer(4),
}

export const viewerSettings_Team = {}
