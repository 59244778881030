//////////////////////////////////////////
//		  ooOOOO BOILERPLATE FILE		//
//		 oo		 _____					//
//		_I__n_n__||_|| ________			//
//	  >(_________|_7_|-|______|			//
//	   /o ()() ()() o   oo  oo			//
//////////////////////////////////////////

///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			Used to render data

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Box } from '@mui/material/'
import { useContext, useReducer } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  TsInterface_DataViewerAdditionalData,
  TsInterface_DataViewerData,
  TsInterface_DataViewerLine,
  TsInterface_DataViewerLines,
  TsInterface_DataViewerSettings,
} from 'rfbp_core/components/data_viewer'
import { evaluateConditionLogic } from 'rfbp_core/components/logic'
import {
  Context_RootData_ClientKey,
  Context_RootData_ClientPermissions,
  Context_RootData_ClientUser,
  Context_RootData_GlobalUser,
  Context_UserInterface_AlertDialog,
  Context_UserInterface_ConfirmDialog,
  Context_UserInterface_CustomDialog,
  Context_UserInterface_ErrorDialog,
  Context_UserInterface_FormDialog,
  Context_UserInterface_LoadingBar,
  Context_UserInterface_PromptDialog,
} from 'rfbp_core/services/context'
import { getProp, objectToArray } from 'rfbp_core/services/helper_functions'

import { TsInterface_DataViewerHooksObject } from './data_viewer_types'

///////////////////////////////
// Typescript
///////////////////////////////

interface TsInterface_ComponentProps {
  viewerAdditionalData: TsInterface_DataViewerAdditionalData
  viewerData: TsInterface_DataViewerData
  viewerLines: TsInterface_DataViewerLines
  viewerSettings: TsInterface_DataViewerSettings
}

///////////////////////////////
// Variables
///////////////////////////////

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Component
///////////////////////////////

export const DataViewer = (props: TsInterface_ComponentProps): JSX.Element => {
  // Props
  let pr_viewerAdditionalData: TsInterface_DataViewerAdditionalData = getProp(props, 'viewerAdditionalData', {})
  let pr_viewerData: TsInterface_DataViewerData = getProp(props, 'viewerData', {})
  let pr_viewerLines: TsInterface_DataViewerLines = getProp(props, 'viewerLines', {})
  let pr_viewerSettings: TsInterface_DataViewerSettings = getProp(props, 'viewerSettings', {})

  // Hooks - useContext, useState, useReducer, other
  // { sort-start } - hooks
  const un_routerNavigation = useNavigate()
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)
  const { uc_RootData_ClientPermissions } = useContext(Context_RootData_ClientPermissions)
  const { uc_RootData_ClientUser } = useContext(Context_RootData_ClientUser)
  const { uc_RootData_GlobalUser } = useContext(Context_RootData_GlobalUser)
  const { uc_setUserInterface_AlertDialogDisplay } = useContext(Context_UserInterface_AlertDialog)
  const { uc_setUserInterface_ConfirmDialogDisplay } = useContext(Context_UserInterface_ConfirmDialog)
  const { uc_setUserInterface_CustomDialogDisplay } = useContext(Context_UserInterface_CustomDialog)
  const { uc_setUserInterface_ErrorDialogDisplay } = useContext(Context_UserInterface_ErrorDialog)
  const { uc_setUserInterface_FormDialogDisplay } = useContext(Context_UserInterface_FormDialog)
  const { uc_setUserInterface_LoadingBarDisplay } = useContext(Context_UserInterface_LoadingBar)
  const { uc_setUserInterface_PromptDialogDisplay } = useContext(Context_UserInterface_PromptDialog)
  // { sort-end } - hooks

  // Hooks - useEffect

  // Other Variables
  const viewerHooks: TsInterface_DataViewerHooksObject = {
    uc_RootData_ClientKey: uc_RootData_ClientKey,
    uc_RootData_ClientPermissions: uc_RootData_ClientPermissions,
    uc_RootData_ClientUser: uc_RootData_ClientUser,
    uc_RootData_GlobalUser: uc_RootData_GlobalUser,
    ur_forceRerender: ur_forceRerender,
    un_routerNavigation: un_routerNavigation,
    uc_setRootData_ClientKey: uc_setRootData_ClientKey,
    uc_setUserInterface_AlertDialogDisplay: uc_setUserInterface_AlertDialogDisplay,
    uc_setUserInterface_ConfirmDialogDisplay: uc_setUserInterface_ConfirmDialogDisplay,
    uc_setUserInterface_CustomDialogDisplay: uc_setUserInterface_CustomDialogDisplay,
    uc_setUserInterface_ErrorDialogDisplay: uc_setUserInterface_ErrorDialogDisplay,
    uc_setUserInterface_FormDialogDisplay: uc_setUserInterface_FormDialogDisplay,
    uc_setUserInterface_LoadingBarDisplay: uc_setUserInterface_LoadingBarDisplay,
    uc_setUserInterface_PromptDialogDisplay: uc_setUserInterface_PromptDialogDisplay,
  }

  // Functions
  const returnLineCSS = (line: TsInterface_DataViewerLine): string => {
    let cellClassName = ''
    if (line != null && line.line_css != null) {
      cellClassName = line.line_css(pr_viewerData, pr_viewerAdditionalData)
    }
    if (pr_viewerSettings != null && pr_viewerSettings.conditional_line_styles != null) {
      for (let loopConditionIndex in pr_viewerSettings.conditional_line_styles) {
        let loopCondition = pr_viewerSettings.conditional_line_styles[loopConditionIndex]
        if (evaluateConditionLogic(loopCondition.conditional_display, { viewerData: pr_viewerData }) === true) {
          cellClassName += ' '
          cellClassName += loopCondition.className
        }
      }
    }
    return cellClassName
  }

  // JSX Generation
  const rJSX_Component = (): JSX.Element => {
    let componentJSX = (
      <Box>
        {objectToArray(pr_viewerLines).map((line: TsInterface_DataViewerLine, lineIndex: number) => (
          <Box
            key={lineIndex}
            className={returnLineCSS(line)}
          >
            {line.line_jsx(pr_viewerData, pr_viewerAdditionalData, viewerHooks)}
          </Box>
        ))}
      </Box>
    )
    return componentJSX
  }

  // Render
  return <>{rJSX_Component()}</>
}
