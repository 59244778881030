///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			just shows the query params passed in the url

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Box } from '@mui/material/'
import queryString from 'query-string'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { rJSX_StagingWarning } from 'rfbp_aux/containers/authenticated_container'
import { Json } from 'rfbp_core/components/code_display'
import { rLIB } from 'rfbp_core/localization/library'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

// Authenticated Nav Data

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Container
///////////////////////////////

export const Container: React.FC = (): JSX.Element => {
  // Props

  // const params = useParams()

  // Hooks - useContext, useState, useReducer, other
  // { sort-start } - hooks

  // { sort-end } - hooks

  // Hooks - useEffect
  useEffect(() => {
    document.title = rLIB('Query Params', false) as string
  }, [])

  // Functions

  // JSX Generation
  const rJSX_Page = (): JSX.Element => {
    let pageJSX = (
      <Box className="tw-p-8">
        {rJSX_StagingWarning()}
        <Json data={queryString.parse(useLocation().search)} />
      </Box>
    )

    return pageJSX
  }

  // Render
  return <>{rJSX_Page()}</>
}
