///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			containers are pages / views used in the app and are made up of components and can interact with services and models

		TODO:

			Loop through groups and file max number of items for bar graph and divide everything by that to get percents for bar charts
			Include numbers


	*/

///////////////////////////////
// Imports
///////////////////////////////

import {
  AppBar,
  Box,
  Button,
  Card,
  Dialog,
  DialogContent,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material/'
import Grid2 from '@mui/material/Unstable_Grid2/Grid2'
import { determineTaskColorStatus } from 'app/models/projects/project_services'
import Holidays from 'date-holidays'
import moment from 'moment-timezone'
import React, { useContext, useEffect, useReducer, useState } from 'react'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import { AuthenticatedContainer } from 'rfbp_aux/containers/authenticated_container'
import { ApplicationPages } from 'rfbp_aux/data/application_structure'
import { DatabaseRef_Regions_Collection } from 'rfbp_aux/services/database_endpoints/directory/regions'
import { DatabaseRef_GHL_Conversations_DateRange_Query, DatabaseRef_GHL_Users_Collection } from 'rfbp_aux/services/database_endpoints/integrations/ghl'
import {
  DatabaseRef_FeedbackByDateAndRegion_Query,
  DatabaseRef_Feedback_Collection,
  DatabaseRef_Feedback_Query,
} from 'rfbp_aux/services/database_endpoints/operations/feedback'
import {
  DatabaseRef_ActiveProtoProjects_Query,
  DatabaseRef_OpenProjects_Query,
  DatabaseRef_ProjectAggregateStats_Document,
  DatabaseRef_RecentlyCompletedProjects_Query,
} from 'rfbp_aux/services/database_endpoints/operations/projects'
import { DatabaseRef_OpenAndReadyTasks_Query, DatabaseRef_RecentlyCompletedTasks_Query } from 'rfbp_aux/services/database_endpoints/operations/tasks'
import { DatePicker } from 'rfbp_core/components/date_picker'
import {
  FeedbackNpsComments,
  FeedbackNpsGraph,
  TsInterface_FeedbackCommentsSettings,
  TsInterface_FeedbackData,
  TsInterface_FeedbackGraphSettings,
} from 'rfbp_core/components/feedback'
import { Icon } from 'rfbp_core/components/icons'
import {
  TableCellBasic,
  TableCellTimestamp,
  TsInterface_TableAdditionalData,
  TsInterface_TableColumns,
  TsInterface_TableDataRow,
  TsInterface_TableHooks,
  TsInterface_TableSettings,
} from 'rfbp_core/components/table'
import { TableBasic } from 'rfbp_core/components/table/table_basic'
import { TabsUrl, TsInterface_TabsSettingsUrl } from 'rfbp_core/components/tabs'
import { rLIB } from 'rfbp_core/localization/library'
import {
  Context_RootData_ClientKey,
  Context_UserInterface_CustomDialog,
  Context_UserInterface_ErrorDialog,
  UserInterface_Default_CustomDialogDisplayState,
} from 'rfbp_core/services/context'
import { DatabaseGetCollection, DatabaseGetLiveCollection, DatabaseGetLiveDocument, DatabaseSetReplaceDocument } from 'rfbp_core/services/database_management'
import {
  downloadCSV,
  dynamicSort,
  getProp,
  objectToArray,
  returnDateFromUnknownDateFormat,
  returnExactDaysBetweenDates,
  returnFormattedDate,
} from 'rfbp_core/services/helper_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject, TsType_UnknownPromise, TsType_VoidFunction } from 'rfbp_core/typescript/global_types'

///////////////////////////////
// Typescript
///////////////////////////////

type TsType_CSVCellContents = string | number

///////////////////////////////
// Variables
///////////////////////////////

// Authenticated Nav Data
const pageKey: string = ApplicationPages['AdminStatsListPage']['key']

// Displayed Translatable Strings
// { sort-start } - displayed text - scoped sort plugin
const se_STATS = 'Stats'
// { sort-end } - displayed text

///////////////////////////////
// Initialization
///////////////////////////////

const hd = new Holidays('US')

///////////////////////////////
// Functions
///////////////////////////////

const getMonthKey = (index: number) => {
  const keys = ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec']
  return keys[index]
}

const initializeFullYearData = (year: number) => {
  const fullYearData: TsInterface_UnspecifiedObject = {}
  const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
  monthNames.forEach((month, index) => {
    const key = getMonthKey(index)
    fullYearData[key] = { month: `${month}`, npsScore: 0, promoters: 0, passives: 0, detractors: 0, totalProjects: 0 }
  })
  return fullYearData
}

// const generateAggregateStatistics = ( projectStats: TsInterface_UnspecifiedObject ): TsInterface_UnspecifiedObject => {
// let cleanedProjectStats: TsInterface_UnspecifiedObject = {
// 	aggregate: {
// 		projects: {
// 			total: 0,
// 			risk_levels: {
// 				green: 0,
// 				yellow: 0,
// 				red: 0,
// 				gray: 0,
// 			}
// 		},
// 		tasks: {
// 			total: 0,
// 			color_statuses: {
// 				completed: 0,
// 				unassigned: 0,
// 				deleted: 0,
// 				not_ready: 0,
// 				ready_green: 0,
// 				ready_yellow: 0,
// 				ready_red: 0,
// 			}
// 		},
// 	},
// 	css_reps: {

// 	}
// }
// for( let loopProjectKey in projectStats ){
// 	let loopProject = projectStats[ loopProjectKey ]
// 	cleanedProjectStats["aggregate"]["projects"]["total"]++
// 	if( loopProject != null && loopProject["associated_customer_risk_status"] != null){
// 		cleanedProjectStats["aggregate"]["projects"]["risk_levels"][ loopProject["associated_customer_risk_status"] ]++
// 	}
// 	if( loopProject != null && loopProject["associated_css_rep_name"] != null ){
// 		if( cleanedProjectStats["css_reps"][ loopProject["associated_css_rep_name"] ] == null ){
// 			cleanedProjectStats["css_reps"][ loopProject["associated_css_rep_name"] ] = {
// 				projects: {
// 					total: 0,
// 					risk_levels: {
// 						green: 0,
// 						yellow: 0,
// 						red: 0,
// 						gray: 0,
// 					}
// 				},
// 				tasks: {
// 					total: 0,
// 					color_statuses: {
// 						completed: 0,
// 						unassigned: 0,
// 						deleted: 0,
// 						not_ready: 0,
// 						ready_green: 0,
// 						ready_yellow: 0,
// 						ready_red: 0,
// 					}
// 				},
// 			}
// 		}
// 		cleanedProjectStats["css_reps"][ loopProject["associated_css_rep_name"] ]["projects"]["total"]++
// 		if( loopProject["associated_customer_risk_status"] != null){
// 			cleanedProjectStats["css_reps"][ loopProject["associated_css_rep_name"] ]["projects"]["risk_levels"][ loopProject["associated_customer_risk_status"] ]++
// 		}
// 	}
// 	for( let loopTaskKey in loopProject.tasks ){
// 		let loopTask = loopProject.tasks[ loopTaskKey ]
// 		cleanedProjectStats["aggregate"]["tasks"]["total"]++
// 		if( loopTask != null && loopTask["color_status"] != null ){
// 			cleanedProjectStats["aggregate"]["tasks"]["color_statuses"][ loopTask["color_status"] ]++
// 		}
// 		if( loopProject != null && loopProject["associated_css_rep_name"] != null ){
// 			cleanedProjectStats["css_reps"][ loopProject["associated_css_rep_name"] ]["tasks"]["total"]++
// 			if( loopTask != null && loopTask["color_status"] != null){
// 				cleanedProjectStats["css_reps"][ loopProject["associated_css_rep_name"] ]["tasks"]["color_statuses"][ loopTask["color_status"] ]++
// 			}
// 		}
// 	}
// }
// return cleanedProjectStats
// }

const tabsSettings: TsInterface_TabsSettingsUrl = {
  baseUrl: ApplicationPages.AdminStatsListPage.url(),
  tabQueryParam: 'tab',
  overridePageTitle: true,
  basePageTitle: rLIB('Stats', false) as string,
}

const feedbackCommentsSettings: TsInterface_FeedbackCommentsSettings = {
  sort_order: 'timestamp_created',
  mapping: {
    rating: 'feedback_rating',
    name: 'associated_user_name',
    text: 'feedback_notes',
    date: 'timestamp_created',
    chips: {
      feedback_topic: {
        key: 'feedback_topic',
        // color: "inherit"
      },
    },
  },
  display_buttons: {
    associated_project_key: true,
  },
}

const feedbackGraphSettings: TsInterface_FeedbackGraphSettings = {
  show_graph_score: true,
}

const generateAggregateProjectStats = (clientKey: string, cutoffDate: Date): TsType_UnknownPromise => {
  return new Promise((resolve, reject) => {
    // Instantiate Variables
    let databasePromiseArray: TsType_UnknownPromise[] = []
    let protoProjects: TsInterface_UnspecifiedObject = {}
    let openProjects: TsInterface_UnspecifiedObject = {}
    let recentlyCompletedProjects: TsInterface_UnspecifiedObject = {}
    let openTasks: TsInterface_UnspecifiedObject = {}
    let recentlyCompletedTasks: TsInterface_UnspecifiedObject = {}
    // Get Data
    databasePromiseArray.push(
      DatabaseGetCollection(DatabaseRef_ActiveProtoProjects_Query(clientKey, null))
        .then((res_DGC) => {
          protoProjects = res_DGC.data
        })
        .catch((rej_DGC) => {
          console.error(rej_DGC)
        }),
    )
    databasePromiseArray.push(
      DatabaseGetCollection(DatabaseRef_OpenProjects_Query(clientKey))
        .then((res_DGC) => {
          openProjects = res_DGC.data
        })
        .catch((rej_DGC) => {
          console.error(rej_DGC)
        }),
    )
    databasePromiseArray.push(
      DatabaseGetCollection(DatabaseRef_RecentlyCompletedProjects_Query(clientKey, cutoffDate))
        .then((res_DGC) => {
          recentlyCompletedProjects = res_DGC.data
        })
        .catch((rej_DGC) => {
          console.error(rej_DGC)
        }),
    )
    // TODO - might need to pull aggregate tasks stats from projects
    databasePromiseArray.push(
      DatabaseGetCollection(DatabaseRef_OpenAndReadyTasks_Query(clientKey))
        .then((res_DGC) => {
          openTasks = res_DGC.data
        })
        .catch((rej_DGC) => {
          console.error(rej_DGC)
        }),
    )
    databasePromiseArray.push(
      DatabaseGetCollection(DatabaseRef_RecentlyCompletedTasks_Query(clientKey, cutoffDate))
        .then((res_DGC) => {
          recentlyCompletedTasks = res_DGC.data
        })
        .catch((rej_DGC) => {
          console.error(rej_DGC)
        }),
    )
    // After Data Loaded
    Promise.all(databasePromiseArray).finally(() => {
      // Generate Stats Object
      let projectAndStatsUpdateObject: TsInterface_UnspecifiedObject = {
        associated_css_reps: {},
        associated_task_owners: {},
        project_counts: {
          proto_projects: objectToArray(protoProjects).length,
          open_projects: objectToArray(openProjects).length,
          unassigned_projects: 0,
          recently_completed_projects: objectToArray(recentlyCompletedProjects).length,
        },
        project_breakdowns: {
          open_project_counts_by_css_rep: {},
          open_project_ids_by_css_rep: {}, // TODO
          completed_project_counts_by_css_rep: {},
          completed_project_ids_by_css_rep: {}, // TODO
        },
        task_counts: {
          // open_tasks: 0,
          ready_green_tasks: 0,
          ready_yellow_tasks: 0,
          ready_red_tasks: 0,
          recently_completed_tasks: objectToArray(recentlyCompletedTasks).length,
          // completed_green_tasks: 0, // TODO
          // completed_yellow_tasks: 0, // TODO
          // completed_red_tasks: 0, // TODO
        },
        task_breakdowns: {
          open_task_counts_with_no_owner_by_status: {
            // total_tasks: 0,
            ready_green_tasks: 0,
            ready_yellow_tasks: 0,
            ready_red_tasks: 0,
          },
          open_task_counts_by_owner_by_status: {},
          open_task_ids_by_owner_by_status: {}, // TODO
          // open_task_counts_by_owner: {},
          completed_task_counts_by_owner: {},
          completed_task_ids_by_owner: {}, // TODO
          open_task_counts_by_task_name_by_status: {},
        },
        version: 1,
        timestamp_last_updated: new Date(),
        timestamp_start_date: cutoffDate,
      }
      // Loop through projects
      for (let loopProjectKey in openProjects) {
        let loopProject = openProjects[loopProjectKey]
        if (loopProject != null) {
          if (loopProject.associated_css_rep_key != null) {
            // Build List of CSS Reps
            if (loopProject.associated_css_rep_name != null && projectAndStatsUpdateObject['associated_css_reps'][loopProject.associated_css_rep_key] == null) {
              projectAndStatsUpdateObject['associated_css_reps'][loopProject.associated_css_rep_key] = loopProject.associated_css_rep_name
            }
            // Increment project count for css rep
            if (projectAndStatsUpdateObject['project_breakdowns']['open_project_counts_by_css_rep'][loopProject.associated_css_rep_key] == null) {
              projectAndStatsUpdateObject['project_breakdowns']['open_project_counts_by_css_rep'][loopProject.associated_css_rep_key] = {
                active: 0,
              }
            }
            projectAndStatsUpdateObject['project_breakdowns']['open_project_counts_by_css_rep'][loopProject.associated_css_rep_key]['active']++
            if (projectAndStatsUpdateObject['project_breakdowns']['open_project_ids_by_css_rep'][loopProject.associated_css_rep_key] == null) {
              projectAndStatsUpdateObject['project_breakdowns']['open_project_ids_by_css_rep'][loopProject.associated_css_rep_key] = {}
            }
            projectAndStatsUpdateObject['project_breakdowns']['open_project_ids_by_css_rep'][loopProject.associated_css_rep_key][loopProjectKey] = {
              key: loopProject.key,
              id_number: loopProject.id_number,
              color: themeVariables.primary_main,
            }
          } else {
            // Increment Unassigned Projects
            projectAndStatsUpdateObject['project_counts']['unassigned_projects']++
          }
        }
      }
      for (let loopProjectKey in recentlyCompletedProjects) {
        let loopProject = recentlyCompletedProjects[loopProjectKey]
        if (loopProject != null) {
          if (loopProject.associated_css_rep_key != null) {
            // Build List of CSS Reps
            if (loopProject.associated_css_rep_name != null && projectAndStatsUpdateObject['associated_css_reps'][loopProject.associated_css_rep_key] == null) {
              projectAndStatsUpdateObject['associated_css_reps'][loopProject.associated_css_rep_key] = loopProject.associated_css_rep_name
            }
            // Increment project count for css rep
            if (projectAndStatsUpdateObject['project_breakdowns']['completed_project_counts_by_css_rep'][loopProject.associated_css_rep_key] == null) {
              projectAndStatsUpdateObject['project_breakdowns']['completed_project_counts_by_css_rep'][loopProject.associated_css_rep_key] = {
                completed: 0,
              }
            }
            projectAndStatsUpdateObject['project_breakdowns']['completed_project_counts_by_css_rep'][loopProject.associated_css_rep_key]['completed']++
            if (projectAndStatsUpdateObject['project_breakdowns']['completed_project_ids_by_css_rep'][loopProject.associated_css_rep_key] == null) {
              projectAndStatsUpdateObject['project_breakdowns']['completed_project_ids_by_css_rep'][loopProject.associated_css_rep_key] = {}
            }
            projectAndStatsUpdateObject['project_breakdowns']['completed_project_ids_by_css_rep'][loopProject.associated_css_rep_key][loopProjectKey] = {
              key: loopProject.key,
              id_number: loopProject.id_number,
              color: themeVariables.info_main,
            }
          }
        }
      }
      // Loop through tasks
      for (let loopTaskKey in openTasks) {
        let loopTask = openTasks[loopTaskKey]
        // Total Task Color Counts
        let loopTaskColorStatus = determineTaskColorStatus(loopTask)
        if (loopTaskColorStatus.active === true) {
          // projectAndStatsUpdateObject["task_counts"]["open_tasks"]++
          switch (loopTaskColorStatus.color_status) {
            case 'ready_green':
              projectAndStatsUpdateObject['task_counts']['ready_green_tasks']++
              break
            case 'ready_yellow':
              projectAndStatsUpdateObject['task_counts']['ready_yellow_tasks']++
              break
            case 'ready_red':
              projectAndStatsUpdateObject['task_counts']['ready_red_tasks']++
              break
          }
          // Build List of Task Owners
          if (loopTask.associated_owner_key != null) {
            if (loopTask.associated_owner_name != null && projectAndStatsUpdateObject['associated_task_owners'][loopTask.associated_owner_key] == null) {
              projectAndStatsUpdateObject['associated_task_owners'][loopTask.associated_owner_key] = loopTask.associated_owner_name
            }
            // Tasks by owner by status
            if (projectAndStatsUpdateObject['task_breakdowns']['open_task_counts_by_owner_by_status'][loopTask.associated_owner_key] == null) {
              projectAndStatsUpdateObject['task_breakdowns']['open_task_counts_by_owner_by_status'][loopTask.associated_owner_key] = {
                // total_tasks: 0,
                ready_green_tasks: 0,
                ready_yellow_tasks: 0,
                ready_red_tasks: 0,
              }
            }
            // Task IDs
            if (projectAndStatsUpdateObject['task_breakdowns']['open_task_ids_by_owner_by_status'][loopTask.associated_owner_key] == null) {
              projectAndStatsUpdateObject['task_breakdowns']['open_task_ids_by_owner_by_status'][loopTask.associated_owner_key] = {}
            }
            projectAndStatsUpdateObject['task_breakdowns']['open_task_ids_by_owner_by_status'][loopTask.associated_owner_key][loopTaskKey] = {
              key: getProp(loopTask, 'associated_project_key', null),
              id_number: loopTask.id_number,
            }
            // Color
            switch (loopTaskColorStatus.color_status) {
              case 'ready_green':
                projectAndStatsUpdateObject['task_breakdowns']['open_task_counts_by_owner_by_status'][loopTask.associated_owner_key]['ready_green_tasks']++
                projectAndStatsUpdateObject['task_breakdowns']['open_task_ids_by_owner_by_status'][loopTask.associated_owner_key][loopTaskKey]['color'] =
                  themeVariables.success_main
                break
              case 'ready_yellow':
                projectAndStatsUpdateObject['task_breakdowns']['open_task_counts_by_owner_by_status'][loopTask.associated_owner_key]['ready_yellow_tasks']++
                projectAndStatsUpdateObject['task_breakdowns']['open_task_ids_by_owner_by_status'][loopTask.associated_owner_key][loopTaskKey]['color'] =
                  themeVariables.warning_main
                break
              case 'ready_red':
                projectAndStatsUpdateObject['task_breakdowns']['open_task_counts_by_owner_by_status'][loopTask.associated_owner_key]['ready_red_tasks']++
                projectAndStatsUpdateObject['task_breakdowns']['open_task_ids_by_owner_by_status'][loopTask.associated_owner_key][loopTaskKey]['color'] =
                  themeVariables.error_main
                break
            }
          } else {
            // Tasks with no owner by status
            switch (loopTaskColorStatus.color_status) {
              case 'ready_green':
                projectAndStatsUpdateObject['task_breakdowns']['open_task_counts_with_no_owner_by_status']['ready_green_tasks']++
                break
              case 'ready_yellow':
                projectAndStatsUpdateObject['task_breakdowns']['open_task_counts_with_no_owner_by_status']['ready_yellow_tasks']++
                break
              case 'ready_red':
                projectAndStatsUpdateObject['task_breakdowns']['open_task_counts_with_no_owner_by_status']['ready_red_tasks']++
                break
            }
          }
          // Task Status by task name
          if (loopTask.name != null) {
            if (projectAndStatsUpdateObject['task_breakdowns']['open_task_counts_by_task_name_by_status'][loopTask.name] == null) {
              projectAndStatsUpdateObject['task_breakdowns']['open_task_counts_by_task_name_by_status'][loopTask.name] = {
                ready_green_tasks: 0,
                ready_yellow_tasks: 0,
                ready_red_tasks: 0,
              }
            }
            switch (loopTaskColorStatus.color_status) {
              case 'ready_green':
                projectAndStatsUpdateObject['task_breakdowns']['open_task_counts_by_task_name_by_status'][loopTask.name]['ready_green_tasks']++
                break
              case 'ready_yellow':
                projectAndStatsUpdateObject['task_breakdowns']['open_task_counts_by_task_name_by_status'][loopTask.name]['ready_yellow_tasks']++
                break
              case 'ready_red':
                projectAndStatsUpdateObject['task_breakdowns']['open_task_counts_by_task_name_by_status'][loopTask.name]['ready_red_tasks']++
                break
            }
          }
        }
      }
      for (let loopTaskKey in recentlyCompletedTasks) {
        let loopTask = recentlyCompletedTasks[loopTaskKey]
        // let loopTaskColorStatus = determineTaskColorStatus( loopTask )
        // Build List of Task Owners
        if (loopTask.associated_owner_key != null) {
          if (loopTask.associated_owner_name != null && projectAndStatsUpdateObject['associated_task_owners'][loopTask.associated_owner_key] == null) {
            projectAndStatsUpdateObject['associated_task_owners'][loopTask.associated_owner_key] = loopTask.associated_owner_name
          }
          // Tasks by owner
          if (projectAndStatsUpdateObject['task_breakdowns']['completed_task_counts_by_owner'][loopTask.associated_owner_key] == null) {
            projectAndStatsUpdateObject['task_breakdowns']['completed_task_counts_by_owner'][loopTask.associated_owner_key] = {
              completed: 0,
            }
          }
          projectAndStatsUpdateObject['task_breakdowns']['completed_task_counts_by_owner'][loopTask.associated_owner_key]['completed']++
          if (projectAndStatsUpdateObject['task_breakdowns']['completed_task_ids_by_owner'][loopTask.associated_owner_key] == null) {
            projectAndStatsUpdateObject['task_breakdowns']['completed_task_ids_by_owner'][loopTask.associated_owner_key] = {}
          }
          projectAndStatsUpdateObject['task_breakdowns']['completed_task_ids_by_owner'][loopTask.associated_owner_key][loopTaskKey] = {
            key: getProp(loopTask, 'associated_project_key', null),
            id_number: loopTask.id_number,
            color: themeVariables.info_main,
          }
        }
      }
      // Save to Database
      DatabaseSetReplaceDocument(DatabaseRef_ProjectAggregateStats_Document(clientKey), projectAndStatsUpdateObject)
        .then((res_DSRD) => {
          resolve(res_DSRD)
        })
        .catch((rej_DSRD) => {
          reject(rej_DSRD)
        })
    })
  })
}

// Function to calculate business hours between two timestamps
const calculateBusinessHours = (startUnix: number, endUnix: number): number => {
  const startTime = moment.tz(startUnix, 'America/Chicago')
  const endTime = moment.tz(endUnix, 'America/Chicago')
  let totalBusinessHours = 0
  // Ensure the start time is before the end time
  if (startTime.isAfter(endTime)) {
    throw new Error('Start time must be before end time.')
  }
  // Business hours are from 8 AM to 5 PM
  const businessStartHour = 8
  const businessEndHour = 17
  // Calculate business hours only on business days
  let current = startTime.clone()
  while (current.isBefore(endTime)) {
    if (isBusinessDay(current)) {
      const startOfDay = current.clone().startOf('day')
      const endOfDay = current.clone().endOf('day')
      let dailyStart = current.isSame(startTime, 'day') ? startTime : startOfDay.clone().hour(businessStartHour)
      let dailyEnd = current.isSame(endTime, 'day') ? endTime : endOfDay.clone().hour(businessEndHour).minute(0)
      if (dailyStart.isBefore(startOfDay.clone().hour(businessStartHour))) {
        dailyStart = startOfDay.clone().hour(businessStartHour)
      }
      if (dailyEnd.isAfter(endOfDay.clone().hour(businessEndHour))) {
        dailyEnd = endOfDay.clone().hour(businessEndHour)
      }
      if (dailyStart.isBefore(dailyEnd)) {
        const startHour = dailyStart.hour() + dailyStart.minute() / 60
        const endHour = dailyEnd.hour() + dailyEnd.minute() / 60
        totalBusinessHours += Math.max(0, endHour - startHour)
      }
    }
    current.add(1, 'days').startOf('day')
  }
  return parseFloat(totalBusinessHours.toFixed(1))
}

function getPublicHolidays(hd: Holidays, year: number): string[] {
  const holidays = hd.getHolidays(year)
  let publicHolidays: string[] = []
  for (let holiday of holidays) {
    if (holiday.type === 'public') {
      publicHolidays.push(holiday.date)
    }
  }
  return publicHolidays
}

function isBusinessDay(day: moment.Moment): boolean {
  const weekday = day.isoWeekday() < 6 // Monday (1) through Friday (5)
  const localDate = day.clone().tz('America/Chicago').toDate() // Ensuring the date is in local time for holiday check
  let publicHolidays = getPublicHolidays(hd, day.year())
  let stringDate = returnFormattedDate(localDate, 'YYYY-MM-DD') + ' 00:00:00'
  if (weekday === true && publicHolidays.indexOf(stringDate) === -1) {
    return true
  } else {
    return false
  }
}

const tableColumns_ResponseTimes: TsInterface_TableColumns = {
  // Customer Name
  // Assigned User
  // Last Message Date/Time
  // Last Message
  // Aging (Business Hours)
  // Average Response Time (Business Hours)

  contactName: TableCellBasic('contactName', rLIB('Customer Name'), 'contactName'),
  // assignedTo: TableCellBasic( "assignedTo", rLIB('Assigned To'), "assignedTo" ),
  assignedTo: {
    header: {
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rLIB('Assigned To')
      },
      header_sort_by: 'assignedTo',
    },
    cell: {
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = <></>
        if (
          tableAdditionalData != null &&
          tableAdditionalData.us_ghlUsers != null &&
          rowData != null &&
          rowData.assignedTo != null &&
          tableAdditionalData.us_ghlUsers[rowData.assignedTo as string] != null &&
          tableAdditionalData.us_ghlUsers[rowData.assignedTo as string].name != null
        ) {
          cellJSX = <Box>{tableAdditionalData.us_ghlUsers[rowData.assignedTo as string].name}</Box>
        }
        return cellJSX
      },
    },
  },
  CALCULATED_businessHours: {
    header: {
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rLIB('Elapsed Business Hours')
      },
      header_sort_by: 'CALCULATED_businessHours',
    },
    cell: {
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = <></>
        if (rowData != null && rowData.lastMessageDate != null) {
          let businessHours = calculateBusinessHours(rowData.lastMessageDate as number, new Date().getTime())
          cellJSX = <Box>{businessHours}</Box>
        }
        return cellJSX
      },
    },
  },
  lastMessageDate: TableCellTimestamp('lastMessageDate', rLIB('Last Message Time'), 'lastMessageDate', 'D MMM YYYY - h:mm a', false),
  // lastMessageBody: TableCellBasic('lastMessageBody', rLIB('Last Message'), 'lastMessageBody'),
  lastMessageBody: {
    header: {
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rLIB('Last Message')
      },
      header_sort_by: 'lastMessageBody',
    },
    cell: {
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = <></>
        let maxLength = 200
        if (rowData != null && rowData.lastMessageBody != null) {
          if (rowData.lastMessageBody.toString().length <= maxLength) {
            return <Box>{rowData.lastMessageBody}</Box>
          } else {
            return <Box>{rowData.lastMessageBody.toString().substring(0, maxLength) + '...'}</Box>
          }

          // cellJSX = <Box>{rowData.lastMessageBody}</Box>
        }
        return cellJSX
      },
    },
  },
}

const tableSettings_ResponseTimes: TsInterface_TableSettings = {
  paginated: true,
  pagination_rows_per_page_default: 100,
  pagination_rows_per_page_options: [10, 25, 50, 100],
  show_header: true,
  size: 'small',
  sortable: true,
  sort_direction: 'asc',
  sort_property_default: 'lastMessageDate',
}

///////////////////////////////
// Container
///////////////////////////////

export const Container: React.FC = (): JSX.Element => {
  // Props

  // Hooks - useContext, useState, useReducer, other
  // { sort-start } - hooks
  const [us_aggregateProjectStatsData, us_setAggregateProjectStatsData] = useState<TsInterface_FeedbackData>({})
  const [us_conversationEndTimestamp, us_setConversationEndTimestamp] = useState<number>(new Date().getTime() - 7200000)
  const [us_conversationStartTimestamp, us_setConversationStartTimestamp] = useState<number>(new Date().getTime() - 259200000)
  const [us_downloadingNPS, us_setDownloadingNPS] = useState<boolean>(false)
  const [us_feedbackData, us_setFeedbackData] = useState<TsInterface_FeedbackData>({})
  const [us_feedbackEndDate, us_setFeedbackEndDate] = useState<Date>(new Date(new Date().getFullYear(), new Date().getMonth() + 1, 1))
  const [us_feedbackStartDate, us_setFeedbackStartDate] = useState<Date>(new Date(new Date().getFullYear(), new Date().getMonth(), 1))
  const [us_ghlConversations, us_setGhlConversations] = useState<TsInterface_UnspecifiedObject>({})
  const [us_ghlUsers, us_setGhlUsers] = useState<TsInterface_UnspecifiedObject>({})
  const [us_loadedProjectStats, us_setLoadedProjectStats] = useState<boolean>(false)
  const [us_refreshingProjectStats, us_setRefreshingProjectStats] = useState<boolean>(false)
  const [us_npsYear, us_setNPSYear] = useState<number>(new Date().getFullYear())
  const [us_npsViewMonthlyOrQuarterly, us_setNPSViewMonthlyOrQuarterly] = useState('monthly')
  const [us_dataByQuarter, us_setDataByQuarter] = useState({})
  const [us_selectedRegion, us_setSelectedRegion] = useState<string>('ALL_REGIONS')
  const [us_regionLists, us_setRegionsLists] = useState<TsInterface_UnspecifiedObject>({})

  const [us_rawNPSData, us_setRawNPSData] = useState<TsInterface_UnspecifiedObject>({})
  const [us_groupedByMonthAndCalculatedNPSData, us_setGroupedByMonthAndCalculatedNPSData] = useState<TsInterface_UnspecifiedObject>({})
  //const un_routerNavigation = useNavigate()
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)
  const { uc_setUserInterface_CustomDialogDisplay } = useContext(Context_UserInterface_CustomDialog)
  const { uc_setUserInterface_ErrorDialogDisplay } = useContext(Context_UserInterface_ErrorDialog)
  // { sort-end } - hooks

  // Hooks - useEffect
  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setGhlUsers(newData)
      ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_GHL_Users_Collection(res_GCK.clientKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, ur_forceRerender, uc_setRootData_ClientKey, us_feedbackStartDate, us_feedbackEndDate])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    // Unix timestamp for 2 hours ago (in milliseconds)
    let twoHoursAgo = new Date().getTime() - 7200000
    // Unix timestamp for 72 hours ago (in milliseconds)
    let seventyTwoHoursAgo = new Date().getTime() - 259200000
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      let inboundConversations: TsInterface_UnspecifiedObject = {}
      for (let loopKey in newData) {
        let loopConversation = newData[loopKey]
        if (loopConversation['lastMessageDirection'] === 'inbound') {
          inboundConversations[loopKey] = loopConversation
        }
      }
      us_setGhlConversations(inboundConversations)
      us_setConversationStartTimestamp(seventyTwoHoursAgo)
      us_setConversationEndTimestamp(twoHoursAgo)
      ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveCollection(
          DatabaseRef_GHL_Conversations_DateRange_Query(res_GCK.clientKey, seventyTwoHoursAgo, twoHoursAgo),
          updateLiveData,
        )
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, ur_forceRerender, uc_setRootData_ClientKey, us_feedbackStartDate, us_feedbackEndDate])

  useEffect(() => {
    let unsubscribeLiveData: any
    const updateLiveData = (newData: any) => {
      us_setFeedbackData(newData)
      ur_forceRerender()
    }
    const startDate = us_feedbackStartDate instanceof Date ? us_feedbackStartDate : new Date(us_feedbackStartDate)
    const endDate = us_feedbackEndDate instanceof Date ? us_feedbackEndDate : new Date(us_feedbackEndDate)

    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_Feedback_Query(res_GCK.clientKey, startDate, endDate), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })

    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, ur_forceRerender, uc_setRootData_ClientKey, us_feedbackStartDate, us_feedbackEndDate])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setAggregateProjectStatsData(newData)
      us_setLoadedProjectStats(true)
      ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveDocument(DatabaseRef_ProjectAggregateStats_Document(res_GCK.clientKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, ur_forceRerender, uc_setRootData_ClientKey, us_feedbackStartDate, us_feedbackEndDate])

  // Get Regions
  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setRegionsLists(newData)
      ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_Regions_Collection(res_GCK.clientKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

  //Get NPS Data
  useEffect(() => {
    const startDate = new Date(`${us_npsYear}-01-01T00:00:00.000Z`)
    const endDate = new Date(`${us_npsYear}-12-31T23:59:59.999Z`)
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        let query
        if (us_selectedRegion === 'ALL_REGIONS') {
          query = DatabaseRef_Feedback_Query(res_GCK.clientKey, startDate, endDate)
        } else {
          query = DatabaseRef_FeedbackByDateAndRegion_Query(res_GCK.clientKey, startDate, endDate, us_selectedRegion)
        }
        return DatabaseGetCollection(query)
      })
      .then((data) => {
        us_setRawNPSData(data)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
  }, [us_npsYear, uc_RootData_ClientKey, uc_setRootData_ClientKey, us_selectedRegion])

  //Combine nps by month and calculate NPS score
  useEffect(() => {
    if (us_rawNPSData) {
      const groupedData: any = initializeFullYearData(us_npsYear)

      const getMonthName = (monthIndex: number): string => {
        const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
        return monthNames[monthIndex]
      }

      const getMonthKey = (monthIndex: number): string => {
        const monthKeys = ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec']
        return monthKeys[monthIndex]
      }

      objectToArray(us_rawNPSData.data).forEach((feedback: any) => {
        if (feedback.timestamp_created && feedback.timestamp_created.seconds) {
          const date = new Date(feedback.timestamp_created.seconds * 1000)
          const monthIndex = date.getMonth()
          const monthKey = getMonthKey(monthIndex)

          if (groupedData[monthKey] !== undefined) {
            if (feedback.feedback_rating !== null && feedback.feedback_rating !== undefined) {
              if (feedback.associated_user_name !== 'AJ') {
                if (!Array.isArray(groupedData[monthKey].feedbacks)) {
                  groupedData[monthKey].feedbacks = []
                }
                groupedData[monthKey].feedbacks.push(feedback.feedback_rating)
              }
            }
          }
        } else {
          console.warn('Timestamp or seconds is undefined for feedback:', feedback)
        }
      })

      const calculatedNPSData: any = {}
      Object.keys(groupedData).forEach((monthKey: string) => {
        const feedbacks: number[] = groupedData[monthKey].feedbacks || []
        const npsData = calculateNPS(feedbacks)
        const monthIndex = Object.keys(groupedData).indexOf(monthKey)
        calculatedNPSData[monthKey] = {
          month: getMonthName(monthIndex),
          ...npsData,
        }
      })

      const quarterlyData: any = {
        Q1: [],
        Q2: [],
        Q3: [],
        Q4: [],
      }

      // Aggregate feedbacks into quarters
      Object.keys(groupedData).forEach((monthKey: string) => {
        const feedbacks: number[] = groupedData[monthKey].feedbacks || []
        const monthIndex = Object.keys(groupedData).indexOf(monthKey)
        const quarterKey = getQuarter(new Date(`${us_npsYear}-${monthIndex + 1}-01`))
        quarterlyData[quarterKey] = quarterlyData[quarterKey].concat(feedbacks)
      })

      const calculatedQuarterlyData: any = {}
      Object.keys(quarterlyData).forEach((quarterKey: string) => {
        const feedbacks: number[] = quarterlyData[quarterKey]
        const npsData = calculateNPS(feedbacks)
        calculatedQuarterlyData[quarterKey] = {
          month: quarterKey,
          ...npsData,
        }
      })

      us_setGroupedByMonthAndCalculatedNPSData(calculatedNPSData)
      us_setDataByQuarter(calculatedQuarterlyData)
    }
  }, [us_rawNPSData, us_npsYear])

  // Functions

  const calculateNPS = (feedbacks: any) => {
    const total: any =
      feedbacks.filter((score: any) => score >= 9).length +
      feedbacks.filter((score: any) => score >= 7 && score <= 8).length +
      feedbacks.filter((score: any) => score <= 6).length
    const promoters = feedbacks.filter((score: any) => score >= 9).length
    const passives = feedbacks.filter((score: any) => score >= 7 && score <= 8).length
    const detractors = feedbacks.filter((score: any) => score <= 6).length
    return {
      npsScore: total > 0 ? parseFloat((((promoters - detractors) / total) * 100).toFixed(1)) : 0,
      promoters,
      passives,
      detractors,
      totalProjects: total,
    }
  }

  const getQuarter = (date: any) => {
    const month = date.getMonth() + 1 // getMonth() is zero-based
    if (month <= 3) return 'Q1'
    if (month <= 6) return 'Q2'
    if (month <= 9) return 'Q3'
    return 'Q4'
  }
  const getMonthKey = (index: number) => {
    const keys = ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec']
    return keys[index]
  }

  const initializeFullYearData = (year: number) => {
    const fullYearData: TsInterface_UnspecifiedObject = {}
    const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
    monthNames.forEach((month, index) => {
      const key = getMonthKey(index)
      fullYearData[key] = { month: `${month}`, npsScore: 0, promoters: 0, passives: 0, detractors: 0, totalProjects: 0 }
    })
    return fullYearData
  }

  const transformToFeedbackData = (rowData: any): TsInterface_FeedbackData => {
    // Transform rowData to match TsInterface_FeedbackData structure
    return {
      promoters: rowData.promoters,
      detractors: rowData.detractors,
      passives: rowData.passives,
      total: rowData.totalProjects,
      score: rowData.npsScore,
      // Add any additional fields required by TsInterface_FeedbackData
    }
  }

  const calendarFeedbackStartDateOnChange = (newCalendarDate: Date): TsType_UnknownPromise => {
    return new Promise((resolve, reject) => {
      us_setFeedbackStartDate(newCalendarDate)
      resolve({ success: true })
    })
  }

  const calendarFeedbackEndDateOnChange = (newCalendarDate: Date): TsType_UnknownPromise => {
    return new Promise((resolve, reject) => {
      us_setFeedbackEndDate(newCalendarDate)
      resolve({ success: true })
    })
  }

  const returnStatTypographySX = (number: number, colorIfNotZero: string): TsInterface_UnspecifiedObject => {
    let sx: TsInterface_UnspecifiedObject = { opacity: 0.15 }
    if (number !== 0) {
      sx = { color: colorIfNotZero }
    }
    return sx
  }

  const downloadNPSCSV = () => {
    return new Promise((resolve, reject) => {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          DatabaseGetCollection(DatabaseRef_Feedback_Collection(res_GCK.clientKey))
            .then((res_DGC) => {
              let csvData: TsType_CSVCellContents[][] = []
              let csvHeaders: TsType_CSVCellContents[] = ['Timestamp', 'Employee', 'Job Code', 'NPS', 'Notes', 'Stage', 'FSD']
              csvData.push(csvHeaders)
              for (let loopNPSKey in res_DGC.data) {
                let rowData: string[] = []
                let loopNPS = res_DGC.data[loopNPSKey]
                if (getProp(loopNPS, 'timestamp_created', null) !== null) {
                  let formattedDate = returnFormattedDate(getProp(loopNPS, 'timestamp_created', ''), 'M/D/YYYY')
                  rowData.push(formattedDate)
                } else {
                  rowData.push('')
                }
                rowData.push(getProp(loopNPS, 'associated_user_name', ''))
                rowData.push(getProp(loopNPS, 'associated_project_id_number', ''))
                rowData.push(getProp(loopNPS, 'feedback_rating', ''))
                rowData.push(getProp(loopNPS, 'feedback_notes', ''))
                rowData.push(getProp(loopNPS, 'feedback_topic', ''))
                rowData.push(getProp(loopNPS, 'feedback_additional_identifier', ''))
                csvData.push(rowData)
              }
              downloadCSV('NPSExport', csvData)
                .then((res_DC) => {
                  resolve(res_DC)
                })
                .catch((rej_DC) => {
                  reject(rej_DC)
                })
            })
            .catch((rej_DGC) => {
              reject(rej_DGC)
            })
        })
        .catch((rej_GCK) => {
          reject(rej_GCK)
        })
    })
  }

  // JSX Generation
  const rJSX_StartDatePicker = (): JSX.Element => {
    return (
      <Box className="tw-mr-2 tw-inline-block tw-align-top">
        <DatePicker
          key={'start_date'}
          datePickerText={rLIB('Start Date')}
          datePickerDate={us_feedbackStartDate}
          datePickerDisabled={false}
          datePickerSettings={{ thin_input: true }}
          datePickerDateOnChange={calendarFeedbackStartDateOnChange}
        />
      </Box>
    )
  }
  const rJSX_EndDatePicker = (): JSX.Element => {
    return (
      <Box className="tw-mr-2 tw-inline-block tw-align-top">
        <DatePicker
          key={'end_date'}
          datePickerText={rLIB('End Date')}
          datePickerDate={us_feedbackEndDate}
          datePickerDisabled={false}
          datePickerSettings={{ thin_input: true }}
          datePickerDateOnChange={calendarFeedbackEndDateOnChange}
        />
      </Box>
    )
  }

  const rJSX_FeedbackTab = (): JSX.Element => {
    let tabJSX = <></>
    tabJSX = (
      <Box className="tw-mt-1">
        <Box
          className="tw-m-auto"
          sx={{ maxWidth: '700px' }}
        >
          <FeedbackNpsGraph
            feedbackData={us_feedbackData}
            feedbackSettings={feedbackGraphSettings}
          />
          <FeedbackNpsComments
            feedbackData={us_feedbackData}
            feedbackSettings={feedbackCommentsSettings}
          />
        </Box>
      </Box>
    )
    return tabJSX
  }

  const npsGraphSettings: TsInterface_FeedbackGraphSettings = {
    show_graph_score: false,
    show_graph_labels: true,
  }

  const tableSettings_NPSTable: TsInterface_TableSettings = {
    paginated: false,
    show_header: true,
    sortable: false,
  }
  const tableColumns_NPSTable: TsInterface_TableColumns = {
    month: TableCellBasic('month', us_npsViewMonthlyOrQuarterly === 'monthly' ? rLIB('Month') : rLIB('Quarter'), 'month'),
    npsScore: {
      header: {
        header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => rLIB('NPS Score'),
        header_sort_by: 'npsScore',
      },
      cell: {
        cell_jsx: (rowData: any, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
          const content = rowData.totalProjects > 0 && rowData.totalProjects !== null ? rowData.npsScore : '-'
          return <>{content}</>
        },
      },
    },
    npsChart: {
      header: {
        header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => rLIB('NPS Chart'),
        header_sort_by: 'npsChart',
      },
      cell: {
        cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
          const feedbackData = transformToFeedbackData(rowData)
          return rJSX_NpsGraph(feedbackData, npsGraphSettings)
        },
      },
    },
  }

  const rJSX_MonthQuarterToggle = () => {
    let monthQuarterToggle = (
      <ToggleButtonGroup
        value={us_npsViewMonthlyOrQuarterly}
        exclusive
        onChange={(event, newValue) => {
          if (newValue !== null) {
            us_setNPSViewMonthlyOrQuarterly(newValue)
          }
        }}
        aria-label="View mode"
      >
        <ToggleButton
          size="small"
          value="monthly"
          aria-label="Monthly view"
        >
          {rLIB('Monthly')}
        </ToggleButton>
        <ToggleButton
          size="small"
          value="quarterly"
          aria-label="Quarterly view"
        >
          {rLIB('Quarterly')}
        </ToggleButton>
      </ToggleButtonGroup>
    )

    return monthQuarterToggle
  }

  const rJSX_NpsGraph = (npsData: any, feedbackSettings: TsInterface_FeedbackGraphSettings): JSX.Element => {
    const rJSX_BreakdownBarSegmentLabels = (label: number): JSX.Element => {
      return feedbackSettings.show_graph_labels ? <>{label}</> : <></>
    }

    const detractors = getProp(npsData, 'detractors', 0)
    const passives = getProp(npsData, 'passives', 0)
    const promoters = getProp(npsData, 'promoters', 0)
    const total = getProp(npsData, 'total', 0)

    if (total !== 0) {
      return (
        <Box
          sx={{ width: '100%', height: '40px', minWidth: '300px', overflow: 'hidden' }}
          className="tw-rounded-lg"
        >
          <Stack
            direction="row"
            sx={{ width: '100%' }}
          >
            <Tooltip
              title={`${detractors} Detractors`}
              placement="top"
            >
              <Box
                className="tw-text-center tw-py-2"
                sx={{
                  background: themeVariables.error_main,
                  width: total > 0 ? `${(detractors / total) * 100}%` : '0%',
                  height: '40px',
                }}
              >
                {rJSX_BreakdownBarSegmentLabels(detractors)}
              </Box>
            </Tooltip>
            <Tooltip
              title={`${passives} Passives`}
              placement="top"
            >
              <Box
                className="tw-text-center tw-py-2"
                sx={{
                  background: themeVariables.warning_main,
                  width: total > 0 ? `${(passives / total) * 100}%` : '0%',
                  height: '40px',
                }}
              >
                {rJSX_BreakdownBarSegmentLabels(passives)}
              </Box>
            </Tooltip>
            <Tooltip
              title={`${promoters} Promoters`}
              placement="top"
            >
              <Box
                className="tw-text-center tw-py-2"
                sx={{
                  background: themeVariables.success_main,
                  width: total > 0 ? `${(promoters / total) * 100}%` : '0%',
                  height: '40px',
                }}
              >
                {rJSX_BreakdownBarSegmentLabels(promoters)}
              </Box>
            </Tooltip>
          </Stack>
        </Box>
      )
    } else {
      return (
        <Box
          sx={{ width: '100%', height: '40px', minWidth: '300px', overflow: 'hidden', background: themeVariables.background_default }}
          className="tw-rounded-lg"
        ></Box>
      )
    }
  }
  const rJSX_NPSTab = (): JSX.Element => {
    const transformedData =
      us_npsViewMonthlyOrQuarterly === 'monthly' ? objectToArray(us_groupedByMonthAndCalculatedNPSData) : objectToArray(us_dataByQuarter || {})

    return (
      <Box>
        {rJSX_MonthQuarterToggle()}
        <Card className="tw-mt-2">
          <TableBasic
            tableAdditionalData={{}}
            tableColumns={tableColumns_NPSTable}
            tableData={transformedData}
            tableSettings={tableSettings_NPSTable}
          />
        </Card>
      </Box>
    )
  }

  const handleYearChange = (event: SelectChangeEvent<number>) => {
    us_setNPSYear(event.target.value as number)
  }

  const rJSX_YearPicker = (): JSX.Element => {
    const currentYear = new Date().getFullYear()
    const years = Array.from(new Array(currentYear - 2022 + 1), (val, index) => 2022 + index)

    return (
      <Box
        className="tw-mr-2 tw-inline-block tw-align-top"
        sx={{
          backgroundColor: 'rgb(39, 43, 52)',
          borderRadius: '4px',
        }}
      >
        <FormControl
          variant="outlined"
          fullWidth
        >
          <InputLabel id="year-picker-label">Select Year</InputLabel>
          <Select
            labelId="year-picker-label"
            id="year-picker"
            value={us_npsYear}
            onChange={handleYearChange}
            label={rLIB('Select Year')}
            className="tw-w-24 tw-h-9"
          >
            {years.map((year) => (
              <MenuItem
                key={year}
                value={year}
              >
                {year}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    )
  }

  const rJSX_RegionDropdown = (shrinkButton: boolean): JSX.Element => {
    let dropdownJSX = (
      <Box className="tw-inline-block tw-align-top">
        <FormControl className="bp_thin_select_input">
          <InputLabel id={'region_filter'}>{rLIB('Region')}</InputLabel>
          <Select
            id={'region_filter'}
            labelId={'region_filter'}
            color="primary"
            value={us_selectedRegion}
            label={rLIB('Region')}
            onChange={(event: any) => {
              if (event != null && event.target != null && event.target.value != null) {
                us_setSelectedRegion(event.target.value)
              }
            }}
            variant="outlined"
          >
            <MenuItem value={'ALL_REGIONS'}>{rLIB('All Regions')}</MenuItem>
            {objectToArray(us_regionLists)
              .sort(dynamicSort('name', null))
              .map((option: TsInterface_UnspecifiedObject, index: number) => (
                <MenuItem
                  key={index}
                  value={option['key']}
                >
                  {option['name']}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </Box>
    )
    return dropdownJSX
  }

  const rJSX_BarGraph = (
    unformattedData: TsInterface_UnspecifiedObject,
    idBreakdowns: TsInterface_UnspecifiedObject,
    renderConfig: TsInterface_UnspecifiedObject,
    mapKeyToNewField: boolean,
    mapFieldObject: TsInterface_UnspecifiedObject,
    sortOrder: 'label' | 'percent',
    sortDirection: 'asc' | 'desc',
    dialogHeader: JSX.Element,
  ): JSX.Element => {
    let graphJSX = <></>
    let formattedBarGraphData: TsInterface_UnspecifiedObject = {}
    let maxGraphCount = 0
    // Determine max graph count
    for (let loopItemKey in unformattedData) {
      let loopItem = unformattedData[loopItemKey]
      let loopTotal = 0
      for (let loopRenderKey in renderConfig) {
        if (loopItem[loopRenderKey] != null) {
          loopTotal += loopItem[loopRenderKey]
        }
      }
      if (loopTotal > maxGraphCount) {
        maxGraphCount = loopTotal
      }
    }
    // Generate Graph Bars with correct lengths
    for (let loopItemKey in unformattedData) {
      let loopItem = unformattedData[loopItemKey]
      let formattedLoopItem: TsInterface_UnspecifiedObject = {
        label: null,
        percent: 0,
        bars: {},
        data: getProp(idBreakdowns, loopItemKey, {}),
      }
      if (mapKeyToNewField === true && mapFieldObject[loopItemKey] != null) {
        formattedLoopItem['label'] = mapFieldObject[loopItemKey]
      } else {
        formattedLoopItem['label'] = loopItemKey
      }
      let barOrder = 0
      for (let loopRenderKey in renderConfig) {
        if (loopItem[loopRenderKey] != null && loopItem[loopRenderKey] !== 0) {
          formattedLoopItem['bars'][loopRenderKey] = {
            color: renderConfig[loopRenderKey],
            count: loopItem[loopRenderKey],
            percent: loopItem[loopRenderKey] / maxGraphCount,
            order: barOrder,
          }
          barOrder++
          formattedLoopItem['percent'] += loopItem[loopRenderKey] / maxGraphCount
        }
      }
      formattedBarGraphData[loopItemKey] = formattedLoopItem
    }
    graphJSX = (
      <Box>
        {objectToArray(formattedBarGraphData)
          .sort(dynamicSort(sortOrder, sortDirection))
          .map((person: TsInterface_UnspecifiedObject, index: number) => (
            <Box key={index}>
              <Box>
                {person.label}
                <Box
                  className="tw-ml-2 hover:tw-opacity-100 tw-opacity-20 tw-cursor-pointer tw-inline-block"
                  onClick={() => {
                    setTimeout(() => {
                      // Build Dialog
                      let dialogJSX = (
                        <Box>
                          <Dialog
                            // TransitionComponent={ Transition }
                            className="bp_dialog_xl_width"
                            keepMounted
                            onClose={() => {
                              uc_setUserInterface_CustomDialogDisplay(UserInterface_Default_CustomDialogDisplayState)
                            }}
                            open={true}
                          >
                            <AppBar
                              position="static"
                              color="inherit"
                            >
                              <Toolbar>
                                <IconButton
                                  aria-label="menu"
                                  color="inherit"
                                  disabled
                                  edge="start"
                                  size="large"
                                  sx={{ mr: 2, color: '#fff !important' }}
                                >
                                  <Icon icon="barcode" />
                                </IconButton>
                                <Typography
                                  component={'span'}
                                  variant={'h6'}
                                  sx={{ flexGrow: 1 }}
                                >
                                  <Box className="tw-inline-block">{dialogHeader}</Box>
                                </Typography>
                              </Toolbar>
                            </AppBar>
                            <DialogContent sx={{ padding: '0px' }}>
                              {objectToArray(person.data)
                                .sort(dynamicSort('id_number', null))
                                .map((row, index) => (
                                  <Box
                                    key={index}
                                    className="tw-m-2 tw-inline-block tw-p-1 tw-rounded tw-cursor-pointer"
                                    sx={{ backgroundColor: getProp(row, 'color', themeVariables.background_json) }}
                                    onClick={() => {
                                      if (row.key != null) {
                                        let url = window.location.origin + ApplicationPages.AdminActiveProjectViewPage.url(row.key)
                                        window.open(url, '_blank')
                                      }
                                    }}
                                  >
                                    {row.id_number}
                                  </Box>
                                ))}
                            </DialogContent>
                          </Dialog>
                        </Box>
                      )
                      // Open Dialog
                      uc_setUserInterface_CustomDialogDisplay({
                        display: true,
                        dialog: {
                          dialog_jsx: dialogJSX,
                          settings: {
                            max_width: 'lg',
                          },
                        },
                      })
                    }, 1)
                  }}
                >
                  <Icon icon="magnifying-glass" />
                </Box>
              </Box>
              <Box sx={{ paddingLeft: '16px' }}>
                <Box
                  sx={{
                    background: themeVariables.background_default,
                    height: '32px',
                    width: '100%',
                    borderRadius: '8px',
                    overflow: 'hidden',
                    marginBottom: '8px',
                  }}
                >
                  {objectToArray(person.bars)
                    .sort(dynamicSort('order', 'asc'))
                    .map((barData: TsInterface_UnspecifiedObject, barIndex: number) => (
                      <Box
                        key={barIndex}
                        className="tw-inline-block"
                        sx={{ background: barData.color, width: barData.percent * 100 + '%', height: '32px' }}
                      >
                        <Box sx={{ marginLeft: '2px', marginTop: '4px' }}>{barData.count}</Box>
                      </Box>
                    ))}
                </Box>
              </Box>
            </Box>
          ))}
      </Box>
    )
    return graphJSX
  }

  const rJSX_ProjectStatsRefreshButton = (): JSX.Element => {
    let buttonJSX = (
      <Button
        color="inherit"
        className="tw-float-right"
        variant="outlined"
        startIcon={
          us_refreshingProjectStats ? (
            <Icon
              icon="arrows-rotate"
              className="bp_spin"
            />
          ) : (
            <Icon icon="arrows-rotate" />
          )
        }
        disabled={us_refreshingProjectStats}
        onClick={() => {
          us_setRefreshingProjectStats(true)
          let cutoffDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - 7)
          getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
            .then((res_GCK) => {
              generateAggregateProjectStats(res_GCK.clientKey, cutoffDate)
                .then((res_GAPS) => {
                  us_setRefreshingProjectStats(false)
                  // ur_forceRerender()
                })
                .catch((rej_GAPS) => {
                  console.error(rej_GAPS)
                  us_setRefreshingProjectStats(false)
                  // ur_forceRerender()
                })
            })
            .catch((rej_GCK) => {
              console.error(rej_GCK)
            })
        }}
      >
        {rLIB('Refresh Stats')}
      </Button>
    )
    return buttonJSX
  }

  const rJSX_ProjectStatsBanner = (): JSX.Element => {
    let bannerJSX = <></>
    let currentDate = new Date()
    if (us_aggregateProjectStatsData == null || us_aggregateProjectStatsData.timestamp_last_updated == null) {
      bannerJSX = (
        <Box
          className="tw-w-full tw-rounded-md tw-p-2 tw-mb-1"
          sx={{ background: themeVariables.error_main }}
        >
          <Typography
            sx={{ marginTop: '2px' }}
            variant="h6"
            className="tw-float-left"
          >
            <Icon
              icon="square-info"
              size="lg"
              className="tw-mr-1"
            />{' '}
            {rLIB('Project stats not generated yet')}
          </Typography>
          {rJSX_ProjectStatsRefreshButton()}
          <Box className="tw-clear-both"></Box>
        </Box>
      )
    } else if (
      us_aggregateProjectStatsData.timestamp_last_updated != null &&
      returnExactDaysBetweenDates(currentDate, returnDateFromUnknownDateFormat(us_aggregateProjectStatsData.timestamp_last_updated as Date)) > 1
    ) {
      bannerJSX = (
        <Box
          className="tw-w-full tw-rounded-md tw-p-2 tw-mb-1"
          sx={{ background: themeVariables.warning_main }}
        >
          <Typography
            sx={{ marginTop: '2px' }}
            variant="h6"
            className="tw-float-left"
          >
            <Icon
              icon="square-info"
              size="lg"
              className="tw-mr-1"
            />{' '}
            {rLIB('Project stats last updated')}: {returnFormattedDate(us_aggregateProjectStatsData.timestamp_last_updated, 'D MMM YYYY (h:mm a)')}
          </Typography>
          {rJSX_ProjectStatsRefreshButton()}
          <Box className="tw-clear-both"></Box>
        </Box>
      )
    }
    return bannerJSX
  }

  const rJSX_ProjectStatsFooter = (): JSX.Element => {
    let footerJSX = <></>
    // let currentDate = new Date()
    // if(
    // 	aggregateProjectStatsData == null ||
    // 	aggregateProjectStatsData.timestamp_last_updated == null
    // ){
    // } else if(
    // 	aggregateProjectStatsData.timestamp_last_updated != null &&
    // 	returnExactDaysBetweenDates( currentDate, returnDateFromUnknownDateFormat(aggregateProjectStatsData.timestamp_last_updated as Date ) ) > 1
    // ) {
    // } else {
    footerJSX = (
      <Box className="tw-opacity-20 tw-mt-2">
        {rJSX_ProjectStatsRefreshButton()}
        <Box className="tw-clear-both tw-mb-1"></Box>
      </Box>
    )
    // }
    return footerJSX
  }

  const rJSX_ProjectTab = (): JSX.Element => {
    let tabJSX = <></>
    const activeProjectsLabel = rLIB('Active Projects')
    const activeProjectsElement = typeof activeProjectsLabel === 'string' ? <>{activeProjectsLabel}</> : activeProjectsLabel
    const completedProjectsLabel = rLIB('Completed Projects')
    const completedProjectsElement = typeof completedProjectsLabel === 'string' ? <>{completedProjectsLabel}</> : completedProjectsLabel
    const activeTasksLabel = rLIB('Active Tasks')
    const activeTasksElement = typeof activeTasksLabel === 'string' ? <>{activeTasksLabel}</> : activeTasksLabel
    const completedTasksLabel = rLIB('Completed Tasks')
    const completedTasksElement = typeof completedTasksLabel === 'string' ? <>{completedTasksLabel}</> : completedTasksLabel

    if (us_loadedProjectStats === true && objectToArray(us_aggregateProjectStatsData).length > 0) {
      tabJSX = (
        <Box className="">
          {rJSX_ProjectStatsBanner()}
          <Box>
            <Card className="tw-p-2">
              <Box className="tw-mb-4">
                <Typography
                  variant="h5"
                  className="tw-font-bold tw-mb-4"
                >
                  {rLIB('Projects')}
                </Typography>
                <Grid2
                  container
                  spacing={2}
                >
                  <Grid2
                    xs={6}
                    md={3}
                    lg={3}
                  >
                    <Box className="tw-text-center">
                      <Typography
                        sx={returnStatTypographySX(getProp(us_aggregateProjectStatsData['project_counts'], 'proto_projects', 0), themeVariables.error_main)}
                        variant="h2"
                        className="tw-font-bold"
                      >
                        {getProp(us_aggregateProjectStatsData['project_counts'], 'proto_projects', 0)}
                      </Typography>
                      <Typography
                        sx={returnStatTypographySX(getProp(us_aggregateProjectStatsData['project_counts'], 'proto_projects', 0), themeVariables.error_main)}
                        variant="body1"
                      >
                        {rLIB('Unlinked Projects')}
                      </Typography>
                    </Box>
                  </Grid2>
                  <Grid2
                    xs={6}
                    md={3}
                    lg={3}
                  >
                    <Box className="tw-text-center">
                      <Typography
                        sx={returnStatTypographySX(
                          getProp(us_aggregateProjectStatsData['project_counts'], 'unassigned_projects', 0),
                          themeVariables.warning_dark,
                        )}
                        variant="h2"
                        className="tw-font-bold"
                      >
                        {getProp(us_aggregateProjectStatsData['project_counts'], 'unassigned_projects', 0)}
                      </Typography>
                      <Typography
                        sx={returnStatTypographySX(
                          getProp(us_aggregateProjectStatsData['project_counts'], 'unassigned_projects', 0),
                          themeVariables.warning_dark,
                        )}
                        variant="body1"
                      >
                        {rLIB('Unassigned Projects')}
                      </Typography>
                    </Box>
                  </Grid2>
                  <Grid2
                    xs={6}
                    md={3}
                    lg={3}
                  >
                    <Box className="tw-text-center">
                      <Typography
                        sx={returnStatTypographySX(getProp(us_aggregateProjectStatsData['project_counts'], 'open_projects', 0), themeVariables.primary_main)}
                        variant="h2"
                        className="tw-font-bold"
                      >
                        {getProp(us_aggregateProjectStatsData['project_counts'], 'open_projects', 0)}
                      </Typography>
                      <Typography
                        sx={returnStatTypographySX(getProp(us_aggregateProjectStatsData['project_counts'], 'open_projects', 0), themeVariables.primary_main)}
                        variant="body1"
                      >
                        {rLIB('Active Projects')}
                      </Typography>
                    </Box>
                  </Grid2>
                  <Grid2
                    xs={6}
                    md={3}
                    lg={3}
                  >
                    <Box className="tw-text-center">
                      <Typography
                        sx={returnStatTypographySX(
                          getProp(us_aggregateProjectStatsData['project_counts'], 'recently_completed_projects', 0),
                          themeVariables.info_main,
                        )}
                        variant="h2"
                        className="tw-font-bold"
                      >
                        {getProp(us_aggregateProjectStatsData['project_counts'], 'recently_completed_projects', 0)}
                      </Typography>
                      <Typography
                        sx={returnStatTypographySX(
                          getProp(us_aggregateProjectStatsData['project_counts'], 'recently_completed_projects', 0),
                          themeVariables.info_main,
                        )}
                        variant="body1"
                      >
                        {rLIB('Recently Completed Projects')}
                      </Typography>
                    </Box>
                  </Grid2>
                  <Grid2
                    xs={12}
                    md={6}
                    lg={6}
                  >
                    {}
                    <Box className="tw-text-center">
                      <Typography
                        variant="h6"
                        className="tw-opacity-50"
                      >
                        {rLIB('Active Projects by CSS Rep')}
                      </Typography>
                    </Box>
                    {}
                    {rJSX_BarGraph(
                      us_aggregateProjectStatsData.project_breakdowns.open_project_counts_by_css_rep,
                      us_aggregateProjectStatsData.project_breakdowns.open_project_ids_by_css_rep,
                      { active: themeVariables.primary_main },
                      true,
                      us_aggregateProjectStatsData.associated_css_reps,
                      'percent',
                      'desc',
                      activeProjectsElement,
                    )}
                  </Grid2>
                  <Grid2
                    xs={12}
                    md={6}
                    lg={6}
                  >
                    <Box className="tw-text-center">
                      <Typography
                        variant="h6"
                        className="tw-opacity-50"
                      >
                        {rLIB('Completed Projects by CSS Rep')}
                      </Typography>
                    </Box>
                    {rJSX_BarGraph(
                      us_aggregateProjectStatsData.project_breakdowns.completed_project_counts_by_css_rep,
                      us_aggregateProjectStatsData.project_breakdowns.completed_project_ids_by_css_rep,
                      { completed: themeVariables.info_main },
                      true,
                      us_aggregateProjectStatsData.associated_css_reps,
                      'percent',
                      'desc',
                      completedProjectsElement,
                    )}
                  </Grid2>
                </Grid2>
              </Box>
              <Divider className="tw-my-4" />
              <Box className="tw-mb-4">
                <Typography
                  variant="h5"
                  className="tw-font-bold tw-mb-4"
                >
                  {rLIB('Tasks')}
                </Typography>
                <Grid2
                  container
                  spacing={2}
                >
                  <Grid2
                    xs={6}
                    md={3}
                    lg={3}
                  >
                    <Box className="tw-text-center">
                      <Typography
                        sx={returnStatTypographySX(getProp(us_aggregateProjectStatsData['task_counts'], 'ready_green_tasks', 0), themeVariables.success_main)}
                        variant="h2"
                        className="tw-font-bold"
                      >
                        {getProp(us_aggregateProjectStatsData['task_counts'], 'ready_green_tasks', 0)}
                      </Typography>
                      <Typography
                        sx={returnStatTypographySX(getProp(us_aggregateProjectStatsData['task_counts'], 'ready_green_tasks', 0), themeVariables.success_main)}
                        variant="body1"
                      >
                        {rLIB('Active Green')}
                      </Typography>
                    </Box>
                  </Grid2>
                  <Grid2
                    xs={6}
                    md={3}
                    lg={3}
                  >
                    <Box className="tw-text-center">
                      <Typography
                        sx={returnStatTypographySX(getProp(us_aggregateProjectStatsData['task_counts'], 'ready_yellow_tasks', 0), themeVariables.warning_main)}
                        variant="h2"
                        className="tw-font-bold"
                      >
                        {getProp(us_aggregateProjectStatsData['task_counts'], 'ready_yellow_tasks', 0)}
                      </Typography>
                      <Typography
                        sx={returnStatTypographySX(getProp(us_aggregateProjectStatsData['task_counts'], 'ready_yellow_tasks', 0), themeVariables.warning_main)}
                        variant="body1"
                      >
                        {rLIB('Active Yellow Tasks')}
                      </Typography>
                    </Box>
                  </Grid2>
                  <Grid2
                    xs={6}
                    md={3}
                    lg={3}
                  >
                    <Box className="tw-text-center">
                      <Typography
                        sx={returnStatTypographySX(getProp(us_aggregateProjectStatsData['task_counts'], 'ready_red_tasks', 0), themeVariables.error_main)}
                        variant="h2"
                        className="tw-font-bold"
                      >
                        {getProp(us_aggregateProjectStatsData['task_counts'], 'ready_red_tasks', 0)}
                      </Typography>
                      <Typography
                        sx={returnStatTypographySX(getProp(us_aggregateProjectStatsData['task_counts'], 'ready_red_tasks', 0), themeVariables.error_main)}
                        variant="body1"
                      >
                        {rLIB('Active Red Tasks')}
                      </Typography>
                    </Box>
                  </Grid2>
                  <Grid2
                    xs={6}
                    md={3}
                    lg={3}
                  >
                    <Box className="tw-text-center">
                      <Typography
                        sx={returnStatTypographySX(
                          getProp(us_aggregateProjectStatsData['task_counts'], 'recently_completed_tasks', 0),
                          themeVariables.info_main,
                        )}
                        variant="h2"
                        className="tw-font-bold"
                      >
                        {getProp(us_aggregateProjectStatsData['task_counts'], 'recently_completed_tasks', 0)}
                      </Typography>
                      <Typography
                        sx={returnStatTypographySX(
                          getProp(us_aggregateProjectStatsData['task_counts'], 'recently_completed_tasks', 0),
                          themeVariables.info_main,
                        )}
                        variant="body1"
                      >
                        {rLIB('Recently Completed Tasks')}
                      </Typography>
                    </Box>
                  </Grid2>
                  <Grid2
                    xs={12}
                    md={6}
                    lg={6}
                  >
                    <Box className="tw-text-center">
                      <Typography
                        variant="h6"
                        className="tw-opacity-50"
                      >
                        {rLIB('Active Tasks by Owner')}
                      </Typography>
                    </Box>
                    {rJSX_BarGraph(
                      us_aggregateProjectStatsData.task_breakdowns.open_task_counts_by_owner_by_status,
                      us_aggregateProjectStatsData.task_breakdowns.open_task_ids_by_owner_by_status,
                      {
                        ready_green_tasks: themeVariables.success_main,
                        ready_yellow_tasks: themeVariables.warning_dark,
                        ready_red_tasks: themeVariables.error_main,
                      },
                      true,
                      us_aggregateProjectStatsData.associated_task_owners,
                      'percent',
                      'desc',
                      activeTasksElement,
                    )}
                  </Grid2>
                  <Grid2
                    xs={12}
                    md={6}
                    lg={6}
                  >
                    <Box className="tw-text-center">
                      <Typography
                        variant="h6"
                        className="tw-opacity-50"
                      >
                        {rLIB('Completed Tasks by Owner')}
                      </Typography>
                    </Box>
                    {rJSX_BarGraph(
                      us_aggregateProjectStatsData.task_breakdowns.completed_task_counts_by_owner,
                      us_aggregateProjectStatsData.task_breakdowns.completed_task_ids_by_owner,
                      { completed: themeVariables.info_main },
                      true,
                      us_aggregateProjectStatsData.associated_task_owners,
                      'percent',
                      'desc',
                      completedTasksElement,
                    )}
                  </Grid2>
                </Grid2>
              </Box>
              <Divider className="tw-my-4" />
              <Box className="tw-float-right tw-opacity-20">
                {rLIB('Project Stats')}: {returnFormattedDate(us_aggregateProjectStatsData.timestamp_start_date, 'D MMM YYYY')} -{' '}
                {returnFormattedDate(us_aggregateProjectStatsData.timestamp_last_updated, 'D MMM YYYY')}
              </Box>
            </Card>
          </Box>
          {rJSX_ProjectStatsFooter()}
        </Box>
      )
    } else if (us_loadedProjectStats === true && objectToArray(us_aggregateProjectStatsData).length === 0) {
      tabJSX = <Box>{rJSX_ProjectStatsBanner()}</Box>
    } else {
      tabJSX = <Box> </Box>
    }
    return tabJSX
  }

  const rJSX_NPSExportButton = (): JSX.Element => {
    let buttonJSX = (
      <Button
        variant="contained"
        color="success"
        startIcon={<Icon icon="file-arrow-down" />}
        disabled={us_downloadingNPS}
        onClick={() => {
          us_setDownloadingNPS(true)
          downloadNPSCSV()
            .then((res_DNC) => {
              us_setDownloadingNPS(false)
            })
            .catch((res_DNC) => {
              us_setDownloadingNPS(false)
              uc_setUserInterface_ErrorDialogDisplay({
                display: true,
                error: res_DNC.error,
              })
            })
        }}
      >
        {rLIB('Download NPS')}
      </Button>
    )
    return buttonJSX
  }

  const rJSX_ResponseTimeTab = (): JSX.Element => {
    let tabJSX = (
      <Box>
        <Typography
          variant="h6"
          className="tw-font-bold"
        >
          {rLIB('GHL Unanswered Conversations')}
        </Typography>
        <Typography
          variant="body1"
          className="tw-opacity-30 tw-mb-4"
        >
          {returnFormattedDate(us_conversationStartTimestamp, 'D MMM YY h:mma')} - {returnFormattedDate(us_conversationEndTimestamp, 'D MMM YY h:mma')}
        </Typography>
        <Card>
          <TableBasic
            tableAdditionalData={{
              us_ghlUsers: us_ghlUsers,
            }}
            tableColumns={tableColumns_ResponseTimes}
            tableData={objectToArray(us_ghlConversations)}
            tableSettings={tableSettings_ResponseTimes}
          />
        </Card>
      </Box>
    )
    return tabJSX
  }

  const rJSX_Page = (): JSX.Element => {
    let pageJSX = (
      <AuthenticatedContainer
        pageHeader={rLIB('Stats')}
        pageKey={pageKey}
        content={
          <Box>
            <TabsUrl
              tabs={[
                {
                  tabUrlKey: 'Projects',
                  tabHeader: rLIB('Projects'),
                  tabButtons: [
                    { fullJSX: rJSX_StartDatePicker(), minJSX: rJSX_StartDatePicker(), sizeCutoff: 0 },
                    { fullJSX: rJSX_EndDatePicker(), minJSX: rJSX_EndDatePicker(), sizeCutoff: 0 },
                  ],
                  tabContent: rJSX_ProjectTab(),
                },
                {
                  tabUrlKey: 'Feedback',
                  tabHeader: rLIB('Feedback'),
                  tabButtons: [
                    { fullJSX: rJSX_StartDatePicker(), minJSX: rJSX_StartDatePicker(), sizeCutoff: 0 },
                    { fullJSX: rJSX_EndDatePicker(), minJSX: rJSX_EndDatePicker(), sizeCutoff: 0 },
                    { fullJSX: rJSX_NPSExportButton(), minJSX: rJSX_NPSExportButton(), sizeCutoff: 0 },
                  ],
                  tabContent: rJSX_FeedbackTab(),
                },
                {
                  tabUrlKey: 'NPS',
                  tabHeader: rLIB('NPS'),
                  tabButtons: [
                    { fullJSX: rJSX_YearPicker(), minJSX: rJSX_YearPicker(), sizeCutoff: 0 },
                    { fullJSX: rJSX_RegionDropdown(false), minJSX: rJSX_RegionDropdown(true), sizeCutoff: 0 },
                  ],
                  tabContent: rJSX_NPSTab(),
                },
                {
                  tabUrlKey: 'Response_Time',
                  tabHeader: rLIB('Response Time'),
                  tabButtons: [
                    { fullJSX: rJSX_StartDatePicker(), minJSX: rJSX_StartDatePicker(), sizeCutoff: 0 },
                    { fullJSX: rJSX_EndDatePicker(), minJSX: rJSX_EndDatePicker(), sizeCutoff: 0 },
                  ],
                  tabContent: rJSX_ResponseTimeTab(),
                },
              ]}
              tabsSettings={tabsSettings}
            />
          </Box>
        }
      />
    )
    return pageJSX
  }

  // Render
  return <>{rJSX_Page()}</>
}
