/* eslint-disable react/prop-types */
///////////////////////////////
// Description
///////////////////////////////

///////////////////////////////
// Imports
///////////////////////////////

import { Box, Button } from '@mui/material'
import { RenderPageProps, Viewer, Worker } from '@react-pdf-viewer/core'
import '@react-pdf-viewer/core/lib/styles/index.css'
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout'
import '@react-pdf-viewer/default-layout/lib/styles/index.css'
import { saveAs } from 'file-saver'
import { PDFDocument } from 'pdf-lib'
import React, { useRef, useState } from 'react'

///////////////////////////////
// Typescript
///////////////////////////////

interface Annotation {
  text: string
  x: number
  y: number
  page: number
}

interface PdfEditorProps {
  pdfUrl: string
  initialAnnotations: Annotation[]
}

// CSS
let pageCSS: string = `
		.rpv-core__inner-page {
			background: #525659;
      // width: 1500px !important;
		}
    // .rpv-core__page-layer::after {
    //   box-shadow: none !important;
    // }
    .rpv-core__text-layer {
      top: 0;
      left: 0;
      position: relative
    }
	`

///////////////////////////////
// Variables
///////////////////////////////

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Exports
///////////////////////////////

export const LayeredPdfEditor: React.FC<PdfEditorProps> = ({ pdfUrl, initialAnnotations }) => {
  const [annotations, setAnnotations] = useState<Annotation[]>(initialAnnotations)
  const [selectedAnnotation, setSelectedAnnotation] = useState<Annotation | null>(null)
  const [menuPosition, setMenuPosition] = useState<{ top: number; left: number; x: number; y: number; page: number } | null>(null)
  const [pdfWidth, setPdfWidth] = useState<number>(800)
  const [initialLoaded, setInitialLoaded] = useState<boolean>(false) // To ensure that we only detect the PDF width once

  console.log()

  const defaultLayoutPluginInstance = defaultLayoutPlugin()
  const viewerRef = useRef<HTMLDivElement>(null)

  // useEffect(() => {
  //   const updatePdfWidth = () => {
  //     if (viewerRef.current) {
  //       const containerWidth = viewerRef.current.clientWidth
  //       setPdfWidth(containerWidth)
  //     }
  //   }
  //   updatePdfWidth()
  //   window.addEventListener('resize', updatePdfWidth)
  //   return () => {
  //     window.removeEventListener('resize', updatePdfWidth)
  //   }
  // }, [])

  const handlePageClick = (e: React.MouseEvent<HTMLDivElement>, page: number) => {
    const rect = (e.target as HTMLElement).getBoundingClientRect()
    const x = e.clientX - rect.left
    const y = e.clientY - rect.top

    // Use pdfWidth that is set after initial PDF render
    const scaleFactor = rect.width / pdfWidth

    console.log('<><><>')
    console.log(e.clientY)
    console.log(e.clientX)
    console.log(x / scaleFactor)
    console.log(y / scaleFactor)
    console.log(page)

    setMenuPosition({
      top: e.clientY,
      left: e.clientX,
      x: x / scaleFactor, // Adjust for scale
      y: y / scaleFactor, // Adjust for scale
      page: page,
    })
    setSelectedAnnotation(null)
  }

  const handleAddOrUpdateAnnotation = (text: string) => {
    if (menuPosition) {
      const newAnnotations = selectedAnnotation
        ? annotations.map((ann) => (ann === selectedAnnotation ? { ...ann, text } : ann))
        : [
            ...annotations,
            {
              text,
              x: menuPosition.x,
              y: menuPosition.y,
              page: menuPosition.page,
            },
          ]
      setAnnotations(newAnnotations)
      handleMenuClose()
    }
  }

  const handleMenuClose = () => {
    setMenuPosition(null)
    setSelectedAnnotation(null)
  }

  const handleDeleteAnnotation = () => {
    if (selectedAnnotation) {
      setAnnotations(annotations.filter((ann) => ann !== selectedAnnotation))
      handleMenuClose()
    }
  }

  const downloadPdf = async () => {
    const pdfBytes = await fetch(pdfUrl).then((res) => res.arrayBuffer())
    const pdfDoc = await PDFDocument.load(pdfBytes)
    const pages = pdfDoc.getPages()

    annotations.forEach(({ text, x, y, page }) => {
      const selectedPage = pages[page - 1]
      const { width, height } = selectedPage.getSize()
      const scaleFactor = pdfWidth / width

      selectedPage.drawText(text, {
        x: x / scaleFactor, // Adjust X based on scale
        y: height - y / scaleFactor, // Flip Y-coordinate
        size: 12,
      })
    })

    const mergedPdf = await pdfDoc.save()
    saveAs(new Blob([mergedPdf]), 'annotated.pdf')
  }

  // This function runs after the PDF has loaded, allowing us to measure the PDF's actual width.
  const handleDocumentLoad = () => {
    if (viewerRef.current && !initialLoaded) {
      const containerWidth = viewerRef.current.clientWidth
      setPdfWidth(containerWidth) // Set the width once when the PDF is initially loaded
      setInitialLoaded(true) // Mark as loaded to prevent further changes
    }
  }

  const renderPageLayer = (props: RenderPageProps) => {
    // Safely access the canvas layer's properties
    // const canvasWidth = props.canvasLayer?.children?.[0]?.props?.width || pdfWidth

    let pageWidth = pdfWidth
    if (
      props != null &&
      props.canvasLayer != null &&
      props.canvasLayer.children != null &&
      // @ts-expect-error
      props.canvasLayer.children[0] != null &&
      // @ts-expect-error
      props.canvasLayer.children[0].props != null &&
      // @ts-expect-error
      props.canvasLayer.children[0].props.width != null
    ) {
      // @ts-expect-error
      pageWidth = props.canvasLayer.children[0].props.width
    }
    const scaleFactor = pageWidth / pdfWidth

    return (
      <Box>
        <div
          style={{ position: 'relative', width: '100%', height: '100%', left: 0, top: 0 }}
          onClick={(e) => handlePageClick(e, props.pageIndex + 1)}
        >
          {props.canvasLayer.children}
          {props.annotationLayer.children}
          {props.textLayer.children}
          {annotations
            .filter((annotation) => annotation.page === props.pageIndex + 1)
            .map((annotation, index) => (
              <div
                key={index}
                style={{
                  position: 'absolute',
                  top: `${annotation.y * scaleFactor}px`,
                  left: `${annotation.x * scaleFactor}px`,
                  // transform: 'translate(-50%, -50%)',
                  // backgroundColor: 'rgba(255, 255, 255, 0)',
                  // border: '1px solid red',
                  // padding: '2px',
                  // borderRadius: '2px',
                  // cursor: 'pointer',
                  // color: 'black',
                  color: 'red',
                }}
                onClick={(e) => {
                  e.stopPropagation()
                  setSelectedAnnotation(annotation)
                  setMenuPosition({
                    top: e.clientY,
                    left: e.clientX,
                    x: annotation.x,
                    y: annotation.y,
                    page: annotation.page,
                  })
                }}
              >
                {annotation.text}
              </div>
            ))}
        </div>
      </Box>
    )
  }

  // Return JSX
  return (
    <div
      style={{ height: '100vh' }}
      ref={viewerRef}
    >
      <Button
        variant="contained"
        color="primary"
        className="tw-mb-2"
        onClick={() => {
          downloadPdf()
        }}
      >
        DOWNLOAD
      </Button>
      <div style={{ width: '1000px', margin: 'auto' }}>
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
          <Viewer
            fileUrl={pdfUrl}
            plugins={[defaultLayoutPluginInstance]}
            renderPage={renderPageLayer}
            onDocumentLoad={handleDocumentLoad} // Trigger when PDF is fully loaded
          />
        </Worker>
      </div>
      {/* <Menu
        anchorReference="anchorPosition"
        anchorPosition={menuPosition ? { top: menuPosition.top, left: menuPosition.left } : undefined}
        open={Boolean(menuPosition)}
        onClose={handleMenuClose}
      >
        <MenuItem>
          <TextField
            label="Text"
            defaultValue={selectedAnnotation ? selectedAnnotation.text : ''}
            onBlur={(e) => handleAddOrUpdateAnnotation(e.target.value)}
            autoFocus
          />
        </MenuItem>
        {selectedAnnotation && <MenuItem onClick={handleDeleteAnnotation}>Delete</MenuItem>}
      </Menu> */}
      <style>{pageCSS}</style>
    </div>
  )
}
