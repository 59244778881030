///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Box, Button, Card, Skeleton, Tooltip, Typography } from '@mui/material'
import {
  addTaskWorkflowToProject,
  assignUserToProjectTaskRole,
  determineTaskColorStatusSingle,
  editProjectStatus,
  openProjectIDGenerationDialog,
  removeWorkflowFromProject,
  rJSX_TaskBreakdownBarFullColors,
} from 'app/models/projects/project_services'
import { Trans } from 'react-i18next'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import { ApplicationPages } from 'rfbp_aux/data/application_structure'
import { DatabaseRef_TaskWorkflowsActive_Query } from 'rfbp_aux/services/database_endpoints/directory/task_workflows'
import { DatabaseRef_UsersWithTaskRole_Query, DatabaseRef_UsersWithUserRole_Query } from 'rfbp_aux/services/database_endpoints/directory/users'
import { DatabaseRef_AllProjectTasks_Query, DatabaseRef_Task_Document } from 'rfbp_aux/services/database_endpoints/operations/tasks'
import {
  TsInterface_FormAdditionalData,
  TsInterface_FormData,
  TsInterface_FormHooksObject,
  TsInterface_FormInput,
  TsInterface_FormInputs,
  TsInterface_FormSettings,
  TsInterface_FormSubmittedData,
} from 'rfbp_core/components/form'
import { Icon } from 'rfbp_core/components/icons'
import {
  TableBasic,
  TableCellBasic,
  TsInterface_TableAdditionalData,
  TsInterface_TableDataRow,
  TsInterface_TableHooks,
  TsInterface_TableSettings,
} from 'rfbp_core/components/table'
import { rLIB } from 'rfbp_core/localization/library'
import { DatabaseBatchUpdate, DatabaseGetCollection, TsInterface_DatabaseBatchUpdatesArray } from 'rfbp_core/services/database_management'
import { dynamicSort, getProp, objectToArray, returnFormattedDate } from 'rfbp_core/services/helper_functions'
import { onClickAppNavigation } from 'rfbp_core/services/navigation/navigation_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_PromiseArray, TsInterface_UnspecifiedObject } from 'rfbp_core/typescript/global_types'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

// Displayed Translatable Strings
// { sort-start } - displayed text - scoped sort plugin
const s_ARE_YOU_SURE_THAT_YOU_WANT_TO_CHANGE_THE_SALES_REP_FOR_THIS_PROJECT: JSX.Element = (
  <Trans>Are you sure that you want to change the sales rep for this project</Trans>
)
const s_ARE_YOU_SURE_THAT_YOU_WANT_TO_REFRESH_THIS_PROJECTS_WORKFLOW: JSX.Element = <Trans>Are you sure that you want to refresh this projects workflow</Trans>
const s_ARE_YOU_SURE_THAT_YOU_WANT_TO_REMOVE_THIS_PROJECTS_WORKFLOW: JSX.Element = <Trans>Are you sure that you want to remove this project's workflow?</Trans>
const s_ASSIGNED: JSX.Element = <Trans>Assigned</Trans>
const s_ASSIGN_USER_TO_ROLE: JSX.Element = <Trans>Assign user to role</Trans>
const s_CHANGE_PROJECT_STATUS: JSX.Element = <Trans>Change Project Status</Trans>
const s_EDIT_SALES_REP: JSX.Element = <Trans>Edit Sales Rep</Trans>
const s_FAILED_TO_REFRESH_WORKFLOW_FOR_PROJECT: JSX.Element = <Trans>Failed to refresh workflow for project</Trans>
const s_FAILED_TO_REMOVE_WORKFLOW_FROM_PROJECT: JSX.Element = <Trans>Failed to remove workflow from project</Trans>
const s_GENERATE_PROJECT_ID: JSX.Element = <Trans>Generate Project ID</Trans>
const s_NO_TEAM_ASSIGNMENTS_MADE: JSX.Element = <Trans>No Team Assignments Made</Trans>
const s_OPEN_EDITOR: JSX.Element = <Trans>Open Editor</Trans>
const s_OTHERWISE_CLICK_DISMISS: JSX.Element = <Trans>Otherwise click "Dismiss"</Trans>
const s_REFRESH_WORKFLOW: JSX.Element = <Trans>Refresh workflow</Trans>
const s_REFRESH_WORKFLOW_FOR_PROJECT: JSX.Element = <Trans>Refresh workflow for project</Trans>
const s_REMOVE_WORKFLOW: JSX.Element = <Trans>Remove workflow</Trans>
const s_REMOVE_WORKFLOW_FROM_PROJECT: JSX.Element = <Trans>Remove workflow from project</Trans>
const s_ROLE: JSX.Element = <Trans>Role</Trans>
const s_SELECT_A_TASK_WORKFLOW: JSX.Element = <Trans>Select a task workflow</Trans>
const s_SUCCESSFULLY_LINKED: JSX.Element = <Trans>Successfully Linked</Trans>
const s_TASKS: JSX.Element = <Trans>Tasks</Trans>
const s_TASKS_SCHEDULED_LATER: JSX.Element = <Trans>Tasks Scheduled Later</Trans>
const s_TASK_WORKFLOW: JSX.Element = <Trans>Task Workflow</Trans>
const s_THE_CURRENT_SALES_REP_WILL_LOSE_ACCESS_TO_THIS_PROJECT: JSX.Element = <Trans>The current sales rep will lose access to this project</Trans>
const s_THIS_WILL_DELETE_ALL_TASKS_AND_RESET_ALL_PROGRESS_ON_THIS_PROJECT: JSX.Element = (
  <Trans>This will delete all tasks and reset all progress on this project.</Trans>
)
const s_TYPE_DANGER_TO_PROCEED: JSX.Element = <Trans>Type "DANGER" to proceed</Trans>
const s_USER: JSX.Element = <Trans>User</Trans>
const s_USER_ACCOUNT_NOT_ADDED: JSX.Element = <Trans>User Account not added</Trans>
const s_USER_ACCOUNT_NOT_LINKED: JSX.Element = <Trans>User Account not linked</Trans>
const s_VIEW_PROTO_PROJECTS: JSX.Element = <Trans>View Proto Project</Trans>
const s_YOU_MUST_ENTER_DANGER_IN_ORDER_TO_PROCEED: JSX.Element = <Trans>You must enter "DANGER" in order to proceed.</Trans>
const s_YOU_MUST_GENERATE_A_PROJECT_ID_BEFORE_ASSIGNING_TEAM_MEMBERS: JSX.Element = <Trans>You must generate a Project ID before assigning team members</Trans>
const s_YOU_MUST_SELECT_A_TASK_WORKFLOW_BEFORE_ASSIGNING_TEAM_MEMBERS: JSX.Element = (
  <Trans>You must select a task workflow before assigning team members</Trans>
)
// { sort-end } - displayed text

// Table
const tableSettings_TeamMembers: TsInterface_TableSettings = {
  paginated: false,
  show_header: true,
  size: 'small',
  sortable: false,
}

// Form
const formInputs_TaskWorkflowKey: TsInterface_FormInputs = {
  associated_task_workflow_key: {
    key: 'associated_task_workflow_key',
    label: s_TASK_WORKFLOW,
    input_type: 'multiple_choice_radio',
    required: true,
    data_type: 'string',
    options: [],
  },
}

///////////////////////////////
// Functions
///////////////////////////////

const generationProjectID = (
  uc_RootData_ClientKey: any,
  uc_setRootData_ClientKey: any,
  projectKey: any,
  rootProject: any,
  uc_setUserInterface_ErrorDialogDisplay: any,
  uc_setUserInterface_PromptDialogDisplay: any,
): void => {
  getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
    .then((res_GCK) => {
      openProjectIDGenerationDialog(res_GCK.clientKey, projectKey, rootProject, uc_setUserInterface_ErrorDialogDisplay, uc_setUserInterface_PromptDialogDisplay)
    })
    .catch((rej_GCK) => {
      uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
    })
}

const openTaskWorkflowSelectionDialog = (
  uc_RootData_ClientKey: any,
  uc_setRootData_ClientKey: any,
  uc_setUserInterface_FormDialogDisplay: any,
  projectKey: any,
  uc_RootData_ClientUser: any,
  setReloadTaskWorfklow: any,
  uc_setUserInterface_ErrorDialogDisplay: any,
): void => {
  getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
    .then((res_GCK) => {
      DatabaseGetCollection(DatabaseRef_TaskWorkflowsActive_Query(res_GCK.clientKey, 'name', false, null, {}))
        .then((res_DGC) => {
          // Generate list of available task workflows
          let formInputOptions: TsInterface_UnspecifiedObject[] = []
          for (let loopTaskWorkflowKey in res_DGC.data) {
            let loopTaskWorkflow = res_DGC.data[loopTaskWorkflowKey]
            if (loopTaskWorkflow['available_for_use'] === true) {
              formInputOptions.push({
                key: loopTaskWorkflow['key'],
                value: loopTaskWorkflow['name'],
              })
            } else {
              // formInputOptions.push({
              // 	key: loopTaskWorkflow["key"],
              // 	value: loopTaskWorkflow["name"],
              // 	disabled: true
              // })
            }
          }
          formInputOptions = formInputOptions.sort(dynamicSort('timestamp_created', 'asc'))
          formInputs_TaskWorkflowKey['associated_task_workflow_key']['options'] = formInputOptions
          // Open Dialog
          uc_setUserInterface_FormDialogDisplay({
            display: true,
            form: {
              form: {
                formAdditionalData: {},
                formData: {},
                formInputs: formInputs_TaskWorkflowKey,
                formOnChange: (
                  formAdditionalData: TsInterface_FormAdditionalData,
                  formData: TsInterface_FormData,
                  formInputs: TsInterface_FormInputs,
                  formSettings: TsInterface_FormSettings,
                ) => {},
                formSettings: {},
                formSubmission: (
                  formSubmittedData: TsInterface_FormSubmittedData,
                  formAdditionalData: TsInterface_FormAdditionalData,
                  formHooks: TsInterface_FormHooksObject,
                ) => {
                  return new Promise((resolve, reject) => {
                    addTaskWorkflowToProject(
                      res_GCK.clientKey,
                      projectKey,
                      formSubmittedData.associated_task_workflow_key,
                      getProp(uc_RootData_ClientUser, 'key', null),
                      getProp(uc_RootData_ClientUser, 'name', null),
                    )
                      .then((res_ATWTP) => {
                        setReloadTaskWorfklow(new Date().getTime())
                        resolve(res_ATWTP)
                      })
                      .catch((rej_ATWTP) => {
                        uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_ATWTP.error })
                        reject(rej_ATWTP)
                      })
                  })
                },
              },
              dialog: {
                formDialogHeaderColor: 'success',
                formDialogHeaderText: s_SELECT_A_TASK_WORKFLOW,
                formDialogIcon: (
                  <Icon
                    type="solid"
                    icon="sitemap"
                  />
                ),
              },
            },
          })
        })
        .catch((rej_DGC) => {
          uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DGC.error })
        })
    })
    .catch((rej_GCK) => {
      uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
    })
}

const deleteWorkflow = (
  uc_setUserInterface_PromptDialogDisplay: any,
  uc_RootData_ClientKey: any,
  uc_setRootData_ClientKey: any,
  projectKey: any,
  uc_RootData_ClientUser: any,
  setReloadTaskWorfklow: any,
  uc_setUserInterface_ErrorDialogDisplay: any,
): void => {
  uc_setUserInterface_PromptDialogDisplay({
    display: true,
    prompt: {
      color: 'error',
      confirm_text: s_REMOVE_WORKFLOW,
      default_value: '',
      header: s_REMOVE_WORKFLOW_FROM_PROJECT,
      icon: (
        <Icon
          icon="siren-on"
          type="solid"
        />
      ),
      input_label: s_TYPE_DANGER_TO_PROCEED,
      input_type: 'text',
      text: (
        <>
          {s_ARE_YOU_SURE_THAT_YOU_WANT_TO_REMOVE_THIS_PROJECTS_WORKFLOW} {s_THIS_WILL_DELETE_ALL_TASKS_AND_RESET_ALL_PROGRESS_ON_THIS_PROJECT}
        </>
      ),
      submit_callback: (promptValue: string) => {
        return new Promise((resolve, reject) => {
          if (promptValue === 'DANGER') {
            getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey).then((res_GCK) => {
              removeWorkflowFromProject(
                res_GCK.clientKey,
                projectKey,
                getProp(uc_RootData_ClientUser, 'key', null),
                getProp(uc_RootData_ClientUser, 'name', null),
              )
                .then((res_RWFP) => {
                  setReloadTaskWorfklow(new Date().getTime())
                  resolve(res_RWFP)
                })
                .catch((rej_RWFP) => {
                  uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_RWFP.error })
                  reject(rej_RWFP)
                })
            })
          } else {
            uc_setUserInterface_ErrorDialogDisplay({
              display: true,
              error: {
                message: s_FAILED_TO_REMOVE_WORKFLOW_FROM_PROJECT,
                details: (
                  <>
                    {s_YOU_MUST_ENTER_DANGER_IN_ORDER_TO_PROCEED} {s_OTHERWISE_CLICK_DISMISS}
                  </>
                ),
                code: 'ER-D-APV-DW-01',
              },
            })
            resolve({ close_dialog: false })
          }
        })
      },
    },
  })
}

const repairTasks = (
  uc_setUserInterface_PromptDialogDisplay: any,
  uc_RootData_ClientKey: any,
  uc_setRootData_ClientKey: any,
  projectKey: any,
  rootProject: any,
  uc_RootData_ClientUser: any,
  setReloadTaskWorfklow: any,
  uc_setUserInterface_ErrorDialogDisplay: any,
): void => {
  uc_setUserInterface_PromptDialogDisplay({
    display: true,
    prompt: {
      color: 'secondary',
      confirm_text: s_REFRESH_WORKFLOW,
      default_value: '',
      header: s_REFRESH_WORKFLOW_FOR_PROJECT,
      icon: (
        <Icon
          icon="screwdriver-wrench"
          type="solid"
        />
      ),
      input_label: s_TYPE_DANGER_TO_PROCEED,
      input_type: 'text',
      text: <>{s_ARE_YOU_SURE_THAT_YOU_WANT_TO_REFRESH_THIS_PROJECTS_WORKFLOW}?</>,
      submit_callback: (promptValue: string) => {
        return new Promise((resolve, reject) => {
          if (promptValue === 'DANGER') {
            getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
              .then((res_GCK) => {
                // TODO: Add repair tasks function
                DatabaseGetCollection(DatabaseRef_AllProjectTasks_Query(res_GCK.clientKey, projectKey))
                  .then((res_DGC) => {
                    console.log(res_DGC.data)

                    // status
                    // status_complete = true

                    let updateArray: TsInterface_DatabaseBatchUpdatesArray = []

                    for (let loopTaskKey in res_DGC.data) {
                      let loopTask = res_DGC.data[loopTaskKey]
                      let taskUpdateObject: TsInterface_UnspecifiedObject = {}
                      if (loopTask.status_complete === true && loopTask.timestamp_completed == null) {
                        taskUpdateObject['status_complete'] = false
                      }

                      if (objectToArray(taskUpdateObject).length > 0) {
                        updateArray.push({
                          type: 'setMerge',
                          ref: DatabaseRef_Task_Document(res_GCK.clientKey, loopTaskKey),
                          data: taskUpdateObject,
                        })
                      }
                    }

                    DatabaseBatchUpdate(updateArray)
                      .then((res_DBU) => {
                        resolve(res_DBU)
                      })
                      .catch((rej_DBU) => {
                        reject(rej_DBU)
                      })
                  })
                  .catch((rej_DGC) => {
                    uc_setUserInterface_ErrorDialogDisplay({
                      display: true,
                      error: rej_DGC.error,
                    })
                    reject(rej_DGC)
                  })
              })
              .catch((rej_GCK) => {
                uc_setUserInterface_ErrorDialogDisplay({
                  display: true,
                  error: rej_GCK.error,
                })
                reject(rej_GCK)
              })
          } else {
            uc_setUserInterface_ErrorDialogDisplay({
              display: true,
              error: {
                message: s_FAILED_TO_REFRESH_WORKFLOW_FOR_PROJECT,
                details: (
                  <>
                    {s_YOU_MUST_ENTER_DANGER_IN_ORDER_TO_PROCEED} {s_OTHERWISE_CLICK_DISMISS}
                  </>
                ),
                code: 'ER-D-APV-RW-02',
              },
            })
            resolve({ close_dialog: false })
          }
        })
      },
    },
  })
}

const refreshWorkflow = (
  uc_setUserInterface_PromptDialogDisplay: any,
  uc_RootData_ClientKey: any,
  uc_setRootData_ClientKey: any,
  projectKey: any,
  rootProject: any,
  uc_RootData_ClientUser: any,
  setReloadTaskWorfklow: any,
  uc_setUserInterface_ErrorDialogDisplay: any,
): void => {
  uc_setUserInterface_PromptDialogDisplay({
    display: true,
    prompt: {
      color: 'info',
      confirm_text: s_REFRESH_WORKFLOW,
      default_value: '',
      header: s_REFRESH_WORKFLOW_FOR_PROJECT,
      icon: (
        <Icon
          icon="wand-magic-sparkles"
          type="solid"
        />
      ),
      input_label: s_TYPE_DANGER_TO_PROCEED,
      input_type: 'text',
      text: <>{s_ARE_YOU_SURE_THAT_YOU_WANT_TO_REFRESH_THIS_PROJECTS_WORKFLOW}?</>,
      submit_callback: (promptValue: string) => {
        return new Promise((resolve, reject) => {
          if (promptValue === 'DANGER') {
            getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey).then((res_GCK) => {
              addTaskWorkflowToProject(
                res_GCK.clientKey,
                projectKey,
                getProp(rootProject, 'associated_task_workflow_key', null),
                getProp(uc_RootData_ClientUser, 'key', null),
                getProp(uc_RootData_ClientUser, 'name', null),
              )
                .then((res_ATWTP) => {
                  setReloadTaskWorfklow(new Date().getTime())
                  resolve(res_ATWTP)
                })
                .catch((rej_ATWTP) => {
                  uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_ATWTP.error })
                  reject(rej_ATWTP)
                })
            })
          } else {
            uc_setUserInterface_ErrorDialogDisplay({
              display: true,
              error: {
                message: s_FAILED_TO_REFRESH_WORKFLOW_FOR_PROJECT,
                details: (
                  <>
                    {s_YOU_MUST_ENTER_DANGER_IN_ORDER_TO_PROCEED} {s_OTHERWISE_CLICK_DISMISS}
                  </>
                ),
                code: 'ER-D-APV-RW-01',
              },
            })
            resolve({ close_dialog: false })
          }
        })
      },
    },
  })
}

const openEditProjectStatusDialog = (
  uc_RootData_ClientKey: any,
  uc_setRootData_ClientKey: any,
  rootProject: any,
  uc_setUserInterface_FormDialogDisplay: any,
  uc_setUserInterface_ErrorDialogDisplay: any,
  uc_RootData_ClientUser: any,
) => {
  getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
    .then((res_GCK) => {
      editProjectStatus(
        res_GCK.clientKey,
        rootProject.key,
        rootProject,
        uc_setUserInterface_FormDialogDisplay,
        uc_setUserInterface_ErrorDialogDisplay,
        uc_RootData_ClientUser,
      )
    })
    .catch((rej_GCK) => {
      uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
    })
}

const openTeamMemberEditDialog = (
  clientKey: string,
  role: TsInterface_UnspecifiedObject,
  userOptions: TsInterface_UnspecifiedObject,
  uc_setUserInterface_FormDialogDisplay: any,
  rootProject: any,
  projectKey: any,
  uc_RootData_ClientUser: any,
): void => {
  let formInputs_DynamicUserAssociation: TsInterface_FormInputs = {}

  console.log(userOptions)

  let dynamicInput: TsInterface_FormInput = {
    data_type: 'string',
    input_type: 'association_autocomplete_combo_box',
    key: role.role_key,
    label: role.role_name,
    required: true,
    options: objectToArray(userOptions),
  }
  formInputs_DynamicUserAssociation[role.role_key] = dynamicInput
  uc_setUserInterface_FormDialogDisplay({
    display: true,
    form: {
      form: {
        formAdditionalData: {},
        formData: rootProject,
        formInputs: formInputs_DynamicUserAssociation,
        formOnChange: (
          formAdditionalData: TsInterface_FormAdditionalData,
          formData: TsInterface_FormData,
          formInputs: TsInterface_FormInputs,
          formSettings: TsInterface_FormSettings,
        ) => {},
        formSettings: {},
        formSubmission: (
          formSubmittedData: TsInterface_FormSubmittedData,
          formAdditionalData: TsInterface_FormAdditionalData,
          formHooks: TsInterface_FormHooksObject,
        ) => {
          return new Promise((resolve, reject) => {
            assignUserToProjectTaskRole(
              clientKey,
              projectKey,
              formSubmittedData[role.role_key + '_key'],
              formSubmittedData[role.role_key + '_name'],
              role.key,
              getProp(uc_RootData_ClientUser, 'key', null),
              getProp(uc_RootData_ClientUser, 'name', null),
            )
              .then((res_AUTPTR) => {
                resolve(res_AUTPTR)
              })
              .catch((rej_AUTPTR) => {
                reject(rej_AUTPTR)
              })
          })
        },
      },
      dialog: {
        formDialogHeaderColor: 'success',
        formDialogHeaderText: (
          <>
            {s_ASSIGN_USER_TO_ROLE}: {role.role_name}
          </>
        ),
        formDialogIcon: (
          <Icon
            type="solid"
            icon="user"
          />
        ),
      },
    },
  })
}

///////////////////////////////
// JSX
///////////////////////////////

const rJSX_TeamMemberEditIcon = (
  role: TsInterface_UnspecifiedObject,
  uc_RootData_ClientKey: any,
  uc_setRootData_ClientKey: any,
  uc_setUserInterface_PromptDialogDisplay: any,
  uc_setUserInterface_ErrorDialogDisplay: any,
  uc_setUserInterface_FormDialogDisplay: any,
  rootProject: any,
  projectKey: any,
  uc_RootData_ClientUser: any,
): JSX.Element => {
  let editIconJSX = <></>
  if (role != null && role.disabled !== true) {
    editIconJSX = (
      <Box
        className="tw-opacity-50 hover:tw-opacity-100 tw-cursor-pointer"
        onClick={() => {
          getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
            .then((res_GCK) => {
              let databasePromiseArray: TsInterface_PromiseArray = []
              let userOptions: TsInterface_UnspecifiedObject = {}
              databasePromiseArray.push(
                DatabaseGetCollection(DatabaseRef_UsersWithUserRole_Query(res_GCK.clientKey, role.key))
                  .then((res_DGC) => {
                    for (let loopUserKey in res_DGC.data) {
                      let loopUser = res_DGC.data[loopUserKey]
                      if (loopUser != null && loopUser.status !== 'deleted' && loopUser.name != null) {
                        userOptions[loopUserKey] = {
                          key: loopUserKey,
                          value: loopUser.name,
                        }
                      }
                    }
                  })
                  .catch((rej_DGC) => {
                    console.error(rej_DGC)
                  }),
              )
              databasePromiseArray.push(
                DatabaseGetCollection(DatabaseRef_UsersWithTaskRole_Query(res_GCK.clientKey, role.key))
                  .then((res_DGC) => {
                    for (let loopUserKey in res_DGC.data) {
                      let loopUser = res_DGC.data[loopUserKey]
                      if (loopUser != null && loopUser.status !== 'deleted' && loopUser.name != null) {
                        userOptions[loopUserKey] = {
                          key: loopUserKey,
                          value: loopUser.name,
                        }
                      }
                    }
                  })
                  .catch((rej_DGC) => {
                    console.error(rej_DGC)
                  }),
              )
              // After User Data Loaded
              Promise.all(databasePromiseArray).finally(() => {
                if (role.role_key === 'associated_sales_rep') {
                  uc_setUserInterface_PromptDialogDisplay({
                    display: true,
                    prompt: {
                      color: 'error',
                      confirm_text: s_OPEN_EDITOR,
                      default_value: '',
                      header: s_EDIT_SALES_REP,
                      icon: (
                        <Icon
                          icon="siren-on"
                          type="solid"
                        />
                      ),
                      input_label: s_TYPE_DANGER_TO_PROCEED,
                      input_type: 'text',
                      text: (
                        <>
                          {s_ARE_YOU_SURE_THAT_YOU_WANT_TO_CHANGE_THE_SALES_REP_FOR_THIS_PROJECT}? {s_THE_CURRENT_SALES_REP_WILL_LOSE_ACCESS_TO_THIS_PROJECT}
                        </>
                      ),
                      submit_callback: (promptValue: string) => {
                        return new Promise((resolve, reject) => {
                          if (promptValue === 'DANGER') {
                            openTeamMemberEditDialog(
                              res_GCK.clientKey,
                              role,
                              userOptions,
                              uc_setUserInterface_FormDialogDisplay,
                              rootProject,
                              projectKey,
                              uc_RootData_ClientUser,
                            )
                            resolve({ close_dialog: true })
                          } else {
                            resolve({ close_dialog: false })
                          }
                        })
                      },
                    },
                  })
                } else {
                  openTeamMemberEditDialog(
                    res_GCK.clientKey,
                    role,
                    userOptions,
                    uc_setUserInterface_FormDialogDisplay,
                    rootProject,
                    projectKey,
                    uc_RootData_ClientUser,
                  )
                }
              })
            })
            .catch((rej_GCK) => {
              uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
            })
        }}
      >
        <Icon icon="pen-to-square" />
      </Box>
    )
  } else {
    editIconJSX = (
      <Box className="tw-opacity-10">
        <Icon icon="pen-to-square" />
      </Box>
    )
  }
  return editIconJSX
}

const rJSX_ProtoProjectButton = (rootProject: any, un_routerNavigation: any): JSX.Element => {
  let buttonJSX = <></>
  if (rootProject.associated_proto_project_key != null) {
    buttonJSX = (
      <Button
        className=""
        color="info"
        variant="outlined"
        startIcon={<Icon icon="magnifying-glass"></Icon>}
        onClick={(event) => {
          onClickAppNavigation(event, un_routerNavigation, ApplicationPages.AdminActiveProjectMergePage.url(rootProject.associated_proto_project_key))
        }}
      >
        {s_VIEW_PROTO_PROJECTS}
      </Button>
    )
  }
  return buttonJSX
}

const rJSX_ProjectStatusButton = (
  uc_RootData_ClientKey: any,
  uc_setRootData_ClientKey: any,
  rootProject: any,
  uc_setUserInterface_FormDialogDisplay: any,
  uc_setUserInterface_ErrorDialogDisplay: any,
  uc_RootData_ClientUser: any,
): JSX.Element => {
  let buttonJSX = (
    <Button
      className="tw-m-auto tw-mr-2"
      color="primary"
      variant="outlined"
      startIcon={<Icon icon="pen-to-square" />}
      disabled={rootProject.associated_css_rep_key == null}
      onClick={() => {
        openEditProjectStatusDialog(
          uc_RootData_ClientKey,
          uc_setRootData_ClientKey,
          rootProject,
          uc_setUserInterface_FormDialogDisplay,
          uc_setUserInterface_ErrorDialogDisplay,
          uc_RootData_ClientUser,
        )
      }}
    >
      {s_CHANGE_PROJECT_STATUS}
    </Button>
  )
  return buttonJSX
}

const rJSX_TeamMembersOwnersList = (
  readOrWrite: 'read' | 'write',
  clientUserRoles: any,
  rootProject: any,
  projectTaskWorkflowUserRoles: any,
  combinedUserRoles: any,
  projectRoleAssignments: any,
  projectTasks: any,
  uc_RootData_ClientKey: any,
  uc_setRootData_ClientKey: any,
  uc_setUserInterface_CustomDialogDisplay: any,
  un_routerNavigation: any,
  uc_setUserInterface_FormDialogDisplay: any,
  uc_setUserInterface_ErrorDialogDisplay: any,
  uc_RootData_ClientUser: any,
  uc_setUserInterface_PromptDialogDisplay: any,
  projectKey: any,
  setReloadTaskWorfklow: any,
): JSX.Element => {
  let listJSX = <></>
  let rolesObject: TsInterface_UnspecifiedObject = {
    associated_customer: {
      disabled: true,
      icon: getProp(clientUserRoles['customer'], 'icon', <></>),
      key: 'customer',
      user_key: getProp(rootProject, 'associated_customer_key', null),
      user_name: getProp(rootProject, 'associated_customer_name', null),
      user_name_jsx: getProp(rootProject, 'associated_customer_name', <Box className="tw-italic tw-opacity-30">{rLIB('Not Assigned')}</Box>),
      project_role_key: 'associated_customer_key',
      role_key: 'associated_customer',
      role_name: getProp(clientUserRoles['customer'], 'name', <></>),
      task_counts: { total: 0 },
      task_completion_types: { direct: 0, dispatcher: 0, scheduled: 0 },
    },
    associated_sales_rep: {
      icon: getProp(clientUserRoles['sales_rep'], 'icon', <></>),
      key: 'sales_rep',
      user_key: getProp(rootProject, 'associated_sales_rep_key', null),
      user_name: getProp(rootProject, 'associated_sales_rep_name', null),
      user_name_jsx: getProp(rootProject, 'associated_sales_rep_name', <Box className="tw-italic tw-opacity-30">{rLIB('Not Assigned')}</Box>),
      project_role_key: 'associated_sales_rep_key',
      role_key: 'associated_sales_rep',
      role_name: getProp(clientUserRoles['sales_rep'], 'name', <></>),
      task_counts: { total: 0 },
      task_completion_types: { direct: 0, dispatcher: 0, scheduled: 0 },
    },
    associated_css_rep: {
      icon: getProp(clientUserRoles['css_rep'], 'icon', <></>),
      key: 'css_rep',
      user_key: getProp(rootProject, 'associated_css_rep_key', null),
      user_name: getProp(rootProject, 'associated_css_rep_name', null),
      user_name_jsx: getProp(rootProject, 'associated_css_rep_name', <Box className="tw-italic tw-opacity-30">{rLIB('Not Assigned')}</Box>),
      project_role_key: 'associated_css_rep_key',
      role_key: 'associated_css_rep',
      role_name: getProp(clientUserRoles['css_rep'], 'name', <></>),
      task_counts: { total: 0 },
      task_completion_types: { direct: 0, dispatcher: 0, scheduled: 0 },
    },
    // associated_electrical_foreman: {
    // icon: getProp(clientUserRoles["electrical_foreman"], "icon", <></>),
    // key: "electrical_foreman",
    // user_key: getProp( rootProject, "associated_electrical_foreman_key", null ),
    // user_name: getProp( rootProject, "associated_electrical_foreman_name", null ),
    // user_name_jsx: getProp( rootProject, "associated_electrical_foreman_name", <Box className="tw-italic tw-opacity-30">{ rLIB("Not Assigned") }</Box> ),
    // project_role_key: "associated_electrical_foreman_key",
    // role_key: "associated_electrical_foreman",
    // role_name: getProp(clientUserRoles["electrical_foreman"], "name", <></>),
    // task_counts: { total: 0 },
    // task_completion_types: { direct: 0, dispatcher: 0, scheduled: 0 },
    // },
    associated_general_manager: {
      icon: getProp(clientUserRoles['general_manager'], 'icon', <></>),
      key: 'general_manager',
      user_key: getProp(rootProject, 'associated_general_manager_key', null),
      user_name: getProp(rootProject, 'associated_general_manager_name', null),
      user_name_jsx: getProp(rootProject, 'associated_general_manager_name', <Box className="tw-italic tw-opacity-30">{rLIB('Not Assigned')}</Box>),
      project_role_key: 'associated_general_manager_key',
      role_key: 'associated_general_manager',
      role_name: getProp(clientUserRoles['general_manager'], 'name', <></>),
      task_counts: { total: 0 },
      task_completion_types: { direct: 0, dispatcher: 0, scheduled: 0 },
    },
    associated_accounts_payable: {
      icon: getProp(clientUserRoles['accounts_payable'], 'icon', <></>),
      key: 'accounts_payable',
      user_key: getProp(rootProject, 'associated_accounts_payable_key', null),
      user_name: getProp(rootProject, 'associated_accounts_payable_name', null),
      user_name_jsx: getProp(rootProject, 'associated_accounts_payable_name', <Box className="tw-italic tw-opacity-30">{rLIB('Not Assigned')}</Box>),
      project_role_key: 'associated_accounts_payable_key',
      role_key: 'associated_accounts_payable',
      role_name: getProp(clientUserRoles['accounts_payable'], 'name', <></>),
      task_counts: { total: 0 },
      task_completion_types: { direct: 0, dispatcher: 0, scheduled: 0 },
    },
    associated_accounts_receivable: {
      icon: getProp(clientUserRoles['accounts_receivable'], 'icon', <></>),
      key: 'accounts_receivable',
      user_key: getProp(rootProject, 'associated_accounts_receivable_key', null),
      user_name: getProp(rootProject, 'associated_accounts_receivable_name', null),
      user_name_jsx: getProp(rootProject, 'associated_accounts_receivable_name', <Box className="tw-italic tw-opacity-30">{rLIB('Not Assigned')}</Box>),
      project_role_key: 'associated_accounts_receivable_key',
      role_key: 'associated_accounts_receivable',
      role_name: getProp(clientUserRoles['accounts_receivable'], 'name', <></>),
      task_counts: { total: 0 },
      task_completion_types: { direct: 0, dispatcher: 0, scheduled: 0 },
    },
  }
  for (let loopRoleKey in projectTaskWorkflowUserRoles) {
    if (rolesObject['associated_' + loopRoleKey] == null) {
      if (combinedUserRoles[loopRoleKey] != null) {
        rolesObject['associated_' + loopRoleKey] = {
          icon: getProp(combinedUserRoles[loopRoleKey], 'icon', <></>),
          key: loopRoleKey,
          user_key: getProp(rootProject, 'associated_' + loopRoleKey + '_key', null),
          user_name: getProp(rootProject, 'associated_' + loopRoleKey + '_name', null),
          user_name_jsx: getProp(rootProject, 'associated_' + loopRoleKey + '_name', <Box className="tw-italic tw-opacity-30">{rLIB('Not Assigned')}</Box>),
          project_role_key: 'associated_' + loopRoleKey + '_key',
          role_key: 'associated_' + loopRoleKey,
          role_name: combinedUserRoles[loopRoleKey]['name'],
          task_counts: { total: 0 },
          task_completion_types: { direct: 0, dispatcher: 0, scheduled: 0 },
        }
      }
    }
  }
  // Add roles for AdHoc Tasks not on workflow
  for (let loopTaskKey in projectTasks) {
    let loopTask = projectTasks[loopTaskKey]
    if (
      loopTask != null &&
      loopTask.associated_owner_type != null &&
      rolesObject != null &&
      rolesObject[loopTask.associated_owner_type] == null &&
      combinedUserRoles[loopTask.associated_owner_type] != null
    ) {
      let loopRoleKey = loopTask.associated_owner_type
      rolesObject['associated_' + loopRoleKey] = {
        icon: getProp(combinedUserRoles[loopRoleKey], 'icon', <></>),
        key: loopRoleKey,
        user_key: getProp(rootProject, 'associated_' + loopRoleKey + '_key', null),
        user_name: getProp(rootProject, 'associated_' + loopRoleKey + '_name', null),
        user_name_jsx: getProp(rootProject, 'associated_' + loopRoleKey + '_name', <Box className="tw-italic tw-opacity-30">{rLIB('Not Assigned')}</Box>),
        project_role_key: 'associated_' + loopRoleKey + '_key',
        role_key: 'associated_' + loopRoleKey,
        role_name: combinedUserRoles[loopRoleKey]['name'],
        task_counts: { total: 0 },
        task_completion_types: { direct: 0, dispatcher: 0, scheduled: 0 },
      }
    }
  }
  for (let loopFullRoleKey in rolesObject) {
    let loopRole = rolesObject[loopFullRoleKey]
    if (
      loopRole != null &&
      loopRole.user_key != null &&
      projectRoleAssignments != null &&
      projectRoleAssignments['timestamp_assignment'] != null &&
      projectRoleAssignments['timestamp_assignment'][loopRole.key] != null &&
      projectRoleAssignments['timestamp_assignment'][loopRole.key][loopRole.user_key] != null
    ) {
      rolesObject[loopFullRoleKey]['timestamp_assignment_jsx'] = (
        <Typography
          variant="subtitle1"
          className="tw-opacity-30"
        >
          {s_ASSIGNED}: {returnFormattedDate(projectRoleAssignments['timestamp_assignment'][loopRole.key][loopRole.user_key].toDate(), 'MMMM D, YYYY')}
        </Typography>
      )
    } else {
      rolesObject[loopFullRoleKey]['timestamp_assignment_jsx'] = <></>
    }
  }
  for (let loopTaskKey in projectTasks) {
    let loopTask = projectTasks[loopTaskKey]
    if (
      loopTask != null &&
      loopTask['associated_owner_type'] != null &&
      rolesObject != null &&
      rolesObject['associated_' + loopTask['associated_owner_type']] != null
    ) {
      rolesObject['associated_' + loopTask['associated_owner_type']]['task_counts']['total']++
      let taskColorValue = determineTaskColorStatusSingle(loopTask)
      if (taskColorValue != null) {
        if (rolesObject['associated_' + loopTask['associated_owner_type']]['task_counts'][taskColorValue] == null) {
          rolesObject['associated_' + loopTask['associated_owner_type']]['task_counts'][taskColorValue] = 0
        }
        rolesObject['associated_' + loopTask['associated_owner_type']]['task_counts'][taskColorValue]++
      }
      if (loopTask['task_completion_type'] != null) {
        if (rolesObject['associated_' + loopTask['associated_owner_type']]['task_completion_types'][loopTask['task_completion_type']] == null) {
          rolesObject['associated_' + loopTask['associated_owner_type']]['task_completion_types'][loopTask['task_completion_type']] = 0
        }
        rolesObject['associated_' + loopTask['associated_owner_type']]['task_completion_types'][loopTask['task_completion_type']]++
      }
    }
  }
  // Generate Table Columns
  let tableColumns_TeamMembers = {
    icon: {
      header: {
        header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
          return <></>
        },
        header_sort_by: null,
      },
      cell: {
        cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
          let cellJSX = <>{rowData.icon}</>
          return cellJSX
        },
        cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
          let cellCSS = 'tw-w-4'
          return cellCSS
        },
      },
    },
    role_name: TableCellBasic('role_name', s_ROLE, null),
    // notify: {
    // header: {
    // 	header_jsx: ( tableAdditionalData: TsInterface_TableAdditionalData ) => {
    // 		if( tableAdditionalData.readOrWrite === "write" ){
    // 			return s_NOTIFY
    // 		} else {
    // 			return <></>
    // 		}
    // 	},
    // 	header_sort_by: null
    // },
    // cell: {
    // 	cell_jsx: (
    // 		rowData: TsInterface_TableDataRow,
    // 		tableAdditionalData: TsInterface_TableAdditionalData,
    // 		tableHooks: TsInterface_TableHooks
    // 	) => {
    // 		let cellJSX = <></>
    // 		if( tableAdditionalData.readOrWrite === "write" ){
    // 			cellJSX =
    // 			<Box className="tw-text-center" onClick={ () => {
    // 				getClientKey( uc_RootData_ClientKey, uc_setRootData_ClientKey ).then(( res_GCK ) => {
    // 					if( rowData.user_key != null ){
    // 						openUserPushNotificationDialog( uc_setUserInterface_CustomDialogDisplay, res_GCK.clientKey, rowData.user_key as string )
    // 					}
    // 				}).catch(( rej_GCK ) => {
    // 					tableHooks.uc_setUserInterface_ErrorDialogDisplay({display: true, error: rej_GCK.error })
    // 				})
    // 			}}
    // 			>
    // 				<Icon icon="bell" className="tw-opacity-30 tw-cursor-pointer hover:tw-opacity-100" />
    // 			</Box>
    // 		}
    // 		return cellJSX
    // 	},
    // 	cell_css: ( rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData ) => {
    // 		let cellCSS = "tw-w-4"
    // 		return cellCSS
    // 	}
    // },
    // },
    user_name: {
      header: {
        header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
          return s_USER
        },
        header_sort_by: null,
      },
      cell: {
        cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
          let cellJSX = <></>
          if (
            rowData != null &&
            rowData.task_completion_types != null &&
            rowData.task_counts != null &&
            //@ts-expect-error
            rowData.task_counts.total > 0 &&
            //@ts-expect-error
            rowData.task_completion_types.direct === 0 &&
            // @ts-ignore
            rowData.task_completion_types.dispatcher === 0 &&
            // @ts-ignore
            rowData.task_completion_types.scheduled > 0
          ) {
            cellJSX = (
              <Box>
                <Tooltip
                  placement="left"
                  title={s_TASKS_SCHEDULED_LATER}
                >
                  <Box
                    className="tw-inline-block tw-mr-2"
                    sx={{ color: themeVariables.warning_dark }}
                  >
                    <Icon icon="clock" />
                  </Box>
                </Tooltip>
                <Box
                  className="tw-inline-block tw-italic"
                  sx={{ color: themeVariables.warning_main }}
                >
                  {s_TASKS_SCHEDULED_LATER}
                </Box>
              </Box>
            )
          } else {
            let cellIcon = <></>
            if (rowData.user_name == null) {
              cellIcon = (
                <Tooltip
                  placement="left"
                  title={s_USER_ACCOUNT_NOT_ADDED}
                >
                  <Box
                    className="tw-inline-block tw-mr-2"
                    sx={{ color: themeVariables.gray_700 }}
                  >
                    <Icon icon="triangle-exclamation" />
                  </Box>
                </Tooltip>
              )
            } else if (rowData.user_key == null) {
              cellIcon = (
                <Tooltip
                  placement="left"
                  title={s_USER_ACCOUNT_NOT_LINKED}
                >
                  <Box
                    className="tw-inline-block tw-mr-2"
                    sx={{ color: themeVariables.error_main }}
                  >
                    <Icon icon="link-slash" />
                  </Box>
                </Tooltip>
              )
            } else {
              cellIcon = (
                <Tooltip
                  placement="left"
                  title={s_SUCCESSFULLY_LINKED}
                >
                  <Box
                    className="tw-inline-block tw-mr-2"
                    sx={{ color: themeVariables.success_main }}
                  >
                    <Icon icon="badge-check" />
                  </Box>
                </Tooltip>
              )
            }
            let editIconJSX = <></>
            if (tableAdditionalData.readOrWrite === 'write') {
              editIconJSX = (
                <Box className="tw-inline-block tw-ml-2">
                  {rJSX_TeamMemberEditIcon(
                    rowData,
                    uc_RootData_ClientKey,
                    uc_setRootData_ClientKey,
                    uc_setUserInterface_PromptDialogDisplay,
                    uc_setUserInterface_ErrorDialogDisplay,
                    uc_setUserInterface_FormDialogDisplay,
                    rootProject,
                    projectKey,
                    uc_RootData_ClientUser,
                  )}
                </Box>
              )
            }
            cellJSX = (
              <Box>
                {cellIcon}
                <Box className="tw-inline-block">{rowData.user_name_jsx}</Box>
                {editIconJSX}
              </Box>
            )
          }
          return cellJSX
        },
        cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
          let cellCSS = ''
          return cellCSS
        },
      },
    },
    tasks: {
      header: {
        header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
          return s_TASKS
        },
        header_sort_by: null,
      },
      cell: {
        cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
          let cellJSX = <>{rJSX_TaskBreakdownBarFullColors(rowData.task_counts as unknown as TsInterface_UnspecifiedObject)}</>
          return cellJSX
        },
        cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
          let cellCSS = ''
          return cellCSS
        },
      },
    },
  }
  let tableSubButtonsJSX = <></>
  if (readOrWrite === 'write') {
    tableSubButtonsJSX = (
      <Box className="tw-mt-2 tw-text-right">
        {rJSX_ProtoProjectButton(rootProject, un_routerNavigation)}
        {rJSX_ProjectStatusButton(
          uc_RootData_ClientKey,
          uc_setRootData_ClientKey,
          rootProject,
          uc_setUserInterface_FormDialogDisplay,
          uc_setUserInterface_ErrorDialogDisplay,
          uc_RootData_ClientUser,
        )}
        <Button
          className="tw-m-auto tw-mr-2"
          color="secondary"
          variant="outlined"
          startIcon={<Icon icon="screwdriver-wrench" />}
          onClick={() => {
            repairTasks(
              uc_setUserInterface_PromptDialogDisplay,
              uc_RootData_ClientKey,
              uc_setRootData_ClientKey,
              projectKey,
              rootProject,
              uc_RootData_ClientUser,
              setReloadTaskWorfklow,
              uc_setUserInterface_ErrorDialogDisplay,
            )
          }}
        >
          {rLIB('Repair Tasks')}
        </Button>
        <Button
          className="tw-m-auto tw-mr-2"
          color="info"
          variant="outlined"
          startIcon={<Icon icon="wand-magic-sparkles" />}
          onClick={() => {
            refreshWorkflow(
              uc_setUserInterface_PromptDialogDisplay,
              uc_RootData_ClientKey,
              uc_setRootData_ClientKey,
              projectKey,
              rootProject,
              uc_RootData_ClientUser,
              setReloadTaskWorfklow,
              uc_setUserInterface_ErrorDialogDisplay,
            )
          }}
        >
          {s_REFRESH_WORKFLOW}
        </Button>
        <Button
          className="tw-m-auto"
          color="error"
          variant="outlined"
          startIcon={<Icon icon="siren-on" />}
          onClick={() => {
            deleteWorkflow(
              uc_setUserInterface_PromptDialogDisplay,
              uc_RootData_ClientKey,
              uc_setRootData_ClientKey,
              projectKey,
              uc_RootData_ClientUser,
              setReloadTaskWorfklow,
              uc_setUserInterface_ErrorDialogDisplay,
            )
          }}
        >
          {s_REMOVE_WORKFLOW_FROM_PROJECT}
        </Button>
      </Box>
    )
  }
  // JSX
  listJSX = (
    <Box>
      <Card>
        <TableBasic
          tableAdditionalData={{
            readOrWrite: readOrWrite,
          }}
          tableColumns={tableColumns_TeamMembers}
          tableData={objectToArray(rolesObject).sort(dynamicSort('key', 'asc'))}
          tableSettings={tableSettings_TeamMembers}
        />
      </Card>
      {tableSubButtonsJSX}
    </Box>
  )
  return listJSX
}

///////////////////////////////
// JSX Exports
///////////////////////////////

export const rJSX_SelectTaskWorkflowButton = (
  uc_RootData_ClientKey: any,
  uc_RootData_ClientUser: any,
  projectKey: any,
  setReloadTaskWorfklow: any,
  uc_setRootData_ClientKey: any,
  uc_setUserInterface_ErrorDialogDisplay: any,
  uc_setUserInterface_FormDialogDisplay: any,
): JSX.Element => {
  let buttonJSX = (
    <Button
      className=""
      color="inherit"
      variant="outlined"
      startIcon={<Icon icon="sitemap"></Icon>}
      onClick={() => {
        openTaskWorkflowSelectionDialog(
          uc_RootData_ClientKey,
          uc_setRootData_ClientKey,
          uc_setUserInterface_FormDialogDisplay,
          projectKey,
          uc_RootData_ClientUser,
          setReloadTaskWorfklow,
          uc_setUserInterface_ErrorDialogDisplay,
        )
      }}
    >
      {s_SELECT_A_TASK_WORKFLOW}
    </Button>
  )
  return buttonJSX
}

export const rJSX_SelectProjectCodeButton = (
  uc_RootData_ClientKey: any,
  projectKey: any,
  rootProject: any,
  uc_setRootData_ClientKey: any,
  uc_setUserInterface_ErrorDialogDisplay: any,
  uc_setUserInterface_PromptDialogDisplay: any,
): JSX.Element => {
  let buttonJSX = (
    <Button
      className=""
      color="inherit"
      variant="outlined"
      startIcon={<Icon icon="barode"></Icon>}
      onClick={() => {
        generationProjectID(
          uc_RootData_ClientKey,
          uc_setRootData_ClientKey,
          projectKey,
          rootProject,
          uc_setUserInterface_ErrorDialogDisplay,
          uc_setUserInterface_PromptDialogDisplay,
        )
      }}
    >
      {s_GENERATE_PROJECT_ID}
    </Button>
  )
  return buttonJSX
}

export const rJSX_TeamTab = (
  readOrWrite: 'read' | 'write',
  uc_RootData_ClientKey: any,
  uc_RootData_ClientUser: any,
  clientUserRoles: any,
  combinedUserRoles: any,
  projectKey: any,
  projectRoleAssignments: any,
  projectTaskWorkflowUserRoles: any,
  projectTasks: any,
  rootProject: any,
  rootProjectLoaded: any,
  un_routerNavigation: any,
  setReloadTaskWorfklow: any,
  uc_setRootData_ClientKey: any,
  uc_setUserInterface_CustomDialogDisplay: any,
  uc_setUserInterface_ErrorDialogDisplay: any,
  uc_setUserInterface_FormDialogDisplay: any,
  uc_setUserInterface_PromptDialogDisplay: any,
): JSX.Element => {
  let tabJSX = <></>
  if (rootProjectLoaded === true) {
    if (rootProject != null && rootProject['id_number'] == null) {
      if (readOrWrite === 'write') {
        tabJSX = (
          <Box>
            <Card className="tw-text-center tw-p-4">
              <Typography
                variant="h6"
                className="tw-mb-4"
              >
                {s_YOU_MUST_GENERATE_A_PROJECT_ID_BEFORE_ASSIGNING_TEAM_MEMBERS}
              </Typography>
              {rJSX_SelectProjectCodeButton(
                uc_RootData_ClientKey,
                projectKey,
                rootProject,
                uc_setRootData_ClientKey,
                uc_setUserInterface_ErrorDialogDisplay,
                uc_setUserInterface_PromptDialogDisplay,
              )}
            </Card>
            <Box className="tw-text-right tw-pt-4">
              {rJSX_ProjectStatusButton(
                uc_RootData_ClientKey,
                uc_setRootData_ClientKey,
                rootProject,
                uc_setUserInterface_FormDialogDisplay,
                uc_setUserInterface_ErrorDialogDisplay,
                uc_RootData_ClientUser,
              )}
            </Box>
          </Box>
        )
      } else {
        tabJSX = (
          <Box className="tw-text-center">
            <Typography variant="h6">{s_NO_TEAM_ASSIGNMENTS_MADE}</Typography>
          </Box>
        )
      }
    } else if (rootProject != null && rootProject['associated_task_workflow_key'] == null) {
      if (readOrWrite === 'write') {
        tabJSX = (
          <Box>
            <Card className="tw-text-center tw-p-4">
              <Typography
                variant="h6"
                className="tw-mb-4"
              >
                {s_YOU_MUST_SELECT_A_TASK_WORKFLOW_BEFORE_ASSIGNING_TEAM_MEMBERS}
              </Typography>
              {rJSX_SelectTaskWorkflowButton(
                uc_RootData_ClientKey,
                uc_RootData_ClientUser,
                projectKey,
                setReloadTaskWorfklow,
                uc_setRootData_ClientKey,
                uc_setUserInterface_ErrorDialogDisplay,
                uc_setUserInterface_FormDialogDisplay,
              )}
            </Card>
            <Box className="tw-text-right tw-pt-4">
              {rJSX_ProjectStatusButton(
                uc_RootData_ClientKey,
                uc_setRootData_ClientKey,
                rootProject,
                uc_setUserInterface_FormDialogDisplay,
                uc_setUserInterface_ErrorDialogDisplay,
                uc_RootData_ClientUser,
              )}
            </Box>
          </Box>
        )
      } else {
        tabJSX = (
          <Box className="tw-text-center">
            <Typography variant="h6">{s_NO_TEAM_ASSIGNMENTS_MADE}</Typography>
          </Box>
        )
      }
    } else {
      tabJSX = (
        <Box>
          {rJSX_TeamMembersOwnersList(
            readOrWrite,
            clientUserRoles,
            rootProject,
            projectTaskWorkflowUserRoles,
            combinedUserRoles,
            projectRoleAssignments,
            projectTasks,
            uc_RootData_ClientKey,
            uc_setRootData_ClientKey,
            uc_setUserInterface_CustomDialogDisplay,
            un_routerNavigation,
            uc_setUserInterface_FormDialogDisplay,
            uc_setUserInterface_ErrorDialogDisplay,
            uc_RootData_ClientUser,
            uc_setUserInterface_PromptDialogDisplay,
            projectKey,
            setReloadTaskWorfklow,
          )}
        </Box>
      )
    }
  } else {
    tabJSX = (
      <Box className="tw-text-center">
        <Skeleton
          variant="rectangular"
          height={200}
          className="tw-my-2"
        />
      </Box>
    )
  }
  return tabJSX
}
