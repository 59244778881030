///////////////////////////////
// Imports
///////////////////////////////

import { Masonry } from '@mui/lab'
import { AppBar, Box, Dialog, DialogContent, Divider, IconButton, Toolbar, Typography } from '@mui/material'
import { Icon } from 'rfbp_core/components/icons'
import {
  TableCellBasic,
  TableCellManage,
  TsInterface_TableAdditionalData,
  TsInterface_TableColumns,
  TsInterface_TableDatabaseSettings,
  TsInterface_TableDataRow,
  TsInterface_TableHooks,
} from 'rfbp_core/components/table'
import { TableCellCommaSeparated } from 'rfbp_core/components/table/cells/table_cell_comma_separated'
import { TableCellCurrency } from 'rfbp_core/components/table/cells/table_cell_currency'
import { TableCellTruncatedString } from 'rfbp_core/components/table/cells/table_cell_truncated_string'
import { rLIB } from 'rfbp_core/localization/library'
import { UserInterface_Default_CustomDialogDisplayState } from 'rfbp_core/services/context'
import { formatCurrency, getProp, objectToArray } from 'rfbp_core/services/helper_functions'
import { capitalizeAllWordsInString } from 'rfbp_core/services/helper_functions/capitalize_all_words_in_string'

///////////////////////////////
// Exports
///////////////////////////////

export const tableColumns_MaterialReceipts: TsInterface_TableColumns = {
  manage: TableCellManage({
    view: {
      icon: (
        <Icon
          type="solid"
          icon="magnifying-glass"
        />
      ),
      label: <>{rLIB('View Receipt')}</>,
      onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        if (rowData.key != null) {
          tableHooks.uc_setUserInterface_CustomDialogDisplay({
            display: true,
            dialog: {
              dialog_jsx: (
                <Dialog
                  className="bp_dialog_lg_width"
                  keepMounted
                  onClose={() => {
                    tableHooks.uc_setUserInterface_CustomDialogDisplay(UserInterface_Default_CustomDialogDisplayState)
                  }}
                  open={true}
                >
                  <AppBar
                    position="static"
                    color="inherit"
                  >
                    <Toolbar>
                      <IconButton
                        aria-label="menu"
                        color="inherit"
                        disabled
                        edge="start"
                        size="large"
                        sx={{ mr: 2, color: '#fff !important' }}
                      >
                        <Icon icon="receipt" />
                      </IconButton>
                      <Typography
                        component={'span'}
                        variant={'h6'}
                        sx={{ flexGrow: 1 }}
                      >
                        <Box className="tw-inline-block">{rLIB('Receipt')}</Box>
                      </Typography>
                    </Toolbar>
                  </AppBar>
                  <DialogContent className="tw-p-10">
                    <Box className="tw-p-0">
                      <Box className="">
                        <Masonry
                          columns={{ xs: 1, sm: 2, md: 3 }}
                          spacing={2}
                        >
                          <Box>
                            <Icon icon="calendar-days" /> {rLIB('Purchase Date')}
                            <Box className="tw-opacity-50">
                              {/* {returnFormattedDate(rowData.purchase_date, 'D MMM YYYY')} */}
                              {rowData.purchase_date}
                            </Box>
                          </Box>
                          <Box>
                            <Icon icon="diagram-project" /> {rLIB('Project ID')}
                            <Box className="tw-opacity-50">{rowData.associated_project_id_number}</Box>
                          </Box>
                          <Box>
                            <Icon icon="user" /> {rLIB('Name')}
                            <Box className="tw-opacity-50">{rowData.associated_user_name}</Box>
                          </Box>
                          <Box>
                            <Icon icon="circle-question" /> {rLIB('Reason')}
                            <Box className="tw-opacity-50">{rowData.purchase_reason}</Box>
                          </Box>
                          <Box>
                            <Icon icon="store" /> {rLIB('Store')}
                            <Box className="tw-opacity-50">{rowData.purchase_store}</Box>
                          </Box>
                          <Box>
                            <Icon icon="notes" /> {rLIB('Notes')}
                            <Box className="tw-opacity-50">{rowData.purchase_notes}</Box>
                          </Box>
                          <Box>
                            <Icon icon="credit-card" /> {rLIB('Total')}
                            <Box className="tw-opacity-50">
                              {typeof rowData.purchase_total === 'number' && !isNaN(rowData.purchase_total)
                                ? formatCurrency(rowData.purchase_total)
                                : 'No valid total'}
                            </Box>
                          </Box>
                          <Box>
                            <Icon icon="tag" /> {rLIB('Category')}
                            <Box className="tw-opacity-50">
                              {capitalizeAllWordsInString(objectToArray(getProp(rowData, 'purchase_categories', {})).join(', '))}
                            </Box>
                          </Box>
                        </Masonry>
                      </Box>
                      <Divider className="tw-my-10" />
                      <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                        <Box
                          sx={{
                            backgroundImage: "url('" + rowData.purchase_image_url + "')",
                            backgroundSize: 'contain',
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'center',
                            mx: 2,
                            height: 800,
                            width: '100%',
                            textAlign: 'center',
                          }}
                        ></Box>
                      </Box>
                    </Box>
                  </DialogContent>
                </Dialog>
              ),
              settings: {
                max_width: 'lg',
              },
            },
          })
        }
      },
    },
  }),
  purchase_date: TableCellBasic('purchase_date', rLIB('Purchase Date'), 'purchase_date'),
  project_id: TableCellBasic('associated_project_id_number', rLIB('Project ID'), 'associated_project_id_number'),
  purchase_categories: TableCellCommaSeparated('purchase_categories', rLIB('Category'), 'purchase_categories'),
  user_names: TableCellBasic('associated_user_name', rLIB('Name'), 'associated_user_name'),
  purchase_reason: TableCellBasic('purchase_reason', rLIB('Reason'), 'purchase_reason'),
  purchase_store: TableCellBasic('purchase_store', rLIB('Store'), 'purchase_store'),
  purchase_notes: TableCellTruncatedString('purchase_notes', rLIB('Notes'), 'purchase_notes', 50),
  purchase_total: TableCellCurrency('purchase_total', rLIB('Total'), 'purchase_total'),
}

export const tableSettings_MaterialReceipts: TsInterface_TableDatabaseSettings = {
  rows_per_page: 100,
  show_header: true,
  size: 'small',
  sort_direction: 'desc',
  sort_property: 'purchase_date',
  use_live_data: true,
}
