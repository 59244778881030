///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			containers are pages / views used in the app and are made up of components and can interact with services and models

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import {
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from '@mui/material/'
import { useContext, useEffect, useReducer, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import { AuthenticatedContainer } from 'rfbp_aux/containers/authenticated_container'
import { ApplicationNavPages, ApplicationPages } from 'rfbp_aux/data/application_structure'
import { DatabaseRef_UsersWithSpecificTaskRole_Query } from 'rfbp_aux/services/database_endpoints/directory/users'
import {
  DatabaseRef_GlobalDesktopWiki_DevRequestsByStatus_Query,
  DatabaseRef_GlobalDesktopWiki_DevRequest_Document,
} from 'rfbp_aux/services/database_endpoints/global/wiki'
import { StorageRef_DevRequestImages } from 'rfbp_aux/services/storage_endpoints/dev_requests'
import { Icon } from 'rfbp_core/components/icons'
import { TableBasic, TableCellManage, TsInterface_TableColumns, TsInterface_TableDataRow, TsInterface_TableSettings } from 'rfbp_core/components/table'
import { TabsUrl } from 'rfbp_core/components/tabs'
import { rLIB } from 'rfbp_core/localization/library'
import {
  Context_RootData_ClientKey,
  Context_UserInterface_CustomDialog,
  Context_UserInterface_ErrorDialog,
  Context_UserInterface_FormDialog,
} from 'rfbp_core/services/context'
import { DatabaseGetLiveCollection, DatabaseSetMergeDocument, StorageUploadFile } from 'rfbp_core/services/database_management'
import { getProp, objectToArray, returnFormattedDate } from 'rfbp_core/services/helper_functions'
import { directAppNavigation } from 'rfbp_core/services/navigation/navigation_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject, TsType_VoidFunction } from 'rfbp_core/typescript/global_types'

import { v4 as uuidv4 } from 'uuid'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

// Authenticated Nav Data
const pageKey: string = ApplicationPages['IssueTrackerIndexPage']['key']

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Container
///////////////////////////////

export const Container: React.FC = (): JSX.Element => {
  // Props
  // const params = useParams()
  // const itemKey: string = params.id as string

  // Hooks - useContext, useState, useReducer, other
  // { sort-start } - hooks
  // const [ us_items, us_setItems ] = 		useState<TsInterface_UnspecifiedObject>({})
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)
  const un_routerNavigation = useNavigate()
  // { sort-end } - hooks

  const [us_unassignedTasks, us_setUnassignedTasks] = useState<TsInterface_UnspecifiedObject>({})
  const [us_assignedTasks, us_setassignedTasks] = useState<TsInterface_UnspecifiedObject>({})
  const [us_completedTasks, us_setCompletedTasks] = useState<TsInterface_UnspecifiedObject>({})

  const [us_selectedFeedbackRequest, us_setSelectedFeedbackRequest] = useState<TsInterface_UnspecifiedObject | null>(null)
  const [us_dialogOpen, us_setDialogOpen] = useState(false)

  const [us_rejectDialogOpen, us_setRejectDialogOpen] = useState(false)
  const [us_taskToReject, us_setTaskToReject] = useState<TsInterface_UnspecifiedObject | null>(null)

  const [us_completeDialogOpen, us_setCompleteDialogOpen] = useState(false)
  const [us_taskToComplete, us_setTaskToComplete] = useState<TsInterface_UnspecifiedObject | null>(null)
  const [us_developers, us_setDevelopers] = useState<TsInterface_UnspecifiedObject>({})
  const [us_selectedDeveloper, us_setSelectedDeveloper] = useState<string | null>(null) // Store the selected developer's key

  const [isUnassignedDialogOpen, setIsUnassignedDialogOpen] = useState(false)
  const [currentRowData, setCurrentRowData] = useState<TsInterface_TableDataRow | null>(null)

  const [isEditing, setIsEditing] = useState(false)

  const { uc_setUserInterface_CustomDialogDisplay } = useContext(Context_UserInterface_CustomDialog)
  const { uc_setUserInterface_ErrorDialogDisplay } = useContext(Context_UserInterface_ErrorDialog)
  const { uc_setUserInterface_FormDialogDisplay } = useContext(Context_UserInterface_FormDialog)

  const [selectedImageUrl, setSelectedImageUrl] = useState<string | null>(null)
  const [newUploadedFiles, setNewUploadedFiles] = useState<File[]>([]) // Store newly uploaded files

  const [devRequestKey, setDevRequestKey] = useState<string>(uuidv4()) // To store the unique key for the dev request

  // Hooks - useEffect
  useEffect(() => {
    document.title = rLIB('Dev Requests', false) as string
  }, [])

  //get dev requests data
  useEffect(() => {
    let unsubscribeUnassigned: TsType_VoidFunction
    let unsubscribeAssigned: TsType_VoidFunction
    let unsubscribeCompleted: TsType_VoidFunction

    const updateUnassignedTasks = (newData: TsInterface_UnspecifiedObject) => {
      us_setUnassignedTasks(newData)
    }

    const updateAssignedTasks = (newData: TsInterface_UnspecifiedObject) => {
      us_setassignedTasks(newData)
    }

    const updateCompletedTasks = (newData: TsInterface_UnspecifiedObject) => {
      us_setCompletedTasks(newData)
    }

    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        // Fetch all datasets concurrently using Promise.all
        Promise.all([
          DatabaseGetLiveCollection(DatabaseRef_GlobalDesktopWiki_DevRequestsByStatus_Query('unassigned'), updateUnassignedTasks),
          DatabaseGetLiveCollection(DatabaseRef_GlobalDesktopWiki_DevRequestsByStatus_Query('assigned'), updateAssignedTasks),
          DatabaseGetLiveCollection(DatabaseRef_GlobalDesktopWiki_DevRequestsByStatus_Query('completed'), updateCompletedTasks),
        ])
          .then((unsubscribes) => {
            // Save the unsubscribe functions
            unsubscribeUnassigned = unsubscribes[0]
            unsubscribeAssigned = unsubscribes[1]
            unsubscribeCompleted = unsubscribes[2]
          })
          .catch((error) => {
            console.error('Failed to fetch live collections:', error)
          })
      })
      .catch((error) => {
        console.error('Failed to get client key:', error)
      })

    return () => {
      // Clean up all live listeners
      if (typeof unsubscribeUnassigned === 'function') {
        unsubscribeUnassigned()
      }
      if (typeof unsubscribeAssigned === 'function') {
        unsubscribeAssigned()
      }
      if (typeof unsubscribeCompleted === 'function') {
        unsubscribeCompleted()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, us_setUnassignedTasks, us_setassignedTasks, us_setCompletedTasks])

  //Get Developers
  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setDevelopers(newData)
      ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_UsersWithSpecificTaskRole_Query(res_GCK.clientKey, 'developer'), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

  // Functions

  const handleEditClick = (rowData: TsInterface_TableDataRow) => {
    us_setSelectedFeedbackRequest(rowData) // Set the selected feedback request
    setIsEditing(true) // Set the dialog to edit mode
    us_setDialogOpen(true) // Open the dialog
  }

  const handleDropdownChange = (rowData: TsInterface_UnspecifiedObject, field: string, newValue: string) => {
    // Update the Firestore document
    DatabaseSetMergeDocument(DatabaseRef_GlobalDesktopWiki_DevRequest_Document(rowData.key), { [field]: newValue })
      .then(() => {
        console.log(`Successfully updated ${field} to ${newValue}`)
      })
      .catch((error) => {
        console.error(`Failed to update ${field}:`, error)
      })
  }

  const handleDeveloperChange = (rowData: TsInterface_UnspecifiedObject, newDeveloperKey: string) => {
    if (!newDeveloperKey) {
      // No developer selected, set status to 'unassigned' and clear developer info
      let updatedData = {
        associated_developer_name: null,
        associated_developer_key: null,
        status: 'unassigned',
      }

      DatabaseSetMergeDocument(DatabaseRef_GlobalDesktopWiki_DevRequest_Document(rowData.key), updatedData)
        .then(() => console.log('Developer unassigned and task status set to unassigned.'))
        .catch((error) => console.error('Failed to update developer:', error))
    } else {
      // Developer selected, set status to 'assigned' if it's currently 'unassigned'
      const developer = us_developers[newDeveloperKey]
      let updatedData: any = {
        associated_developer_name: developer.name,
        associated_developer_key: developer.key,
      }

      // Auto-change status if the task is currently unassigned
      if (rowData.status === 'unassigned') {
        updatedData.status = 'assigned'
      }

      DatabaseSetMergeDocument(DatabaseRef_GlobalDesktopWiki_DevRequest_Document(rowData.key), updatedData)
        .then(() => console.log(`Successfully updated developer to ${developer.name}`))
        .catch((error) => console.error('Failed to update developer:', error))
    }
  }

  const handleRejectClick = (rowData: TsInterface_UnspecifiedObject) => {
    us_setTaskToReject(rowData)
    us_setRejectDialogOpen(true) // Open the reject confirmation dialog
  }

  const handleConfirmReject = () => {
    if (us_taskToReject) {
      DatabaseSetMergeDocument(DatabaseRef_GlobalDesktopWiki_DevRequest_Document(us_taskToReject.key), {
        status: 'rejected',
      })
        .then(() => {
          console.log('Task successfully rejected.')
          us_setRejectDialogOpen(false) // Close the dialog after confirmation
          us_setTaskToReject(null) // Clear the selected task
        })
        .catch((error) => {
          console.error('Failed to reject task:', error)
        })
    }
  }

  const handleCompleteClick = (rowData: TsInterface_UnspecifiedObject) => {
    us_setTaskToComplete(rowData) // Set the task to complete
    us_setCompleteDialogOpen(true) // Open the confirmation dialog
  }

  const handleConfirmComplete = () => {
    if (us_taskToComplete) {
      // Prepare the data to update Firestore
      const updatedData = {
        status: 'completed',
        timestamp_completed: new Date(), // Add the current timestamp
      }

      // Update Firestore with the new status and timestamp
      DatabaseSetMergeDocument(DatabaseRef_GlobalDesktopWiki_DevRequest_Document(us_taskToComplete.key), updatedData)
        .then(() => {
          console.log('Task successfully completed with timestamp.')
          us_setCompleteDialogOpen(false) // Close the dialog
          us_setTaskToComplete(null) // Clear the task
          ur_forceRerender() // Re-render the table
        })
        .catch((error) => {
          console.error('Failed to complete task:', error)
        })
    }
  }

  const handleFileUpload = (files: File[]): Promise<string[]> => {
    return new Promise((resolve, reject) => {
      if (!files || files.length === 0) {
        return resolve([]) // If no files, return an empty array
      }

      const uploadPromises: Promise<any>[] = []
      const uploadedUrls: string[] = []

      // Process each file and upload
      for (let i = 0; i < files.length; i++) {
        const file = files[i]
        const uniqueFileName = `${uuidv4()}_${file.name}` // Generate unique filename with UUID

        const uploadPromise = StorageUploadFile(
          StorageRef_DevRequestImages(
            'etw_energy', // The clientKey
            devRequestKey, // The generated devRequestKey
            uniqueFileName, // The unique filename
          ),
          file, // The file to upload
          {}, // Metadata
        )
          .then((res_SUF: any) => {
            const url = getProp(res_SUF, 'url', null)
            if (url) {
              uploadedUrls.push(url) // Store URL
            }
          })
          .catch((error) => {
            console.error('Error uploading file:', error)
            reject(error)
          })

        uploadPromises.push(uploadPromise)
      }

      // Wait for all uploads to complete
      Promise.all(uploadPromises)
        .then(() => {
          setNewUploadedFiles(uploadedUrls as any) // Store URLs in state
          resolve(uploadedUrls) // Resolve with uploaded URLs
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  const handleSave = () => {
    if (!us_selectedFeedbackRequest) return

    // Ensure image_urls is always an array
    const existingImageUrls = Array.isArray(us_selectedFeedbackRequest.image_urls) ? us_selectedFeedbackRequest.image_urls : []

    // If there are new files to upload, handle the file upload
    if (newUploadedFiles.length > 0) {
      handleFileUpload(newUploadedFiles)
        .then((uploadedUrls) => {
          console.log('New files uploaded. URLs:', uploadedUrls)

          // Merge the new uploaded image URLs with existing ones
          const updatedImageUrls = [
            ...existingImageUrls, // Existing URLs
            ...uploadedUrls, // New URLs
          ]

          // Define the data to update, including new image URLs
          const updateObject = {
            associated_user_name: us_selectedFeedbackRequest.associated_user_name,
            subject: us_selectedFeedbackRequest.subject,
            body: us_selectedFeedbackRequest.body,
            image_urls: updatedImageUrls, // Append new URLs to existing ones
          }

          // Save the updated request to the database
          DatabaseSetMergeDocument(DatabaseRef_GlobalDesktopWiki_DevRequest_Document(us_selectedFeedbackRequest.key), updateObject)
            .then((res_DAD) => {
              console.log('Update successful:', res_DAD)
              setIsEditing(false) // Close edit mode
              us_setDialogOpen(false) // Close dialog
            })
            .catch((error) => {
              console.error('Error updating request:', error)
              uc_setUserInterface_ErrorDialogDisplay({
                display: true,
                error: error.message || 'An error occurred while updating the request.',
              })
            })
        })
        .catch((error) => {
          console.error('Error uploading files:', error)
        })
    } else {
      // No new files to upload, just update the text fields
      const updateObject = {
        associated_user_name: us_selectedFeedbackRequest.associated_user_name,
        subject: us_selectedFeedbackRequest.subject,
        body: us_selectedFeedbackRequest.body,
        image_urls: existingImageUrls, // No change in image URLs
      }

      // Save the updated request to the database
      DatabaseSetMergeDocument(DatabaseRef_GlobalDesktopWiki_DevRequest_Document(us_selectedFeedbackRequest.key), updateObject)
        .then((res_DAD) => {
          console.log('Update successful:', res_DAD)
          setIsEditing(false) // Close edit mode
          us_setDialogOpen(false) // Close dialog
        })
        .catch((error) => {
          console.error('Error updating request:', error)
          uc_setUserInterface_ErrorDialogDisplay({
            display: true,
            error: error.message || 'An error occurred while updating the request.',
          })
        })
    }
  }

  // JSX Generation

  const tableColumns_unassignedDevRequests: TsInterface_TableColumns = {
    manage: TableCellManage({
      view: {
        icon: (
          <Icon
            icon="eye"
            type="solid"
          />
        ),
        label: <>{rLIB('View')}</>,
        onClick: (rowData: TsInterface_TableDataRow) => {
          if (rowData != null) {
            viewDevRequest(rowData) // Open the feedback dialog
          }
        },
      },
      reject: {
        icon: (
          <Icon
            icon="times-circle"
            type="solid"
          />
        ),
        label: <>{rLIB('Reject')}</>,
        onClick: (rowData: TsInterface_TableDataRow) => {
          if (rowData != null && rowData.status !== 'completed') {
            handleRejectClick(rowData) // Trigger the reject task flow
          }
        },
      },
    }),

    timestamp_created: {
      header: {
        header_jsx: () => <Box>{rLIB('Date Created')}</Box>,
        header_sort_by: 'timestamp_created',
      },
      cell: {
        cell_jsx: (rowData: TsInterface_UnspecifiedObject) => (
          <Typography>{rowData.timestamp_created ? returnFormattedDate(rowData.timestamp_created, 'D MMM YYYY h:mm a') : ''}</Typography>
        ),
      },
    },

    associated_developer_name: {
      header: {
        header_jsx: () => <Box>{rLIB('Developer Assigned')}</Box>,
        header_sort_by: 'associated_developer_name',
      },
      cell: {
        cell_jsx: (rowData: TsInterface_UnspecifiedObject) => (
          <Select
            value={rowData.associated_developer_key || ''} // Ensure you set the current developer based on the task's current assigned developer
            onChange={(event) => {
              const selectedKey = event.target.value
              us_setSelectedDeveloper(selectedKey) // Update the selected developer key in state

              // Call handleDeveloperChange to update Firestore with the selected developer's name and key
              handleDeveloperChange(rowData, selectedKey)
            }}
            fullWidth
            displayEmpty
            variant="standard"
            sx={{
              'backgroundColor': 'transparent',
              'color': 'inherit',
              '.MuiSelect-icon': {
                color: 'inherit',
              },
              '&:before': {
                borderBottom: 'none',
              },
              '&:after': {
                borderBottom: 'none',
              },
            }}
          >
            <MenuItem value="">No Developer Assigned</MenuItem>
            {Object.keys(us_developers).map((developerKey) => (
              <MenuItem
                key={developerKey}
                value={developerKey}
              >
                {us_developers[developerKey].name}
              </MenuItem>
            ))}
          </Select>
        ),
      },
    },
    subject: {
      header: {
        header_jsx: () => <Box>{rLIB('Subject')}</Box>,
        header_sort_by: 'subject',
      },
      cell: {
        cell_jsx: (rowData: TsInterface_UnspecifiedObject) => <>{rowData.subject ? rowData.subject : 'No Subject'}</>,
      },
    },
    body: {
      header: {
        header_jsx: () => <Box>{rLIB('Description')}</Box>,
        header_sort_by: 'body',
      },
      cell: {
        cell_jsx: (rowData: TsInterface_UnspecifiedObject) => (
          <>
            {rowData.body
              ? rowData.body.length > 30
                ? `${rowData.body.substring(0, 30)}...` // Truncate and add ellipsis
                : rowData.body
              : 'No Description'}
          </>
        ),
      },
    },

    difficulty_rating: {
      header: {
        header_jsx: () => <Box>{rLIB('Difficulty Rating')}</Box>,
        header_sort_by: 'difficulty_rating',
      },
      cell: {
        cell_jsx: (rowData: TsInterface_UnspecifiedObject) => (
          <Select
            value={rowData.difficulty_rating || ''}
            onChange={(e) => handleDropdownChange(rowData, 'difficulty_rating', e.target.value)}
            fullWidth
            displayEmpty
            variant="standard"
            sx={{
              'backgroundColor': 'transparent', // Match table background
              'color': 'inherit', // Inherit text color
              '.MuiSelect-icon': {
                color: 'inherit', // Ensure the dropdown arrow inherits color
              },
              '&:before': {
                borderBottom: 'none', // Remove underline
              },
              '&:after': {
                borderBottom: 'none', // Remove underline
              },
            }}
          >
            <MenuItem value="">No Rating</MenuItem>
            <MenuItem value="Low">Low</MenuItem>
            <MenuItem value="Low-Med">Low-Med</MenuItem>
            <MenuItem value="Med">Med</MenuItem>
            <MenuItem value="Med-High">Med-High</MenuItem>
            <MenuItem value="High">High</MenuItem>
          </Select>
        ),
      },
    },
    impact_rating: {
      header: {
        header_jsx: () => <Box>{rLIB('Impact Rating')}</Box>,
        header_sort_by: 'impact_rating',
      },
      cell: {
        cell_jsx: (rowData: TsInterface_UnspecifiedObject) => (
          <Select
            value={rowData.impact_rating || ''}
            onChange={(e) => handleDropdownChange(rowData, 'impact_rating', e.target.value)}
            fullWidth
            displayEmpty
            variant="standard"
            sx={{
              'backgroundColor': 'transparent', // Match table background
              'color': 'inherit', // Inherit text color
              '.MuiSelect-icon': {
                color: 'inherit', // Ensure the dropdown arrow inherits color
              },
              '&:before': {
                borderBottom: 'none', // Remove underline
              },
              '&:after': {
                borderBottom: 'none', // Remove underline
              },
            }}
          >
            <MenuItem value="">No Rating</MenuItem>
            <MenuItem value="Low">Low</MenuItem>
            <MenuItem value="Low-Med">Low-Med</MenuItem>
            <MenuItem value="Med">Med</MenuItem>
            <MenuItem value="Med-High">Med-High</MenuItem>
            <MenuItem value="High">High</MenuItem>
          </Select>
        ),
      },
    },

    associated_page_key: {
      header: {
        header_jsx: () => <Box>{rLIB('Page')}</Box>,
        header_sort_by: 'associated_page_key',
      },
      cell: {
        cell_jsx: (rowData: TsInterface_UnspecifiedObject) => {
          const isValidLink = checkPageLinkValidity(rowData.associated_page_key)

          return (
            <>
              {isValidLink ? (
                <Typography
                  component="a"
                  onClick={() => navigateToPage(rowData.associated_page_key)} // Call navigation only if valid
                  style={{ color: '#007bff', textDecoration: 'underline', cursor: 'pointer' }} // Styling for clickable link
                >
                  {rowData.associated_page_key}
                </Typography>
              ) : (
                <Typography>{rowData.associated_page_key || 'No Page'}</Typography>
              )}
            </>
          )
        },
      },
    },
    associated_user_name: {
      header: {
        header_jsx: () => <Box>{rLIB('Reported By')}</Box>,
        header_sort_by: 'associated_user_name',
      },
      cell: {
        cell_jsx: (rowData: TsInterface_UnspecifiedObject) => <>{rowData.associated_user_name ? rowData.associated_user_name : 'Unknown'}</>,
      },
    },
    type: {
      header: {
        header_jsx: () => <Box>{rLIB('Type')}</Box>,
        header_sort_by: 'type',
      },
      cell: {
        cell_jsx: (rowData: TsInterface_UnspecifiedObject) => (
          <>{rowData.type === 'known_issues' ? 'Bug' : rowData.type === 'future_improvement' ? 'Feature Request' : 'Unknown Type'}</>
        ),
      },
    },
  }

  // Function to check if the pageKey is valid for navigation
  const checkPageLinkValidity = (pageKey: string): boolean => {
    // Check if the pageKey exists in ApplicationNavPages and has a valid URL
    return pageKey != null && ApplicationNavPages != null && ApplicationNavPages[pageKey] != null && ApplicationNavPages[pageKey]['url'] != null
  }

  // Function to navigate to a specific page based on the pageKey
  const navigateToPage = (pageKey: string): void => {
    if (checkPageLinkValidity(pageKey)) {
      directAppNavigation(un_routerNavigation, ApplicationNavPages[pageKey]['url'])
    }
  }

  const tableSettings_unassignedDevRequests: TsInterface_TableSettings = {
    paginated: false,
    size: 'small',
    sort_direction: 'asc',
    sort_property_default: 'timestamp_created',
    sortable: true,
    alternate_row_color_hex: themeVariables.background_hover,
    alternate_row_colors: true,
    sticky_header: true,
    sticky_table_height: 'calc(100vh - 180px)',
  }

  //assigned dev requests table columns
  const tableColumns_assignedDevRequests: TsInterface_TableColumns = {
    manage: TableCellManage({
      view: {
        icon: (
          <Icon
            icon="eye"
            type="solid"
          />
        ),
        label: <>{rLIB('View')}</>,
        onClick: (rowData: TsInterface_TableDataRow) => {
          if (rowData != null) {
            viewDevRequest(rowData) // Open the feedback dialog
          }
        },
      },

      reject: {
        icon: (
          <Icon
            icon="times-circle"
            type="solid"
          />
        ),
        label: <>{rLIB('Reject')}</>,
        onClick: (rowData: TsInterface_TableDataRow) => {
          if (rowData != null && rowData.status !== 'completed') {
            handleRejectClick(rowData) // Trigger the reject task flow
          }
        },
      },
      complete: {
        icon: (
          <Icon
            icon="check-circle"
            type="solid"
          />
        ),
        label: <>{rLIB('Complete Task')}</>,
        onClick: (rowData: TsInterface_TableDataRow) => {
          if (rowData != null) {
            handleCompleteClick(rowData) // Trigger the complete task flow
          }
        },
      },
    }),

    timestamp_created: {
      header: {
        header_jsx: () => <Box>{rLIB('Date Created')}</Box>,
        header_sort_by: 'timestamp_created',
      },
      cell: {
        cell_jsx: (rowData: TsInterface_UnspecifiedObject) => (
          <Typography>{rowData.timestamp_created ? returnFormattedDate(rowData.timestamp_created, 'D MMM YYYY h:mm a') : ''}</Typography>
        ),
      },
    },

    associated_developer_name: {
      header: {
        header_jsx: () => <Box>{rLIB('Developer Assigned')}</Box>,
        header_sort_by: 'associated_developer_name',
      },
      cell: {
        cell_jsx: (rowData: TsInterface_UnspecifiedObject) => (
          <Select
            value={rowData.associated_developer_key || ''}
            onChange={(event) => {
              const selectedKey = event.target.value
              us_setSelectedDeveloper(selectedKey)
              handleDeveloperChange(rowData, selectedKey)
            }}
            fullWidth
            displayEmpty
            variant="standard"
            sx={{
              'backgroundColor': 'transparent',
              'color': 'inherit',
              '.MuiSelect-icon': {
                color: 'inherit',
              },
              '&:before': {
                borderBottom: 'none',
              },
              '&:after': {
                borderBottom: 'none',
              },
            }}
          >
            <MenuItem value="">No Developer Assigned</MenuItem>
            {Object.keys(us_developers).map((developerKey) => (
              <MenuItem
                key={developerKey}
                value={developerKey}
              >
                {us_developers[developerKey].name}
              </MenuItem>
            ))}
          </Select>
        ),
      },
    },

    subject: {
      header: {
        header_jsx: () => <Box>{rLIB('Subject')}</Box>,
        header_sort_by: 'subject',
      },
      cell: {
        cell_jsx: (rowData: TsInterface_UnspecifiedObject) => <>{rowData.subject ? rowData.subject : 'No Subject'}</>,
      },
    },

    body: {
      header: {
        header_jsx: () => <Box>{rLIB('Description')}</Box>,
        header_sort_by: 'body',
      },
      cell: {
        cell_jsx: (rowData: TsInterface_UnspecifiedObject) => (
          <>
            {rowData.body
              ? rowData.body.length > 30
                ? `${rowData.body.substring(0, 30)}...` // Truncate and add ellipsis
                : rowData.body
              : 'No Description'}
          </>
        ),
      },
    },

    difficulty_rating: {
      header: {
        header_jsx: () => <Box>{rLIB('Difficulty Rating')}</Box>,
        header_sort_by: 'difficulty_rating',
      },
      cell: {
        cell_jsx: (rowData: TsInterface_UnspecifiedObject) => (
          <Select
            value={rowData.difficulty_rating || ''}
            onChange={(e) => handleDropdownChange(rowData, 'difficulty_rating', e.target.value)}
            fullWidth
            displayEmpty
            variant="standard"
            sx={{
              'backgroundColor': 'transparent', // Match table background
              'color': 'inherit', // Inherit text color
              '.MuiSelect-icon': {
                color: 'inherit', // Ensure the dropdown arrow inherits color
              },
              '&:before': {
                borderBottom: 'none', // Remove underline
              },
              '&:after': {
                borderBottom: 'none', // Remove underline
              },
            }}
          >
            <MenuItem value="">No Rating</MenuItem>
            <MenuItem value="Low">Low</MenuItem>
            <MenuItem value="Low-Med">Low-Med</MenuItem>
            <MenuItem value="Med">Med</MenuItem>
            <MenuItem value="Med-High">Med-High</MenuItem>
            <MenuItem value="High">High</MenuItem>
          </Select>
        ),
      },
    },
    impact_rating: {
      header: {
        header_jsx: () => <Box>{rLIB('Impact Rating')}</Box>,
        header_sort_by: 'impact_rating',
      },
      cell: {
        cell_jsx: (rowData: TsInterface_UnspecifiedObject) => (
          <Select
            value={rowData.impact_rating || ''}
            onChange={(e) => handleDropdownChange(rowData, 'impact_rating', e.target.value)}
            fullWidth
            displayEmpty
            variant="standard"
            sx={{
              'backgroundColor': 'transparent', // Match table background
              'color': 'inherit', // Inherit text color
              '.MuiSelect-icon': {
                color: 'inherit', // Ensure the dropdown arrow inherits color
              },
              '&:before': {
                borderBottom: 'none', // Remove underline
              },
              '&:after': {
                borderBottom: 'none', // Remove underline
              },
            }}
          >
            <MenuItem value="">No Rating</MenuItem>
            <MenuItem value="Low">Low</MenuItem>
            <MenuItem value="Low-Med">Low-Med</MenuItem>
            <MenuItem value="Med">Med</MenuItem>
            <MenuItem value="Med-High">Med-High</MenuItem>
            <MenuItem value="High">High</MenuItem>
          </Select>
        ),
      },
    },

    associated_page_key: {
      header: {
        header_jsx: () => <Box>{rLIB('Page')}</Box>,
        header_sort_by: 'associated_page_key',
      },
      cell: {
        cell_jsx: (rowData: TsInterface_UnspecifiedObject) => {
          const isValidLink = checkPageLinkValidity(rowData.associated_page_key)

          return (
            <>
              {isValidLink ? (
                <Typography
                  component="a"
                  onClick={() => navigateToPage(rowData.associated_page_key)} // Call navigation only if valid
                  style={{ color: '#007bff', textDecoration: 'underline', cursor: 'pointer' }} // Styling for clickable link
                >
                  {rowData.associated_page_key}
                </Typography>
              ) : (
                <Typography>{rowData.associated_page_key || 'No Page'}</Typography>
              )}
            </>
          )
        },
      },
    },
    associated_user_name: {
      header: {
        header_jsx: () => <Box>{rLIB('Reported By')}</Box>,
        header_sort_by: 'associated_user_name',
      },
      cell: {
        cell_jsx: (rowData: TsInterface_UnspecifiedObject) => <>{rowData.associated_user_name ? rowData.associated_user_name : 'Unknown'}</>,
      },
    },

    // status: {
    //   header: {
    //     header_jsx: () => <Box>{rLIB('Status')}</Box>,
    //     header_sort_by: 'status',
    //   },
    //   cell: {
    //     cell_jsx: (rowData: TsInterface_UnspecifiedObject) => (
    //       <Typography>{rowData.status ? rLIB(rowData.status.charAt(0).toUpperCase() + rowData.status.slice(1)) : 'No Status'}</Typography>
    //     ),
    //   },
    // },

    type: {
      header: {
        header_jsx: () => <Box>{rLIB('Type')}</Box>,
        header_sort_by: 'type',
      },
      cell: {
        cell_jsx: (rowData: TsInterface_UnspecifiedObject) => (
          <>{rowData.type === 'known_issues' ? 'Bug' : rowData.type === 'future_improvement' ? 'Feature Request' : 'Unknown Type'}</>
        ),
      },
    },
  }

  const tableSettings_assignedDevRequests: TsInterface_TableSettings = {
    paginated: false,
    size: 'small',
    sort_direction: 'asc',
    sort_property_default: 'timestamp_created',
    sortable: true,
    alternate_row_color_hex: themeVariables.background_hover,
    alternate_row_colors: true,
    sticky_header: true,
    sticky_table_height: 'calc(100vh - 180px)',
  }

  //completed dev requests table columns
  const tableColumns_completedDevRequests: TsInterface_TableColumns = {
    manage: TableCellManage({
      view: {
        icon: (
          <Icon
            icon="eye"
            type="solid"
          />
        ),
        label: <>{rLIB('View')}</>,
        onClick: (rowData: TsInterface_TableDataRow) => {
          if (rowData != null) {
            viewDevRequest(rowData) // Open the feedback dialog
          }
        },
      },
    }),

    timestamp_completed: {
      header: {
        header_jsx: () => <Box>{rLIB('Date Completed')}</Box>,
        header_sort_by: 'timestamp_completed',
      },
      cell: {
        cell_jsx: (rowData: TsInterface_UnspecifiedObject) => (
          <Box>{rowData.timestamp_completed ? returnFormattedDate(rowData.timestamp_completed, 'D MMM YYYY h:mm a') : rLIB('No Completed Date')}</Box>
        ),
      },
    },

    associated_developer_name: {
      header: {
        header_jsx: () => <Box>{rLIB('Developer Assigned')}</Box>,
        header_sort_by: 'associated_developer_name',
      },
      cell: {
        cell_jsx: (rowData: TsInterface_UnspecifiedObject) => <Typography>{rowData.associated_developer_name || 'No Developer Assigned'}</Typography>,
      },
    },

    subject: {
      header: {
        header_jsx: () => <Box>{rLIB('Subject')}</Box>,
        header_sort_by: 'subject',
      },
      cell: {
        cell_jsx: (rowData: TsInterface_UnspecifiedObject) => <>{rowData.subject ? rowData.subject : 'No Subject'}</>,
      },
    },

    body: {
      header: {
        header_jsx: () => <Box>{rLIB('Description')}</Box>,
      },
      cell: {
        cell_jsx: (rowData: TsInterface_UnspecifiedObject) => <>{rowData.body ? rowData.body : 'No Description'}</>,
      },
    },

    difficulty_rating: {
      header: {
        header_jsx: () => <Box>{rLIB('Difficulty Rating')}</Box>,
        header_sort_by: 'difficulty_rating',
      },
      cell: {
        cell_jsx: (rowData: TsInterface_UnspecifiedObject) => <Typography>{rowData.difficulty_rating || 'No Rating'}</Typography>,
      },
    },

    impact_rating: {
      header: {
        header_jsx: () => <Box>{rLIB('Impact Rating')}</Box>,
        header_sort_by: 'impact_rating',
      },
      cell: {
        cell_jsx: (rowData: TsInterface_UnspecifiedObject) => <Typography>{rowData.impact_rating || 'No Rating'}</Typography>,
      },
    },

    associated_page_key: {
      header: {
        header_jsx: () => <Box>{rLIB('Page')}</Box>,
        header_sort_by: 'associated_page_key',
      },
      cell: {
        cell_jsx: (rowData: TsInterface_UnspecifiedObject) => {
          const isValidLink = checkPageLinkValidity(rowData.associated_page_key)

          return (
            <>
              {isValidLink ? (
                <Typography
                  component="a"
                  onClick={() => navigateToPage(rowData.associated_page_key)} // Call navigation only if valid
                  style={{ color: '#007bff', textDecoration: 'underline', cursor: 'pointer' }} // Styling for clickable link
                >
                  {rowData.associated_page_key}
                </Typography>
              ) : (
                <Typography>{rowData.associated_page_key || 'No Page'}</Typography>
              )}
            </>
          )
        },
      },
    },

    associated_user_name: {
      header: {
        header_jsx: () => <Box>{rLIB('Reported By')}</Box>,
        header_sort_by: 'associated_user_name',
      },
      cell: {
        cell_jsx: (rowData: TsInterface_UnspecifiedObject) => <>{rowData.associated_user_name ? rowData.associated_user_name : 'Unknown'}</>,
      },
    },

    type: {
      header: {
        header_jsx: () => <Box>{rLIB('Type')}</Box>,
        header_sort_by: 'type',
      },
      cell: {
        cell_jsx: (rowData: TsInterface_UnspecifiedObject) => (
          <>{rowData.type === 'known_issues' ? 'Bug' : rowData.type === 'future_improvement' ? 'Feature Request' : 'Unknown Type'}</>
        ),
      },
    },
    status: {
      header: {
        header_jsx: () => <Box>{rLIB('Status')}</Box>,
        header_sort_by: 'status',
      },
      cell: {
        cell_jsx: (rowData: TsInterface_UnspecifiedObject) => (
          <Typography>{rowData.status ? rLIB(rowData.status.charAt(0).toUpperCase() + rowData.status.slice(1)) : 'No Status'}</Typography>
        ),
      },
    },
  }

  const tableSettings_completedDevRequests: TsInterface_TableSettings = {
    paginated: false,
    size: 'small',
    sort_direction: 'desc',
    sort_property_default: 'timestamp_completed',
    sortable: true,
    alternate_row_color_hex: themeVariables.background_hover,
    alternate_row_colors: true,
    sticky_header: true,
    sticky_table_height: 'calc(100vh - 180px)',
  }

  const rJSX_RejectDialog = () => {
    return (
      <Dialog
        open={us_rejectDialogOpen}
        onClose={() => us_setRejectDialogOpen(false)}
      >
        <DialogTitle>{rLIB('Confirm Rejection')}</DialogTitle>
        <DialogContent>
          <Typography>{rLIB('Are you sure you want to reject this request?')}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => us_setRejectDialogOpen(false)}>{rLIB('No')}</Button>
          <Button
            onClick={handleConfirmReject}
            color="primary"
          >
            {rLIB('Yes')}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  const rJSX_unassignedDialog = () => {
    return (
      <Dialog
        open={isUnassignedDialogOpen}
        onClose={() => setIsUnassignedDialogOpen(false)}
      >
        <DialogTitle>{rLIB('Assign Task')}</DialogTitle>
        <DialogContent>
          <Typography>{rLIB('Please assign the task before completing it.')}</Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setIsUnassignedDialogOpen(false)}
            color="primary"
          >
            {rLIB('Close')}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  const rJSX_CompleteTaskDialog = () => {
    return (
      <Dialog
        open={us_completeDialogOpen}
        onClose={() => us_setCompleteDialogOpen(false)}
      >
        <DialogTitle>{rLIB('Confirm Task Completion')}</DialogTitle>
        <DialogContent>
          <Typography>{rLIB('Are you sure you want to complete this task?')}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => us_setCompleteDialogOpen(false)}>{rLIB('No')}</Button>
          <Button
            onClick={handleConfirmComplete}
            color="primary"
          >
            {rLIB('Yes')}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  const rJSX_devRequestDialog = ({ isEditing, setIsEditing }: { isEditing: any; setIsEditing: any }) => {
    const [selectedImageUrl, setSelectedImageUrl] = useState<string | null>(null)
    const [newUploadedFiles, setNewUploadedFiles] = useState<File[]>([]) // Store newly uploaded files

    // Function to handle image clicks and preview
    const handleImageClick = (url: string) => {
      setSelectedImageUrl(url)
    }

    const handleCloseImageDialog = () => {
      setSelectedImageUrl(null)
    }

    // Handle file selection for additional image uploads
    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const files = event.target.files
      if (files) {
        const fileArray = Array.from(files)
        console.log('Additional files selected for upload:', fileArray)
        setNewUploadedFiles(fileArray) // Set files for additional upload
      }
    }

    const handleSave = () => {
      if (!us_selectedFeedbackRequest) return

      // Check if image_urls is defined and is an array; if not, default to an empty array
      const existingImageUrls = Array.isArray(us_selectedFeedbackRequest.image_urls) ? us_selectedFeedbackRequest.image_urls : []

      // Log the existing image URLs to ensure it's working
      console.log('Existing image URLs:', existingImageUrls)

      // If there are new files to upload, handle the file upload
      if (newUploadedFiles.length > 0) {
        handleFileUpload(newUploadedFiles)
          .then((uploadedUrls) => {
            console.log('New files uploaded. URLs:', uploadedUrls)

            // If uploadedUrls is not an array, default to an empty array
            const validUploadedUrls = Array.isArray(uploadedUrls) ? uploadedUrls : []

            // Merge the new uploaded image URLs with existing ones
            const updatedImageUrls = [
              ...existingImageUrls, // Existing URLs
              ...validUploadedUrls, // New URLs
            ]

            // Log the final array of URLs before saving
            console.log('Final image URLs to save:', updatedImageUrls)

            // Define the data to update, including new image URLs
            const updateObject = {
              associated_user_name: us_selectedFeedbackRequest.associated_user_name,
              subject: us_selectedFeedbackRequest.subject,
              body: us_selectedFeedbackRequest.body,
              image_urls: updatedImageUrls, // Append new URLs to existing ones
            }

            // Save the updated request to the database
            DatabaseSetMergeDocument(DatabaseRef_GlobalDesktopWiki_DevRequest_Document(us_selectedFeedbackRequest.key), updateObject)
              .then((res_DAD) => {
                console.log('Update successful:', res_DAD)

                // Clear uploaded files after saving
                setNewUploadedFiles([])
                setIsEditing(false) // Close edit mode
                us_setDialogOpen(false) // Close dialog
              })
              .catch((error) => {
                console.error('Error updating request:', error)
                uc_setUserInterface_ErrorDialogDisplay({
                  display: true,
                  error: error.message || 'An error occurred while updating the request.',
                })
              })
          })
          .catch((error) => {
            console.error('Error uploading files:', error)
          })
      } else {
        // No new files to upload, just update the text fields
        const updateObject = {
          associated_user_name: us_selectedFeedbackRequest.associated_user_name,
          subject: us_selectedFeedbackRequest.subject,
          body: us_selectedFeedbackRequest.body,
          image_urls: existingImageUrls, // No change in image URLs
        }

        // Save the updated request to the database
        DatabaseSetMergeDocument(DatabaseRef_GlobalDesktopWiki_DevRequest_Document(us_selectedFeedbackRequest.key), updateObject)
          .then((res_DAD) => {
            console.log('Update successful:', res_DAD)

            // Clear uploaded files after saving
            setNewUploadedFiles([])
            setIsEditing(false) // Close edit mode
            us_setDialogOpen(false) // Close dialog
          })
          .catch((error) => {
            console.error('Error updating request:', error)
            uc_setUserInterface_ErrorDialogDisplay({
              display: true,
              error: error.message || 'An error occurred while updating the request.',
            })
          })
      }
    }

    // Handle closing the dialog and reset editing state
    const handleCloseDialog = () => {
      // Clear uploaded files when closing the dialog
      setNewUploadedFiles([])

      setIsEditing(false) // Reset editing state when closing the dialog
      us_setDialogOpen(false) // Close the dialog
    }

    return (
      <Dialog
        open={us_dialogOpen}
        onClose={handleCloseDialog}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>
          {us_selectedFeedbackRequest ? `${us_selectedFeedbackRequest.associated_user_name} - ${us_selectedFeedbackRequest.subject}` : rLIB('View Dev Request')}
        </DialogTitle>
        <DialogContent>
          {us_selectedFeedbackRequest ? (
            <>
              <Card>
                <Table>
                  <TableBody>
                    {/* User Name - Non-editable */}
                    <TableRow>
                      <TableCell>{rLIB('User Name')}</TableCell>
                      <TableCell>{us_selectedFeedbackRequest?.associated_user_name || 'N/A'}</TableCell>
                    </TableRow>
                    {/* Dev Request Date */}
                    <TableRow>
                      <TableCell>{rLIB('Date Created')}</TableCell>
                      <TableCell>
                        {us_selectedFeedbackRequest?.timestamp_created
                          ? new Date(us_selectedFeedbackRequest.timestamp_created.seconds * 1000).toLocaleDateString()
                          : 'N/A'}
                      </TableCell>
                    </TableRow>

                    {/* Dev Request Subject - Editable */}
                    <TableRow>
                      <TableCell>{rLIB('Subject')}</TableCell>
                      <TableCell>
                        {isEditing ? (
                          <TextField
                            fullWidth
                            value={us_selectedFeedbackRequest.subject}
                            onChange={(e) =>
                              us_setSelectedFeedbackRequest({
                                ...us_selectedFeedbackRequest,
                                subject: e.target.value,
                              })
                            }
                          />
                        ) : (
                          us_selectedFeedbackRequest?.subject || 'N/A'
                        )}
                      </TableCell>
                    </TableRow>

                    {/* Description - Editable */}
                    <TableRow>
                      <TableCell>{rLIB('Description')}</TableCell>
                      <TableCell>
                        {isEditing ? (
                          <TextField
                            fullWidth
                            multiline
                            value={us_selectedFeedbackRequest.body}
                            onChange={(e) =>
                              us_setSelectedFeedbackRequest({
                                ...us_selectedFeedbackRequest,
                                body: e.target.value,
                              })
                            }
                          />
                        ) : (
                          us_selectedFeedbackRequest?.body || 'N/A'
                        )}
                      </TableCell>
                    </TableRow>

                    {/* Status */}
                    <TableRow>
                      <TableCell>{rLIB('Status')}</TableCell>
                      <TableCell>{us_selectedFeedbackRequest?.status || 'N/A'}</TableCell>
                    </TableRow>

                    {/* Developer */}
                    <TableRow>
                      <TableCell>{rLIB('Developer')}</TableCell>
                      <TableCell>{us_selectedFeedbackRequest?.associated_developer_name || 'N/A'}</TableCell>
                    </TableRow>

                    {/* Page Key */}
                    <TableRow>
                      <TableCell>{rLIB('Page Key')}</TableCell>
                      <TableCell>{us_selectedFeedbackRequest?.associated_page_key || 'N/A'}</TableCell>
                    </TableRow>

                    {/* Uploaded Images Display */}
                    {us_selectedFeedbackRequest.image_urls?.length > 0 && (
                      <Box
                        mt={2}
                        p={2}
                      >
                        <Typography variant="h6">{rLIB('Uploaded Images')}</Typography>
                        <Box
                          display="flex"
                          flexWrap="wrap"
                          gap={2}
                          mt={1}
                        >
                          {us_selectedFeedbackRequest.image_urls.map((url: string, index: number) => (
                            <Box
                              key={index}
                              sx={{ position: 'relative', width: '100px', height: '100px', cursor: 'pointer' }}
                              onClick={() => handleImageClick(url)}
                            >
                              <Box
                                component="img"
                                src={url}
                                alt={`Image ${index + 1}`}
                                sx={{
                                  'width': '100%',
                                  'height': '100%',
                                  'objectFit': 'cover',
                                  'borderRadius': '4px',
                                  '&:hover': { opacity: 0.8 },
                                }}
                              />
                            </Box>
                          ))}
                        </Box>
                      </Box>
                    )}

                    {/* New Image Upload */}
                    {isEditing && (
                      <TableRow>
                        <TableCell>{rLIB('Upload More Images')}</TableCell>
                        <TableCell>
                          <input
                            type="file"
                            multiple
                            onChange={handleFileChange}
                          />
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </Card>
            </>
          ) : (
            <Typography>{rLIB('No feedback selected')}</Typography>
          )}
        </DialogContent>

        <DialogActions>
          {isEditing ? (
            <>
              <Button
                onClick={() => setIsEditing(false)}
                color="primary"
              >
                {rLIB('Cancel')}
              </Button>
              <Button
                onClick={handleSave}
                color="primary"
              >
                {rLIB('Save')}
              </Button>
            </>
          ) : (
            <Button
              onClick={() => setIsEditing(true)}
              color="primary"
            >
              {rLIB('Edit')}
            </Button>
          )}
          <Button
            onClick={handleCloseDialog}
            color="primary"
          >
            {rLIB('Close')}
          </Button>
        </DialogActions>

        {/* Image Expansion Dialog */}
        <Dialog
          open={Boolean(selectedImageUrl)}
          onClose={handleCloseImageDialog}
          maxWidth="lg"
          fullWidth
        >
          <DialogTitle>{rLIB('Image Preview')}</DialogTitle>
          <DialogContent>
            {selectedImageUrl && (
              <Box
                component="img"
                src={selectedImageUrl}
                alt="Expanded Preview"
                sx={{ width: '100%', maxHeight: '80vh', objectFit: 'contain' }}
              />
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseImageDialog}>{rLIB('Close')}</Button>
          </DialogActions>
        </Dialog>
      </Dialog>
    )
  }

  const viewDevRequest = (rowData: TsInterface_TableDataRow) => {
    us_setSelectedFeedbackRequest(rowData) // Set the selected feedback request
    us_setDialogOpen(true) // Open the dialog
  }

  const rJSX_UnassignedRequests = (us_unassignedTasks: TsInterface_UnspecifiedObject) => {
    let tableJSX = (
      <Card>
        <Box className="">
          <TableBasic
            tableAdditionalData={{ tab: 'Unassigned' }} // Pass the tab here
            tableColumns={tableColumns_unassignedDevRequests}
            tableData={objectToArray(us_unassignedTasks)}
            tableSettings={tableSettings_unassignedDevRequests}
          />
          {rJSX_devRequestDialog({ isEditing, setIsEditing })}

          {rJSX_RejectDialog()}
          {rJSX_CompleteTaskDialog()}
          {rJSX_unassignedDialog()}
        </Box>
      </Card>
    )
    return tableJSX
  }

  const rJSX_AssignedRequests = (us_assignedTasks: TsInterface_UnspecifiedObject) => {
    let tableJSX = (
      <Card>
        <Box className="">
          <TableBasic
            tableAdditionalData={{ tab: 'Assigned' }} // Pass the tab here
            tableColumns={tableColumns_assignedDevRequests}
            tableData={objectToArray(us_assignedTasks)}
            tableSettings={tableSettings_assignedDevRequests}
          />
          {rJSX_devRequestDialog({ isEditing, setIsEditing })}

          {rJSX_RejectDialog()}
          {rJSX_CompleteTaskDialog()}
        </Box>
      </Card>
    )
    return tableJSX
  }

  const rJSX_CompletedRequests = (us_completedTasks: TsInterface_UnspecifiedObject) => {
    let tableJSX = (
      <Card>
        <Box className="">
          <TableBasic
            tableAdditionalData={{ tab: 'Completed' }} // Pass the tab here
            tableColumns={tableColumns_completedDevRequests}
            tableData={objectToArray(us_completedTasks)}
            tableSettings={tableSettings_completedDevRequests}
          />
          {rJSX_devRequestDialog({ isEditing, setIsEditing })}

          {rJSX_RejectDialog()}
          {rJSX_CompleteTaskDialog()}
        </Box>
      </Card>
    )
    return tableJSX
  }

  const rJSX_DevRequestsTabs = (): JSX.Element => {
    return (
      <TabsUrl
        tabs={[
          {
            tabUrlKey: 'Unassigned_Requests',
            tabHeader: rLIB('Unassigned Requests'),
            tabContent: rJSX_UnassignedRequests(us_unassignedTasks),
            tabButtons: [],
          },
          {
            tabUrlKey: 'Assigned_Requests',
            tabHeader: rLIB('Assigned Requests'),
            tabContent: rJSX_AssignedRequests(us_assignedTasks),
            tabButtons: [],
          },
          {
            tabUrlKey: 'Completed_Requests',
            tabHeader: rLIB('Completed Requests'),
            tabContent: rJSX_CompletedRequests(us_completedTasks),
            tabButtons: [],
          },
        ]}
        tabsSettings={{
          baseUrl: ApplicationPages.IssueTrackerIndexPage.url(),
          tabQueryParam: 'tab',
          overridePageTitle: true,
          basePageTitle: 'Dev Requests',
        }}
      />
    )
  }

  // JSX Generation
  const rJSX_Page = (): JSX.Element => {
    return (
      <Box>
        <AuthenticatedContainer
          pageHeader={rLIB('Dev Requests')}
          pageKey={pageKey}
          content={rJSX_DevRequestsTabs()}
        />

        {/* <Json data={us_developers}></Json> */}
      </Box>
    )
  }

  // Render
  return <>{rJSX_Page()}</>
}
