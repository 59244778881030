//======================================//
//		  ooOOOO USED BY BOILERPLATE    //
//		 oo		 _____					//
//		_I__n_n__||_|| ________			//
//	  >(_________|_7_|-|______|			//
//	   /o ()() ()() o   oo  oo			//
//======================================//

///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			1) Defines all valid URLs and which containers they are linked to
			2) Specifies the global theme/style of the application
			3) Creates live database listeners for user and client information
			4) Generates application permissions based on user and client information
			5) Triggers a rerender when global user, client user, or permissions are updated
			6) Makes user/client info and other global state variables available via context
			7) Provides the physical location where error messages are rendered

			ADDING A NEW PAGE
				1) Create file in containers folder
				2) Import page into "App.tsx" (this file)
				3) Add Route into the Router in "App.tsx" at the bottom (this file)
				4) Add Page into the "ApplicationPages" variable of "applicationStructure.tsx"
				5) IF the page is a root level page, add it to ApplicationNavPages (and ApplicationNavSections if it is in a new section) in "applicationStructure.tsx"

		TODO:
			[ ] Typescript - 1 instance of @ts-expect-error - combined contexts
			[ ] Typescript - 4 instances of any

	*/

///////////////////////////////
// Imports
///////////////////////////////

import CssBaseline from '@mui/material/CssBaseline'
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles'
import { Container as AllAlertsListPage } from 'app/pages/alerts/alerts_list'
import { Container as AdminDatabaseAPIManagementIndexPage } from 'app/pages/api_management/api_management_index'
import { Container as AutomaticDataImportPages } from 'app/pages/api_management/automatic_data_imports'
import { Container as AdminCronjobManagementIndexPage } from 'app/pages/api_management/cronjobs_management_index'
import { Container as BoilerplateDocsPage } from 'app/pages/boilerplate_docs/boilerplate_docs'
import { Container as DesignIndexPage } from 'app/pages/design/design_index'
import { Container as AdminDatabaseCompanyDocumentsPage } from 'app/pages/documents/company_documents_index'
import { Container as AdminDatabaseFinancePartnersListPage } from 'app/pages/finance_partners/finance_partners_list'
import { Container as GMDashboardIndexPage } from 'app/pages/gm_dashboard/gm_dashboard_index'
import { Container as TaskEfficiencyPage } from 'app/pages/gm_dashboard/task_efficiency_index'
import { Container as AdminDatabaseHoasListPage } from 'app/pages/hoas/hoas_list'
import { Container as AdminDatabaseHoaViewPage } from 'app/pages/hoas/hoas_view'
import { Container as HomePage } from 'app/pages/home/home'
import { Container as UserSettingsPage } from 'app/pages/home/user_settings'
import { Container as AdminDatabaseIncidentReportsListPage } from 'app/pages/incident_reports/incident_reports_list'
import { Container as AdminDatabaseIncidentReportViewPage } from 'app/pages/incident_reports/incident_report_view'
import { Container as AdminInvoiceIndexPage } from 'app/pages/invoices/invoices_index'
import { Container as AdminInvoiceSettingsPage } from 'app/pages/invoices/invoice_settings'
import { Container as IssueTrackerIndexPage } from 'app/pages/issue_tracker/issue_tracker_index'
import { Container as AdminDatabaseJurisdictionsListPage } from 'app/pages/jurisdictions/jurisdictions_list'
import { Container as AdminDatabaseJurisdictionViewPage } from 'app/pages/jurisdictions/jurisdiction_view'
import { Container as AdminDatabaseLeaderboardIndexPage } from 'app/pages/leaderboards/leaderboard_index'
import { Container as LeadSourcesIndexPage } from 'app/pages/lead_sources/lead_sources_index'
import { Container as PaymentTermsPage } from 'app/pages/legal/payment_terms'
import { Container as PowerwallInstallAgreementPage } from 'app/pages/legal/powerwall_install_agreement'
import { Container as PrivacyPolicyPage } from 'app/pages/legal/privacy_policy'
import { Container as TermsOfServicePage } from 'app/pages/legal/terms_of_service'
import { Container as LiveReviewIndexPage } from 'app/pages/live_review/live_review_index'
import { Container as MachineLearningIndexPage } from 'app/pages/machine_learning/machine_learning_index'
import { Container as MLEvaluationProcedureViewPage } from 'app/pages/machine_learning/ml_evaluation_procedure_view'
import { Container as AdminDatabaseMaterialsIndexPage } from 'app/pages/materials/materials_index'
import { Container as AdminDatabaseMaterialsOrderViewPage } from 'app/pages/materials/order_view'
import { Container as AdminDatabaseMaterialsSupplierViewPage } from 'app/pages/materials/supplier_view'
import { Container as UnauthenticatedMaterialsSupplierOrdersListPage } from 'app/pages/materials/unauthenticated_supplier_orders_list'
import { Container as CombineManagementPage } from 'app/pages/payroll/combine_management'
import { Container as AdminPayrollIndexPage } from 'app/pages/payroll/payroll_index'
import { Container as AdminPayrollSettingsPage } from 'app/pages/payroll/payroll_settings'
import { Container as ProjectFinanceViewPage } from 'app/pages/payroll/project_view_finance'
import { Container as AdminFinanceSpendingCardholdersViewPage } from 'app/pages/payroll/spending_cardholder_view'
import { Container as AdminFinanceSpendingIndexPage } from 'app/pages/payroll/spending_index'
import { Container as AdminFinanceSpendingProfileViewPage } from 'app/pages/payroll/spending_profile_view'
import { Container as PerformanceManagementPage } from 'app/pages/performance_management/performance_management_index'
import { Container as AdminActiveProjectsListPage } from 'app/pages/projects/active_projects_list'
import { Container as AdminActiveProjectMergePage } from 'app/pages/projects/active_projects_merge'
import { Container as AdminActiveProjectUrlSearchPage } from 'app/pages/projects/active_projects_url_search'
import { Container as AdminActiveProjectViewPage } from 'app/pages/projects/active_projects_view'
import { Container as AdminProjectManualImportsPage } from 'app/pages/projects/manual_project_import'
import { Container as AdminDatabaseRegionsListPage } from 'app/pages/regions/region_list'
import { Container as SalesOpportunitiesIndexPage } from 'app/pages/sales_opportunities/sales_opportunities_index'
import { Container as SalesOpportunityPage } from 'app/pages/sales_opportunities/sales_opportunities_view'
import { Container as SalesProjectsListPage } from 'app/pages/sales_partner/sales_projects_list'
import { Container as SalesProjectViewPage } from 'app/pages/sales_partner/sales_project_view'
import { Container as SalesTasksListPage } from 'app/pages/sales_partner/sales_tasks_list'
import { Container as AdminDatabaseSalesPartnersListPage } from 'app/pages/sales_partners/sales_partners_list'
import { Container as AdminSalesOpportunityPage } from 'app/pages/sales_tools/admin_view'
import { Container as SalesOpportunityDiscoverySessionViewPage } from 'app/pages/sales_tools/sales_campaigns'
import { Container as SalesDoorhangerRoutePage } from 'app/pages/sales_tools/sales_doorhanger_route'
import { Container as SalesOpportunityViewPage } from 'app/pages/sales_tools/sales_opportunity_view'
import { Container as AdminSalesToolsIndexPage } from 'app/pages/sales_tools/sales_tool_index'
import { Container as UnauthenticatedSalesOpportunityPage } from 'app/pages/sales_tools/unauthenticated_sales_opportunity_view'
import { Container as TaskDispatchBookmarkPage } from 'app/pages/scheduler/task_dispatch_bookmark'
import { Container as TaskCalendarSchedulePage } from 'app/pages/scheduler/task_dispatch_index'
import { Container as AdminStatsListPage } from 'app/pages/stats/admin_stats_list'
import { Container as SubKActiveTasksListPage } from 'app/pages/subcontractor/subk_active_tasks_list'
import { Container as SubKProjectViewPage } from 'app/pages/subcontractor/subk_project_view'
import { Container as SunrunApiPage } from 'app/pages/sunrun/sunrun_api'
import { Container as SuperDatabaseManagementPage } from 'app/pages/super/database_management'
import { Container as AllAssignedTasksListPage } from 'app/pages/tasks/assigned_tasks_list'
import { Container as AdminDatabaseTaskFormsListPage } from 'app/pages/task_forms/task_forms_list'
import { Container as AdminDatabaseTaskFormsViewPage } from 'app/pages/task_forms/task_forms_view'
import { Container as AdminDatabaseTasksListPage } from 'app/pages/task_library/tasks_list'
import { Container as AdminDatabaseTaskWorkflowListPage } from 'app/pages/task_workflows/task_workflow_list'
import { Container as AdminDatabaseTaskWorkflowViewPage } from 'app/pages/task_workflows/task_workflow_view'
import { Container as AdminDatabaseTeamsListPage } from 'app/pages/teams/teams_list'
import { Container as AdminDatabaseTeamViewPage } from 'app/pages/teams/teams_view'
import { Container as TempNPSEditPage } from 'app/pages/TEMP/nps_edit'
import { Container as AllTimesheetsIndexPage } from 'app/pages/timesheets/timesheet_index'
import { Container as AdminDatabaseToolsListPage } from 'app/pages/tools/tools_list'
import { Container as AdminDatabaseToolViewPage } from 'app/pages/tools/tool_view'
import { Container as UnauthenticatedCalculatorsPage } from 'app/pages/unauthenticated/calculators'
import { Container as UnauthenticatedLandingPage } from 'app/pages/unauthenticated/landing'
import { Container as UnauthenticatedLoginPage } from 'app/pages/unauthenticated/login'
import { Container as ShortUrlPage } from 'app/pages/unauthenticated/short_url'
import { Container as ShowQueryParamsPage } from 'app/pages/unauthenticated/show_query_params'
import { Container as TeslaPowerwall } from 'app/pages/unauthenticated/tesla_powerwall'
import { Container as TeslaPowerwallConfirm } from 'app/pages/unauthenticated/tesla_powerwall_confirm'
import { Container as SalesHierarchyImportPage } from 'app/pages/users/sales_hierarchy_import'
import { Container as AdminDatabaseUserEditPage } from 'app/pages/users/user_edit'
import { Container as AdminDatabaseUserListPage } from 'app/pages/users/user_list'
import { Container as AdminDatabaseUserNewPage } from 'app/pages/users/user_new'
import { Container as AdminDatabaseUserViewPage } from 'app/pages/users/user_view'
import { Container as AdminDatabaseUtilitiesListPage } from 'app/pages/utilities/utilities_list'
import { Container as AdminDatabaseUtilityViewPage } from 'app/pages/utilities/utility_view'
import { Container as AdminDatabaseTrailerViewPage } from 'app/pages/vehicles/trailer_view'
import { Container as AdminDatabaseVehiclesListPage } from 'app/pages/vehicles/vehicles_list'
import { Container as AdminDatabaseVehicleUsageReportsPage } from 'app/pages/vehicles/vehicle_mileage_reports'
import { Container as AdminDatabaseVehicleViewPage } from 'app/pages/vehicles/vehicle_view'
import 'app/styles/app.css'
import 'app/styles/react-inner-image.css'
import { getAuth, onAuthStateChanged } from 'firebase/auth'
import { useEffect, useReducer, useState } from 'react'
import { clarity } from 'react-microsoft-clarity'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import { DefaultAppTheme } from 'rfbp_aux/config/app_theme'
import { devOrProdEnvironment } from 'rfbp_aux/config/config'
import { ApplicationPages } from 'rfbp_aux/data/application_structure'
import { Container as SearchManagementIndexPage } from 'rfbp_aux/pages/search_management/search_management_index'
import { Container as SuperClientPermissionsManagementPage } from 'rfbp_aux/pages/super/client_permissions_management'
import { Container as SuperTestLabPage } from 'rfbp_aux/pages/super/test_lab'
import { DatabaseRef_UserUnreadMessageThreads_AllNav_Query } from 'rfbp_aux/services/database_endpoints/operations/messages'
import { DatabaseRef_UnreadNotifications_AllNav_Query } from 'rfbp_aux/services/database_endpoints/operations/notifications'
import { DatabaseRef_UnassignedProjects_AdminNav_Query } from 'rfbp_aux/services/database_endpoints/operations/projects'
import { DatabaseRef_OpenReminders_Query_AllNav_Query } from 'rfbp_aux/services/database_endpoints/operations/reminders'
import { DatabaseRef_UserOpenAndReadyTasks_Query_AllNav_Query } from 'rfbp_aux/services/database_endpoints/operations/tasks'
import {
  DatabaseRef_ClientPermissions_Document,
  DatabaseRef_ClientUser_Document,
  DatabaseRef_GlobalUser_Document,
} from 'rfbp_aux/services/database_endpoints/standard_database_endpoints'
import 'rfbp_aux/styles/main.css'
import { AlertDialog, ConfirmDialog, CustomDialog, ErrorDialog, FormDialog, PromptDialog, SnackbarDisplay } from 'rfbp_core/components/dialog'
import { ScrollToTop } from 'rfbp_core/components/utilities/scroll_to_top'
import {
  CombinedAppContext,
  RootData_Default_AuthenticatedUserState,
  RootData_Default_ClientKeyState,
  RootData_Default_ClientPermissionsState,
  RootData_Default_ClientUserState,
  RootData_Default_GlobalUserState,
  RootData_Default_UserPermissionsState,
  TsInterface_RootData_AuthenticatedUser,
  TsInterface_RootData_ClientPermissions,
  TsInterface_RootData_ClientUser,
  TsInterface_RootData_GlobalUser,
  TsInterface_RootData_UserPermissions,
  TsInterface_UserInterface_AlertDialogDisplay,
  TsInterface_UserInterface_ConfirmDialogDisplay,
  TsInterface_UserInterface_ConfirmDialogInternalState,
  TsInterface_UserInterface_CustomDialogDisplay,
  TsInterface_UserInterface_ErrorDialogDisplay,
  TsInterface_UserInterface_FormDialogDisplay,
  TsInterface_UserInterface_FormDialogInternalState,
  TsInterface_UserInterface_NavBadges,
  TsInterface_UserInterface_PromptDialogDisplay,
  TsInterface_UserInterface_PromptDialogInternalState,
  TsInterface_UserInterface_SnackbarDisplay,
  TsType_RootData_ClientKey,
  TsType_UserInterface_LoadingBarDisplay,
  TsType_UserInterface_NavBarDisplay,
  UserInterface_Default_AlertDialogDisplayState,
  UserInterface_Default_ConfirmDialogDisplayState,
  UserInterface_Default_ConfirmDialogInternalState,
  UserInterface_Default_CustomDialogDisplayState,
  UserInterface_Default_ErrorDialogDisplayState,
  UserInterface_Default_FormDialogDisplayState,
  UserInterface_Default_FormDialogInternalState,
  UserInterface_Default_LoadingBarDisplayState,
  UserInterface_Default_NavBarDisplayState,
  UserInterface_Default_PromptDialogDisplayState,
  UserInterface_Default_PromptDialogInternalState,
  UserInterface_Default_SnackbarDisplayState,
} from 'rfbp_core/services/context'
import { DatabaseGetLiveCollection, DatabaseGetLiveDocument } from 'rfbp_core/services/database_management'
import { objectToArray } from 'rfbp_core/services/helper_functions'
import { TsInterface_UnspecifiedObject } from 'rfbp_core/typescript/global_types'

///////////////////////////////
// Typescript
///////////////////////////////

interface TsInterface_ClientPermissionsLiveData {
  [propKey: string]: any
  // TODO
}

interface TsInterface_ClientUserLiveData {
  [propKey: string]: any
  // TODO
}

interface TsInterface_GlobalUserLiveData {
  [propKey: string]: any
  // TODO
}

type TsType_DismissFunction = (obj?: any) => void

///////////////////////////////
// Variables
///////////////////////////////

// Nav Badges
let defaultNavBadgeCounts = {
  counts: {
    AdminActiveProjectsListPage: 0,
    AllAlertsListPage: 0,
    AllAssignedTasksListPage: 0,
  },
}

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Exports
///////////////////////////////

function App() {
  // @ts-ignore
  if (devOrProdEnvironment !== 'dev') {
    clarity.init('nmwnxq3qxa') // TODO: move to env

    // Cookie consent
    clarity.consent()
    // Identify the user
    // clarity.identify('USER_ID', { userProperty: 'value' })
  }

  // Hooks - useContext, useState, useReducer, other
  // { sort-start } - hooks
  const [uc_AppData_UrlHistory, uc_setAppData_UrlHistory] = useState<string[]>([])
  const [uc_RootData_AuthenticatedUser, uc_setRootData_AuthenticatedUser] =
    useState<TsInterface_RootData_AuthenticatedUser>(RootData_Default_AuthenticatedUserState)
  const [uc_RootData_ClientKey, uc_setRootData_ClientKey] = useState<TsType_RootData_ClientKey>(RootData_Default_ClientKeyState)
  const [uc_RootData_ClientPermissions, uc_setRootData_ClientPermissions] =
    useState<TsInterface_RootData_ClientPermissions>(RootData_Default_ClientPermissionsState)
  const [uc_RootData_ClientUser, uc_setRootData_ClientUser] = useState<TsInterface_RootData_ClientUser>(RootData_Default_ClientUserState)
  const [uc_RootData_GlobalUser, uc_setRootData_GlobalUser] = useState<TsInterface_RootData_GlobalUser>(RootData_Default_GlobalUserState)
  const [uc_RootData_UserPermissions, uc_setRootData_UserPermissions] = useState<TsInterface_RootData_UserPermissions>(RootData_Default_UserPermissionsState)
  const [uc_UserInterface_AlertDialogDisplay, uc_setUserInterface_AlertDialogDisplay] = useState<TsInterface_UserInterface_AlertDialogDisplay>(
    UserInterface_Default_AlertDialogDisplayState,
  )
  const [uc_UserInterface_ConfirmDialogAction, uc_setUserInterface_ConfirmDialogAction] = useState<TsInterface_UserInterface_ConfirmDialogInternalState>(
    UserInterface_Default_ConfirmDialogInternalState,
  )
  const [uc_UserInterface_ConfirmDialogDisplay, uc_setUserInterface_ConfirmDialogDisplay] = useState<TsInterface_UserInterface_ConfirmDialogDisplay>(
    UserInterface_Default_ConfirmDialogDisplayState,
  )
  const [uc_UserInterface_CustomDialogDisplay, uc_setUserInterface_CustomDialogDisplay] = useState<TsInterface_UserInterface_CustomDialogDisplay>(
    UserInterface_Default_CustomDialogDisplayState,
  )
  const [uc_UserInterface_ErrorDialogDisplay, uc_setUserInterface_ErrorDialogDisplay] = useState<TsInterface_UserInterface_ErrorDialogDisplay>(
    UserInterface_Default_ErrorDialogDisplayState,
  )
  const [uc_UserInterface_FormDialogAction, uc_setUserInterface_FormDialogAction] = useState<TsInterface_UserInterface_FormDialogInternalState>(
    UserInterface_Default_FormDialogInternalState,
  )
  const [uc_UserInterface_FormDialogDisplay, uc_setUserInterface_FormDialogDisplay] = useState<TsInterface_UserInterface_FormDialogDisplay>(
    UserInterface_Default_FormDialogDisplayState,
  )
  const [uc_UserInterface_LoadingBarDisplay, uc_setUserInterface_LoadingBarDisplay] = useState<TsType_UserInterface_LoadingBarDisplay>(
    UserInterface_Default_LoadingBarDisplayState,
  )
  const [uc_UserInterface_NavBadges, uc_setUserInterface_NavBadges] = useState<TsInterface_UserInterface_NavBadges>(defaultNavBadgeCounts)
  const [uc_UserInterface_NavBarDisplay, uc_setUserInterface_NavBarDisplay] =
    useState<TsType_UserInterface_NavBarDisplay>(UserInterface_Default_NavBarDisplayState)
  const [uc_UserInterface_PageContentViewType, uc_setUserInterface_PageContentViewType] = useState<string>('default')
  const [uc_UserInterface_PromptDialogAction, uc_setUserInterface_PromptDialogAction] = useState<TsInterface_UserInterface_PromptDialogInternalState>(
    UserInterface_Default_PromptDialogInternalState,
  )
  const [uc_UserInterface_PromptDialogDisplay, uc_setUserInterface_PromptDialogDisplay] = useState<TsInterface_UserInterface_PromptDialogDisplay>(
    UserInterface_Default_PromptDialogDisplayState,
  )
  const [uc_UserInterface_SnackbarDisplay, uc_setUserInterface_SnackbarDisplay] = useState<TsInterface_UserInterface_SnackbarDisplay>(
    UserInterface_Default_SnackbarDisplayState,
  )
  const [uc_rootData_AuthenticatedUserKey, uc_setRootData_AuthenticatedUserKey] = useState<string | null>(null)
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  // { sort-end } - hooks

  // useEffect(() => {
  //   const auth = getAuth()

  //   const unsubscribe = onAuthStateChanged(auth, (user) => {
  //     if (user) {
  //       const timestamp = new Date().toISOString()
  //       const userName = user.displayName || user.email || 'Unknown User'
  //       console.log(`User: ${userName}, Last Login: ${timestamp}`)
  //     }
  //   })

  //   return () => unsubscribe()
  // }, [])

  // Hooks - useEffect
  useEffect(() => {
    // Create listeners to user and client database locations and generate application permissions
    // Create variables for database unsubscribers
    let databaseClientPermissionsUnsubscribe: TsType_DismissFunction
    let databaseClientUserUnsubscribe: TsType_DismissFunction
    let databaseGlobalUserUnsubscribe: TsType_DismissFunction
    let databaseUnassignedProjectsUnsubscribe: TsType_DismissFunction
    let databaseUnreadNotificationsUnsubscribe: TsType_DismissFunction
    let databaseUnreadMessagesUnsubscribe: TsType_DismissFunction
    let databaseOpenTasksUnsubscribe: TsType_DismissFunction
    let databaseUnreadRemindersUnsubscribe: TsType_DismissFunction
    if (uc_rootData_AuthenticatedUserKey != null) {
      // Set user authentication to global state
      uc_setRootData_AuthenticatedUser({ loggedIn: true, uid: uc_rootData_AuthenticatedUserKey })

      // Nav Badge Callback Functions
      const unassignedProjectsLiveDataUpgrade = (newData: TsInterface_UnspecifiedObject) => {
        uc_setUserInterface_NavBadges((state: any, props: any) => ({
          counts: { ...state.counts, unassignedProjects: objectToArray(newData).length },
        }))
        ur_forceRerender()
      }

      const unreadAlertsLiveDataUpgrade = (newData: TsInterface_UnspecifiedObject) => {
        uc_setUserInterface_NavBadges((state: any, props: any) => ({
          counts: { ...state.counts, unreadAlerts: objectToArray(newData).length },
        }))
        ur_forceRerender()
      }

      const unreadMessagesLiveDataUpgrade = (newData: TsInterface_UnspecifiedObject) => {
        uc_setUserInterface_NavBadges((state: any, props: any) => ({
          // counts: { ...state.counts, AllMesagesListPage: objectToArray(newData).length },
          counts: { ...state.counts, unreadMessages: objectToArray(newData).length },
        }))
        ur_forceRerender()
      }

      const openTasksLiveDataUpgrade = (newData: TsInterface_UnspecifiedObject) => {
        uc_setUserInterface_NavBadges((state: any, props: any) => ({
          counts: { ...state.counts, openTasks: objectToArray(newData).length },
        }))
        ur_forceRerender()
      }

      const unreadRemindersLiveDataUpgrade = (newData: TsInterface_UnspecifiedObject) => {
        uc_setUserInterface_NavBadges((state: any, props: any) => ({
          counts: { ...state.counts, unreadReminders: objectToArray(newData).length },
        }))
        ur_forceRerender()
      }

      // Callback function to set client permissions to global state
      const clientPermissionsLiveDataUpdate = (clientPermissionsLiveData: TsInterface_ClientPermissionsLiveData) => {
        uc_setRootData_ClientPermissions(clientPermissionsLiveData as TsInterface_RootData_ClientPermissions)
      }

      // Callback function to set client user to global state
      const clientUserLiveDataUpdate = (clientUserLiveData: TsInterface_ClientUserLiveData) => {
        uc_setRootData_ClientUser(clientUserLiveData as TsInterface_RootData_ClientUser)
      }

      // Callback funtion to set global client to global state and susequently load client level info
      const globalUserLiveDataUpdate = (globalUserLiveData: TsInterface_GlobalUserLiveData) => {
        // Set Global User and Client Key
        uc_setRootData_GlobalUser(globalUserLiveData as TsInterface_RootData_GlobalUser)
        if (globalUserLiveData != null && globalUserLiveData.client_key != null) {
          uc_setRootData_ClientKey(globalUserLiveData.client_key)
        }
        // Create Database Listener for Client User
        if (globalUserLiveData != null && globalUserLiveData.client_key != null && uc_rootData_AuthenticatedUserKey != null) {
          databaseClientUserUnsubscribe = DatabaseGetLiveDocument(
            DatabaseRef_ClientUser_Document(globalUserLiveData.client_key, uc_rootData_AuthenticatedUserKey),
            clientUserLiveDataUpdate,
          )
        }
        // Create Database Listener for Client Permissions
        if (globalUserLiveData != null && globalUserLiveData.client_key != null) {
          databaseClientPermissionsUnsubscribe = DatabaseGetLiveDocument(
            DatabaseRef_ClientPermissions_Document(globalUserLiveData.client_key),
            clientPermissionsLiveDataUpdate,
          )
        }
        // Create Database Listener for Nav Badge - Unassigned Projects
        if (
          globalUserLiveData != null &&
          globalUserLiveData.client_key != null &&
          uc_rootData_AuthenticatedUserKey != null &&
          globalUserLiveData.user_role === 'admin'
        ) {
          // TODO - expand roles here?
          databaseUnassignedProjectsUnsubscribe = DatabaseGetLiveCollection(
            DatabaseRef_UnassignedProjects_AdminNav_Query(globalUserLiveData.client_key),
            unassignedProjectsLiveDataUpgrade,
          )
        }
        // Create Database Listener for Nav Badge - Unread Notifications
        if (globalUserLiveData != null && globalUserLiveData.client_key != null) {
          databaseUnreadNotificationsUnsubscribe = DatabaseGetLiveCollection(
            DatabaseRef_UnreadNotifications_AllNav_Query(globalUserLiveData.client_key, uc_rootData_AuthenticatedUserKey),
            unreadAlertsLiveDataUpgrade,
          )
        }
        // Create Database Listener for Nav Badge - Unread Project Messages
        if (globalUserLiveData != null && globalUserLiveData.client_key != null) {
          databaseUnreadMessagesUnsubscribe = DatabaseGetLiveCollection(
            DatabaseRef_UserUnreadMessageThreads_AllNav_Query(globalUserLiveData.client_key, uc_rootData_AuthenticatedUserKey),
            unreadMessagesLiveDataUpgrade,
          )
        }
        // Create Database Listener for Nav Badge - Unread Project Messages
        if (globalUserLiveData != null && globalUserLiveData.client_key != null) {
          databaseOpenTasksUnsubscribe = DatabaseGetLiveCollection(
            DatabaseRef_UserOpenAndReadyTasks_Query_AllNav_Query(globalUserLiveData.client_key, uc_rootData_AuthenticatedUserKey),
            openTasksLiveDataUpgrade,
          )
        }
        // Create Database Listener for Nav Badge - Unread Project Messages
        if (globalUserLiveData != null && globalUserLiveData.client_key != null) {
          databaseUnreadRemindersUnsubscribe = DatabaseGetLiveCollection(
            DatabaseRef_OpenReminders_Query_AllNav_Query(globalUserLiveData.client_key, uc_rootData_AuthenticatedUserKey, new Date()),
            unreadRemindersLiveDataUpgrade,
          )
        }
      }
      // Create Database Listener for Global User
      databaseGlobalUserUnsubscribe = DatabaseGetLiveDocument(DatabaseRef_GlobalUser_Document(uc_rootData_AuthenticatedUserKey), globalUserLiveDataUpdate)
    } else {
      // If the user is not authenticated then wipe all user and client related data in global state
      uc_setRootData_AuthenticatedUser(RootData_Default_AuthenticatedUserState)
      uc_setRootData_ClientPermissions(RootData_Default_ClientPermissionsState)
      uc_setRootData_ClientUser(RootData_Default_ClientUserState)
      uc_setRootData_GlobalUser(RootData_Default_GlobalUserState)
    }
    return () => {
      if (typeof databaseClientPermissionsUnsubscribe === 'function') {
        databaseClientPermissionsUnsubscribe()
      }
      if (typeof databaseClientUserUnsubscribe === 'function') {
        databaseClientUserUnsubscribe()
      }
      if (typeof databaseGlobalUserUnsubscribe === 'function') {
        databaseGlobalUserUnsubscribe()
      }
      if (typeof databaseUnassignedProjectsUnsubscribe === 'function') {
        databaseUnassignedProjectsUnsubscribe()
      }
      if (typeof databaseUnreadNotificationsUnsubscribe === 'function') {
        databaseUnreadNotificationsUnsubscribe()
      }
      if (typeof databaseUnreadMessagesUnsubscribe === 'function') {
        databaseUnreadMessagesUnsubscribe()
      }
      if (typeof databaseOpenTasksUnsubscribe === 'function') {
        databaseOpenTasksUnsubscribe()
      }
      if (typeof databaseUnreadRemindersUnsubscribe === 'function') {
        databaseUnreadRemindersUnsubscribe()
      }
    }
  }, [ur_forceRerender, uc_rootData_AuthenticatedUserKey])

  useEffect(() => {
    let authenticatedUnsubscribe: TsType_DismissFunction
    // Verify if user is authenticated
    authenticatedUnsubscribe = onAuthStateChanged(getAuth(), (user) => {
      if (user && user.uid != null) {
        uc_setRootData_AuthenticatedUserKey(user.uid)
      }
    })
    return () => {
      // Close database listeners on component unmount - I don't think this gets used in production (just on hot reload during development)
      if (typeof authenticatedUnsubscribe === 'function') {
        authenticatedUnsubscribe()
      }
    }
  }, [ur_forceRerender])

  // Context
  let Context_AppData_UrlHistory_Passthrough = { uc_AppData_UrlHistory, uc_setAppData_UrlHistory }
  let Context_RootData_AuthenticatedUser_Passthrough = { uc_RootData_AuthenticatedUser, uc_setRootData_AuthenticatedUser }
  let Context_RootData_ClientKey_Passthrough = { uc_RootData_ClientKey, uc_setRootData_ClientKey }
  let Context_RootData_ClientPermissions_Passthrough = { uc_RootData_ClientPermissions, uc_setRootData_ClientPermissions }
  let Context_RootData_ClientUser_Passthrough = { uc_RootData_ClientUser, uc_setRootData_ClientUser }
  let Context_RootData_GlobalUser_Passthrough = { uc_RootData_GlobalUser, uc_setRootData_GlobalUser }
  let Context_RootData_UserPermissions_Passthrough = { uc_RootData_UserPermissions, uc_setRootData_UserPermissions }
  let Context_UserInterface_AlertDialog_Passthrough = { uc_UserInterface_AlertDialogDisplay, uc_setUserInterface_AlertDialogDisplay }
  let Context_UserInterface_ConfirmDialog_Passthrough = { uc_UserInterface_ConfirmDialogDisplay, uc_setUserInterface_ConfirmDialogDisplay }
  let Context_UserInterface_CustomDialog_Passthrough = { uc_UserInterface_CustomDialogDisplay, uc_setUserInterface_CustomDialogDisplay }
  let Context_UserInterface_ErrorDialog_Passthrough = { uc_UserInterface_ErrorDialogDisplay, uc_setUserInterface_ErrorDialogDisplay }
  let Context_UserInterface_FormDialog_Passthrough = { uc_UserInterface_FormDialogDisplay, uc_setUserInterface_FormDialogDisplay }
  let Context_UserInterface_LoadingBar_Passthrough = { uc_UserInterface_LoadingBarDisplay, uc_setUserInterface_LoadingBarDisplay }
  let Context_UserInterface_NavBadges_Passthrough = { uc_UserInterface_NavBadges, uc_setUserInterface_NavBadges }
  let Context_UserInterface_NavBar_Passthrough = { uc_UserInterface_NavBarDisplay, uc_setUserInterface_NavBarDisplay }
  let Context_UserInterface_PageContentViewType_Passthrough = { uc_UserInterface_PageContentViewType, uc_setUserInterface_PageContentViewType }
  let Context_UserInterface_PromptDialog_Passthrough = { uc_UserInterface_PromptDialogDisplay, uc_setUserInterface_PromptDialogDisplay }
  let Context_UserInterface_Snackbar_Passthrough = { uc_UserInterface_SnackbarDisplay, uc_setUserInterface_SnackbarDisplay }

  // Handle Dialogs
  let alertDialogJSX: JSX.Element = <></>
  let confirmDialogJSX: JSX.Element = <></>
  let customDialogJSX: JSX.Element = <></>
  let errorDialogJSX: JSX.Element = <></>
  let formDialogJSX: JSX.Element = <></>
  let promptDialogJSX: JSX.Element = <></>
  let snackbarJSX: JSX.Element = <></>

  // Alerts
  const potentiallyRenderAlertDialog = () => {
    if (uc_UserInterface_AlertDialogDisplay.display === true) {
      alertDialogJSX = AlertDialog(uc_UserInterface_AlertDialogDisplay.alert, () => {
        uc_setUserInterface_AlertDialogDisplay(UserInterface_Default_AlertDialogDisplayState)
      })
    }
  }

  potentiallyRenderAlertDialog()

  // Confirms
  const potentiallyRenderConfirmDialog = () => {
    if (uc_UserInterface_ConfirmDialogDisplay.display === true) {
      confirmDialogJSX = ConfirmDialog(
        uc_UserInterface_ConfirmDialogDisplay.confirm,
        () => {
          uc_setUserInterface_ConfirmDialogDisplay(UserInterface_Default_ConfirmDialogDisplayState)
        },
        uc_UserInterface_ConfirmDialogAction,
        uc_setUserInterface_ConfirmDialogAction,
      )
    }
  }

  potentiallyRenderConfirmDialog()

  // Custom Dialog
  const potentiallyRenderCustomDialog = () => {
    if (uc_UserInterface_CustomDialogDisplay.display === true) {
      customDialogJSX = CustomDialog(uc_UserInterface_CustomDialogDisplay.dialog, () => {
        uc_setUserInterface_CustomDialogDisplay(UserInterface_Default_CustomDialogDisplayState)
      })
    }
  }

  potentiallyRenderCustomDialog()

  // Errors
  const potentiallyRenderErrorDialog = () => {
    if (uc_UserInterface_ErrorDialogDisplay.display === true) {
      errorDialogJSX = ErrorDialog(uc_UserInterface_ErrorDialogDisplay.error, () => {
        uc_setUserInterface_ErrorDialogDisplay(UserInterface_Default_ErrorDialogDisplayState)
      })
    }
  }

  potentiallyRenderErrorDialog()

  // Forms
  const potentiallyRenderFormDialog = () => {
    if (uc_UserInterface_FormDialogDisplay.display === true) {
      formDialogJSX = FormDialog(
        uc_UserInterface_FormDialogDisplay.form,
        () => {
          uc_setUserInterface_FormDialogDisplay(UserInterface_Default_FormDialogDisplayState)
        },
        uc_UserInterface_FormDialogAction,
        uc_setUserInterface_FormDialogAction,
      )
    }
  }

  potentiallyRenderFormDialog()

  // Prompts
  const potentiallyRenderPromptDialog = () => {
    if (uc_UserInterface_PromptDialogDisplay.display === true) {
      promptDialogJSX = PromptDialog(
        uc_UserInterface_PromptDialogDisplay.prompt,
        () => {
          uc_setUserInterface_PromptDialogDisplay(UserInterface_Default_PromptDialogDisplayState)
        },
        uc_UserInterface_PromptDialogAction,
        uc_setUserInterface_PromptDialogAction,
      )
    }
  }

  potentiallyRenderPromptDialog()

  // Snackbars
  const potentiallyRenderSnackbar = () => {
    snackbarJSX = SnackbarDisplay(uc_UserInterface_SnackbarDisplay.display, uc_UserInterface_SnackbarDisplay.snackbar, () => {
      let copiedSnackbarDisplay = JSON.parse(JSON.stringify(uc_UserInterface_SnackbarDisplay))
      copiedSnackbarDisplay.display = false
      uc_setUserInterface_SnackbarDisplay(copiedSnackbarDisplay)
      // uc_setUserInterface_SnackbarDisplay( UserInterface_Default_SnackbarDisplayState )
    })
  }

  potentiallyRenderSnackbar()

  // Render
  return (
    <StyledEngineProvider injectFirst>
      {/* @ts-expect-error  - TODO: reason */}
      <CombinedAppContext.Provider
        value={{
          Context_AppData_UrlHistory_Passthrough,
          Context_RootData_AuthenticatedUser_Passthrough,
          Context_RootData_ClientKey_Passthrough,
          Context_RootData_ClientPermissions_Passthrough,
          Context_RootData_ClientUser_Passthrough,
          Context_RootData_GlobalUser_Passthrough,
          Context_RootData_UserPermissions_Passthrough,
          Context_UserInterface_AlertDialog_Passthrough,
          Context_UserInterface_ConfirmDialog_Passthrough,
          Context_UserInterface_CustomDialog_Passthrough,
          Context_UserInterface_ErrorDialog_Passthrough,
          Context_UserInterface_FormDialog_Passthrough,
          Context_UserInterface_LoadingBar_Passthrough,
          Context_UserInterface_NavBadges_Passthrough,
          Context_UserInterface_PageContentViewType_Passthrough,
          Context_UserInterface_NavBar_Passthrough,
          Context_UserInterface_PromptDialog_Passthrough,
          Context_UserInterface_Snackbar_Passthrough,
        }}
      >
        <ThemeProvider theme={DefaultAppTheme}>
          <CssBaseline />
          <BrowserRouter>
            <ScrollToTop />
            {alertDialogJSX}
            {confirmDialogJSX}
            {customDialogJSX}
            {errorDialogJSX}
            {formDialogJSX}
            {promptDialogJSX}
            {snackbarJSX}
            <Routes>
              {/* { sort-start } - application pages - scoped sort plugin */}
              <Route
                path={ApplicationPages.AdminActiveProjectMergePage.url(':id')}
                element={<AdminActiveProjectMergePage />}
              />
              <Route
                path={ApplicationPages.AdminActiveProjectUrlSearchPage.url(':id')}
                element={<AdminActiveProjectUrlSearchPage />}
              />
              <Route
                path={ApplicationPages.AdminActiveProjectViewPage.url(':id')}
                element={<AdminActiveProjectViewPage />}
              />
              <Route
                path={ApplicationPages.AdminActiveProjectsListPage.url()}
                element={<AdminActiveProjectsListPage />}
              />
              <Route
                path={ApplicationPages.AutomaticDataImportPages.url()}
                element={<AutomaticDataImportPages />}
              />
              <Route
                path={ApplicationPages.IssueTrackerIndexPage.url()}
                element={<IssueTrackerIndexPage />}
              />
              <Route
                path={ApplicationPages.AdminCronjobManagementIndexPage.url()}
                element={<AdminCronjobManagementIndexPage />}
              />
              <Route
                path={ApplicationPages.AdminDatabaseAPIManagementIndexPage.url()}
                element={<AdminDatabaseAPIManagementIndexPage />}
              />
              <Route
                path={ApplicationPages.AdminDatabaseCompanyDocumentsPage.url()}
                element={<AdminDatabaseCompanyDocumentsPage />}
              />
              <Route
                path={ApplicationPages.AdminDatabaseHoasListPage.url()}
                element={<AdminDatabaseHoasListPage />}
              />
              <Route
                path={ApplicationPages.AdminDatabaseHoaViewPage.url(':id')}
                element={<AdminDatabaseHoaViewPage />}
              />
              <Route
                path={ApplicationPages.AdminDatabaseIncidentReportViewPage.url(':id')}
                element={<AdminDatabaseIncidentReportViewPage />}
              />
              <Route
                path={ApplicationPages.AdminDatabaseIncidentReportsListPage.url()}
                element={<AdminDatabaseIncidentReportsListPage />}
              />
              <Route
                path={ApplicationPages.AdminDatabaseJurisdictionViewPage.url(':id')}
                element={<AdminDatabaseJurisdictionViewPage />}
              />
              <Route
                path={ApplicationPages.AdminDatabaseJurisdictionsListPage.url()}
                element={<AdminDatabaseJurisdictionsListPage />}
              />
              <Route
                path={ApplicationPages.AdminDatabaseLeaderboardIndexPage.url()}
                element={<AdminDatabaseLeaderboardIndexPage />}
              />
              <Route
                path={ApplicationPages.AdminDatabaseMaterialsIndexPage.url()}
                element={<AdminDatabaseMaterialsIndexPage />}
              />
              <Route
                path={ApplicationPages.AdminDatabaseMaterialsOrderViewPage.url(':id')}
                element={<AdminDatabaseMaterialsOrderViewPage />}
              />
              <Route
                path={ApplicationPages.AdminDatabaseMaterialsSupplierViewPage.url(':id')}
                element={<AdminDatabaseMaterialsSupplierViewPage />}
              />
              <Route
                path={ApplicationPages.AdminDatabaseRegionsListPage.url()}
                element={<AdminDatabaseRegionsListPage />}
              />
              <Route
                path={ApplicationPages.AdminDatabaseSalesPartnersListPage.url()}
                element={<AdminDatabaseSalesPartnersListPage />}
              />
              <Route
                path={ApplicationPages.AdminDatabaseFinancePartnersListPage.url()}
                element={<AdminDatabaseFinancePartnersListPage />}
              />
              <Route
                path={ApplicationPages.AdminDatabaseTaskFormsListPage.url()}
                element={<AdminDatabaseTaskFormsListPage />}
              />
              <Route
                path={ApplicationPages.AdminDatabaseTaskFormsViewPage.url(':id')}
                element={<AdminDatabaseTaskFormsViewPage />}
              />
              <Route
                path={ApplicationPages.AdminDatabaseTaskWorkflowListPage.url()}
                element={<AdminDatabaseTaskWorkflowListPage />}
              />
              <Route
                path={ApplicationPages.AdminDatabaseTaskWorkflowViewPage.url(':id')}
                element={<AdminDatabaseTaskWorkflowViewPage />}
              />
              <Route
                path={ApplicationPages.AdminDatabaseTasksListPage.url()}
                element={<AdminDatabaseTasksListPage />}
              />
              <Route
                path={ApplicationPages.AdminDatabaseToolViewPage.url(':id')}
                element={<AdminDatabaseToolViewPage />}
              />
              <Route
                path={ApplicationPages.AdminDatabaseToolsListPage.url()}
                element={<AdminDatabaseToolsListPage />}
              />
              <Route
                path={ApplicationPages.AdminDatabaseTrailerViewPage.url(':id')}
                element={<AdminDatabaseTrailerViewPage />}
              />
              <Route
                path={ApplicationPages.AdminDatabaseUserEditPage.url(':id')}
                element={<AdminDatabaseUserEditPage />}
              />
              <Route
                path={ApplicationPages.AdminDatabaseUserListPage.url()}
                element={<AdminDatabaseUserListPage />}
              />
              <Route
                path={ApplicationPages.AdminDatabaseUserNewPage.url()}
                element={<AdminDatabaseUserNewPage />}
              />
              <Route
                path={ApplicationPages.AdminDatabaseUserViewPage.url(':id')}
                element={<AdminDatabaseUserViewPage />}
              />{' '}
              <Route
                path={ApplicationPages.AdminDatabaseTeamViewPage.url(':id')}
                element={<AdminDatabaseTeamViewPage />}
              />
              <Route
                path={ApplicationPages.AdminDatabaseTeamsListPage.url()}
                element={<AdminDatabaseTeamsListPage />}
              />
              <Route
                path={ApplicationPages.AdminDatabaseUtilitiesListPage.url()}
                element={<AdminDatabaseUtilitiesListPage />}
              />
              <Route
                path={ApplicationPages.AdminDatabaseUtilityViewPage.url(':id')}
                element={<AdminDatabaseUtilityViewPage />}
              />
              <Route
                path={ApplicationPages.AdminDatabaseVehicleUsageReportsPage.url()}
                element={<AdminDatabaseVehicleUsageReportsPage />}
              />
              <Route
                path={ApplicationPages.AdminDatabaseVehicleViewPage.url(':id')}
                element={<AdminDatabaseVehicleViewPage />}
              />
              <Route
                path={ApplicationPages.AdminDatabaseVehiclesListPage.url()}
                element={<AdminDatabaseVehiclesListPage />}
              />
              <Route
                path={ApplicationPages.AdminPayrollIndexPage.url()}
                element={<AdminPayrollIndexPage />}
              />
              <Route
                path={ApplicationPages.AdminInvoiceIndexPage.url()}
                element={<AdminInvoiceIndexPage />}
              />
              <Route
                path={ApplicationPages.AdminInvoiceSettingsPage.url()}
                element={<AdminInvoiceSettingsPage />}
              />
              <Route
                path={ApplicationPages.AdminPayrollSettingsPage.url()}
                element={<AdminPayrollSettingsPage />}
              />
              <Route
                path={ApplicationPages.AdminFinanceSpendingIndexPage.url()}
                element={<AdminFinanceSpendingIndexPage />}
              />
              <Route
                path={ApplicationPages.AdminFinanceSpendingCardholdersViewPage.url(':id')}
                element={<AdminFinanceSpendingCardholdersViewPage />}
              />
              <Route
                path={ApplicationPages.AdminFinanceSpendingProfileViewPage.url(':id')}
                element={<AdminFinanceSpendingProfileViewPage />}
              />
              <Route
                path={ApplicationPages.AdminProjectManualImportsPage.url()}
                element={<AdminProjectManualImportsPage />}
              />
              <Route
                path={ApplicationPages.AdminSalesToolsIndexPage.url()}
                element={<AdminSalesToolsIndexPage />}
              />
              <Route
                path={ApplicationPages.SalesOpportunitiesIndexPage.url()}
                element={<SalesOpportunitiesIndexPage />}
              />
              <Route
                path={ApplicationPages.SalesOpportunityPage.url(':id')}
                element={<SalesOpportunityPage />}
              />
              <Route
                path={ApplicationPages.SalesDoorhangerRoutePage.url(':id')}
                element={<SalesDoorhangerRoutePage />}
              />
              <Route
                path={ApplicationPages.AdminStatsListPage.url()}
                element={<AdminStatsListPage />}
              />
              <Route
                path={ApplicationPages.AllAlertsListPage.url()}
                element={<AllAlertsListPage />}
              />
              <Route
                path={ApplicationPages.AllAssignedTasksListPage.url()}
                element={<AllAssignedTasksListPage />}
              />
              <Route
                path={ApplicationPages.AllTimesheetsIndexPage.url()}
                element={<AllTimesheetsIndexPage />}
              />
              <Route
                path={ApplicationPages.GMDashboardIndexPage.url()}
                element={<GMDashboardIndexPage />}
              />
              <Route
                path={ApplicationPages.TaskEfficiencyPage.url()}
                element={<TaskEfficiencyPage />}
              />
              <Route
                path={ApplicationPages.BoilerplateDocsPage.url()}
                element={<BoilerplateDocsPage />}
              />
              <Route
                path={ApplicationPages.CombineManagementPage.url()}
                element={<CombineManagementPage />}
              />
              <Route
                path={ApplicationPages.HomePage.url()}
                element={<HomePage />}
              />
              <Route
                path={ApplicationPages.LiveReviewIndexPage.url()}
                element={<LiveReviewIndexPage />}
              />
              <Route
                path={ApplicationPages.LeadSourcesIndexPage.url()}
                element={<LeadSourcesIndexPage />}
              />
              <Route
                path={ApplicationPages.MLEvaluationProcedureViewPage.url(':id')}
                element={<MLEvaluationProcedureViewPage />}
              />
              <Route
                path={ApplicationPages.MachineLearningIndexPage.url()}
                element={<MachineLearningIndexPage />}
              />
              <Route
                path={ApplicationPages.PaymentTermsPage.url()}
                element={<PaymentTermsPage />}
              />
              <Route
                path={ApplicationPages.PerformanceManagementPage.url()}
                element={<PerformanceManagementPage />}
              />
              <Route
                path={ApplicationPages.PowerwallInstallAgreementPage.url()}
                element={<PowerwallInstallAgreementPage />}
              />
              <Route
                path={ApplicationPages.PrivacyPolicyPage.url()}
                element={<PrivacyPolicyPage />}
              />
              <Route
                path={ApplicationPages.ProjectFinanceViewPage.url(':id')}
                element={<ProjectFinanceViewPage />}
              />
              <Route
                path={ApplicationPages.SalesHierarchyImportPage.url()}
                element={<SalesHierarchyImportPage />}
              />
              <Route
                path={ApplicationPages.SalesOpportunityViewPage.url(':id')}
                element={<SalesOpportunityViewPage />}
              />
              <Route
                path={ApplicationPages.SalesOpportunityDiscoverySessionViewPage.url(':id')}
                element={<SalesOpportunityDiscoverySessionViewPage />}
              />
              <Route
                path={ApplicationPages.SalesProjectViewPage.url(':id')}
                element={<SalesProjectViewPage />}
              />
              <Route
                path={ApplicationPages.SalesProjectsListPage.url()}
                element={<SalesProjectsListPage />}
              />
              <Route
                path={ApplicationPages.SalesTasksListPage.url()}
                element={<SalesTasksListPage />}
              />
              <Route
                path={ApplicationPages.SearchManagementIndexPage.url()}
                element={<SearchManagementIndexPage />}
              />
              <Route
                path={ApplicationPages.ShortUrlPage.url(':id')}
                element={<ShortUrlPage />}
              />
              <Route
                path={ApplicationPages.ShowQueryParamsPage.url()}
                element={<ShowQueryParamsPage />}
              />
              <Route
                path={ApplicationPages.SubKActiveTasksListPage.url()}
                element={<SubKActiveTasksListPage />}
              />
              <Route
                path={ApplicationPages.SubKProjectViewPage.url(':id')}
                element={<SubKProjectViewPage />}
              />
              <Route
                path={ApplicationPages.SunrunApiPage.url()}
                element={<SunrunApiPage />}
              />
              <Route
                path={ApplicationPages.SuperClientPermissionsManagementPage.url()}
                element={<SuperClientPermissionsManagementPage />}
              />
              <Route
                path={ApplicationPages.SuperTestLabPage.url()}
                element={<SuperTestLabPage />}
              />
              <Route
                path={ApplicationPages.SuperDatabaseManagementPage.url()}
                element={<SuperDatabaseManagementPage />}
              />
              <Route
                path={ApplicationPages.DesignIndexPage.url()}
                element={<DesignIndexPage />}
              />
              <Route
                path={ApplicationPages.TaskDispatchBookmarkPage.url()}
                element={<TaskDispatchBookmarkPage />}
              />
              <Route
                path={ApplicationPages.TaskCalendarSchedulePage.url(':date')}
                element={<TaskCalendarSchedulePage />}
              />
              <Route
                path={ApplicationPages.TempNPSEditPage.url()}
                element={<TempNPSEditPage />}
              />
              <Route
                path={ApplicationPages.TermsOfServicePage.url()}
                element={<TermsOfServicePage />}
              />
              <Route
                path={ApplicationPages.TeslaPowerwall.url()}
                element={<TeslaPowerwall />}
              />
              <Route
                path={ApplicationPages.TeslaPowerwallConfirm.url(':cid', ':uid')}
                element={<TeslaPowerwallConfirm />}
              />
              <Route
                path={ApplicationPages.UnauthenticatedLandingPage.url()}
                element={<UnauthenticatedLandingPage />}
              />
              <Route
                path={ApplicationPages.UnauthenticatedCalculatorsPage.url()}
                element={<UnauthenticatedCalculatorsPage />}
              />
              <Route
                path={ApplicationPages.UnauthenticatedLoginPage.url()}
                element={<UnauthenticatedLoginPage />}
              />
              <Route
                path={ApplicationPages.AdminSalesOpportunityPage.url(':id')}
                element={<AdminSalesOpportunityPage />}
              />
              <Route
                path={ApplicationPages.UnauthenticatedSalesOpportunityPage.url(':id')}
                element={<UnauthenticatedSalesOpportunityPage isAuthenticatedUser={false} />}
              />
              <Route
                path={ApplicationPages.UnauthenticatedMaterialsSupplierOrdersListPage.url(':ck', ':sk')}
                element={<UnauthenticatedMaterialsSupplierOrdersListPage />}
              />
              <Route
                path={ApplicationPages.UserSettingsPage.url()}
                element={<UserSettingsPage />}
              />
              {/* { sort-end } - application pages */}
              <Route
                path="*"
                element={
                  <Navigate
                    to={ApplicationPages.HomePage.url()}
                    replace
                  />
                }
              />
            </Routes>
          </BrowserRouter>
        </ThemeProvider>
      </CombinedAppContext.Provider>
    </StyledEngineProvider>
  )
}

export default App
