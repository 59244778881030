//////////////////////////////////////////
//		  ooOOOO BOILERPLATE FILE		//
//		 oo		 _____					//
//		_I__n_n__||_|| ________			//
//	  >(_________|_7_|-|______|			//
//	   /o ()() ()() o   oo  oo			//
//////////////////////////////////////////

///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:


		TODO:
			Clean up all the stuff that is hard coded with override and new_estimate and maybe make a more advanced timeline that can
			have all that stuff customizable

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineOppositeContent, TimelineSeparator } from '@mui/lab/'
import { Box } from '@mui/material/'
import { themeVariables } from 'rfbp_aux/config/app_theme' // OUTSIDE BOILERPLATE
import {
  TsInterface_TimelineEvents,
  TsInterface_TimelineFormattedData,
  TsInterface_TimelineFormattedDate,
  TsInterface_TimelineSettings,
} from 'rfbp_core/components/timeline'
import { dynamicSort, getProp, objectToArray, returnFormattedDate } from 'rfbp_core/services/helper_functions'

///////////////////////////////
// Typescript
///////////////////////////////

interface TsInterface_ComponentProps {
  // propKey: any
  timelineEvents: TsInterface_TimelineEvents
  timelineSettings: TsInterface_TimelineSettings
}

///////////////////////////////
// Variables
///////////////////////////////

// CSS
let timelineCss: string =
  `
		.timeline_left_content {
			margin-right: 4px;
			text-align: right;
		}
		.timeline_right_content {
			margin-left: 4px;
		}
		.timeline_container {
			margin: 0px;
			padding: 0px;
		}
		.timeline_date {
			// border: 2px solid ` +
  themeVariables.info_main +
  `;
			border: 2px solid ` +
  themeVariables.gray_400 +
  `;
			color: ` +
  themeVariables.info_main +
  `;
			padding: 4px 16px;
			border-radius: 4px;
			width: auto;
			display: inline-block;
			// min-width: 140px;
			text-align: left
		}
	`

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Component
///////////////////////////////

export const TimelineBasic = (props: TsInterface_ComponentProps): JSX.Element => {
  // Props
  let pr_timelineEvents: TsInterface_ComponentProps['timelineEvents'] = getProp(props, 'timelineEvents', {})
  let pr_timelineSettings: TsInterface_ComponentProps['timelineSettings'] = getProp(props, 'timelineSettings', {})
  let pr_sortDirection: TsInterface_TimelineSettings['direction'] = getProp(pr_timelineSettings, 'direction', 'desc')

  // Hooks - useContext, useState, useReducer, other
  // { sort-start } - hooks

  // { sort-end } - hooks

  // Hooks - useEffect

  // Functions
  const returnFormattedTimelineData = (): TsInterface_TimelineFormattedData => {
    let formattedTimelineData: TsInterface_TimelineFormattedData = {}
    for (let timelineEventKey in pr_timelineEvents) {
      let timelineEvent = pr_timelineEvents[timelineEventKey]
      if (timelineEvent.timestamp != null) {
        let compositeDate = returnFormattedDate(timelineEvent.timestamp, 'YYYY-MM-DD')
        if (formattedTimelineData[compositeDate] == null) {
          formattedTimelineData[compositeDate] = {
            key: compositeDate,
            // date: new Date(compositeDate),
            date: new Date(compositeDate),
            events: [],
          }
        }
        formattedTimelineData[compositeDate]['events'].push(timelineEvent)
      }
    }
    return formattedTimelineData
  }

  // JSX Generation
  const rJSX_TimelineDate = (timestamp: number, additionalTimestamp: number, timestampColor: string, timestampType: string): JSX.Element => {
    let timelineDateJSX
    if (timestampType === 'override') {
      if (additionalTimestamp == null) {
        timelineDateJSX = (
          <Box component="div">
            <Box
              component="div"
              sx={{ color: timestampColor }}
            >
              {returnFormattedDate(timestamp, 'h:mm a')}
            </Box>
          </Box>
        )
      } else {
        timelineDateJSX = (
          <Box component="div">
            <Box
              component="div"
              className="tw-line-through tw-opacity-50"
            >
              <em>{returnFormattedDate(timestamp, 'h:mm a')}</em>
            </Box>
            <Box
              component="div"
              sx={{ color: timestampColor }}
            >
              {returnFormattedDate(additionalTimestamp, 'h:mm a')}
            </Box>
          </Box>
        )
      }
    } else if (timestampType === 'new_estimate') {
      if (additionalTimestamp == null) {
        timelineDateJSX = (
          <Box component="div">
            <Box
              component="div"
              sx={{ color: timestampColor }}
            >
              ({returnFormattedDate(timestamp, 'h:mm a')})
            </Box>
          </Box>
        )
      } else {
        timelineDateJSX = (
          <Box component="div">
            <Box
              component="div"
              className="tw-line-through tw-opacity-50"
            >
              <em>{returnFormattedDate(timestamp, 'h:mm a')}</em>
            </Box>
            <Box
              component="div"
              sx={{ color: timestampColor }}
            >
              ({returnFormattedDate(additionalTimestamp, 'h:mm a')})
            </Box>
          </Box>
        )
      }
    } else {
      timelineDateJSX = (
        <Box component="div">
          <Box
            component="div"
            sx={{ color: timestampColor }}
          >
            {returnFormattedDate(timestamp, 'h:mm a')}
          </Box>
        </Box>
      )
    }
    return timelineDateJSX
  }

  const rJSX_Component = (): JSX.Element => {
    let leftGridStyles = getProp(pr_timelineSettings, 'styles.left_grid', { flex: 'inherit', alignSelf: 'center', width: '100px' })
    let rightGridStyles = getProp(pr_timelineSettings, 'styles.right_grid', { alignSelf: 'center' })
    let componentJSX = (
      <div>
        <div>
          {objectToArray(returnFormattedTimelineData())
            .sort(dynamicSort('key', pr_sortDirection))
            .map((timelineDate: TsInterface_TimelineFormattedDate) => (
              <div key={timelineDate.key}>
                <div className="timeline_date">
                  <strong>{returnFormattedDate(timelineDate.date, 'MMM D, YYYY')}</strong>
                </div>
                <Timeline className="timeline_container">
                  {timelineDate['events'].sort(dynamicSort('timestamp', pr_sortDirection)).map((event: any) => (
                    <div key={event.timestamp}>
                      <TimelineItem>
                        <TimelineOppositeContent
                          style={leftGridStyles}
                          color="text.secondary"
                        >
                          <div className="timeline_left_content">
                            {rJSX_TimelineDate(event.timestamp, event.additional_timestamp, event.timestamp_color, event.timestamp_type)}
                          </div>
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                          <TimelineConnector />
                          <TimelineDot
                            color={event['color']}
                            variant={event['dot_variant']}
                          />
                          <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent style={rightGridStyles}>
                          <div className="timeline_right_content">{event.text}</div>
                        </TimelineContent>
                      </TimelineItem>
                    </div>
                  ))}
                </Timeline>
              </div>
            ))}
        </div>
        <style>{timelineCss}</style>
      </div>
    )
    return componentJSX
  }

  // Render
  return <>{rJSX_Component()}</>
}
