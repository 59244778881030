//////////////////////////////////////////
//		  ooOOOO BOILERPLATE FILE		//
//		 oo		 _____					//
//		_I__n_n__||_|| ________			//
//	  >(_________|_7_|-|______|			//
//	   /o ()() ()() o   oo  oo			//
//////////////////////////////////////////

///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			Header for messages with thread name, avatar, rename button, and mark as unread button

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Badge, Box, IconButton, Tooltip, Typography } from '@mui/material/'
import { useContext } from 'react'
import {
  generateMessageThreadName,
  rJSX_Avatar,
  TsInterface_ChatHeaderSettings,
  TsInterface_ChatMarkAsUnread,
  TsInterface_ChatRenameMessageThread,
  TsInterface_ChatThread,
} from 'rfbp_core/components/chat'
import { Icon } from 'rfbp_core/components/icons'
import { Context_UserInterface_CustomDialog, Context_UserInterface_PromptDialog, TsInterface_PromptDialogObject } from 'rfbp_core/services/context'
import { getProp, objectToArray } from 'rfbp_core/services/helper_functions'

import { rLIB } from 'rfbp_core/localization/library'
import { ChatMembershipEditCustomDialog } from './chat_membership_edit'

///////////////////////////////
// Typescript
///////////////////////////////

interface TsInterface_ComponentProps {
  chatHeaderSettings: TsInterface_ChatHeaderSettings
  chatMarkAsUnread: TsInterface_ChatMarkAsUnread
  chatRenameMessageThread: TsInterface_ChatRenameMessageThread
  chatThread: TsInterface_ChatThread
}

///////////////////////////////
// Variables
///////////////////////////////

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Component
///////////////////////////////

export const ChatHeaderDisplay = (props: TsInterface_ComponentProps): JSX.Element => {
  // Props
  let pr_chatHeaderSettings: TsInterface_ComponentProps['chatHeaderSettings'] = getProp(props, 'chatHeaderSettings', {})
  let pr_chatThread: TsInterface_ComponentProps['chatThread'] = getProp(props, 'chatThread', {})
  let pr_chatRenameMessageThread: TsInterface_ComponentProps['chatRenameMessageThread'] = getProp(props, 'chatRenameMessageThread', (threadKey: string) => {
    return new Promise((resolve, reject) => {
      resolve({ success: true })
    })
  })
  let pr_chatMarkAsUnread: TsInterface_ComponentProps['chatMarkAsUnread'] = getProp(props, 'chatMarkAsUnread', (threadKey: string, threadName: string) => {
    return new Promise((resolve, reject) => {
      resolve({ success: true })
    })
  })

  // Hooks - useContext, useState, useReducer, other
  // { sort-start } - hooks
  const { uc_setUserInterface_CustomDialogDisplay } = useContext(Context_UserInterface_CustomDialog)
  const { uc_setUserInterface_PromptDialogDisplay } = useContext(Context_UserInterface_PromptDialog)
  // { sort-end } - hooks

  // Hooks - useEffect

  // Functions
  const markAsUnread = () => {
    pr_chatMarkAsUnread(pr_chatThread.key).finally(() => {
      // Nothing?
    })
  }

  const renameMessageThread = () => {
    let defaultValue = getProp(pr_chatThread, 'thread_name_override_desktop', null)
    const PromptDialogObject: TsInterface_PromptDialogObject = {
      color: 'info',
      confirm_text: <>{rLIB('Rename')}</>,
      default_value: defaultValue,
      header: <>{rLIB('Rename message thread')}</>,
      icon: <Icon icon="pen-to-square" />,
      input_label: <>{rLIB('Thread Name')}</>,
      input_type: 'text',
      submit_callback: (promptValue: string) => {
        return new Promise((resolve, reject) => {
          pr_chatRenameMessageThread(pr_chatThread.key, promptValue).finally(() => {
            resolve({ success: true })
          })
        })
      },
      text: <>{rLIB('Enter a name for this message thread')}</>,
    }
    uc_setUserInterface_PromptDialogDisplay({
      display: true,
      prompt: PromptDialogObject,
    })
  }

  const openChatThreadMembership = () => {
    uc_setUserInterface_CustomDialogDisplay({
      display: true,
      dialog: {
        dialog_jsx: (
          <ChatMembershipEditCustomDialog
            chatThread={pr_chatThread}
            chatThreadKey={pr_chatThread.key}
          />
        ),
        settings: {
          max_width: 'lg',
        },
      },
    })
  }

  // JSX Generation
  const rJSX_RenameThreadButton = (): JSX.Element => {
    let buttonJSX = <></>
    if (pr_chatHeaderSettings == null || pr_chatHeaderSettings['hide_rename_button'] !== true) {
      buttonJSX = (
        <Tooltip
          title={rLIB('Rename message thread')}
          placement="top"
        >
          <IconButton
            className="tw-inline-block"
            onClick={() => {
              renameMessageThread()
            }}
            sx={{ marginTop: '-4px', paddingBottom: '0px', paddingTop: '0px' }}
          >
            <Icon icon="pen-to-square" />
          </IconButton>
        </Tooltip>
      )
    }
    return buttonJSX
  }

  const rJSX_EditMembershipButton = (): JSX.Element => {
    let buttonJSX = <></>
    if (pr_chatHeaderSettings == null || pr_chatHeaderSettings['show_membership_edit_button'] === true) {
      let customMemberCount = 0
      if (pr_chatThread != null && pr_chatThread['additional_thread_members'] != null) {
        customMemberCount = objectToArray(pr_chatThread['additional_thread_members']).length
      }
      buttonJSX = (
        <Tooltip
          title={rLIB('Edit Thread Membership')}
          placement="top"
        >
          <IconButton
            className="tw-inline-block"
            onClick={() => {
              openChatThreadMembership()
            }}
            sx={{ marginTop: '-4px', paddingBottom: '0px', paddingTop: '0px' }}
          >
            <Badge
              color="warning"
              overlap="circular"
              badgeContent={customMemberCount}
              invisible={customMemberCount === 0}
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
              <Icon icon="users" />
            </Badge>
          </IconButton>
        </Tooltip>
      )
    }
    return buttonJSX
  }

  const rJSX_UnreadThreadButton = (): JSX.Element => {
    let buttonJSX = <></>
    if (pr_chatHeaderSettings == null || pr_chatHeaderSettings['hide_unread_button'] !== true) {
      buttonJSX = (
        <Tooltip
          title={rLIB('Mark as Unread')}
          placement="top"
        >
          <IconButton
            className="tw-inline-block"
            onClick={() => {
              markAsUnread()
            }}
            sx={{ marginTop: '-4px', paddingBottom: '0px', paddingTop: '0px' }}
          >
            <Icon icon="envelope" />
          </IconButton>
        </Tooltip>
      )
    }
    return buttonJSX
  }

  const rJSX_ThreadAvatar = (chatThread: TsInterface_ChatThread) => {
    let threadAvatarJSX = <></>
    if (pr_chatHeaderSettings != null && chatThread.avatar_override != null) {
      threadAvatarJSX = chatThread.avatar_override
    } else {
      threadAvatarJSX = rJSX_Avatar(chatThread['associated_member_names'], 'lc_message_avatar_medium', 0, pr_chatHeaderSettings.associated_viewer_key)
    }
    return threadAvatarJSX
  }

  const rJSX_ThreadMembersList = (chatThread: TsInterface_ChatThread): JSX.Element => {
    let listJSX = <></>
    if (chatThread != null && chatThread['thread_name_override_desktop'] != null) {
      listJSX = (
        <Typography
          className="tw-ml-3 tw-italic tw-opacity-30"
          sx={{ paddingLeft: '0px' }}
          variant="body1"
        >
          {generateMessageThreadName(undefined, chatThread['associated_member_names'], pr_chatHeaderSettings.associated_viewer_key)}
        </Typography>
      )
    }
    return listJSX
  }

  const rJSX_Component = (): JSX.Element => {
    let componentJSX = (
      <Box
        component="div"
        className="tw-text-left tw-p-1"
      >
        <Box
          component="div"
          className="tw-inline-block"
        >
          <Box
            component="div"
            className="tw-flex"
          >
            {rJSX_ThreadAvatar(pr_chatThread)}
          </Box>
        </Box>
        <Box
          component="div"
          className="tw-inline-block tw-align-top tw-pb-2 tw-pt-0"
          sx={{ marginTop: '0px' }}
        >
          <Box>
            <Typography
              className="tw-inline-block tw-ml-3"
              sx={{ paddingLeft: '0px' }}
              variant="h6"
            >
              {generateMessageThreadName(
                pr_chatThread['thread_name_override_desktop'],
                pr_chatThread['associated_member_names'],
                pr_chatHeaderSettings.associated_viewer_key,
              )}
            </Typography>
            {rJSX_UnreadThreadButton()}
            {rJSX_RenameThreadButton()}
            {rJSX_EditMembershipButton()}
          </Box>
          <Box>{rJSX_ThreadMembersList(pr_chatThread)}</Box>
        </Box>
      </Box>
    )
    return componentJSX
  }

  // Render
  return <>{rJSX_Component()}</>
}
