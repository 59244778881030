//////////////////////////////////////////
//		  ooOOOO BOILERPLATE FILE		//
//		 oo		 _____					//
//		_I__n_n__||_|| ________			//
//	  >(_________|_7_|-|______|			//
//	   /o ()() ()() o   oo  oo			//
//////////////////////////////////////////

///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Box } from '@mui/material/'
import React, { useEffect, useState } from 'react'
import { getProp } from 'rfbp_core/services/helper_functions'

import { TsInterface_TabAdvancedButtonsArray } from './tabs_types'

///////////////////////////////
// Typescript
///////////////////////////////

interface TsInterface_ComponentProps {
  tabButtons: TsInterface_TabAdvancedButtonsArray
}

///////////////////////////////
// Variables
///////////////////////////////

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Component
///////////////////////////////

export const TabButtons = (props: TsInterface_ComponentProps): JSX.Element => {
  // Props
  let pr_tabButtons: TsInterface_ComponentProps['tabButtons'] = getProp(props, 'tabButtons', [])

  // Hooks - useContext, useState, useReducer, other
  // { sort-start } - hooks
  const [us_pageWidth, us_setPageWidth] = useState<number>(window.innerWidth)
  // { sort-end } - hooks

  // Hooks - useEffect
  useEffect(() => {
    const handleResize = () => {
      us_setPageWidth(window.innerWidth)
    }
    window.addEventListener('resize', handleResize)
    // Cleanup function to remove event listener when component unmounts
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  // Functions

  // JSX Generation
  const rJSX_ButtonRow = (): JSX.Element => {
    let buttonsJSX = <></>
    let buttonsToRender: JSX.Element[] = []
    if (pr_tabButtons != null) {
      for (let loopButtonIndex in pr_tabButtons) {
        let loopButton = pr_tabButtons[parseInt(loopButtonIndex)]
        if (us_pageWidth < loopButton.sizeCutoff) {
          buttonsToRender.push(loopButton.minJSX)
        } else {
          buttonsToRender.push(loopButton.fullJSX)
        }
      }
    }
    // Button JSX
    buttonsJSX = (
      <Box>
        {buttonsToRender.map((jsx, index) => (
          <React.Fragment key={index}>{jsx}</React.Fragment>
        ))}
      </Box>
    )
    return buttonsJSX
  }

  const rJSX_Component = (): JSX.Element => {
    let componentJSX: JSX.Element = <Box className="tw-w-full">{rJSX_ButtonRow()}</Box>
    return componentJSX
  }

  // Render
  return <>{rJSX_Component()}</>
}
