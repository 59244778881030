///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			containers are pages / views used in the app and are made up of components and can interact with services and models

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Box, Button, Card, Typography } from '@mui/material/'
import { pdf } from '@react-pdf/renderer'
import saveAs from 'file-saver'
import JSZip from 'jszip'
import React, { useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { AuthenticatedContainer } from 'rfbp_aux/containers/authenticated_container'
import { ApplicationPages } from 'rfbp_aux/data/application_structure'
import { Icon } from 'rfbp_core/components/icons'
import { BasicImportButtonAndDialog } from 'rfbp_core/components/imports/basic_import_button_and_dialog'
import { generatePdfFromData, TsInterface_PdfTemplate } from 'rfbp_core/components/pdf/custom'
import { LayeredPdfEditor } from 'rfbp_core/components/pdf/overlayed'
import { TabsUrl } from 'rfbp_core/components/tabs'
import { rLIB } from 'rfbp_core/localization/library'
import { Context_UserInterface_FormDialog } from 'rfbp_core/services/context'
import { formatCurrency, returnFormattedDate } from 'rfbp_core/services/helper_functions'
import { onClickAppNavigation } from 'rfbp_core/services/navigation/navigation_functions'
import { TsInterface_UnspecifiedObject } from 'rfbp_core/typescript/global_types'
import { logoBase64 } from './images/logo_base_64'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

// Authenticated Nav Data
const pageKey: string = ApplicationPages['AdminInvoiceIndexPage']['key']

// PDF Generation

const invoicePdfTemplate: TsInterface_PdfTemplate = {
  page_settings: {
    flexDirection: 'column',
    paddingTop: '0in',
    paddingBottom: '0in',
    paddingLeft: '0in',
    paddingRight: '0in',
  },
  page_contents: {
    COLOR_HEADER: {
      key: 'COLOR_HEADER',
      order: 1,
      component_type: 'static_table',
      table_style: {
        backgroundColor: '#929598',
        display: 'flex',
        width: 100,
      },
      rows: {
        ROW1: {
          key: 'ROW1',
          order: 1,
          row_style: {
            flexDirection: 'row',
          },
          cells: {
            PLACEHOLDER_CELL: {
              key: 'PLACEHOLDER_CELL',
              order: 2,
              cell_style: {
                width: 40,
              },
              contents: {
                EMPTY_TEXT: {
                  component_type: 'static_text',
                  key: 'EMPTY_TEXT',
                  order: 1,
                  text_style: {
                    fontSize: 24,
                    textAlign: 'right',
                    color: '#22c6ff',
                    marginBottom: 10,
                  },
                  text: ' ',
                },
              },
            },
          },
        },
      },
    },
    CONTENT_VIEW: {
      key: 'CONTENT_VIEW',
      order: 2,
      component_type: 'view',
      view_style: {
        paddingTop: '0.5in',
        paddingBottom: '0.5in',
        paddingLeft: '1in',
        paddingRight: '1in',
      },
      contents: {
        LOGO_HEADER: {
          key: 'LOGO_HEADER',
          order: 1,
          component_type: 'static_table',
          table_style: {
            display: 'flex',
            width: 100,
          },
          rows: {
            ROW1: {
              key: 'ROW1',
              order: 1,
              row_style: {
                flexDirection: 'row',
              },
              cells: {
                LOGO_CELL: {
                  key: 'LOGO_CELL',
                  order: 1,
                  cell_style: {
                    width: 60,
                  },
                  contents: {
                    LOGO: {
                      component_type: 'static_image',
                      key: 'LOGO',
                      order: 1,
                      image_style: {
                        width: 70,
                        marginTop: 16,
                        marginBottom: 16,
                      },
                      src: logoBase64,
                    },
                  },
                },
                INVOICE_CELL: {
                  key: 'INVOICE_CELL',
                  order: 2,
                  cell_style: {
                    width: 40,
                  },
                  contents: {
                    INVOICE_TEXT: {
                      component_type: 'static_text',
                      key: 'INVOICE_TEXT',
                      order: 1,
                      text_style: {
                        fontSize: 24,
                        textAlign: 'right',
                        color: '#22c6ff',
                        marginBottom: 10,
                      },
                      text: 'INVOICE',
                    },
                    TABLE: {
                      component_type: 'static_table',
                      key: 'TABLE',
                      order: 1,
                      table_style: {
                        display: 'flex',
                        width: 100,
                        textAlign: 'right',
                      },
                      rows: {
                        ROW1: {
                          key: 'ROW1',
                          order: 1,
                          row_style: {
                            flexDirection: 'row',
                          },
                          cells: {
                            CELL1: {
                              key: 'CELL1',
                              order: 1,
                              cell_style: {
                                width: 65,
                                padding: 2,
                              },
                              contents: {
                                INVOICE_NUMBER_LABEL: {
                                  component_type: 'static_text',
                                  key: 'INVOICE_NUMBER_LABEL',
                                  order: 1,
                                  text_style: {
                                    fontSize: 10,
                                    textAlign: 'right',
                                    color: '#BBBBBB',
                                  },
                                  text: 'INVOICE NUMBER',
                                },
                              },
                            },
                            CELL2: {
                              key: 'CELL2',
                              order: 2,
                              cell_style: {
                                width: 35,
                                padding: 2,
                              },
                              contents: {
                                INVOICE_NUMBER: {
                                  component_type: 'variable_text',
                                  key: 'INVOICE_NUMBER',
                                  order: 1,
                                  text_style: {
                                    fontSize: 10,
                                    color: '#000000',
                                  },
                                  variable_mapping_key: 'invoice_number',
                                },
                              },
                            },
                          },
                        },
                        ROW2: {
                          key: 'ROW2',
                          order: 2,
                          row_style: {
                            flexDirection: 'row',
                          },
                          cells: {
                            CELL1: {
                              key: 'CELL1',
                              order: 1,
                              cell_style: {
                                width: 65,
                                padding: 2,
                              },
                              contents: {
                                DATE: {
                                  component_type: 'static_text',
                                  key: 'DATE',
                                  order: 1,
                                  text_style: {
                                    fontSize: 10,
                                    textAlign: 'right',
                                    color: '#BBBBBB',
                                  },
                                  text: 'DATE OF ISSUE ',
                                },
                              },
                            },
                            CELL2: {
                              key: 'CELL2',
                              order: 2,
                              cell_style: {
                                width: 35,
                                padding: 2,
                              },
                              contents: {
                                INVOICE_DATE: {
                                  component_type: 'variable_text',
                                  key: 'INVOICE_DATE',
                                  order: 1,
                                  text_style: {
                                    fontSize: 10,
                                  },
                                  variable_mapping_key: 'invoice_date',
                                  fallback_text: ' ',
                                  fallback_text_style: {
                                    fontSize: 10,
                                    color: '#FF0000',
                                  },
                                },
                              },
                            },
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
          },
        },
        ADDRESS_TABLE: {
          key: 'ADDRESS_TABLE',
          order: 2,
          component_type: 'static_table',
          table_style: {
            display: 'flex',
            width: 100,
          },
          rows: {
            ROW1: {
              key: 'ROW1',
              order: 1,
              row_style: {
                flexDirection: 'row',
              },
              cells: {
                CELL1: {
                  key: 'CELL1',
                  order: 1,
                  cell_style: {
                    width: 25,
                  },
                  contents: {
                    ADDRESS_LABEL: {
                      component_type: 'static_text',
                      key: 'ADDRESS_LABEL',
                      order: 1,
                      text_style: {
                        marginTop: 5,
                        fontSize: 10,
                        textAlign: 'left',
                        color: '#444444',
                      },
                      // text: '3331 N. PanAm Expy',
                      text: '98 San Jacinto Blvd',
                    },
                  },
                },
                CELL2: {
                  key: 'CELL2',
                  order: 2,
                  cell_style: {
                    width: 25,
                  },
                  contents: {
                    ADDRESS: {
                      component_type: 'static_text',
                      key: 'ADDRESS',
                      order: 1,
                      text_style: {
                        marginTop: 5,
                        fontSize: 10,
                        textAlign: 'left',
                        color: '#444444',
                      },
                      text: 'Accounts Payable',
                    },
                  },
                },
                CELL3: {
                  key: 'CELL3',
                  order: 3,
                  cell_style: {
                    width: 50,
                  },
                  contents: {},
                },
              },
            },
            ROW2: {
              key: 'ROW2',
              order: 2,
              row_style: {
                flexDirection: 'row',
              },
              cells: {
                CELL1: {
                  key: 'CELL1',
                  order: 1,
                  cell_style: {
                    width: 25,
                  },
                  contents: {
                    ADDRESS_LABEL: {
                      component_type: 'static_text',
                      key: 'ADDRESS_LABEL',
                      order: 1,
                      text_style: {
                        marginTop: 5,
                        fontSize: 10,
                        textAlign: 'left',
                        color: '#444444',
                      },
                      // text: 'San Antonio, TX',
                      text: '4th Floor',
                    },
                  },
                },
                CELL2: {
                  key: 'CELL2',
                  order: 2,
                  cell_style: {
                    width: 25,
                  },
                  contents: {
                    ADDRESS: {
                      component_type: 'static_text',
                      key: 'ADDRESS',
                      order: 1,
                      text_style: {
                        marginTop: 5,
                        fontSize: 10,
                        textAlign: 'left',
                        color: '#444444',
                      },
                      text: 'ap@etwenergy.com',
                    },
                  },
                },
                CELL3: {
                  key: 'CELL3',
                  order: 3,
                  cell_style: {
                    width: 50,
                  },
                  contents: {},
                },
              },
            },
            ROW3: {
              key: 'ROW3',
              order: 3,
              row_style: {
                flexDirection: 'row',
              },
              cells: {
                CELL1: {
                  key: 'CELL1',
                  order: 1,
                  cell_style: {
                    width: 25,
                  },
                  contents: {
                    ADDRESS_LABEL: {
                      component_type: 'static_text',
                      key: 'ADDRESS_LABEL',
                      order: 1,
                      text_style: {
                        marginTop: 5,
                        fontSize: 10,
                        textAlign: 'left',
                        color: '#444444',
                      },
                      // text: '78219',
                      text: 'Austin, TX 78701',
                    },
                  },
                },
                CELL2: {
                  key: 'CELL2',
                  order: 2,
                  cell_style: {
                    width: 25,
                  },
                  contents: {
                    ADDRESS: {
                      component_type: 'static_text',
                      key: 'ADDRESS',
                      order: 1,
                      text_style: {
                        marginTop: 5,
                        fontSize: 10,
                        textAlign: 'left',
                        color: '#444444',
                      },
                      text: '801-864-1503',
                    },
                  },
                },
                CELL3: {
                  key: 'CELL3',
                  order: 3,
                  cell_style: {
                    width: 50,
                  },
                  contents: {},
                },
              },
            },
          },
        },
        BILLED_TO_TABLE: {
          key: 'BILLED_TO_TABLE',
          order: 3,
          component_type: 'static_table',
          table_style: {
            display: 'flex',
            width: 100,
            marginBottom: 32,
          },
          rows: {
            ROW1: {
              key: 'ROW1',
              order: 1,
              row_style: {
                flexDirection: 'row',
              },
              cells: {
                CELL1: {
                  key: 'CELL1',
                  order: 1,
                  cell_style: {
                    width: 100,
                    padding: 2,
                    marginTop: 16,
                  },
                  contents: {
                    BILLED_TO: {
                      component_type: 'static_text',
                      key: 'BILLED_TO',
                      order: 1,
                      text_style: {
                        fontSize: 10,
                        textAlign: 'left',
                        color: '#BBBBBB',
                      },
                      text: 'BILLED TO',
                    },
                  },
                },
              },
            },
            ROW2: {
              key: 'ROW2',
              order: 2,
              row_style: {
                flexDirection: 'row',
              },
              cells: {
                CELL1: {
                  key: 'CELL1',
                  order: 1,
                  cell_style: {
                    width: 100,
                    padding: 2,
                  },
                  contents: {
                    INVOICE_DATE: {
                      component_type: 'variable_text',
                      key: 'INVOICE_DATE',
                      order: 1,
                      text_style: {
                        fontSize: 10,
                        color: '#444444',
                      },
                      variable_mapping_key: 'client_name',
                      fallback_text: ' ',
                      fallback_text_style: {
                        fontSize: 10,
                        color: '#FF0000',
                      },
                    },
                  },
                },
              },
            },
            ROW3: {
              key: 'ROW3',
              order: 3,
              row_style: {
                flexDirection: 'row',
              },
              cells: {
                CELL1: {
                  key: 'CELL1',
                  order: 1,
                  cell_style: {
                    width: 100,
                    padding: 2,
                  },
                  contents: {
                    STREET: {
                      component_type: 'variable_text',
                      key: 'STREET',
                      order: 1,
                      text_style: {
                        fontSize: 10,
                        color: '#444444',
                      },
                      variable_mapping_key: 'location_address',
                      fallback_text: ' ',
                      fallback_text_style: {
                        fontSize: 10,
                        color: '#FF0000',
                      },
                    },
                  },
                },
              },
            },
            ROW4: {
              key: 'ROW4',
              order: 4,
              row_style: {
                flexDirection: 'row',
              },
              cells: {
                CELL1: {
                  key: 'CELL1',
                  order: 1,
                  cell_style: {
                    width: 100,
                    padding: 2,
                  },
                  contents: {
                    CITY: {
                      component_type: 'variable_text',
                      key: 'CITY',
                      order: 1,
                      text_style: {
                        fontSize: 10,
                        color: '#444444',
                        display: 'inline-block',
                      },
                      variable_mapping_key: 'location_city_plus_state',
                      fallback_text: ' ',
                      fallback_text_style: {
                        fontSize: 10,
                        color: '#FF0000',
                      },
                    },
                  },
                },
              },
            },
            ROW5: {
              key: 'ROW5',
              order: 5,
              row_style: {
                flexDirection: 'row',
              },
              cells: {
                CELL1: {
                  key: 'CELL1',
                  order: 1,
                  cell_style: {
                    width: 100,
                    padding: 2,
                  },
                  contents: {
                    ZIP: {
                      component_type: 'variable_text',
                      key: 'ZIP',
                      order: 1,
                      text_style: {
                        fontSize: 10,
                        color: '#444444',
                      },
                      variable_mapping_key: 'location_zip',
                      fallback_text: ' ',
                      fallback_text_style: {
                        fontSize: 10,
                        color: '#FF0000',
                      },
                    },
                  },
                },
              },
            },
          },
        },
        LINE_ITEMS_TABLE: {
          key: 'LINE_ITEMS_TABLE',
          order: 5,
          component_type: 'looped_data_table',
          table_style: {
            display: 'flex',
            width: 100,
          },
          row_style: {
            flexDirection: 'row',
          },
          table_data_variable_mapping_key: 'line_items',
          columns: {
            COL0: {
              key: 'COL0',
              order: 0,
              column_header_style: {
                width: 20,
                backgroundColor: '#929598',
                color: 'white',
                fontSize: 10,
                padding: 8,
                marginBottom: 4,
              },
              column_body_style: {
                width: 20,
                backgroundColor: '#F3F3F3',
                fontSize: 10,
                padding: 8,
                marginBottom: 4,
              },
              header_text: 'PROJECT',
              variable_mapping_key: 'item_job_code',
              fallback_text: ' ',
              fallback_text_style: {
                color: '#FF0000',
              },
            },
            COL1: {
              key: 'COL1',
              order: 1,
              column_header_style: {
                width: 60,
                backgroundColor: '#929598',
                color: 'white',
                fontSize: 10,
                padding: 8,
                marginBottom: 4,
              },
              column_body_style: {
                width: 60,
                backgroundColor: '#F3F3F3',
                fontSize: 10,
                padding: 8,
                marginBottom: 4,
              },
              header_text: 'DESCRIPTION',
              variable_mapping_key: 'item_description',
              fallback_text: ' ',
              fallback_text_style: {
                color: '#FF0000',
              },
            },
            COL4: {
              key: 'COL4',
              order: 4,
              column_header_style: {
                width: 20,
                backgroundColor: '#929598',
                color: 'white',
                fontSize: 10,
                padding: 8,
                marginBottom: 4,
              },
              column_body_style: {
                width: 20,
                backgroundColor: '#F3F3F3',
                fontSize: 10,
                padding: 8,
                marginBottom: 4,
              },
              header_text: 'AMOUNT ($)',
              variable_mapping_key: 'item_total',
              fallback_text: ' ',
              fallback_text_style: {
                color: '#FF0000',
              },
            },
          },
        },

        TOTAL_TABLE: {
          key: 'TOTAL_TABLE',
          order: 6,
          component_type: 'static_table',
          table_style: {
            display: 'flex',
            width: 100,
            marginTop: 10,
          },
          rows: {
            ROW1: {
              key: 'ROW1',
              order: 1,
              row_style: {
                flexDirection: 'row',
              },
              cells: {
                CELL0: {
                  key: 'CELL0',
                  order: 0,
                  cell_style: {
                    width: 80,
                    padding: 2,
                  },
                  contents: {},
                },
                CELL1: {
                  key: 'CELL1',
                  order: 1,
                  cell_style: {
                    width: 20,
                    padding: 2,
                    borderTop: '1px solid #444444',
                    marginTop: 10,
                  },
                  contents: {
                    INVOICE_DATE_LABEL: {
                      component_type: 'static_text',
                      key: 'INVOICE_DATE_LABEL',
                      order: 1,
                      text_style: {
                        paddingTop: 18,
                        fontSize: 10,
                        textAlign: 'right',
                        color: '#BBBBBB',
                      },
                      text: 'INVOICE TOTAL',
                    },
                  },
                },
              },
            },
            ROW2: {
              key: 'ROW2',
              order: 2,
              row_style: {
                flexDirection: 'row',
              },
              cells: {
                CELL0: {
                  key: 'CELL0',
                  order: 0,
                  cell_style: {
                    width: 80,
                    padding: 2,
                  },
                  contents: {},
                },
                CELL1: {
                  key: 'CELL1',
                  order: 1,
                  cell_style: {
                    width: 20,
                    padding: 2,
                  },
                  contents: {
                    LINE_ITEM_TOTAL: {
                      component_type: 'variable_text',
                      key: 'LINE_ITEM_TOTAL',
                      order: 1,
                      text_style: {
                        fontSize: 16,
                        fontWeight: 'bold',
                        textAlign: 'right',
                      },
                      variable_mapping_key: 'line_item_total',
                      fallback_text: ' ',
                      fallback_text_style: {
                        fontSize: 16,
                        color: '#FF0000',
                        textAlign: 'right',
                      },
                    },
                  },
                },
              },
            },
          },
        },
        NOTES_TABLE: {
          component_type: 'static_table',
          key: 'NOTES_TABLE',
          order: 7,
          table_style: {
            display: 'flex',
            width: 100,
          },
          rows: {
            ROW1: {
              key: 'ROW1',
              order: 1,
              row_style: {
                flexDirection: 'row',
              },
              cells: {
                CELL1: {
                  key: 'CELL1',
                  order: 1,
                  cell_style: {
                    width: 100,
                    padding: 2,
                    marginTop: 6,
                  },
                  contents: {
                    INVOICE_DATE_LABEL: {
                      key: 'INVOICE_DATE_LABEL',
                      order: 1,
                      component_type: 'static_text',
                      text_style: {
                        fontSize: 10,
                        textAlign: 'left',
                        // color: '#000000',
                        marginRight: 8,
                        fontWeight: 'bold',
                        color: '#BBBBBB',
                      },
                      text: 'NOTES:',
                    },
                  },
                },
              },
            },
            ROW2: {
              key: 'ROW2',
              order: 2,
              row_style: {
                flexDirection: 'row',
              },
              cells: {
                CELL1: {
                  key: 'CELL1',
                  order: 1,
                  cell_style: {
                    width: 100,
                    padding: 2,
                    marginTop: 6,
                  },
                  contents: {
                    NOTES: {
                      component_type: 'variable_text',
                      key: 'NOTES',
                      order: 1,
                      text_style: {
                        fontSize: 10,
                        fontWeight: 'bold',
                      },
                      variable_mapping_key: 'line_item_notes',
                      fallback_text: ' ',
                      fallback_text_style: {
                        fontSize: 10,
                        color: '#FF0000',
                      },
                    },
                  },
                },
              },
            },
          },
        },
        TERMS_TABLE: {
          key: 'TERMS_TABLE',
          order: 8,
          component_type: 'static_table',
          table_style: {
            display: 'flex',
            width: 100,
            marginTop: 40,
          },
          rows: {
            ROW1: {
              key: 'ROW1',
              order: 1,
              row_style: {
                flexDirection: 'row',
              },
              cells: {
                CELL1: {
                  key: 'CELL1',
                  order: 1,
                  cell_style: {
                    width: 100,
                    padding: 2,
                  },
                  contents: {
                    INVOICE_DATE_LABEL: {
                      component_type: 'static_text',
                      key: 'INVOICE_DATE_LABEL',
                      order: 1,
                      text_style: {
                        fontSize: 10,
                        textAlign: 'left',
                        color: '#444444',
                      },
                      text: 'NET10',
                      // text: 'This is a quote. Invoice charges may vary and are based on actual work required.',
                    },
                  },
                },
              },
            },
            ROW2: {
              key: 'ROW2',
              order: 2,
              row_style: {
                flexDirection: 'row',
              },
              cells: {
                CELL1: {
                  key: 'CELL1',
                  order: 1,
                  cell_style: {
                    width: 100,
                    padding: 2,
                    borderBottom: '1px solid #444444',
                  },
                  contents: {
                    INVOICE_DATE_LABEL: {
                      component_type: 'static_text',
                      key: 'INVOICE_DATE_LABEL',
                      order: 1,
                      text_style: {
                        fontSize: 10,
                        textAlign: 'left',
                        color: '#444444',
                      },
                      text: ' ',
                    },
                  },
                },
              },
            },
          },
        },
      },
    },
  },
}

const detailedInvoicePdfTemplate: TsInterface_PdfTemplate = {
  page_settings: {
    flexDirection: 'column',
    paddingTop: '0in',
    paddingBottom: '0in',
    paddingLeft: '0in',
    paddingRight: '0in',
  },
  page_contents: {
    COLOR_HEADER: {
      key: 'COLOR_HEADER',
      order: 1,
      component_type: 'static_table',
      table_style: {
        backgroundColor: '#929598',
        display: 'flex',
        width: 100,
      },
      rows: {
        ROW1: {
          key: 'ROW1',
          order: 1,
          row_style: {
            flexDirection: 'row',
          },
          cells: {
            PLACEHOLDER_CELL: {
              key: 'PLACEHOLDER_CELL',
              order: 2,
              cell_style: {
                width: 40,
              },
              contents: {
                EMPTY_TEXT: {
                  component_type: 'static_text',
                  key: 'EMPTY_TEXT',
                  order: 1,
                  text_style: {
                    fontSize: 24,
                    textAlign: 'right',
                    color: '#22c6ff',
                    marginBottom: 10,
                  },
                  text: ' ',
                },
              },
            },
          },
        },
      },
    },
    CONTENT_VIEW: {
      key: 'CONTENT_VIEW',
      order: 2,
      component_type: 'view',
      view_style: {
        paddingTop: '0.5in',
        paddingBottom: '0.5in',
        paddingLeft: '1in',
        paddingRight: '1in',
      },
      contents: {
        LOGO_HEADER: {
          key: 'LOGO_HEADER',
          order: 1,
          component_type: 'static_table',
          table_style: {
            display: 'flex',
            width: 100,
          },
          rows: {
            ROW1: {
              key: 'ROW1',
              order: 1,
              row_style: {
                flexDirection: 'row',
              },
              cells: {
                LOGO_CELL: {
                  key: 'LOGO_CELL',
                  order: 1,
                  cell_style: {
                    width: 60,
                  },
                  contents: {
                    LOGO: {
                      component_type: 'static_image',
                      key: 'LOGO',
                      order: 1,
                      image_style: {
                        width: 70,
                        marginTop: 16,
                        marginBottom: 16,
                      },
                      src: logoBase64,
                    },
                  },
                },
                INVOICE_CELL: {
                  key: 'INVOICE_CELL',
                  order: 2,
                  cell_style: {
                    width: 40,
                  },
                  contents: {
                    INVOICE_TEXT: {
                      component_type: 'static_text',
                      key: 'INVOICE_TEXT',
                      order: 1,
                      text_style: {
                        fontSize: 24,
                        textAlign: 'right',
                        color: '#22c6ff',
                        marginBottom: 10,
                      },
                      text: 'INVOICE',
                    },
                    TABLE: {
                      component_type: 'static_table',
                      key: 'TABLE',
                      order: 1,
                      table_style: {
                        display: 'flex',
                        width: 100,
                        textAlign: 'right',
                      },
                      rows: {
                        ROW1: {
                          key: 'ROW1',
                          order: 1,
                          row_style: {
                            flexDirection: 'row',
                          },
                          cells: {
                            CELL1: {
                              key: 'CELL1',
                              order: 1,
                              cell_style: {
                                width: 65,
                                padding: 2,
                              },
                              contents: {
                                INVOICE_NUMBER_LABEL: {
                                  component_type: 'static_text',
                                  key: 'INVOICE_NUMBER_LABEL',
                                  order: 1,
                                  text_style: {
                                    fontSize: 10,
                                    textAlign: 'right',
                                    color: '#BBBBBB',
                                  },
                                  text: 'INVOICE NUMBER',
                                },
                              },
                            },
                            CELL2: {
                              key: 'CELL2',
                              order: 2,
                              cell_style: {
                                width: 35,
                                padding: 2,
                              },
                              contents: {
                                INVOICE_NUMBER: {
                                  component_type: 'variable_text',
                                  key: 'INVOICE_NUMBER',
                                  order: 1,
                                  text_style: {
                                    fontSize: 10,
                                    color: '#000000',
                                  },
                                  variable_mapping_key: 'invoice_number',
                                },
                              },
                            },
                          },
                        },
                        ROW2: {
                          key: 'ROW2',
                          order: 2,
                          row_style: {
                            flexDirection: 'row',
                          },
                          cells: {
                            CELL1: {
                              key: 'CELL1',
                              order: 1,
                              cell_style: {
                                width: 65,
                                padding: 2,
                              },
                              contents: {
                                DATE: {
                                  component_type: 'static_text',
                                  key: 'DATE',
                                  order: 1,
                                  text_style: {
                                    fontSize: 10,
                                    textAlign: 'right',
                                    color: '#BBBBBB',
                                  },
                                  text: 'DATE OF ISSUE ',
                                },
                              },
                            },
                            CELL2: {
                              key: 'CELL2',
                              order: 2,
                              cell_style: {
                                width: 35,
                                padding: 2,
                              },
                              contents: {
                                INVOICE_DATE: {
                                  component_type: 'variable_text',
                                  key: 'INVOICE_DATE',
                                  order: 1,
                                  text_style: {
                                    fontSize: 10,
                                  },
                                  variable_mapping_key: 'invoice_date',
                                  fallback_text: ' ',
                                  fallback_text_style: {
                                    fontSize: 10,
                                    color: '#FF0000',
                                  },
                                },
                              },
                            },
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
          },
        },
        ADDRESS_TABLE: {
          key: 'ADDRESS_TABLE',
          order: 2,
          component_type: 'static_table',
          table_style: {
            display: 'flex',
            width: 100,
          },
          rows: {
            ROW1: {
              key: 'ROW1',
              order: 1,
              row_style: {
                flexDirection: 'row',
              },
              cells: {
                CELL1: {
                  key: 'CELL1',
                  order: 1,
                  cell_style: {
                    width: 25,
                  },
                  contents: {
                    ADDRESS_LABEL: {
                      component_type: 'static_text',
                      key: 'ADDRESS_LABEL',
                      order: 1,
                      text_style: {
                        marginTop: 5,
                        fontSize: 10,
                        textAlign: 'left',
                        color: '#444444',
                      },
                      // text: '3331 N. PanAm Expy',
                      text: '98 San Jacinto Blvd',
                    },
                  },
                },
                CELL2: {
                  key: 'CELL2',
                  order: 2,
                  cell_style: {
                    width: 25,
                  },
                  contents: {
                    ADDRESS: {
                      component_type: 'static_text',
                      key: 'ADDRESS',
                      order: 1,
                      text_style: {
                        marginTop: 5,
                        fontSize: 10,
                        textAlign: 'left',
                        color: '#444444',
                      },
                      text: 'Accounts Payable',
                    },
                  },
                },
                CELL3: {
                  key: 'CELL3',
                  order: 3,
                  cell_style: {
                    width: 50,
                  },
                  contents: {},
                },
              },
            },
            ROW2: {
              key: 'ROW2',
              order: 2,
              row_style: {
                flexDirection: 'row',
              },
              cells: {
                CELL1: {
                  key: 'CELL1',
                  order: 1,
                  cell_style: {
                    width: 25,
                  },
                  contents: {
                    ADDRESS_LABEL: {
                      component_type: 'static_text',
                      key: 'ADDRESS_LABEL',
                      order: 1,
                      text_style: {
                        marginTop: 5,
                        fontSize: 10,
                        textAlign: 'left',
                        color: '#444444',
                      },
                      // text: 'San Antonio, TX',
                      text: '4th Floor',
                    },
                  },
                },
                CELL2: {
                  key: 'CELL2',
                  order: 2,
                  cell_style: {
                    width: 25,
                  },
                  contents: {
                    ADDRESS: {
                      component_type: 'static_text',
                      key: 'ADDRESS',
                      order: 1,
                      text_style: {
                        marginTop: 5,
                        fontSize: 10,
                        textAlign: 'left',
                        color: '#444444',
                      },
                      text: 'ap@etwenergy.com',
                    },
                  },
                },
                CELL3: {
                  key: 'CELL3',
                  order: 3,
                  cell_style: {
                    width: 50,
                  },
                  contents: {},
                },
              },
            },
            ROW3: {
              key: 'ROW3',
              order: 3,
              row_style: {
                flexDirection: 'row',
              },
              cells: {
                CELL1: {
                  key: 'CELL1',
                  order: 1,
                  cell_style: {
                    width: 25,
                  },
                  contents: {
                    ADDRESS_LABEL: {
                      component_type: 'static_text',
                      key: 'ADDRESS_LABEL',
                      order: 1,
                      text_style: {
                        marginTop: 5,
                        fontSize: 10,
                        textAlign: 'left',
                        color: '#444444',
                      },
                      // text: '78219',
                      text: 'Austin, TX 78701',
                    },
                  },
                },
                CELL2: {
                  key: 'CELL2',
                  order: 2,
                  cell_style: {
                    width: 25,
                  },
                  contents: {
                    ADDRESS: {
                      component_type: 'static_text',
                      key: 'ADDRESS',
                      order: 1,
                      text_style: {
                        marginTop: 5,
                        fontSize: 10,
                        textAlign: 'left',
                        color: '#444444',
                      },
                      text: '801-864-1503',
                    },
                  },
                },
                CELL3: {
                  key: 'CELL3',
                  order: 3,
                  cell_style: {
                    width: 50,
                  },
                  contents: {},
                },
              },
            },
          },
        },
        BILLED_TO_TABLE: {
          key: 'BILLED_TO_TABLE',
          order: 3,
          component_type: 'static_table',
          table_style: {
            display: 'flex',
            width: 100,
            marginBottom: 32,
          },
          rows: {
            ROW1: {
              key: 'ROW1',
              order: 1,
              row_style: {
                flexDirection: 'row',
              },
              cells: {
                CELL1: {
                  key: 'CELL1',
                  order: 1,
                  cell_style: {
                    width: 100,
                    padding: 2,
                    marginTop: 16,
                  },
                  contents: {
                    BILLED_TO: {
                      component_type: 'static_text',
                      key: 'BILLED_TO',
                      order: 1,
                      text_style: {
                        fontSize: 10,
                        textAlign: 'left',
                        color: '#BBBBBB',
                      },
                      text: 'BILLED TO',
                    },
                  },
                },
              },
            },
            ROW2: {
              key: 'ROW2',
              order: 2,
              row_style: {
                flexDirection: 'row',
              },
              cells: {
                CELL1: {
                  key: 'CELL1',
                  order: 1,
                  cell_style: {
                    width: 100,
                    padding: 2,
                  },
                  contents: {
                    INVOICE_DATE: {
                      component_type: 'variable_text',
                      key: 'INVOICE_DATE',
                      order: 1,
                      text_style: {
                        fontSize: 10,
                        color: '#444444',
                      },
                      variable_mapping_key: 'client_name',
                      fallback_text: ' ',
                      fallback_text_style: {
                        fontSize: 10,
                        color: '#FF0000',
                      },
                    },
                  },
                },
              },
            },
            ROW3: {
              key: 'ROW3',
              order: 3,
              row_style: {
                flexDirection: 'row',
              },
              cells: {
                CELL1: {
                  key: 'CELL1',
                  order: 1,
                  cell_style: {
                    width: 100,
                    padding: 2,
                  },
                  contents: {
                    STREET: {
                      component_type: 'variable_text',
                      key: 'STREET',
                      order: 1,
                      text_style: {
                        fontSize: 10,
                        color: '#444444',
                      },
                      variable_mapping_key: 'location_address',
                      fallback_text: ' ',
                      fallback_text_style: {
                        fontSize: 10,
                        color: '#FF0000',
                      },
                    },
                  },
                },
              },
            },
            ROW4: {
              key: 'ROW4',
              order: 4,
              row_style: {
                flexDirection: 'row',
              },
              cells: {
                CELL1: {
                  key: 'CELL1',
                  order: 1,
                  cell_style: {
                    width: 100,
                    padding: 2,
                  },
                  contents: {
                    CITY: {
                      component_type: 'variable_text',
                      key: 'CITY',
                      order: 1,
                      text_style: {
                        fontSize: 10,
                        color: '#444444',
                        display: 'inline-block',
                      },
                      variable_mapping_key: 'location_city_plus_state',
                      fallback_text: ' ',
                      fallback_text_style: {
                        fontSize: 10,
                        color: '#FF0000',
                      },
                    },
                  },
                },
              },
            },
            ROW5: {
              key: 'ROW5',
              order: 5,
              row_style: {
                flexDirection: 'row',
              },
              cells: {
                CELL1: {
                  key: 'CELL1',
                  order: 1,
                  cell_style: {
                    width: 100,
                    padding: 2,
                  },
                  contents: {
                    ZIP: {
                      component_type: 'variable_text',
                      key: 'ZIP',
                      order: 1,
                      text_style: {
                        fontSize: 10,
                        color: '#444444',
                      },
                      variable_mapping_key: 'location_zip',
                      fallback_text: ' ',
                      fallback_text_style: {
                        fontSize: 10,
                        color: '#FF0000',
                      },
                    },
                  },
                },
              },
            },
          },
        },
        JOB_CODE_TABLE: {
          component_type: 'static_table',
          key: 'JOB_CODE_TABLE',
          order: 4,
          table_style: {
            display: 'flex',
            width: 100,
          },
          rows: {
            ROW1: {
              key: 'ROW1',
              order: 1,
              row_style: {
                flexDirection: 'row',
              },
              cells: {
                CELL1: {
                  key: 'CELL1',
                  order: 1,
                  cell_style: {
                    width: 100,
                    padding: 2,
                    marginTop: 6,
                  },
                  contents: {
                    JOB_CODE: {
                      key: 'JOB_CODE',
                      order: 1,
                      component_type: 'variable_text',
                      text_style: {
                        fontSize: 14,
                        textAlign: 'left',
                        // color: '#000000',
                        marginRight: 8,
                        fontWeight: 'bold',
                        color: '#22c6ff',
                      },
                      variable_mapping_key: 'job_code',
                      fallback_text: ' ',
                      fallback_text_style: {
                        fontSize: 14,
                        textAlign: 'left',
                        // color: '#000000',
                        marginRight: 8,
                        fontWeight: 'bold',
                        color: '#22c6ff',
                      },
                    },
                  },
                },
              },
            },
          },
        },
        LINE_ITEMS_TABLE: {
          key: 'LINE_ITEMS_TABLE',
          order: 5,
          component_type: 'looped_data_table',
          table_style: {
            display: 'flex',
            width: 100,
          },
          row_style: {
            flexDirection: 'row',
          },
          table_data_variable_mapping_key: 'line_items',
          columns: {
            COL1: {
              key: 'COL1',
              order: 1,
              column_header_style: {
                width: 50,
                backgroundColor: '#929598',
                color: 'white',
                fontSize: 10,
                padding: 8,
                marginBottom: 4,
              },
              column_body_style: {
                width: 50,
                backgroundColor: '#F3F3F3',
                fontSize: 10,
                padding: 8,
                marginBottom: 4,
              },
              header_text: 'DESCRIPTION',
              variable_mapping_key: 'item_description',
              fallback_text: ' ',
              fallback_text_style: {
                color: '#FF0000',
              },
            },
            COL2: {
              key: 'COL2',
              order: 2,
              column_header_style: {
                width: 15,
                backgroundColor: '#929598',
                color: 'white',
                fontSize: 10,
                padding: 8,
                marginBottom: 4,
              },
              column_body_style: {
                width: 15,
                backgroundColor: '#F3F3F3',
                fontSize: 10,
                padding: 8,
                marginBottom: 4,
              },
              header_text: 'RATE ($)',
              variable_mapping_key: 'item_rate',
              fallback_text: ' ',
              fallback_text_style: {
                color: '#FF0000',
              },
            },
            COL3: {
              key: 'COL3',
              order: 3,
              column_header_style: {
                width: 20,
                backgroundColor: '#929598',
                color: 'white',
                fontSize: 10,
                padding: 8,
                marginBottom: 4,
              },
              column_body_style: {
                width: 20,
                backgroundColor: '#F3F3F3',
                fontSize: 10,
                padding: 8,
                marginBottom: 4,
              },
              header_text: 'ADDERS ($)',
              variable_mapping_key: 'item_adders',
              fallback_text: ' ',
              fallback_text_style: {
                color: '#FF0000',
              },
            },
            COL4: {
              key: 'COL4',
              order: 4,
              column_header_style: {
                width: 20,
                backgroundColor: '#929598',
                color: 'white',
                fontSize: 10,
                padding: 8,
                marginBottom: 4,
              },
              column_body_style: {
                width: 20,
                backgroundColor: '#F3F3F3',
                fontSize: 10,
                padding: 8,
                marginBottom: 4,
              },
              header_text: 'AMOUNT ($)',
              variable_mapping_key: 'item_total',
              fallback_text: ' ',
              fallback_text_style: {
                color: '#FF0000',
              },
            },
          },
        },
        FEES_TABLE: {
          component_type: 'static_table',
          key: 'FEES_TABLE',
          order: 6,
          table_style: {
            display: 'flex',
            width: 100,
          },
          rows: {
            ROW1: {
              key: 'ROW1',
              order: 1,
              row_style: {
                flexDirection: 'row',
              },
              cells: {
                CELL1: {
                  key: 'CELL1',
                  order: 1,
                  cell_style: {
                    width: 18,
                    padding: 2,
                    marginTop: 6,
                  },
                  contents: {
                    LABEL: {
                      key: 'LABEL',
                      order: 1,
                      component_type: 'static_text',
                      text_style: {
                        fontSize: 10,
                        textAlign: 'left',
                        // color: '#000000',
                        marginRight: 8,
                        fontWeight: 'bold',
                        color: '#BBBBBB',
                      },
                      text: 'Permitting Fees:',
                    },
                  },
                },
                CELL2: {
                  key: 'CELL2',
                  order: 2,
                  cell_style: {
                    width: 82,
                    padding: 2,
                    marginTop: 6,
                  },
                  contents: {
                    NOTES: {
                      component_type: 'variable_text',
                      key: 'NOTES',
                      order: 1,
                      text_style: {
                        fontSize: 10,
                        fontWeight: 'bold',
                      },
                      variable_mapping_key: 'permitting_fees',
                      fallback_text: ' ',
                      fallback_text_style: {
                        fontSize: 10,
                        color: '#FF0000',
                      },
                    },
                  },
                },
              },
            },
          },
        },
        NOTES_TABLE: {
          component_type: 'static_table',
          key: 'NOTES_TABLE',
          order: 7,
          table_style: {
            display: 'flex',
            width: 100,
          },
          rows: {
            ROW1: {
              key: 'ROW1',
              order: 1,
              row_style: {
                flexDirection: 'row',
              },
              cells: {
                CELL1: {
                  key: 'CELL1',
                  order: 1,
                  cell_style: {
                    width: 8,
                    padding: 2,
                    marginTop: 6,
                  },
                  contents: {
                    LABEL: {
                      key: 'LABEL',
                      order: 1,
                      component_type: 'static_text',
                      text_style: {
                        fontSize: 10,
                        textAlign: 'left',
                        // color: '#000000',
                        marginRight: 8,
                        fontWeight: 'bold',
                        color: '#BBBBBB',
                      },
                      text: 'NOTES:',
                    },
                  },
                },
                CELL2: {
                  key: 'CELL2',
                  order: 2,
                  cell_style: {
                    width: 92,
                    padding: 2,
                    marginTop: 6,
                  },
                  contents: {
                    NOTES: {
                      component_type: 'variable_text',
                      key: 'NOTES',
                      order: 1,
                      text_style: {
                        fontSize: 10,
                        fontWeight: 'bold',
                      },
                      variable_mapping_key: 'line_item_notes',
                      fallback_text: ' ',
                      fallback_text_style: {
                        fontSize: 10,
                        color: '#FF0000',
                      },
                    },
                  },
                },
              },
            },
          },
        },
        TOTAL_TABLE: {
          key: 'TOTAL_TABLE',
          order: 8,
          component_type: 'static_table',
          table_style: {
            display: 'flex',
            width: 100,
            marginTop: 10,
          },
          rows: {
            ROW1: {
              key: 'ROW1',
              order: 1,
              row_style: {
                flexDirection: 'row',
              },
              cells: {
                CELL0: {
                  key: 'CELL0',
                  order: 0,
                  cell_style: {
                    width: 80,
                    padding: 2,
                  },
                  contents: {},
                },
                CELL1: {
                  key: 'CELL1',
                  order: 1,
                  cell_style: {
                    width: 20,
                    padding: 2,
                    borderTop: '1px solid #444444',
                    marginTop: 10,
                  },
                  contents: {
                    INVOICE_DATE_LABEL: {
                      component_type: 'static_text',
                      key: 'INVOICE_DATE_LABEL',
                      order: 1,
                      text_style: {
                        paddingTop: 18,
                        fontSize: 10,
                        textAlign: 'right',
                        color: '#BBBBBB',
                      },
                      text: 'INVOICE TOTAL',
                    },
                  },
                },
              },
            },
            ROW2: {
              key: 'ROW2',
              order: 2,
              row_style: {
                flexDirection: 'row',
              },
              cells: {
                CELL0: {
                  key: 'CELL0',
                  order: 0,
                  cell_style: {
                    width: 80,
                    padding: 2,
                  },
                  contents: {},
                },
                CELL1: {
                  key: 'CELL1',
                  order: 1,
                  cell_style: {
                    width: 20,
                    padding: 2,
                  },
                  contents: {
                    LINE_ITEM_TOTAL: {
                      component_type: 'variable_text',
                      key: 'LINE_ITEM_TOTAL',
                      order: 1,
                      text_style: {
                        fontSize: 16,
                        fontWeight: 'bold',
                        textAlign: 'right',
                      },
                      variable_mapping_key: 'line_item_total',
                      fallback_text: ' ',
                      fallback_text_style: {
                        fontSize: 16,
                        color: '#FF0000',
                        textAlign: 'right',
                      },
                    },
                  },
                },
              },
            },
          },
        },
        TERMS_TABLE: {
          key: 'TERMS_TABLE',
          order: 9,
          component_type: 'static_table',
          table_style: {
            display: 'flex',
            width: 100,
            marginTop: 40,
          },
          rows: {
            ROW1: {
              key: 'ROW1',
              order: 1,
              row_style: {
                flexDirection: 'row',
              },
              cells: {
                CELL1: {
                  key: 'CELL1',
                  order: 1,
                  cell_style: {
                    width: 100,
                    padding: 2,
                  },
                  contents: {
                    INVOICE_DATE_LABEL: {
                      component_type: 'static_text',
                      key: 'INVOICE_DATE_LABEL',
                      order: 1,
                      text_style: {
                        fontSize: 10,
                        textAlign: 'left',
                        color: '#444444',
                      },
                      text: 'NET10',
                      // text: 'This is a quote. Invoice charges may vary and are based on actual work required.',
                    },
                  },
                },
              },
            },
            ROW2: {
              key: 'ROW2',
              order: 2,
              row_style: {
                flexDirection: 'row',
              },
              cells: {
                CELL1: {
                  key: 'CELL1',
                  order: 1,
                  cell_style: {
                    width: 100,
                    padding: 2,
                    borderBottom: '1px solid #444444',
                  },
                  contents: {
                    INVOICE_DATE_LABEL: {
                      component_type: 'static_text',
                      key: 'INVOICE_DATE_LABEL',
                      order: 1,
                      text_style: {
                        fontSize: 10,
                        textAlign: 'left',
                        color: '#444444',
                      },
                      text: ' ',
                    },
                  },
                },
              },
            },
          },
        },
      },
    },
  },
}

const quotePdfTemplate: TsInterface_PdfTemplate = {
  page_settings: {
    flexDirection: 'column',
    paddingTop: '0in',
    paddingBottom: '0in',
    paddingLeft: '0in',
    paddingRight: '0in',
  },
  page_contents: {
    COLOR_HEADER: {
      key: 'COLOR_HEADER',
      order: 1,
      component_type: 'static_table',
      table_style: {
        backgroundColor: '#929598',
        display: 'flex',
        width: 100,
      },
      rows: {
        ROW1: {
          key: 'ROW1',
          order: 1,
          row_style: {
            flexDirection: 'row',
          },
          cells: {
            PLACEHOLDER_CELL: {
              key: 'PLACEHOLDER_CELL',
              order: 2,
              cell_style: {
                width: 40,
              },
              contents: {
                EMPTY_TEXT: {
                  component_type: 'static_text',
                  key: 'EMPTY_TEXT',
                  order: 1,
                  text_style: {
                    fontSize: 24,
                    textAlign: 'right',
                    color: '#22c6ff',
                    marginBottom: 10,
                  },
                  text: ' ',
                },
              },
            },
          },
        },
      },
    },
    CONTENT_VIEW: {
      key: 'CONTENT_VIEW',
      order: 2,
      component_type: 'view',
      view_style: {
        paddingTop: '0.5in',
        paddingBottom: '0.5in',
        paddingLeft: '1in',
        paddingRight: '1in',
      },
      contents: {
        LOGO_HEADER: {
          key: 'LOGO_HEADER',
          order: 1,
          component_type: 'static_table',
          table_style: {
            display: 'flex',
            width: 100,
          },
          rows: {
            ROW1: {
              key: 'ROW1',
              order: 1,
              row_style: {
                flexDirection: 'row',
              },
              cells: {
                LOGO_CELL: {
                  key: 'LOGO_CELL',
                  order: 1,
                  cell_style: {
                    width: 60,
                  },
                  contents: {
                    LOGO: {
                      component_type: 'static_image',
                      key: 'LOGO',
                      order: 1,
                      image_style: {
                        width: 70,
                        marginTop: 16,
                        marginBottom: 16,
                      },
                      src: logoBase64,
                    },
                  },
                },
                INVOICE_CELL: {
                  key: 'INVOICE_CELL',
                  order: 2,
                  cell_style: {
                    width: 40,
                  },
                  contents: {
                    INVOICE_TEXT: {
                      component_type: 'static_text',
                      key: 'INVOICE_TEXT',
                      order: 1,
                      text_style: {
                        fontSize: 24,
                        textAlign: 'right',
                        color: '#22c6ff',
                        marginBottom: 10,
                      },
                      text: 'QUOTE',
                    },
                    TABLE: {
                      component_type: 'static_table',
                      key: 'TABLE',
                      order: 1,
                      table_style: {
                        display: 'flex',
                        width: 100,
                        textAlign: 'right',
                      },
                      rows: {
                        ROW1: {
                          key: 'ROW1',
                          order: 1,
                          row_style: {
                            flexDirection: 'row',
                          },
                          cells: {
                            CELL1: {
                              key: 'CELL1',
                              order: 1,
                              cell_style: {
                                width: 65,
                                padding: 2,
                              },
                              contents: {
                                QUOTE_NUMBER_LABEL: {
                                  component_type: 'static_text',
                                  key: 'QUOTE_NUMBER_LABEL',
                                  order: 1,
                                  text_style: {
                                    fontSize: 10,
                                    textAlign: 'right',
                                    color: '#BBBBBB',
                                  },
                                  text: 'QUOTE NUMBER',
                                },
                              },
                            },
                            CELL2: {
                              key: 'CELL2',
                              order: 2,
                              cell_style: {
                                width: 35,
                                padding: 2,
                              },
                              contents: {
                                QUOTE_NUMBER: {
                                  component_type: 'variable_text',
                                  key: 'QUOTE_NUMBER',
                                  order: 1,
                                  text_style: {
                                    fontSize: 10,
                                    color: '#000000',
                                  },
                                  variable_mapping_key: 'invoice_number',
                                },
                              },
                            },
                          },
                        },
                        ROW2: {
                          key: 'ROW2',
                          order: 2,
                          row_style: {
                            flexDirection: 'row',
                          },
                          cells: {
                            CELL1: {
                              key: 'CELL1',
                              order: 1,
                              cell_style: {
                                width: 65,
                                padding: 2,
                              },
                              contents: {
                                DATE: {
                                  component_type: 'static_text',
                                  key: 'DATE',
                                  order: 1,
                                  text_style: {
                                    fontSize: 10,
                                    textAlign: 'right',
                                    color: '#BBBBBB',
                                  },
                                  text: 'DATE OF ISSUE ',
                                },
                              },
                            },
                            CELL2: {
                              key: 'CELL2',
                              order: 2,
                              cell_style: {
                                width: 35,
                                padding: 2,
                              },
                              contents: {
                                INVOICE_DATE: {
                                  component_type: 'variable_text',
                                  key: 'INVOICE_DATE',
                                  order: 1,
                                  text_style: {
                                    fontSize: 10,
                                  },
                                  variable_mapping_key: 'invoice_date',
                                  fallback_text: ' ',
                                  fallback_text_style: {
                                    fontSize: 10,
                                    color: '#FF0000',
                                  },
                                },
                              },
                            },
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
          },
        },
        ADDRESS_TABLE: {
          key: 'ADDRESS_TABLE',
          order: 2,
          component_type: 'static_table',
          table_style: {
            display: 'flex',
            width: 100,
          },
          rows: {
            ROW1: {
              key: 'ROW1',
              order: 1,
              row_style: {
                flexDirection: 'row',
              },
              cells: {
                CELL1: {
                  key: 'CELL1',
                  order: 1,
                  cell_style: {
                    width: 25,
                  },
                  contents: {
                    ADDRESS_LABEL: {
                      component_type: 'static_text',
                      key: 'ADDRESS_LABEL',
                      order: 1,
                      text_style: {
                        marginTop: 5,
                        fontSize: 10,
                        textAlign: 'left',
                        color: '#444444',
                      },
                      // text: '3331 N. PanAm Expy',
                      text: '98 San Jacinto Blvd',
                    },
                  },
                },
                CELL2: {
                  key: 'CELL2',
                  order: 2,
                  cell_style: {
                    width: 25,
                  },
                  contents: {
                    ADDRESS: {
                      component_type: 'static_text',
                      key: 'ADDRESS',
                      order: 1,
                      text_style: {
                        marginTop: 5,
                        fontSize: 10,
                        textAlign: 'left',
                        color: '#444444',
                      },
                      text: 'Accounts Payable',
                    },
                  },
                },
                CELL3: {
                  key: 'CELL3',
                  order: 3,
                  cell_style: {
                    width: 50,
                  },
                  contents: {},
                },
              },
            },
            ROW2: {
              key: 'ROW2',
              order: 2,
              row_style: {
                flexDirection: 'row',
              },
              cells: {
                CELL1: {
                  key: 'CELL1',
                  order: 1,
                  cell_style: {
                    width: 25,
                  },
                  contents: {
                    ADDRESS_LABEL: {
                      component_type: 'static_text',
                      key: 'ADDRESS_LABEL',
                      order: 1,
                      text_style: {
                        marginTop: 5,
                        fontSize: 10,
                        textAlign: 'left',
                        color: '#444444',
                      },
                      // text: 'San Antonio, TX',
                      text: '4th Floor',
                    },
                  },
                },
                CELL2: {
                  key: 'CELL2',
                  order: 2,
                  cell_style: {
                    width: 25,
                  },
                  contents: {
                    ADDRESS: {
                      component_type: 'static_text',
                      key: 'ADDRESS',
                      order: 1,
                      text_style: {
                        marginTop: 5,
                        fontSize: 10,
                        textAlign: 'left',
                        color: '#444444',
                      },
                      text: 'ap@etwenergy.com',
                    },
                  },
                },
                CELL3: {
                  key: 'CELL3',
                  order: 3,
                  cell_style: {
                    width: 50,
                  },
                  contents: {},
                },
              },
            },
            ROW3: {
              key: 'ROW3',
              order: 3,
              row_style: {
                flexDirection: 'row',
              },
              cells: {
                CELL1: {
                  key: 'CELL1',
                  order: 1,
                  cell_style: {
                    width: 25,
                  },
                  contents: {
                    ADDRESS_LABEL: {
                      component_type: 'static_text',
                      key: 'ADDRESS_LABEL',
                      order: 1,
                      text_style: {
                        marginTop: 5,
                        fontSize: 10,
                        textAlign: 'left',
                        color: '#444444',
                      },
                      // text: '78219',
                      text: 'Austin, TX 78701',
                    },
                  },
                },
                CELL2: {
                  key: 'CELL2',
                  order: 2,
                  cell_style: {
                    width: 25,
                  },
                  contents: {
                    ADDRESS: {
                      component_type: 'static_text',
                      key: 'ADDRESS',
                      order: 1,
                      text_style: {
                        marginTop: 5,
                        fontSize: 10,
                        textAlign: 'left',
                        color: '#444444',
                      },
                      text: '801-864-1503',
                    },
                  },
                },
                CELL3: {
                  key: 'CELL3',
                  order: 3,
                  cell_style: {
                    width: 50,
                  },
                  contents: {},
                },
              },
            },
          },
        },
        BILLED_TO_TABLE: {
          key: 'BILLED_TO_TABLE',
          order: 3,
          component_type: 'static_table',
          table_style: {
            display: 'flex',
            width: 100,
            marginBottom: 32,
          },
          rows: {
            ROW1: {
              key: 'ROW1',
              order: 1,
              row_style: {
                flexDirection: 'row',
              },
              cells: {
                CELL1: {
                  key: 'CELL1',
                  order: 1,
                  cell_style: {
                    width: 100,
                    padding: 2,
                    marginTop: 16,
                  },
                  contents: {
                    BILLED_TO: {
                      component_type: 'static_text',
                      key: 'BILLED_TO',
                      order: 1,
                      text_style: {
                        fontSize: 10,
                        textAlign: 'left',
                        color: '#BBBBBB',
                      },
                      text: 'BILLED TO',
                    },
                  },
                },
              },
            },
            ROW2: {
              key: 'ROW2',
              order: 2,
              row_style: {
                flexDirection: 'row',
              },
              cells: {
                CELL1: {
                  key: 'CELL1',
                  order: 1,
                  cell_style: {
                    width: 100,
                    padding: 2,
                  },
                  contents: {
                    INVOICE_DATE: {
                      component_type: 'variable_text',
                      key: 'INVOICE_DATE',
                      order: 1,
                      text_style: {
                        fontSize: 10,
                        color: '#444444',
                      },
                      variable_mapping_key: 'client_name',
                      fallback_text: ' ',
                      fallback_text_style: {
                        fontSize: 10,
                        color: '#FF0000',
                      },
                    },
                  },
                },
              },
            },
            ROW3: {
              key: 'ROW3',
              order: 3,
              row_style: {
                flexDirection: 'row',
              },
              cells: {
                CELL1: {
                  key: 'CELL1',
                  order: 1,
                  cell_style: {
                    width: 100,
                    padding: 2,
                  },
                  contents: {
                    STREET: {
                      component_type: 'variable_text',
                      key: 'STREET',
                      order: 1,
                      text_style: {
                        fontSize: 10,
                        color: '#444444',
                      },
                      variable_mapping_key: 'location_address',
                      fallback_text: ' ',
                      fallback_text_style: {
                        fontSize: 10,
                        color: '#FF0000',
                      },
                    },
                  },
                },
              },
            },
            ROW4: {
              key: 'ROW4',
              order: 4,
              row_style: {
                flexDirection: 'row',
              },
              cells: {
                CELL1: {
                  key: 'CELL1',
                  order: 1,
                  cell_style: {
                    width: 100,
                    padding: 2,
                  },
                  contents: {
                    CITY: {
                      component_type: 'variable_text',
                      key: 'CITY',
                      order: 1,
                      text_style: {
                        fontSize: 10,
                        color: '#444444',
                        display: 'inline-block',
                      },
                      variable_mapping_key: 'location_city_plus_state',
                      fallback_text: ' ',
                      fallback_text_style: {
                        fontSize: 10,
                        color: '#FF0000',
                      },
                    },
                  },
                },
              },
            },
            ROW5: {
              key: 'ROW5',
              order: 5,
              row_style: {
                flexDirection: 'row',
              },
              cells: {
                CELL1: {
                  key: 'CELL1',
                  order: 1,
                  cell_style: {
                    width: 100,
                    padding: 2,
                  },
                  contents: {
                    ZIP: {
                      component_type: 'variable_text',
                      key: 'ZIP',
                      order: 1,
                      text_style: {
                        fontSize: 10,
                        color: '#444444',
                      },
                      variable_mapping_key: 'location_zip',
                      fallback_text: ' ',
                      fallback_text_style: {
                        fontSize: 10,
                        color: '#FF0000',
                      },
                    },
                  },
                },
              },
            },
          },
        },
        LINE_ITEMS_TABLE: {
          key: 'LINE_ITEMS_TABLE',
          order: 5,
          component_type: 'looped_data_table',
          table_style: {
            display: 'flex',
            width: 100,
          },
          row_style: {
            flexDirection: 'row',
          },
          table_data_variable_mapping_key: 'line_items',
          columns: {
            COL0: {
              key: 'COL0',
              order: 0,
              column_header_style: {
                width: 20,
                backgroundColor: '#929598',
                color: 'white',
                fontSize: 10,
                padding: 8,
                marginBottom: 4,
              },
              column_body_style: {
                width: 20,
                backgroundColor: '#F3F3F3',
                fontSize: 10,
                padding: 8,
                marginBottom: 4,
              },
              header_text: 'PROJECT',
              variable_mapping_key: 'item_job_code',
              fallback_text: ' ',
              fallback_text_style: {
                color: '#FF0000',
              },
            },
            COL1: {
              key: 'COL1',
              order: 1,
              column_header_style: {
                width: 60,
                backgroundColor: '#929598',
                color: 'white',
                fontSize: 10,
                padding: 8,
                marginBottom: 4,
              },
              column_body_style: {
                width: 60,
                backgroundColor: '#F3F3F3',
                fontSize: 10,
                padding: 8,
                marginBottom: 4,
              },
              header_text: 'DESCRIPTION',
              variable_mapping_key: 'item_description',
              fallback_text: ' ',
              fallback_text_style: {
                color: '#FF0000',
              },
            },
            COL4: {
              key: 'COL4',
              order: 4,
              column_header_style: {
                width: 20,
                backgroundColor: '#929598',
                color: 'white',
                fontSize: 10,
                padding: 8,
                marginBottom: 4,
              },
              column_body_style: {
                width: 20,
                backgroundColor: '#F3F3F3',
                fontSize: 10,
                padding: 8,
                marginBottom: 4,
              },
              header_text: 'AMOUNT ($)',
              variable_mapping_key: 'item_total',
              fallback_text: ' ',
              fallback_text_style: {
                color: '#FF0000',
              },
            },
          },
        },

        TOTAL_TABLE: {
          key: 'TOTAL_TABLE',
          order: 6,
          component_type: 'static_table',
          table_style: {
            display: 'flex',
            width: 100,
            marginTop: 10,
          },
          rows: {
            ROW1: {
              key: 'ROW1',
              order: 1,
              row_style: {
                flexDirection: 'row',
              },
              cells: {
                CELL0: {
                  key: 'CELL0',
                  order: 0,
                  cell_style: {
                    width: 80,
                    padding: 2,
                  },
                  contents: {},
                },
                CELL1: {
                  key: 'CELL1',
                  order: 1,
                  cell_style: {
                    width: 20,
                    padding: 2,
                    borderTop: '1px solid #444444',
                    marginTop: 10,
                  },
                  contents: {
                    QUOTE_DATE_LABEL: {
                      component_type: 'static_text',
                      key: 'QUOTE_DATE_LABEL',
                      order: 1,
                      text_style: {
                        paddingTop: 18,
                        fontSize: 10,
                        textAlign: 'right',
                        color: '#BBBBBB',
                      },
                      text: 'QUOTE TOTAL',
                    },
                  },
                },
              },
            },
            ROW2: {
              key: 'ROW2',
              order: 2,
              row_style: {
                flexDirection: 'row',
              },
              cells: {
                CELL0: {
                  key: 'CELL0',
                  order: 0,
                  cell_style: {
                    width: 80,
                    padding: 2,
                  },
                  contents: {},
                },
                CELL1: {
                  key: 'CELL1',
                  order: 1,
                  cell_style: {
                    width: 20,
                    padding: 2,
                  },
                  contents: {
                    LINE_ITEM_TOTAL: {
                      component_type: 'variable_text',
                      key: 'LINE_ITEM_TOTAL',
                      order: 1,
                      text_style: {
                        fontSize: 16,
                        fontWeight: 'bold',
                        textAlign: 'right',
                      },
                      variable_mapping_key: 'line_item_total',
                      fallback_text: ' ',
                      fallback_text_style: {
                        fontSize: 16,
                        color: '#FF0000',
                        textAlign: 'right',
                      },
                    },
                  },
                },
              },
            },
          },
        },
        NOTES_TABLE: {
          component_type: 'static_table',
          key: 'NOTES_TABLE',
          order: 7,
          table_style: {
            display: 'flex',
            width: 100,
          },
          rows: {
            ROW1: {
              key: 'ROW1',
              order: 1,
              row_style: {
                flexDirection: 'row',
              },
              cells: {
                CELL1: {
                  key: 'CELL1',
                  order: 1,
                  cell_style: {
                    width: 100,
                    padding: 2,
                    marginTop: 6,
                  },
                  contents: {
                    INVOICE_DATE_LABEL: {
                      key: 'INVOICE_DATE_LABEL',
                      order: 1,
                      component_type: 'static_text',
                      text_style: {
                        fontSize: 10,
                        textAlign: 'left',
                        // color: '#000000',
                        marginRight: 8,
                        fontWeight: 'bold',
                        color: '#BBBBBB',
                      },
                      text: 'NOTES:',
                    },
                  },
                },
              },
            },
            ROW2: {
              key: 'ROW2',
              order: 2,
              row_style: {
                flexDirection: 'row',
              },
              cells: {
                CELL1: {
                  key: 'CELL1',
                  order: 1,
                  cell_style: {
                    width: 100,
                    padding: 2,
                    marginTop: 6,
                  },
                  contents: {
                    NOTES: {
                      component_type: 'variable_text',
                      key: 'NOTES',
                      order: 1,
                      text_style: {
                        fontSize: 10,
                        fontWeight: 'bold',
                      },
                      variable_mapping_key: 'line_item_notes',
                      fallback_text: ' ',
                      fallback_text_style: {
                        fontSize: 10,
                        color: '#FF0000',
                      },
                    },
                  },
                },
              },
            },
          },
        },
        TERMS_TABLE: {
          key: 'TERMS_TABLE',
          order: 8,
          component_type: 'static_table',
          table_style: {
            display: 'flex',
            width: 100,
            marginTop: 40,
          },
          rows: {
            ROW1: {
              key: 'ROW1',
              order: 1,
              row_style: {
                flexDirection: 'row',
              },
              cells: {
                CELL1: {
                  key: 'CELL1',
                  order: 1,
                  cell_style: {
                    width: 100,
                    padding: 2,
                  },
                  contents: {
                    INVOICE_DATE_LABEL: {
                      component_type: 'static_text',
                      key: 'INVOICE_DATE_LABEL',
                      order: 1,
                      text_style: {
                        fontSize: 10,
                        textAlign: 'left',
                        color: '#444444',
                      },
                      // text: 'NET10',
                      text: 'This is a quote. Invoice charges may vary and are based on actual work required.',
                    },
                  },
                },
              },
            },
            ROW2: {
              key: 'ROW2',
              order: 2,
              row_style: {
                flexDirection: 'row',
              },
              cells: {
                CELL1: {
                  key: 'CELL1',
                  order: 1,
                  cell_style: {
                    width: 100,
                    padding: 2,
                    borderBottom: '1px solid #444444',
                  },
                  contents: {
                    INVOICE_DATE_LABEL: {
                      component_type: 'static_text',
                      key: 'INVOICE_DATE_LABEL',
                      order: 1,
                      text_style: {
                        fontSize: 10,
                        textAlign: 'left',
                        color: '#444444',
                      },
                      text: ' ',
                    },
                  },
                },
              },
            },
          },
        },
      },
    },
  },
}

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Container
///////////////////////////////

export const Container: React.FC = (): JSX.Element => {
  // Props
  // const params = useParams()
  // const itemKey: string = params.id as string

  // Hooks - useContext, useState, useReducer, other
  // { sort-start } - hooks
  const { uc_setUserInterface_FormDialogDisplay } = useContext(Context_UserInterface_FormDialog)

  // const ur_forceRerender = 				useReducer(() => ({}), {})[1] as () => void
  // const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = 		useContext( Context_RootData_ClientKey )

  const un_routerNavigation = useNavigate()
  // { sort-end } - hooks

  // Hooks - useEffect
  useEffect(() => {
    document.title = rLIB('Invoicing', false) as string
  }, [])

  // Functions
  const onChange = async (data: TsInterface_UnspecifiedObject) => {
    return new Promise((resolve, reject) => {
      resolve({ success: true })
    })
  }

  function serialToDate(serial: number) {
    // Base date in JavaScript (January 1, 1900)
    let baseDate = new Date(1900, 0, 1)

    // Adjust for the fact that Excel treats 1900 as a leap year incorrectly
    let daysToAdd = serial - 1 // Excel serial number 1 corresponds to Jan 1, 1900

    // Create a new date by adding the serial number as days to the base date
    baseDate.setDate(baseDate.getDate() + daysToAdd)

    return baseDate
  }

  const downloadInvoiceZip = (spreadsheetData: TsInterface_UnspecifiedObject) => {
    return new Promise((resolve, reject) => {
      const zip = new JSZip()
      // Group Rows by Invoice Number
      let groupedInvoices: TsInterface_UnspecifiedObject = {}
      for (let loopRowIndex in spreadsheetData) {
        let loopRow = spreadsheetData[loopRowIndex]
        let loopKey = loopRow.invoice_number
        if (loopRow != null && loopRow['download_type'] != null && loopRow['download_type'].toLowerCase().indexOf('quote') > -1) {
          loopKey = loopRow.invoice_number + '_quote'
        } else if (loopRow != null && loopRow['download_type'] != null && loopRow['download_type'].toLowerCase().indexOf('detail') > -1) {
          loopKey = loopRow.invoice_number + '_detailed'
        } else {
          loopKey = loopRow.invoice_number + '_invoice'
        }
        if (groupedInvoices[loopKey] == null) {
          groupedInvoices[loopKey] = []
        }
        groupedInvoices[loopKey].push(loopRow)
      }

      console.log(groupedInvoices)

      // Generate Formatted Data with combined line items
      let formattedDataList: TsInterface_UnspecifiedObject[] = []
      for (let invoiceNumber in groupedInvoices) {
        let invoiceData = groupedInvoices[invoiceNumber]
        let formattedData: TsInterface_UnspecifiedObject = {
          download_type: invoiceData[0].download_type,
          invoice_number: invoiceData[0].invoice_number,
          permitting_fees: null,
          // permitting_fees: invoiceData[0].permitting_fees,
          invoice_date: returnFormattedDate(serialToDate(invoiceData[0].invoice_date), 'MM/DD/YYYY'),
          client_name: invoiceData[0].client_name,
          location_address: invoiceData[0].location_address,
          location_city: invoiceData[0].location_city,
          location_state: invoiceData[0].location_state,
          location_zip: invoiceData[0].location_zip,
          location_city_plus_state: invoiceData[0].location_city + ', ' + invoiceData[0].location_state,
          job_code: invoiceData[0].job_code,
          line_items: [],
          line_item_total: 0,
          line_item_notes: '',
        }

        console.log(formattedData)

        for (let lineItem of invoiceData) {
          // Add first permitting fee found
          if (
            lineItem.permitting_fees != null &&
            !isNaN(parseFloat(lineItem.permitting_fees)) &&
            lineItem.permitting_fees > 0 &&
            formattedData.permitting_fees == null
          ) {
            formattedData.permitting_fees = formatCurrency(parseFloat(lineItem.permitting_fees))
            formattedData.line_item_total += parseFloat(lineItem.permitting_fees)
          }
          formattedData.line_items.push({
            item_job_code: lineItem.job_code,
            item_description: lineItem.description,
            item_total: formatCurrency(lineItem.amount),
            // NEW
            item_rate: formatCurrency(lineItem.rate),
            item_adders: formatCurrency(lineItem.adders),
          })
          if (!isNaN(parseFloat(lineItem.amount))) {
            formattedData.line_item_total += parseFloat(lineItem.amount)
          }
          if (lineItem.notes) {
            formattedData.line_item_notes += lineItem.notes + '\n'
          }
        }
        formattedData.line_item_total = formatCurrency(formattedData.line_item_total)
        formattedDataList.push(formattedData)
      }
      // Download Zip

      console.log('<><>')
      console.log(formattedDataList)

      let promiseArray: Promise<any>[] = []
      for (let i = 0; i < formattedDataList.length; i++) {
        let invoiceNumber = formattedDataList[i].invoice_number
        let downloadType = formattedDataList[i].download_type
        let template = invoicePdfTemplate
        if (downloadType != null && downloadType.toLowerCase().indexOf('quote') > -1) {
          template = quotePdfTemplate
        } else if (downloadType != null && downloadType.toLowerCase().indexOf('detail') > -1) {
          template = detailedInvoicePdfTemplate
        }
        // template = detailedInvoicePdfTemplate // TESTING
        let dataForDownload = []
        dataForDownload.push(formattedDataList[i])
        let pdfJSX = generatePdfFromData(template, formattedDataList[i], 'download', uc_setUserInterface_FormDialogDisplay, onChange, {})
        // downloadPdfFromData({ name: 'test' + i + '.pdf' }, pdfContent, dataForDownload)
        const pdfBlob = pdf(pdfJSX).toBlob()
        zip.file(invoiceNumber + `.pdf`, pdfBlob)
      }
      Promise.all(promiseArray).finally(() => {
        zip
          .generateAsync({ type: 'blob' })
          .then((blob) => {
            saveAs(blob, 'invoices.zip')
            resolve({ success: true })
          })
          .catch((error) => {
            console.error(error)
            resolve({ success: false })
          })
      })
    })
  }

  // JSX Generation
  const rJSX_SettingsButton = (): JSX.Element => {
    let buttonJSX = (
      <Button
        color="inherit"
        variant="outlined"
        onClick={(event) => {
          onClickAppNavigation(event, un_routerNavigation, ApplicationPages.AdminInvoiceSettingsPage.url())
        }}
        disableElevation
        className="tw-mr-2 tw-align-top"
        sx={{ height: '36px' }}
      >
        <Icon
          icon="gear"
          sx={{ fontSize: '18px' }}
        />
      </Button>
    )
    return buttonJSX
  }

  const rJSX_Pdf2Content = (): JSX.Element => {
    let tabJSX = (
      <>
        {LayeredPdfEditor({
          pdfUrl:
            'https://firebasestorage.googleapis.com/v0/b/etw-energy.appspot.com/o/temp%2Fpdf%20test.pdf?alt=media&token=b7a9ea87-166d-466d-a65f-40a1337623d1',
          initialAnnotations: [
            {
              x: 100,
              y: 400,
              text: 'Test Annotation 1',
              page: 1,
            },
            {
              x: 170,
              y: 142,
              text: 'Test Annotation 2a',
              page: 2,
            },
            {
              x: 10,
              y: 10,
              text: 'Test Annotation 2b',
              page: 2,
            },
          ],
        })}
      </>
    )

    return tabJSX
  }

  const rJSX_Pdf2Tab = (): JSX.Element => {
    let tabJSX = <>{rJSX_Pdf2Content()}</>

    return tabJSX
  }

  const rJSX_BulkInvoicesTab = (): JSX.Element => {
    let tabJSX = <></>
    tabJSX = (
      <Card className="tw-text-center tw-p-4">
        <Typography>{rLIB('Use the import button above to upload a CSV with your invoices.')}</Typography>
      </Card>
    )
    return tabJSX
  }

  const rJSX_ImportInvoicesButton = (shrink: boolean): JSX.Element => {
    let buttonJSX = (
      <BasicImportButtonAndDialog
        importAdditionalData={{}}
        importButtonColor={'info'}
        importButtonShrink={shrink}
        importButtonText={rLIB('Import Invoices')}
        importDialogHeader={rLIB('Confirm Invoices Import')}
        importMappingOptions={{
          invoice_number: {
            key: 'invoice_number',
            required: false,
            label: rLIB('Invoice Number'),
            automatch_properties: ['Invoice Number'],
          },
          invoice_date: {
            key: 'invoice_date',
            required: false,
            label: rLIB('Invoice Date'),
            automatch_properties: ['Invoice Date'],
          },
          client_name: {
            key: 'client_name',
            required: false,
            label: rLIB('Client Name'),
            automatch_properties: ['Client Name'],
          },
          location_address: {
            key: 'location_address',
            required: false,
            label: rLIB('Street'),
            automatch_properties: ['Street'],
          },
          location_city: {
            key: 'location_city',
            required: false,
            label: rLIB('City'),
            automatch_properties: ['City'],
          },
          location_state: {
            key: 'location_state',
            required: false,
            label: rLIB('State'),
            automatch_properties: ['State'],
          },
          location_zip: {
            key: 'location_zip',
            required: false,
            label: rLIB('Zip Code'),
            automatch_properties: ['ZIP Code'],
          },
          job_code: {
            key: 'job_code',
            required: false,
            label: rLIB('Project'),
            automatch_properties: ['Project'],
          },
          description: {
            key: 'description',
            required: false,
            label: rLIB('Description'),
            automatch_properties: ['Description'],
          },
          rate: {
            key: 'rate',
            required: false,
            label: rLIB('Rate'),
            automatch_properties: ['Rate'],
          },
          adders: {
            key: 'adders',
            required: false,
            label: rLIB('Adders'),
            automatch_properties: ['Adders'],
          },
          amount: {
            key: 'amount',
            required: false,
            label: rLIB('Amount'),
            automatch_properties: ['Amount'],
          },
          permitting_fees: {
            key: 'permitting_fees',
            required: false,
            label: rLIB('Permitting Fees'),
            automatch_properties: ['Permitting Fees'],
          },
          notes: {
            key: 'notes',
            required: false,
            label: rLIB('Notes'),
            automatch_properties: ['Notes'],
          },
          download_type: {
            key: 'download_type',
            required: false,
            label: rLIB('Download Type'),
            automatch_properties: ['Download Type'],
          },
        }}
        importSubmission={(spreadsheetData, importAdditionalData, importHooks) => {
          return new Promise((resolve, reject) => {
            downloadInvoiceZip(spreadsheetData)
              .then((res) => {
                resolve({ success: true })
              })
              .catch((err) => {
                reject({ success: false })
              })
          })
        }}
      />
    )
    return buttonJSX
  }

  const rJSX_Page = (): JSX.Element => {
    let pageJSX = (
      <AuthenticatedContainer
        pageHeader={rLIB('Invoicing')}
        pageKey={pageKey}
        content={
          <Box>
            <TabsUrl
              tabsSettings={{
                baseUrl: ApplicationPages.AdminInvoiceIndexPage.url(),
                tabQueryParam: 'tab',
                overridePageTitle: true,
                basePageTitle: rLIB('PDF', false) as string,
              }}
              tabs={[
                {
                  tabUrlKey: 'Bulk_Invoices',
                  tabOnChange: () => {},
                  tabHeader: rLIB('Bulk Invoice and Quotes'),
                  tabContent: <Box>{rJSX_BulkInvoicesTab()}</Box>,
                  tabButtons: [
                    { fullJSX: rJSX_SettingsButton(), minJSX: rJSX_SettingsButton(), sizeCutoff: 0 },
                    { fullJSX: rJSX_ImportInvoicesButton(false), minJSX: rJSX_ImportInvoicesButton(true), sizeCutoff: 0 },
                  ],
                },
                // {
                //   tabUrlKey: 'PDF',
                //   tabOnChange: () => {},
                //   tabHeader: rLIB('PDF'),
                //   tabContent: <Box>{rJSX_PdfTab()}</Box>,
                //   tabButtons: [{ fullJSX: rJSX_SettingsButton(), minJSX: rJSX_SettingsButton(), sizeCutoff: 0 }],
                // },
                // {
                //   tabUrlKey: 'PDF2',
                //   tabOnChange: () => {},
                //   tabHeader: 'PDF2',
                //   tabContent: <Box>{rJSX_Pdf2Tab()}</Box>,
                //   tabButtons: [{ fullJSX: rJSX_SettingsButton(), minJSX: rJSX_SettingsButton(), sizeCutoff: 0 }],
                // },
              ]}
            />
          </Box>
        }
      />
    )
    return pageJSX
  }

  // Render
  return <>{rJSX_Page()}</>
}
