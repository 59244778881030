///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			containers are pages / views used in the app and are made up of components and can interact with services and models

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Box, Button, Card } from '@mui/material/'
import { useContext, useEffect, useReducer } from 'react'
import { Trans } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import { AuthenticatedContainer } from 'rfbp_aux/containers/authenticated_container'
import { ApplicationPages } from 'rfbp_aux/data/application_structure'
import {
  DatabaseRef_Utilities_Collection,
  DatabaseRef_Utilities_Search,
  DatabaseRef_Utility_Document,
} from 'rfbp_aux/services/database_endpoints/directory/utilities'
import { TsInterface_FormInputs } from 'rfbp_core/components/form'
import {
  TsInterface_FormAdditionalData,
  TsInterface_FormData,
  TsInterface_FormHooksObject,
  TsInterface_FormSettings,
  TsInterface_FormSubmittedData,
} from 'rfbp_core/components/form/form_types'
import { Icon } from 'rfbp_core/components/icons'
import {
  TableCellBasic,
  TableCellManage,
  TableDatabase,
  TsInterface_TableAdditionalData,
  TsInterface_TableColumns,
  TsInterface_TableDatabaseEndpointQueryObject,
  TsInterface_TableDatabaseSettings,
  TsInterface_TableDataRow,
  TsInterface_TableHooks,
} from 'rfbp_core/components/table'
import { Context_RootData_ClientKey, Context_UserInterface_ErrorDialog, Context_UserInterface_FormDialog } from 'rfbp_core/services/context'
import {
  DatabaseSetMergeDocument,
  DatabaseStagedBatchUpdate,
  generateDatabaseQuery,
  TsInterface_DatabaseBatchUpdatesArray,
  TsInterface_OrderByArray,
  TsInterface_QueryCursorsObject,
  TsInterface_QueryOperatorsArray,
} from 'rfbp_core/services/database_management'
import { getProp, keyFromString } from 'rfbp_core/services/helper_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'

import { BasicImportButtonAndDialog } from 'rfbp_core/components/imports/basic_import_button_and_dialog'
import { rLIB } from 'rfbp_core/localization/library'
import { directAppNavigation, onClickAppNavigation } from 'rfbp_core/services/navigation/navigation_functions'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

// Authenticated Nav Data
const pageKey: string = ApplicationPages['AdminDatabaseUtilitiesListPage']['key']

// Displayed Translatable Strings
// { sort-start } - displayed text - scoped sort plugin
const s_CREATE_UTILITY: JSX.Element = <Trans>Create Utility</Trans>
const s_NEW_UTILITY: JSX.Element = <Trans>New Utility</Trans>
const s_NO_UTILITIES_FOUND_BY_SEARCH: JSX.Element = <Trans>No utilities found by search</Trans>
const s_PHONE: JSX.Element = <Trans>Phone</Trans>
const s_UTILITIES: JSX.Element = <Trans>Utilities</Trans>
const s_UTILITY_NAME: JSX.Element = <Trans>Utility Name</Trans>
const s_VIEW: JSX.Element = <Trans>View</Trans>
const se_UTILITIES: string = 'Utilities'
// { sort-end } - displayed text

// Forms
const formInputs_UtilityName: TsInterface_FormInputs = {
  name: {
    data_type: 'string',
    input_type: 'text_basic',
    key: 'name',
    label: s_UTILITY_NAME,
    required: true,
  },
}

// Table
const tableColumns_Utilities: TsInterface_TableColumns = {
  manage: TableCellManage({
    view: {
      icon: (
        <Icon
          type="solid"
          icon="magnifying-glass"
        />
      ),
      label: <>{s_VIEW}</>,
      onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        if (rowData.key != null) {
          directAppNavigation(tableHooks.un_routerNavigation, ApplicationPages.AdminDatabaseUtilityViewPage.url(rowData.key as string))
        }
      },
    },
    // New delete functionality
    delete: {
      icon: (
        <Icon
          type="solid"
          icon="trash-can"
        />
      ),
      label: <Trans>Delete</Trans>,
      onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        tableHooks.uc_setUserInterface_PromptDialogDisplay({
          display: true,
          prompt: {
            color: 'error',
            confirm_text: rLIB('Delete') as JSX.Element,
            header: rLIB('Delete Utility'),
            text: rLIB('Are you sure you want to delete this utility?'),
            submit_callback: (promptValue: string) => {
              return new Promise((resolve, reject) => {
                let designPartnerKey = keyFromString(rowData.name as string)
                DatabaseSetMergeDocument(DatabaseRef_Utility_Document(tableHooks.uc_RootData_ClientKey, designPartnerKey), { status: 'deleted' })
                  .then(() => {
                    resolve({ success: true })
                  })
                  .catch((error) => {
                    tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error })
                    reject(error)
                  })
              })
            },
          },
        })
      },
    },
  }),
  // name: TableCellBasic( "name", s_UTILITY_NAME, "name" ),
  name: {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return s_UTILITY_NAME
      },
      header_sort_by: 'name',
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = (
          <>
            <Box
              className="tw-cursor-pointer tw-rounded-md tw-p-1 tw-text-center tw-inline-block"
              sx={{ 'background': themeVariables.background_default, '&:hover': { background: themeVariables.background_json } }}
              onClick={(event) => {
                if (rowData.key != null) {
                  // TODO - handle other user types probably
                  onClickAppNavigation(event, tableHooks.un_routerNavigation, ApplicationPages.AdminDatabaseUtilityViewPage.url(rowData.key as string))
                }
              }}
            >
              {rowData.name}
            </Box>
          </>
        )
        return cellJSX
      },
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        let cellCSS = ''
        return cellCSS
      },
    },
  },
  phone: TableCellBasic('phone', s_PHONE, 'phone'),
  email: TableCellBasic('email', rLIB('Email'), 'email'),
}

const tableSettings_Utilities: TsInterface_TableDatabaseSettings = {
  rows_per_page: 100,
  show_header: true,
  size: 'small',
  sort_direction: 'asc',
  sort_property: 'name',
  use_live_data: false,
  conditional_row_styles: [
    {
      className: 'tw-opacity-30 tw-line-through',
      conditional_display: {
        active: true,
        logic_type: 'comparison',
        source: 'rowData',
        prop: 'status',
        comparator: '==',
        value: 'deleted',
        conditions: [],
      },
    },
  ],
  searchable: true,
  search_settings_database: {
    search_type: 'firebase',
    search_no_data_message: s_NO_UTILITIES_FOUND_BY_SEARCH,
    search_property_options: [{ key: 'name', value: s_UTILITY_NAME }],
  },
}

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Container
///////////////////////////////

export const Container: React.FC = (): JSX.Element => {
  // Props
  // const params = useParams()
  // const itemKey: string = params.id as string

  // Hooks - useContext, useState, useReducer, other
  const un_routerNavigation = useNavigate()
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)
  const { uc_setUserInterface_ErrorDialogDisplay } = useContext(Context_UserInterface_ErrorDialog)
  const { uc_setUserInterface_FormDialogDisplay } = useContext(Context_UserInterface_FormDialog)

  // Hooks - useEffect
  useEffect(() => {
    document.title = se_UTILITIES
  }, [])

  useEffect(() => {
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
    return () => {}
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

  // Functions
  const tableDatabaseEndpoint_Utilities = (
    queryGenerationData: TsInterface_TableDatabaseEndpointQueryObject,
    tableAdditionalData: TsInterface_TableAdditionalData,
  ) => {
    let queryOperatorsArray: TsInterface_QueryOperatorsArray = [{ prop: 'status', comparator: '==', value: 'active' }]
    let orderByArray: TsInterface_OrderByArray = [{ prop: 'name', desc: false }]
    let queryCursorsObject: TsInterface_QueryCursorsObject = {}
    if (queryGenerationData['startAfter'] != null) {
      queryCursorsObject['startAfter'] = queryGenerationData.startAfter
    }
    if (queryGenerationData['startAt'] != null) {
      queryCursorsObject['startAt'] = queryGenerationData.startAt
    }
    if (queryGenerationData['endAt'] != null) {
      queryCursorsObject['endAt'] = queryGenerationData.endAt
    }
    if (queryGenerationData['endBefore'] != null) {
      queryCursorsObject['endBefore'] = queryGenerationData.endBefore
    }
    let limit = getProp(queryGenerationData, 'limit', 100)
    return generateDatabaseQuery(
      DatabaseRef_Utilities_Collection(uc_RootData_ClientKey as string),
      queryOperatorsArray,
      orderByArray,
      queryCursorsObject,
      limit,
    )
  }

  const createUtility = (): void => {
    uc_setUserInterface_FormDialogDisplay({
      display: true,
      form: {
        form: {
          formAdditionalData: {},
          formData: {},
          formInputs: formInputs_UtilityName,
          formOnChange: (
            formAdditionalData: TsInterface_FormAdditionalData,
            formData: TsInterface_FormData,
            formInputs: TsInterface_FormInputs,
            formSettings: TsInterface_FormSettings,
          ) => {},
          formSettings: {},
          formSubmission: (
            formSubmittedData: TsInterface_FormSubmittedData,
            formAdditionalData: TsInterface_FormAdditionalData,
            formHooks: TsInterface_FormHooksObject,
          ) => {
            return new Promise((resolve, reject) => {
              getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                .then((res_GCK) => {
                  formSubmittedData.status = 'active'
                  const utilityKey = keyFromString(formSubmittedData.name)

                  formSubmittedData.key = utilityKey
                  DatabaseSetMergeDocument(DatabaseRef_Utility_Document(res_GCK.clientKey, utilityKey), formSubmittedData)
                    .then((res_DSMD) => {
                      resolve({ success: true })
                      directAppNavigation(un_routerNavigation, ApplicationPages.AdminDatabaseUtilityViewPage.url(utilityKey))
                    })
                    .catch((rej_DSMD) => {
                      uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                    })
                })
                .catch((rej_GCK) => {
                  uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                })
            })
          },
        },
        dialog: {
          formDialogHeaderColor: 'success',
          formDialogHeaderText: <>{s_CREATE_UTILITY}</>,
          formDialogIcon: (
            <Icon
              type="solid"
              icon="pen-to-square"
            />
          ),
        },
      },
    })
  }

  const tableSearchEndpoint_Utilities = (searchProperty: string, searchInput: string) => {
    let limit = tableSettings_Utilities.rows_per_page
    if (limit === null) {
      limit = 20
    }
    return DatabaseRef_Utilities_Search(uc_RootData_ClientKey as string, searchProperty, searchInput, limit)
  }

  // JSX Generation
  const rJSX_NewUtilityButton = (): JSX.Element => {
    let buttonJSX = <></>
    buttonJSX = (
      <Button
        color="success"
        variant="contained"
        onClick={() => {
          createUtility()
        }}
        disableElevation
        startIcon={
          <Icon
            icon="circle-plus"
            type="solid"
          />
        }
        sx={{ marginRight: 2 }}
      >
        {s_NEW_UTILITY}
      </Button>
    )
    return buttonJSX
  }

  const rJSX_ImportUtilitiesButton = (shrink: boolean): JSX.Element => {
    let buttonJSX = (
      <BasicImportButtonAndDialog
        importAdditionalData={{}}
        importButtonColor={'info'}
        importButtonShrink={shrink}
        importButtonText={rLIB('Import Utilities')}
        importDialogHeader={rLIB('Import Utilities')}
        importMappingOptions={{
          name: { key: 'name', required: true, label: rLIB('Name'), automatch_properties: ['Name'] },
        }}
        importSubmission={(spreadsheetData, importAdditionalData, importHooks) => {
          return new Promise((resolve, reject) => {
            try {
              getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                .then((res_GCK) => {
                  // Create Update Array
                  let updateArray: TsInterface_DatabaseBatchUpdatesArray = []
                  for (let loopRowKey in spreadsheetData) {
                    updateArray.push({
                      type: 'setMerge',
                      ref: DatabaseRef_Utility_Document(res_GCK.clientKey as string, keyFromString(spreadsheetData[loopRowKey].name)),
                      data: { name: spreadsheetData[loopRowKey].name, status: 'active', key: keyFromString(spreadsheetData[loopRowKey].name) },
                    })
                  }
                  // Save to Database
                  DatabaseStagedBatchUpdate(updateArray)
                    .then((res_DSBU) => {
                      resolve(res_DSBU)
                    })
                    .catch((rej_DSBU) => {
                      console.error('Batch Update Error:', rej_DSBU)
                      uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSBU.error })
                      reject(rej_DSBU)
                    })
                })
                .catch((rej_GCK) => {
                  console.error('Client Key Error:', rej_GCK)
                  uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                  reject(rej_GCK)
                })
            } catch (error) {
              console.error('Error during import submission:', error)
              reject(error)
            }
          })
        }}
      />
    )
    return buttonJSX
  }

  const rJSX_UtilityTable = (): JSX.Element => {
    let tableJSX = <></>
    if (uc_RootData_ClientKey != null) {
      if (tableSettings_Utilities.search_settings_database == null) {
        tableSettings_Utilities.search_settings_database = { search_type: 'firebase' }
      }
      tableSettings_Utilities.search_settings_database.search_endpoint = tableSearchEndpoint_Utilities
      tableJSX = (
        <Box>
          <Card className="">
            <TableDatabase
              tableAdditionalData={{}}
              tableColumns={tableColumns_Utilities}
              tableDatabaseEndpoint={tableDatabaseEndpoint_Utilities}
              tableSettings={tableSettings_Utilities}
            />
          </Card>
        </Box>
      )
    } else {
      tableJSX = <></>
    }
    return tableJSX
  }

  const rJSX_Page = (): JSX.Element => {
    let pageJSX = (
      <AuthenticatedContainer
        pageHeader={s_UTILITIES}
        pageKey={pageKey}
        content={
          <Box>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Box>{[rJSX_NewUtilityButton(), rJSX_ImportUtilitiesButton(true)]}</Box>
            </Box>
            <Box className="tw-mt-2">{rJSX_UtilityTable()}</Box>
          </Box>
        }
      />
    )
    return pageJSX
  }

  // Render
  return <>{rJSX_Page()}</>
}
