///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			containers are pages / views used in the app and are made up of components and can interact with services and models

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Box, Button, Card, Switch, Tooltip, Typography } from '@mui/material/'
import { alertSeverityIconOptions } from 'app/data/general_form_options'
import React, { useContext, useEffect, useReducer } from 'react'
import { useNavigate } from 'react-router-dom'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import { AuthenticatedContainer } from 'rfbp_aux/containers/authenticated_container'
import { ApplicationPages } from 'rfbp_aux/data/application_structure'
import { DatabaseRef_SalesPartner_Collection } from 'rfbp_aux/services/database_endpoints/directory/sales_partners'
import {
  DatabaseRef_TaskWorkflows_Collection,
  DatabaseRef_TaskWorkflows_Search,
  DatabaseRef_TaskWorkflow_Document,
} from 'rfbp_aux/services/database_endpoints/directory/task_workflows'
import {
  TsInterface_FormAdditionalData,
  TsInterface_FormData,
  TsInterface_FormHooksObject,
  TsInterface_FormInputs,
  TsInterface_FormSettings,
  TsInterface_FormSubmittedData,
} from 'rfbp_core/components/form'
import { Icon } from 'rfbp_core/components/icons'
import {
  TableBasic,
  TableCellBasic,
  TableCellManage,
  TableDatabase,
  TsInterface_TableAdditionalData,
  TsInterface_TableColumns,
  TsInterface_TableDatabaseEndpointQueryObject,
  TsInterface_TableDatabaseSettings,
  TsInterface_TableDataRow,
  TsInterface_TableHooks,
} from 'rfbp_core/components/table'
import { TabsUrl } from 'rfbp_core/components/tabs'
import { rLIB } from 'rfbp_core/localization/library'
import {
  Context_RootData_ClientKey,
  Context_UserInterface_ErrorDialog,
  Context_UserInterface_PromptDialog,
  TsInterface_PromptDialogObject,
} from 'rfbp_core/services/context'
import {
  DatabaseAddDocument,
  DatabaseGetCollection,
  DatabaseSetMergeDocument,
  generateDatabaseQuery,
  TsInterface_OrderByArray,
  TsInterface_QueryCursorsObject,
  TsInterface_QueryOperatorsArray,
} from 'rfbp_core/services/database_management'
import { getProp, objectToArray } from 'rfbp_core/services/helper_functions'
import { directAppNavigation, onClickAppNavigation } from 'rfbp_core/services/navigation/navigation_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject } from 'rfbp_core/typescript/global_types'
import { taskOutcomes } from './data/hardcoded_task_outcomes'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

// Authenticated Nav Data
const pageKey: string = ApplicationPages['AdminDatabaseTaskWorkflowListPage']['key']

// Table
const tableSettings_TaskWorkflows: TsInterface_TableDatabaseSettings = {
  rows_per_page: 100,
  show_header: true,
  size: 'small',
  sort_direction: 'asc',
  sort_property: 'name',
  use_live_data: true,
  collapsible_columns: true,
  conditional_row_styles: [
    {
      className: 'tw-opacity-30 tw-line-through',
      conditional_display: {
        active: true,
        logic_type: 'comparison',
        source: 'rowData',
        prop: 'status',
        comparator: '==',
        value: 'deleted',
        conditions: [],
      },
    },
  ],
  searchable: true,
  search_settings_database: {
    search_type: 'firebase',
    search_no_data_message: rLIB('No task workflows found by search') as JSX.Element,
    search_property_options: [{ key: 'name', value: rLIB('Workflow Name') }],
  },
}

const tableColumns_TaskWorkflows: TsInterface_TableColumns = {
  manage: TableCellManage({
    view: {
      icon: (
        <Icon
          type="solid"
          icon="magnifying-glass"
        />
      ),
      label: <>{rLIB('View')}</>,
      onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        if (rowData.key != null) {
          directAppNavigation(tableHooks.un_routerNavigation, ApplicationPages.AdminDatabaseTaskWorkflowViewPage.url(rowData.key as string))
        }
      },
    },
    rename: {
      icon: (
        <Icon
          type="solid"
          icon="pen-to-square"
        />
      ),
      label: <>{rLIB('Rename')}</>,
      onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        if (rowData.key != null) {
          tableHooks.uc_setUserInterface_PromptDialogDisplay({
            display: true,
            prompt: {
              color: 'info',
              confirm_text: rLIB('Rename'),
              default_value: rowData.name,
              header: rLIB('Rename Workflow'),
              icon: (
                <Icon
                  icon="pen-to-square"
                  type="solid"
                />
              ),
              input_label: rLIB('Workflow Name'),
              input_type: 'text',
              text: rLIB('Enter a name for this workflow'),
              submit_callback: (promptValue: string) => {
                return new Promise((resolve, reject) => {
                  let updateObject = {
                    name: promptValue,
                  }
                  getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                    .then((res_GCK) => {
                      DatabaseSetMergeDocument(DatabaseRef_TaskWorkflow_Document(res_GCK.clientKey, rowData.key as string), updateObject)
                        .then((res_DSMD) => {
                          resolve({ success: true })
                        })
                        .catch((rej_DSMD) => {
                          tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                        })
                    })
                    .catch((rej_GCK) => {
                      tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                    })
                })
              },
            },
          })
        }
      },
    },
    delete: {
      icon: (
        <Icon
          icon="trash"
          type="solid"
        />
      ),
      label: <>{rLIB('Delete')}</>,
      conditional_display: {
        active: true,
        logic_type: 'comparison',
        source: 'rowData',
        prop: 'status',
        comparator: '!=',
        value: 'deleted',
        conditions: [],
      },
      onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        if (rowData != null && rowData.key != null && tableHooks != null && tableHooks.uc_RootData_ClientKey != null) {
          tableHooks.uc_setUserInterface_ConfirmDialogDisplay({
            display: true,
            confirm: {
              color: 'error',
              header: rLIB('Delete Workflow'),
              icon: (
                <Icon
                  icon="trash"
                  type="solid"
                />
              ),
              submit_text: rLIB('Delete'),
              text: rLIB('Are you sure that you want to delete this workflow?'),
              submit_callback: () => {
                return new Promise((resolve, reject) => {
                  let updateObject = {
                    status: 'deleted',
                  }
                  DatabaseSetMergeDocument(DatabaseRef_TaskWorkflow_Document(tableHooks.uc_RootData_ClientKey, rowData.key as string), updateObject)
                    .then((res_DSMD) => {
                      // TODO - Log
                      resolve({ success: true })
                    })
                    .catch((rej_DSMD) => {
                      tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                      reject(rej_DSMD)
                    })
                })
              },
            },
          })
        }
      },
    },
    undelete: {
      icon: (
        <Icon
          type="solid"
          icon="trash-undo"
        />
      ),
      label: <>{rLIB('Undelete')}</>,
      conditional_display: {
        active: true,
        logic_type: 'comparison',
        source: 'rowData',
        prop: 'status',
        comparator: '==',
        value: 'deleted',
        conditions: [],
      },
      onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        if (rowData.key != null) {
          tableHooks.uc_setUserInterface_ConfirmDialogDisplay({
            display: true,
            confirm: {
              color: 'info',
              header: rLIB('Undelete Workflow'),
              icon: (
                <Icon
                  icon="trash-undo"
                  type="solid"
                />
              ),
              submit_text: rLIB('Undelete'),
              text: rLIB('Are you sure that you want to undelete this workflow?'),
              submit_callback: () => {
                return new Promise((resolve, reject) => {
                  getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                    .then((res_GCK) => {
                      let updateObject = {
                        status: 'active',
                      }
                      DatabaseSetMergeDocument(DatabaseRef_TaskWorkflow_Document(res_GCK.clientKey, rowData.key as string), updateObject)
                        .then((res_DSMD) => {
                          resolve({ success: true })
                        })
                        .catch((rej_DSMD) => {
                          tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                        })
                    })
                    .catch((rej_GCK) => {
                      tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                    })
                })
              },
            },
          })
        }
      },
    },
  }),
  ui_icon: {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return <></>
      },
      header_sort_by: '',
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let iconJSX = <></>
        if (
          rowData != null &&
          rowData.ui_icon != null &&
          alertSeverityIconOptions != null &&
          alertSeverityIconOptions[rowData.ui_icon as string] != null &&
          alertSeverityIconOptions[rowData.ui_icon as string]['value'] != null
        ) {
          iconJSX = alertSeverityIconOptions[rowData.ui_icon as string]['value']
        } else {
          iconJSX = alertSeverityIconOptions['none']['value']
        }
        let cellJSX = (
          <Box
            onClick={() => {
              tableHooks.uc_setUserInterface_FormDialogDisplay({
                display: true,
                form: {
                  form: {
                    formAdditionalData: {},
                    formData: rowData,
                    formInputs: {
                      ui_icon: {
                        data_type: 'string',
                        input_type: 'multiple_choice_radio',
                        key: 'ui_icon',
                        label: rLIB('Icon'),
                        required: false,
                        options: objectToArray(alertSeverityIconOptions),
                      },
                    },
                    formOnChange: (
                      formAdditionalData: TsInterface_FormAdditionalData,
                      formData: TsInterface_FormData,
                      formInputs: TsInterface_FormInputs,
                      formSettings: TsInterface_FormSettings,
                    ) => {},
                    formSettings: {},
                    formSubmission: (
                      formSubmittedData: TsInterface_FormSubmittedData,
                      formAdditionalData: TsInterface_FormAdditionalData,
                      formHooks: TsInterface_FormHooksObject,
                    ) => {
                      return new Promise((resolve, reject) => {
                        getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                          .then((res_GCK) => {
                            let updateObject = {
                              ui_icon: getProp(formSubmittedData, 'ui_icon', null),
                            }
                            DatabaseSetMergeDocument(DatabaseRef_TaskWorkflow_Document(res_GCK.clientKey, rowData.key as string), updateObject)
                              .then((res_DSMD) => {
                                resolve(res_DSMD)
                              })
                              .catch((rej_DSMD) => {
                                reject(rej_DSMD)
                              })
                          })
                          .catch((rej_GCK) => {
                            formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                          })
                      })
                    },
                  },
                  dialog: {
                    formDialogHeaderColor: 'success',
                    formDialogHeaderText: (
                      <>
                        {rLIB('Edit Icon')} - {rowData.name}
                      </>
                    ),
                    formDialogIcon: (
                      <Icon
                        type="solid"
                        icon="pen-to-square"
                      />
                    ),
                  },
                },
              })
            }}
          >
            {iconJSX}
          </Box>
        )

        return cellJSX
      },
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        let cellCSS = 'tw-w-5'
        return cellCSS
      },
    },
  },
  name: {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rLIB('Workflow Name')
      },
      header_sort_by: 'name',
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = (
          <>
            <Box
              className="tw-cursor-pointer tw-rounded-md tw-p-1 tw-text-center tw-inline-block"
              sx={{ 'background': themeVariables.background_default, '&:hover': { background: themeVariables.background_json } }}
              onClick={(event) => {
                if (rowData.key != null) {
                  // TODO - handle other user types probably
                  onClickAppNavigation(event, tableHooks.un_routerNavigation, ApplicationPages.AdminDatabaseTaskWorkflowViewPage.url(rowData.key as string))
                }
              }}
            >
              {rowData.name}
            </Box>
          </>
        )
        return cellJSX
      },
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        let cellCSS = ''
        return cellCSS
      },
    },
  },
  sales_partner: {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rLIB('Sales Partner')
      },
      header_sort_by: 'sales_partner',
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = <Box></Box>
        // Edit Icon
        let editIconJSX = (
          <Tooltip
            title={rLIB('Edit Sales Partner')}
            placement="right"
          >
            <Box
              className="tw-inline-block tw-ml-2 tw-opacity-30 tw-cursor-pointer"
              onClick={() => {
                // Get Sales Partners
                getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                  .then((res_GCK) => {
                    let salesPartnerOptions: TsInterface_UnspecifiedObject[] = []
                    let allSalesPartners: TsInterface_UnspecifiedObject = {}
                    let promiseArray: Promise<any>[] = []
                    promiseArray.push(
                      DatabaseGetCollection(DatabaseRef_SalesPartner_Collection(res_GCK.clientKey))
                        .then((res_DGC) => {
                          allSalesPartners = res_DGC.data
                          for (let loopSalesPartnerKey in allSalesPartners) {
                            let loopSalesPartner = allSalesPartners[loopSalesPartnerKey]
                            if (loopSalesPartner != null && loopSalesPartner.status === 'active' && loopSalesPartner.name != null) {
                              salesPartnerOptions.push({
                                key: loopSalesPartner.key,
                                value: loopSalesPartner.name,
                              })
                            }
                          }
                        })
                        .catch((rej_DGC) => {
                          console.error(rej_DGC)
                        }),
                    )
                    // Open Dialog
                    Promise.all(promiseArray).then(() => {
                      tableHooks.uc_setUserInterface_FormDialogDisplay({
                        display: true,
                        form: {
                          form: {
                            formAdditionalData: {},
                            formData: rowData,
                            formInputs: {
                              associated_sales_partner_key: {
                                data_type: 'string',
                                key: 'associated_sales_partner_key',
                                label: rLIB('Sales Partner'),
                                required: false,
                                input_type: 'multiple_choice_radio',
                                options: salesPartnerOptions,
                                submit_on_change: true,
                              },
                            },
                            formOnChange: (
                              formAdditionalData: TsInterface_FormAdditionalData,
                              formData: TsInterface_FormData,
                              formInputs: TsInterface_FormInputs,
                              formSettings: TsInterface_FormSettings,
                            ) => {},
                            formSettings: {
                              submit_button_hide: true,
                            },
                            formSubmission: (
                              formSubmittedData: TsInterface_FormSubmittedData,
                              formAdditionalData: TsInterface_FormAdditionalData,
                              formHooks: TsInterface_FormHooksObject,
                            ) => {
                              return new Promise((resolve, reject) => {
                                let updateObject = {
                                  associated_sales_partner_key: getProp(formSubmittedData, 'associated_sales_partner_key', null),
                                  associated_sales_partner_name: null,
                                }
                                if (
                                  formSubmittedData != null &&
                                  formSubmittedData.associated_sales_partner_key != null &&
                                  allSalesPartners != null &&
                                  allSalesPartners[formSubmittedData.associated_sales_partner_key] != null &&
                                  allSalesPartners[formSubmittedData.associated_sales_partner_key].name != null
                                ) {
                                  updateObject.associated_sales_partner_name = allSalesPartners[formSubmittedData.associated_sales_partner_key].name
                                }
                                DatabaseSetMergeDocument(DatabaseRef_TaskWorkflow_Document(res_GCK.clientKey, rowData.key as string), updateObject)
                                  .then((res_DSMD) => {
                                    resolve(res_DSMD)
                                  })
                                  .catch((rej_DSMD) => {
                                    reject(rej_DSMD)
                                  })
                              })
                            },
                          },
                          dialog: {
                            formDialogHeaderColor: 'success',
                            formDialogHeaderText: (
                              <>
                                {rLIB('Edit Sales Partner')} - {rowData.name}
                              </>
                            ),
                            formDialogIcon: (
                              <Icon
                                type="solid"
                                icon="pen-to-square"
                              />
                            ),
                          },
                        },
                      })
                    })
                  })
                  .catch((rej_GCK) => {
                    tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                  })
              }}
            >
              <Icon icon="pen-to-square" />
            </Box>
          </Tooltip>
        )
        if (rowData != null && rowData.associated_sales_partner_name != null && rowData.associated_sales_partner_name !== '') {
          cellJSX = (
            <Box>
              <Typography className="tw-inline-block">{rowData.associated_sales_partner_name}</Typography>
              {editIconJSX}
            </Box>
          )
        } else {
          cellJSX = (
            <Box>
              <Typography className="tw-opacity-30 tw-italic tw-inline-block">{rLIB('No Sales Partner')}</Typography>
              {editIconJSX}
            </Box>
          )
        }
        return cellJSX
      },
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        let cellCSS = 'tw-max-w-sm'
        return cellCSS
      },
    },
  },
  workflow_version_number: TableCellBasic('workflow_version_number', rLIB('Workflow Version'), 'workflow_version_number'),
  available_for_use: {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rLIB('Available for use')
      },
      header_sort_by: 'available_for_use',
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        if (rowData['available_for_use'] == null) {
          rowData['available_for_use'] = false
        }
        let cellJSX = (
          <Box>
            <Switch
              disabled={rowData == null || rowData['published_to_prod'] !== true}
              checked={rowData.available_for_use as boolean}
              onChange={(event: any) => {
                if (event != null && event.target != null && event.target.checked != null && rowData != null && rowData.key != null) {
                  let updateObject = {
                    available_for_use: event.target.checked,
                  }
                  getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                    .then((res_GCK) => {
                      DatabaseSetMergeDocument(DatabaseRef_TaskWorkflow_Document(res_GCK.clientKey, rowData.key as string), updateObject)
                        .then((res_DSMD) => {
                          tableHooks.ur_forceRerender()
                        })
                        .catch((rej_DSMD) => {
                          tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                        })
                    })
                    .catch((rej_GCK) => {
                      tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                    })
                }
              }}
            />
          </Box>
        )
        return cellJSX
      },
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        let cellCSS = ''
        return cellCSS
      },
    },
  },
  notes: {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rLIB('Notes')
      },
      header_sort_by: 'notes',
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = <Box></Box>
        let editIconJSX = (
          <Tooltip
            title={rLIB('Edit Notes')}
            placement="right"
          >
            <Box
              className="tw-inline-block tw-ml-2 tw-opacity-30 tw-cursor-pointer"
              onClick={() => {
                tableHooks.uc_setUserInterface_FormDialogDisplay({
                  display: true,
                  form: {
                    form: {
                      formAdditionalData: {},
                      formData: rowData,
                      formInputs: {
                        notes: {
                          data_type: 'string',
                          input_type: 'text_multiline',
                          key: 'notes',
                          label: rLIB('Notes'),
                          required: false,
                        },
                      },
                      formOnChange: (
                        formAdditionalData: TsInterface_FormAdditionalData,
                        formData: TsInterface_FormData,
                        formInputs: TsInterface_FormInputs,
                        formSettings: TsInterface_FormSettings,
                      ) => {},
                      formSettings: {},
                      formSubmission: (
                        formSubmittedData: TsInterface_FormSubmittedData,
                        formAdditionalData: TsInterface_FormAdditionalData,
                        formHooks: TsInterface_FormHooksObject,
                      ) => {
                        return new Promise((resolve, reject) => {
                          getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                            .then((res_GCK) => {
                              let updateObject = {
                                notes: getProp(formSubmittedData, 'notes', null),
                              }
                              DatabaseSetMergeDocument(DatabaseRef_TaskWorkflow_Document(res_GCK.clientKey, rowData.key as string), updateObject)
                                .then((res_DSMD) => {
                                  resolve(res_DSMD)
                                })
                                .catch((rej_DSMD) => {
                                  reject(rej_DSMD)
                                })
                            })
                            .catch((rej_GCK) => {
                              formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                            })
                        })
                      },
                    },
                    dialog: {
                      formDialogHeaderColor: 'success',
                      formDialogHeaderText: (
                        <>
                          {rLIB('Edit Notes')} - {rowData.name}
                        </>
                      ),
                      formDialogIcon: (
                        <Icon
                          type="solid"
                          icon="pen-to-square"
                        />
                      ),
                    },
                  },
                })
              }}
            >
              <Icon icon="pen-to-square" />
            </Box>
          </Tooltip>
        )
        if (rowData != null && rowData.notes != null && rowData.notes !== '') {
          cellJSX = (
            <Box>
              <Typography className="tw-inline-block">{rowData.notes}</Typography>
              {editIconJSX}
            </Box>
          )
        } else {
          cellJSX = (
            <Box>
              <Typography className="tw-opacity-30 tw-italic tw-inline-block">{rLIB('No Notes')}</Typography>
              {editIconJSX}
            </Box>
          )
        }
        return cellJSX
      },
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        let cellCSS = 'tw-max-w-sm'
        return cellCSS
      },
    },
  },
}

const tableColumns_TaskOutcomes: TsInterface_TableColumns = {
  value: TableCellBasic('value', rLIB('Outcome Name'), 'value'),
}

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Container
///////////////////////////////

export const Container: React.FC = (): JSX.Element => {
  // Props

  // Hooks - useContext, useState, useReducer, other
  // { sort-start } - hooks
  const un_routerNavigation = useNavigate()
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)
  const { uc_setUserInterface_ErrorDialogDisplay } = useContext(Context_UserInterface_ErrorDialog)
  const { uc_setUserInterface_PromptDialogDisplay } = useContext(Context_UserInterface_PromptDialog)
  // { sort-end } - hooks

  // Hooks - useEffect

  useEffect(() => {
    document.title = rLIB('Workflows', false) as string
  }, [])

  useEffect(() => {
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
    return () => {}
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

  // Other Variables
  const tableAdditionalData_TaskWorkflows: TsInterface_TableAdditionalData = {}

  // Functions
  const tableDatabaseEndpoint_TaskWorkflows = (
    queryGenerationData: TsInterface_TableDatabaseEndpointQueryObject,
    tableAdditionalData: TsInterface_TableAdditionalData,
  ) => {
    let queryOperatorsArray: TsInterface_QueryOperatorsArray = [{ prop: 'status', comparator: '==', value: 'active' }]
    let orderByArray: TsInterface_OrderByArray = [{ prop: 'name', desc: false }]
    let queryCursorsObject: TsInterface_QueryCursorsObject = {}
    if (queryGenerationData['startAfter'] != null) {
      queryCursorsObject['startAfter'] = queryGenerationData.startAfter
    }
    if (queryGenerationData['startAt'] != null) {
      queryCursorsObject['startAt'] = queryGenerationData.startAt
    }
    if (queryGenerationData['endAt'] != null) {
      queryCursorsObject['endAt'] = queryGenerationData.endAt
    }
    if (queryGenerationData['endBefore'] != null) {
      queryCursorsObject['endBefore'] = queryGenerationData.endBefore
    }
    let limit = getProp(queryGenerationData, 'limit', 5)
    return generateDatabaseQuery(
      DatabaseRef_TaskWorkflows_Collection(uc_RootData_ClientKey as string),
      queryOperatorsArray,
      orderByArray,
      queryCursorsObject,
      limit,
    )
  }

  const tableSearchEndpoint_TaskWorkflows = (searchProperty: string, searchInput: string) => {
    let limit = tableSettings_TaskWorkflows.rows_per_page
    if (limit === null) {
      limit = 20
    }
    return DatabaseRef_TaskWorkflows_Search(uc_RootData_ClientKey as string, searchProperty, searchInput, limit)
  }

  const promptObject: TsInterface_PromptDialogObject = {
    color: 'info',
    confirm_text: rLIB('Create') as JSX.Element,
    default_value: null,
    header: rLIB('Create New Workflow'),
    icon: (
      <Icon
        icon="sitemap"
        type="solid"
      />
    ),
    input_label: rLIB('Workflow Name') as JSX.Element,
    input_type: 'text',
    text: rLIB('Enter a name for your new workflow'),
    submit_callback: (promptValue: string) => {
      return new Promise((resolve, reject) => {
        let updateObject = {
          name: promptValue,
          workflow_version_number: 2,
          status: 'active',
        }
        getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
          .then((res_GCK) => {
            DatabaseAddDocument(DatabaseRef_TaskWorkflows_Collection(res_GCK.clientKey), updateObject, true)
              .then((res_DAD) => {
                directAppNavigation(un_routerNavigation, ApplicationPages.AdminDatabaseTaskWorkflowViewPage.url(res_DAD.key))
                resolve({ success: true })
              })
              .catch((rej_DAD) => {
                uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DAD.error })
              })
          })
          .catch((rej_GCK) => {
            uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
          })
      })
    },
  }

  // JSX Generation
  const rJSX_TaskWorkflowTabContent = (): JSX.Element => {
    let tableJSX = <></>
    if (tableSettings_TaskWorkflows.search_settings_database == null) {
      tableSettings_TaskWorkflows.search_settings_database = { search_type: 'firebase' }
    }
    tableSettings_TaskWorkflows.search_settings_database.search_endpoint = tableSearchEndpoint_TaskWorkflows
    if (uc_RootData_ClientKey != null) {
      tableJSX = (
        <Card className="">
          <TableDatabase
            tableAdditionalData={tableAdditionalData_TaskWorkflows}
            tableColumns={tableColumns_TaskWorkflows}
            tableDatabaseEndpoint={tableDatabaseEndpoint_TaskWorkflows}
            tableSettings={tableSettings_TaskWorkflows}
          />
        </Card>
      )
    } else {
      tableJSX = <></>
    }
    return tableJSX
  }

  const rJSX_TaskOutcomeTabContent = (): JSX.Element => {
    return (
      <Box>
        <Card className="">
          <TableBasic
            tableAdditionalData={{}}
            tableColumns={tableColumns_TaskOutcomes}
            tableData={objectToArray(taskOutcomes)}
            tableSettings={{
              paginated: false,
              size: 'small',
              sort_direction: 'desc',
              sort_property_default: 'name',
              sortable: true,
              // alternate_row_color_hex: themeVariables.background_hover,
              // alternate_row_colors: true,
              sticky_header: true,
              sticky_table_height: 'calc(100vh - 180px)',
            }}
          />
        </Card>
        <Box className="tw-mt-2 tw-p-2 tw-bg-warning_main tw-rounded">
          <Typography>
            <Icon
              icon="exclamation-triangle"
              type="solid"
              className="tw-mr-2"
            />
            {rLIB('Task Outcomes are currently hardcoded')}
          </Typography>
        </Box>
      </Box>
    )
  }

  const rJSX_NewWorkflowButton = (): JSX.Element => {
    return (
      <Button
        color="success"
        variant="contained"
        onClick={() => {
          uc_setUserInterface_PromptDialogDisplay({
            display: true,
            prompt: promptObject,
          })
        }}
        disableElevation
        startIcon={
          <Icon
            icon="circle-plus"
            type="solid"
          />
        }
      >
        {rLIB('New Task Workflow')}
      </Button>
    )
  }

  const rJSX_NewOutcomeButton = (): JSX.Element => {
    return (
      <Button
        color="success"
        variant="contained"
        disabled={true}
        onClick={() => {
          // TODO: Implement
        }}
        disableElevation
        startIcon={
          <Icon
            icon="circle-plus"
            type="solid"
          />
        }
      >
        {rLIB('New Task Outcome')}
      </Button>
    )
  }

  const rJSX_Page = (): JSX.Element => {
    let pageJSX = (
      <AuthenticatedContainer
        pageHeader={rLIB('Task Workflows')}
        pageKey={pageKey}
        content={
          <Box>
            <TabsUrl
              tabs={[
                {
                  tabUrlKey: 'Workflows',
                  tabHeader: rLIB('Workflows'),
                  tabButtons: [{ minJSX: rJSX_NewWorkflowButton(), fullJSX: rJSX_NewWorkflowButton(), sizeCutoff: 0 }],
                  tabContent: rJSX_TaskWorkflowTabContent(),
                },
                {
                  tabUrlKey: 'Outcomes',
                  tabHeader: rLIB('Task Outcomes'),
                  tabContent: rJSX_TaskOutcomeTabContent(),
                  tabButtons: [{ minJSX: rJSX_NewOutcomeButton(), fullJSX: rJSX_NewOutcomeButton(), sizeCutoff: 0 }],
                },
              ]}
              tabsSettings={{
                baseUrl: ApplicationPages.AdminDatabaseTaskWorkflowListPage.url(),
                tabQueryParam: 'tab',
                overridePageTitle: true,
                basePageTitle: rLIB('Task Workflows', false) as string,
              }}
            />
          </Box>
        }
      />
    )
    return pageJSX
  }

  // Render
  return <>{rJSX_Page()}</>
}
