import { Box } from '@mui/material'
import { MuiChipsInput } from 'mui-chips-input'
import {
  TsInterface_FormAdditionalData,
  TsInterface_FormData,
  TsInterface_FormHooksObject,
  TsInterface_FormInput,
  TsInterface_FormInputs,
  TsInterface_FormSettings,
  TsType_InputChangeCallback,
} from 'rfbp_core/components/form'
import { rLIB } from 'rfbp_core/localization/library'
import { getProp } from 'rfbp_core/services/helper_functions'
import { TsInterface_UnspecifiedObject } from 'rfbp_core/typescript/global_types'

function isValidEmail(email: string) {
  // Regular expression pattern for validating email address
  let emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
  // Test the email against the pattern
  return emailPattern.test(email)
}

export const taskOutcomes: TsInterface_UnspecifiedObject = {
  send_email: {
    key: 'send_email',
    value: rLIB('Send Email'),
    required_fields: {
      to_array: {
        key: 'to_array',
        label: rLIB('To'),
        input_type: 'custom_form_input_jsx',
        required: true,
        data_type: 'string',
        renderCustomFormInput: (
          formInput: TsInterface_FormInput,
          formInputs: TsInterface_FormInputs,
          formData: TsInterface_FormData,
          formInputChange: TsType_InputChangeCallback,
          formSettings: TsInterface_FormSettings,
          formAdditionalData: TsInterface_FormAdditionalData,
          formHooks: TsInterface_FormHooksObject,
        ) => {
          let inputJSX = (
            <Box
              className=""
              sx={{ marginBottom: '16px' }}
            >
              <MuiChipsInput
                label={rLIB('Email Recipients')}
                placeholder={rLIB('Add Emails', false) as string}
                fullWidth={true}
                value={getProp(formData, formInput.key, [])}
                onChange={(chipsArray: string[]) => {
                  formInputChange(formInput.key, chipsArray, true)
                }}
                disabled={false}
                disableEdition={true}
                disableDeleteOnBackspace={true}
                onKeyDown={(event: any) => {
                  // Nothing
                }}
                addOnBlur={true}
                renderChip={(Component, key, props) => {
                  // eslint-disable-next-line
                  if (isValidEmail(props.label) === true) {
                    return (
                      <Component
                        {...props}
                        key={key}
                      />
                    )
                  } else {
                    return (
                      <Component
                        {...props}
                        key={key}
                        color="error"
                      />
                    )
                  }
                }}
              />
            </Box>
          )
          return inputJSX
        },
      },
      subject: {
        key: 'subject',
        label: rLIB('Email Subject'),
        input_type: 'text_basic',
        required: true,
        data_type: 'string',
      },
      body: {
        key: 'body',
        label: rLIB('Email Body'),
        input_type: 'text_multiline',
        required: true,
        data_type: 'string',
      },
    },
  },
  send_sms: {
    key: 'send_sms',
    value: rLIB('Send SMS'),
    required_fields: {
      phone_number: {
        key: 'phone_number',
        label: rLIB('Phone Number'),
        input_type: 'phone_number_usa',
        required: true,
        data_type: 'string',
      },
      message: {
        key: 'message',
        label: rLIB('Message'),
        input_type: 'text_multiline',
        required: true,
        data_type: 'string',
      },
    },
  },
  // copy_data_to_root_project: {
  //   key: 'copy_data_to_root_project',
  //   value: rLIB('Copy Data to Root Project'),
  //   required_fields: {},
  // },
  external_project_sync: {
    key: 'external_project_sync',
    value: rLIB('Trigger External Project Sync'),
    required_fields: {},
  },
}

/*
 let iconJSX = <></>
      switch (outcome['outcome_event']['app_notification_type']) {
        case 'success':
          iconJSX = (
            <Box
              className="tw-inline-block tw-mr-2"
              sx={{ color: themeVariables.success_main }}
            >
              <Icon
                type="solid"
                icon="circle-check"
              />
            </Box>
          )
          break
        case 'info':
          iconJSX = (
            <Box
              className="tw-inline-block tw-mr-2"
              sx={{ color: themeVariables.info_main }}
            >
              <Icon
                type="solid"
                icon="circle-info"
              />
            </Box>
          )
          break
        case 'warning':
          iconJSX = (
            <Box
              className="tw-inline-block tw-mr-2"
              sx={{ color: themeVariables.warning_main }}
            >
              <Icon
                type="solid"
                icon="triangle-exclamation"
              />
            </Box>
          )
          break
        case 'error':
          iconJSX = (
            <Box
              className="tw-inline-block tw-mr-2"
              sx={{ color: themeVariables.error_main }}
            >
              <Icon
                type="solid"
                icon="siren-on"
              />
            </Box>
          )
          break
      }
      taskOutcomeEventJSX = (
        <Box>
          <Box className="tw-text-left tw-mx-1">
            <Typography
              variant="body1"
              className="tw-inline-block"
            >
              {rLIB('Event')}: {rLIB('Send in platform notification')}
            </Typography>
            <Box
              className="tw-ml-2 tw-inline-block tw-cursor-pointer tw-opacity-30 hover:tw-opacity-100"
              onClick={() => {
                if (editable === true) {
                  editTaskOutcomeEvent(task, outcome)
                }
              }}
            >
              <Icon icon="pen-to-square" />
            </Box>
          </Box>
          <Box className="tw-text-left tw-mx-1">
            {iconJSX}
            {outcome['outcome_event']['app_notification_text']}
          </Box>
        </Box>
      )
*/
