///////////////////////////////
// Imports
///////////////////////////////

import { collection, CollectionReference, doc, DocumentData, DocumentReference, getFirestore, Query } from 'firebase/firestore'
import { getStorage, ref, StorageReference } from 'firebase/storage'
import { generateDatabaseQuery, TsInterface_OrderByArray, TsInterface_QueryOperatorsArray } from 'rfbp_core/services/database_management'

///////////////////////////////
// Exports
///////////////////////////////

// Teams
export const DatabaseRef_SchedulingTeams_Collection = (clientKey: string): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'operations', 'schedules', 'teams')
}

export const DatabaseRef_SchedulingTeams_Document = (clientKey: string, teamKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'operations', 'schedules', 'teams', teamKey)
}

export const DatabaseRef_SchedulingActiveTeams_Query = (clientKey: string): Query<unknown> => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [{ prop: 'status', comparator: '==', value: 'active' }]
  let queryCursorsObject = {}
  return generateDatabaseQuery(
    collection(getFirestore(), 'clients', clientKey, 'operations', 'schedules', 'teams'),
    queryOperatorsArray,
    [],
    queryCursorsObject,
    null,
  )
}

export const DatabaseRef_ActiveTeamsByType_Query = (clientKey: string, teamType: string): Query<unknown> => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [
    { prop: 'status', comparator: '==', value: 'active' },
    { prop: 'team_type', comparator: '==', value: teamType },
  ]
  let queryCursorsObject = {}
  return generateDatabaseQuery(
    collection(getFirestore(), 'clients', clientKey, 'operations', 'schedules', 'teams'),
    queryOperatorsArray,
    [],
    queryCursorsObject,
    null,
  )
}

export const DatabaseRef_SchedulingDeletedTeams_Query = (clientKey: string): Query<unknown> => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [{ prop: 'status', comparator: '==', value: 'deleted' }]
  let queryCursorsObject = {}
  return generateDatabaseQuery(
    collection(getFirestore(), 'clients', clientKey, 'operations', 'schedules', 'teams'),
    queryOperatorsArray,
    [],
    queryCursorsObject,
    null,
  )
}

export const DatabaseRef_SchedulingActiveTeamsOfSpecificType_Query = (clientKey: string, teamType: string): Query<unknown> => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [
    { prop: 'status', comparator: '==', value: 'active' },
    { prop: 'team_type', comparator: '==', value: teamType },
  ]
  let queryCursorsObject = {}
  return generateDatabaseQuery(
    collection(getFirestore(), 'clients', clientKey, 'operations', 'schedules', 'teams'),
    queryOperatorsArray,
    [],
    queryCursorsObject,
    null,
  )
}

// Team Types
export const DatabaseRef_SchedulingTeamTypeSettings_Collection = (clientKey: string): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'operations', 'schedules', 'team_types')
}

export const DatabaseRef_SchedulingTeamTypeSettings_Document = (clientKey: string, teamTypeKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'operations', 'schedules', 'team_types', teamTypeKey)
}

// Time On
export const DatabaseRef_ScheduledTeamTimeOn_Collection = (clientKey: string): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'operations', 'schedules', 'team_time_on')
}

export const DatabaseRef_ScheduledTeamTimeOn_Document = (clientKey: string, teamKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'operations', 'schedules', 'team_time_on', teamKey)
}

// Time Off
export const DatabaseRef_ScheduledTimeOff_Collection = (clientKey: string): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'operations', 'schedules', 'time_off')
}

export const DatabaseRef_ScheduledTimeOff_Document = (clientKey: string, timeOffKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'operations', 'schedules', 'time_off', timeOffKey)
}

export const DatabaseRef_ScheduledTimeOff_Query = (clientKey: string, startDate: Date, endDate: Date): Query<unknown> => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [
    { prop: 'timestamp_time_off', comparator: '>=', value: startDate },
    { prop: 'timestamp_time_off', comparator: '<=', value: endDate },
  ]
  let queryCursorsObject = {}
  return generateDatabaseQuery(
    collection(getFirestore(), 'clients', clientKey, 'operations', 'schedules', 'time_off'),
    queryOperatorsArray,
    [],
    queryCursorsObject,
    null,
  )
}

export const DatabaseRef_ScheduledTimeOff_LastUpdated_Query = (clientKey: string, startDate: Date, endDate: Date) => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [
    { prop: 'timestamp_last_updated', comparator: '>=', value: startDate },
    { prop: 'timestamp_last_updated', comparator: '<=', value: endDate },
  ]
  let queryCursorsObject = {}
  let orderByArray: TsInterface_OrderByArray = [{ prop: 'timestamp_last_updated', desc: true }]
  return generateDatabaseQuery(
    collection(getFirestore(), 'clients', clientKey, 'operations', 'schedules', 'time_off'),
    queryOperatorsArray,
    orderByArray,
    queryCursorsObject,
    null,
  )
}

// Reference for team files collection
export const DatabaseRef_TeamFiles_Collection = (clientKey: string, teamKey: string): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'operations', 'schedules', 'teams', teamKey, 'files')
}

// Reference for a specific file within a team
export const DatabaseRef_TeamFile_Document = (clientKey: string, teamKey: string, fileKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'operations', 'schedules', 'teams', teamKey, 'files', fileKey)
}

// Storage reference for team files
export const StorageRef_TeamFile = (clientKey: string, teamKey: string, fileName: string): StorageReference => {
  return ref(getStorage(), `/clients/${clientKey}/operations/schedules/teams/${teamKey}/files/${fileName}`)
}

export const DatabaseRef_TeamLogs_Collection = (clientKey: string, teamKey: string): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'operations', 'schedules', 'teams', teamKey, 'logs')
}

//Teams Page Forum
export const DatabaseRef_TeamForumThreads_Collection = (clientKey: string, teamKey: string): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'operations', 'schedules', 'teams', teamKey, 'forum')
}

export const DatabaseRef_TeamForumThread_Document = (clientKey: string, teamKey: string, threadKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'operations', 'schedules', 'teams', teamKey, 'forum', threadKey)
}

export const DatabaseRef_TeamForumMessages_Collection = (clientKey: string, teamKey: string, threadKey: string): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'operations', 'schedules', 'teams', teamKey, 'forum', threadKey, 'messages')
}

export const DatabaseRef_TeamForumMessage_Document = (
  clientKey: string,
  teamKey: string,
  threadKey: string,
  messageKey: string,
): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'operations', 'schedules', 'teams', teamKey, 'forum', threadKey, 'messages', messageKey)
}

export const StorageRef_Team_MessageThread_File = (clientKey: string, teamKey: string, threadKey: string, fileName: string): StorageReference => {
  return ref(getStorage(), '/clients/' + clientKey + '/teams/' + teamKey + '/threads/' + threadKey + '/files/' + fileName)
}
