///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			containers are pages / views used in the app and are made up of components and can interact with services and models

		TODO:

			MAYBE Write cloud function to read only prod data at path and then have them select the data they want to copy to staging
				Feel like I built this utility before somewhere...


			// TODO - get collections on server and return and then pull list of documents in collection


			const admin = require('firebase-admin');

			// Initialize Firebase Admin SDK
			admin.initializeApp({
			credential: admin.credential.applicationDefault(),
			databaseURL: 'YOUR_DATABASE_URL'
			});

			async function getSubcollectionIds(path) {
			try {
				const collections = await admin.firestore().doc(path).listCollections();
				const collectionIds = collections.map(collection => collection.id);
				return collectionIds;
			} catch (error) {
				console.error('Error getting subcollection IDs:', error);
				return [];
			}
			}

			// Example usage:
			const path = 'YOUR_DOCUMENT_PATH'; // Replace this with your document path
			getSubcollectionIds(path)
			.then(subcollectionIds => {
				console.log('Subcollection IDs:', subcollectionIds);
			})
			.catch(error => {
				console.error('Error:', error);
			});




	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Box, Button, Card, Divider, FormControl, MenuItem, Select, TextField, Typography } from '@mui/material/'
import { processCycleTimeRtgToInstallData } from 'app/pages/gm_dashboard/services/data_cycle_time_rtg_to_install'
import { processPanelsInstalledData } from 'app/pages/gm_dashboard/services/data_panels_installed'
import { processSafetyReviewData } from 'app/pages/gm_dashboard/services/data_safety_review'
import { convertGmDataToEmailTemplateFormat } from 'app/pages/gm_dashboard/services/gm_email_html_generation'

import React, { useEffect, useReducer, useState } from 'react'
import { AuthenticatedContainer } from 'rfbp_aux/containers/authenticated_container'
import { ApplicationPages } from 'rfbp_aux/data/application_structure'
import { DatabaseRef_SalesPartner_Collection } from 'rfbp_aux/services/database_endpoints/directory/sales_partners'
import {
  DatabaseRef_CycleTimeByDate_Query,
  DatabaseRef_PanelsInstalledByDate_Query,
  DatabaseRef_SafetyReviewByDate_Query,
} from 'rfbp_aux/services/database_endpoints/operations/gm_dashboards'
import { DatabaseRef_ScheduledTimeOff_Query } from 'rfbp_aux/services/database_endpoints/operations/schedules'
import { DatabaseRef_RootClient_Collection } from 'rfbp_aux/services/database_endpoints/standard_database_endpoints'
import { Json } from 'rfbp_core/components/code_display'
import { TabsUrl } from 'rfbp_core/components/tabs'
import { rLIB } from 'rfbp_core/localization/library'
import { cloudFunctionManageRequest } from 'rfbp_core/services/cloud_functions'
import { DatabaseGetCollection, DatabaseGetLiveCollection } from 'rfbp_core/services/database_management'
import { generateHtmlForEmailFromTemplateObject } from 'rfbp_core/services/emails'
import { dynamicSort, objectToArray, returnFormattedDateKey } from 'rfbp_core/services/helper_functions'
import { TsInterface_UnspecifiedObject, TsType_VoidFunction } from 'rfbp_core/typescript/global_types'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

// Authenticated Nav Data
const pageKey: string = ApplicationPages['SuperTestLabPage']['key']

///////////////////////////////
// Functions
///////////////////////////////

// const moveProjectDataBetweenClients = (
// originClientKey: string,
// originProjectKey: string,
// destinationClientKey: string,
// destinationProjectKey: string,
// ) => {
// // Instantiate Variables
// let promiseArray1: TsType_UnknownPromise[] = []
// let promiseArray2: TsType_UnknownPromise[] = []
// let updateArray: TsInterface_DatabaseBatchUpdatesArray = []
// let contactLogs: TsInterface_UnspecifiedObject | null = null
// let documentsAgreements: TsInterface_UnspecifiedObject | null = null
// let documentsDesign: TsInterface_UnspecifiedObject | null = null
// let documentsPermits: TsInterface_UnspecifiedObject | null = null
// let designPreferences: TsInterface_UnspecifiedObject | null = null
// let projectNotes: TsInterface_UnspecifiedObject | null = null
// let projectNotesSubdata: TsInterface_UnspecifiedObject = {}
// let taskFormData: TsInterface_UnspecifiedObject | null = null
// // Get Data
// promiseArray1.push( DatabaseGetCollection( DatabaseRef_ProjectContactLogs_Collection( originClientKey, originProjectKey )).then((res_DGC) => {
// 	if(res_DGC != null && res_DGC.data != null){ contactLogs = res_DGC.data }
// }).catch((rej_DGC) => {
// 	console.error(rej_DGC)
// }) )
// promiseArray1.push( DatabaseGetCollection( DatabaseRef_Project_Documents_Collection( originClientKey, originProjectKey, "agreements" )).then((res_DGC) => {
// 	if(res_DGC != null && res_DGC.data != null){ documentsAgreements = res_DGC.data }
// }).catch((rej_DGC) => {
// 	console.error(rej_DGC)
// }) )
// promiseArray1.push( DatabaseGetCollection( DatabaseRef_Project_Documents_Collection( originClientKey, originProjectKey, "design" )).then((res_DGC) => {
// 	if(res_DGC != null && res_DGC.data != null){ documentsDesign = res_DGC.data }
// }).catch((rej_DGC) => {
// 	console.error(rej_DGC)
// }) )
// promiseArray1.push( DatabaseGetCollection( DatabaseRef_Project_Documents_Collection( originClientKey, originProjectKey, "permits" )).then((res_DGC) => {
// 	if(res_DGC != null && res_DGC.data != null){ documentsPermits = res_DGC.data }
// }).catch((rej_DGC) => {
// 	console.error(rej_DGC)
// }) )
// promiseArray1.push( DatabaseGetDocument( DatabaseRef_ProjectDesignPreferences_Document( originClientKey, originProjectKey )).then((res_DGD) => {
// 	if(res_DGD != null && res_DGD.data != null){ designPreferences = res_DGD.data }
// }).catch((rej_DGD) => {
// 	console.error(rej_DGD)
// }) )
// promiseArray1.push( DatabaseGetCollection( DatabaseRef_ProjectNoteThreads_Collection( originClientKey, originProjectKey )).then((res_DGC) => {
// 	if(res_DGC != null && res_DGC.data != null){ projectNotes = res_DGC.data }
// }).catch((rej_DGC) => {
// 	console.error(rej_DGC)
// }) )
// promiseArray1.push( DatabaseGetCollection( DatabaseRef_ProjectTaskFormData_Collection( originClientKey, originProjectKey )).then((res_DGC) => {
// 	if(res_DGC != null && res_DGC.data != null){ taskFormData = res_DGC.data }
// }).catch((rej_DGC) => {
// 	console.error(rej_DGC)
// }) )
// // Initial Data Loaded
// Promise.all( promiseArray1 ).finally(() => {
// 	for( let loopKey in projectNotes ){
// 		promiseArray2.push( DatabaseGetCollection( DatabaseRef_ProjectNoteThreadNotes_Collection( originClientKey, originProjectKey, loopKey )).then((res_DGC) => {
// 			if(res_DGC != null && res_DGC.data != null){ projectNotesSubdata[ loopKey ] = res_DGC.data }
// 		}).catch((rej_DGC) => {
// 			console.error(rej_DGC)
// 		}) )
// 	}
// 	Promise.all( promiseArray2 ).finally(() => {
// 		// Subsequent Data Loaded
// 		if( contactLogs != null ){
// 			for( let loopKey in contactLogs ){
// 				let loopData = contactLogs[ loopKey ]
// 				updateArray.push({
// 					type: "setMerge",
// 					ref: DatabaseRef_ProjectContactLogs_Document( destinationClientKey, destinationProjectKey, loopKey ),
// 					data: loopData
// 				})
// 			}
// 		}
// 		if( documentsAgreements != null ){
// 			for( let loopKey in documentsAgreements ){
// 				let loopData = documentsAgreements[ loopKey ]
// 				updateArray.push({
// 					type: "setMerge",
// 					ref: DatabaseRef_Project_Documents_Document( destinationClientKey, destinationProjectKey, "agreements", loopKey ),
// 					data: loopData
// 				})
// 			}
// 		}
// 		if( documentsDesign != null ){
// 			for( let loopKey in documentsDesign ){
// 				let loopData = documentsDesign[ loopKey ]
// 				updateArray.push({
// 					type: "setMerge",
// 					ref: DatabaseRef_Project_Documents_Document( destinationClientKey, destinationProjectKey, "design", loopKey ),
// 					data: loopData
// 				})
// 			}
// 		}
// 		if( documentsPermits != null ){
// 			for( let loopKey in documentsPermits ){
// 				let loopData = documentsPermits[ loopKey ]
// 				updateArray.push({
// 					type: "setMerge",
// 					ref: DatabaseRef_Project_Documents_Document( destinationClientKey, destinationProjectKey, "permits", loopKey ),
// 					data: loopData
// 				})
// 			}
// 		}
// 		if( designPreferences != null ){
// 			updateArray.push({
// 				type: "setMerge",
// 				ref: DatabaseRef_ProjectDesignPreferences_Document( destinationClientKey, destinationProjectKey ),
// 				data: designPreferences
// 			})
// 		}
// 		if( taskFormData != null ){
// 			for( let loopKey in taskFormData ){
// 				let loopData = taskFormData[ loopKey ]
// 				// TODO - change key
// 				let newLoopKey = loopKey.replace(originProjectKey, destinationProjectKey)
// 				updateArray.push({
// 					type: "setMerge",
// 					ref: DatabaseRef_ProjectTaskFormData_Document( destinationClientKey, destinationProjectKey, newLoopKey ),
// 					data: loopData
// 				})
// 			}
// 		}
// 		if( projectNotes != null ){
// 			for( let loopKey in projectNotes ){
// 				let loopData = projectNotes[ loopKey ]
// 				updateArray.push({
// 					type: "setMerge",
// 					ref: DatabaseRef_ProjectNoteThreads_Document( destinationClientKey, destinationProjectKey, loopKey ),
// 					data: loopData
// 				})
// 				if( projectNotesSubdata != null && projectNotesSubdata[loopKey] != null ){
// 					for( let subLoopKey in projectNotesSubdata[ loopKey ] ){
// 						let subLoopData = projectNotesSubdata[ loopKey ][ subLoopKey ]
// 						updateArray.push({
// 							type: "setMerge",
// 							ref: DatabaseRef_ProjectNoteThreadNotes_Document( destinationClientKey, destinationProjectKey, loopKey, subLoopKey ),
// 							data: subLoopData
// 						})
// 					}
// 				}
// 			}
// 		}
// 		console.log( "<><><><><><><><>" )
// 		console.log( updateArray )
// 		DatabaseBatchUpdate( updateArray ).then((res_DBU) => {
// 			console.log( res_DBU )
// 		}).catch((rej_DBU) => {
// 			console.error( rej_DBU )
// 		})
// 	})
// })
// }

// Database Management Utility
let databaseStructureOptions: TsInterface_UnspecifiedObject = {
  directory: {
    type: 'collection',
    key: 'directory',
    docs: {
      equipment: {
        type: 'doc',

        key: 'equipment',
        collections: {
          groups: {
            type: 'collection',
            key: 'groups',
            dynamic_doc_keys: true,
            docs: {
              $dynamicKey: {
                type: 'doc',
              },
            },
          },
          kits: {
            type: 'collection',
            key: 'kits',
          },
          main: {
            type: 'collection',
            key: 'main',
          },
          manufacturers: {
            type: 'collection',
            key: 'manufacturers',
          },
          types: {
            type: 'collection',
            key: 'types',
          },
        },
      },
      regions: {
        key: 'regions',
      },
      sales_partners: {
        key: 'sales_partners',
      },
      task_blueprints: {
        key: 'task_blueprints',
      },
    },
  },
  finances: {
    type: 'collection',
    key: 'finances',
  },
  integrations: {
    type: 'collection',
    key: 'integrations',
  },
  machine_learning: {
    type: 'collection',
    key: 'machine_learning',
  },
  materials: {
    type: 'collection',
    key: 'materials',
  },
  operations: {
    type: 'collection',
    key: 'operations',
  },
  settings: {
    type: 'collection',
    key: 'settings',
  },
  timesheets: {
    type: 'collection',
    key: 'timesheets',
  },
  webstore: {
    type: 'collection',
    key: 'webstore',
  },
}

// us_panelsInstalledFormattedData
const TEMP_GMDashboardData_PanelsInstalled = {
  summary_rows: [
    [
      {
        cellValue: {
          key: null,
          ref: null,
          props: {
            children: 'Panels Installed',
          },
          _owner: null,
          _store: {},
        },
        conditionalFormatting: {
          fontWeight: 'bold',
          width: '150px',
        },
      },
      {
        cellValue: 'Aug 05',
        conditionalFormatting: {
          fontWeight: 'bold',
          opacity: 1,
          width: '150px',
        },
      },
      {
        cellValue: 'Aug 12',
        conditionalFormatting: {
          fontWeight: 'bold',
          opacity: 1,
          width: '150px',
        },
      },
      {
        cellValue: 'Aug 19',
        conditionalFormatting: {
          fontWeight: 'bold',
          opacity: 0.5,
          width: '150px',
        },
      },
      {
        cellValue: 'Aug 26',
        conditionalFormatting: {
          fontWeight: 'bold',
          opacity: 0.5,
          width: '150px',
        },
      },
    ],
    [
      {
        cellValue: {
          key: null,
          ref: null,
          props: {
            children: 'Total',
          },
          _owner: null,
          _store: {},
        },
        conditionalFormatting: {
          fontWeight: 'bold',
          width: '150px',
        },
      },
      {
        cellValue: '627 / 800',
        conditionalFormatting: {
          backgroundColor: '#c82424',
          opacity: 1,
        },
        additionalCellData: {
          totalPanels: 627,
          goalPanels: 800,
          conditionalFormatting: {
            backgroundColor: '#c82424',
            opacity: 1,
          },
        },
      },
      {
        cellValue: '955 / 1100',
        conditionalFormatting: {
          backgroundColor: '#c82424',
          opacity: 1,
        },
        additionalCellData: {
          totalPanels: 955,
          goalPanels: 1100,
          conditionalFormatting: {
            backgroundColor: '#c82424',
            opacity: 1,
          },
        },
      },
      {
        cellValue: '478 / 800',
        conditionalFormatting: {
          backgroundColor: '#c82424',
          opacity: 0.5,
        },
        additionalCellData: {
          totalPanels: 478,
          goalPanels: 800,
          conditionalFormatting: {
            backgroundColor: '#c82424',
            opacity: 0.5,
          },
        },
      },
      {
        cellValue: '170 / 200',
        conditionalFormatting: {
          backgroundColor: '#c82424',
          opacity: 0.5,
        },
        additionalCellData: {
          totalPanels: 170,
          goalPanels: 200,
          conditionalFormatting: {
            backgroundColor: '#c82424',
            opacity: 0.5,
          },
        },
      },
    ],
  ],
  data_rows: [
    [
      {
        cellValue: 'Dream Team (712)',
        conditionalFormatting: {
          fontWeight: 'bold',
          width: '150px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '150px',
        },
      },
      {
        cellValue: 63,
        conditionalFormatting: {
          backgroundColor: '#c82424',
          opacity: 1,
          cursor: 'pointer',
          width: '150px',
        },
      },
      {
        cellValue: 20,
        conditionalFormatting: {
          backgroundColor: '#c82424',
          opacity: 0.5,
          cursor: 'pointer',
          width: '150px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 0.5,
          cursor: 'default',
          width: '150px',
        },
      },
    ],
    [
      {
        cellValue: 'Illuminatis',
        conditionalFormatting: {
          fontWeight: 'bold',
          width: '150px',
        },
      },
      {
        cellValue: 102,
        conditionalFormatting: {
          backgroundColor: '#28a56c',
          opacity: 1,
          cursor: 'pointer',
          width: '150px',
        },
      },
      {
        cellValue: 77,
        conditionalFormatting: {
          backgroundColor: '#c82424',
          opacity: 1,
          cursor: 'pointer',
          width: '150px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 0.5,
          cursor: 'default',
          width: '150px',
        },
      },
      {
        cellValue: 39,
        conditionalFormatting: {
          backgroundColor: '#c82424',
          opacity: 0.5,
          cursor: 'pointer',
          width: '150px',
        },
      },
    ],
    [
      {
        cellValue: 'Slay That Pitch',
        conditionalFormatting: {
          fontWeight: 'bold',
          width: '150px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '150px',
        },
      },
      {
        cellValue: 24,
        conditionalFormatting: {
          backgroundColor: '#c82424',
          opacity: 1,
          cursor: 'pointer',
          width: '150px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 0.5,
          cursor: 'default',
          width: '150px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 0.5,
          cursor: 'default',
          width: '150px',
        },
      },
    ],
    [
      {
        cellValue: 'Solar Bears',
        conditionalFormatting: {
          fontWeight: 'bold',
          width: '150px',
        },
      },
      {
        cellValue: 73,
        conditionalFormatting: {
          backgroundColor: '#c82424',
          opacity: 1,
          cursor: 'pointer',
          width: '150px',
        },
      },
      {
        cellValue: 80,
        conditionalFormatting: {
          backgroundColor: '#c82424',
          opacity: 1,
          cursor: 'pointer',
          width: '150px',
        },
      },
      {
        cellValue: 10,
        conditionalFormatting: {
          backgroundColor: '#c82424',
          opacity: 0.5,
          cursor: 'pointer',
          width: '150px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 0.5,
          cursor: 'default',
          width: '150px',
        },
      },
    ],
    [
      {
        cellValue: 'Solar Energy',
        conditionalFormatting: {
          fontWeight: 'bold',
          width: '150px',
        },
      },
      {
        cellValue: 79,
        conditionalFormatting: {
          backgroundColor: '#c82424',
          opacity: 1,
          cursor: 'pointer',
          width: '150px',
        },
      },
      {
        cellValue: 94,
        conditionalFormatting: {
          backgroundColor: '#c82424',
          opacity: 1,
          cursor: 'pointer',
          width: '150px',
        },
      },
      {
        cellValue: 74,
        conditionalFormatting: {
          backgroundColor: '#c82424',
          opacity: 0.5,
          cursor: 'pointer',
          width: '150px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 0.5,
          cursor: 'default',
          width: '150px',
        },
      },
    ],
    [
      {
        cellValue: 'Solar Expanse',
        conditionalFormatting: {
          fontWeight: 'bold',
          width: '150px',
        },
      },
      {
        cellValue: 81,
        conditionalFormatting: {
          backgroundColor: '#c82424',
          opacity: 1,
          cursor: 'pointer',
          width: '150px',
        },
      },
      {
        cellValue: 90,
        conditionalFormatting: {
          backgroundColor: '#c82424',
          opacity: 1,
          cursor: 'pointer',
          width: '150px',
        },
      },
      {
        cellValue: 43,
        conditionalFormatting: {
          backgroundColor: '#c82424',
          opacity: 0.5,
          cursor: 'pointer',
          width: '150px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 0.5,
          cursor: 'default',
          width: '150px',
        },
      },
    ],
    [
      {
        cellValue: 'Star Troopers (712)',
        conditionalFormatting: {
          fontWeight: 'bold',
          width: '150px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '150px',
        },
      },
      {
        cellValue: 161,
        conditionalFormatting: {
          backgroundColor: '#28a56c',
          opacity: 1,
          cursor: 'pointer',
          width: '150px',
        },
      },
      {
        cellValue: 60,
        conditionalFormatting: {
          backgroundColor: '#c82424',
          opacity: 0.5,
          cursor: 'pointer',
          width: '150px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 0.5,
          cursor: 'default',
          width: '150px',
        },
      },
    ],
    [
      {
        cellValue: 'Sun Seekers (Peoria Local)',
        conditionalFormatting: {
          fontWeight: 'bold',
          width: '150px',
        },
      },
      {
        cellValue: 108,
        conditionalFormatting: {
          backgroundColor: '#28a56c',
          opacity: 1,
          cursor: 'pointer',
          width: '150px',
        },
      },
      {
        cellValue: 45,
        conditionalFormatting: {
          backgroundColor: '#c82424',
          opacity: 1,
          cursor: 'pointer',
          width: '150px',
        },
      },
      {
        cellValue: 97,
        conditionalFormatting: {
          backgroundColor: '#c82424',
          opacity: 0.5,
          cursor: 'pointer',
          width: '150px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 0.5,
          cursor: 'default',
          width: '150px',
        },
      },
    ],
    [
      {
        cellValue: 'The Alphas (711)',
        conditionalFormatting: {
          fontWeight: 'bold',
          width: '150px',
        },
      },
      {
        cellValue: 71,
        conditionalFormatting: {
          backgroundColor: '#c82424',
          opacity: 1,
          cursor: 'pointer',
          width: '150px',
        },
      },
      {
        cellValue: 76,
        conditionalFormatting: {
          backgroundColor: '#c82424',
          opacity: 1,
          cursor: 'pointer',
          width: '150px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 0.5,
          cursor: 'default',
          width: '150px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 0.5,
          cursor: 'default',
          width: '150px',
        },
      },
    ],
    [
      {
        cellValue: 'Watt Wizards',
        conditionalFormatting: {
          fontWeight: 'bold',
          width: '150px',
        },
      },
      {
        cellValue: 54,
        conditionalFormatting: {
          backgroundColor: '#c82424',
          opacity: 1,
          cursor: 'pointer',
          width: '150px',
        },
      },
      {
        cellValue: 122,
        conditionalFormatting: {
          backgroundColor: '#28a56c',
          opacity: 1,
          cursor: 'pointer',
          width: '150px',
        },
      },
      {
        cellValue: 83,
        conditionalFormatting: {
          backgroundColor: '#c82424',
          opacity: 0.5,
          cursor: 'pointer',
          width: '150px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 0.5,
          cursor: 'default',
          width: '150px',
        },
      },
    ],
    [
      {
        cellValue: 'Wolf Pack',
        conditionalFormatting: {
          fontWeight: 'bold',
          width: '150px',
        },
      },
      {
        cellValue: 59,
        conditionalFormatting: {
          backgroundColor: '#c82424',
          opacity: 1,
          cursor: 'pointer',
          width: '150px',
        },
      },
      {
        cellValue: 123,
        conditionalFormatting: {
          backgroundColor: '#28a56c',
          opacity: 1,
          cursor: 'pointer',
          width: '150px',
        },
      },
      {
        cellValue: 91,
        conditionalFormatting: {
          backgroundColor: '#c82424',
          opacity: 0.5,
          cursor: 'pointer',
          width: '150px',
        },
      },
      {
        cellValue: 131,
        conditionalFormatting: {
          backgroundColor: '#28a56c',
          opacity: 0.5,
          cursor: 'pointer',
          width: '150px',
        },
      },
    ],
  ],
}

// us_formattedCrewHours
const TEMP_GMDashboardData_CrewHours = {
  summary_rows: [
    [
      {
        cellValue: {
          key: null,
          ref: null,
          props: {
            children: 'Crew Hours',
          },
          _owner: null,
          _store: {},
        },
        conditionalFormatting: {
          fontWeight: 'bold',
          width: '200px',
        },
      },
      {
        cellValue: {
          key: null,
          ref: null,
          props: {
            children: [
              'Aug 05',
              ' ',
              {
                key: null,
                ref: null,
                props: {
                  icon: 'arrows-rotate',
                  className: 'tw-opacity-30 hover:tw-opacity-100 tw-cursor-pointer tw-ml-1',
                  tooltip: {
                    key: null,
                    ref: null,
                    props: {
                      children: 'Refresh Data',
                    },
                    _owner: null,
                    _store: {},
                  },
                },
                _owner: null,
                _store: {},
              },
            ],
          },
          _owner: null,
          _store: {},
        },
        conditionalFormatting: {
          fontWeight: 'bold',
          opacity: 1,
          width: '120px',
        },
      },
      {
        cellValue: {
          key: null,
          ref: null,
          props: {
            children: [
              'Aug 12',
              ' ',
              {
                key: null,
                ref: null,
                props: {
                  icon: 'arrows-rotate',
                  className: 'tw-opacity-30 hover:tw-opacity-100 tw-cursor-pointer tw-ml-1',
                  tooltip: {
                    key: null,
                    ref: null,
                    props: {
                      children: 'Refresh Data',
                    },
                    _owner: null,
                    _store: {},
                  },
                },
                _owner: null,
                _store: {},
              },
            ],
          },
          _owner: null,
          _store: {},
        },
        conditionalFormatting: {
          fontWeight: 'bold',
          opacity: 1,
          width: '120px',
        },
      },
      {
        cellValue: {
          key: null,
          ref: null,
          props: {
            children: [
              'Aug 19',
              ' ',
              {
                key: null,
                ref: null,
                props: {
                  icon: 'arrows-rotate',
                  className: 'tw-opacity-30 hover:tw-opacity-100 tw-cursor-pointer tw-ml-1',
                  tooltip: {
                    key: null,
                    ref: null,
                    props: {
                      children: 'Refresh Data',
                    },
                    _owner: null,
                    _store: {},
                  },
                },
                _owner: null,
                _store: {},
              },
            ],
          },
          _owner: null,
          _store: {},
        },
        conditionalFormatting: {
          fontWeight: 'bold',
          opacity: 0.5,
          width: '120px',
        },
      },
      {
        cellValue: {
          key: null,
          ref: null,
          props: {
            children: [
              'Aug 26',
              ' ',
              {
                key: null,
                ref: null,
                props: {
                  icon: 'arrows-rotate',
                  className: 'tw-opacity-30 hover:tw-opacity-100 tw-cursor-pointer tw-ml-1',
                  tooltip: {
                    key: null,
                    ref: null,
                    props: {
                      children: 'Refresh Data',
                    },
                    _owner: null,
                    _store: {},
                  },
                },
                _owner: null,
                _store: {},
              },
            ],
          },
          _owner: null,
          _store: {},
        },
        conditionalFormatting: {
          fontWeight: 'bold',
          opacity: 0.5,
          width: '120px',
        },
      },
    ],
    [
      {
        cellValue: 'Total',
        conditionalFormatting: {
          fontWeight: 'bold',
          backgroundColor: '',
          opacity: 1,
          width: '200px',
        },
      },
      {
        cellValue: '-88.54',
        conditionalFormatting: {
          fontWeight: 'normal',
          backgroundColor: '#28a56c',
          opacity: 1,
          width: '120px',
        },
      },
      {
        cellValue: '198.59',
        conditionalFormatting: {
          fontWeight: 'normal',
          backgroundColor: '#c82424',
          opacity: 1,
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          fontWeight: 'normal',
          backgroundColor: '',
          opacity: 1,
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          fontWeight: 'normal',
          backgroundColor: '',
          opacity: 1,
          width: '120px',
        },
      },
    ],
  ],
  data_rows: [
    [
      {
        cellValue: 'Abdiel Mata',
        conditionalFormatting: {
          fontWeight: 'bold',
          backgroundColor: '',
          opacity: 1,
          width: '200px',
        },
      },
      {
        cellValue: '10.46',
        conditionalFormatting: {
          backgroundColor: '#c82424',
          opacity: 1,
          cursor: 'pointer',
          width: '120px',
        },
      },
      {
        cellValue: '14.91',
        conditionalFormatting: {
          backgroundColor: '#c82424',
          opacity: 1,
          cursor: 'pointer',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
    ],
    [
      {
        cellValue: 'Aby Cabrera',
        conditionalFormatting: {
          fontWeight: 'bold',
          backgroundColor: '',
          opacity: 1,
          width: '200px',
        },
      },
      {
        cellValue: '7.03',
        conditionalFormatting: {
          backgroundColor: '#c82424',
          opacity: 1,
          cursor: 'pointer',
          width: '120px',
        },
      },
      {
        cellValue: '8.59',
        conditionalFormatting: {
          backgroundColor: '#c82424',
          opacity: 1,
          cursor: 'pointer',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
    ],
    [
      {
        cellValue: 'Adam Toddington',
        conditionalFormatting: {
          fontWeight: 'bold',
          backgroundColor: '',
          opacity: 1,
          width: '200px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '8.74',
        conditionalFormatting: {
          backgroundColor: '#c82424',
          opacity: 1,
          cursor: 'pointer',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
    ],
    [
      {
        cellValue: 'Anderson Hohn',
        conditionalFormatting: {
          fontWeight: 'bold',
          backgroundColor: '',
          opacity: 1,
          width: '200px',
        },
      },
      {
        cellValue: '5.10',
        conditionalFormatting: {
          backgroundColor: '#c82424',
          opacity: 1,
          cursor: 'pointer',
          width: '120px',
        },
      },
      {
        cellValue: '-6.84',
        conditionalFormatting: {
          backgroundColor: '#28a56c',
          opacity: 1,
          cursor: 'pointer',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
    ],
    [
      {
        cellValue: 'Antonio Arciga',
        conditionalFormatting: {
          fontWeight: 'bold',
          backgroundColor: '',
          opacity: 1,
          width: '200px',
        },
      },
      {
        cellValue: '8.16',
        conditionalFormatting: {
          backgroundColor: '#c82424',
          opacity: 1,
          cursor: 'pointer',
          width: '120px',
        },
      },
      {
        cellValue: '-1.64',
        conditionalFormatting: {
          backgroundColor: '#28a56c',
          opacity: 1,
          cursor: 'pointer',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
    ],
    [
      {
        cellValue: 'Antonio Carpenterio',
        conditionalFormatting: {
          fontWeight: 'bold',
          backgroundColor: '',
          opacity: 1,
          width: '200px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
    ],
    [
      {
        cellValue: 'Austin Johnson',
        conditionalFormatting: {
          fontWeight: 'bold',
          backgroundColor: '',
          opacity: 1,
          width: '200px',
        },
      },
      {
        cellValue: '5.53',
        conditionalFormatting: {
          backgroundColor: '#c82424',
          opacity: 1,
          cursor: 'pointer',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
    ],
    [
      {
        cellValue: 'Ben Woodward',
        conditionalFormatting: {
          fontWeight: 'bold',
          backgroundColor: '',
          opacity: 1,
          width: '200px',
        },
      },
      {
        cellValue: '-30.01',
        conditionalFormatting: {
          backgroundColor: '#28a56c',
          opacity: 1,
          cursor: 'pointer',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
    ],
    [
      {
        cellValue: 'Bryce Jones',
        conditionalFormatting: {
          fontWeight: 'bold',
          backgroundColor: '',
          opacity: 1,
          width: '200px',
        },
      },
      {
        cellValue: '5.42',
        conditionalFormatting: {
          backgroundColor: '#c82424',
          opacity: 1,
          cursor: 'pointer',
          width: '120px',
        },
      },
      {
        cellValue: '-15.61',
        conditionalFormatting: {
          backgroundColor: '#28a56c',
          opacity: 1,
          cursor: 'pointer',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
    ],
    [
      {
        cellValue: 'Carlos Castaneda',
        conditionalFormatting: {
          fontWeight: 'bold',
          backgroundColor: '',
          opacity: 1,
          width: '200px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
    ],
    [
      {
        cellValue: 'Carlos Castro',
        conditionalFormatting: {
          fontWeight: 'bold',
          backgroundColor: '',
          opacity: 1,
          width: '200px',
        },
      },
      {
        cellValue: '6.06',
        conditionalFormatting: {
          backgroundColor: '#c82424',
          opacity: 1,
          cursor: 'pointer',
          width: '120px',
        },
      },
      {
        cellValue: '6.57',
        conditionalFormatting: {
          backgroundColor: '#c82424',
          opacity: 1,
          cursor: 'pointer',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
    ],
    [
      {
        cellValue: 'Damyan Melton',
        conditionalFormatting: {
          fontWeight: 'bold',
          backgroundColor: '',
          opacity: 1,
          width: '200px',
        },
      },
      {
        cellValue: '-18.26',
        conditionalFormatting: {
          backgroundColor: '#28a56c',
          opacity: 1,
          cursor: 'pointer',
          width: '120px',
        },
      },
      {
        cellValue: '26.57',
        conditionalFormatting: {
          backgroundColor: '#c82424',
          opacity: 1,
          cursor: 'pointer',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
    ],
    [
      {
        cellValue: 'Daniel Garcia Jr',
        conditionalFormatting: {
          fontWeight: 'bold',
          backgroundColor: '',
          opacity: 1,
          width: '200px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
    ],
    [
      {
        cellValue: 'Edgar Porras',
        conditionalFormatting: {
          fontWeight: 'bold',
          backgroundColor: '',
          opacity: 1,
          width: '200px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
    ],
    [
      {
        cellValue: 'Felix Medina',
        conditionalFormatting: {
          fontWeight: 'bold',
          backgroundColor: '',
          opacity: 1,
          width: '200px',
        },
      },
      {
        cellValue: '-24.67',
        conditionalFormatting: {
          backgroundColor: '#28a56c',
          opacity: 1,
          cursor: 'pointer',
          width: '120px',
        },
      },
      {
        cellValue: '-13.64',
        conditionalFormatting: {
          backgroundColor: '#28a56c',
          opacity: 1,
          cursor: 'pointer',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
    ],
    [
      {
        cellValue: 'Francisco Gomez',
        conditionalFormatting: {
          fontWeight: 'bold',
          backgroundColor: '',
          opacity: 1,
          width: '200px',
        },
      },
      {
        cellValue: '16.24',
        conditionalFormatting: {
          backgroundColor: '#c82424',
          opacity: 1,
          cursor: 'pointer',
          width: '120px',
        },
      },
      {
        cellValue: '20.68',
        conditionalFormatting: {
          backgroundColor: '#c82424',
          opacity: 1,
          cursor: 'pointer',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
    ],
    [
      {
        cellValue: 'Frederick Burks',
        conditionalFormatting: {
          fontWeight: 'bold',
          backgroundColor: '',
          opacity: 1,
          width: '200px',
        },
      },
      {
        cellValue: '4.13',
        conditionalFormatting: {
          backgroundColor: '#c82424',
          opacity: 1,
          cursor: 'pointer',
          width: '120px',
        },
      },
      {
        cellValue: '14.78',
        conditionalFormatting: {
          backgroundColor: '#c82424',
          opacity: 1,
          cursor: 'pointer',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
    ],
    [
      {
        cellValue: 'Gabriel Aguirre',
        conditionalFormatting: {
          fontWeight: 'bold',
          backgroundColor: '',
          opacity: 1,
          width: '200px',
        },
      },
      {
        cellValue: '-14.79',
        conditionalFormatting: {
          backgroundColor: '#28a56c',
          opacity: 1,
          cursor: 'pointer',
          width: '120px',
        },
      },
      {
        cellValue: '-0.19',
        conditionalFormatting: {
          backgroundColor: '#28a56c',
          opacity: 1,
          cursor: 'pointer',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
    ],
    [
      {
        cellValue: 'Gabriel Gonzales',
        conditionalFormatting: {
          fontWeight: 'bold',
          backgroundColor: '',
          opacity: 1,
          width: '200px',
        },
      },
      {
        cellValue: '-10.63',
        conditionalFormatting: {
          backgroundColor: '#28a56c',
          opacity: 1,
          cursor: 'pointer',
          width: '120px',
        },
      },
      {
        cellValue: '-0.48',
        conditionalFormatting: {
          backgroundColor: '#28a56c',
          opacity: 1,
          cursor: 'pointer',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
    ],
    [
      {
        cellValue: 'Gabriel Halcon',
        conditionalFormatting: {
          fontWeight: 'bold',
          backgroundColor: '',
          opacity: 1,
          width: '200px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '17.54',
        conditionalFormatting: {
          backgroundColor: '#c82424',
          opacity: 1,
          cursor: 'pointer',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
    ],
    [
      {
        cellValue: 'George Blanco II',
        conditionalFormatting: {
          fontWeight: 'bold',
          backgroundColor: '',
          opacity: 1,
          width: '200px',
        },
      },
      {
        cellValue: '-25.38',
        conditionalFormatting: {
          backgroundColor: '#28a56c',
          opacity: 1,
          cursor: 'pointer',
          width: '120px',
        },
      },
      {
        cellValue: '-13.42',
        conditionalFormatting: {
          backgroundColor: '#28a56c',
          opacity: 1,
          cursor: 'pointer',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
    ],
    [
      {
        cellValue: 'Gerardo Castillo',
        conditionalFormatting: {
          fontWeight: 'bold',
          backgroundColor: '',
          opacity: 1,
          width: '200px',
        },
      },
      {
        cellValue: '12.78',
        conditionalFormatting: {
          backgroundColor: '#c82424',
          opacity: 1,
          cursor: 'pointer',
          width: '120px',
        },
      },
      {
        cellValue: '5.35',
        conditionalFormatting: {
          backgroundColor: '#c82424',
          opacity: 1,
          cursor: 'pointer',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
    ],
    [
      {
        cellValue: 'Gerardo Soriano',
        conditionalFormatting: {
          fontWeight: 'bold',
          backgroundColor: '',
          opacity: 1,
          width: '200px',
        },
      },
      {
        cellValue: '-19.87',
        conditionalFormatting: {
          backgroundColor: '#28a56c',
          opacity: 1,
          cursor: 'pointer',
          width: '120px',
        },
      },
      {
        cellValue: '14.14',
        conditionalFormatting: {
          backgroundColor: '#c82424',
          opacity: 1,
          cursor: 'pointer',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
    ],
    [
      {
        cellValue: 'Gilbert Rodriguez',
        conditionalFormatting: {
          fontWeight: 'bold',
          backgroundColor: '',
          opacity: 1,
          width: '200px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
    ],
    [
      {
        cellValue: 'Haroll Gonzalez',
        conditionalFormatting: {
          fontWeight: 'bold',
          backgroundColor: '',
          opacity: 1,
          width: '200px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
    ],
    [
      {
        cellValue: 'Jeremy Gonzales',
        conditionalFormatting: {
          fontWeight: 'bold',
          backgroundColor: '',
          opacity: 1,
          width: '200px',
        },
      },
      {
        cellValue: '-7.71',
        conditionalFormatting: {
          backgroundColor: '#28a56c',
          opacity: 1,
          cursor: 'pointer',
          width: '120px',
        },
      },
      {
        cellValue: '-0.72',
        conditionalFormatting: {
          backgroundColor: '#28a56c',
          opacity: 1,
          cursor: 'pointer',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
    ],
    [
      {
        cellValue: 'Jon-Angel Cantu',
        conditionalFormatting: {
          fontWeight: 'bold',
          backgroundColor: '',
          opacity: 1,
          width: '200px',
        },
      },
      {
        cellValue: '2.05',
        conditionalFormatting: {
          backgroundColor: '#c82424',
          opacity: 1,
          cursor: 'pointer',
          width: '120px',
        },
      },
      {
        cellValue: '9.07',
        conditionalFormatting: {
          backgroundColor: '#c82424',
          opacity: 1,
          cursor: 'pointer',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
    ],
    [
      {
        cellValue: 'Jonathan Martinez',
        conditionalFormatting: {
          fontWeight: 'bold',
          backgroundColor: '',
          opacity: 1,
          width: '200px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
    ],
    [
      {
        cellValue: 'Jose Cabrera',
        conditionalFormatting: {
          fontWeight: 'bold',
          backgroundColor: '',
          opacity: 1,
          width: '200px',
        },
      },
      {
        cellValue: '11.11',
        conditionalFormatting: {
          backgroundColor: '#c82424',
          opacity: 1,
          cursor: 'pointer',
          width: '120px',
        },
      },
      {
        cellValue: '15.06',
        conditionalFormatting: {
          backgroundColor: '#c82424',
          opacity: 1,
          cursor: 'pointer',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
    ],
    [
      {
        cellValue: 'Jose Murillo',
        conditionalFormatting: {
          fontWeight: 'bold',
          backgroundColor: '',
          opacity: 1,
          width: '200px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
    ],
    [
      {
        cellValue: 'Joshua Jenkins',
        conditionalFormatting: {
          fontWeight: 'bold',
          backgroundColor: '',
          opacity: 1,
          width: '200px',
        },
      },
      {
        cellValue: '7.23',
        conditionalFormatting: {
          backgroundColor: '#c82424',
          opacity: 1,
          cursor: 'pointer',
          width: '120px',
        },
      },
      {
        cellValue: '-0.70',
        conditionalFormatting: {
          backgroundColor: '#28a56c',
          opacity: 1,
          cursor: 'pointer',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
    ],
    [
      {
        cellValue: 'Juan Santos',
        conditionalFormatting: {
          fontWeight: 'bold',
          backgroundColor: '',
          opacity: 1,
          width: '200px',
        },
      },
      {
        cellValue: '-21.62',
        conditionalFormatting: {
          backgroundColor: '#28a56c',
          opacity: 1,
          cursor: 'pointer',
          width: '120px',
        },
      },
      {
        cellValue: '-9.97',
        conditionalFormatting: {
          backgroundColor: '#28a56c',
          opacity: 1,
          cursor: 'pointer',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
    ],
    [
      {
        cellValue: 'Kelan Williams',
        conditionalFormatting: {
          fontWeight: 'bold',
          backgroundColor: '',
          opacity: 1,
          width: '200px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '45.44',
        conditionalFormatting: {
          backgroundColor: '#c82424',
          opacity: 1,
          cursor: 'pointer',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
    ],
    [
      {
        cellValue: 'Landan Crowder',
        conditionalFormatting: {
          fontWeight: 'bold',
          backgroundColor: '',
          opacity: 1,
          width: '200px',
        },
      },
      {
        cellValue: '2.87',
        conditionalFormatting: {
          backgroundColor: '#c82424',
          opacity: 1,
          cursor: 'pointer',
          width: '120px',
        },
      },
      {
        cellValue: '-19.05',
        conditionalFormatting: {
          backgroundColor: '#28a56c',
          opacity: 1,
          cursor: 'pointer',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
    ],
    [
      {
        cellValue: 'Manuel Vallejo',
        conditionalFormatting: {
          fontWeight: 'bold',
          backgroundColor: '',
          opacity: 1,
          width: '200px',
        },
      },
      {
        cellValue: '18.00',
        conditionalFormatting: {
          backgroundColor: '#c82424',
          opacity: 1,
          cursor: 'pointer',
          width: '120px',
        },
      },
      {
        cellValue: '4.82',
        conditionalFormatting: {
          backgroundColor: '#c82424',
          opacity: 1,
          cursor: 'pointer',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
    ],
    [
      {
        cellValue: 'Marquis Washington',
        conditionalFormatting: {
          fontWeight: 'bold',
          backgroundColor: '',
          opacity: 1,
          width: '200px',
        },
      },
      {
        cellValue: '-11.24',
        conditionalFormatting: {
          backgroundColor: '#28a56c',
          opacity: 1,
          cursor: 'pointer',
          width: '120px',
        },
      },
      {
        cellValue: '7.72',
        conditionalFormatting: {
          backgroundColor: '#c82424',
          opacity: 1,
          cursor: 'pointer',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
    ],
    [
      {
        cellValue: 'No Sit Inspection',
        conditionalFormatting: {
          fontWeight: 'bold',
          backgroundColor: '',
          opacity: 1,
          width: '200px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
    ],
    [
      {
        cellValue: 'Phillip Rodriguez',
        conditionalFormatting: {
          fontWeight: 'bold',
          backgroundColor: '',
          opacity: 1,
          width: '200px',
        },
      },
      {
        cellValue: '-2.51',
        conditionalFormatting: {
          backgroundColor: '#28a56c',
          opacity: 1,
          cursor: 'pointer',
          width: '120px',
        },
      },
      {
        cellValue: '13.15',
        conditionalFormatting: {
          backgroundColor: '#c82424',
          opacity: 1,
          cursor: 'pointer',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
    ],
    [
      {
        cellValue: 'Pierre Brim',
        conditionalFormatting: {
          fontWeight: 'bold',
          backgroundColor: '',
          opacity: 1,
          width: '200px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '27.29',
        conditionalFormatting: {
          backgroundColor: '#c82424',
          opacity: 1,
          cursor: 'pointer',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
    ],
    [
      {
        cellValue: 'Samuel Alvarado',
        conditionalFormatting: {
          fontWeight: 'bold',
          backgroundColor: '',
          opacity: 1,
          width: '200px',
        },
      },
      {
        cellValue: '8.03',
        conditionalFormatting: {
          backgroundColor: '#c82424',
          opacity: 1,
          cursor: 'pointer',
          width: '120px',
        },
      },
      {
        cellValue: '12.93',
        conditionalFormatting: {
          backgroundColor: '#c82424',
          opacity: 1,
          cursor: 'pointer',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
    ],
    [
      {
        cellValue: 'Santiago Alaniz',
        conditionalFormatting: {
          fontWeight: 'bold',
          backgroundColor: '',
          opacity: 1,
          width: '200px',
        },
      },
      {
        cellValue: '-21.89',
        conditionalFormatting: {
          backgroundColor: '#28a56c',
          opacity: 1,
          cursor: 'pointer',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
    ],
    [
      {
        cellValue: 'Sergio Cuevas',
        conditionalFormatting: {
          fontWeight: 'bold',
          backgroundColor: '',
          opacity: 1,
          width: '200px',
        },
      },
      {
        cellValue: '7.28',
        conditionalFormatting: {
          backgroundColor: '#c82424',
          opacity: 1,
          cursor: 'pointer',
          width: '120px',
        },
      },
      {
        cellValue: '6.50',
        conditionalFormatting: {
          backgroundColor: '#c82424',
          opacity: 1,
          cursor: 'pointer',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
    ],
    [
      {
        cellValue: 'Ulysses Davis',
        conditionalFormatting: {
          fontWeight: 'bold',
          backgroundColor: '',
          opacity: 1,
          width: '200px',
        },
      },
      {
        cellValue: '-17.44',
        conditionalFormatting: {
          backgroundColor: '#28a56c',
          opacity: 1,
          cursor: 'pointer',
          width: '120px',
        },
      },
      {
        cellValue: '0.99',
        conditionalFormatting: {
          backgroundColor: '#c82424',
          opacity: 1,
          cursor: 'pointer',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
    ],
    [
      {
        cellValue: 'null',
        conditionalFormatting: {
          fontWeight: 'bold',
          backgroundColor: '',
          opacity: 1,
          width: '200px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
      {
        cellValue: '-',
        conditionalFormatting: {
          backgroundColor: 'inherit',
          opacity: 1,
          cursor: 'default',
          width: '120px',
        },
      },
    ],
  ],
}

///////////////////////////////
// Container
///////////////////////////////

export const Container: React.FC = (): JSX.Element => {
  // Props

  // Hooks - useContext, useState, useReducer, other
  // { sort-start } - hooks
  const [us_clientsList, us_setClientsList] = useState<TsInterface_UnspecifiedObject>({})
  const [us_destinationClientKey, us_setDestinationClientKey] = useState<string>('')
  const [us_doc10, us_setDoc10] = useState<string>('')
  const [us_doc2, us_setDoc2] = useState<string>('')
  const [us_doc4, us_setDoc4] = useState<string>('')
  const [us_doc6, us_setDoc6] = useState<string>('')
  const [us_doc8, us_setDoc8] = useState<string>('')
  const [us_originClientKey, us_setOriginClientKey] = useState<string>('')
  const [us_originDatabase, us_setOriginDatabase] = useState<string>('staging')
  const [us_sub1, us_setSub1] = useState<string>('')
  const [us_sub3, us_setSub3] = useState<string>('')
  const [us_sub5, us_setSub5] = useState<string>('')
  const [us_sub7, us_setSub7] = useState<string>('')
  const [us_sub9, us_setSub9] = useState<string>('')
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  // { sort-end } - hooks

  // Hooks - useEffect
  useEffect(() => {
    document.title = rLIB('Test Lab', false) as string
  }, [])

  useEffect(() => {
    // moveProjectFieldFromSubcollectionToMainDocument( "jmorrell1", "0", 500 )
    // moveProjectFieldFromSubcollectionToMainDocument( "etw_energy", "Z377841510", 500 )
  }, [])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setClientsList(newData)
      ur_forceRerender()
    }
    unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_RootClient_Collection(), updateLiveData)
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [ur_forceRerender])

  // Variables

  // Functions

  // JSX Generation
  const rJSX_OriginDropdown = (): JSX.Element => {
    let dropdownJSX = (
      <FormControl
        className="bp_thin_select_input bp_thin_select_multiple_input"
        sx={{ minWidth: '130px', marginRight: '12px', marginBottom: '8px' }}
      >
        <Select
          onChange={(event, value) => {
            if (event != null && event.target != null && event.target.value != null) {
              us_setOriginDatabase(event.target.value)
            }
          }}
          value={us_originDatabase}
        >
          <MenuItem value={'staging'}>{rLIB('Staging')}</MenuItem>
          <MenuItem value={'prod'}>{rLIB('Production')}</MenuItem>
        </Select>
      </FormControl>
    )
    return dropdownJSX
  }

  const rJSX_OriginClientDropdown = (): JSX.Element => {
    let dropdownJSX = (
      <FormControl
        className="bp_thin_select_input bp_thin_select_multiple_input"
        sx={{ minWidth: '130px', marginRight: '12px', marginBottom: '8px' }}
      >
        <Select
          onChange={(event, value) => {
            if (event != null && event.target != null && event.target.value != null) {
              us_setOriginClientKey(event.target.value)
            }
          }}
          value={us_originClientKey || ''}
        >
          {objectToArray(us_clientsList)
            .sort(dynamicSort('key', null))
            .map((option: TsInterface_UnspecifiedObject) => (
              <MenuItem
                key={option['key']}
                value={option['key']}
              >
                {option['key']}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    )
    return dropdownJSX
  }

  const rJSX_DestinationClientDropdown = (): JSX.Element => {
    let dropdownJSX = (
      <FormControl
        className="bp_thin_select_input bp_thin_select_multiple_input"
        sx={{ minWidth: '130px', marginRight: '12px', marginBottom: '8px' }}
      >
        <Select
          onChange={(event, value) => {
            if (event != null && event.target != null && event.target.value != null) {
              us_setDestinationClientKey(event.target.value)
            }
          }}
          value={us_destinationClientKey || ''}
        >
          {objectToArray(us_clientsList)
            .sort(dynamicSort('key', null))
            .map((option: TsInterface_UnspecifiedObject) => (
              <MenuItem
                key={option['key']}
                value={option['key']}
              >
                {option['key']}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    )
    return dropdownJSX
  }

  const rJSX_DropdownS1 = (): JSX.Element => {
    let dropdownJSX = <></>
    const updateState = (value: string) => {
      us_setSub1(value)
      us_setDoc2('')
      us_setSub3('')
      us_setDoc4('')
      us_setSub5('')
      us_setDoc6('')
      us_setSub7('')
      us_setDoc8('')
      us_setSub9('')
      us_setDoc10('')
    }
    if (us_originClientKey !== '') {
      // Dropdown Options
      let dropdownOptions: TsInterface_UnspecifiedObject = {}
      if (databaseStructureOptions != null) {
        dropdownOptions = databaseStructureOptions
      }
      // Dropdown JSX
      dropdownJSX = (
        <FormControl
          className="bp_thin_select_input bp_thin_select_multiple_input"
          sx={{ minWidth: '130px', marginRight: '12px', marginBottom: '8px' }}
        >
          <Select
            onChange={(event, value) => {
              if (event != null && event.target != null && event.target.value != null) {
                updateState(event.target.value)
              }
            }}
            value={us_sub1 || ''}
          >
            {objectToArray(dropdownOptions)
              .sort(dynamicSort('key', null))
              .map((option: TsInterface_UnspecifiedObject, index: number) => (
                <MenuItem
                  key={index}
                  value={option['key']}
                >
                  {option['key']}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      )
    }
    return dropdownJSX
  }

  const rJSX_DropdownD2 = (): JSX.Element => {
    let dropdownJSX = <></>
    const updateState = (value: string) => {
      // us_setSub1( value )
      us_setDoc2(value)
      us_setSub3('')
      us_setDoc4('')
      us_setSub5('')
      us_setDoc6('')
      us_setSub7('')
      us_setDoc8('')
      us_setSub9('')
      us_setDoc10('')
    }
    if (us_originClientKey !== '' && us_sub1 !== '') {
      if (databaseStructureOptions != null && databaseStructureOptions[us_sub1] != null && databaseStructureOptions[us_sub1]['dynamic_doc_keys'] === true) {
        dropdownJSX = (
          <FormControl
            className="bp_thin_text_input"
            sx={{ width: '130px', marginTop: '0px !important', marginBottom: '0px !important' }}
          >
            <TextField
              className="bp_thin_text_input"
              value={us_doc2 || ''}
              margin="normal"
              type="text"
              sx={{ marginTop: '0px !important', marginBottom: '0px !important' }}
              onChange={(event) => {
                if (event != null && event.target != null && event.target.value != null) {
                  updateState(event.target.value)
                }
              }}
              onBlur={(event) => {
                if (event != null && event.target != null && event.target.value != null) {
                  updateState(event.target.value)
                }
              }}
              variant="outlined"
              InputLabelProps={{ shrink: true }}
            />
          </FormControl>
        )
      } else {
        // Dropdown Options
        let dropdownOptions: TsInterface_UnspecifiedObject = {}
        if (databaseStructureOptions != null && databaseStructureOptions[us_sub1] != null && databaseStructureOptions[us_sub1]['docs'] != null) {
          dropdownOptions = databaseStructureOptions[us_sub1]['docs']
        }
        // Dropdown JSX
        dropdownJSX = (
          <FormControl
            className="bp_thin_select_input bp_thin_select_multiple_input"
            sx={{ minWidth: '130px', marginRight: '12px', marginBottom: '8px' }}
          >
            <Select
              onChange={(event, value) => {
                if (event != null && event.target != null && event.target.value != null) {
                  updateState(event.target.value)
                }
              }}
              value={us_doc2 || ''}
            >
              {objectToArray(dropdownOptions)
                .sort(dynamicSort('key', null))
                .map((option: TsInterface_UnspecifiedObject, index: number) => (
                  <MenuItem
                    key={index}
                    value={option['key']}
                  >
                    {option['key']}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        )
      }
    }
    return dropdownJSX
  }

  const rJSX_DropdownS3 = (): JSX.Element => {
    let dropdownJSX = <></>
    const updateState = (value: string) => {
      // us_setSub1( value )
      // us_setDoc2( value )
      us_setSub3(value)
      us_setDoc4('')
      us_setSub5('')
      us_setDoc6('')
      us_setSub7('')
      us_setDoc8('')
      us_setSub9('')
      us_setDoc10('')
    }
    if (us_originClientKey !== '' && us_sub1 !== '' && us_doc2 !== '') {
      // Dropdown Options
      let dropdownOptions: TsInterface_UnspecifiedObject = {}
      if (
        databaseStructureOptions != null &&
        databaseStructureOptions[us_sub1] != null &&
        databaseStructureOptions[us_sub1]['docs'] != null &&
        databaseStructureOptions[us_sub1]['docs'][us_doc2] != null &&
        databaseStructureOptions[us_sub1]['docs'][us_doc2]['collections'] != null
      ) {
        dropdownOptions = databaseStructureOptions[us_sub1]['docs'][us_doc2]['collections']
        // } else if(
        // 	databaseStructureOptions != null &&
        // 	databaseStructureOptions[ us_sub1 ] != null &&
        // 	databaseStructureOptions[ us_sub1 ]["docs"] != null &&
        // 	databaseStructureOptions[ us_sub1 ]["docs"][ "$dynamicKey" ] != null &&
        // 	databaseStructureOptions[ us_sub1 ]["docs"][ "$dynamicKey" ]["collections"] != null
        // ){
        // 	dropdownOptions = databaseStructureOptions[ us_sub1 ]["docs"][ "$dynamicKey" ]["collections"]
      }
      // Dropdown JSX
      dropdownJSX = (
        <FormControl
          className="bp_thin_select_input bp_thin_select_multiple_input"
          sx={{ minWidth: '130px', marginRight: '12px', marginBottom: '8px' }}
        >
          <Select
            onChange={(event, value) => {
              if (event != null && event.target != null && event.target.value != null) {
                updateState(event.target.value)
              }
            }}
            value={us_sub3 || ''}
          >
            {objectToArray(dropdownOptions)
              .sort(dynamicSort('key', null))
              .map((option: TsInterface_UnspecifiedObject, index: number) => (
                <MenuItem
                  key={index}
                  value={option['key']}
                >
                  {option['key']}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      )
    }
    return dropdownJSX
  }

  const rJSX_DropdownD4 = (): JSX.Element => {
    let dropdownJSX = <></>
    const updateState = (value: string) => {
      // us_setSub1( value )
      // us_setDoc2( value )
      // us_setSub3( value )
      us_setDoc4(value)
      us_setSub5('')
      us_setDoc6('')
      us_setSub7('')
      us_setDoc8('')
      us_setSub9('')
      us_setDoc10('')
    }
    if (us_originClientKey !== '' && us_sub1 !== '' && us_doc2 !== '' && us_sub3 !== '') {
      if (
        databaseStructureOptions != null &&
        databaseStructureOptions[us_sub1] != null &&
        databaseStructureOptions[us_sub1]['docs'] != null &&
        databaseStructureOptions[us_sub1]['docs'][us_doc2] != null &&
        databaseStructureOptions[us_sub1]['docs'][us_doc2]['collections'] != null &&
        databaseStructureOptions[us_sub1]['docs'][us_doc2]['collections'][us_sub3] != null &&
        databaseStructureOptions[us_sub1]['docs'][us_doc2]['collections'][us_sub3]['dynamic_doc_keys'] === true
      ) {
        dropdownJSX = (
          <FormControl
            className="bp_thin_text_input"
            sx={{ width: '130px', marginTop: '0px !important', marginBottom: '0px !important' }}
          >
            <TextField
              className="bp_thin_text_input"
              value={us_doc4 || ''}
              margin="normal"
              type="text"
              sx={{ marginTop: '0px !important', marginBottom: '0px !important' }}
              onChange={(event) => {
                if (event != null && event.target != null && event.target.value != null) {
                  updateState(event.target.value)
                }
              }}
              onBlur={(event) => {
                if (event != null && event.target != null && event.target.value != null) {
                  updateState(event.target.value)
                }
              }}
              variant="outlined"
              InputLabelProps={{ shrink: true }}
            />
          </FormControl>
        )
      } else {
        // Dropdown Options
        let dropdownOptions: TsInterface_UnspecifiedObject = {}
        if (
          databaseStructureOptions != null &&
          databaseStructureOptions[us_sub1] != null &&
          databaseStructureOptions[us_sub1]['docs'] != null &&
          databaseStructureOptions[us_sub1]['docs'][us_doc2] != null &&
          databaseStructureOptions[us_sub1]['docs'][us_doc2]['collections'] != null &&
          databaseStructureOptions[us_sub1]['docs'][us_doc2]['collections'][us_sub3] != null &&
          databaseStructureOptions[us_sub1]['docs'][us_doc2]['collections'][us_sub3]['docs'] != null
        ) {
          dropdownOptions = databaseStructureOptions[us_sub1]['docs'][us_doc2]['collections'][us_sub3]['docs']
          // } else if(
          // 	databaseStructureOptions != null &&
          // 	databaseStructureOptions[ us_sub1 ] != null &&
          // 	databaseStructureOptions[ us_sub1 ]["docs"] != null &&
          // 	databaseStructureOptions[ us_sub1 ]["docs"][ "$dynamicKey" ] != null &&
          // 	databaseStructureOptions[ us_sub1 ]["docs"][ "$dynamicKey" ]["collections"] != null &&
          // 	databaseStructureOptions[ us_sub1 ]["docs"][ "$dynamicKey" ]["collections"][ us_sub3 ] != null &&
          // 	databaseStructureOptions[ us_sub1 ]["docs"][ "$dynamicKey" ]["collections"][ us_sub3 ]["docs"] != null
          // ){
          // 	dropdownOptions = databaseStructureOptions[ us_sub1 ]["docs"][ "$dynamicKey" ]["collections"]
        }
        // D

        // Dropdown JSX
        dropdownJSX = (
          <FormControl
            className="bp_thin_select_input bp_thin_select_multiple_input"
            sx={{ minWidth: '130px', marginRight: '12px', marginBottom: '8px' }}
          >
            <Select
              onChange={(event, value) => {
                if (event != null && event.target != null && event.target.value != null) {
                  updateState(event.target.value)
                }
              }}
              value={us_doc4 || ''}
            >
              {objectToArray(dropdownOptions)
                .sort(dynamicSort('key', null))
                .map((option: TsInterface_UnspecifiedObject, index: number) => (
                  <MenuItem
                    key={index}
                    value={option['key']}
                  >
                    {option['key']}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        )
      }
    }
    return dropdownJSX
  }

  const rJSX_DropdownS5 = (): JSX.Element => {
    let dropdownJSX = <></>

    return dropdownJSX
  }

  const rJSX_DropdownD6 = (): JSX.Element => {
    let dropdownJSX = <></>

    return dropdownJSX
  }

  const rJSX_DropdownS7 = (): JSX.Element => {
    let dropdownJSX = <></>

    return dropdownJSX
  }

  const rJSX_DropdownD8 = (): JSX.Element => {
    let dropdownJSX = <></>

    return dropdownJSX
  }

  const rJSX_DropdownS9 = (): JSX.Element => {
    let dropdownJSX = <></>

    return dropdownJSX
  }

  const rJSX_DropdownD10 = (): JSX.Element => {
    let dropdownJSX = <></>

    return dropdownJSX
  }

  // const rJSX_ClientKeyDropdown = (): JSX.Element => {
  // let dropdownJSX =
  // <FormControl className="bp_thin_select_input bp_thin_select_multiple_input" sx={{ minWidth: "130px", marginRight: "12px", marginBottom: "8px" }}>
  // 	<Select
  // 		onChange={ ( event, value ) => {

  // 		} }
  // 		value={ conditionLine.variable_2 || "" }
  // 	>
  // 		{ objectToArray( calculationData.variables ).sort( dynamicSort( "name", null )).map(( option: TsInterface_UnspecifiedObject ) => (
  // 			<MenuItem key={ option["key"] } value={ option["key"] } disabled={ option["status"] === "inactive" }>
  // 				{ option["name"] }{ variableTrace2 }
  // 			</MenuItem>
  // 		)) }
  // 	</Select>
  // </FormControl>
  // return dropdownJSX
  // }

  const rJSX_DatabaseManagementTab = (): JSX.Element => {
    return (
      <Box>
        <Card className="tw-p-4">
          <Typography
            variant="h6"
            className="tw-mb-2"
          >
            {rLIB('Database Management Utility')}
          </Typography>
          <Typography
            variant="body1"
            className="tw-opacity-40 tw-mt-4"
          ></Typography>
          <Divider className="tw-my-2" />
          <Box className="tw-ml-4">
            <Typography
              variant="body1"
              className="tw-mb-2 tw-opacity-40"
            >
              {rLIB('Copy From')}:
            </Typography>
            {rJSX_OriginDropdown()}
            {rJSX_OriginClientDropdown()}

            {rJSX_DropdownS1()}
            {rJSX_DropdownD2()}
            {rJSX_DropdownS3()}
            {rJSX_DropdownD4()}
            {rJSX_DropdownS5()}
            {rJSX_DropdownD6()}
            {rJSX_DropdownS7()}
            {rJSX_DropdownD8()}
            {rJSX_DropdownS9()}
            {rJSX_DropdownD10()}
          </Box>
          <Box className="tw-ml-4">
            <Typography
              variant="body1"
              className="tw-mb-2 tw-opacity-40"
            >
              {rLIB('Copy To')}:
            </Typography>
            {rJSX_DestinationClientDropdown()}
          </Box>
        </Card>
        <Json
          data={{
            us_originDatabase: us_originDatabase,
            us_originClientKey: us_originClientKey,
            us_destinationClientKey: us_destinationClientKey,
            us_sub1: us_sub1,
            us_doc2: us_doc2,
            us_sub3: us_sub3,
            us_doc4: us_doc4,
            us_sub5: us_sub5,
            us_doc6: us_doc6,
            us_sub7: us_sub7,
            us_doc8: us_doc8,
            us_sub9: us_sub9,
            us_doc10: us_doc10,
          }}
        />
      </Box>
    )
  }

  const transformDataToMatchDummyFormat = (data: any): any => {
    const extractTextFromCellValue = (cellValue: any): string => {
      if (typeof cellValue === 'string' || typeof cellValue === 'number') {
        return String(cellValue) // Convert numbers to strings
      } else if (cellValue && cellValue.props && cellValue.props.children) {
        if (Array.isArray(cellValue.props.children)) {
          return cellValue.props.children.map((child: any) => (typeof child === 'string' || typeof child === 'number' ? String(child) : '')).join('')
        } else {
          return typeof cellValue.props.children === 'string' || typeof cellValue.props.children === 'number' ? String(cellValue.props.children) : ''
        }
      }
      return ''
    }

    const darkenRedShade = (color: string): string => {
      if (color === '#c82424') {
        // Check for the original red color
        return '#eb4747' // Set to a slightly darker red shade
      }
      return color
    }

    return {
      summary_rows: data.summary_rows.map((row: any[]) =>
        row.map((cell: any) => ({
          cellValue: extractTextFromCellValue(cell.cellValue),
          conditionalFormatting: {
            ...cell.conditionalFormatting,
            fontWeight: 'bold', // Ensure summary rows are bolded
            color: '#000000', // Set text color to black
            backgroundColor: darkenRedShade(cell.conditionalFormatting.backgroundColor), // Adjust red background color
            paddingLeft: '10px', // Add left padding to cells
          },
        })),
      ),
      data_rows: data.data_rows.map((row: any[]) =>
        row.map((cell: any) => {
          const newFormatting = { ...cell.conditionalFormatting }
          delete newFormatting.fontWeight // Remove bolding from data rows
          newFormatting.color = '#000000' // Set text color to black
          newFormatting.backgroundColor = darkenRedShade(newFormatting.backgroundColor) // Adjust red background color
          newFormatting.paddingLeft = '10px' // Add left padding to cells

          return {
            cellValue: extractTextFromCellValue(cell.cellValue),
            conditionalFormatting: newFormatting,
          }
        }),
      ),
    }
  }

  function fetchAndProcessSafetyReviewData(clientKey: any, startDate: Date, endDate: Date, mondaysInDateRange: any) {
    return DatabaseGetCollection(DatabaseRef_SafetyReviewByDate_Query(clientKey, startDate, endDate))
      .then((rawSafetyReviewData) => {
        const processedData = processSafetyReviewData(rawSafetyReviewData.data, mondaysInDateRange)

        return transformDataToMatchDummyFormat(processedData)
      })
      .then((transformedData) => {
        return transformedData
      })
      .catch((error) => {
        console.error('Error fetching and processing safety review data:', error)
        throw error
      })
  }

  function fetchAndProcessPanelsInstalledData(clientKey: any, startDate: Date, endDate: Date, mondaysInDateRange: string[], organizedTimeOff: any) {
    return DatabaseGetCollection(DatabaseRef_PanelsInstalledByDate_Query(clientKey, startDate, endDate))
      .then((rawPanelsInstalledData) => {
        console.log('Raw panels installed data .data:', JSON.stringify(rawPanelsInstalledData.data, null, 2))
        console.log('Mondays in date range:', mondaysInDateRange)
        console.log('Organized time off:', JSON.stringify(organizedTimeOff, null, 2))

        const processedData = processPanelsInstalledData(rawPanelsInstalledData.data, mondaysInDateRange, organizedTimeOff)

        return transformDataToMatchDummyFormat(processedData)
      })
      .then((transformedData) => {
        return transformedData
      })
      .catch((error) => {
        console.error('Error fetching and processing panels installed data:', error)
        throw error
      })
  }

  function fetchAndOrganizeTimeOffData(clientKey: any, startDate: Date, endDate: Date, mondaysInDateRange: string[]) {
    return DatabaseGetCollection(DatabaseRef_ScheduledTimeOff_Query(clientKey, startDate, endDate))
      .then((rawTimeOffData) => {
        const organizedTimeOffData = organizeTimeOffByWeekAndTeam(rawTimeOffData.data, mondaysInDateRange)

        return organizedTimeOffData
      })
      .catch((error) => {
        console.error('Error fetching and organizing time off data:', error)
        throw error
      })
  }

  function organizeTimeOffByWeekAndTeam(us_timeOff: any, us_mondaysInDateRange: string[]): any {
    const timeOffByWeekAndTeam: any = {}

    us_mondaysInDateRange.forEach((monday: string) => {
      timeOffByWeekAndTeam[monday] = {} // Initialize week object
    })

    Object.values(us_timeOff).forEach((timeOffEntry: any) => {
      const timeOffDate = new Date(timeOffEntry.timestamp_time_off.seconds * 1000)
      const timeOffDateKey = returnFormattedDateKey(timeOffDate)

      const weekStart = us_mondaysInDateRange.find((monday: string) => {
        const weekStartDate = new Date(monday)
        const weekEndDate = new Date(weekStartDate)
        weekEndDate.setDate(weekEndDate.getDate() + 6) // End of the week (Sunday)

        return timeOffDate >= weekStartDate && timeOffDate <= weekEndDate
      })

      if (weekStart) {
        const teamKey = timeOffEntry.associated_team_key
        if (!timeOffByWeekAndTeam[weekStart][teamKey]) {
          timeOffByWeekAndTeam[weekStart][teamKey] = {
            associated_team_key: teamKey,
            associated_team_name: timeOffEntry.associated_team_name,
            time_off_type: timeOffEntry.time_off_type,
            days_off: [],
          }
        }

        timeOffByWeekAndTeam[weekStart][teamKey].days_off.push(timeOffDateKey)
      }
    })

    return timeOffByWeekAndTeam
  }
  function fetchAndOrganizeSalesPartnersData(clientKey: string): Promise<any> {
    return new Promise((resolve, reject) => {
      if (!clientKey) {
        reject('No client key provided')
        return
      }

      DatabaseGetLiveCollection(DatabaseRef_SalesPartner_Collection(clientKey), (newData: any) => {
        resolve(newData)
      })
    })
  }

  function fetchAndProcessCycleTimeData(clientKey: string, startDate: Date, endDate: Date, mondaysInDateRange: string[], salesPartners: any): Promise<any> {
    return new Promise((resolve, reject) => {
      if (!clientKey || !salesPartners) {
        reject('No client key or sales partners data provided')
        return
      }

      DatabaseGetLiveCollection(DatabaseRef_CycleTimeByDate_Query(clientKey, startDate, endDate), (newData: any) => {
        const enrichedData = Object.keys(newData).reduce((acc: any, dateKey: any) => {
          const dailyData = newData[dateKey].data
          const enrichedDailyData = Object.keys(dailyData).reduce((accInner: any, projectKey: any) => {
            const projectData = dailyData[projectKey]
            const salesPartnerKey = projectData.associated_sales_partner_key

            // Display the key and name for debugging
            const salesPartnerName = salesPartners[salesPartnerKey]?.name || 'Unknown Partner'

            accInner[projectKey] = { ...projectData, salesPartnerName }
            return accInner
          }, {})
          acc[dateKey] = { ...newData[dateKey], data: enrichedDailyData }
          return acc
        }, {})

        // Process the data and then apply the formatting
        const processedData = processCycleTimeRtgToInstallData(enrichedData, mondaysInDateRange, salesPartners, 4)
        const formattedData = transformDataToMatchDummyFormat(processedData)

        resolve(formattedData)
      })
    })
  }

  function generateMondaysInDateRange(startDate: Date, endDate: Date): string[] {
    let currentMonday = new Date(startDate)

    // Adjust to the first Monday on or after the start date
    while (currentMonday.getDay() !== 1) {
      currentMonday.setDate(currentMonday.getDate() + 1)
    }

    const mondaysInRange = []

    // Loop through the date range and collect all Mondays
    while (currentMonday <= endDate) {
      mondaysInRange.push(currentMonday.toISOString().split('T')[0])

      // Move to the next Monday
      currentMonday.setDate(currentMonday.getDate() + 7)
    }

    return mondaysInRange
  }

  const rJSX_EmailTab = (): JSX.Element => {
    const currentDate = new Date()

    const startDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - currentDate.getDay() - 6) // Previous Monday
    const endDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() + (7 - currentDate.getDay())) // End of the current week (Sunday)

    const mondaysInDateRange = generateMondaysInDateRange(startDate, endDate)

    let panelsInstalledData: any
    let safetyReviewData: any
    let cycleTimeData: any
    let salesPartnersData: any

    // Fetch and organize the Sales Partners data first
    fetchAndOrganizeSalesPartnersData('etw_energy')
      .then((organizedSalesPartnersData) => {
        salesPartnersData = organizedSalesPartnersData

        // Now that Sales Partners data is loaded, fetch and process Cycle Time data
        return fetchAndProcessCycleTimeData('etw_energy', startDate, endDate, mondaysInDateRange, salesPartnersData)
      })
      .then((processedCycleTimeData) => {
        cycleTimeData = processedCycleTimeData

        // Fetch and organize the time off data next
        return fetchAndOrganizeTimeOffData('etw_energy', startDate, endDate, mondaysInDateRange)
      })
      .then((organizedTimeOffData) => {
        // Fetch and process panels installed data
        return fetchAndProcessPanelsInstalledData('etw_energy', startDate, endDate, mondaysInDateRange, organizedTimeOffData)
      })
      .then((processedPanelsInstalledData) => {
        panelsInstalledData = processedPanelsInstalledData

        // Finally, fetch and process safety review data
        return fetchAndProcessSafetyReviewData('etw_energy', startDate, endDate, mondaysInDateRange)
      })
      .then((processedSafetyReviewData) => {
        safetyReviewData = processedSafetyReviewData
      })
      .catch((error) => {
        console.error('Error fetching data:', error)
      })

    return (
      <Box>
        <Box
          className="ql-scroll-html-render"
          dangerouslySetInnerHTML={{
            __html: generateHtmlForEmailFromTemplateObject(convertGmDataToEmailTemplateFormat([panelsInstalledData, safetyReviewData, cycleTimeData]), {}),
          }}
        />

        <Button
          onClick={() => {
            cloudFunctionManageRequest('manageEmails', {
              function: 'sendGMDashboardWeeklyEmail',
              to: ['tyler.colson@etwenergy.com'],
              subject: 'GM Dashboard Email',
              cc: [],
              bcc: [],
            })
              .then((res) => {})
              .catch((rej) => {
                console.error('Error sending email:', rej)
              })
          }}
        >
          SEND EMAIL
        </Button>

        {/* <Button
          onClick={() => {
            cloudFunctionManageRequest('manageEmails', {
              function: 'sendSendgridHtmlEmail',
              to: ['tyler.colson@etwenergy.com'], // Email sent only to you
              subject: 'Test GM Dashboard Email',
              html: generateHtmlForEmailFromTemplateObject(convertGmDataToEmailTemplateFormat([panelsInstalledData, safetyReviewData, cycleTimeData]), {}),
              cc: [],
              bcc: [],
            })
              .then((res) => {})
              .catch((rej) => {
                console.error('Error sending email:', rej)
              })
          }}
        >
          SEND EMAIL
        </Button> */}
      </Box>
    )
  }

  const rJSX_Page = (): JSX.Element => {
    let pageJSX = (
      <AuthenticatedContainer
        pageHeader={rLIB('Test Lab')}
        pageKey={pageKey}
        content={
          <Box>
            <TabsUrl
              tabs={[
                {
                  tabOnChange: () => {},
                  tabUrlKey: 'email',
                  tabHeader: 'HTML Email',
                  tabButtons: [],
                  tabContent: rJSX_EmailTab(),
                },
                {
                  tabOnChange: () => {},
                  tabUrlKey: 'database_management',
                  tabHeader: 'Database Management',
                  tabButtons: [],
                  tabContent: rJSX_DatabaseManagementTab(),
                },
              ]}
              tabsSettings={{
                baseUrl: ApplicationPages.SuperTestLabPage.url(),
                tabQueryParam: 'tab',
                overridePageTitle: true,
                basePageTitle: rLIB('Test Lab', false) as string,
              }}
            />
          </Box>
        }
      />
    )
    return pageJSX
  }

  // Render
  return <>{rJSX_Page()}</>
}

/*
		DB Origin (prod or staging)
		DB Origin path length
		DB Destination (only staging) - select client
	*/
