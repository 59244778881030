///////////////////////////////

import { themeVariables } from 'rfbp_aux/config/app_theme'
import { objectToArray } from '../../../../rfbp_core/services/helper_functions/index'

///////////////////////////////
// Imports
///////////////////////////////

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Exports
///////////////////////////////

// TEMP
export const processPayInefficiencyData = (rawCrewHours: any, mondaysInDateRange: string[]): { summary_rows: any[]; data_rows: any[] } => {
  const today = new Date()
  today.setHours(0, 0, 0, 0) // Normalize today's date

  // Function to get the previous Monday
  const getPreviousMonday = (date: Date) => {
    const currentDate = new Date(date.getTime())
    const day = currentDate.getDay()
    const diff = day === 0 ? 6 : day - 1
    currentDate.setDate(currentDate.getDate() - diff)
    currentDate.setHours(0, 0, 0, 0)
    return currentDate
  }

  // Get the previous Monday
  const previousMondayDate = getPreviousMonday(new Date(new Date().setDate(new Date().getDate() - 7)))
  const previousMondayString = previousMondayDate.toISOString().split('T')[0]

  const formattedData: any = {
    summary_rows: [],
    data_rows: [],
  }

  const dateHeaders = [{ cellValue: 'Pay Inefficiency', conditionalFormatting: { fontWeight: 'bold', width: '200px' } }]
  const totals: any = {}
  const personData: any = {}

  // Initialize the headers for the data rows
  objectToArray(mondaysInDateRange).forEach((monday: string) => {
    const date = new Date(monday)
    date.setUTCDate(date.getUTCDate() + 1)
    const formattedDateKey = monday
    const formattedDate = date.toLocaleDateString('en-US', { month: 'short', day: '2-digit' })

    totals[formattedDateKey] = null

    dateHeaders.push({
      cellValue: formattedDate,
      conditionalFormatting: { fontWeight: 'bold', width: '120px' },
    })
  })

  // Process the raw crew hours data
  Object.entries(rawCrewHours || {}).forEach(([dateStr, weekData]: [string, any]) => {
    const formattedDateKey = dateStr

    Object.entries(weekData.data || {}).forEach(([personId, details]: [string, any]) => {
      if (details && 'pay_inefficiency' in details && 'name' in details) {
        const payInefficiency = details.pay_inefficiency || null

        if (!personData[details.name]) {
          personData[details.name] = { details: {} }
          objectToArray(mondaysInDateRange).forEach((monday: string) => {
            personData[details.name].details[monday] = null
          })
        }

        personData[details.name].details[formattedDateKey] = payInefficiency

        if (payInefficiency !== null) {
          if (totals[formattedDateKey] === null) {
            totals[formattedDateKey] = 0
          }
          totals[formattedDateKey] += payInefficiency
        }
      }
    })
  })

  // Filter out people who have null for all weeks
  const filteredPersons = Object.keys(personData).filter((personName) => {
    return mondaysInDateRange.some((monday) => personData[personName].details[monday] !== null)
  })

  // Sort by the previous week's pay inefficiency
  const sortedPersons = filteredPersons.sort((a, b) => {
    const valueA = personData[a].details[previousMondayString]
    const valueB = personData[b].details[previousMondayString]

    if (valueA === null || valueA === undefined) return 1
    if (valueB === null || valueB === undefined) return -1
    return valueB - valueA
  })

  // Build the rows
  sortedPersons.forEach((personName: string) => {
    const rowData = [
      {
        cellValue: personName,
        conditionalFormatting: { fontWeight: 'bold', backgroundColor: '', opacity: 1, width: '200px' },
      },
    ]
    objectToArray(mondaysInDateRange).forEach((monday: string) => {
      const payInefficiencyThisWeek = personData[personName].details[monday]
      const isDefined = payInefficiencyThisWeek !== null && !isNaN(Number(payInefficiencyThisWeek))
      const isFuture = new Date(monday) > today
      rowData.push({
        cellValue: isDefined ? Number(payInefficiencyThisWeek).toFixed(2) : '-', // Just return the number without dollar sign
        conditionalFormatting: {
          fontWeight: 'normal',
          backgroundColor: isDefined
            ? Number(payInefficiencyThisWeek) > 0
              ? themeVariables.error_main
              : Number(payInefficiencyThisWeek) < 0
                ? themeVariables.success_main
                : ''
            : 'inherit',
          opacity: isFuture ? 0.5 : 1,
          width: '120px',
        },
      })
    })
    formattedData.data_rows.push(rowData)
  })

  // Add the totals row
  formattedData.summary_rows.push(dateHeaders)
  const totalMetricsRow = [
    {
      cellValue: 'Total',
      conditionalFormatting: { fontWeight: 'bold', backgroundColor: '', opacity: 1, width: '200px' },
    },
  ]
  objectToArray(mondaysInDateRange).forEach((monday: string) => {
    const totalValue = totals[monday] !== null && !isNaN(totals[monday]) ? totals[monday].toFixed(2) : '-'
    const isFuture = new Date(monday) > today
    totalMetricsRow.push({
      cellValue: totalValue !== '-' ? totalValue : '-',
      conditionalFormatting: {
        fontWeight: 'normal',
        backgroundColor: totalValue > 0 ? themeVariables.error_main : totalValue < 0 ? themeVariables.success_main : '',
        opacity: isFuture ? 0.5 : 1,
        width: '120px',
      },
    })
  })
  formattedData.summary_rows.push(totalMetricsRow)

  return formattedData
}
