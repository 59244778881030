///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			Return folders for documents page in project view page

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Trans } from 'react-i18next'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import { DatabaseRef_Project_Documents_Collection, DatabaseRef_Project_Documents_Document } from 'rfbp_aux/services/database_endpoints/operations/projects'
import { StorageRef_ProjectDocumentsFile } from 'rfbp_aux/services/storage_endpoints/projects'
import { rLIB } from 'rfbp_core/localization/library'
import { DatabaseAddDocument, DatabaseSetMergeDocument, StorageUploadFile } from 'rfbp_core/services/database_management'
import { getProp, returnFormattedDate } from 'rfbp_core/services/helper_functions'
import { TsInterface_UnspecifiedObject, TsType_UnknownPromise } from 'rfbp_core/typescript/global_types'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

// Displayed Translatable Strings
// { sort-start } - displayed text - scoped sort plugin
const s_FAILED_TO_IMPORT_FILE: JSX.Element = <Trans>Failed to import file</Trans>
const s_MISSING_REQUIRED_PARAMETERS: JSX.Element = <Trans>Missing required parameters</Trans>
// { sort-end } - displayed text

///////////////////////////////
// Functions
///////////////////////////////

// TEMP
export const returnProjectDocumentFolders = (clientKey: string | null): TsInterface_UnspecifiedObject => {
  let projectFolders: TsInterface_UnspecifiedObject = {}
  switch (clientKey) {
    // case "clientKey":
    default:
      projectFolders = {
        agreements: {
          name: 'Agreements',
          subtitle: '(NEM agreement, call consent, Costco agreements)',
          key: 'agreements',
          subcontractor_visibility: {
            subcontractor_construction: true,
            subcontractor_design: true,
            subcontractor_permitting: true,
            subcontractor_warehouse: true,
          },
        },
        costco: {
          name: 'Costco',
          subtitle: '',
          key: 'costco',
          subcontractor_visibility: {
            subcontractor_construction: true,
            subcontractor_design: true,
            subcontractor_permitting: true,
            subcontractor_warehouse: true,
          },
        },
        design: {
          name: 'Design',
          subtitle: '(Plant set, Construction document, Single line diagram)',
          key: 'design',
          subcontractor_visibility: {
            subcontractor_construction: true,
            subcontractor_design: true,
            subcontractor_permitting: true,
            subcontractor_warehouse: true,
          },
        },
        hoa: {
          name: 'HOA',
          subtitle: '',
          key: 'hoa',
          subcontractor_visibility: {
            subcontractor_construction: true,
            subcontractor_design: true,
            subcontractor_permitting: true,
            subcontractor_warehouse: true,
          },
        },
        inspections: {
          name: 'Inspections',
          subtitle: '',
          key: 'inspections',
          subcontractor_visibility: {
            subcontractor_construction: true,
            subcontractor_design: true,
            subcontractor_permitting: true,
            subcontractor_warehouse: true,
          },
        },
        interconnection: {
          name: 'Interconnection',
          subtitle: '',
          key: 'interconnection',
          subcontractor_visibility: {
            subcontractor_construction: true,
            subcontractor_design: true,
            subcontractor_permitting: true,
            subcontractor_warehouse: true,
          },
        },
        loan: {
          name: 'Loan',
          subtitle: '',
          key: 'loan',
          subcontractor_visibility: {
            subcontractor_construction: true,
            subcontractor_design: true,
            subcontractor_permitting: true,
            subcontractor_warehouse: true,
          },
        },
        permits: {
          name: 'Permits',
          subtitle: '(Utility and City )',
          key: 'permits',
          subcontractor_visibility: {
            subcontractor_construction: true,
            subcontractor_design: true,
            subcontractor_permitting: true,
            subcontractor_warehouse: true,
          },
        },
        permitting_packet: {
          name: 'Permitting Packet',
          subtitle: '(Permitting Packet )',
          key: 'permitting_packet',
          subcontractor_visibility: {
            subcontractor_construction: true,
            subcontractor_design: true,
            subcontractor_permitting: true,
            subcontractor_warehouse: true,
          },
        },
        proposals: {
          name: 'Proposals',
          subtitle: '(Proposals, Change orders, Solar Access Record)',
          key: 'proposals',
          subcontractor_visibility: {
            subcontractor_construction: true,
            subcontractor_design: true,
            subcontractor_permitting: true,
            subcontractor_warehouse: true,
          },
        },
        site_audit: {
          name: 'Site Audit',
          subtitle: '(Outputs)',
          key: 'site_audit',
          subcontractor_visibility: {
            subcontractor_construction: true,
            subcontractor_design: true,
            subcontractor_permitting: true,
            subcontractor_warehouse: true,
          },
        },
        sow_documents: {
          name: 'Additional Work Documents',
          subtitle: '(bids, invoices)',
          key: 'sow_documents',
          subcontractor_visibility: {
            subcontractor_construction: true,
            subcontractor_design: true,
            subcontractor_permitting: true,
            subcontractor_warehouse: true,
          },
        },
        utility: {
          name: 'Utility',
          subtitle: '',
          key: 'utility',
          subcontractor_visibility: {
            subcontractor_construction: true,
            subcontractor_design: true,
            subcontractor_permitting: true,
            subcontractor_warehouse: true,
          },
        },
      }
  }
  return projectFolders
}

export const importProjectDocument = (
  clientKey: string,
  userName: string,
  userKey: string,
  projectKey: string,
  folderKey: string,
  file: Blob | Uint8Array | ArrayBuffer,
  initialFileName: string,
  fileSize: number, // Capture the file size passed from uploadProjectDocument
): TsType_UnknownPromise => {
  return new Promise((resolve, reject) => {
    if (clientKey != null && projectKey != null && folderKey != null) {
      let dateString = returnFormattedDate(new Date(), 'MM-DD-YYYY HH:mm:ss')
      let nameWithoutType = initialFileName.substring(0, initialFileName.lastIndexOf('.'))
      let fileType = initialFileName.substring(initialFileName.lastIndexOf('.') + 1, initialFileName.length)
      let fileName = nameWithoutType + ' (' + dateString + ').' + fileType

      // Upload the file
      StorageUploadFile(StorageRef_ProjectDocumentsFile(clientKey, projectKey, folderKey, fileName), file, {
        clientKey: clientKey,
        projectKey: projectKey,
        folderKey: folderKey,
      })
        .then((res_SUF: any) => {
          // Add the file size to the document metadata
          let projectUpdateObject: TsInterface_UnspecifiedObject = {
            file_name_full: fileName,
            file_name: initialFileName,
            file_type: fileType,
            file_size: fileSize, // Store file size in bytes
            timestamp_uploaded: new Date(),
            associated_uploader_name: userName,
            associated_uploader_key: userKey,
            url: getProp(res_SUF, 'url', null),
            status: 'active',
            visible_to_customer: false,
          }

          // Store metadata in Firestore
          DatabaseAddDocument(DatabaseRef_Project_Documents_Collection(clientKey, projectKey, folderKey), projectUpdateObject, true)
            .then((res_DAD) => {
              resolve(res_DAD)
            })
            .catch((rej_DAD) => {
              reject(rej_DAD)
            })
        })
        .catch((rej_SUF) => {
          reject(rej_SUF)
        })
    } else {
      reject({
        success: false,
        error: {
          message: s_FAILED_TO_IMPORT_FILE,
          details: s_MISSING_REQUIRED_PARAMETERS,
          code: 'ER-D-PDF-IPD-01',
        },
      })
    }
  })
}

export const deleteProjectDocument = (clientKey: string, projectKey: string, folderKey: string, fileKey: string): TsType_UnknownPromise => {
  return new Promise((resolve, reject) => {
    if (clientKey != null && projectKey != null && folderKey != null && fileKey != null) {
      let projectUpdateObject: TsInterface_UnspecifiedObject = {
        status: 'deleted',
      }
      DatabaseSetMergeDocument(DatabaseRef_Project_Documents_Document(clientKey, projectKey, folderKey, fileKey), projectUpdateObject)
        .then((res_DSMD) => {
          resolve(res_DSMD)
        })
        .catch((rej_DSMD) => {
          reject(rej_DSMD)
        })
    } else {
      reject({
        success: false,
        error: {
          message: rLIB('Failed to delete file'),
          details: s_MISSING_REQUIRED_PARAMETERS,
          code: 'ER-D-PDF-DPD-01',
        },
      })
    }
  })
}

export const returnIconKeyForFileType = (fileType: string): { icon: string; color: string } => {
  let iconKey = { icon: 'file', color: themeVariables.white }
  switch (fileType) {
    case 'pdf':
      iconKey = { icon: 'file-pdf', color: themeVariables.error_main }
      break
    case 'doc':
      iconKey = { icon: 'file-word', color: themeVariables.info_main }
      break
    case 'docx':
      iconKey = { icon: 'file-word', color: themeVariables.info_main }
      break
    case 'ppt':
      iconKey = { icon: 'file-powerpoint', color: themeVariables.error_main }
      break
    case 'pptx':
      iconKey = { icon: 'file-powerpoint', color: themeVariables.error_main }
      break
    case 'xlsx':
      iconKey = { icon: 'file-excel', color: themeVariables.success_main }
      break
    case 'csv':
      iconKey = { icon: 'file-csv', color: themeVariables.success_main }
      break
    case 'jpg':
      iconKey = { icon: 'file-image', color: themeVariables.primary_light }
      break
    case 'jpeg':
      iconKey = { icon: 'file-image', color: themeVariables.primary_light }
      break
    case 'png':
      iconKey = { icon: 'file-image', color: themeVariables.primary_light }
      break
    case 'zip':
      iconKey = { icon: 'file-zipper', color: themeVariables.gray_400 }
      break
    // file-audio
    // file-video
  }
  return iconKey
}
