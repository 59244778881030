///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Box, Button, Card } from '@mui/material'
import { hardCodedAllocationTypes } from 'app/models/timesheets/timesheet_hard_coded_data'
import { useContext, useEffect, useReducer, useState } from 'react'
import { Trans } from 'react-i18next'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import {
  DatabaseRef_ActiveTimeSheetAllocationTypes_Query,
  DatabaseRef_TimeSheetsAllocationTypes_Document,
} from 'rfbp_aux/services/database_endpoints/timesheets/allocation_types'
import { Icon } from 'rfbp_core/components/icons'
import {
  TableBasic,
  TableCellBasic,
  TableCellManage,
  TsInterface_TableAdditionalData,
  TsInterface_TableColumns,
  TsInterface_TableDataRow,
  TsInterface_TableHooks,
  TsInterface_TableManageAction,
  TsInterface_TableSettings,
} from 'rfbp_core/components/table'
import { rLIB } from 'rfbp_core/localization/library'
import { Context_RootData_ClientKey } from 'rfbp_core/services/context'
import { DatabaseGetLiveCollection, DatabaseSetMergeDocument } from 'rfbp_core/services/database_management'
import { dynamicSort, getProp, keyFromString, objectToArray } from 'rfbp_core/services/helper_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject, TsType_VoidFunction } from 'rfbp_core/typescript/global_types'

///////////////////////////////
// Typescript
///////////////////////////////

interface TabProps {}

///////////////////////////////
// Variables
///////////////////////////////

// Displayed Translatable Strings
// { sort-start } - displayed text - scoped sort plugin
const s_ADD_ALLOCATION_SUBTYPE: JSX.Element = <Trans>Add Allocation Subtype</Trans>
const s_ADD_SUBTYPE: JSX.Element = <Trans>Add Subtype</Trans>
const s_ALLOCATION_SUBTYPE: JSX.Element = <Trans>Allocation Subtype</Trans>
const s_ALLOCATION_SUBTYPES: JSX.Element = <Trans>Allocation Subtypes</Trans>
const s_ALLOCATION_TYPE: JSX.Element = <Trans>Allocation Type</Trans>
const s_ALLOCATION_TYPE_CODE: JSX.Element = <Trans>Allocation Type Code</Trans>
const s_ARE_YOU_SURE_THAT_YOU_WANT_TO_CHANGE_THE_PAYROLL_CODE_OVERRIDE_FOR_THIS_ALLOCATION_SUBTYPE: JSX.Element = (
  <Trans>Are you sure that you want to change the payroll code override for this allocation subtype</Trans>
)
const s_ARE_YOU_SURE_THAT_YOU_WANT_TO_DELETE_THIS_ALLOCATION_SUBTYPE: JSX.Element = <Trans>Are you sure that you want to delete this allocation subtype?</Trans>
const s_ARE_YOU_SURE_THAT_YOU_WANT_TO_DELETE_THIS_ALLOCATION_TYPE: JSX.Element = <Trans>Are you sure that you want to delete this allocation type?</Trans>
const s_CODE: JSX.Element = <Trans>Code</Trans>
const s_CONFIRM_TOGGLE: JSX.Element = <Trans>Confirm Toggle</Trans>
const s_DELETE: JSX.Element = <Trans>Delete</Trans>
const s_DELETE_ALLOCATION_SUBTYPE: JSX.Element = <Trans>Delete Allocation Subtype</Trans>
const s_DELETE_ALLOCATION_TYPE: JSX.Element = <Trans>Delete Allocation Type</Trans>
const s_EDIT_ALLOCATION_SUBTYPE_CODE: JSX.Element = <Trans>Edit Allocation Subtype Code</Trans>
const s_EDIT_ALLOCATION_TYPE_CODE: JSX.Element = <Trans>Edit Allocation Type Code</Trans>
const s_FAILED_TO_UPDATE_ALLOCATION_SUBTYPE: JSX.Element = <Trans>Failed to update allocation subtype</Trans>
const s_INCLUDE_WITH_ADMIN_TIME_IN_PAYROLL: JSX.Element = <Trans>Include with Admin Time in Payroll</Trans>
const s_INCLUDE_WITH_FIELD_WORK_IN_PAYROLL: JSX.Element = <Trans>Include with Field Work in Payroll</Trans>
const s_MISSING_CODE: JSX.Element = <Trans>Missing Code</Trans>
const s_NO_ALLOCATION_SUBTYPES: JSX.Element = <Trans>No Allocation Subtypes</Trans>
const s_OTHERWISE_CLICK_DISMISS: JSX.Element = <Trans>Otherwise click dismiss</Trans>
const s_TYPE_DANGER_TO_PROCEED: JSX.Element = <Trans>Type DANGER to proceed</Trans>
const s_UPDATE_ALLOCATION_SUBTYPE_PAYROLL_CODE_OVERRIDE: JSX.Element = <Trans>Update Allocation Subtype Payroll Code Override</Trans>
const s_YOU_MUST_ENTER_DANGER_IN_ORDER_TO_PROCEED: JSX.Element = <Trans>You must enter DANGER in order to proceed.</Trans>
// { sort-end } - displayed text

// Table
const tableSettings_AllocationTypes: TsInterface_TableSettings = {
  paginated: true,
  pagination_rows_per_page_default: 100,
  pagination_rows_per_page_options: [10, 25, 50, 100],
  show_header: true,
  size: 'small',
  sort_direction: 'asc',
  sort_property_default: 'name',
  sortable: true,
  collapsible_columns: true,
}

const manageCellDelete: TsInterface_TableManageAction = {
  icon: (
    <Icon
      type="solid"
      icon="trash"
    />
  ),
  label: <>{s_DELETE_ALLOCATION_TYPE}</>,
  onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
    tableHooks.uc_setUserInterface_ConfirmDialogDisplay({
      display: true,
      confirm: {
        color: 'error',
        header: s_DELETE_ALLOCATION_TYPE,
        icon: (
          <Icon
            icon="trash"
            type="solid"
          />
        ),
        submit_text: s_DELETE,
        text: s_ARE_YOU_SURE_THAT_YOU_WANT_TO_DELETE_THIS_ALLOCATION_TYPE,
        submit_callback: () => {
          return new Promise((resolve, reject) => {
            if (rowData.key != null) {
              getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                .then((res_GCK) => {
                  let updateObject: TsInterface_UnspecifiedObject = {
                    status: 'deleted',
                  }
                  getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                    .then((res_GCK) => {
                      DatabaseSetMergeDocument(DatabaseRef_TimeSheetsAllocationTypes_Document(res_GCK.clientKey, rowData.key as string), updateObject)
                        .then((res_DSMD) => {
                          resolve(res_DSMD)
                          tableHooks.ur_forceRerender()
                        })
                        .catch((rej_DSMD) => {
                          tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                          reject(rej_DSMD)
                        })
                    })
                    .catch((rej_GCK) => {
                      tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                      reject(rej_GCK)
                    })
                })
                .catch((rej_GCK) => {
                  tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                  reject(rej_GCK)
                })
            }
          })
        },
      },
    })
  },
}

const tableColumns_AllocationTypes: TsInterface_TableColumns = {
  manage: TableCellManage({
    delete: manageCellDelete,
  }),
  name: TableCellBasic('name', s_ALLOCATION_TYPE, 'name'),
  code: {
    cell: {
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        return 'tw-p-0'
      },
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = <></>
        const editAllocationCode = () => {
          tableHooks.uc_setUserInterface_PromptDialogDisplay({
            display: true,
            prompt: {
              color: 'success',
              confirm_text: rLIB('Save'),
              default_value: rowData.code,
              header: (
                <>
                  {rowData.name}: {s_EDIT_ALLOCATION_TYPE_CODE}
                </>
              ),
              icon: (
                <Icon
                  icon="code-simple"
                  type="solid"
                />
              ),
              input_label: s_ALLOCATION_TYPE_CODE,
              input_type: 'text',
              text: s_EDIT_ALLOCATION_TYPE_CODE,
              submit_callback: (promptValue: string) => {
                return new Promise((resolve, reject) => {
                  let updateObject = {
                    code: promptValue,
                  }
                  getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                    .then((res_GCK) => {
                      DatabaseSetMergeDocument(DatabaseRef_TimeSheetsAllocationTypes_Document(res_GCK.clientKey, rowData.key as string), updateObject)
                        .then((res_DSMD) => {
                          resolve(res_DSMD)
                          tableHooks.ur_forceRerender()
                        })
                        .catch((rej_DSMD) => {
                          tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                        })
                    })
                    .catch((rej_GCK) => {
                      tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                    })
                })
              },
            },
          })
        }
        if (rowData.code == null) {
          cellJSX = (
            <Box
              className="tw-py-1 tw-px-2 tw-text-left tw-rounded-lg tw-cursor-pointer tw-inline-block"
              sx={{ background: themeVariables.background_json }}
              onClick={() => {
                editAllocationCode()
              }}
            >
              <Box className="tw-italic tw-inline-block tw-opacity-30">{s_MISSING_CODE}</Box>
            </Box>
          )
        } else {
          cellJSX = (
            <Box
              className="tw-py-1 tw-px-2 tw-text-left tw-rounded-lg tw-cursor-pointer tw-inline-block"
              sx={{ background: themeVariables.background_json }}
              onClick={() => {
                editAllocationCode()
              }}
            >
              <Box className="tw-inline-block">{rowData.code}</Box>
            </Box>
          )
        }
        return cellJSX
      },
    },
    header: {
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return s_CODE
      },
      header_sort_by: 'code',
    },
  },
  subtypes: {
    cell: {
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        return 'tw-p-0'
      },
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = <></>
        // Subtype Creation Button
        let addSubtypeButton = (
          <Box>
            <Button
              variant="outlined"
              color="success"
              size="small"
              className="tw-mb-2 tw-mt-2"
              onClick={() => {
                tableHooks.uc_setUserInterface_PromptDialogDisplay({
                  display: true,
                  prompt: {
                    color: 'success',
                    confirm_text: rLIB('Save'),
                    default_value: null,
                    header: (
                      <>
                        {rowData.name}: {s_ADD_ALLOCATION_SUBTYPE}
                      </>
                    ),
                    icon: (
                      <Icon
                        icon="circle-plus"
                        type="solid"
                      />
                    ),
                    input_label: s_ALLOCATION_SUBTYPE,
                    input_type: 'text',
                    text: s_ADD_ALLOCATION_SUBTYPE,
                    submit_callback: (promptValue: string) => {
                      return new Promise((resolve, reject) => {
                        let newAllocationSubtypeKey = keyFromString(promptValue)
                        let updateObject: TsInterface_UnspecifiedObject = {
                          subtypes: {},
                        }
                        updateObject['subtypes'][newAllocationSubtypeKey] = {
                          name: promptValue,
                          key: newAllocationSubtypeKey,
                          status: 'active',
                          // code: null,
                        }
                        getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                          .then((res_GCK) => {
                            DatabaseSetMergeDocument(DatabaseRef_TimeSheetsAllocationTypes_Document(res_GCK.clientKey, rowData.key as string), updateObject)
                              .then((res_DSMD) => {
                                resolve(res_DSMD)
                                tableHooks.ur_forceRerender()
                              })
                              .catch((rej_DSMD) => {
                                tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                              })
                          })
                          .catch((rej_GCK) => {
                            tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                          })
                      })
                    },
                  },
                })
              }}
            >
              <Icon
                icon="circle-plus"
                className="tw-mr-2"
              ></Icon>
              {s_ADD_SUBTYPE}
            </Button>
          </Box>
        )
        // Subtype Code
        const rJSX_SubtypeCode = (subType: TsInterface_UnspecifiedObject): JSX.Element => {
          let subtypeCode = <></>
          const editAllocationCode = () => {
            tableHooks.uc_setUserInterface_PromptDialogDisplay({
              display: true,
              prompt: {
                color: 'success',
                confirm_text: rLIB('Save'),
                default_value: subType.code,
                header: (
                  <>
                    {subType.name}: {s_EDIT_ALLOCATION_SUBTYPE_CODE}
                  </>
                ),
                icon: (
                  <Icon
                    icon="code-simple"
                    type="solid"
                  />
                ),
                input_label: s_ALLOCATION_TYPE_CODE,
                input_type: 'text',
                text: s_EDIT_ALLOCATION_SUBTYPE_CODE,
                submit_callback: (promptValue: string) => {
                  return new Promise((resolve, reject) => {
                    let updateObject: TsInterface_UnspecifiedObject = {
                      subtypes: {},
                    }
                    updateObject['subtypes'][subType.key] = {
                      code: promptValue,
                    }
                    getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                      .then((res_GCK) => {
                        DatabaseSetMergeDocument(DatabaseRef_TimeSheetsAllocationTypes_Document(res_GCK.clientKey, rowData.key as string), updateObject)
                          .then((res_DSMD) => {
                            resolve(res_DSMD)
                            tableHooks.ur_forceRerender()
                          })
                          .catch((rej_DSMD) => {
                            tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                          })
                      })
                      .catch((rej_GCK) => {
                        tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                      })
                  })
                },
              },
            })
          }
          if (subType.code == null) {
            subtypeCode = (
              <Box
                className="tw-ml-2 tw-py-1 tw-px-2 tw-text-left tw-rounded-lg tw-cursor-pointer tw-inline-block"
                sx={{ background: themeVariables.background_json }}
                onClick={() => {
                  editAllocationCode()
                }}
              >
                <Box className="tw-italic tw-inline-block tw-opacity-30">{s_MISSING_CODE}</Box>
              </Box>
            )
          } else {
            subtypeCode = (
              <Box
                className="tw-ml-2 tw-py-1 tw-px-2 tw-text-left tw-rounded-lg tw-cursor-pointer tw-inline-block"
                sx={{ background: themeVariables.background_json }}
                onClick={() => {
                  editAllocationCode()
                }}
              >
                <Box className="tw-inline-block">{subType.code}</Box>
              </Box>
            )
          }
          return subtypeCode
        }
        // Subtype Deletion
        const rJSX_SubtypeDelete = (subType: TsInterface_UnspecifiedObject): JSX.Element => {
          let subtypeDeleteJSX = (
            <Box
              className=" tw-inline-block tw-ml-2 tw-opacity-30 hover:tw-opacity-100 tw-cursor-pointer"
              onClick={() => {
                tableHooks.uc_setUserInterface_ConfirmDialogDisplay({
                  display: true,
                  confirm: {
                    color: 'error',
                    header: s_DELETE_ALLOCATION_SUBTYPE,
                    icon: (
                      <Icon
                        icon="trash"
                        type="solid"
                      />
                    ),
                    submit_text: s_DELETE,
                    text: s_ARE_YOU_SURE_THAT_YOU_WANT_TO_DELETE_THIS_ALLOCATION_SUBTYPE,
                    submit_callback: () => {
                      return new Promise((resolve, reject) => {
                        if (rowData.key != null) {
                          getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                            .then((res_GCK) => {
                              let updateObject: TsInterface_UnspecifiedObject = {
                                subtypes: {},
                              }
                              updateObject['subtypes'][subType.key] = {
                                status: 'deleted',
                              }
                              getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                                .then((res_GCK) => {
                                  DatabaseSetMergeDocument(
                                    DatabaseRef_TimeSheetsAllocationTypes_Document(res_GCK.clientKey, rowData.key as string),
                                    updateObject,
                                  )
                                    .then((res_DSMD) => {
                                      resolve(res_DSMD)
                                      tableHooks.ur_forceRerender()
                                    })
                                    .catch((rej_DSMD) => {
                                      tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                                      reject(rej_DSMD)
                                    })
                                })
                                .catch((rej_GCK) => {
                                  tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                                  reject(rej_GCK)
                                })
                            })
                            .catch((rej_GCK) => {
                              tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                              reject(rej_GCK)
                            })
                        }
                      })
                    },
                  },
                })
              }}
            >
              <Icon
                icon="trash"
                sx={{ fontSize: '16px' }}
              ></Icon>
            </Box>
          )
          return subtypeDeleteJSX
        }
        // Subtype Payroll Code Override
        const rJSX_SubtypePayrollCodeOverride = (subType: TsInterface_UnspecifiedObject): JSX.Element => {
          let overrideJSX = <></>
          if (rowData.key === 'admin_time') {
            if (subType.include_with_field_work_in_payroll === true) {
              overrideJSX = (
                <Icon
                  icon="truck-pickup"
                  className="tw-ml-2 tw-cursor-pointer"
                  sx={{ color: themeVariables.warning_main, fontSize: '16px' }}
                  tooltip={s_INCLUDE_WITH_FIELD_WORK_IN_PAYROLL}
                  tooltipPlacement="right"
                  onClick={() => {
                    if (rowData.key != null) {
                      tableHooks.uc_setUserInterface_PromptDialogDisplay({
                        display: true,
                        prompt: {
                          color: 'error',
                          confirm_text: s_CONFIRM_TOGGLE,
                          default_value: '',
                          header: s_UPDATE_ALLOCATION_SUBTYPE_PAYROLL_CODE_OVERRIDE,
                          icon: (
                            <Icon
                              icon="siren-on"
                              type="solid"
                            />
                          ),
                          input_label: s_TYPE_DANGER_TO_PROCEED,
                          input_type: 'text',
                          text: s_ARE_YOU_SURE_THAT_YOU_WANT_TO_CHANGE_THE_PAYROLL_CODE_OVERRIDE_FOR_THIS_ALLOCATION_SUBTYPE,
                          submit_callback: (promptValue: string) => {
                            return new Promise((resolve, reject) => {
                              if (promptValue === 'DANGER') {
                                let updateObject: TsInterface_UnspecifiedObject = {
                                  subtypes: {
                                    [subType.key]: {
                                      include_with_field_work_in_payroll: false,
                                    },
                                  },
                                }
                                getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                                  .then((res_GCK) => {
                                    DatabaseSetMergeDocument(
                                      DatabaseRef_TimeSheetsAllocationTypes_Document(res_GCK.clientKey, rowData.key as string),
                                      updateObject,
                                    )
                                      .then((res_DSMD) => {
                                        tableHooks.ur_forceRerender()
                                        resolve(res_DSMD)
                                      })
                                      .catch((rej_DSMD) => {
                                        tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                                        reject(rej_DSMD)
                                      })
                                  })
                                  .catch((rej_GCK) => {
                                    tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                                    reject(rej_GCK)
                                  })
                              } else {
                                tableHooks.uc_setUserInterface_ErrorDialogDisplay({
                                  display: true,
                                  error: {
                                    message: s_FAILED_TO_UPDATE_ALLOCATION_SUBTYPE,
                                    details: (
                                      <>
                                        {s_YOU_MUST_ENTER_DANGER_IN_ORDER_TO_PROCEED} {s_OTHERWISE_CLICK_DISMISS}
                                      </>
                                    ),
                                    code: 'ER-D-ML-DMC-01',
                                  },
                                })
                                resolve({ close_dialog: false })
                              }
                            })
                          },
                        },
                      })
                    }
                  }}
                />
              )
            } else {
              overrideJSX = (
                <Icon
                  icon="chair-office"
                  className="tw-ml-2 tw-cursor-pointer"
                  sx={{ color: themeVariables.info_main, fontSize: '16px' }}
                  tooltip={s_INCLUDE_WITH_ADMIN_TIME_IN_PAYROLL}
                  tooltipPlacement="right"
                  onClick={() => {
                    if (rowData.key != null) {
                      tableHooks.uc_setUserInterface_PromptDialogDisplay({
                        display: true,
                        prompt: {
                          color: 'error',
                          confirm_text: s_CONFIRM_TOGGLE,
                          default_value: '',
                          header: s_UPDATE_ALLOCATION_SUBTYPE_PAYROLL_CODE_OVERRIDE,
                          icon: (
                            <Icon
                              icon="siren-on"
                              type="solid"
                            />
                          ),
                          input_label: s_TYPE_DANGER_TO_PROCEED,
                          input_type: 'text',
                          text: s_ARE_YOU_SURE_THAT_YOU_WANT_TO_CHANGE_THE_PAYROLL_CODE_OVERRIDE_FOR_THIS_ALLOCATION_SUBTYPE,
                          submit_callback: (promptValue: string) => {
                            return new Promise((resolve, reject) => {
                              if (promptValue === 'DANGER') {
                                let updateObject: TsInterface_UnspecifiedObject = {
                                  subtypes: {
                                    [subType.key]: {
                                      include_with_field_work_in_payroll: true,
                                    },
                                  },
                                }
                                getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                                  .then((res_GCK) => {
                                    DatabaseSetMergeDocument(
                                      DatabaseRef_TimeSheetsAllocationTypes_Document(res_GCK.clientKey, rowData.key as string),
                                      updateObject,
                                    )
                                      .then((res_DSMD) => {
                                        tableHooks.ur_forceRerender()
                                        resolve(res_DSMD)
                                      })
                                      .catch((rej_DSMD) => {
                                        tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                                        reject(rej_DSMD)
                                      })
                                  })
                                  .catch((rej_GCK) => {
                                    tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                                    reject(rej_GCK)
                                  })
                              } else {
                                tableHooks.uc_setUserInterface_ErrorDialogDisplay({
                                  display: true,
                                  error: {
                                    message: s_FAILED_TO_UPDATE_ALLOCATION_SUBTYPE,
                                    details: (
                                      <>
                                        {s_YOU_MUST_ENTER_DANGER_IN_ORDER_TO_PROCEED} {s_OTHERWISE_CLICK_DISMISS}
                                      </>
                                    ),
                                    code: 'ER-D-ML-DMC-01',
                                  },
                                })
                                resolve({ close_dialog: false })
                              }
                            })
                          },
                        },
                      })
                    }
                  }}
                />
              )
            }
          }
          return overrideJSX
        }
        // Subtype Render
        const rJSX_Subtype = (subType: TsInterface_UnspecifiedObject): JSX.Element => {
          let subtypeJSX = <></>
          if (subType.status === 'active') {
            subtypeJSX = (
              <Box className="tw-mb-1">
                <Icon
                  icon="caret-right"
                  className="tw-mr-2"
                ></Icon>
                {subType.name}
                {rJSX_SubtypeCode(subType)}
                {rJSX_SubtypeDelete(subType)}
                {rJSX_SubtypePayrollCodeOverride(subType)}
              </Box>
            )
          }
          return subtypeJSX
        }
        if (rowData.key === 'admin_time' || rowData.key === 'non_working_time') {
          // List
          if (rowData != null && rowData.subtypes != null && Object.keys(rowData.subtypes).length > 0) {
            cellJSX = (
              <Box className="tw-mt-2">
                {objectToArray(getProp(rowData, 'subtypes', {}))
                  .sort(dynamicSort('name', 'asc'))
                  .map((subType, index) => (
                    <Box key={index}>{rJSX_Subtype(subType)}</Box>
                  ))}
                {addSubtypeButton}
              </Box>
            )
          } else {
            cellJSX = (
              <Box>
                <Box className="tw-italic tw-inline-block tw-opacity-30">{s_NO_ALLOCATION_SUBTYPES}</Box>
                {addSubtypeButton}
              </Box>
            )
          }
        } else if (rowData.key === 'break' || rowData.key === 'field_work') {
          cellJSX = (
            <Box className="tw-mt-2">
              <Button
                variant="outlined"
                color="success"
                size="small"
                className="tw-mb-2 tw-mt-2"
                disabled={true}
                onClick={() => {}}
              >
                <Icon
                  icon="circle-plus"
                  className="tw-mr-2"
                ></Icon>
                {s_ADD_SUBTYPE}
              </Button>
            </Box>
          )
        }
        return cellJSX
      },
    },
    header: {
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return s_ALLOCATION_SUBTYPES
      },
      header_sort_by: null,
    },
  },
}

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// JSX Exports
///////////////////////////////

export const Tab: React.FC<TabProps> = (): JSX.Element => {
  // Props

  // Hooks - useContext, useState, useReducer, other
  // { sort-start } - hooks
  const [us_allocationTypes, us_setAllocationTypes] = useState<TsInterface_UnspecifiedObject>({})
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)
  // { sort-end } - hooks

  // Hooks - useEffect
  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setAllocationTypes(newData)
      ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_ActiveTimeSheetAllocationTypes_Query(res_GCK.clientKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

  // Other Variables
  const tableAdditionalData_AllocationTypes: TsInterface_TableAdditionalData = {}

  // Functions
  const returnHardCodedAllocationTypesWithDynamicSubTypes = (): TsInterface_UnspecifiedObject[] => {
    let formattedAllocationTypes: TsInterface_UnspecifiedObject = {}
    for (let loopAllocationTypeKey in hardCodedAllocationTypes) {
      formattedAllocationTypes[loopAllocationTypeKey] = {
        key: loopAllocationTypeKey,
        name: hardCodedAllocationTypes[loopAllocationTypeKey].name,
        status: hardCodedAllocationTypes[loopAllocationTypeKey].status,
        subtypes: {},
      }
    }
    for (let loopAllocationTypeKey in us_allocationTypes) {
      formattedAllocationTypes[loopAllocationTypeKey] = {
        key: loopAllocationTypeKey,
        name: us_allocationTypes[loopAllocationTypeKey].name,
        status: us_allocationTypes[loopAllocationTypeKey].status,
        code: us_allocationTypes[loopAllocationTypeKey].code,
        subtypes: us_allocationTypes[loopAllocationTypeKey].subtypes,
      }
    }
    return objectToArray(formattedAllocationTypes)
  }

  // Call Functions

  // JSX Generation
  const rJSX_Tab = (): JSX.Element => {
    let tabJSX = <></>
    tabJSX = (
      <Box className="tw-pt-1">
        <Card className="tw-mt-2">
          <TableBasic
            tableAdditionalData={tableAdditionalData_AllocationTypes}
            tableColumns={tableColumns_AllocationTypes}
            tableData={returnHardCodedAllocationTypesWithDynamicSubTypes()}
            tableSettings={tableSettings_AllocationTypes}
          />
        </Card>
      </Box>
    )
    return tabJSX
  }

  // Render
  return <>{rJSX_Tab()}</>
}
