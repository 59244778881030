///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			containers are pages / views used in the app and are made up of components and can interact with services and models

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Box, CircularProgress } from '@mui/material/'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { AuthenticatedContainer } from 'rfbp_aux/containers/authenticated_container'
import { ApplicationPages } from 'rfbp_aux/data/application_structure'
import { returnFormattedDateKey } from 'rfbp_core/services/helper_functions'
import { directAppNavigation } from 'rfbp_core/services/navigation/navigation_functions'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

// Authenticated Nav Data
const pageKey: string = ApplicationPages['TaskDispatchBookmarkPage']['key']

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Container
///////////////////////////////

export const Container: React.FC = (): JSX.Element => {
  // Props

  // Hooks - useContext, useState, useReducer, other
  // { sort-start } - hooks
  const un_routerNavigation = useNavigate()
  // { sort-end } - hooks

  // Hooks - useEffect
  useEffect(() => {
    let today = new Date()
    let formattedDate = returnFormattedDateKey(today)
    directAppNavigation(un_routerNavigation, ApplicationPages.TaskCalendarSchedulePage.url(formattedDate) + '?tab=Day')
  }, [un_routerNavigation])

  // Functions

  // JSX Generation
  const rJSX_PageContent = (): JSX.Element => {
    let pageContentJSX = (
      <Box className="tw-text-center">
        <CircularProgress
          variant="indeterminate"
          color="primary"
          className="tw-m-auto"
        />
      </Box>
    )
    return pageContentJSX
  }

  const rJSX_Page = (): JSX.Element => {
    let pageJSX = (
      <AuthenticatedContainer
        pageHeader={<></>}
        pageKey={pageKey}
        content={<Box>{rJSX_PageContent()}</Box>}
      />
    )
    return pageJSX
  }

  // Render
  return <>{rJSX_Page()}</>
}
