///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			containers are pages / views used in the app and are made up of components and can interact with services and models

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Box, Button, Card, Typography } from '@mui/material/'
import _ from 'lodash'
import { useContext, useEffect, useReducer, useState } from 'react'
import { Trans } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { AuthenticatedContainer } from 'rfbp_aux/containers/authenticated_container'
import { ApplicationPages } from 'rfbp_aux/data/application_structure'
import { DatabaseRef_SpendingProfiles_Document } from 'rfbp_aux/services/database_endpoints/finances/spending'
import { Json } from 'rfbp_core/components/code_display'
import { Icon } from 'rfbp_core/components/icons'
import { Context_RootData_ClientKey } from 'rfbp_core/services/context'
import { DatabaseGetLiveDocument } from 'rfbp_core/services/database_management'
import { formatDateToYYYYMMDD, getProp } from 'rfbp_core/services/helper_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject, TsType_VoidFunction } from 'rfbp_core/typescript/global_types'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

// Authenticated Nav Data
const pageKey: string = ApplicationPages['AdminFinanceSpendingProfileViewPage']['key']

// Displayed Translatable Strings
// { sort-start } - displayed text - scoped sort plugin
const s_BACK_TO_ALL_CARDHOLDERS: JSX.Element = <Trans>Back to all Cardholders</Trans>
const s_MISSING: JSX.Element = <Trans>Missing</Trans>
const s_SPENDING_CONTROLS: JSX.Element = <Trans>Spending Controls</Trans>
const s_ETW_SPENDING_CONTROLS: JSX.Element = <Trans>ETW Spending Controls</Trans>
const se_Cardholder = 'Cardholder'
// { sort-end } - displayed text

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Container
///////////////////////////////

export const Container: React.FC = (): JSX.Element => {
  // Props
  const params = useParams()
  const profileKey: string = params.id as string

  // Hooks - useContext, useState, useReducer, other
  // { sort-start } - hooks
  const [us_profile, us_setProfile] = useState<TsInterface_UnspecifiedObject>({})
  const un_routerNavigation = useNavigate()
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)
  // { sort-end } - hooks

  // Hooks - useEffect
  useEffect(() => {
    document.title = se_Cardholder
  }, [])

  useEffect(() => {
    if (profileKey) {
      let unsubscribeLiveData: TsType_VoidFunction
      const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
        us_setProfile(newData)
        ur_forceRerender()
      }
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          unsubscribeLiveData = DatabaseGetLiveDocument(DatabaseRef_SpendingProfiles_Document(res_GCK.clientKey, profileKey), updateLiveData)
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
      return () => {
        if (typeof unsubscribeLiveData === 'function') {
          unsubscribeLiveData()
        }
      }
    }
  }, [uc_RootData_ClientKey, ur_forceRerender, uc_setRootData_ClientKey, profileKey])

  // Functions

  // JSX Generation
  const rJSX_BackButton = (): JSX.Element => {
    let buttonJSX = (
      <Button
        color="inherit"
        variant="outlined"
        onClick={() => {
          un_routerNavigation(ApplicationPages.AdminFinanceSpendingIndexPage.url())
        }}
        disableElevation
        startIcon={<Icon icon="chevron-left" />}
        className="tw-mr-2"
      >
        {s_BACK_TO_ALL_CARDHOLDERS}
      </Button>
    )
    return buttonJSX
  }

  const rJSX_LineItem = (displayText: JSX.Element, propKey: string, obj: TsInterface_UnspecifiedObject, dataType: string): JSX.Element => {
    let lineJSX = <></>
    let missingTextJSX = (
      <Box
        component={'span'}
        className="tw-text-error_light tw-italic"
      >
        {s_MISSING}
      </Box>
    )

    const propValue = getProp(obj, propKey, missingTextJSX)

    let contentToRender

    if (dataType === 'date') {
      try {
        contentToRender = formatDateToYYYYMMDD(new Date(propValue * 1000))
      } catch (e) {
        contentToRender = propValue
      }
    } else if (dataType === 'card') {
      contentToRender = `**** **** **** ${propValue}`
    } else if (typeof propValue === 'object' && propValue !== null) {
      contentToRender = Object.entries(propValue).map(([key, value]) => {
        let valueObj
        if (typeof value === 'object') {
          if (_.isEmpty(value)) return null
          valueObj = <Json data={value as object} />
        } else {
          valueObj = value as string
        }
        return (
          <Box key={key}>
            <Box
              className="tw-pr-2 tw-font-bold tw-pl-6"
              component={'span'}
            >
              {key}:
            </Box>
            <Box
              className="tw-pr-1 tw-font-normal"
              component={'span'}
            >
              {valueObj}
            </Box>
          </Box>
        )
      })
    } else {
      contentToRender = propValue
    }

    if (propValue === missingTextJSX) {
      contentToRender = propValue
    }

    // Full JSX
    lineJSX = (
      <Box>
        <Typography variant="h6">
          <Box
            className="tw-pr-2 tw-font-bold"
            component={'span'}
          >
            {displayText}:
          </Box>
          <Box
            className="tw-pr-1 tw-opacity-50 tw-font-normal"
            component={'span'}
          >
            {contentToRender}
          </Box>
        </Typography>
      </Box>
    )
    return lineJSX
  }

  const rJSX_Page = (): JSX.Element => {
    let pageJSX = (
      <AuthenticatedContainer
        pageHeader={us_profile?.name || ''}
        pageKey={pageKey}
        content={
          <Box>
            <Box>{rJSX_BackButton()}</Box>
            <Card className="tw-p-4">
              {rJSX_LineItem(s_SPENDING_CONTROLS, 'spending_controls', us_profile, 'object')}
              {rJSX_LineItem(s_ETW_SPENDING_CONTROLS, 'etw_spending_controls', us_profile, 'object')}
            </Card>
          </Box>
        }
      />
    )
    return pageJSX
  }

  return <>{rJSX_Page()}</>
}
