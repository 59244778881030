import { Box, Button, Card, Chip, CircularProgress, FormControl, MenuItem, Select } from '@mui/material/'
import { tableColumns_User, tableSettings_AllUsers } from 'app/models/users/user_table'
import React, { useContext, useEffect, useReducer, useState } from 'react'
import { Trans } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { AuthenticatedContainer } from 'rfbp_aux/containers/authenticated_container'
import { ApplicationPages, ClientUserRoles, returnClientUserRoles } from 'rfbp_aux/data/application_structure'
import { DatabaseRef_SalesPartner_Collection } from 'rfbp_aux/services/database_endpoints/directory/sales_partners'
import { DatabaseRef_Users_Search } from 'rfbp_aux/services/database_endpoints/directory/users'
import { DatabaseRef_Users_Collection } from 'rfbp_aux/services/database_endpoints/standard_database_endpoints'
import { Icon } from 'rfbp_core/components/icons'
import { TableDatabase, TsInterface_TableAdditionalData, TsInterface_TableDatabaseEndpointQueryObject } from 'rfbp_core/components/table'
import { Context_RootData_ClientKey, Context_RootData_ClientPermissions } from 'rfbp_core/services/context'
import {
  DatabaseGetLiveCollection,
  generateDatabaseQuery,
  TsInterface_OrderByArray,
  TsInterface_QueryCursorsObject,
  TsInterface_QueryOperatorsArray,
} from 'rfbp_core/services/database_management'
import { dynamicSort, getProp, objectToArray } from 'rfbp_core/services/helper_functions'
import { onClickAppNavigation } from 'rfbp_core/services/navigation/navigation_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject, TsType_VoidFunction } from 'rfbp_core/typescript/global_types'

///////////////////////////////
// Variables
///////////////////////////////

const pageKey: string = ApplicationPages['AdminDatabaseUserListPage']['key']

const s_ALL_DEACTIVATED_USERS: JSX.Element = <Trans>All Deactivated Users</Trans>
const s_ALL_EXTERNAL_USERS: JSX.Element = <Trans>All External Users</Trans>
const s_ALL_INTERNAL_USERS: JSX.Element = <Trans>All Internal Users</Trans>
const s_ALL_USERS: JSX.Element = <Trans>All Users</Trans>
const s_NEW_USER: JSX.Element = <Trans>New User</Trans>
const s_SHOW_ALL_USERS_WITH_TASK_ROLES: JSX.Element = <Trans>Show all users with task roles</Trans>
const s_USERS: JSX.Element = <Trans>Users</Trans>
const se_USERS = 'Users'

export const Container: React.FC = (): JSX.Element => {
  const [us_clientUserRoles, us_setClientUserRoles] = useState<TsInterface_UnspecifiedObject>({})
  const [us_salesPartnersList, us_setSalesPartnersList] = useState<TsInterface_UnspecifiedObject>({})
  const [us_showAllWithTaskRole, us_setShowAllWithTaskRole] = useState<boolean>(false)
  const [us_userTableFilter, us_setUserTableFilter] = useState<string>('ALL_INTERNAL_USERS')
  const [us_salesPartnerFilter, us_setSalesPartnerFilter] = useState<string>('ALL_SALES_PARTNERS')
  const un_routerNavigation = useNavigate()
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)
  const { uc_RootData_ClientPermissions } = useContext(Context_RootData_ClientPermissions)

  useEffect(() => {
    document.title = se_USERS
  }, [])

  useEffect(() => {
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        us_setClientUserRoles(returnClientUserRoles(res_GCK.clientKey))
        ur_forceRerender()
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {}
  }, [uc_RootData_ClientKey, ur_forceRerender, uc_setRootData_ClientKey])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setSalesPartnersList(newData)
      ur_forceRerender()
    }
    if (uc_RootData_ClientKey != null) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_SalesPartner_Collection(res_GCK.clientKey), updateLiveData)
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    } else {
      us_setSalesPartnersList({})
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, ur_forceRerender, uc_setRootData_ClientKey])

  // Function for generating the appropriate query based on filters
  const tableDatabaseEndpoint_User = (
    queryGenerationData: TsInterface_TableDatabaseEndpointQueryObject,
    tableAdditionalData: TsInterface_TableAdditionalData,
  ) => {
    let queryOperatorsArray: TsInterface_QueryOperatorsArray = []
    let orderByArray: TsInterface_OrderByArray = [{ prop: 'name', desc: false }]

    // Apply sales partner filter if it's not set to ALL_SALES_PARTNERS
    if (us_salesPartnerFilter !== 'ALL_SALES_PARTNERS') {
      queryOperatorsArray.push({ prop: 'associated_sales_partner_key', comparator: '==', value: us_salesPartnerFilter })
    }

    // Apply task role filter if enabled
    if (us_showAllWithTaskRole === true && tableAdditionalData != null && tableAdditionalData.user_role != null) {
      orderByArray = []
      queryOperatorsArray.push({ prop: 'task_roles.' + tableAdditionalData.user_role, comparator: '==', value: true })
    } else {
      // Apply status filter (active/deleted)
      if (tableAdditionalData != null && tableAdditionalData.user_status != null) {
        queryOperatorsArray.push({ prop: 'status', comparator: '==', value: tableAdditionalData.user_status })
      }

      // Handle different filters for user type
      if (us_userTableFilter === 'ALL_USERS') {
        // No need to filter by organization type
      } else if (us_userTableFilter === 'ALL_INTERNAL_USERS') {
        // Filter only internal users
        queryOperatorsArray.push({ prop: 'associated_organization_type', comparator: '==', value: 'internal' })
      } else if (us_userTableFilter === 'ALL_EXTERNAL_USERS') {
        // Filter only external users
        queryOperatorsArray.push({ prop: 'associated_organization_type', comparator: '==', value: 'external' })
      } else if (tableAdditionalData != null && tableAdditionalData.user_role != null) {
        // Filter by user role
        queryOperatorsArray.push({ prop: 'user_role', comparator: '==', value: tableAdditionalData.user_role })
      }
    }

    // Handle pagination cursors (startAt, startAfter, endAt, endBefore)
    let queryCursorsObject: TsInterface_QueryCursorsObject = {}
    if (queryGenerationData['startAfter'] != null) {
      queryCursorsObject['startAfter'] = queryGenerationData.startAfter
    }
    if (queryGenerationData['startAt'] != null) {
      queryCursorsObject['startAt'] = queryGenerationData.startAt
    }
    if (queryGenerationData['endAt'] != null) {
      queryCursorsObject['endAt'] = queryGenerationData.endAt
    }
    if (queryGenerationData['endBefore'] != null) {
      queryCursorsObject['endBefore'] = queryGenerationData.endBefore
    }

    // Apply limit for pagination
    let limit = getProp(queryGenerationData, 'limit', 5)

    // Generate and return the Firestore query
    return generateDatabaseQuery(DatabaseRef_Users_Collection(uc_RootData_ClientKey as string), queryOperatorsArray, orderByArray, queryCursorsObject, limit)
  }

  const tableSearchEndpoint_Users = (searchProperty: string, searchInput: string) => {
    let limit = tableSettings_AllUsers.rows_per_page
    if (limit === null) {
      limit = 20
    }
    return DatabaseRef_Users_Search(uc_RootData_ClientKey as string, searchProperty, searchInput, limit)
  }

  const rJSX_Table = (): JSX.Element => {
    let tableJSX = <></>
    if (tableSettings_AllUsers.search_settings_database == null) {
      tableSettings_AllUsers.search_settings_database = { search_type: 'firebase' }
    }
    tableSettings_AllUsers.search_settings_database.search_endpoint = tableSearchEndpoint_Users

    let userStatus = 'active'
    if (us_userTableFilter === 'ALL_DEACTIVATED_USERS') {
      userStatus = 'deleted'
    }

    let organizationType = 'internal'
    if (us_userTableFilter === 'ALL_EXTERNAL_USERS') {
      organizationType = 'external'
    }

    let tableAdditionalData_AllUsers: TsInterface_TableAdditionalData = {
      associated_organization_type: organizationType,
      task_roles: ClientUserRoles,
      client_type: uc_RootData_ClientPermissions.client_type,
      sales_partners: us_salesPartnersList,
      user_status: userStatus,
    }

    tableJSX = (
      <Card className="tw-mt-4">
        <TableDatabase
          tableAdditionalData={tableAdditionalData_AllUsers}
          tableColumns={tableColumns_User}
          tableDatabaseEndpoint={tableDatabaseEndpoint_User}
          tableSettings={tableSettings_AllUsers}
        />
      </Card>
    )

    return tableJSX
  }

  const rJSX_UserTypeFilterDropdown = (): JSX.Element => {
    return (
      <Box className="tw-inline-block tw-align-top">
        <FormControl className="bp_thin_select_input">
          <Select
            color="primary"
            value={us_userTableFilter}
            onChange={(event: any) => {
              if (event != null && event.target != null && event.target.value != null) {
                us_setUserTableFilter(event.target.value)
              }
            }}
            variant="outlined"
          >
            <MenuItem value={'ALL_USERS'}>
              <Chip
                size={'small'}
                label={s_ALL_USERS}
                color={'default'}
                icon={<Icon icon="users" />}
              />
            </MenuItem>
            <MenuItem value={'ALL_INTERNAL_USERS'}>
              <Chip
                size={'small'}
                label={s_ALL_INTERNAL_USERS}
                color={'default'}
                icon={<Icon icon="users" />}
              />
            </MenuItem>
            <MenuItem value={'ALL_EXTERNAL_USERS'}>
              <Chip
                size={'small'}
                label={s_ALL_EXTERNAL_USERS}
                color={'default'}
                icon={<Icon icon="users" />}
              />
            </MenuItem>
            <MenuItem value={'ALL_DEACTIVATED_USERS'}>
              <Chip
                size={'small'}
                label={s_ALL_DEACTIVATED_USERS}
                color={'default'}
                icon={<Icon icon="users" />}
              />
            </MenuItem>
            {objectToArray(us_clientUserRoles)
              .sort(dynamicSort('key', null))
              .map((option: TsInterface_UnspecifiedObject, index: number) => (
                <MenuItem
                  key={index}
                  value={option['key']}
                >
                  <Chip
                    size={'small'}
                    label={ClientUserRoles[option['key']]['name']}
                    color={ClientUserRoles[option['key']]['color']}
                    icon={<Icon icon="users" />}
                  />
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </Box>
    )
  }

  const rJSX_SalesPartnerFilterDropdown = (): JSX.Element => {
    return (
      <Box className="tw-inline-block tw-align-top tw-ml-2">
        <FormControl className="bp_thin_select_input">
          <Select
            color="primary"
            value={us_salesPartnerFilter}
            onChange={(event: any) => {
              if (event != null && event.target != null && event.target.value != null) {
                us_setSalesPartnerFilter(event.target.value)
              }
            }}
            variant="outlined"
          >
            <MenuItem value={'ALL_SALES_PARTNERS'}>
              <Chip
                size={'small'}
                label={'All Sales Partners'}
                color={'default'}
                icon={<Icon icon="handshake" />} // Adding handshake icon here
              />
            </MenuItem>
            {objectToArray(us_salesPartnersList)
              .sort(dynamicSort('name', null))
              .map((option: TsInterface_UnspecifiedObject, index: number) => (
                <MenuItem
                  key={index}
                  value={option['key']}
                >
                  <Chip
                    size={'small'}
                    label={option['name']}
                    color={'default'}
                    icon={<Icon icon="handshake" />} // Adding handshake icon here as well
                  />
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </Box>
    )
  }

  const rJSX_Page = (): JSX.Element => {
    let pageJSX = <></>
    if (uc_RootData_ClientKey != null) {
      pageJSX = (
        <AuthenticatedContainer
          pageHeader={s_USERS}
          pageKey={pageKey}
          content={
            <Box className="tw-align-top">
              <Button
                color="success"
                variant="contained"
                onClick={(event) => {
                  onClickAppNavigation(event, un_routerNavigation, ApplicationPages.AdminDatabaseUserNewPage.url())
                }}
                disableElevation
                startIcon={<Icon icon="square-plus" />}
                className="tw-mr-2"
              >
                {s_NEW_USER}
              </Button>
              {rJSX_UserTypeFilterDropdown()}
              {rJSX_SalesPartnerFilterDropdown()}
              {rJSX_Table()}
            </Box>
          }
        />
      )
    } else {
      pageJSX = (
        <AuthenticatedContainer
          pageHeader={s_USERS}
          pageKey={pageKey}
          content={
            <Box className="tw-text-center">
              <CircularProgress />
            </Box>
          }
        />
      )
    }
    return pageJSX
  }

  // Render
  return <>{rJSX_Page()}</>
}
