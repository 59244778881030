import { collection, CollectionReference, doc, DocumentData, DocumentReference, getFirestore, Query } from 'firebase/firestore'
import { generateDatabaseQuery, TsInterface_QueryOperatorsArray } from 'rfbp_core/services/database_management'

export const DatabaseRef_Deduction_Lookup_Collection = (clientKey: string): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'operations', 'performance_management', 'deduction_lookup')
}

export const DatabaseRef_Deduction_Lookup_Document = (clientKey: string, deductionKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'operations', 'performance_management', 'deduction_lookup', deductionKey)
}

export const DatabaseRef_Deductions_Collection = (clientKey: string): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'operations', 'performance_management', 'deductions')
}

export const DatabaseRef_Deductions_Document = (clientKey: string, deductionKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'operations', 'performance_management', 'deductions', deductionKey)
}

export const DatabaseRef_DeductionsByDate_Query = (clientKey: string, startDate: Date, endDate: Date): Query<unknown> => {
  const startDateFormat = startDate.toISOString().slice(0, 10)
  const endDateFormat = endDate.toISOString().slice(0, 10)

  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [
    { prop: 'deduction_date', comparator: '>=', value: startDateFormat },
    { prop: 'deduction_date', comparator: '<=', value: endDateFormat },
  ]

  return generateDatabaseQuery(
    collection(getFirestore(), 'clients', clientKey, 'operations', 'performance_management', 'deductions'),
    queryOperatorsArray,
    [],
    {},
    null,
  )
}
